import { Form } from "antd";
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

function AutoGeneratedUUID(props) {
    const { t } = useTranslation();
    const element = props.element;
    const dataName = props.dataName;
    const title = props.title;

    const initialValue = element[dataName] ? element[dataName] : uuidv4();

    return (
        <Form.Item
            key={dataName}
            label={t(title)}
            name={dataName}
            initialValue={initialValue}
        >
            <p>{initialValue}</p>
        </Form.Item>
    );
}

export default AutoGeneratedUUID;
