import React  from 'react'
import styles from './WindowMarker.module.css'
import '../../../styles/App.css'
import { theme } from 'antd';
import { Table } from "antd";
import { checkData, columns } from '../../TableShowTwoColumns/TableShowTwoColumns';
const { useToken } = theme;

export function WindowMarker({visible,setVisible,data}) {
    const { token } = useToken();

    const handleClick = () => {
        setVisible(!visible);
    }

    const _data = data;
    delete _data.actual
    delete _data.should
    const dataTable = checkData(_data)
    
    return (
        <div className={ [styles.mainContainer, visible ? 'fadeIn' : 'fadeOut'].join(' ') } style={{backgroundColor:token.colorBgBase}}>
            <header>
                <h5>{ data["name"] ? data["name"] : 'Sin Nombre' }</h5>
                <i style={{ cursor: 'pointer' }} className='bi bi-x' onClick={() => handleClick()}></i>
            </header>
            <div className={styles.infoCity} style={{}}>
                <Table dataSource={dataTable} pagination={{ pageSize:8 }}  columns={columns} />
            </div>
        </div>
    )
}
