import '../../styles/App.css'
import '../../styles/Map.css'
// import { useAuth } from "../Hooks/index";
import { data } from "../../Mock/data/DB";
import { useEffect } from 'react';
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
import * as React from "react";
import markerBlue from "../../assets/marker.png";
import markerRed from "../../assets/markerRed.png";
import MarkerClusterGroup from 'react-leaflet-cluster'
import "leaflet/dist/leaflet.css";

// Components
import {WindowMarker} from './WindowMaker/WindowMarker.component';
import $ from 'jquery';
import MapMenu from '../Map/MapMenu/MapMenu.component';
import { List } from 'react-virtualized';
import LazyLoad from 'react-lazyload';
import { v4 as uuidv4 } from 'uuid';
import { use } from 'i18next';
/**
 * TODO: 
 * Utilizar el componente de React-Leaflet "MarkerClusterGroup"
 *  para agrupar los markers en clusters, lo cual mejorará el rendimiento 
 * al no tener que renderizar todos los markers individualmente.

Utilizar una librería de virtualización como react-virtualized para renderizar
 solo los markers que están en el área visible del mapa. Esto ayudará a reducir
  la cantidad de markers que se renderizan simultáneamente, mejorando así el rendimiento.

Utilizar un sistema de carga diferida para cargar los markers de manera incremental.
 Esto ayudará a evitar la carga de todos los markers al mismo tiempo, lo cual puede
  causar una carga importante en el navegador.

Utilizar una técnica de "lazy loading" para cargar solo los markers que son
 visibles en el mapa. Esto ayudará a reducir la cantidad de markers que se renderizan 
 simultáneamente, mejorando así el rendimiento.

Utilizar un sistema de cache para almacenar los markers ya renderizados. Esto ayudará
 a evitar tener que volver a renderizar los mismos markers cada vez que se cambia la vista del mapa.
 */
const windowMarkerRef = React.createRef();

export function Map({items,
  
  dataToShowAtMarker=[
    {"title": "Name","paramName":"name"},
    {"title": "Dev EUI","paramName":"dev_EUI"},
    {"title": "Description","paramName":"description"},

    {"title": "Tipo de conneción","paramName":"connection"},
    {"title": "Total mensajes(24hs)","paramName":"messageLast24hs"},
    {"title": "Total mensajes recibidos:","paramName":"messageRecibed"},

    {"title": "RSSI:","paramName":"RSSI"},
    {"title": "SNR:","paramName":"SNR"},
    {"title": "Ultimo conteo WIFI:","paramName":"WifiCount"},
    {"title": "Ultimo conteo BT:","paramName":"BTCount"},
    {"title": "Ultimo conteo BLE:","paramName":"BleCount"},
    
]
    
}) {

  let [windowMarkerVisible, setWindowMarkerVisible] = React.useState(false)
  let [windowMarkerData, setWindowMarkerData] = React.useState({})

  let [dataMap,setDataMap] = React.useState([])

  useEffect(() => {
    if (!items){
      setDataMap(data)
    }else{
      setDataMap(items)
    }
  },[])
  
  const loveIcon = L.icon({
    iconUrl: markerBlue,
    iconRetinaUrl: markerBlue,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [35, 35],
  })
  const IconMarkRed = L.icon({
    iconUrl: markerRed,
    iconRetinaUrl: markerRed,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [35, 35],
  })

  const handleClickMarker = (item) => {
    setWindowMarkerData(item)
    setWindowMarkerVisible(true)
  }
  
  const hours = 48
  const diference = hours*60*60*1000
  function listItems(){
    return dataMap.map((item) => {
      return (
        <Marker
          key={uuidv4()}
          position={[item.latitude, item.longitude]}
          title={item.name}
          icon={((new Date() - new Date(item.lastSeen)) >diference)?IconMarkRed:loveIcon}
          eventHandlers={{click: () => handleClickMarker(item)}}
      ></Marker>
    )}
    )
  }
  //Remove next function (talk with Buchu)
  function putMarkersOnMap() {
    return (
      <List
        width={100}
        height={100}
        rowCount={dataMap.length}
        rowHeight={20}
        rowRenderer={() => (
          dataMap.map(item => (
            (item.longitude && item.latitude) ?
              <Marker
                key={uuidv4()}
                position={[parseFloat(item.latitude), parseFloat(item.longitude)]}
                icon={loveIcon}
                eventHandlers={{
                  click: () => handleClickMarker(item)
                }}
              >
                <Popup>
                  {item.sensorTypeInfo?item.sensorTypeInfo.name:item.name}
              </Popup>
              </Marker> : null
          ))
          )}
      />
    )
  }
  return (
    <>
      {/* Ventana de los marcadores */}
      <WindowMarker 
        visible={windowMarkerVisible} setVisible={setWindowMarkerVisible} 
        data={windowMarkerData} dataToShow={dataToShowAtMarker}
      />
      <MapMenu/>
      <MapContainer
        className="markercluster-map"
        center={[40.0563, -1.12761]}
        zoom={10}
        maxZoom={18}
        style={{ margin: '0px', height: '100vh', zIndex: 0 }}
        onClick={(e) => {console.log(e)}}

      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          onClick={(e) => {console.log(e)}}

        />

        <MarkerClusterGroup chunkedLoading
          onClick={(e) => {console.log(e)}}
        >
        <LazyLoad once={true} threshold={200}>
          { listItems()}
          </LazyLoad>
        </MarkerClusterGroup>
      </MapContainer>
    </>
  )
}


