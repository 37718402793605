import { Col, Input, Modal, Row, Select, message } from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from 'react';
import { ENV } from '../../../../../utils';
import { useTranslation } from 'react-i18next';

export function ModalDelete({originalData, callback,setIsDeleteModalOpen, isDeleteModalOpen}) {
      // Delete an element modal view
      const { t } = useTranslation()
      async function handleDeleteOk() {
        let result = await fetch(`${ENV.Sta_API}sta/${originalData.dev_EUI}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json'
            }
          })
          if(result.status===200){
            message.success("STA deleted")
          }else{
            message.error("Error deleting STA")
          }
          await callback()
          setIsDeleteModalOpen(false);

      };
  
      function handleDeleteCancel() {
          setIsDeleteModalOpen(false);
      };
  
    return(
        <Modal title="Delete Element" open={isDeleteModalOpen} onOk={handleDeleteOk} onCancel={handleDeleteCancel}>
        <p>{t('Are you sure you want to remove this element?')}</p>
        {
            originalData.id ? <p>{t("ID")}: {originalData.id}</p> : <></>
        }
        {
            originalData.name ? <p>{t("Name")}: {originalData.name}</p> : <></>
        }
    </Modal>
    )
}