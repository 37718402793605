import { Col, Card, Typography, Space, Row, theme } from "antd"
import { useTranslation } from "react-i18next";
import styles from './DashboardCard.module.css'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import { MessageFilled } from '@ant-design/icons'
import { useAuth } from "../../../Hooks";

const { Paragraph, Text } = Typography
const { useToken } = theme


const DashboardCard = ({ type, data }) => {
    const { t } = useTranslation();
    const {accessToken, user} = useAuth()
    // console.log("USER: ", user)
    const { token } = useToken()

    if (type == "info") {

        // Calculate data
        let paragraphs = []
        for (let i = 0; i < data.info.labels.length; i++) {
            paragraphs.push(<Paragraph>{t(data.info.labels[i])}: <Text className={styles.property} strong>{data.info.data[i]}</Text></Paragraph>)
        }

        return (
            <Card title={t('Information')} bordered={false}>
                {paragraphs}
            </Card>
        )
    }

    if (type == "bar") {
        return (
            <Card title={t('Signal')} bordered={false}>
                <Bar redraw={true} data={data}
                    options={{
                        plugins: {
                            legend: {
                                display: false
                            }
                        }
                    }} />
            </Card>
        )
    }

    if (type == "donut") {
        return (
            <Card title={t('Sensors')} bordered={false}>
                <Row gutter={10}>
                    <Col span={14}>
                        <Card title={t('Packages')}>
                            <Space direction="vertical" size={8}>
                                <Space><MessageFilled style={{ fontSize: 'large', color: "#815AED" }} /><Text className={styles.property}>{data.datasets[0].data[0]}%</Text> {t('Received')}</Space>
                                <Space><MessageFilled style={{ fontSize: 'large', color: "#d4d4d4" }} /><Text className={styles.property}>{data.datasets[0].data[1]}%</Text> {t('Lost')}</Space>
                            </Space>
                        </Card>
                    </Col>
                    <Col span={10}>
                        <Doughnut redraw={true} data={data}
                            options={{
                                plugins: {
                                    legend: {
                                        display: false
                                    }
                                }
                            }}
                        />
                    </Col>

                </Row>
            </Card>
        )
    }
}

export default DashboardCard