import { Form, Input } from "antd";
import {useTranslation} from 'react-i18next'

// TODO: Need to be completed :)

function TextInputJSON(props) {

    const {t} = useTranslation()

    const element = props.element
    const dataName = props.dataName
    const title = props.title
    return ( 
        <Form.Item
        key={dataName}
        label={t(title)}
        name={dataName}
        initialValue={"{}"}        
    >
        <Input defaultValue={"{}"} copyable="true" />
    </Form.Item>
     );
}

export default TextInputJSON;