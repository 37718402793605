import BooleanInput from "../../components/UpdateItemForm/BooleanInput.component"
import DateInput from "../../components/UpdateItemForm/DateInput.component"
import EnumSelectorAbstract from "../../components/UpdateItemForm/EnumSelectorAbstract.component"
import FloatInput from "../../components/UpdateItemForm/FloatInput.component"
import NumberInput from "../../components/UpdateItemForm/NumberInput.component"
import TextInput from "../../components/UpdateItemForm/TextInput.component"
import Divisor from "../../components/UpdateItemForm/Divisor"
//export const streetLightInputDisableAtom = atomWithStorage('streetLightInputDisable', true)
//enableGroupAtom



export const streetlight = {
    attributes: [
        { name: 'id',
            key: 'id',
            required: true, formItem: TextInput,
            default: true, width: 150
        },
        
        { name: 'fabricante',
            key: 'vendor',
            formItem: EnumSelectorAbstract,
            options:[
                {value:"UVAX",label:"UVAX"},
                //{value:"Wellness",label:"Wellness"}
            ],
            required: true, width: 150,
            default: true
        },
        
        { name: 'tipo',
            key: 'type',
            enable:false,
            formItem: EnumSelectorAbstract,
            options:[
                {value:"streetlight",label:"streetlight"},
                {value:"streetlightcontrolCabinet",label:"streetlightcontrolCabinet"}
            ],
            disabledVarName: "streetlightType",
            disabledVarMaxValue: 1,
            default: true,
            required: true, width: 150
        },

        {  formItem: Divisor},
        { name: 'IP',
            key: 'uvaxIp',
            enable:false,
            formItem: TextInput,
            required: true, width: 150,
            default: true,
        },
        { name: 'Port',
            key: 'uvaxPort',
            enable:false,
            formItem: NumberInput,
            required: true, width: 150,
            default: true,
        },
        { key: 'uvaxUser', name: 'user', enable:false,
            formItem: TextInput,
            required: false, width: 150,
            default: true,
        },
        { key: 'uvaxPass', name: 'pass', enable:false,
            formItem: TextInput,
            required: false, width: 150,
            default: false,
        },
        { key: 'uvaxEndpoint', name: 'endpoint', enable:false,
            formItem: TextInput,
            required: false, width: 150,
            default: true,
        },
        {  formItem: Divisor},
//pg05
        { name: 'timeInstant', //common
            key: 'TimeInstant',
            required: false,
            formItem: DateInput,
            checkVarForDisabled: 'streetLightType',
            default: true, width: 150
        },
        // location
        { name: 'latitude', //common
            key: 'latitude',
            required: true, formItem: FloatInput,
            default: true, width: 150
        },
        { name: 'longitude', //common
            key: 'longitude',
            required: true, formItem: FloatInput,
            default: true, width: 150
        },

        { name: 'address',  // common
            key: 'address',
            required: false, formItem: TextInput,
            default: true, width: 150,
            textWidth: 255
        },
        { name: 'serialNumber', //common
            key: 'serialNumber',
            required: false, formItem: TextInput,
            default: true, width: 150
        },
        { name: 'refStreetlightGroup', //common
            key: 'refStreetlightGroup',
            required: false, formItem: TextInput,
            default: true, width: 150
        },
        { name: 'refStreetlightControlCabinetGroup', //unique
            key: 'refStreetlightControlCabinetGroup',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'refStreetlightControlCabinetZone', //unique
            key: 'refStreetlightControlCabinetZone',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'brandName', //unique
            key: 'brandName',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0

        },
        { name: 'modelName', //unique
            key: 'modelName',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0

        },
        { name: 'manufacturerName', //unique
            key: 'manufacturerName',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'dateServiceStarted', //common
            key: 'dateServiceStarted',
            required: false, formItem: DateInput,
            default: true, width: 150
        },
        { name: 'dateLastProgramming', //unique
            key: 'dateLastProgramming',
            required: false, formItem: DateInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'setWorkingMode', //common
            key: 'setWorkingMode',
            formItem: EnumSelectorAbstract,
            options:[
                {value:"automatic", label:"automatic"},
                {value:"manual", label:"manual"},
                {value:"semiautomatic", label:"semiautomatic"}
                ],
            default: true, width: 150,required: false
        },
        { name: 'maximumPowerAvailable', //unique
            key: 'maximumPowerAvailable',
            required: false, formItem: NumberInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0

        },

//pg06 from doc
        { name: 'totalEnergyConsumed', //common
            key: 'totalEnergyConsumed',
            required: false, formItem: NumberInput,
            default: true, width: 150
        },
        { name: 'energyCost', //unique
            key: 'energyCost',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'totalReactiveEnergyConsumed', //unique
            key: 'totalReactiveEnergyConsumed',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'dateMeteringStarted', //common
            key: 'dateMeteringStarted',
            required: false, formItem: DateInput,
            default: true, width: 150
        },
        { name: 'lastMeterReading', //unique
            key: 'lastMeterReading',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0

        },
        { name: 'frequency', //unique
            key: 'frequency',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'totalActivePower', //unique
            key: 'totalActivePower',
            required: false, formItem: NumberInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0

        },
        { name: 'totalReactivePower', //unique
            key: 'totalReactivePower',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'totalPowerFactor', //unique
            key: 'totalPowerFactor',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'activePowerR', //unique
            key: 'activePowerR',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'activePowerS', //unique
            key: 'activePowerS',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'activePowerT', //unique
            key: 'activePowerT',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0

        },
        { name: 'reactivePowerR', //unique
            key: 'reactivePowerR',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'reactivePowerS', //unique
            key: 'reactivePowerS',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0

        },

//pg07 from doc
        { name: 'reactivePowerT', //unique
            key: 'reactivePowerT',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'powerFactorR', //unique
            key: 'powerFactorR',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'powerFactorS', //unique
            key: 'powerFactorS',
            required: false, formItem: FloatInput,
            default: true, width: 150 ,
            disabledVarName: "streetlightType",disabledTargetValue:0

        },
        { name: 'powerFactorT', //unique
            key: 'powerFactorT',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'cosPhi', //unique
            key: 'cosPhi',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'intensityR', //unique
            key: 'intensityR',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'intensityS', //unique
            key: 'intensityS',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0

        },
        { name: 'intensityT', //unique
            key: 'intensityT',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'voltageR', //unique
            key: 'voltageR',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0

        },
        { name: 'voltageS', //unique
            key: 'voltageS',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'voltageT', //unique
            key: 'voltageT',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0

        },
        { name: 'thdrVoltageR', //unique
            key: 'thdrVoltageR',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'thdrVoltageS', //unique
            key: 'thdrVoltageS',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0

        },
        { name: 'thdrVoltageT', //unique
            key: 'thdrVoltageT',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'switchOnOff', //common
            key: 'switchOnOff',
            required: false, formItem: EnumSelectorAbstract,
            options:[
                {value:"on",label:"on"},
                {value:"off",label:"off"},
            ],
            default: true, width: 150
        },

//pg08 from doc
        { name: 'switchOnOff_status', //common
            key: 'switchOnOff_status',
            formItem: EnumSelectorAbstract,
            options:[
                {value:"UNKNOWN",label:"UNKNOWN"},
                {value:"PENDING",label:"PENDING"},
                {value:"DELIVERED",label:"DELIVERED"},
                {value:"OK",label:"OK"},
                {value:"ERROR",label:"ERROR"},
            ],
            required: false, default: true, width: 150
        },
        { name: 'switchOnOff_info', //common
            key: 'switchOnOff_info',
            required: false, formItem: TextInput,
            default: true, width: 150
        },
        { name: 'powerState', //common
            key: 'powerState',
            formItem: EnumSelectorAbstract,
            options:[
                {value:"on",label:"on"},
                {value:"off",label:"off"}
            ],
            required: false, default: true, width: 150
        },
        { name: 'dateLastSwitchingOff', //common
            key: 'dateLastSwitchingOff',
            required: false, formItem: DateInput,
            default: true, width: 150
        },
        { name: 'dateLastSwitchingOn', //common
            key: 'dateLastSwitchingOn',
            required: false, formItem: DateInput,
            default: true, width: 150
        },
        { name: 'status', //common
            key: 'status',
            required: false, formItem: TextInput,
            default: true, width: 150
        },
        { name: 'dataProvider', //common
            key: 'dataProvider',
            required: false, formItem: TextInput,
            default: true, width: 150
        },
        { name: 'image', //common
            key: 'image',
            required: false, formItem: TextInput,
            default: true, width: 150
        },
        { name: 'totalIntensity', //unique
            key: 'totalIntensity',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'totalVoltage', //unique
            key: 'totalVoltage',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'description', //common
            key: 'description',
            required: false, formItem: TextInput,
            default: true, width: 150
        },
        { name: 'source', //common
            key: 'source',
            required: false, formItem: TextInput,
            default: true, width: 150
        },
//pg09 from doc

        { name: 'areaServed', //common
            key: 'areaServed',
            required: false, formItem: TextInput,
            default: true, width: 150
        },
        { name: 'annotations', //common
            key: 'annotations',
            required: false, formItem: TextInput,
            default: true, width: 150
        },
        { name: 'cupBoardMadeOf', //unique
            key: 'cupBoardMadeOf',
            formItem: EnumSelectorAbstract,
            options:[
                {value:"plastic",label:"plastic"},
                {value:"metal",label:"metal"},
                {value:"concrete",label:"concrete"},
            ],
            required: false, default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0

        },
        { name: 'features', //unique
            key: 'features',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'compliantWith', //unique
            key: 'compliantWith',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'nextActuationDeadline', //unique
            key: 'nextActuationDeadline',
            required: false, formItem: DateInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'responsible', //unqiue
            key: 'responsible',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'meterReadingPeriod', //unique
            key: 'meterReadingPeriod',
            required: false, formItem: NumberInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'cmdPowerState', //unique
            key: 'cmdPowerState',
            formItem: EnumSelectorAbstract,
            options:[
                {value:"on",label:"on"},
                {value:"off",label:"off"}
            ],
            required: false, default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
//pg10 from doc
        { name: 'cmdIlluminanceLevel', //unique
            key: 'cmdIlluminanceLevel',
            required: false, formItem: NumberInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'cmdWorkingMode', //unique
            key: 'cmdWorkingMode',
            formItem: EnumSelectorAbstract,
            options:[
                {value:"automatic",label:"automatic"},
                {value:"manual",label:"manual"}
            ],
            required: false, default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'dateLastCommanded', //common
            key: 'dateLastCommanded',
            required: false, formItem: DateInput,
            default: true, width: 150
        },
        { name: 'enableHistoricCommand', //common
            key: 'enableHistoricCommand',
            required: false, formItem: BooleanInput,
            default: true, width: 150
        },
        { name: 'lastCommander', //common
            key: 'lastCommander',
            required: false, formItem: TextInput,
            default: true, width: 150
        },
        { name: 'lastCommanderMessage', //common
            key: 'lastCommanderMessage',
            required: false, formItem: TextInput,
            default: true, width: 150
        },
//pg11 from doc
        { name: 'commandTimeinstant', //common
            key: 'commandTimeinstant',
            required: false, formItem: DateInput,
            default: true, width: 150
        },
        { name: 'mainDoorOpen', //unique
            key: 'mainDoorOpen',
            required: false, formItem: BooleanInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'secondaryDoorOpen', //unique
            key: 'secondaryDoorOpen',
            required: false, formItem: BooleanInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'powerFailure', //unique
            key: 'powerFailure',
            required: false, formItem: BooleanInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'temperature', //common
            key: 'temperature',
            required: false, formItem: NumberInput,
            default: true, width: 150
        },
        { name: 'enableKeepAlive', //unique
            key: 'enableKeepAlive',
            required: false, formItem: BooleanInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'setKeepAlive', //unique
            key: 'setKeepAlive',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'setKeepAlive_status', //unique
            key: 'setKeepAlive_status',
            formItem: EnumSelectorAbstract,
            options:[
                {value:"UNKNOWN",label:"UNKNOWN"},
                {value:"PENDING",label:"PENDING"},
                {value:"DELIVERED",label:"DELIVERED"},
                {value:"OK",label:"OK"},
                {value:"ERROR",label:"ERROR"},
            ],
            required: false, default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'setKeepAlive_info', //unique
            key: 'setKeepAlive_info',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },

//pg12 from doc
        { name: 'setWeeklySchedule', //unique
            key: 'setWeeklySchedule',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'setWeeklySchedule_status', //unique
            key: 'setWeeklySchedule_status',
            formItem: EnumSelectorAbstract,
            options:[
                {value:"UNKNOWN",label:"UNKNOWN"},
                {value:"PENDING",label:"PENDING"},
                {value:"DELIVERED",label:"DELIVERED"},
                {value:"OK",label:"OK"},
                {value:"ERROR",label:"ERROR"},
            ],
            required: false, default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'setWeeklySchedule_info', //unique
            key: 'setWeeklySchedule_info',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'weeklySchedule', //unique
            key: 'weeklySchedule',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'dateLastWeeklySchedule', //unique
            key: 'dateLastWeeklySchedule',
            required: false, formItem: DateInput,
            width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        { name: 'name', //common
            key: 'name',
            required: false, formItem: TextInput,
            default: true, width: 150
        },
        { name: 'cpuTemperature', //unique
            key: 'cpuTemperature',
            required: false, formItem: NumberInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:0
        },
        // (common)
        //{ key: 'municipality', name: 'municipality', required: false, formItem: DateInput, default: true, width: 150 },

//pg13 from doc (common)
        // { key: 'zip', name: 'zip', required: false, formItem: TextInput, default: true, width: 150 },
        // { key: 'zone', name: 'zone', required: false, formItem: TextInput, default: true, width: 150 },
        // { key: 'district', name: 'district', required: false, formItem: TextInput, default: true, width: 150 },
        // { key: 'province', name: 'province', required: false, formItem: TextInput, default: true, width: 150 },
        // { key: 'region', name: 'region', required: false, formItem: TextInput, default: true, width: 150 },
        // { key: 'community', name: 'community', required: false, formItem: TextInput, default: true, width: 150 },
        // { key: 'country', name: 'country', required: false, formItem: TextInput, default: true, width: 150 },
        // { key: 'streetAddress', name: 'streetAddress', required: false, formItem: TextInput, default: true, width: 150 },
        // { key: 'postalCode', name: 'postalCode', required: false, formItem: TextInput, default: true, width: 150 },
        // { key: 'addressLocality', name: 'addressLocality', required: false, formItem: TextInput, default: true, width: 150 },
        // { key: 'addressRegion', name: 'addressRegion', required: false, formItem: TextInput, default: true, width: 150 },
        // { key: 'addressCommunity', name: 'addressCommunity', required: false, formItem: TextInput, default: true, width: 150 },
        // { key: 'addressCountry', name: 'addressCountry', required: false, formItem: TextInput, default: true, width: 150 },



// -------------------- //
// ENTITY STREETLIGHT
// -------------------- //

//pg21 from doc
        { name: 'refStreetlightControlCabinet', //unique
            key: 'refStreetlightControlCabinet',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        //status:{type:DataTypes.STRING}, //
        //powerState:{type:DataTypes.STRING}, //
        //refStreetlightGroup:{type:DataTypes.STRING}, //
        { name: 'refStreetlightZone', //unique
            key: 'refStreetlightZone',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        //dateLastSwitchingOn: {type: DataTypes.DATE}, //"2019-05-23T05:15:00.000Z"
//pg22 from doc
        //dateLastSwitchingOff: {type: DataTypes.DATE}, //"2019-05-23T05:15:00.000Z"
        { name: 'controllingMethod', //unique
            key: 'controllingMethod',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        //dateServiceStarted
        { name: 'illuminanceLevel', //unique
            key: 'illuminanceLevel',
            required: false, formItem: FloatInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        //switchOnOff:{type:DataTypes.BOOLEAN},
        //switchOnOff_status:{type:DataTypes.STRING},
        //switchOnOff_info:{type:DataTypes.STRING}, //"El comando se ha ejecutado correctamente"

//pg23 from doc

        { name: 'setIlluminanceLevel', //unique
            key: 'setIlluminanceLevel',
            required: false, formItem: NumberInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        { name: 'setIlluminanceLevel_status', //unique
            key: 'setIlluminanceLevel_status',
            formItem: EnumSelectorAbstract,
            options:[
                {value:"UNKNOWN",label:"UNKNOWN"},
                {value:"PENDING",label:"PENDING"},
                {value:"DELIVERED",label:"DELIVERED"},
                {value:"OK",label:"OK"},
                {value:"ERROR",label:"ERROR"},
            ],
            required: false,  default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        { name: 'setIlluminanceLevel_info',//unique
            key: 'setIlluminanceLevel_info',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        //setWorkingMode:{type:DataTypes.STRING}, // "automatic" || "manual"
        { name: 'setWorkingMode_status',//unique
            key: 'setWorkingMode_status',
            formItem: EnumSelectorAbstract,
            options:[
                {value:"UNKNOWN",label:"UNKNOWN"},
                {value:"PENDING",label:"PENDING"},
                {value:"DELIVERED",label:"DELIVERED"},
                {value:"OK",label:"OK"},
                {value:"ERROR",label:"ERROR"},
            ],
            required: false, default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },

//pg24 from doc
        { name: 'setWorkingMode_info',//unique
            key: 'setWorkingMode_info',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        { name: 'activePower',//unique
            key: 'activePower',
            required: false, formItem: NumberInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        //dateMeteringStarted:{type:DataTypes.DATE},
        { name: 'delay',//unique
            key: 'delay',
            required: false, formItem: NumberInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        { name: 'luminosity',//unique
            key: 'luminosity',
            required: false, formItem: NumberInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        //serialNumber:{type:DataTypes.STRING},
        //manufacturerName:{type:DataTypes.STRING},
        { name: 'timer',//unique
            key: 'timer',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },

//pg25 from doc
        { name: 'workingMode',//unique
            key: 'workingMode',
            formItem: EnumSelectorAbstract,
            options:[
                {value:"automatic",label:"automatic"},
                {value:"manual",label:"manual"},
                {value:"semiautomatic",label:"semiautomatic"},
            ],
            required: false, default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        //image
        { name: 'refStreetlightControlContactor',//unique
            key: 'refStreetlightControlContactor',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        { name: 'reactivePower',//unique
            key: 'reactivePower',
            required: false, formItem: NumberInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        { name: 'refStreetlightModel',//unique
            key: 'refStreetlightModel',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        //annotations:{type:DataTypes.STRING},
        { name: 'circuit',//unique
            key: 'circuit',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        { name: 'dateLastLampChange',//unique
            key: 'dateLastLampChange',
            required: false, formItem: DateInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },

//pg26 from doc
        //description:{type:DataTypes.STRING},
        { name: 'lanternHeight',//unique
            key: 'lanternHeight',
            required: false, formItem: NumberInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        { name: 'locationCategory',//unique
            key: 'locationCategory',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        //source:{type:DataTypes.STRING},
        //dataProvider:{type:DataTypes.STRING},

//pg27
        //areaServed:{type:DataTypes.STRING},
        { name: 'intensity',//unique
            key: 'intensity',
            required: false, formItem: NumberInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        { name: 'voltage',//unique
            key: 'voltage',
            required: false, formItem: NumberInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        { name: 'powerFactor',//unique
            key: 'powerFactor',
            required: false, formItem: NumberInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        //temperature: {type:DataTypes.FLOAT},
        //dateLastCommanded:{type:DataTypes.DATE},
//pg28
        //enableHistoricCommand:{type:DataTypes.BOOLEAN},
        //lastCommander:{type:DataTypes.STRING},
        //lastCommanderMessage:{type:DataTypes.STRING},
        
//pg29

        //commandTimeinstant:{type:DataTypes.DATE},
        { name: 'workingHours',//unique
            key: 'workingHours',
            required: false, formItem: NumberInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        { name: 'resetWorkingHours',//unique
            key: 'resetWorkingHours',
            required: false, formItem: BooleanInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
//pg30
        { name: 'resetWorkingHours_info',//unique
            key: 'resetWorkingHours_info',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        { name: 'resetWorkingHours_status',//unique
            key: 'resetWorkingHours_status',
            formItem: EnumSelectorAbstract,
            options:[
                {value:"UNKNOWN",label:"UNKNOWN"},
                {value:"PENDING",label:"PENDING"},
                {value:"DELIVERED",label:"DELIVERED"},
                {value:"OK",label:"OK"},
                {value:"ERROR",label:"ERROR"},
            ],
            required: false,  default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        //name:{type:DataTypes.STRING},
        { name: 'owner',//unique
            key: 'owner',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        { name: 'deviceId',//unique
            key: 'deviceId',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        { name: 'streetType',//unique
            key: 'streetType',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        { name: 'connectionPort',//unique
            key: 'connectionPort',
            required: false, formItem: NumberInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },

//pg31

        //totalEnergyConsumed:{},
        { name: 'operatingHours',//unique
            key: 'operatingHours',
            required: false, formItem: NumberInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        { name: 'controllerInstallationDate',//unique
            key: 'controllerInstallationDate',
            required: false, formItem: DateInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
         },
        { name: 'driverInstallationDate',//unique
            key: 'driverInstallationDate',
            required: false, formItem: DateInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
         },
        { name: 'firmwareVersion',//unique
            key: 'firmwareVersion',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
        },
        { name: 'calendarName',//unique
            key: 'calendarName',
            required: false, formItem: TextInput,
            default: true, width: 150,
            disabledVarName: "streetlightType",disabledTargetValue:1
         },

//pg32 (all common)

        { key: 'municipality', name: 'municipality', required: false, formItem: TextInput, default: true, width: 150 },
        { key: 'zip', name: 'zip', required: false, formItem: TextInput, default: true, width: 150 },
        { key: 'zone', name: 'zone', required: false, formItem: TextInput, default: true, width: 150 },
        { key: 'district', name: 'district', required: false, formItem: TextInput, default: true, width: 150 },
        { key: 'province', name: 'province', required: false, formItem: TextInput, default: true, width: 150 },
        { key: 'region', name: 'region', required: false, formItem: TextInput, default: true, width: 150 },

//pg33 (all common)
        { key: 'community', name: 'community', required: false, formItem: TextInput, default: true, width: 150 },
        { key: 'country', name: 'country', required: false, formItem: TextInput, default: true, width: 150 },
        { key: 'streetAddress', name: 'streetAddress', required: false, formItem: TextInput, default: true, width: 150 },
        { key: 'postalCode', name: 'postalCode', required: false, formItem: TextInput, default: true, width: 150 },
        { key: 'addressLocality', name: 'addressLocality', required: false, formItem: TextInput, default: true, width: 150 },
        { key: 'addressRegion', name: 'addressRegion', required: false, formItem: TextInput, default: true, width: 150 },
        { key: 'addressCommunity', name: 'addressCommunity', required: false, formItem: TextInput, default: true, width: 150 },
        { key: 'addressCountry', name: 'addressCountry', required: false, formItem: TextInput, default: true, width: 150 },

//////////////
        { key: "actions", name: "Actions",
        actions: [
            "edit",
            "remove",
            "CMD",
            "logs"
        ], default: true }

    ]
}
