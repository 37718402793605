import { theme } from "antd";
import { Tabs } from "antd"
import Alarms from "./Alarms/Alarms"
import Notifications from "./Notifications/Notifications"
import { useState } from "react"
import "./index.css"

const { useToken } = theme

function AlarmSystem() {
    const { token } = useToken()
    let [tabValue, setTabValue] = useState(1)
    let changeTab = (item)=>{setTabValue(item)}
    let tabItems = [
        {key: 0,label: ""},
        {key: 1,label: "Alarm", children: <Alarms hardware={["gateways","devices"]}/>},
        {key: 2,label: "Notifications", children: <Notifications hardware={["gateway","device"]}/>},
    ]
    return (
        <>
           <h1 style={{color: token.colorText }}> Alarms System</h1>
            <Tabs 
                style={{ background: "transparent !important", justifyContent: "space-between",  color: `${token.colorText} !important` }}
                items={tabItems} 
                activeKey={tabValue}  
                onChange={changeTab}
            />
        </>
    )
}

export default AlarmSystem
