import { Button, Flex,  Table,  message } from "antd";
import { useState, useEffect } from "react";
import { DeleteOutlined, EditOutlined,SyncOutlined,UploadOutlined,InfoOutlined,SettingOutlined  } from '@ant-design/icons'
import { ENV } from '../../../utils';
import { theme } from "antd";
import { ModalUploadCSV } from "./Modals/ModalUploadCSV/ModalUploadCSV";
import { ModalStaConfig } from "./Modals/ModalStaConfig/ModalStaConfig";
import { ModalStaInfo } from "./Modals/ModalStaInfo/ModalStaInfo";
import { ModalUpdateFirmware } from "./Modals/ModalUpdateFirmware/ModalUpdateFirmware";
import { ModalAddOrEdit } from "./Modals/ModalAddOrEdit/ModalAddOrEdit";
import { ModalDelete } from "./Modals/ModalDelete/ModalDelete";

const { useToken } = theme

function StaTab() {
    let [data, setData] = useState([])
    
    const [modal_AddOrEdit_Open, setModal_AddOrEdit_Open] = useState(false)
    const [modal_CSV_Open, setModal_CSV_Open] = useState(false)
    const [modal_UpdateFirmware_Open, setModal_UpdateFirmware_Open] = useState(false)
    const [modal_staConfig_Open, setModal_StaConfig_Open] = useState(false)
    const [modal_staInfo_Open, setModal_StaInfo_Open] = useState(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);


   function openDeleteModal() {
      setEntityData(data[0])
      setIsDeleteModalOpen(true);
   };

    let [addOrEdit,setAddOrEdit] = useState("Add")
    let [entityData,setEntityData] = useState ({})

    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedKeys) => {
        setSelectedRowKeys(selectedKeys);
      },
    };

    const { token } = useToken()

    function createSta(){
      setAddOrEdit("Add")
      setModal_AddOrEdit_Open(true)
      setEntityData({
        dev_EUI: "",
        name:"",
        description:"",
        latitude:"",
        longitude:"",
        connection: 'lora' ,
      })
    }

    function editSta(data){
      setEntityData({old_DEV_EUI:data.dev_EUI,...data})
      setAddOrEdit("Edit")
      setModal_AddOrEdit_Open(true)
    }

    function showInfo(data){
      setEntityData({...data})
      setModal_StaInfo_Open(true)
    }

    function configSta(data){
      setEntityData({...data})
      setModal_StaConfig_Open(true)
    }

    async function refreshData() {
        fetch(`${ENV.Sta_API}sta`)
        .then(response => response.json())
        .then(data => setData(data.result))
    }


    useEffect(() => {refreshData()}, [])
    return(
        <>
        {/* <Modals> */}
          {/* Specific to record */}
         
            <ModalAddOrEdit 
              AddOrEdit={addOrEdit}
              originalData={entityData} 
              modalOpen={modal_AddOrEdit_Open} setModalOpen={setModal_AddOrEdit_Open}  
              callback={refreshData}
            />
            <ModalStaConfig 
              originalData={entityData} 
              modalOpen={modal_staConfig_Open} setModalOpen={setModal_StaConfig_Open}
              callback={refreshData}
            />
            <ModalStaInfo 
              originalData={entityData} 
              modalOpen={modal_staInfo_Open} setModalOpen={setModal_StaInfo_Open}
              callback={refreshData}
            />
            <ModalDelete
              originalData={entityData} 
              setIsDeleteModalOpen={setIsDeleteModalOpen}
              isDeleteModalOpen={isDeleteModalOpen}
              callback={refreshData}
            />

          {/* GENERAL */}
            <ModalUpdateFirmware 
              modalOpen={modal_UpdateFirmware_Open} setModalOpen={setModal_UpdateFirmware_Open}
              callback={refreshData}
            />
            <ModalUploadCSV 
              modalOpen={modal_CSV_Open} setModalOpen={setModal_CSV_Open}
              callback={refreshData}
            />

        {/* </Modals> */}


        <Flex justify="flex-end">
              <Button 
                type="primary"  style={{marginRight:"6px"}} className="buttonCentered" 
                onClick={()=>{setModal_StaConfig_Open(true)}} > 
                Ventana Detección
              </Button>
              <Button 
                type="primary"  style={{marginRight:"6px"}} className="buttonCentered" 
                onClick={()=>{setModal_UpdateFirmware_Open(true)}} > 
                Actualizar Firmware
              </Button>
              <Button 
                type="primary"  style={{marginRight:"6px"}} className="buttonCentered" 
                onClick={null} > Actualizar Salt
              </Button>
                            
              <Button 
                  type="primary"  style={{marginRight:"6px"}} icon={<UploadOutlined />}
                  onClick={()=>{setModal_CSV_Open(true)}} >
                Upload CSV
              </Button>

              <Button 
                type="primary" className="buttonCentered" style={{marginRight:"6px"}}
                onClick={()=>{createSta()}} > 
                Add 
              </Button> 
        </Flex>
        
        <Table 
          dataSource={data}
          rowKey="dev_EUI"
          pagination={{ pageSize: 5 }}
          style={{marginTop:"20px"}}
          rowSelection={rowSelection}
        >
          <Table.Column title="dev_EUI" value="dev_EUI" key={"dev_EUI"} dataIndex={"dev_EUI"}/>
          <Table.Column title="Name" value="name" key={"name"} dataIndex={"name"}/>
          <Table.Column title="Description" value="description" key={"description"} dataIndex={"description"} />
          <Table.Column title="Latitude, Longitude" value="lat" dataIndex={"latitude"}
            render={(text, record) => (<Flex justify="flex-start">{record.latitude}, {record.longitude}</Flex>)}
          />
          <Table.Column title="Last seen"  value="lastSeen" key={"lastSeen"} dataIndex={"lastSeen"}
            render={(text, record) => {
              const lastSeen = new Date(record.lastSeen)
              const hours = 48
              const diference = hours*60*60*1000
              const result = ((new Date() - lastSeen) >diference)? "🟥 OFF":"🟩 ON"
              return `${result}, ${lastSeen.toLocaleString()}`
              
            }}
          />
          <Table.Column title="Connection" value="connection" key={"connection"} dataIndex={"connection"} />
          <Table.Column title="Actual version" value="actualVersion" key={"actual"} dataIndex={"actual"} />
          <Table.Column title="Should salt version" value="should" key={"should"} dataIndex={"should"}/>
          <Table.Column title="Actions"
            render={(text, record) => (
              <>
              <Flex justify="flex-start">
                <SyncOutlined onClick={null} 
                style={{ color: '#66C3F4', width:"2em" }}/>
                <EditOutlined   
                  onClick={()=>{editSta(record)}}  
                  style={{ "fontSize": "large", "color": token.colorPrimary }}
                />
                <DeleteOutlined  
                  onClick={()=>{openDeleteModal()}}
                  style={{ "fontSize": "large", "color": token.colorError }}
                  />
                  <InfoOutlined
                    onClick={()=>{showInfo(record)}}
                    style={{ "fontSize": "large", "color": "yellow" }}
                  />
                  <SettingOutlined 
                    onClick={()=>{ configSta(record)}}
                    style={{ "fontSize": "large", "color": "white" }}
                  />
              </Flex>
              </>
            )}
          />
        </Table>
        </>
    )
}
export default StaTab