import { Outlet, useNavigate } from "react-router-dom";

import NavBar from '../components/NavBar/NavBar.component'


import { theme, Layout, Menu } from 'antd';
import { useLocale } from "../Hooks/useLocale";
import { useState } from "react";
import { menuItems } from "../utils/menuItems"

const { Header, Content, Sider } = Layout
const { useToken } = theme

function Home() {
    const { lang, antdLang } = useLocale();

    const { token } = useToken()
    const navigate = useNavigate();

    const [collapsed, setCollapsed] = useState(false);
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    // Left sider position
    let leftPos = 0
    let leftMargin = "200px"
    if (collapsed) {
        leftPos = "-200px"
        leftMargin = 0
    }

    const items = menuItems;

    return (
        <Layout style={{height: "100%"}} >
            <div className="App">
                <Header style={{ background: token.colorBgLayout, lineHeight: '16px', paddingInlineEnd: '0', position: 'sticky', top: 0, zIndex: 1 }}>
                    <NavBar collapsedFunction={toggleCollapsed} collapsed={collapsed} />
                </Header>
                <Layout hasSider>
                    <Sider
                        className='left-menu'
                        style={{
                            background: token.colorBgElevated,
                            overflow: 'auto',
                            position: 'fixed',
                            zIndex: 2,
                            left: leftPos,
                            top: 64,
                            bottom: 0,
                        }}>

                        <Menu
                            defaultSelectedKeys={['']}
                            defaultOpenKeys={[]}
                            mode="inline"
                            items={items}
                            onClick={(selectedKeys) => { navigate(selectedKeys.key) }}
                        />

                    </Sider>
                    <Content style={{ height: "100%", background: token.colorBgBase, marginLeft: leftMargin}}>
                        <Outlet />
                    </Content>
                </Layout>
            </div>
            {/* <Footer /> */}
        </Layout>
    )
}

export default Home;