import React,{ useState } from 'react';
import { Spin,  Row, Col, Select,Flex, Input} from 'antd';
import { ENV } from "../../../../../utils/constants.js"
import { RightCircleTwoTone,PlusCircleTwoTone,DeleteOutlined } from '@ant-design/icons';

function SensorType({
     sensorData, setSensorData,
     setAddListSource,setAddListTarget, setAddListSelected ,setAddListLoading ,
}) {

    const [subTypes, setSubTypes] = useState([]);
    const [sensorsOptions, setSensorsOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const boxStyle= {
        width: '100%',
        height: '100%',
        borderRadius: 6,
    };
    const columStyle = {
        padding: '8px 0' ,
    }
    const rowStyle = {
        padding: '8px 0',width: '100%', 
    }
    const changeSensor= (value,index)=>{
        let tmp = JSON.parse(JSON.stringify(sensorData.sensorType.sensors))
        tmp[index].name = value
        setSensorData({...sensorData,sensorType:{...sensorData.sensorType,sensors:tmp}})
    }
    const changeSensorValue= (event,index)=>{
        let tmp = JSON.parse(JSON.stringify(sensorData.sensorType.sensors))
        tmp[index].value = event.target.value
        setSensorData({...sensorData,sensorType:{...sensorData.sensorType, sensors:tmp}})
    }
    const addSensor = ()=>{
        setSensorData({...sensorData,sensorType:{...sensorData.sensorType,sensors:[...sensorData.sensorType.sensors,{name:"",value:""}]}})
    }
    const removeSensor = (index)=>()=>{
        let tmp_sensorType = sensorData.sensorType.sensors
        tmp_sensorType.splice(index,1)
        setSensorData({...sensorData,sensorType:{...sensorData.sensorType,sensors:tmp_sensorType}})
    }
    const changeType = async (value) => {
        setSensorData({...sensorData,sensorType:{...sensorData.sensorType,type:value}})
        
        try{
            const response_options = await fetch(`${ENV.Infraestructure_API}/${sensorData.mac}/decoder/sensorType/${value}`,{
                method: 'GET', 
            });
            setLoading(true)
            if (response_options.status === 200){
                let subTypeOptions = await response_options.json()
                setSubTypes(subTypeOptions )
            }
            setLoading(false)
            setAddListLoading (true)
            const organizationId = window.location.href.match(/(?<=organizations\/)[0-9]*/g)
            const sensorsToAdd_response = await fetch(`${ENV.Infraestructure_API}/${sensorData.mac}/decoder/sensorsToAdd/${organizationId}/${value}`,{
                method: 'GET', 
            });
            if (sensorsToAdd_response.status == 200){
                let sensorsToAdd = await sensorsToAdd_response.json()
                setAddListSource(sensorsToAdd)
                setAddListTarget([])
                setAddListSelected([])
                setAddListLoading(false)
            }
        }catch (error) {
            setSubTypes([])
        }
        
    }
    
    const changeSubType=(value,item)=>{
        setSensorData({
            ...sensorData,
            sensorType:{
                ...sensorData.sensorType,
                subType:value,
                subTypeToSend:item.label,
            }
        })
        let tmp_sensorOptions = subTypes.filter((item)=>{return item.modelName===value})
        if (tmp_sensorOptions.length!= 1){
            tmp_sensorOptions = []
        }else{
            tmp_sensorOptions = tmp_sensorOptions[0].availableSensors
        }

        setSensorsOptions(tmp_sensorOptions.map((item)=>{return {value:item,label:item}}))


    }
    return(
        <>
        <Flex  vertical style={boxStyle}  gap={16} align='center' >
        <Row style={rowStyle}  gap={16} justify={"space-around"} align={"flex-start"}>
            <Col span={5}>
                <label>Select Sensor Type</label>
                <Select
                    onChange={changeType}
                    defaultValue={sensorData.sensorType.type}
                    style={{ width: "100%" }}
                    options={[
                        { value: 'lbox', label: 'lbox' },
                        { value: 'Wifi', label: 'Wifi' },
                        { value: 'OEMSensors', label: 'OEMSensors' },
                        { value: 'CustomSensors', label: 'CustomSensors' }
                    ]}
                />
            </Col>
            <Col span={5}>
                <label>Select Sensor Subtype</label>
                <Select
                    onChange={changeSubType}
                    defaultValue={sensorData.sensorType.subType}
                    style={{ width: "100%" }}
                    options={
                    subTypes.map((item)=>{
                        return ({
                            label:`${item.vendor}${item.modelName}`,
                            value:`${item.modelName}`
                        })
                    }
                    )}
                />
            </Col>
            <Col span={5}></Col>
            <Col span={5}></Col>
        </Row>

            <Flex  vertical style={{overflow: 'auto',width: '100%', }}  gap={16} align='center' >
                {sensorData.sensorType.sensors.map((item,index)=>{
                    return(
                        <Row key={index} style={rowStyle} gap={16} justify={"space-around"} align={"flex-start"}>
                            <Col span={5}>
                            <Spin hidden={index!=0} spinning={loading}  size="large">
                            </Spin>
                            </Col>
                            <Col span={5}  align={"right"} justify={"center"}>
                                <DeleteOutlined onClick={removeSensor(index)} hidden={index===0} style={{color:"red", fontSize: '20px',  marginRight:'10px'}}/>
                                <RightCircleTwoTone twoToneColor="#8491a3" style={{ fontSize: '20px' }}/>
                            </Col>
                            <Col span={5}>
                                <label>Sensor</label>
                            <Select
                                onChange={event => changeSensor(event, index)}
                                value={{value:item.name,label:item.name}}
                                style={{ width: "100%" }}
                                options={sensorsOptions}
                            />
                            </Col>
                            <Col span={5}>
                                <label>Value</label>
                                <Input
                                onChange={event => changeSensorValue(event, index)}
                                defaultValue={item.value}
                                >
                                </Input>
                            </Col>
                        </Row>

                    )
                })}

                <Row style={rowStyle} gap={16} justify={"space-around"} align={"flex-start"}>
                    <Col span={5}>

                    </Col>
                    <Col span={5} align={"right"}>
                        <PlusCircleTwoTone onClick={addSensor} twoToneColor="#87D68D" style={{ fontSize: '20px' }}/>
                    </Col>
                    <Col span={5}>
                    </Col>
                    <Col span={5}>
                        
                    </Col>
                </Row>
            </Flex>

        </Flex>
        </>

    )
}

export default SensorType
