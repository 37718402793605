import React from 'react'
import styles from './MenuItem.module.css'
import { Link } from 'react-router-dom';
import $ from 'jquery';

const MenuItem = ({ title, icon, subMenus, setActivated, linkActivated, location, rowReverse }) => {

    const activateComponent = () => {
        $('#' + title).slideToggle('fast')
        setActivated();
    }
    // Si se selecciona otro item del menu se oculta
    if (!linkActivated)
        $('#' + title).slideUp('fast');

    const hideLeftMenu = () => {
        let width = window.screen.width;
        if(width < 750)
            $('#leftmenu').children().eq(0).css({ left: '-20rem', width: 'auto' })
    }

    return (
        <div className={[linkActivated ? styles.topContainer : '']} >
            <div id={title + 'parent'} className={[styles.mainContainer, linkActivated ? styles.menuActivated : ''].join(' ')} onClick={() => { activateComponent() }}>
            <p>{title}</p><i className={icon}></i>
            </div>
            <div id={title} className={styles.containerSubMenus}>
                {subMenus?.map((subMenu, index) => {
                    return (subMenu.title !== '' ? <Link key={subMenu.path} to={subMenu.path} onClick={() => { 
                        hideLeftMenu();
                    }}>
                        <div className={styles.containerSubMenu}>
                            <p style={{ color: location.toLowerCase().includes(subMenu.path.toLowerCase()) ? '#20c997' : '' }}>{subMenu.title}</p>
                        </div>
                    </Link> : <div key={index}></div>)
                })}
            </div>
        </div>
    );
}

export default MenuItem;
