import React, { useState,useEffect } from "react";
import Title from "antd/es/skeleton/Title";
import { Table} from "antd"
import { useAuth } from "../../../../../Hooks/useAuth";
import { Checkbox, Input} from "antd";
export const TableHardware = ({  columnTitle, urlDataSource, selectedKeys,setSelectedKeys,variableName}) => {
  const { accessToken } = useAuth()
  const [allElements, setAllElements] = useState([]);
  const [searchText, setSearchText] = useState([]);
  useEffect(() => {
    fetch(urlDataSource,{method: "GET",headers: {"x-access-token":accessToken }})
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error al realizar la solicitud: ${response.status}`);
        }
        return response.json()
      })
      .then((data) => {
        setAllElements(data)
      })
      .catch((error) => {
        console.error('Error:', error);
        setAllElements([])
      })
  }, [])
    if(!selectedKeys[variableName]){
      return (<> The value of selectedKeys[{variableName}] is undefined</>)
    }else{}
      return (
        <>
        <Input.Search
        onSearch={(value) => {
          setSearchText(value)
        }}
        />
        <Table 
          dataSource={allElements} 
          columns={[{
            title: columnTitle, dataIndex: 'title',key: 'title',
            filteredValue: [searchText],
            onFilter: (value, record) => {return record.title.includes(value)}
          }]}
          rowKey={"key"}
          rowSelection={{
            selectedRowKeys:selectedKeys[variableName],
            type: "checkbox",
            fixed: true,
            onSelect: (record, selected) => {
              if(selected){
                setSelectedKeys({...selectedKeys, [variableName]:[...selectedKeys[variableName], record.key]})
              }else{
                setSelectedKeys({...selectedKeys, [variableName]:[...selectedKeys[variableName].slice(0, selectedKeys[variableName].indexOf(record.key)), ...selectedKeys[variableName].slice(selectedKeys[variableName].indexOf(record.key) + 1)]})
              }
            },
            columnTitle:  ()=>{
              if(selectedKeys[variableName]){
                return <Checkbox
                    checked={selectedKeys[variableName].length}
                    indeterminate={selectedKeys[variableName].length > 0 && selectedKeys.length < allElements.length}
                    onChange={
                      ()=>{ 
                        setSelectedKeys({...selectedKeys, [variableName]: selectedKeys[variableName].length === allElements.length ? [] : allElements.map((r) => r.key)});
                      }
                    }
                />
              }else{
                return null
              }}}}
          scroll={{ y: 240 }}
        />
      </>
      )
}
