import { Col, DatePicker, Flex, Input, InputNumber,  Row, Select, Switch } from "antd"

export function AlarmSetting({alarmEntity,setAlarmEntity}){
    console.log(alarmEntity.emails)
    return (
        <>

    <Row>
      <Col span={8}>
      <Flex align='center' justify='space-between' style={{width:"80%", padding:"10px"}}>

            Name: 
            <Input 
                value={alarmEntity.name} 
                onChange={(value)=>{setAlarmEntity({...alarmEntity, name:value.target.value})}}
                style={{ width: 115 }}
            /> 
            </Flex>
      </Col>
      <Col span={16}>
      <Flex align='center' justify='space-between' style={{width:"100%", padding:"10px"}}>

            Description: 
            <Input 
                value={alarmEntity.description} 
                onChange={(value)=>{setAlarmEntity({...alarmEntity, description:value.target.value})}}
                style={{ width: 390 }}
            /> 
            </Flex>
      </Col>
      <Col span={8}></Col>
    </Row>

    <Row>
    <Col span={8}>
        Alarm Type:
        <Select
            options={[{ value: 'connection', label: 'connection' },{ value: 'aforo', label: 'aforo' }]}
            onChange={(value)=>{setAlarmEntity({...alarmEntity, alarmType:value})}}
            value={alarmEntity.alarmType}
            style={{width:"150px"}}
        />
    </Col>
    {
        alarmEntity.alarmType === "aforo"?
            <>
                <Col span={6}>
                    Afora Type:
                    <Select
                        options={[{ value: 'WiFi', label: 'WiFi' },{ value: 'Bluetooth', label: 'Bluetooth' },{ value: 'BLE', label: 'BLE' }]}
                        onChange={(value)=>{setAlarmEntity({...alarmEntity, aforoType:value})}}
                        value={alarmEntity.aforoType}
                        style={{width:"100px"}}
                    />
                </Col>
                <Col span={6}>
                    Afora Value:
                    <InputNumber
                        min={1}
                        max={1000}
                        value={alarmEntity.aforoValue}
                        defaultValue={1}
                        onChange={(value)=>{setAlarmEntity({...alarmEntity, aforoValue:value})}}
                    />
                </Col>
            </>
        : 
        <Col span={8}>            
            Deactive hours:
            <InputNumber
                min={1}
                max={1000}
                value={alarmEntity.deactive_hours}
                onChange={(value)=>{setAlarmEntity({...alarmEntity, deactive_hours:value})}}
            />
        </Col>
    }
    </Row>
    <Row>
      <Col span={8}>
        <Flex align='center' justify='space-between' style={{width:"80%",  padding:"10px"}}>
            startDate:
                <DatePicker 
                    onChange={(value)=>{setAlarmEntity({...alarmEntity, start_date:value})}}
                    value={alarmEntity.start_date} 
                    style={{width:115}}
                />
        </Flex>
    </Col>

      <Col span={8}>
            <Flex align='center' justify='flex-end' style={{width:"100%",  padding:"10px", justifyContent:"center"}}>

                Enable:
                <Flex justify='center'>
                    <Switch
                        justify='center'
                        value={alarmEntity.enable}
                        // TODO: Revisar ya que no la visualiza en la tabla al hacer click onChange={(value)=>{setAlarmEntity({...alarmEntity, enable:value})}}
                        style={{padding:"10px", margin:"0 10px"}}
                    />
                </Flex>

            </Flex>
      </Col>
    </Row>
            <Col>
            <p>To: <p style={{fontSize:"11px"}}>  (separete the emails by comma)</p></p>
            <Input.TextArea 
            showCount maxLength={100}  
            value={alarmEntity.emails?alarmEntity.emails:""}
            onChange={(value)=>{
                setAlarmEntity({...alarmEntity, emails:value.target.value.split(",")})
            }}
            />
            <p>Subject:</p>
            <Input.TextArea showCount maxLength={100}  
            value={alarmEntity.email_subject}
            onChange={(value)=>{setAlarmEntity({...alarmEntity, email_subject:value.target.value})}}  
            />
            <p>Body:</p>
            <Input.TextArea
            showCount
            maxLength={100}
            value={alarmEntity.email_body}
            onChange={(value)=>{setAlarmEntity({...alarmEntity, email_body:value.target.value})}}  
            style={{ height: 120, resize: 'none', margin:"0 0 10px" }}
            />
            </Col>
        </>
        )
}