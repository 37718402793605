import { DatePicker, Form, Input } from "antd";
import {useTranslation} from 'react-i18next'
import { useAtom } from 'jotai'
import {enableInputGroupAtom} from '../../components/UpdateItemForm/CommonForm.jsx'

function DateInput(props) {

    const {t} = useTranslation()

    const dataName = props.dataName
    const title = props.title
    const [enableInputGroup,setEnableInputGroup] = useAtom(enableInputGroupAtom);

    return ( 
        <Form.Item
        key={dataName}
        label={t(title)}
        name={dataName}
    >
        <DatePicker
            disabled={props.disabledTargetValue === enableInputGroup[props.disabledVarName]}
        />
    </Form.Item>
     );
}

export default DateInput;