import React, { useState, useRef, useEffect } from "react";

import { Space, Table, theme, Tooltip, Button, DatePicker, Input, Spin, message } from "antd";
import { SettingOutlined, LinkOutlined, CheckCircleFilled, ClockCircleFilled, SearchOutlined, ExclamationCircleFilled, CalendarOutlined } from '@ant-design/icons'
import RemoveButton from "./RemoveButton";
import EditButton from "./EditButton";
import RedirectButton from "./RedirectButton";
import LogsButton from "./LogsButton";
import CMDButton from "./CMDButton";
import MqttLogButton from "./MqttLogButton";
import ServerButton from "./ServerButton";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import LockButton from "./LockButton";
import HistoryListButton from "./HistoryListButton";
import CalendarButton from "./CalendarButton"
import Highlighter from 'react-highlight-words';
import XmlButton from "./XmlButton";
import EditTermsButton from "./EditTermsButton";
import { useAuth } from "../../Hooks/useAuth.js"
import { useTableAttributes, useTableAttributesUpdate, useTableData, useTableDataUpdate } from '../../Hooks/useTableData'
import ContentPageHeader from "../ContentPageHeader";
import { ENV } from '../../utils';

import './CommonTable.component.css'
import { BasicApi } from "../../API/basicApi";
import CNOButton from "./CNOButton/index.jsx";
import KeysButton from "./KeysButton/index.jsx";

const { useToken } = theme;

/**
 * Creates a Table component with variable data and columns
 * @param {*} { allAttributes, additionalInput, form, submitButton, dataName, additionalButtons, title, route }
 * @returns 
 */
const CommonTable = ({
  allAttributes, additionalInput, form,
  submitButton, dataName, additionalButtons,
  title, route, extra, showBreadCrumbs,
  wrapper, showColor, updateAndDeleteRoute, disabledAddButton }) => {
  const { accessToken, user } = useAuth()
  const navigate = useNavigate();

  const [elementRemoved, setElementRemoved] = useState(false);

  const ComponentExtra = extra
  // // console.log("extra ", extra)
  const { token } = useToken();
  const { t } = useTranslation();
  // ---- Table data Hook
  const tableData = useTableData()
  const tableDataUpdate = useTableDataUpdate()
  const attributes = useTableAttributes();
  const setAttributes = useTableAttributesUpdate();
  // ----
  const defaultAttributes = allAttributes.filter((e) => e.default === true)
  const location = useLocation()

  const [columns, setColumns] = useState([])
  const [loading, setLoading] = useState(false)
  const [reloadState, setReloadState] = useState(false)
  const [pagination, setPagination] = useState({ defaultCurrent: 1, pageSize: 5 ,pageSizeOptions:[10,20,50,100,500,1000]});

  const [messageApi, contextHolder] = message.useMessage();

  const handleReloadOnRemove = () => {
    // Actualizar el estado o realizar cualquier acción necesaria
    setElementRemoved(true);
  };
  const gatewayMac = location.pathname.split("/")[4]

  if( [
        ENV.API_ROUTES.ORGANIZATIONS,ENV.API_ROUTES.SERVICE_PROFILE,
        ENV.API_ROUTES.APPLICATIONS,ENV.API_ROUTES.DEVICE_PROFILE
      ].includes(dataName)){
        route = `${ENV.Infraestructure_API}/${gatewayMac}/${dataName}`
  }
  // Update attributes on changed location
  let api = new BasicApi(route)

  const getData = async (limit, offset) => {
    try {
      const rawData = await api.getAll(accessToken, limit, offset)
      console.log("!RAW: ", rawData)
      if (rawData.db.result !== undefined) {
        setPagination(prev => (
          {
            ...prev,
            total: rawData.db.totalCount
          }
        ));
      }
      const data = rawData.db.result
      console.log("!DATA: ", data)
      let dataFiltered;
      // TODO: This have to be refactored.
      // Recomended create a extern API component and this one must be a commonTable Prop 
      try {
        const organizationSelected = location.pathname.split("/")[6]
        dataFiltered = data.filter(item => ((item.topic === gatewayMac ) && item.organizationID === organizationSelected));
      }
      catch (error) {
        console.log("🟥 No organization selected")
      }
      if (title === "Organizations") {
        dataFiltered = data.filter((item) => {
          console.log("ITEM:", item)
          if (item.topic === gatewayMac )
            return item
        })
      }
      if (title === "Sensors") {
        dataFiltered = data.filter((item) => item.topic === gatewayMac);
        dataFiltered = data.map((item) => {
          const deviceLastSeen = new Date(item.lastSeen);
          const actualDate = new Date();
          const difInHours = (actualDate - deviceLastSeen) / (1000 * 60 * 60);
          item.lastSeenDate = item.lastSeen
          if (difInHours < 6) {
            item.lastSeen = "🟩 ON";
          } else item.lastSeen = "🟥 OFF";
          return item;
        });
      }
      console.log("TITLE", title)
      if (title === "Gateway") {
        dataFiltered = data.filter((item) => item.topic === gatewayMac);
        dataFiltered = data.map((item) => {
          const deviceLastSeen = new Date(item.lastSeen);
          const actualDate = new Date();
          const difInHours = (actualDate - deviceLastSeen) / (1000 * 60 * 60);
          item.lastSeenDate = item.lastSeen
          if (difInHours < 6) {
            item.lastSeen = "🟩 ON";
          } else item.lastSeen = "🟥 OFF";
          return item;
        });
      }
      if (title === "Centralized") {
        dataFiltered = data.filter(item => !item.embedded);
      }
      if (title === "Phisical Gateways") {
        dataFiltered = data.filter(item => item.embedded);
      }
      tableDataUpdate(dataFiltered)
      // console.log(dataName + " table loaded 🟩")

    } catch (error) {
      console.log(dataName + " table not loaded 🟥", error)
      messageApi.open({
        type: "error",
        content: t('Error while loading. ')
      })
    }
    setElementRemoved(false)
  }
  useEffect(() => {
    setLoading(true)

    if (route || elementRemoved) {
      // console.log(`Loading ${dataName}`)
      setPagination(prev => ({ ...prev, current: 1 }))
      getData(pagination.pageSize,0)

    }

    if (defaultAttributes) {
      setAttributes(defaultAttributes)
    }
  }, [location, reloadState])


  // Calculate columns whenever attributes shown change
  useEffect(() => {
    let cols = calculateColumns()
    setColumns(cols)
    setLoading(false)
  }, [attributes, tableData])



  const onTableChange = (pagination, filters, sorter, extra) => {
    setPagination(pagination);
    // console.log('params', pagination, filters, sorter, extra);
  }

  /**
   * Force a reload of the table data
   */
  const reload = () => {
    setReloadState(!reloadState)
  }

  // ----------------------------------------------------------------------
  // DATE PICKER
  // ----------------------------------------------------------------------
  var getDaysArray = function (start, end) {
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  const getColumnDateFilterProps = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <>
        <DatePicker.RangePicker
          showTime={{ format: 'HH:mm' }}
          format="YYYY-MM-DD HH:mm"
          onChange={(value) => {
            clearFilters()
            let dates = getDaysArray(value[0]["$d"], value[1]["$d"])
            // console.log(dates)
            setSelectedKeys(dates)
          }}
          onOk={(value) => {
            // console.log('onOk: ', value);
            let dates = getDaysArray(value[0]["$d"], value[1]["$d"])
            // console.log(dates)
            setSelectedKeys(dates)
          }}
        />
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
          }}
        />
      </>
    ),
    filterIcon: (filtered) => (
      <CalendarOutlined />
    ),
    onFilter: (value, record) => {
      let dateString = `${value.getFullYear()}-${value.getMonth()}-${value.getDay()}`
      return record.lastLock.includes(dateString)
    }
  })

  // ----------------------------------------------------------------------
  // TEXT SEARCHER
  // ----------------------------------------------------------------------
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            {t('Search')}
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  // ----------------------------------------------------------------------

  /**
   * Creates an array of columns to show as json of antd columns.
   * Must be calculated AFTER having tableData
   * @returns columns[]
   */
  function calculateColumns() {
    let temp = []
    attributes.forEach(attr => {
      // Action buttons will be added later in the code
      if (attr.key === "actions") return

      if (attr.key === "web") {
        // Safe copy of tableData
        let tD = tableData
        for (let i = 0; i < tableData.length; i++) {
          // Create the icon
          // ERROR: currently not working
          tD[i]["url"] =
            <Tooltip title={tableData[i].web}>
              <a href={tableData[i].web} target="_blank" rel="noreferrer">
                <LinkOutlined style={{ "fontSize": "large", "color": token.colorPrimary }} />
              </a>
            </Tooltip>
          tableDataUpdate(tD)
        }
      }
      if (attr.key === "serversIcon" && attr["servers"]) {
        for (let i = 0; i < tableData.length; i++) {
          let tD = tableData
          tD[i].serversIcon =
            <Tooltip title={t("Servers")}>
              <ServerButton element={tableData[i].servers} />
            </Tooltip>
          tableDataUpdate(tD)
        }
      }
      if (attr.key === "statusIcon") {
        let tD = tableData
        for (let i = 0; i < tableData.length; i++) {
          if (tableData[i].status === "red") tD[i].statusIcon = <ExclamationCircleFilled style={{ "fontSize": "large", "color": token.colorError }} />
          if (tableData[i].status === "green") tD[i].statusIcon = <CheckCircleFilled style={{ "fontSize": "large", "color": token.colorSuccess }} />
          if (tableData[i].status === "yellow") tD[i].statusIcon = <ClockCircleFilled style={{ "fontSize": "large", "color": token.colorWarning }} />
        }
        tableDataUpdate(tD)
      }
      // create filters for the column
      let filters = []
      let elementsFiltered = []
      for (let i = 0; i < tableData.length; i++) {
        if (!elementsFiltered.includes(tableData[i][attr.key])) {
          filters.push({
            text: tableData[i][attr.key],
            value: tableData[i][attr.key],
          })
        }
        elementsFiltered.push(tableData[i][attr.key])
      }

      let temp_obj = {
        title: t(attr.name),
        dataIndex: attr.key,
        key: attr.key
      }
      if (Object.keys(attr).includes("width")) {
        temp_obj.width = attr.width;
      }

      // normal column with name filter
      if (attr.filter === "name") {
        temp_obj.filters = filters
        temp_obj.onFilter = (value, record) => record[attr.key].indexOf(value) === 0
      }
      // normal column with number filter
      if (attr.filter === "number") {
        temp_obj.sorter = (a, b) => a[attr.key] - b[attr.key]
      }
      // normal column with number filter
      if (attr.filter === "text") {
        temp_obj = { ...temp_obj, ...getColumnSearchProps(attr.key) }
      }
      // normal column with date filter
      if (attr.filter === "date") {
        temp_obj = { ...temp_obj, ...getColumnDateFilterProps() }
      }
      // normal column without filter
      temp.push(temp_obj)
    }); // foreach()

    // Once ended add action buttons
    let actionColumn = defaultAttributes.filter((e) => e.key === "actions")[0]
    // add action column
    temp.push({
      title: <SettingOutlined style={{ "fontSize": "large" }} />,
      dataIndex: actionColumn.key,
      fixed: 'right',
      key: "action-buttons",
      width: 150
    })
    // Add actions to data rows,
    // WARNING: might make the program slow, an alternative is recommended

    let tD = tableData
    for (let i = 0; i < tableData.length; i++) {
      let tempActions = []
      let depthRoutePhisicalGateway = ""
      let updateAndDeletePath = route
      if (updateAndDeleteRoute) {
        //"param/id"
        // example: "type/id"
        let params = updateAndDeleteRoute.split("/")

        updateAndDeletePath += params.reduce((acum, element) => {
          let result = ""
          result = element !== "id" ? result = acum + `/${tD[i][element]}` : acum
          return result
        }, "")
      } else {
        updateAndDeletePath = route
      }
      if (actionColumn.actions.includes("edit")) {
        tempActions.push(
          <EditButton
            route={updateAndDeletePath}
            form={form}
            attributes={allAttributes}
            element={tD[i]}
          />)
      }
      if (actionColumn.actions.includes("networkServer")) {
        // This url is the depth route you will be redirected when click on networkServer Action to go inside of NS
        // and then you must click an organization  
        depthRoutePhisicalGateway = `${location.pathname}/${tD[i].mac}/organizations`;
        tempActions.push(
          <RedirectButton
            route={depthRoutePhisicalGateway}
            element={tD[i]}
            alt={"NetworkServer Embebed"} />
        )
      }
      if (actionColumn.actions.includes("keys")) {
        // This url is the depth route you will be redirected when click on networkServer Action to go inside of NS
        // and then you must click an organization  
        depthRoutePhisicalGateway = `${location.pathname}/${tD[i].mac}/organizations`;
        tempActions.push(
          <KeysButton
            route={depthRoutePhisicalGateway}
            element={tD[i]}
            alt={"NetworkServer Embebed"} />
        )
      }
      if (actionColumn.actions.includes("insideOrganization")) {
        // This url is the depth route you will be redirected when click on networkServer Action to go inside of NS
        // and then you must click an organization  
        depthRoutePhisicalGateway = `${location.pathname}/${tD[i].id}`;
        // console.log("insideOrganization", depthRoutePhisicalGateway)
        tempActions.push(
          <RedirectButton
            route={depthRoutePhisicalGateway}
            element={tD[i]}
            alt={"Organization"} />
        )
      }
      if (actionColumn.actions.includes("CNO")) {
        // depthRoutePhisicalGateway = `${location.pathname}/${tD[i].id}`;
        // console.log("CNOButton")
        tempActions.push(
          <CNOButton
            route={depthRoutePhisicalGateway}
            element={tD[i]} alt={"CNO"}
          />
        )
      }

      if (actionColumn.actions.includes("mqttlog")) tempActions.push(<MqttLogButton element={tD[i]} />) // sends the message data as an element
      if (actionColumn.actions.includes("xml")) tempActions.push(<XmlButton element={tD[i]} />)
      if (actionColumn.actions.includes("editterms")) tempActions.push(<EditTermsButton route={route} attributes={additionalInput} element={tD[i]} />)
      if (actionColumn.actions.includes("calendar")) tempActions.push(<CalendarButton element={tD[i]} />)
      if (actionColumn.actions.includes("lock")) tempActions.push(<LockButton element={tD[i]} />)
      if (actionColumn.actions.includes("history")) tempActions.push(<HistoryListButton element={tD[i]} />)
      if (actionColumn.actions.includes("remove")) tempActions.push(
        <RemoveButton
          route={updateAndDeletePath}
          element={tD[i]}
          dataName={dataName}
          onRemove={handleReloadOnRemove}
        />
      )
      if (actionColumn.actions.includes("CMD")) tempActions.push(
        <CMDButton route={route}
          form={form}
          attributes={allAttributes}
          element={tD[i]}
        />
      )
      if (actionColumn.actions.includes("logs"))
        tempActions.push(
          <LogsButton
            route={route}
            form={form}
            attributes={allAttributes}
            element={tD[i]}
          />)
      tD[i].actions = <Space>{tempActions}</Space>
      tableDataUpdate(tD)
    }
    return temp
  } // calculateColumns()

  /**
   * Row selection of table
   */
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const selectionType = "checkbox";
  return (

    <div className={wrapper ? "wrapper" : ""} >

      {contextHolder}
      <ContentPageHeader route={route} attributes={allAttributes} canAdd={true} addForm={form} api={new BasicApi(route)} submitButton={submitButton} reload={reload} title={title}
        additionalButtons={additionalButtons} showBreadCrumbs={showBreadCrumbs} showColor={showColor} disabledAddButton={disabledAddButton}
      />
      <Spin size="middle" spinning={loading}>
        {extra ? <ComponentExtra /> : <></>}
        <Table dataSource={tableData}
          columns={columns}
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          pagination={
            {
              defaultCurrent: pagination.defaultCurrent,
              total: pagination.total,
              pageSize: pagination.pageSize,
              current: pagination.current,
              pageSizeOptions: pagination.pageSizeOptions,
              onChange: (page, pageSize) => {
                setPagination(prev => ({ ...prev, current: page, pageSize: pageSize }))
                getData(pagination.pageSize,(page-1)*pagination.pageSize)

              }
            }}
          scroll={{
            x: 1500,
            y: 400,
          }}
          onChange={onTableChange}
        />
      </Spin>
    </div>
  );
};

export default CommonTable;
