import { Form, InputNumber } from "antd";
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'
import {enableInputGroupAtom} from '../../components/UpdateItemForm/CommonForm.jsx'

function NumberInput(props) {

    const { t } = useTranslation()

    const element = props.element
    const dataName = props.dataName
    const title = props.title
    const [enableInputGroup,setEnableInputGroup] = useAtom(enableInputGroupAtom);

    return (
        <Form.Item
            key={dataName}
            label={t(title)}
            name={dataName}
            rules={[
                {
                    required: props.required
                },
            ]}
            initialValue={element[dataName] ? element[dataName] : 0}
        >
            <InputNumber defaultValue={element[dataName] ? element[dataName] : 0}
                disabled={props.disabledTargetValue === enableInputGroup[props.disabledVarName]}
            />
        </Form.Item>
    );
}

export default NumberInput;