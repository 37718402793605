import React, { useState } from "react";

import { theme, Tooltip } from "antd";
import { DeleteOutlined } from '@ant-design/icons'
import Modal from "antd/es/modal/Modal";
import { useLocale } from "../../../Hooks/useLocale";
import { useTranslation } from 'react-i18next';
import { useAuth } from "../../../Hooks";
import { BasicApi } from "../../../API/basicApi";
import { ENV } from "../../../utils";

const { useToken } = theme

/**
 * 
 * @param {*} param0 
 * @returns html of a column element
 */
const RemoveButton = ({ element, route ,onRemove }) => {

    const { t } = useTranslation()
    const { token } = useToken()
    const {accessToken, user} = useAuth()
    const api = new BasicApi(route)

    // Delete an element modal view
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    function openDeleteModal() {
        setIsDeleteModalOpen(true);
    };

    function handleDeleteOk() {
        setIsDeleteModalOpen(false);
        if (!element.id)
            element.mac ? element.id = element.mac : element.id = undefined
        api.deleteOne(element.id, accessToken)
        console.log(accessToken)
        console.log("ON REMOVE TRUE")
        onRemove(true)
    };

    function handleDeleteCancel() {
        setIsDeleteModalOpen(false);
    };
    // -------------------------------

    return (
        <>
            <DeleteOutlined style={{ "fontSize": "large", "color": token.colorError }} onClick={openDeleteModal} />
            <Modal title="Delete Element" open={isDeleteModalOpen} onOk={handleDeleteOk} onCancel={handleDeleteCancel}>
                <p>{t('Are you sure you want to remove this element?')}</p>
                {
                    element.id ? <p>{t("ID")}: {element.id}</p> : <></>
                }
                {
                    element.name ? <p>{t("Name")}: {element.name}</p> : <></>
                }
                
            </Modal>
        </>
    )

};

export default RemoveButton;
