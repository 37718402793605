import { ENV } from "../utils";

export class BasicApi {

  constructor(route) {
    this.route = route
  }

  async getAll(accessToken,limit=40, offset=0, extra= "") {
    const url = `${this.route}?limit=${limit}&offset=${offset}${extra}`;
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        "x-access-token": accessToken
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    try {
      if (!([200,304].includes(response.status))) throw result;
      if (result.db.result !== undefined) return result;
    }
    catch(error) {
      console.log(error)
      return result;
    }
    if (!([200,304].includes(response.status))) throw result;

  }

  async getInfraestructureEnums() {
    const url = ENV.Infraestructure_API + "/enums";
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    // console.log(result)

    if (!([200,304].includes(response.status))) throw result;

    return result;
  }

  async getOne(id) {
    const url = `${this.route}/${id}`;
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (!([200,304].includes(response.status))) throw result;

    return result;
  }

  async postOne(data, accessToken) {
    const url = this.route;
    // console.log("POST DATA :", data)
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": accessToken,
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (response.status !== 201) throw result;

    return result;
  }

  async putOne(data, accessToken, id) {
    const url = `${this.route}/${id}`;
    const params = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": accessToken,
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    if (!([200,201].includes(response.status)) ) throw result;

    return result;
  }

  async deleteOne( id, accessToken) {
    const url = `${this.route}/${id}`;
    // console.log(url)
    const params = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": accessToken,
      }
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (!([200,304].includes(response.status))) throw result;

    return result;
  }
}
