import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, Select, Typography } from "antd"
import { BasicApi } from "../../API/basicApi"
import { ENV } from "../../utils"
import { useLocation } from "react-router-dom";
import { useAuth } from "../../Hooks"

const { Text } = Typography

function DeviceProfileSelector(props) {

    const element = props.element
    const dataName = props.dataName
    const title = props.title
    const form = props.form
    const location = useLocation()

    const { t } = useTranslation()
    const gatewayMac = location.pathname.split("/")[4]
    // const thisOrganization = location.pathname.split("/")[6]
    const organizations = new BasicApi(`${ENV.Infraestructure_API}/${gatewayMac}/${ENV.API_ROUTES.DEVICE_PROFILE}`)
    const { accessToken, user } = useAuth()

    const [data, setData] = useState([])

    const onValueChange = (value) => {
        // console.log(dataName + ": " + value)
        form.setFieldValue(dataName, value)
    }

    /**
     * Creates options for all select element with the required input and then creates the Form
     */
    const generateFormOptions = () => {
        // Get all data for selector
        organizations.getAll(accessToken).then((value) => {
            //console.log("DEVICE_PROFILES:  " , value)
            const organizationSelected = location.pathname.split("/")[6]
            const data = value.db.result.filter(item => ((item.topic === gatewayMac || item.topic === ("NSC_" + gatewayMac)) && item.organizationID === organizationSelected));

            setData(data)
            // console.log(dataName + " loaded 👌")
        })
    }

    useEffect(() => {
        generateFormOptions()
    }, [])

    return (
        <Form.Item
            key={dataName}
            label={t(title)}
            name={dataName}
            rules={[
                {
                    required: true
                }
            ]}
        >
            <>
                <Select
                    showSearch
                    style={{
                        width: 200,
                    }}
                    placeholder={t("Search to Select")}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={data.map((deviceProfile) => (
                        {
                            label: deviceProfile.name,
                            value: deviceProfile.id
                        }))}
                    onChange={onValueChange}
                    defaultValue={element[dataName]}
                />
                <Text>{element[dataName]}</Text>
            </>
        </Form.Item>
    );
}

export default DeviceProfileSelector;