import { Button, Grid, Col, Row, theme, Typography, Space, Breadcrumb } from "antd"
import React, { useEffect, useState } from "react"

import { useTranslation } from 'react-i18next';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import DashboardCard from "./DashboardCard";
import { useLocation } from "react-router-dom";
import { useBreadcrumbs, useBreadcrumbsUpdate } from "../../Hooks/useBreadcrumbs";
import styles from "./Dashboard.module.css"
import ContentPageHeader from "../ContentPageHeader";

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend);

const mockData = {
    received: 80,
    lost: 20
}

const Dashboard = ({ props, title }) => {

    const messages = props.messages

    return (
        <div className={props.wrapper ? "wrapper" : ""}>
            <ContentPageHeader title={title} showColor={props.showColor}  showBreadCrumbs ={props.showBreadCrumbs}/>
            <div>
                <Row>
                    <Col style={{ padding: 8 }} xl={8} md={12} span={24}>
                        <DashboardCard type={"info"} data={props} />
                    </Col>
                    <Col style={{ padding: 8 }} xl={8} md={12} span={24}>
                        <DashboardCard type={"donut"} data={{
                            labels: ['Received', 'Lost'],
                            datasets: [{
                                label: '% of Messages',
                                data: [messages.received, messages.lost],
                                backgroundColor: ["#815AED", "#d4d4d4"],
                                borderWidth: 0
                            }]
                        }} />
                    </Col>
                    <Col style={{ padding: 8 }} xl={8} md={12} span={24}>
                        <DashboardCard type={"bar"} data={{
                            labels: ['>-105', '>-110', '>-115', '>-120'],
                            datasets: [{
                                label: '# of Messages',
                                data: messages.numbers,
                                backgroundColor: "#4766D6",
                                borderWidth: 0
                            }]
                        }} />
                    </Col>
                    <Col style={{ padding: 8 }} xl={8} md={12} span={24}>
                        <DashboardCard type={"donut"} data={{
                            labels: ['Received', 'Lost'],
                            datasets: [{
                                label: '% of Messages',
                                data: [messages.received, messages.lost],
                                backgroundColor: ["#815AED", "#d4d4d4"],
                                borderWidth: 0
                            }]
                        }} />
                    </Col>
                    <Col style={{ padding: 8 }} xl={8} md={12} span={24}>
                        <DashboardCard type={"bar"} data={{
                            labels: ['>-105', '>-110', '>-115', '>-120'],
                            datasets: [{
                                label: '# of Messages',
                                data: [23, 42, 43, 5],
                                backgroundColor: "#4766D6",
                                borderWidth: 0
                            }]
                        }} />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Dashboard