import React, { useState } from "react";
import { KeyOutlined } from '@ant-design/icons';
import { Modal, Button, Form } from "antd";
import { theme } from "antd";
import { Formik } from 'formik';
import { dataOtaa, dataAbp } from "./data";
import { BasicApi } from "../../../API/basicApi";
import { ENV } from "../../../utils";
import { useAuth } from "../../../Hooks";


const { useToken } = theme;

const initialValues = {
  genApplicationKey: "",
  applicationKey: ""
};

const KeysButton = ({ alt, element }) => {
  const [visible, setVisible] = useState(false);
  const { token } = useToken();
  const { accessToken } = useAuth()

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (result) => {
    try {
      // console.log('Success:', result);
      let api = new BasicApi(`${ENV.Infraestructure_API}/${element.topic}/${ENV.API_ROUTES.DEVICES}/${element.devEUI}/${ENV.API_ROUTES.KEYS}`)
      api.postOne(result, accessToken)
      let values = result.attributes
      let attributesToShow = []
      attributesToShow.push(values)
    }
    catch (error) {
      // console.log("ERROR: ", error)
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  return (
    <>
      <KeyOutlined
        alt={alt}
        style={{ fontSize: "large", color: token.colorPrimary }}
        onClick={showModal}
      />
      <Modal
        title="Device Activation:"
        open={visible}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <KeysForm onCancel={handleCancel} element={element} />
        </Form>
      </Modal>
    </>
  );
};


const KeysForm = ({ onCancel, element }) => {
  const sensor_abp = false;
  // console.log("ELEMENT: ", element)
  return (
    <Formik
      initialValues={initialValues}
    >
      {({ isSubmitting }) => (
        <>
          {sensor_abp ? (
            <>
              {dataAbp.map((value, index) =>
                <Form.Item label={value.label} name={value.name}>
                  <value.component placeholder={value.placeholder} />
                </Form.Item>
              )
              }
            </>
          ) : (
            <>
              {
                dataOtaa.map((value, index) =>
                  <Form.Item label={value.label} name={value.name}>
                    <value.component placeholder={value.placeholder} />
                  </Form.Item>
                )
              }
              <Form.Item
                key="devEUI"
                label={"DevEui"}
                name={"devEUI"}
                initialValue={element["devEUI"]}
              >
                <p>{element["devEUI"] ? element["devEUI"] : ""} </p>
              </Form.Item>

            </>
          )}
          <div style={{ display: "flex" }}>
            <Button key="cancel" onClick={onCancel}>
              Cancel
            </Button>,
            <Form.Item key={"submit-btn"}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </div>
        </>
      )}
    </Formik>
  );
};

export default KeysButton;
