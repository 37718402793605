import { Form, Input } from "antd";
import {useTranslation} from 'react-i18next'

function PhoneInput(props) {

    const {t} = useTranslation()

    const element = props.element
    const dataName = props.dataName
    const title = props.title

    // TODO: number prefix
    return ( 
        <Form.Item
        key={dataName}
        label={t(title)}
        name={dataName}
        rules={[
            {
                required: true,
                max: 25
            },
        ]}
        
    >
        <Input defaultValue={element[dataName]} copyable="true" />
    </Form.Item>
     );
}

export default PhoneInput;