import React from 'react';
import { Flex, Input,Checkbox } from 'antd';
function SensorFiware({ sensorData, setSensorData}) {
  const boxStyle= {
    width: '100%',
    height: '100%',
    borderRadius: 6,
  };
  const boxStyle2= {
    width: '100%',
  };
  const checkedCreateFiware = (e) => {
    setSensorData({...sensorData, createFiwareServer: e.target.checked});
  };
  const changeTokenUrl=(e)=>{
    const fiware = sensorData.fiware;
    fiware.tokenUrl = e.target.value;
    setSensorData({...sensorData, fiware: fiware});
  }
  const changeBrokerUrl=(e)=>{
    const fiware = sensorData.fiware;
    fiware.brokerUrl = e.target.value;
    setSensorData({...sensorData, fiware: fiware});
  }
  const changeUser=(e)=>{
    const fiware = sensorData.fiware;
    fiware.user = e.target.value;
    setSensorData({...sensorData, fiware: fiware});
  }
  const changeExceedMax=(e)=>{
    const fiware = sensorData.fiware;
    fiware.exceedMax = e.target.value;
    setSensorData({...sensorData, fiware: fiware});
  }
  const changeService=(e)=>{
    const fiware = sensorData.fiware;
    fiware.service = e.target.value;
    setSensorData({...sensorData, fiware: fiware});
  }
  const changeSubService=(e)=>{
    const fiware = sensorData.fiware;
    fiware.subService = e.target.value;
    setSensorData({...sensorData, fiware: fiware});
  }
  return(
    <>
      <br/>
      <Checkbox  checked={sensorData.createFiwareServer} onChange={checkedCreateFiware}>
        Create Fiware
      </Checkbox>
      <Flex  vertical  hidden={!sensorData.createFiwareServer} style={boxStyle} justify={"center"} align={"center"} gap={16}>
        <Flex  style={boxStyle2} gap={16} justify={"space-around"} align={"center"}>
          <Flex vertical>
            <label>Token Url</label>
            <Input onChange={changeTokenUrl} defaultValue={sensorData.fiware.tokenUrl} />
          </Flex>
          <Flex vertical>
            <label>Broker URL</label>
            <Input onChange={changeBrokerUrl} defaultValue={sensorData.fiware.BrokerUrl}/>
          </Flex>
          <Flex vertical>
            <label>User</label>
            <Input onChange={changeUser} defaultValue={sensorData.fiware.User}/>
          </Flex>

        </Flex>
        <Flex  style={boxStyle2} gap={16} justify={"space-around"} align={"center"}>
          <Flex vertical>
            <label>Exceed Max</label>
            <Input  onChange={changeExceedMax} defaultValue={sensorData.fiware.exceedMax}  />
          </Flex>
          <Flex vertical>
            <label>Service</label>
            <Input onChange={changeService} defaultValue={sensorData.fiware.service}/>
          </Flex>
          <Flex vertical>
            <label>Sub service</label>
            <Input onChange={changeSubService} defaultValue={sensorData.fiware.subService}/>
          </Flex>
        </Flex>
      </Flex>
    </>

  )
}
export default SensorFiware