import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, Select, Typography } from "antd"
import { BasicApi } from "../../API/basicApi"
import { ENV } from "../../utils"
import { useAuth } from "../../Hooks/useAuth.js"

const { Text } = Typography

function UsersSelector(props) {
    const { accessToken } = useAuth()

    const element = props.element
    const dataName = props.dataName
    const title = props.title
    const form = props.form

    const { t } = useTranslation()
    const user = new BasicApi(ENV.Auth_API_LOCAL + "/" + ENV.API_ROUTES.USER)

    const [users, setUsers] = useState([])


    const onSearch = (value) => {
        let extraParam = `&startWith=${value}`
        user.getAll(accessToken,40,0,extraParam).then((value) => {
            setUsers(value.db.result)
        })
    }
    const onValueChange = (value) => {
        // console.log(dataName + ": " + value)
        form.setFieldValue(dataName, value)
    }

    /**
     * Creates options for all select element with the required input and then creates the Form
     */
    const generateFormOptions = () => {
        // Get all users for selector
        user.getAll(accessToken).then((value) => {
            setUsers(value.db.result)
            // console.log("Users loaded 👌")
        })
    }

    useEffect(() => {
        generateFormOptions()
    }, [])

    return (
        <Form.Item
            key={dataName}
            label={t(title)}
            name={dataName}
            rules={[
                {
                    type: "array",
                    required: true
                },
            ]}
        >
            <>
                <Select
                    mode="multiple"
                    showSearch
                    style={{
                        width: 200,
                    }}
                    placeholder={t("Search to Select")}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={users.map((user) => ({
                        label: user.username,
                        value: user.id
                    }))}
                    onChange={onValueChange}
                    onSearch={onSearch}
                    defaultValue={element[dataName]}
                />
                <Text>{element[dataName]}</Text>
            </>
        </Form.Item>
    );
}

export default UsersSelector;