import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, Select, Typography } from "antd"
import { BasicApi } from "../../API/basicApi"
import { ENV } from "../../utils"
import { useAuth } from "../../Hooks/useAuth.js"

const { Text } = Typography

function CouncilSelector(props) {
    const { accessToken, user } = useAuth()

    const element = props.element
    const dataName = props.dataName
    const title = props.title
    const form = props.form

    const { t } = useTranslation()
    const council = new BasicApi(ENV.Auth_API_LOCAL + "/" + ENV.API_ROUTES.COUNCIL)

    const [data, setData] = useState([])
    const [roleSelected, setRoleSelected] = useState("")
    const onValueChange = (value) => {
        // console.log(dataName + ": " + value)
        form.setFieldValue(dataName, value)
    }

    /**
     * Creates options for all select element with the required input and then creates the Form
     */
    const generateFormOptions = () => {
        // Get all data for selector
        council.getAll(accessToken).then((value) => {
            value.push({
                name: t("None"),
                id: ""
            })
            setData(value)
            // console.log(dataName + " loaded 👌")
        })
    }

    useEffect(() => {
        // console.log("VALUES SELECTED:", form.getFieldValue("roleName"))
        setRoleSelected(form.getFieldValue("roleName"))
        generateFormOptions()
    }, [])

    return (
        <>
            {
                roleSelected === "USER_COUNCIL" ? (
                    <Form.Item
                        key={dataName}
                        label={t(title)}
                        name={dataName}
                        rules={[
                            {
                                required: true
                            }
                        ]}
                    >
                        <Select
                            mode="multiple"
                            showSearch
                            style={{
                                width: 200,
                            }}
                            placeholder={t("Search to Select")}
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={data.map((council) => ({
                                label: council.name,
                                value: council.id
                            }))}
                            onChange={onValueChange}
                            defaultValue={element[dataName]}
                        />

                    </Form.Item >
                ) : (
                    <>
                    </>
                )
            }

        </>
    );
}

export default CouncilSelector;