import { Form, Input } from "antd";
import {useTranslation} from 'react-i18next'
import { useLocation } from "react-router-dom";

function TextNotEditable(props) {

    const {t} = useTranslation()

    const element = props.element
    const dataName = props.dataName
    const title = props.title
    const location = useLocation()
    const gatewayMac = location.pathname.split("/")[4]

    return ( 
        <Form.Item
        key={dataName}
        label={t(title)}
        name={dataName}
        initialValue={element[dataName] ? element[dataName] : gatewayMac }
    >
        <p>{element[dataName] ? element[dataName] :gatewayMac } </p>
    </Form.Item>
     );
}

export default TextNotEditable;