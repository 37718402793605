import { LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { Popconfirm, theme } from 'antd';
import { useTranslation } from 'react-i18next';

const { useToken } = theme;

function LockButton({element}) {

    const { t } = useTranslation();
    const { token } = useToken();

    const confirm = () => {
        //TODO: edit door state
    }
    const cancel = () => {
        // console.log("Edit door state canceled.")
    }

    if (element.locked) {
        return (
            <Popconfirm
                title={t('Unlock')}
                description={t('Are you sure to unlock this door?')}
                onConfirm={confirm}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
            >
                <LockOutlined style={{ "fontSize": "large", "color": token.colorPrimary }}
                />
            </Popconfirm>
        );
    }
    // When it is unlocked
    return (
        <Popconfirm
            title={t('Lock')}
            description={t('Are you sure to lock this door?')}
            onConfirm={confirm}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
        >
            <UnlockOutlined style={{ "fontSize": "large", "color": token.colorPrimary }}
            />
        </Popconfirm>
    );
}

export default LockButton;