import React, { useState } from "react";
import "./TabNavigation.css"
import {  theme } from "antd";
import { useTranslation } from "react-i18next";

const { useToken } = theme;

export const TabNavigation = ({ selected: defaultSelected = 0, children }) => {
  const [selected, setSelected] = useState(defaultSelected);
  const { token } = useToken();
  const { t } = useTranslation();

  const handleChange = (index) => {
    setSelected(index);
  };

  return (

  <div className="main" style={{background:token.colorBgElevated,	height: "80%" }}>
      <ul className="inline" style={{background: token.colorBgLayout,borderRadius: "15px 15px 0 0"}}>
        {children.map((elem, index) => {
          let style = index === selected ? "selected" : "noSelected";
          let color = index === selected ? {"color": token.colorPrimary } : {"color": token.colorText };
          return (
            <li
              className={style}
              key={index}
              onClick={() => handleChange(index)}
              style={color}
            >
              {t(elem.props.title)}
            </li>
          );
        })}
      </ul>
      <div className="tab" style={{"color": token.colorText }}>{children[selected]}</div>
    </div>
  );
};

export const TabPanel = ({ children }) => {
return <div>{children}</div>;
};