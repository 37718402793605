import { Badge, Calendar } from 'antd'
import { useParams } from 'react-router-dom';
import ContentPageHeader from '../ContentPageHeader';

function EventCalendar({title}) {

    let { doorId } = useParams();

    const dateData = {
        "0": {
            "2": [
                {
                    doorId: 104,
                    type: "success",
                    content: "11:00 Open"
                }
            ],
            "4": [
                {
                    doorId: 102,
                    type: "success",
                    content: "14:00 Close"
                }
            ]
        }
    }

    /**
     * Filters the array and return only the ones which doorID is same as url id
     * @param {*} value 
     * @returns value
     */
    function isOfDoorById(value) {
        if (value.doorId == doorId) return value
        // return empty else
        return
    }

    /**
     * Gets the events given an specific day
     * @param {day.js} date 
     * @returns event[]
     */
    function getEvents(date) {
        let month = dateData[date.month()]
        if (month) {
            let day = month[date.date()]
            if (day) {
                if (doorId) {
                    return day.filter(isOfDoorById)
                } else {
                    return day
                }
            }
        }
        return []
    }

    const monthCellRender = (value) => {

    };

    const dateCellRender = (value) => {
        const events = getEvents(value)
        if (events) {
            return (
                <ul className="events">
                    {events.map((item) => (
                        <li key={item.content}>
                            <Badge status={item.type} text={item.content} />
                        </li>
                    ))}
                </ul>
            );
        }
        return
    };

    return (
        <>
            <ContentPageHeader title={title} />
            <Calendar dateCellRender={dateCellRender} monthCellRender={monthCellRender} />
        </>
    );
}

export default EventCalendar;