import country from 'country-list-js';

/**
 * Utils to get data from the web
 */
export class Utils {
  async getCountries() {
    var country_names = country.ls('name');

    let list = []
    for (let i = 0; i < country_names.length; i++) {
      list.push(country_names[i])
    }

    return list;
  }

  async getStates(countryName) {
    var countryData = country.findByName(countryName);
    
    let list = []
    // sometimes a country does not have provinces
    if(countryData?.provinces) {
      for (let i = 0; i < countryData.provinces.length; i++) {
        list.push(countryData.provinces[i].name)
      }
    }
    else {
      list.push(countryName)
    }


    return list;
  }
}