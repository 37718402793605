import { Tabs } from "antd";
import Alarms from "../AlarmSystem/Alarms/Alarms";
import Notification from "../AlarmSystem/Notifications/Notifications";
import { useState, useEffect } from "react";
import { Map } from "../../components/Map/map.component";
import { ENV } from '../../utils';

import StaTab from "./StaTab/StaTab";

function Sta() {
  let [tabValue, setTabValue] = useState(1)
  let [dataMap,setDataMap] = useState([])


  let changeTab = (item)=>{setTabValue(item)}

  useEffect(() => {
    fetch(`${ENV.Sta_API}sta`)
        .then(response => response.json())
        .then(data => setDataMap(data.result))
  },[])


    const items= [
        {key: 0,label: 'MAP',children:<Map items={dataMap}/>},
        {key: 1,label: 'STA',children: <StaTab/>},
        {key: 2,label: 'Alarms',children: <Alarms  hardware={["stas"]}/>,},
        {key: 3,label: 'Notifications',children: <Notification hardware={["sta"]}/>,},
        
      ];
    return( <Tabs activeKey={tabValue} items={items} onChange={changeTab}/> )
}
export default Sta