/**
 * Nombre fichero: MapMenu.js
 * Fecha: 14/07/2022
 * 
 * Descripcion: Componente menu lateral izquierdo
 * 
 * Fecha de moficiacion: 14/07/2022
 */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from "react-router-dom"
// Estilos
import styles from './MapMenu.module.css'
import MenuItem from './MenuItem/MenuItem.component';
import $ from 'jquery';

const MapMenu = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const pathName = useLocation().pathname;

    const [activated, setActivated] = useState([false, false, false, false, false, false, false, false, false])

    const hide = () => {
        let width = window.screen.width;
        if (width < 750)
            $('#MapMenu').children().eq(0).css({ left: '-20rem', width: 'auto' })
    }

    return (
        <div id="MapMenu ">
            <div className={styles.mainContainermapmenu}>
                <header>
                    <h5>Filter</h5>
                </header>
                <MenuItem title={"Sensors Device"} icon='bi bi-file-earmark-image-fill'
                    setActivated={() => { activated[1] = !activated[1]; setActivated(activated); }}
                    linkActivated={activated[1] }
                />
                <MenuItem title={"Gateway Device"} icon='bi bi-book-half'
                    setActivated={() => { activated[2] = !activated[2]; setActivated(activated); }}
                    linkActivated={activated[2] }
                />
                <MenuItem title={"Advanced Search"} icon=''/>
            </div>
        </div>
    );
}

export default MapMenu;
