import React, { useState } from "react";

import { CodeOutlined } from '@ant-design/icons'
import Modal from "antd/es/modal/Modal";

import { useTranslation } from "react-i18next";

/**
 * 
 * @param {*} param0 
 * @returns html of a column element
 */
const CMDButton = ({ route, form, attributes, element }) => {

    const { t } = useTranslation();

    // Edit an element modal view
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    function openEditModal() {
        setIsEditModalOpen(true);
    };

    function handleEditCancel() {
        setIsEditModalOpen(false);
    };
    // -------------------------------

    return (
        <>
            <CodeOutlined style={{ "fontSize": "large", "color": "#A6CF98" }} onClick={openEditModal} />
            <Modal footer={null} title={t("Cmd menu")} open={isEditModalOpen} onCancel={handleEditCancel}>
                <div>
                    comandos a enviar
                </div>
                {/*
                <CommonForm route={route} element={element} attributes={attributes} />
                */}
            </Modal>
        </>
    )

};

export default CMDButton;
