import React, {useEffect, useState} from 'react';
import { Flex, Select,Spin,Row } from 'antd';
import { ENV } from "../../../../../utils/constants.js"


function SensorPlatform({ sensorData, setSensorData}) {
    const [serverList, setServerList] = useState([]);
    const [loadingServers, setLoadingServers] = useState(true);
    const boxStyle= {
        width: '100%',
        height: '100%',
        borderRadius: 6,
    };
    const handleChange = (value) => {
        setSensorData({...sensorData, server:value});
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
            const response = await fetch(
                `${ENV.Infraestructure_API}/${sensorData.mac}/decoder/servers`, 
                {method: 'GET'}
            )
    
            if (!response.ok) {
                throw new Error(`Error al realizar la solicitud: ${response.status}`);
            }
    
            let data = await response.json()
            data = data.map((item)=>{return {value: item.pkId, label: item.serverName}})
            setServerList(data)
            setLoadingServers(false)
            } catch (error) {
                console.error(error.message);
            }
        };
        fetchData()
    }, [])
    return(
        <Flex  style={boxStyle} justify={"center"} align={"center"} >
            <Spin spinning={loadingServers}  size="large" style={{ width: "100%" }}>
                <Row style={{ width: "100%" }}>
                <div>Select Server:</div>
                <Select
                    mode="multiple"
                    style={{ width: "300px" }}
                    onChange={handleChange}
                    value={sensorData.server}
                    options={serverList}
                    />
                </Row>
            </Spin>
        </Flex>
    )
}
export default SensorPlatform
