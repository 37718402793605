import React from 'react';
import { useState } from 'react';
import {  useAuth } from '../Hooks';
import { Card, Col, Divider, Row, Select, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Text, Title, Paragraph, Link } = Typography

const MyProfile = () => {
  const { user, isAuthenticated, logout } = useAuth();
  const [profileImage, setProfileImage] = useState(`https://robohash.org/${Math.floor(Math.random() * 100)}.png?size=150x150`);
  // console.log("MyProfile")
  // console.log(user)

  const { t } = useTranslation();

  const councils = [
    {
      value: "valencia",
      label: "Valencia"
    },
    {
      value: "paterna",
      label: "Paterna"
    }
  ]
  // console.log(user)
  return (
    <div style={{ padding:"25px"}}>
      
            <Title level={2}>{t('MyProfile')}</Title>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col style={{ padding: 8 }} xl={12} span={24}>
          <Card>
            <Title>{user.name}</Title>
            <Link className="nav-link" onClick={logout}>
              Logout
            </Link>
            <Paragraph>
              <Text strong>{t('First Name')}: </Text><Text>{user.name}</Text>
            </Paragraph>
            <Paragraph>
              <Text strong>{t('Last Name')}: </Text><Text>{user.surname}</Text>
            </Paragraph>

            <Divider dashed />

            <Text strong>{t('Address')}: </Text>
            <Text>
              <Tooltip title={t('Country')}>{user.country}</Tooltip> /
              <Tooltip title={t('State')}>{user.state}</Tooltip> /
              <Tooltip title={t('City')}>{user.locality}</Tooltip> /
              <Tooltip title={t('Street')}>{user.street}</Tooltip>
            </Text>
            <Paragraph>
              <Text strong>{t('Postal Code')}: </Text><Text>{user.postalcode}</Text>
            </Paragraph>

            <Divider dashed />

            <Paragraph>
              <Text strong>{t('Phone')}: </Text><Text>{user.phone}</Text>
            </Paragraph>
          </Card>
        </Col>

        <Col style={{ padding: 8 }} xl={12} span={24}>
          <Card>
            <Title level={2}>{t('Council')}</Title>
            <Select
              mode='multiple'
              style={{
                width: "100%",
              }}
              options={councils}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col style={{ padding: 8 }} xl={12} span={24}>
          <Card>
            <Title level={2}>{t('Business')}</Title>
            <Select
              mode='multiple'
              style={{
                width: "100%",
              }}
              options={councils}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default MyProfile;
