import { Breadcrumb, Button, Col, Modal, Row, Space, Tooltip, Typography, theme } from "antd";
import { PlusOutlined } from '@ant-design/icons'
import { useTranslation } from "react-i18next";
import { useBreadcrumbs, useBreadcrumbsUpdate } from "../../Hooks/useBreadcrumbs";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useToken } from "antd/es/theme/internal";
import './contentPageHeader.component.css'
import CommonForm from "../UpdateItemForm/CommonForm";

const {Title} = Typography

function ContentPageHeader({attributes, canAdd, submitButton, title, reload, additionalButtons, route, showBreadCrumbs,showColor, disabledAddButton}) {

    const { t } = useTranslation();
    const token = useToken();
    const location = useLocation();

    const breadcrumbItems = useBreadcrumbs();
    const updateBreadcrumbs = useBreadcrumbsUpdate()


    // Add an element modal view
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [groupName, setGroupName] = useState("")

    function openAddModal() {
        setIsAddModalOpen(true);
        reload()
    };

    function handleAddCancel() {
        setIsAddModalOpen(false);
        reload()
    };
    // -------------------------------

    // Update breadcrumbs
    useEffect(() => {
        updateBreadcrumbs(location)
    }, [location]);

    // Reload the bar color group when the breadcrumbs change
    useEffect(() => {
        if(breadcrumbItems[1]) {
            var currentRoute = breadcrumbItems[1].key
            var name = currentRoute.split("/")[2]
        setGroupName(name)
        }
    }, [breadcrumbItems])

    return (
        <>
            <Row align="middle" justify="space-between">
                <Col>
                {
                    showBreadCrumbs ? 
                    <Breadcrumb>{breadcrumbItems}</Breadcrumb>
                : <></>
                }
                    <Title style={{ "color": token.colorText }}>{t(title)}</Title>
                </Col>
                <Col>
                    <Space>
                        {additionalButtons}
                        {canAdd &&
                            <Tooltip title={t('New') + " " + t(title)}>
                                <Button type="primary" disabled={disabledAddButton ? disabledAddButton : false} className="buttonCentered" icon={<PlusOutlined />} onClick={openAddModal} />
                            </Tooltip>
                        }
                    </Space>
                </Col>
            </Row>
            {
            (showColor === false) ? <></> :
            <div style={{width: "100%", height: "5px", border: "none", marginBottom: "15px"}} className={groupName.toLowerCase()}/>
            }

            <Modal footer={null} title={t('Add Element')} open={isAddModalOpen} onCancel={handleAddCancel}>
                <CommonForm attributes={attributes} submitButton={submitButton} route={route} modalClose={handleAddCancel}/>
            </Modal>
        </>
    );
}

export default ContentPageHeader;