import { Form, Select, Typography } from "antd";
import { useEffect, useState } from "react";
import {useTranslation} from 'react-i18next'
import { Utils } from '../../API/utils';

const {Text} = Typography

function CountryStateSelector(props) {

    const element = props.element
    const form = props.form

    const utils = new Utils()
    const {t} = useTranslation();

    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [selectedState, setSelectedState] = useState("Andorra")

    /**
     * Creates options for all select element with the required input and then creates the Form
     */
    const generateFormOptions = () => {
        utils.getCountries().then((value) => {
            setCountries(value)
            // console.log("Countries created.")
        })
        if (element) {
            utils.getStates(element["country"]).then((value) => {
                setStates(value)
                // console.log("States created.")
                setSelectedState(states[0])
            })
        }
    }

    /**
     * Loads states for the selected country
     * @param {String} countryName 
     */
    const handleCountryChange = (countryName) => {
        form.setFieldValue("country", countryName)
        utils.getStates(countryName).then((value) => {
            setStates(value)
            // Should reset the selected state
            setSelectedState(value[0])
        })
    }

    const onStateChange = (value) => {
        form.setFieldValue("state", value)
        setSelectedState(value);
        // console.log("State changed: " + value)
    };

    useEffect(() => {
        setSelectedState(states[0])
        // console.log("Updated state to: " + selectedState)
    }, [states])

    useEffect(() => {
        generateFormOptions()
    }, [])

    return (
        <>
            <Form.Item
                key={"country"}
                label={t("Country")}
                name={"country"}
                rules={[
                    {
                        required: true
                    }
                ]}
            >
                <>
                    <Select
                        showSearch
                        style={{
                            width: 200,
                        }}
                        placeholder={t("Search to Select")}
                        onChange={handleCountryChange}
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={countries.map((country) => ({
                            label: country,
                            value: country
                        }))}
                        defaultValue={element?.country}
                    />
                    <Text>{element?.country}</Text>
                </>
            </Form.Item>

            <Form.Item
                key={"state"}
                label={t("State")}
                name={"state"}
                rules={[
                    {

                    },
                ]}
            >
                <>
                    <Select
                        showSearch
                        style={{
                            width: 200,
                        }}
                        placeholder={t("Search to Select")}
                        onChange={onStateChange}
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={states.map((state) => ({
                            label: state,
                            value: state
                        }))}
                        defaultValue={element?.state}
                    />
                    <Text>{element?.state}</Text>
                </>
            </Form.Item>
        </>
    );
}

export default CountryStateSelector