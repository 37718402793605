import { createContext, useState } from "react"

export const AttributesContext = createContext([]);
export const AttributesUpdateContext = createContext(() => null);
export const DataContext = createContext([]);
export const DataUpdateContext = createContext(() => null);

export function TableDataProvider({ children }) {
    const [currentAttributes, setAttributes] = useState([])
    const [currentData, setData] = useState([])


    function changeAttributes(newArray) {
        setAttributes(newArray)
    }

    function changeData(newArray) {
        setData(newArray)
    }

    return (
        <DataContext.Provider value={currentData}>
            <DataUpdateContext.Provider value={changeData}>
                <AttributesContext.Provider value={currentAttributes}>
                    <AttributesUpdateContext.Provider value={changeAttributes}>
                        {children}
                    </AttributesUpdateContext.Provider>
                </AttributesContext.Provider>
            </DataUpdateContext.Provider>
        </DataContext.Provider>
    )
}