export const columns = [
    {
      title: 'Property',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    }
  ];

  
  export const checkData = (data) => {
  const new_data = data ;
  let dataTable = []
  let index = 0;
  for (const key in new_data) {
      if (new_data.hasOwnProperty(key)) {
        const value = new_data[key];
        dataTable.push({key: index, name: key, value: value})
        index++;
      }
    }
    return dataTable;
  }
