import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, Select, Typography } from "antd"
import { BasicApi } from "../../API/basicApi"
import { ENV } from "../../utils"

const { Text } = Typography

function EnumSelector(props) {

    const element = props.element
    const route = props.route
    const title = props.title
    const form = props.form
    const dataName = props.dataName

    const { t } = useTranslation()
    const api = new BasicApi(route)

    const [data, setData] = useState([])

    const onValueChange = (value) => {
        // console.log(dataName + ": " + value)
        form.setFieldValue(dataName, value)
    }

    /**
     * Creates options for all select element with the required input and then creates the Form
     */
    const generateFormOptions = () => {
        // Get all data for selector
        api.getInfraestructureEnums().then((value) => {
            setData(value[dataName])
            // console.log(dataName + " loaded 👌")
        })
    }

    useEffect(() => {
        generateFormOptions()
    }, [])

    return (
        <Form.Item
            key={dataName}
            label={t(title)}
            name={dataName}
            rules={[
                {
                    type: "string",
                    required: true
                },
            ]}
        >
            <>
                <Select
                    showSearch
                    style={{
                        width: 200,
                    }}
                    placeholder={t("Search to Select")}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={data.map((value) => ({
                        label: value,
                        value: value
                    }))}
                    onChange={onValueChange}
                    defaultValue={element[dataName]}
                />
                <Text>{element[dataName]}</Text>
            </>
        </Form.Item>
    );
}

export default EnumSelector;