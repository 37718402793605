import { Link } from 'react-router-dom'
import { useAuth } from "../../Hooks/index";
import { BellFilled, UserOutlined, SearchOutlined, MenuOutlined, CloseOutlined } from "@ant-design/icons"
import { Row, Space, Col, theme, Select, Input, Tooltip, Avatar, Badge, Switch, Button, Modal } from 'antd';
import { useState } from 'react';
import { useLocaleUpdate } from '../../Hooks/useLocale';
import { useThemeUpdate } from "../../Hooks/useTheme";
import { useTranslation } from 'react-i18next';
import styles from './NavBar.module.css'
import NotificationsDrawer from "../NotificationsDrawer/index"
import logo from '../../assets/logo.png';

const { useToken } = theme
const { Search } = Input

function NavBar({ collapsedFunction, collapsed }) {
  const { isAuthenticated, logout, user } = useAuth();
  const switchTheme = useThemeUpdate();

  const changeLanguage = useLocaleUpdate()
  const { t, i18n } = useTranslation()

  const { token } = useToken()

  const handleSwitchTheme = () => {
    switchTheme()
  }

  // Language change
  const handleLangChange = (value) => {
    changeLanguage(value)
    i18n.changeLanguage(value)
  };

  // search in the navbar
  const onSearch = (value) =>  console.log(value);

  // Notifications Drawer
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  // TO see the current user
  const getUser = () => {
    // console.log(user)
  }

  return (
    <>
      <div className={styles.content}>
        <Row justify={'space-between'}>
          <Col flex={1} style={{ display: "flex", justifyContent: "flex-start" }}>
            <Space size={23} align={'center'}>

              {collapsed ?
              <MenuOutlined style={{ "color": token.colorText, "fontSize": "large" }} onClick={collapsedFunction} />
              :
              <CloseOutlined style={{ "color": token.colorText, "fontSize": "large" }} onClick={collapsedFunction} />
              }
              <Link className={styles.logo} to={'/home'}>
                <img src={logo} width="150" alt="LOGO GESINEN" />
              </Link>

            </Space>
          </Col>
          <Col flex={1} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Space size={23}>
            🌙
              <Switch
                checkedChildren={"Dark"}
                unCheckedChildren={"Light"}
                defaultChecked={false}
                onChange={handleSwitchTheme}
              />
              <Select
                defaultValue="English"
                style={{
                  width: 120,
                }}
                onChange={handleLangChange}
                options={[
                  {
                    value: 'en_US',
                    label: 'English',
                  },
                  {
                    value: 'es_ES',
                    label: 'Español',
                  }
                ]}
              />
              {
                isAuthenticated && new Date(user.exp*1000)>new Date()?
                  <>
                    <Tooltip title={<Search
                      placeholder="input search text"
                      allowClear
                      enterButton={t("Search")}
                      size="large"
                      onSearch={onSearch}
                    />}>
                      <SearchOutlined style={{ "color": token.colorText, "fontSize": "large" }} />
                    </Tooltip>
                    <Badge count={1} overflowCount={10}>
                      <BellFilled onClick={showDrawer} style={{ "color": token.colorText, "fontSize": "large" }} />
                    </Badge>
                    <NotificationsDrawer onClose={onClose} open={open} notifications={[
                      {
                        title: "Notification 1",
                        description: "sgadfgasdg"
                      },
                      {
                        title: "Notification 1",
                        description: "463525734747"
                      },
                      {
                        title: "Notification 636",
                        description: "463475685hgffbsdfg"
                      }
                    ]} />
                    <Link className="nav-link" to={'myuser'}>
                      <Avatar onClick={getUser} size="large" icon={<UserOutlined style={{ "color": token.colorText }} />} />
                    </Link>
                  </>
                  :
                  <Link className="nav-link" to={'/sign-in'}>
                    Login
                  </Link>
              }
            </Space>
          </Col>
        </Row>
      </div>
    </>
  )
}
export default NavBar