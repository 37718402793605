import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, Select, Typography } from "antd"
import { BasicApi } from "../../API/basicApi"
import { ENV } from "../../utils"
import { useLocation } from "react-router-dom";
import { useAuth } from "../../Hooks"

const { Text } = Typography

function ApplicationSelector(props) {

    const element = props.element
    const dataName = props.dataName
    const title = props.title
    const form = props.form
    const location = useLocation()

    const { t } = useTranslation()
    const gatewayMac = location.pathname.split("/")[4]
    const thisOrganization = location.pathname.split("/")[6]
    const applications = new BasicApi(`${ENV.Infraestructure_API}/${gatewayMac}/${ENV.API_ROUTES.APPLICATIONS}`)
    const { accessToken, user } = useAuth()

    const [data, setData] = useState([])

    const onValueChange = (value) => {
        // console.log(dataName + ": " + value)
        form.setFieldValue(dataName, value)
    }

    /**
     * Creates options for all select element with the required input and then creates the Form
     */
    const generateFormOptions = () => {
        // Get all data for selector
        applications.getAll(accessToken).then((_applications) => {
            // console.log("APPLICATIONS:  " , _applications)
            // console.log("YEEEE " , thisOrganization)
            const filteredApplications = _applications.db.result.filter(application => {
                if (application.organizationID === thisOrganization && application.topic===gatewayMac) {
                    return true; 
                }
                return false; 
            });
            
            setData(filteredApplications)
            // console.log(dataName + " loaded 👌")
        })
    }

    useEffect(() => {
        generateFormOptions()
    }, [])

    return (
        <Form.Item
            key={dataName}
            label={t(title)}
            name={dataName}
            rules={[
                {
                    required: true
                }
            ]}
        >
            <>
                <Select
                    showSearch
                    style={{
                        width: 200,
                    }}
                    placeholder={t("Search to Select")}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={data.map((application) => (
                        {
                            label: application.name,
                            value: application.id
                        }))}
                    onChange={onValueChange}
                    defaultValue={element[dataName]}
                />
                <Text>{element[dataName]}</Text>
            </>
        </Form.Item>
    );
}

export default ApplicationSelector;