import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, Select, Typography } from "antd"
import { BasicApi } from "../../API/basicApi.js"
import { ENV } from "../../utils/index.js"
import { useAuth } from "../../Hooks/useAuth.js"

const { Text } = Typography

function PhisicalGatewaysTableSelector(props) {
    const { accessToken, user } = useAuth()

    const element = props.element
    const dataName = props.dataName
    const title = props.title
    const form = props.form

    const { t } = useTranslation()
    const phisical_gateways = new BasicApi(ENV.Infraestructure_API + "/" + ENV.API_ROUTES.PHISICAL_GATEWAY)

    // TODO: Cambiar esto por una llamada a todos los NS del gateway a través de api al server
    const [data, setData] = useState([])

    const onValueChange = (value) => {
        // console.log(dataName + ": " + value)
        form.setFieldValue(dataName, value)
    }

    const generateFormOptions = () => {
        // Get all data for selector
        let result = []
        phisical_gateways.getAll(accessToken).then((value) => {
            if (value) {
                value.db.result.map((element) => {
                    result.push({
                        name: element.name,
                        mac: element.mac
                    })
                })
            }
            setData(result)
            console.log(" RECEIVED :: Result: ", value)

            // console.log(dataName + " loaded 👌")
        })
    }

    useEffect(() => {
        generateFormOptions()
    }, [])

    return (
        <Form.Item
            key={dataName}
            label={t(title)}
            name={dataName}
            rules={[
                {
                    required: true
                }
            ]}
        >
            <>
                <Select
                    showSearch
                    style={{
                        width: 200,
                    }}
                    placeholder={t("Search to Select")}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={data.map((phisical_gateway) => ({
                        label: phisical_gateway.name,
                        value: phisical_gateway.mac
                    }))}
                    onChange={onValueChange}
                    defaultValue={element[dataName]}

                />
                <Text>{element[dataName]}</Text>
            </>
        </Form.Item>
    );
}

export default PhisicalGatewaysTableSelector;