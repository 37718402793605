import React, { useState, useEffect } from 'react';
import { Table} from "antd";
import { Button, Flex, Spin } from 'antd';
import ModalAddSensor from './ModalAddSensor/ModalAddSensor';
import { ENV } from "../../../utils/constants.js"



function TabSensor({mac}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataSource,setDataSource] = useState([]);
  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Device EUI',
      dataIndex: 'loraAddress',
      key: 'loraAddress',
    },
    {
      title: 'Name',
      dataIndex: 'sensorModelName',
      key: 'sensorModelName',
    },
    {
      title: 'Sensor Type',
      dataIndex: 'componentName',
      key: 'componentName',
    },
  ]
  const [loading, setLoading] = useState(true);


  const showModal = () => {
    setIsModalOpen(true);
  };
  
  useEffect(() => {
    const fetchData = async () => {
        try {
        const response = await fetch(
            `${ENV.Infraestructure_API}/${mac}/decoder/sensors`, 
            {method: 'GET'}
        )
        setLoading(false)
        if (!response.ok) {
            throw new Error(`Error al realizar la solicitud: ${response.status}`);
        }

        let data = await response.json()
        setDataSource(data.oemsensors)
        } catch (error) {
            console.error(error.message);
        }
    };
    fetchData()
}, [])
  return (
    <>
    <Spin spinning={loading}  size="large">
      <Table dataSource={dataSource} columns={columns}     scroll={{y: 300}}/>
    </Spin>

      <Flex gap="small" wrap="wrap"  justify="flex-end" >
        <Button type="secondary" >Save</Button>
        <Button type="secondary" >Get from Decoder</Button>
        <Button type="primary" onClick={showModal}>Add Sensor</Button>
        <Button type="secondary" >Update Sentilo</Button>
      </Flex>
    <ModalAddSensor mac={mac} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      
    </>
  );



}
export default TabSensor

