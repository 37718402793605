import { theme } from "antd";

import { Button, Col, Row, Select, Table } from "antd"
import { useState, useEffect } from "react"
import { PlusOutlined,DeleteOutlined } from '@ant-design/icons'
import { ENV } from "../../../utils";
import { useAuth } from "../../../Hooks/useAuth";

const { useToken } = theme

function Notifications({url=`${ENV.Alarms_API}notifications`,hardware}) {
    const [dataSource, setDataSource] = useState([]);
    const { accessToken } = useAuth()
    const { token } = useToken()
    let status_options = [
        { value: "GENERATED", label: "GENERATED", /*disabled:true*/ },
        { value: "IN_PROCESS", label: "IN_PROCESS" },
        { value: "SOLVED", label: "SOLVED" },
        { value: "CANCELLED", label: "CANCELLED" }
    ]
    let deleteNotification = async(id)=>{
        fetch(`${url}/${id}`, {
            method: "DELETE",
            headers: {"x-access-token": accessToken}}).then((response) => {
            if (!response.ok) {
                throw new Error(`Error al realizar la solicitud: ${response.status}`);
            }
            refreshData()
        })
    }
    let changeState = async(new_value,index )=>{
        fetch(`${url}/${dataSource[index].id}`, {
            method: "PUT",
            headers: {"x-access-token": accessToken,
            "Content-Type": "application/json"},
            body: JSON.stringify({status: new_value})
        }).then((response) => {
            if (!response.ok) {
                throw new Error(`Error al realizar la solicitud: ${response.status}`);
            }
            refreshData()
        }).catch((error) => {
            console.error('Error:', error);
        })
    }
    const refreshData = async() => {
        fetch(`${url}?type=${hardware.map((item)=>item.toUpperCase()).join(",")}`,{method: "GET",headers: {"x-access-token":accessToken }})
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Error al realizar la solicitud: ${response.status}`);
          }
          return response.json()
        })
        .then((data) => {
            data.result.map((item,index)=>{data.result[index].key = item.id})
            data.result.map((item,index)=>{data.result[index].alarm = item.alarm.name})
            setDataSource(data.result)
        })
        .catch((error) => {
          console.error('Error:', error);
        })
    }
    useEffect(() => {
        refreshData()
    }, [])
    return (
        <>
        
        <Row align="middle" justify="space-between">
        <Col></Col>
        <Col style={{padding:"1rem"}}>
        <Button type="primary" disabled={true}  className="buttonCentered" icon={<PlusOutlined />} />
        
        </Col>
      </Row>
        <Table 
        rowKey="id"
        dataSource={dataSource}
        >
            <Table.Column title="id" dataIndex="id" key="id" />
            <Table.Column title="alarm" dataIndex="alarm" key="alarm" />
            <Table.Column title="hardware_id" dataIndex="hardware_id" key="hardware_id" />
            <Table.Column title="hardware_type" dataIndex="hardware_type" key="hardware_type" />
            <Table.Column title="status" dataIndex="status" key="status" 
            render={(text, record,index) => (
                <>
                 <Select
                    style={{ width: 120 }}
                    onChange={(event)=>changeState(event,index)}
                    value={record.status}
                    options={status_options}
                    />
                </>
            )}
            />
            <Table.Column title="actions" dataIndex="actions" key="actions" 
            render={(text, record,index) => (
                <>
                <DeleteOutlined  onClick={()=>{deleteNotification(record.id)} } style={{ "fontSize": "large", "color": token.colorError }} />
                </>
            )}
            />
        </Table>
        </>
    )
}
export default Notifications
