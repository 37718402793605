import { Modal } from "antd";
import React, { useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { ENV } from '../../../../../utils';

export function ModalUpdateFirmware({modalOpen, setModalOpen,callback}) {
    let [delimiter, setDelimiter] = useState(",")
    let [serverFileName, setServerFileName] = useState("")

    
    const { Dragger } = Upload;
    const sendData = async () => {
        const response = await fetch(`${ENV.Sta_API}sta/process_csv`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({delimiter: delimiter, file_name: serverFileName}),
        });
        const data = await response.json();
        setModalOpen(false)
        callback()
    }

    const propsUpload  = {
        name: 'csv_file',
        action: `${ENV.Sta_API}sta/upload`,
        multiple: false,
        ShowUploadList: false,
        maxCount:1,
        beforeUpload:(file) => {
            if (file.type !== 'text/csv') message.error('You can only upload CSV file!');
            return file.type === 'text/csv' ? true : Upload.LIST_IGNORE;
          },
        onChange:(info) => {
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            setServerFileName(info.file.response.filename)
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      };

    return (
        <Modal
            open={modalOpen}
            onCancel={()=>setModalOpen(false)}
            title="Update Firmware"
            //onOk={}
        >
            <Dragger {...propsUpload}  >
                  <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                  <p className="ant-upload-text">upload the firmware</p>
              </Dragger>

            
            {/*
             <Row >
                <Col> <p>Delimiter</p></Col>
                <Col>
                  <Input 
                        style={{width:"100px"}} type="string" value={delimiter}
                        onChange={(e)=>setDelimiter(e.target.value)}
                  />
                </Col>
            </Row>
            */}
            
        </Modal>
    )
}