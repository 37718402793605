import React, { useState } from 'react';
import { Space, Table, theme, Tooltip, Button, DatePicker, Input, Spin, Typography, message } from "antd";
import { useTranslation } from "react-i18next";

const { useToken } = theme;

function FormSendMessage() {
  const { token } = useToken();
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleCheckboxChange = () => {
    setIsConfirmed(!isConfirmed);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div style={{ alignItems: 'center' }}>
          <label style={{ "color": token.colorText }} htmlFor="inputField">{t("Base64 Message")}</label>
          <input
            type="text"
            placeholder='Type Message In Base64'
            id="inputField"
            value={inputValue}
            style={{ width: '100%', margin: "10px 0 20px 0" }}
            onChange={handleInputChange}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <label style={{ "color": token.colorText }} htmlFor="confirmedCheckbox">Confirmed</label>
          <Input
            type="checkbox"
            id="confirmedCheckbox"
            checked={isConfirmed}
            style={{ width: 'auto', marginLeft: "20px" }}
            onChange={handleCheckboxChange}
          />
        </div>
        <Button
          style={{ margin: "20px" }}
          type="primary" >
          Submit
        </Button>
      </form>
    </div>
  );
}

export default FormSendMessage;
