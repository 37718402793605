import React, { useState } from "react";

import { theme, Tooltip } from "antd";
import { ApartmentOutlined } from '@ant-design/icons'
import Modal from "antd/es/modal/Modal";

import CommonForm from '../../UpdateItemForm/CommonForm'
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

const { useToken } = theme;

const RedirectButton = ({ route, element,alt }) => {

    const { token } = useToken();
    const navigate = useNavigate();

    const RedirectFunction = () => {
        // console.log("RedirectButton",route)
        navigate(route);
    }
    return (
        <>
            <ApartmentOutlined alt={alt}style={{ "fontSize": "large", "color": token.colorPrimary }} onClick={RedirectFunction} />
        </>
    )
};

export default RedirectButton;
