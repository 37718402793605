import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, Select, Typography } from "antd"
import { BasicApi } from "../../API/basicApi"
import { ENV } from "../../utils"
import { useAtom } from 'jotai'
//import { enableGroupAtom } from "../../utils/tables/streetlight"
import {enableInputGroupAtom} from '../../components/UpdateItemForm/CommonForm.jsx'
const { Text } = Typography

function EnumSelectorAbstract(props) {
    const options = props.options
    const element = props.element
    const route = props.route
    const title = props.title
    const form = props.form
    const dataName = props.dataName
    const { t } = useTranslation()
    const api = new BasicApi(route)
    const [data, setData] = useState([])
    const callback = props.callback



    const [enableInputGroup,setEnableInputGroup] = useAtom(enableInputGroupAtom);
    
    const onValueChange = (value) => {
        // console.log(dataName + ": " + value)
        form.setFieldValue(dataName, value)


        if(props.disabledVarMaxValue){
            let enableInputGroup_tmp;
            enableInputGroup_tmp  = JSON.parse(JSON.stringify(enableInputGroup))
            // console.log(enableInputGroup_tmp)
            if(!Object.keys(enableInputGroup_tmp).includes(props.disabledVarName)){
                enableInputGroup_tmp[props.disabledVarName]=1
            }else{
                if (enableInputGroup[props.disabledVarName] >=props.disabledVarMaxValue){
                    enableInputGroup_tmp[props.disabledVarName] = 0
                }else{
                    enableInputGroup_tmp[props.disabledVarName] +=1
                }
            }
            setEnableInputGroup(enableInputGroup_tmp)
        }
    }

    
    //const [disableInputVariable, setdisableInputVariable] = useAtom("streetLightInputDisable")

    return (
        <Form.Item
            key={dataName}
            label={t(title)}
            name={dataName}
            rules={[
                {
                    required: props.required,
                },
            ]}
        >
            <>
                <Select
                    disabled={props.disabledTargetValue === enableInputGroup[props.disabledVarName]}
                    showSearch
                    style={{
                        width: 200,
                    }}
                    placeholder={t("Search to Select")}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    defaultValue={{value:element[dataName],label:element[dataName]}}
                    options={options.map((item) => ({
                        label: item.label,
                        value: item.value
                    }))}
                    onChange={onValueChange}
                />
            </>
        </Form.Item>
    );
}

export default EnumSelectorAbstract;