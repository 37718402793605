import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, Select, Typography } from "antd"
import { BasicApi } from "../../API/basicApi.js"
import { ENV } from "../../utils/index.js"
import { useAuth } from "../../Hooks/useAuth.js"
import { useLocation } from "react-router-dom";

const { Text } = Typography

function ServiceProfileSelector(props) {
    const { accessToken } = useAuth()

    const element = props.element
    const dataName = props.dataName
    const title = props.title
    const form = props.form
    const location = useLocation()

    const { t } = useTranslation()
    const gatewayMac = location.pathname.split("/")[4]

    const serviceProfileApi = new BasicApi(`${ENV.Infraestructure_API}/${gatewayMac}/service-profiles`)

    const [serviceProfile, setServiceProfile] = useState([])

    const onValueChange = (value) => {
        // console.log(dataName + ": " + value)
        form.setFieldValue(dataName, value)
    }

    /**
     * Creates options for all select element with the required input and then creates the Form
     */
    const generateFormOptions = () => {
        // Get all users for selector
        serviceProfileApi.getAll(accessToken).then((value) => {
            const organizationSelected = location.pathname.split("/")[6]
            const data = value.db.result.filter(
                (item => item.topic === gatewayMac  && item.organizationID === organizationSelected)
            );

            setServiceProfile(data)
            // console.log(serviceProfile, "serviceProfile loaded 👌")
        })
    }

    useEffect(() => {
        generateFormOptions()
    }, [])

    return (
        <Form.Item
            key={dataName}
            label={t(title)}
            name={dataName}
            rules={ [
                {
                    required: props.required
                }] }
        >
            <>
                <Select
                    showSearch
                    style={{
                        width: 200,
                    }}
                    placeholder={t("Search to Select")}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={serviceProfile.map((item) => ({
                        label: item.name,
                        value: item.id
                    }))}
                    onChange={onValueChange}
                    defaultValue={element[dataName]}
                />
                <Text>{element[dataName]}</Text>
            </>
        </Form.Item>
    );
}

export default ServiceProfileSelector;