function Divisor(props) {


    return ( 
        <div style={{
            textAlign:'center',
            background:'gray',
            width:"80%",
            borderRadius:"25px",
            margin:"auto",
            height:"20px",
            marginTop:"10px",
            marginBottom:"10px",
            }}>
            
        </div>
     );
}

export default Divisor;