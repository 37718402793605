import { createContext, useContext, useEffect, useState } from 'react';
import jwtDecode from "jwt-decode";
import { jwt, Auth } from "../../API";
import { ContinuousColorLegend } from 'react-vis';
const authApi = new Auth();

export const AuthContext = createContext({
  isAuthenticated: null,
  setIsAuthenticated: null,
  accessToken: null,
  user: null,
  login: () => null,
  logout: () => null,
  getUser: () => null
});

export function AuthProvider({ children }) {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);


  useEffect(() => {
    // Verificar si el usuario
    // está autenticado y obtener información del usuario

    const fetchUser = async () => {
      try {

        const response = jwt.getTokens();
        // console.log("fetchUser ", response);
        const user = await jwtDecode(response.accessToken);
        // console.log("CONTEXT AUTH USER: ",user);
        setUser(user);
        setIsAuthenticated(true);
        setAccessToken(response.accessToken);
      } catch (error) {
        setIsAuthenticated(false);
      }
    };
    fetchUser();
  }, []);

  const getUser  = async () =>{
    const user = await jwtDecode(accessToken);
        // console.log("CONTEXT AUTH USER: ",user);
        setUser(user);
  } 
  const login = async (data) => {
    // API para iniciar sesión
    try {
      const response = await authApi.login(data);
      setAccessToken(response.accessToken);
      const decodeToken = await jwtDecode(response.accessToken);
      setUser(decodeToken.user)
      jwt.saveTokens(response.accessToken);
      return true
    } catch (error) {
      setIsAuthenticated(false);
      // console.log(error)
      throw error
    }
  };

  const logout = () => {

    // Aquí puedes llamar a una API para cerrar sesión
    setUser(null);
    setIsAuthenticated(false);
    setAccessToken(null);
    jwt.removeTokens();
  };
  const data = {
    isAuthenticated,
    setIsAuthenticated,
    accessToken,
    user,
    logout,
    login,
    getUser
  };
  return (
    <AuthContext.Provider
      value={data}
    >
      {children}
    </AuthContext.Provider>
  );
}