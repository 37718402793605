import { Form, Switch } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { enableInputGroupAtom } from '../../components/UpdateItemForm/CommonForm.jsx';

function BooleanInputFalseDefault(props) {

    const { t } = useTranslation();
    const dataName = props.dataName

    const title = props.title;
    const [enableInputGroup, setEnableInputGroup] = useAtom(enableInputGroupAtom);

    const [checked, setChecked] = useState(false);

    useEffect(() => {
    }, []);

    return (
        <Form.Item
            key={dataName}
            label={t(title)}
            name={dataName}
            rules={[
                {
                    type: "boolean",
                    required: props.required
                },
            ]}
            valuePropName="checked"
            initialValue={checked}
            style={{ display: 'none' }}  // Oculta el Form.Item
        >
            <Switch
                checked={checked}
                disabled={props.disabledTargetValue === enableInputGroup[props.disabledVarName]}
            />
        </Form.Item>
    );
}

export default BooleanInputFalseDefault;
