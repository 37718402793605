import { Modal } from "antd";
import { useState,useEffect } from "react";
import Title from "antd/es/typography/Title";
import { Table } from "antd";
import { checkData, columns } from "../../../../../components/TableShowTwoColumns/TableShowTwoColumns";

export function ModalStaInfo({originalData={},modalOpen, setModalOpen}) {
    let [sta,setSta] = useState({})
    useEffect(() => {setSta(originalData)}, [originalData])

    const _sta = sta;
    delete sta.actual
    delete sta.should
    const dataTable = checkData(_sta)

    return(
        <Modal open={modalOpen} onCancel={()=>setModalOpen(false)} onOk={()=>setModalOpen(false)} >
            <Title>Modal Info</Title>
            <div justify='center'>
                <Table dataSource={dataTable} pagination={{pageSize:8}}  columns={columns} />
            </div>
        </Modal>
    )
}