import { Form, Switch } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'
import {enableInputGroupAtom} from '../../components/UpdateItemForm/CommonForm.jsx'

function BooleanInput(props) {

    const { t } = useTranslation()

    const element = props.element
    const dataName = props.dataName
    const title = props.title
    const [enableInputGroup,setEnableInputGroup] = useAtom(enableInputGroupAtom);

    const [checked, setChecked] = useState(true)

    useEffect(()=>{
        if(element) setChecked(element[dataName])
        else setChecked(false)
    }, [])

    function onChange () {
        setChecked(!checked)
    }

    return (
        <Form.Item
            key={dataName}
            label={t(title)}
            name={dataName}
            rules={[
                {
                    type: "boolean",
                    required: props.required
                },
            ]}
            valuePropName="checked"
            initialValue={checked }

        >
            <Switch
                checked={checked}
                onChange={onChange}
                disabled={props.disabledTargetValue === enableInputGroup[props.disabledVarName]}
            />
        </Form.Item>
    );
}

export default BooleanInput;