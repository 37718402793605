import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, Select, Typography } from "antd"
import { BasicApi } from "../../API/basicApi"
import { ENV } from "../../utils"

const { Text } = Typography

function EnumSelectorNetworkServer(props) {
    const [enumSelectorActualValue, setEnumSelectorActualValue] = useState("");

    const element = props.element
    const route = props.route
    const title = props.title
    const form = props.form
    const dataName = props.dataName

    const { t } = useTranslation()
    const api = new BasicApi(route)

    const [data, setData] = useState([])

    const onValueChange = (value) => {
        // console.log(dataName + ": " + value)
        setEnumSelectorActualValue(value)
        form.setFieldValue(dataName, value)
        // console.log(props.child)
    }

    /**
     * Creates options for all select element with the required input and then creates the Form
     */
    const generateFormOptions = () => {
        // Get all data for selector
        api.getInfraestructureEnums().then((value) => {
            setData(value[dataName])
            // console.log(dataName + " loaded 👌")
        })
    }

    useEffect(() => {
        generateFormOptions()
    }, [])

    return (
        <>
            <Form.Item
                key={dataName}
                label={t(title)}
                name={dataName}
                rules={[
                    {
                        type: "string",
                        required: true
                    },
                ]}
            >
                <>
                    <Select
                        showSearch
                        style={{
                            width: 200,
                        }}
                        placeholder={t("Search to Select")}
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={data.map((value) => ({
                            label: value,
                            value: value
                        }))}
                        onChange={onValueChange}
                        defaultValue={element[dataName]}
                    />
                    <Text>{element[dataName]}</Text>
                </>
            </Form.Item>
            {
                props.child.map((attr) => {
                    if(enumSelectorActualValue === "Centralized"){
                        if (attr.formItem && attr.key === "url") {
                            const Component = attr.formItem
                            return <Component route={route} element={element ? element : {}} dataName={attr.key} title={attr.name} form={form} />
                        }
                        else return <></>
                    }
                    else {
                        if (attr.formItem && attr.key === "gateway") {
                            const Component = attr.formItem
                            return <Component route={route} element={element ? element : {}} dataName={attr.key} title={attr.name} form={form} />
                        }
                        else return <></>
                    }
                })
            }
        </>
    );
}

export default EnumSelectorNetworkServer;