import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, Select, Typography } from "antd"
import { BasicApi } from "../../API/basicApi"
import { ENV } from "../../utils"
import { useAuth } from "../../Hooks/useAuth.js"

const { Text } = Typography

function RoleSelector(props) {
    const { accessToken } = useAuth()

    const element = props.element
    const dataName = props.dataName
    const title = props.title
    const form = props.form
    const [roleSelected, setRoleSelected] = useState("")

    const { t } = useTranslation()

    const [data, setData] = useState([])
    const council = new BasicApi(ENV.Auth_API_LOCAL + "/" + ENV.API_ROUTES.COUNCIL)
    const [councils, setCouncils] = useState([])

    const onValueRoleChange = (value) => {
        // console.log("ROLE SELECTED:" + ": " + value)
        setRoleSelected(value)

        form.setFieldValue(dataName, value)
    }
    const onValueChange = (value) => {
        // console.log( "councilsId: " + value)
        form.setFieldValue("councilsId", value)
    }
    /**
     * Creates options for all select element with the required input and then creates the Form
     */
    const generateFormOptions = () => {
        // Get all data for selector
        setData(["SUPER_ADMIN", "ADMIN_TECH", "ADMIN_COUNCIL", "USER_COUNCIL"])
        council.getAll(accessToken).then((value) => {
            setCouncils(value)
            // console.log(dataName + " loaded 👌")
        })
    }
   
    useEffect(() => {
        generateFormOptions()
        
    }, [])

    return (<>
        <Form.Item
            key={dataName}
            label={t(title)}
            name={dataName}
            rules={[
                {
                    required: true
                }
            ]}
        >
            <>
                <Select
                    showSearch
                    style={{
                        width: 200,
                    }}
                    placeholder={t("Search to Select")}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={data.map((name) => ({
                        label: name,
                        value: name
                    }))}
                    onChange={onValueRoleChange}
                    defaultValue={element[dataName]}
                />
                <Text>{element[dataName]}</Text>
            </>
        </Form.Item>
        {
                roleSelected === "USER_COUNCIL" ? (
                    <Form.Item
                        key={"councilsId"}
                        label={"Councils"}
                        name={"councilsId"}
                        rules={[
                            {
                                required: true
                            }
                        ]}
                    >
                        <Select
                            mode="multiple"
                            showSearch
                            style={{
                                width: 200,
                            }}
                            placeholder={t("Search to Select")}
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={councils.map((council) => ({
                                label: council.name,
                                value: council.id
                            }))}
                            onChange={onValueChange}
                            defaultValue={element[dataName]}
                        />

                    </Form.Item >
                ) : (
                    <>
                    </>
                )
            }
    </>
    );
}

export default RoleSelector;