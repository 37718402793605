import { Col, Flex, InputNumber, Modal, Row, Select } from "antd";
import { useState } from "react";
import Title from "antd/es/typography/Title";

export function ModalStaConfig({modalOpen, setModalOpen}) {
    let [windowDetection, setWindowDetection] = useState(0)
    let [connection, setConnection] = useState("lora")

    return(
        <Modal 
            open={modalOpen}
            onCancel={()=>setModalOpen(false)}
            onOk={()=>setModalOpen(false)}
        >
            <Title> Sta config</Title>
            <Flex justify='center'>
                <Col style={{width:"60%"}}>
                    <Flex justify="space-between">
                        Ventana de detección:
                        <InputNumber value={windowDetection} onChange={(e)=>setWindowDetection(e) }style={{width:"100px"}}/>
                    </Flex>
                    <Flex justify="space-between">
                        Connection:
                        <Select value={connection} onChange={(e)=>setConnection(e.target.value)}
                            options={[ {label: "lora", value: "lora"}, {label: "4G", value: "4G"} ]}
                            style={{width:"100px"}}
                        />
                    </Flex>
                </Col>
            </Flex>

        </Modal>
    )
}