import React from 'react';
import {  Flex, Transfer, Spin} from 'antd';

function SensorAdd({ 
    sensorData, setSensorData,
    addListSource,//setAddListSource,
    addListTarget,setAddListTarget,
    addListSelected,setAddListSelected,
    addListLoading, //setAddListLoading
}) {
    const boxStyle= {
        width: '100%',
        height: '100%',
        borderRadius: 6,
    };


    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setAddListTarget(nextTargetKeys)
        let itemsToSend = addListSource.filter(
            (item)=>{return (nextTargetKeys.includes(item.key))}
        )
        setSensorData({
            ...sensorData,
            add:itemsToSend
        })
    };

    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setAddListSelected([...sourceSelectedKeys, ...targetSelectedKeys])

    };
    return(
        <Spin spinning={addListLoading}  size="large">
            <Flex  style={boxStyle} justify={"center"} align={"center"}>
                <Transfer
                    dataSource={addListSource}
                    titles={['Sensor List   ', 'Sensor to Add']}
                    targetKeys={addListTarget}
                    selectedKeys={addListSelected}
                    listStyle={{
                        width: 300,
                        height: 300,
                    }}
                    onChange={onChange}
                    showSearch
                    onSelectChange={onSelectChange}
                    render={(item) => item.title}
                />
            </Flex>
        </Spin>

    )
}
export default SensorAdd
