import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { theme, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import FormSendMessage from "../components/FormSendMessage/FormSendMessage";
import Decoder from "./Decoder/Decoder"
import { TableDataProvider } from "../context/TableData/TableDataContext";
import CommonTable from "../components/CommonTable";
import { sensorsTable, applicationTable, serviceProfileTable, deviceProfileTable,gatewaysTable } from "../utils/tables";
import { ENV } from "../utils";
import CommonForm from "../components/UpdateItemForm/CommonForm";
import VariableColumnAttributesModal from "../components/CommonTable/VariableColumnAttributesModal";
import ContentPageHeader from "../components/ContentPageHeader";
import { BasicApi } from "../API/basicApi";
import Dashboard from "../components/Dashboard";
import { TabNavigation, TabPanel } from "../components/TabNavigation/TabNavigation";
import { useAuth } from "../Hooks";
import { useLocation } from "react-router-dom";

const { useToken } = theme;
const { Title } = Typography

/**
 * Creates a Table component with variable data and columns
 * @param {*} { allAttributes, additionalInput, form, submitButton, dataName, additionalButtons, title, route }
 * @returns 
 */
const NetworkServerDetails = (allAttributes, route, form, title) => {
  const [loading, setLoading] = useState(false)
  const { token } = useToken();
  const { t } = useTranslation();
  const { id } = useParams();
  const { accessToken, user } = useAuth()
  const location = useLocation()

  useEffect(() => {
    setLoading(true)
    setLoading(false)
  })
  const gatewayMac = location.pathname.split("/")[4]
  route = `${ENV.Infraestructure_API}/${gatewayMac}/${ENV.API_ROUTES.APPLICATIONS}`
  // console.log("ROUTEA: ", route)
  return (
    <div className='wrapper' >
      <ContentPageHeader route={route} attributes={allAttributes} canAdd={false} api={new BasicApi(route)} submitButton={null} reload={null} title={`Organization ${id}`}
        additionalButtons={null} showBreadCrumbs={true}
      />
      <TabNavigation selected={0}>
        <TabPanel title="Service Profile">
          <Title style={{ "color": token.colorText }}>{t("Service Profile")}</Title>
          <Spin size="middle" spinning={loading}>
            <TableDataProvider>
              <div></div> {/* DO NOT REMOVE, THIS IS A HOT-FIX*/}
              <CommonTable
                allAttributes={serviceProfileTable.attributes}
                route={`${ENV.Infraestructure_API}/${gatewayMac}/${ENV.API_ROUTES.SERVICE_PROFILE}`}
                form={CommonForm}
                dataName={ENV.API_ROUTES.SERVICE_PROFILE}
                rowsPerPage={15}
                additionalButtons={<VariableColumnAttributesModal allAttributes={deviceProfileTable.attributes}
                wrapper={false}
                showBreadCrumbs={false} />}
                showColor={false}
                disabledAddButton={true}
              />
            </TableDataProvider>
          </Spin>
        </TabPanel>

        <TabPanel title="Applications">
          <div style={{ display: "flex", }}>
            <div style={{ width: "50%", padding: "0px 20px 0px 0px" }}>
              <Spin size="middle" spinning={loading}>
                <TableDataProvider>
                  <CommonTable
                    allAttributes={applicationTable.attributes}
                    route={`${ENV.Infraestructure_API}/${ENV.API_ROUTES.NETWORKSERVER}`}
                    form={CommonForm}
                    dataName={ENV.API_ROUTES.APPLICATIONS}
                    title={t('Applications')}
                    showColor={false}
                    rowsPerPage={15}
                    additionalButtons={<VariableColumnAttributesModal allAttributes={applicationTable.attributes}
                    />}
                    wrapper={false}
                  />

                </TableDataProvider>
              </Spin>
            </div>
            <div style={{ width: "50%", padding: "0px 20px 0px 0px" }}>
              <Spin size="middle" spinning={loading}>
                <TableDataProvider>
                  <CommonTable
                    allAttributes={sensorsTable.attributes}
                    route={`${ENV.Infraestructure_API}/${gatewayMac}/${ENV.API_ROUTES.DEVICES}`}
                    form={CommonForm} dataName={ENV.API_ROUTES.DEVICES}
                    title={t('Sensors')}
                    rowsPerPage={15}
                    wrapper={false}
                    additionalButtons={<VariableColumnAttributesModal allAttributes={sensorsTable.attributes} />}
                    showColor={false}
                  />

                </TableDataProvider>
              </Spin>
            </div>
          </div>
        </TabPanel>
        <TabPanel title="Device Profile">
          <Title style={{ "color": token.colorText }}>{t('Device Profiles')}</Title>
          <Spin size="middle" spinning={loading}>
            <TableDataProvider>
              <CommonTable
                allAttributes={deviceProfileTable.attributes}
                form={CommonForm} 
                route={`${ENV.Infraestructure_API}/${gatewayMac}/${ENV.API_ROUTES.DEVICE_PROFILE}`}
                dataName={ENV.API_ROUTES.DEVICE_PROFILE}
                rowsPerPage={15}
                additionalButtons={<VariableColumnAttributesModal allAttributes={deviceProfileTable.attributes}
                  wrapper={false}
                  showBreadCrumbs={false} />}
                showColor={false}
                disabledAddButton={true}

              />
            </TableDataProvider>
          </Spin>
        </TabPanel>

        <TabPanel title={t("Gateway")}>
          <Spin size="middle" spinning={loading}>
            <TableDataProvider>
              <div></div> {/* DO NOT REMOVE, THIS IS A HOT-FIX*/}
              <div></div> {/* DO NOT REMOVE, THIS IS A HOT-FIX*/}
              <CommonTable
                allAttributes={gatewaysTable.attributes}
                route={`${ENV.Infraestructure_API}/${gatewayMac}/${ENV.API_ROUTES.GATEWAY}`}
                form={CommonForm}
                title="Gateway"
                dataName={ENV.API_ROUTES.GATEWAY}
                rowsPerPage={15}
                additionalButtons={<VariableColumnAttributesModal allAttributes={gatewaysTable.attributes}
                wrapper={false}
                showBreadCrumbs={false} />}
                showColor={false}
                disabledAddButton={true}
              />
            </TableDataProvider>
          </Spin>
        </TabPanel>

        <TabPanel title={t("Send Messages")}>
          <div style={{}}>
            <Title style={{ "color": token.colorText }}>{t("Send Messages")}</Title>
            <FormSendMessage />
          </div>
        </TabPanel>

        <TabPanel title={t("General")}>
          <Dashboard title={""} props={{
            showColor: false,
            wrapper: false,
            info: {
              labels: [ 'Network server', 'Sensors'],
              data: [ id, "0"]
            },
            messages: {
              numbers: [0, 0, 0, 0],
              received: 0,
              lost: 0
            }
          }} />
        </TabPanel>

        <TabPanel title={t("Decoder")}>
            <Decoder/>
        </TabPanel>

      </TabNavigation>
    </div>
  );
};

export default NetworkServerDetails;
