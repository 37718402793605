import React, { useState } from 'react';
import { theme, Typography, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import TabServer from './TabServer/TabServer';
import TabSensor from './TabSensor/TabSensor';
function Decoder() {
  const { Title } = Typography
  const { t } = useTranslation();
  const [DecoderTabValue, SetDecoderTabValue] = useState(0)
  const mac = window.location.href.match(/(?<=(centralized|ph[iy]sical-gateways)\/)[a-f0-9-]*/g)[0]
  return(
    <>
      <Title style={{ "color": theme.colorText }}>{t("Decoder")}</Title>
      <Tabs
        defaultActiveKey={DecoderTabValue}
        onChange={(value) => SetDecoderTabValue(value)}
        
        items={[
            {label: 'Server',key: 0,},
            {label: 'Sensor',key: 1,}
        ]}
      />
      <div hidden={DecoderTabValue!==0}>
        <TabServer mac={mac}/>
      </div>
      <div hidden={DecoderTabValue!==1}>
        <TabSensor mac={mac}/>
      </div>
      </>
  )
}
export default Decoder
