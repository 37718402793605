import { Form, Switch } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
import MqttServerSelector from "./MqttServerSelector.component"

//TODO REFACTOR 

function EmbebedNetServer(props) {

    const { t } = useTranslation()

    const element = props.element
    const dataName = props.dataName
    const title = props.title
    const form = props.form

    const [checked, setChecked] = useState(true)

    useEffect(() => {
        // console.log("element ", element.embebed)
        if (element.embebed) setChecked(true)
        else setChecked(false)
    }, [])

    const onChange = (value) => {
        setChecked(value)
        // console.log("checked", value)
    }

    return (
        <>
            <MqttServerSelector element={element} dataName={props.dataName} title="MQTT Server" form={form} />
        </>
    );
}

export default EmbebedNetServer;