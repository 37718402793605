/**
 * Nombre: AppRouter.js
 * Autor: Luis B M
 * Fecha: 28/02/2023
 *
 * Descripcion: Componente de router-dom para añadir a App.js
 * Docs: https://reactrouter.com/en/main
 */

import React from "react";
import "../styles/index.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  ScrollRestoration,
  useNavigate,
} from "react-router-dom";
// COMPONENTS
import Home from "../Pages/home.component";
import CommonTable from "../components/CommonTable";
import NetworkServerDetails from "../Pages/NetworkServerDetails";

import { Map } from "../components/Map/map.component";
import { LoginPage } from "../Pages/AuthPage/login.component";
import { PrivateRoute } from "../Router/PrivateRoute";
import LoginSubmitButton from "../components/UpdateItemForm/LoginSubmitButton.component";

// UTILS

import {
  networkServerTable,
  councilTable,
  decoderTable,
  doorsTable,
  centralizedTable,
} from "../utils/tables";
import {
  phisicalGatewaysTable,
  loraWanNetworkTable,
  mqttServer,
} from "../utils/tables";
import { mqttLogTable, sensorsTable, sensorTypesTable } from "../utils/tables";
import { serversTable, userTable, waterDevicesTable } from "../utils/tables";
import { waterGeneralMunicipalTable, organizationTable } from "../utils/tables";
import { streetlight } from "../utils/tables/streetlight";

import App from "../App";
import Dashboard from "../components/Dashboard";
import EventCalendar from "../components/EventCalendar";
import MyProfile from "../Pages/myProfile.component";
import ImportWaterDeviceButton from "../components/ActionButtons/ImportWaterDeviceButton";
import UpdateWaterDeviceButton from "../components/ActionButtons/UpdateWaterDeviceButton";
import VariableColumnAttributesModal from "../components/CommonTable/VariableColumnAttributesModal";
import CommonForm from "../components/UpdateItemForm/CommonForm";
import { TableDataProvider } from "../context/TableData/TableDataContext";
import { ENV } from "../utils";
import AlarmSystem from "../../src/Pages/AlarmSystem/AlarmSystem";
import Sta from "../Pages/Sta/Sta";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="sign-in" element={<LoginPage />} />
      <Route
        path="/"
        element={
          <>
          <PrivateRoute>
            <App />
            <ScrollRestoration />
          </PrivateRoute>
          </>
        }
      >
        <Route path="home" element={<Home />}>
          <Route index element={<Map />} />
          <Route
            path="myuser"
            element={
              <PrivateRoute>
                <MyProfile />
              </PrivateRoute>
            }
          ></Route>
          <Route path="LoraWAN">
          <Route
              path="centralized/:id/organizations"
              element={
                <PrivateRoute>
                  <TableDataProvider>
                    <CommonTable
                      allAttributes={organizationTable.attributes}
                      route={`${ENV.Infraestructure_API}`}
                      form={CommonForm}
                      dataName={ENV.API_ROUTES.ORGANIZATIONS}
                      title={"Organizations"}
                      rowsPerPage={15}
                      additionalButtons={
                        <VariableColumnAttributesModal
                          allAttributes={organizationTable.attributes}
                        />
                      }
                      wrapper={true}
                      showBreadCrumbs={true}
                    />
                  </TableDataProvider>
                  </PrivateRoute>
              }
            />
            <Route
              path="centralized"
              element={
                <PrivateRoute>
                  <TableDataProvider>
                    <CommonTable
                      allAttributes={centralizedTable.attributes}
                      route={`${ENV.Infraestructure_API}/${ENV.API_ROUTES.PHISICAL_GATEWAY}`}
                      form={CommonForm}
                      dataName={ENV.API_ROUTES.PHISICAL_GATEWAY}
                      title={"Centralized"}
                      rowsPerPage={15}
                      additionalButtons={
                        <VariableColumnAttributesModal
                          allAttributes={centralizedTable.attributes}
                        />
                      }
                      wrapper={true}
                      showBreadCrumbs={true}
                    />
                  </TableDataProvider>
                </PrivateRoute>
              }
            />
            <Route
              path="centralized/:id/organizations/:organizationId"
              element={
                <PrivateRoute>
                  <TableDataProvider>
                    <NetworkServerDetails />
                  </TableDataProvider>
                </PrivateRoute>
              }
            />

            <Route
              path="networkserver"
              element={
                <PrivateRoute>
                  <TableDataProvider>
                    <CommonTable
                      showBreadCrumbs={true}
                      // extra={ExtraTest}
                      allAttributes={networkServerTable.attributes}
                      route={`${ENV.Infraestructure_API}/${ENV.API_ROUTES.NETWORKSERVER}`}
                      form={CommonForm}
                      dataName={ENV.API_ROUTES.NETWORKSERVER}
                      title={"Network Server"}
                      rowsPerPage={15}
                      additionalButtons={
                        <VariableColumnAttributesModal
                          allAttributes={networkServerTable.attributes}
                        />
                      }
                      wrapper={true}
                    />
                  </TableDataProvider>
                </PrivateRoute>
              }
            >
              <Route
                path="chirp"
                element={
                  <PrivateRoute>
                    <TableDataProvider>
                      <CommonTable
                        allAttributes={loraWanNetworkTable.attributes}
                        route={`${ENV.Infraestructure_API}/${ENV.API_ROUTES.NETWORKSERVER}`}
                        form={CommonForm}
                        dataName={ENV.API_ROUTES.NETWORKSERVER}
                        title={"Network Servers"}
                        rowsPerPage={15}
                        additionalButtons={
                          <VariableColumnAttributesModal
                            allAttributes={loraWanNetworkTable.attributes}
                          />
                        }
                      />
                    </TableDataProvider>
                  </PrivateRoute>
                }
              />
            </Route>
            <Route
              path="dashboard"
              element={
                <PrivateRoute>
                  <Dashboard
                    title={"LoraWAN messages"}
                    props={{
                      wrapper: true,
                      showBreadCrumbs: true,
                      showColor: true,
                      info: {
                        labels: ["Username"],
                        data: ["User"],
                      },
                      messages: {
                        numbers: [45, 63, 7, 12],
                        received: 80,
                        lost: 20,
                      },
                    }}
                  />
                </PrivateRoute>
              }
            />
            <Route
              path="message"
              element={
                <PrivateRoute>
                  <TableDataProvider>
                    <CommonTable
                      allAttributes={mqttLogTable.attributes}
                      form={CommonForm}
                      dataName={"user"}
                      title={"Messages"}
                      rowsPerPage={15} /*extra={ExtraTest}*/
                      additionalButtons={
                        <VariableColumnAttributesModal
                          allAttributes={mqttLogTable.attributes}
                          showBreadCrumbs={true}
                        />
                      }
                      wrapper={true}
                    />
                  </TableDataProvider>
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="decoder">
            <Route
              path="server"
              element={
                <PrivateRoute>
                  <TableDataProvider>
                    <CommonTable
                      allAttributes={decoderTable.attributes}
                      route={`${ENV.Infraestructure_API}/${ENV.API_ROUTES.DECODER}`}
                      form={CommonForm}
                      dataName={ENV.API_ROUTES.DECODER}
                      title={"Decoders"}
                      rowsPerPage={15}
                      additionalButtons={
                        <VariableColumnAttributesModal
                          allAttributes={decoderTable.attributes}
                          showBreadCrumbs={true}
                        />
                      }
                      wrapper={true}
                    />
                  </TableDataProvider>
                </PrivateRoute>
              }
            />
          </Route>

          <Route path="gateway">
            <Route
              path="phisical-gateways"
              element={
                <PrivateRoute>
                  <TableDataProvider>
                    <CommonTable
                      showBreadCrumbs={true}
                      allAttributes={phisicalGatewaysTable.attributes}
                      form={CommonForm}
                      route={`${ENV.Infraestructure_API}/${ENV.API_ROUTES.PHISICAL_GATEWAY}`}
                      dataName={"gateway"}
                      title={"Phisical Gateways"}
                      rowsPerPage={15}
                      additionalButtons={
                        <VariableColumnAttributesModal
                          allAttributes={phisicalGatewaysTable.attributes}
                        />
                      }
                      wrapper={true}
                    />
                  </TableDataProvider>
                </PrivateRoute>
              }
            />
            {
              // TODO: CHANGE PARAMS , Aqui se muestran las organizaciones de este phisical gateway
            }
            <Route
              path="phisical-gateways/:id/organizations"
              element={
                <PrivateRoute>
                
                  <TableDataProvider>
                    <CommonTable
                      allAttributes={organizationTable.attributes}
                      route={`${ENV.Infraestructure_API}`}
                      form={CommonForm}
                      dataName={ENV.API_ROUTES.ORGANIZATIONS}
                      title={"Organizations"}
                      rowsPerPage={15}
                      additionalButtons={
                        <VariableColumnAttributesModal
                          allAttributes={organizationTable.attributes}
                        />
                      }
                      wrapper={true}
                      showBreadCrumbs={true}
                    />
                  </TableDataProvider>
                </PrivateRoute>
              }
            />
         
           <Route
              path="phisical-gateways/:id/organizations/:id"
              element={
                <PrivateRoute>
                  <TableDataProvider>
                    <NetworkServerDetails />
                  </TableDataProvider>
                </PrivateRoute>
              }
            />
          </Route>

          <Route
            path="mqtt-server"
            element={
              <PrivateRoute>
                <TableDataProvider>
                  <CommonTable
                    allAttributes={mqttServer.attributes}
                    route={`${ENV.Infraestructure_API}/${ENV.API_ROUTES.MQTTSERVER}`}
                    form={CommonForm}
                    dataName={"user"}
                    title={"MQTT Server"}
                    rowsPerPage={15}
                    additionalButtons={
                      <VariableColumnAttributesModal
                        allAttributes={mqttServer.attributes}
                      />
                    }
                    wrapper={true}
                    showBreadCrumbs={true}
                  />
                </TableDataProvider>
              </PrivateRoute>
            }
          ></Route>

          <Route path="sensor">
            <Route
              path="units"
              element={
                <PrivateRoute>
                  <TableDataProvider>
                    <CommonTable
                      allAttributes={sensorsTable.attributes}
                      route={`${ENV.Infraestructure_API}/${ENV.API_ROUTES.SENSORUNIT}`}
                      form={CommonForm}
                      dataName={ENV.API_ROUTES.SENSORUNIT}
                      title={"Sensors"}
                      rowsPerPage={15}
                      additionalButtons={
                        <VariableColumnAttributesModal
                          allAttributes={sensorsTable.attributes}
                          showBreadCrumbs={true}
                        />
                      }
                      wrapper={true}
                    />
                  </TableDataProvider>
                </PrivateRoute>
              }
            />
            <Route
              path="types"
              element={
                <PrivateRoute>
                  <TableDataProvider>
                    <CommonTable
                      allAttributes={sensorTypesTable.attributes}
                      route={`${ENV.Infraestructure_API}/${ENV.API_ROUTES.SENSORTYPE}`}
                      form={CommonForm}
                      dataName={ENV.API_ROUTES.SENSORTYPE}
                      title={"Types"}
                      rowsPerPage={15}
                      additionalButtons={
                        <VariableColumnAttributesModal
                          allAttributes={sensorTypesTable.attributes}
                          showBreadCrumbs={true}
                        />
                      }
                      wrapper={true}
                    />
                  </TableDataProvider>
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="server"
            element={
              <PrivateRoute>
                <TableDataProvider>
                  <CommonTable
                    allAttributes={serversTable.attributes}
                    route={`${ENV.Infraestructure_API}/${ENV.API_ROUTES.SERVER}`}
                    form={CommonForm}
                    dataName={ENV.API_ROUTES.SERVER}
                    title={"Servers"}
                    rowsPerPage={15}
                    additionalButtons={
                      <VariableColumnAttributesModal
                        allAttributes={serversTable.attributes}
                        showBreadCrumbs={true}
                      />
                    }
                    wrapper={true}
                  />
                </TableDataProvider>
              </PrivateRoute>
            }
          ></Route>
          <Route path="user-groups">
            {/* <Route path="business" element={
              <PrivateRoute >
                <TableDataProvider>
                  <CommonTable allAttributes={businessTable.attributes} route={`${ENV.Auth_API_LOCAL}/${ENV.API_ROUTES.BUSINESS}`} form={CommonForm} dataName={ENV.API_ROUTES.BUSINESS} title={'Business'} rowsPerPage={15}
                    additionalButtons={<VariableColumnAttributesModal allAttributes={businessTable.attributes} />}
                    wrapper={true}
                  />
                </TableDataProvider>
              </PrivateRoute>
            }
            />
            <Route path="projects" element={
              <PrivateRoute >
                <TableDataProvider>
                  <CommonTable allAttributes={projectsTable.attributes} route={`${ENV.Auth_API_LOCAL}/${ENV.API_ROUTES.PROJECT}`} form={CommonForm} dataName={ENV.API_ROUTES.PROJECT} title={'Projects'} rowsPerPage={15}
                    additionalButtons={<VariableColumnAttributesModal allAttributes={projectsTable.attributes} />}
                    wrapper={true}
                  />
                </TableDataProvider>
              </PrivateRoute>
            }
            /> */}

            <Route
              path="council"
              element={
                <PrivateRoute>
                  <TableDataProvider>
                    <CommonTable
                      allAttributes={councilTable.attributes}
                      form={CommonForm}
                      route={`${ENV.Auth_API_LOCAL}/${ENV.API_ROUTES.COUNCIL}`}
                      dataName={ENV.API_ROUTES.COUNCIL}
                      title={"Council"}
                      rowsPerPage={15}
                      additionalButtons={
                        <VariableColumnAttributesModal
                          allAttributes={councilTable.attributes}
                        />
                      }
                      wrapper={true}
                    />
                  </TableDataProvider>
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="users"
            element={
              <PrivateRoute>
                <TableDataProvider>
                  <CommonTable
                    allAttributes={userTable.attributes}
                    form={CommonForm}
                    route={`${ENV.Auth_API_LOCAL}/${ENV.API_ROUTES.USER}`}
                    submitButton={LoginSubmitButton}
                    dataName={"user"}
                    title={"Users"}
                    rowsPerPage={15}
                    additionalButtons={
                      <VariableColumnAttributesModal
                        allAttributes={userTable.attributes}
                      />
                    }
                    wrapper={true}
                  />
                </TableDataProvider>
              </PrivateRoute>
            }
          ></Route>

          <Route path="alumbrado">
            <Route
              path="dispositivo"
              element={
                <PrivateRoute>
                  <TableDataProvider>
                    <CommonTable
                      allAttributes={streetlight.attributes}
                      form={CommonForm}
                      dataName={"Alumbrado Publico"}
                      route={`${ENV.Infraestructure_API}/street-light`}
                      title={"Alumbrado Publico"}
                      rowsPerPage={15}
                      updateAndDeleteRoute="type/id"
                      additionalButtons={
                        <VariableColumnAttributesModal
                          allAttributes={streetlight.attributes}
                        />
                      }
                      wrapper={true}
                    />
                  </TableDataProvider>
                </PrivateRoute>
              }
            />
          </Route>

          <Route
            path="logger"
            element={
              <PrivateRoute>
                <TableDataProvider>
                  <CommonTable
                    allAttributes={mqttLogTable.attributes}
                    form={CommonForm}
                    dataName={"user"}
                    title={"Logger"}
                    rowsPerPage={15}
                    additionalButtons={
                      <VariableColumnAttributesModal
                        allAttributes={mqttLogTable.attributes}
                      />
                    }
                    wrapper={true}
                  />
                </TableDataProvider>
              </PrivateRoute>
            }
          ></Route>
          <Route path="door">
            <Route
              path="doors"
              element={
                <PrivateRoute>
                  <TableDataProvider>
                    <CommonTable
                      allAttributes={doorsTable.attributes}
                      form={CommonForm}
                      dataName={ENV.API_ROUTES.DOOR}
                      title={"Doors"}
                      rowsPerPage={15}
                      additionalButtons={
                        <VariableColumnAttributesModal
                          allAttributes={doorsTable.attributes}
                        />
                      }
                      wrapper={true}
                    />
                  </TableDataProvider>
                </PrivateRoute>
              }
            />
            <Route
              path="calendar/:doorId?"
              element={
                <PrivateRoute>
                  <EventCalendar title={"Door Calendar"} />
                </PrivateRoute>
              }
            />
            <Route
              path="requests"
              element={
                <PrivateRoute>
                  <TableDataProvider>
                    <CommonTable
                      allAttributes={doorsTable.attributes}
                      route={`${ENV.Infraestructure_API}/${ENV.API_ROUTES.DOORREQUEST}`}
                      form={CommonForm}
                      title={"Door Requests"}
                      dataName={ENV.API_ROUTES.DOORREQUEST}
                      rowsPerPage={15}
                      additionalButtons={
                        <VariableColumnAttributesModal
                          allAttributes={doorsTable.attributes}
                        />
                      }
                      wrapper={true}
                    />
                  </TableDataProvider>
                </PrivateRoute>
              }
            />
            <Route
              path="users"
              element={
                <PrivateRoute>
                  <TableDataProvider>
                    <CommonTable
                      allAttributes={userTable.attributes}
                      form={CommonForm}
                      route={`${ENV.Auth_API_LOCAL}/${ENV.API_ROUTES.USER}`}
                      submitButton={LoginSubmitButton}
                      dataName={ENV.API_ROUTES.USER}
                      title={"Users"}
                      rowsPerPage={15}
                      additionalButtons={
                        <VariableColumnAttributesModal
                          allAttributes={userTable.attributes}
                        />
                      }
                      wrapper={true}
                    />
                  </TableDataProvider>
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="light">
            <Route
              path="groups"
              element={
                <PrivateRoute>
                  <TableDataProvider>
                    <CommonTable
                      allAttributes={userTable.attributes}
                      route={`${ENV.Modules_API}/${ENV.API_ROUTES.LIGHTGROUPS}`}
                      form={CommonForm}
                      dataName={ENV.API_ROUTES.LIGHTGROUPS}
                      title={"Groups"}
                      rowsPerPage={15}
                      additionalButtons={
                        <VariableColumnAttributesModal
                          allAttributes={userTable.attributes}
                        />
                      }
                      wrapper={true}
                    />
                  </TableDataProvider>
                </PrivateRoute>
              }
            />
            <Route path="devices" element={<PrivateRoute></PrivateRoute>} />
            <Route path="requests" element={<PrivateRoute></PrivateRoute>} />
            <Route path="status" element={<PrivateRoute></PrivateRoute>} />
            <Route path="planning" element={<PrivateRoute></PrivateRoute>} />
            <Route path="actuator" element={<PrivateRoute></PrivateRoute>} />
            <Route path="history" element={<PrivateRoute></PrivateRoute>} />
            <Route
              path="controlcabinet"
              element={<PrivateRoute></PrivateRoute>}
            />
          </Route>
          <Route path="water">
            <Route path="general">
              <Route
                path="municipal"
                element={
                  <PrivateRoute>
                    <CommonTable
                      allAttributes={waterGeneralMunicipalTable.attributes}
                      form={CommonForm}
                      route={`${ENV.Auth_API}/${ENV.API_ROUTES.USER}`}
                      submitButton={LoginSubmitButton}
                      dataName={"user"}
                      title={"Municipality"}
                      rowsPerPage={15}
                      additionalButtons={
                        <>
                          <VariableColumnAttributesModal
                            allAttributes={
                              waterGeneralMunicipalTable.attributes
                            }
                          />
                        </>
                      }
                      wrapper={true}
                    />
                  </PrivateRoute>
                }
              />
              <Route path="diameter" element={<PrivateRoute></PrivateRoute>} />
              <Route
                path="servicefee"
                element={<PrivateRoute></PrivateRoute>}
              />
              <Route
                path="consumptionblock"
                element={<PrivateRoute></PrivateRoute>}
              />
              <Route path="sewerrate" element={<PrivateRoute></PrivateRoute>} />
              <Route path="canon" element={<PrivateRoute></PrivateRoute>} />
              <Route path="counter" element={<PrivateRoute></PrivateRoute>} />
              <Route
                path="investment"
                element={<PrivateRoute></PrivateRoute>}
              />
            </Route>
            <Route path="alarms" element={<PrivateRoute></PrivateRoute>} />
            <Route
              path="devices"
              element={
                <PrivateRoute>
                  <TableDataProvider>
                    <CommonTable
                      allAttributes={userTable.attributes}
                      form={CommonForm}
                      dataName={"user"}
                      rowsPerPage={15}
                      additionalButtons={
                        <>
                          <VariableColumnAttributesModal
                            allAttributes={waterDevicesTable.attributes}
                          />
                          <UpdateWaterDeviceButton />
                          <ImportWaterDeviceButton />
                        </>
                      }
                      wrapper={true}
                    />
                  </TableDataProvider>
                </PrivateRoute>
              }
            />
            <Route path="groups" element={<PrivateRoute></PrivateRoute>} />
            <Route
              path="hydricbalance"
              element={<PrivateRoute></PrivateRoute>}
            />
            <Route
              path="observations"
              element={<PrivateRoute></PrivateRoute>}
            />
            <Route path="map" element={<PrivateRoute></PrivateRoute>} />
            <Route path="control" element={<PrivateRoute></PrivateRoute>} />
          </Route>
          <Route path="alarms" element={<PrivateRoute children={<AlarmSystem />}/>}/>
          <Route path="STA" element={<PrivateRoute children={<Sta/>}/>}/>
        </Route>
      </Route>
    </>
  )
);

function AppRouter() {
  return <RouterProvider router={router} />;
}

export default AppRouter;
