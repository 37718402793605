import { Form, Input } from "antd";
import { useState } from "react";
import {useTranslation} from 'react-i18next'
import {EyeTwoTone, EyeInvisibleOutlined} from '@ant-design/icons'

function PasswordInput({element,}) {

    const {t} = useTranslation();
    // console.log("ELEMENT ",element)
    return (
        <Form.Item
            key={"pswd"}
            label={t('Password')}
            name={"pswd"}
            rules={[
                {
                    required: true,
                    min: 5,
                    max: 25
                }
            ]}
        >
            <Input.Password defaultValue={element?.pswd ? element.pswd : ""} copyable="true" 
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
        </Form.Item>
    );
}

export default PasswordInput;