import { List, Modal, Table, theme, Tooltip, Typography } from "antd";
import { HistoryOutlined } from '@ant-design/icons'
import { useTranslation } from "react-i18next";
import { useState } from "react";

const { useToken } = theme;

function HistoryListButton({ element }) {

    const { t } = useTranslation();
    const { token } = useToken();


    // History an element modal view
    const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);

    function openHistoryModal() {
        setIsHistoryModalOpen(true);
    };

    function handleHistoryOk() {
        setIsHistoryModalOpen(false);
        // TODO: History the element with api call
    };

    function handleHistoryCancel() {
        setIsHistoryModalOpen(false);
    };
    // -------------------------------

    // console.log(element.history)

    const columns = [
        {
            title: t("Name"),
            dataIndex: "name"
        },
        {
            title: t("Status"),
            dataIndex: "state"
        },
        {
            title: t("Last Opening"),
            dataIndex: "date"
        }
    ]

    return (
        <>
            <HistoryOutlined style={{ "fontSize": "large", "color": token.colorPrimary }} onClick={openHistoryModal} />
            <Modal title={t("History Element")} open={isHistoryModalOpen} onOk={handleHistoryOk} onCancel={handleHistoryCancel}>
                <Table dataSource={element.history}
                    columns={columns}
                />
            </Modal>
        </>
    );
}

export default HistoryListButton;