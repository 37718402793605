import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/index.css'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker';
import { LocaleProvider } from './context/Locale/LocaleContext'
import './i18n';

import { AuthProvider } from "./context/Auth/index";

// COMPONENTS
import AppRouter from './Router/AppRouter'
import { ThemeProvider } from './context/Theme/ThemeContext';
import { BreadcrumbsProvider } from './context/Breadcrumbs/BreadcrumbsContext';

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <LocaleProvider>
      <AuthProvider>
        <ThemeProvider>
          <BreadcrumbsProvider>
            <AppRouter />
          </BreadcrumbsProvider>
        </ThemeProvider>
      </AuthProvider>
    </LocaleProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();