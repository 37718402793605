import React, { useState,useEffect } from 'react';
import { Table} from "antd";
import { Button, Flex} from 'antd';
import { Spin } from 'antd';
import {ENV} from "../../../utils/constants.js"

function TabServer({mac}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataSource,setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'pkId',
      key: 'pkId',
    },
    {
      title: 'Name',
      dataIndex: 'serverName',
      key: 'serverName',
    },
    {
      title: 'Provider',
      dataIndex: 'provider',
      key: 'provider',
    },
    {
      title: 'Url',
      dataIndex: 'url',
      key: 'url',
    }
  ]

  const showModal = () => {
    setIsModalOpen(true);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${ENV.Infraestructure_API}/${mac}/decoder/servers`, 
          {method: 'GET'}
        )
          setLoading(false);
        if (!response.ok) {
          throw new Error(`Error al realizar la solicitud: ${response.status}`);
        }

        let data = await response.json()
        setDataSource(data)
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData()
  }, [])

  return (
    <>
      <Spin spinning={loading}  size="large">
        <Table dataSource={dataSource} columns={columns} />
      </Spin>
      <Flex gap="small" wrap="wrap"  justify="flex-end" >
        <Button type="secondary" >Update Decoder</Button>
        <Button type="secondary" >From Decoder</Button>
      </Flex>
        
      
    </>
  );



}
export default TabServer
