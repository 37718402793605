import React, { Component, useEffect,useState } from 'react'
import '../../styles/App.css'
import { useFormik } from "formik";
import { initialValues } from "./Login.data";
import { useAuth } from "../../Hooks";
import { useNavigate } from "react-router-dom";
import logo from '../../assets/logo.png';
import { useTranslation } from 'react-i18next';


export function LoginPage(props) {
  const { login, setIsAuthenticated, isAuthenticated,user } = useAuth();
  const navigate = useNavigate();

  const [errorLogin, setErrorLogin] = useState("");

  const { t } = useTranslation()

  // If the auth state changes to true, then login
  useEffect(() => {
    // console.log("Auth state changed to " + isAuthenticated)
    if (isAuthenticated && new Date(user.exp * 1000) > new Date() ) navigate("/home");
  }, [isAuthenticated])


  const formik = useFormik({
    initialValues: initialValues(),
    onSubmit: async (values) => {
      try {
        if (await login(values)) {
          setErrorLogin("Connecting...")
          setIsAuthenticated(true)
          window.location.reload()

        }
        else {
        }
        return;
      } catch (error) {
        console.error(error);
        if (error.status === 404)
          setErrorLogin("** Error: Credenciales incorrectas. ")
        else if (error.status === 500)
          setErrorLogin("** Error: Error en el servidor.")
        else
          setErrorLogin("** Error: Error no definido.")
      }
    },
  })

  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        <form onSubmit={formik.handleSubmit}>
          <img src={logo} width="300" style={{ "padding": "30px" }} alt="LOGO GESINEN"></img>
          <div className="mb-3">
            <label>{t('Email address')}</label>
            <input
              id="username"
              name="username"
              type="email"
              className="form-control"
              placeholder="Enter email"
              onChange={formik.handleChange}
              // value={formik.values.username}
              errormessage={formik.errors.username}
            />
          </div>
          <div className="mb-3">
            <label>{t('Password')}</label>
            <input
              id='pswd'
              name="pswd"
              type="password"
              className="form-control"
              placeholder="Enter password"
              onChange={formik.handleChange}
              // value={formik.values.pswd}
              errormessage={formik.errors.pswd}
            />
          </div>
          <div className="mb-3">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="customCheck1"
              />
              <label className="custom-control-label" htmlFor="customCheck1">
                {t('Rememeber me')}
              </label>
            </div>
          </div>
          <div className="d-grid">
            <button type="submit" className="btn btn-primary">
              {t('Submit')}
            </button>
            <p style={{ color: "red" }}>{errorLogin}</p>
          </div>
        </form>
      </div>
    </div>
  )
}
