export const data = 
[
    // {
    //     "id": 107,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e566_sensor",
    //     "description": "ibox sensor",
    //     "provider": null,
    //     "userId": 32,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "url": "https://trello.com/c/f3Zqsqrn/2-t02-lorawan-networkserver",
    //     "sensorModelName": null,
    //     "installationDate": "2020-06-15",
    //     "applicationName": null,
    //     "latitude": 39.120249,
    //     "longitude": -0.454043,
    //     "deviceEUI": "0079e129d522e566",
    //     "appEUI": "12",
    //     "appKEY": "12",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 197579,
    //     "prevFrameCounterFcnt": 1409,
    //     "latestFrameCounterFcnt": 1409,
    //     "lostFcnt": -778212,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb3f1042",
    //     "messageTime": null
    // },
    // {
    //     "id": 108,
    //     "gatewaysId": null,
    //     "name": "testIbox",
    //     "description": "For testing All",
    //     "provider": null,
    //     "userId": 32,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-06-18",
    //     "applicationName": null,
    //     "latitude": 39.427242,
    //     "longitude": -1.114482,
    //     "deviceEUI": "0079e129d522e634",
    //     "appEUI": "12",
    //     "appKEY": "12",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "inputcmp1"
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": "metercmp1"
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 145,
    //     "prevFrameCounterFcnt": 186,
    //     "latestFrameCounterFcnt": 187,
    //     "lostFcnt": -162678,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb3330b1",
    //     "messageTime": null
    // },
    // {
    //     "id": 109,
    //     "gatewaysId": null,
    //     "name": "Sensor_ibox_Alberto",
    //     "description": "IBOX TEST",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "16",
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-06-24",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e566",
    //     "appEUI": "06263577c3bbc9eb",
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "DoorC01"
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": "EnergyMeterVIPAC01"
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 197579,
    //     "prevFrameCounterFcnt": 1409,
    //     "latestFrameCounterFcnt": 1409,
    //     "lostFcnt": -778212,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb3f1042",
    //     "messageTime": null
    // },
    // {
    //     "id": 110,
    //     "gatewaysId": null,
    //     "name": "IBOX1_Biblio",
    //     "description": "iBOX_GESWAT_e647",
    //     "provider": null,
    //     "userId": 36,
    //     "typeSensor": "20",
    //     "sensorTypeInfo": {
    //         "id": 20,
    //         "name": "IBOX-PUERTA-TEST",
    //         "description": "Puerta TEST",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-06-26",
    //     "applicationName": null,
    //     "latitude": 39.567724,
    //     "longitude": -0.622264,
    //     "deviceEUI": "0079e129d522e647",
    //     "appEUI": "06263577c3bbc9eb",
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb66bea3,b827eb6c4e09,dca63214b1d0,b827eb11f2e1",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 115,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e631_doorLibrary",
    //     "description": "iBOX_GESWAT_e631",
    //     "provider": null,
    //     "userId": 77,
    //     "typeSensor": "89",
    //     "sensorTypeInfo": {
    //         "id": 89,
    //         "name": "Olmos_ibox",
    //         "description": null,
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-07-15",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e631",
    //     "appEUI": null,
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": "b827eb524297",
    //     "dr": 3,
    //     "rssi": -125,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 682,
    //     "latestFrameCounterFcnt": 1494,
    //     "lostFcnt": -322249,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb524297",
    //     "messageTime": null
    // },
    // {
    //     "id": 117,
    //     "gatewaysId": null,
    //     "name": "iBOX_Biblioteca",
    //     "description": "Quatretonda iBox Biblioteca",
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": "21",
    //     "sensorTypeInfo": {
    //         "id": 21,
    //         "name": "iBOX_Puerta",
    //         "description": "Ibox Biblioteca",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-07-19",
    //     "applicationName": null,
    //     "latitude": 38.945463,
    //     "longitude": -0.399479,
    //     "deviceEUI": "0079e129d522e629",
    //     "appEUI": "06263577c3bbc9eb",
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "EnergyMeterQUAGWTC01"
    //         },
    //         {
    //             "inputComponent": "EnergyMeterQUAGWTC02"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -57,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 5066,
    //     "prevFrameCounterFcnt": 4373,
    //     "latestFrameCounterFcnt": 4374,
    //     "lostFcnt": -784639,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 118,
    //     "gatewaysId": null,
    //     "name": "iBOX_Ayuntamiento",
    //     "description": "iBOX Ayuntamiento",
    //     "provider": null,
    //     "userId": 41,
    //     "typeSensor": "22",
    //     "sensorTypeInfo": {
    //         "id": 22,
    //         "name": "iBOX_Ayuntamiento",
    //         "description": "iBOX Ayuntamiento",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-07-19",
    //     "applicationName": null,
    //     "latitude": 38.853991,
    //     "longitude": -0.502074,
    //     "deviceEUI": "0079e129d522e630",
    //     "appEUI": "06263577c3bbc9eb",
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "EnergyMeterEARCTMC01"
    //         },
    //         {
    //             "inputComponent": "EnergyMeterEARCTMC02"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -98,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 326,
    //     "prevFrameCounterFcnt": 2407,
    //     "latestFrameCounterFcnt": 2408,
    //     "lostFcnt": -753304,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827ebf0f17b",
    //     "messageTime": null
    // },
    // {
    //     "id": 121,
    //     "gatewaysId": null,
    //     "name": "171fe129d522e000_wifi",
    //     "description": "Wifi Sensor",
    //     "provider": null,
    //     "userId": 36,
    //     "typeSensor": "27",
    //     "sensorTypeInfo": {
    //         "id": 27,
    //         "name": "171fe129d522e000_wifi_Type",
    //         "description": null,
    //         "sensorType": "wifi",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-08-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "171fe129d522e000",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb66bea3,b827eb6c4e09,dca63214b1d0,b827eb11f2e1",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 123,
    //     "gatewaysId": null,
    //     "name": "Alberto_Puerta",
    //     "description": "Test puerta",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "21",
    //     "sensorTypeInfo": {
    //         "id": 21,
    //         "name": "iBOX_Puerta",
    //         "description": "Ibox Biblioteca",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-10-06",
    //     "applicationName": null,
    //     "latitude": 39.592868,
    //     "longitude": -0.544343,
    //     "deviceEUI": "0079e129d522e630",
    //     "appEUI": "06263577C3BBC9EB2FEF99135EF0F6A5",
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "DoorC01"
    //         },
    //         {
    //             "inputComponent": "DoorC02"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -98,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 326,
    //     "prevFrameCounterFcnt": 2407,
    //     "latestFrameCounterFcnt": 2408,
    //     "lostFcnt": -753304,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827ebf0f17b",
    //     "messageTime": null
    // },
    // {
    //     "id": 124,
    //     "gatewaysId": null,
    //     "name": "DCSWAGWS0000000008",
    //     "description": "water castellon itron",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "30",
    //     "sensorTypeInfo": {
    //         "id": 30,
    //         "name": "water_itron",
    //         "description": "for water",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-11-25",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "532253811817804e",
    //     "appEUI": "00000000000000000",
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 125,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e613_N",
    //     "description": "0079e129d522e613_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e613",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 126,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e614_N",
    //     "description": "0079e129d522e614_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e614",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 127,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e615_N",
    //     "description": "0079e129d522e615_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e615",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 128,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e616_N",
    //     "description": "0079e129d522e616_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e616",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -90,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 3118,
    //     "prevFrameCounterFcnt": 2,
    //     "latestFrameCounterFcnt": 3,
    //     "lostFcnt": -739446,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca632143f21",
    //     "messageTime": null
    // },
    // {
    //     "id": 129,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e617_N",
    //     "description": "0079e129d522e617_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e617",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -107,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2313,
    //     "prevFrameCounterFcnt": 2437,
    //     "latestFrameCounterFcnt": 2438,
    //     "lostFcnt": -1993127,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4ae486",
    //     "messageTime": null
    // },
    // {
    //     "id": 131,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e621_ibox",
    //     "description": null,
    //     "provider": null,
    //     "userId": 52,
    //     "typeSensor": "32",
    //     "sensorTypeInfo": {
    //         "id": 32,
    //         "name": "0079e129d522e621_ibox_Type",
    //         "description": null,
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-08",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e621",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 136,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC01",
    //     "description": "Jaume-Calafate-1",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-07",
    //     "applicationName": null,
    //     "latitude": 39.097538,
    //     "longitude": -0.630113,
    //     "deviceEUI": "04b6480450034109",
    //     "appEUI": "app",
    //     "appKEY": "7F612E785A07E056205E9799861F619C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 326,
    //     "prevFrameCounterFcnt": 929,
    //     "latestFrameCounterFcnt": 951,
    //     "lostFcnt": 322,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 141,
    //     "gatewaysId": null,
    //     "name": "171fe129d522e001_wifi",
    //     "description": null,
    //     "provider": null,
    //     "userId": 54,
    //     "typeSensor": "41",
    //     "sensorTypeInfo": {
    //         "id": 41,
    //         "name": "171fe129d522e001_wifi_Type",
    //         "description": null,
    //         "sensorType": "wifi",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-11",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "171fe129d522e001",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -105,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2345,
    //     "prevFrameCounterFcnt": 128722,
    //     "latestFrameCounterFcnt": 128724,
    //     "lostFcnt": -691835,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb481840",
    //     "messageTime": null
    // },
    // {
    //     "id": 142,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC02",
    //     "description": "Jaume-Calafate-3",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-07",
    //     "applicationName": null,
    //     "latitude": 39.097584,
    //     "longitude": -0.630043,
    //     "deviceEUI": "04b6480450034057",
    //     "appEUI": "app",
    //     "appKEY": "CEF522F5F799AE91D158D7DCD72F8B7D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 296,
    //     "prevFrameCounterFcnt": 899,
    //     "latestFrameCounterFcnt": 900,
    //     "lostFcnt": 243,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 143,
    //     "gatewaysId": null,
    //     "name": "Sensor de Oficina Pruebas",
    //     "description": "para probar en el laboratorio",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "42",
    //     "sensorTypeInfo": {
    //         "id": 42,
    //         "name": "ibox prueba OFININA",
    //         "description": "para probar en el laboratorio",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-14",
    //     "applicationName": null,
    //     "latitude": 39.594463203832,
    //     "longitude": -0.55226997501051,
    //     "deviceEUI": "a079e129d522e624",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "door sensor"
    //         },
    //         {
    //             "inputComponent": "current sensor"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 146,
    //     "gatewaysId": null,
    //     "name": "METEOVINSWTC01",
    //     "description": null,
    //     "provider": null,
    //     "userId": 54,
    //     "typeSensor": "47",
    //     "sensorTypeInfo": {
    //         "id": 47,
    //         "name": "METEOGESWAT1",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "Modbus"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-03-11",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "141f3c71bff07fae",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -88,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1511,
    //     "prevFrameCounterFcnt": 1392,
    //     "latestFrameCounterFcnt": 1393,
    //     "lostFcnt": -904159,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb481840",
    //     "messageTime": null
    // },
    // {
    //     "id": 147,
    //     "gatewaysId": null,
    //     "name": "BOYAVINSWTC01",
    //     "description": null,
    //     "provider": null,
    //     "userId": 54,
    //     "typeSensor": "48",
    //     "sensorTypeInfo": {
    //         "id": 48,
    //         "name": "BOYAGESWAT",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-03-12",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0004a30b00f1a9ee",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 78,
    //     "latestFrameCounterFcnt": 79,
    //     "lostFcnt": -5,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb481840",
    //     "messageTime": null
    // },
    // {
    //     "id": 153,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC45",
    //     "description": "Escoleta-54",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-03-31",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034036",
    //     "appEUI": null,
    //     "appKEY": "50AEDDE4585EA2F15399040CF9C5B791",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -71,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 305,
    //     "prevFrameCounterFcnt": 816,
    //     "latestFrameCounterFcnt": 817,
    //     "lostFcnt": 110,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 154,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC04",
    //     "description": "Jaime-calafate-5",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-04-19",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033985",
    //     "appEUI": null,
    //     "appKEY": "5351D39D5D4182D65182C06735052FED",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 316,
    //     "prevFrameCounterFcnt": 536,
    //     "latestFrameCounterFcnt": 548,
    //     "lostFcnt": 71,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 155,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC05",
    //     "description": "Jaime-calafate-7",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-04-19",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034063",
    //     "appEUI": "2",
    //     "appKEY": "74CE37EAA32F093DE218F3577385C655",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 920,
    //     "latestFrameCounterFcnt": 934,
    //     "lostFcnt": 322,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 156,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC06",
    //     "description": "Jaime-calafate-9",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-04-19",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033996",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 342,
    //     "prevFrameCounterFcnt": 893,
    //     "latestFrameCounterFcnt": 895,
    //     "lostFcnt": 156,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 174,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-11",
    //     "description": "ibox instalado en el taller con dev eui a0......611",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-19",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e611",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 175,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-13",
    //     "description": "ibox instalado en el taller con dev eui a0......613",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-19",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e613",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 176,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-15",
    //     "description": "ibox instalado en el taller con dev eui a0......615",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-19",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e615",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 178,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-17",
    //     "description": "a079e129d522e617",
    //     "provider": null,
    //     "userId": 124,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-18",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e617",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -33,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 170,
    //     "latestFrameCounterFcnt": 171,
    //     "lostFcnt": -99540,
    //     "loraSNR": 7,
    //     "networkServerMac": "e45f016c7dbc",
    //     "messageTime": null
    // },
    // {
    //     "id": 179,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-19",
    //     "description": "ibox instalado en el taller con dev eui a0......619",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-18",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e619",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 180,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-21",
    //     "description": "ibox instalado en el taller con dev eui a0......621",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-18",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e621",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 181,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_plazadelacreu",
    //     "description": "ibox intalado en foios en la plaza de la creu en un cuadro en el parque",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5364359,
    //     "longitude": -0.3560338,
    //     "deviceEUI": "0079e129d522e609",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1925,
    //     "prevFrameCounterFcnt": 26,
    //     "latestFrameCounterFcnt": 27,
    //     "lostFcnt": -48751,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 182,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_alcaldefranciscoroceto",
    //     "description": "ibox instalado en mitad de una carretera unidireccional en la calle alcalde francisco roceto",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5359263,
    //     "longitude": -0.356997,
    //     "deviceEUI": "0079e129d522e607",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 558,
    //     "prevFrameCounterFcnt": 39,
    //     "latestFrameCounterFcnt": 41,
    //     "lostFcnt": -73661,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 183,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_ayuntamiento",
    //     "description": "0079e129d522e644",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5376272,
    //     "longitude": -0.3601489,
    //     "deviceEUI": "0079e129d522e644",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7134,
    //     "prevFrameCounterFcnt": 9,
    //     "latestFrameCounterFcnt": 10,
    //     "lostFcnt": -296819,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 184,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_sarieta",
    //     "description": "ibox  instalado en foios en la calle sarieta en el extremo del pueblo",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.539585,
    //     "longitude": -0.3599087,
    //     "deviceEUI": "0079e129d522e646",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 931,
    //     "prevFrameCounterFcnt": 3368,
    //     "latestFrameCounterFcnt": 3369,
    //     "lostFcnt": -66724,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 185,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_agenciaocupacio",
    //     "description": "ibox instalado en foios en el circulo concentrico exterior al lado de la casa de la ocupacio",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5404003,
    //     "longitude": -0.359261,
    //     "deviceEUI": "0079e129d522e641",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -124,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 2875,
    //     "prevFrameCounterFcnt": 4607,
    //     "latestFrameCounterFcnt": 4608,
    //     "lostFcnt": -106188,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 186,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_regnevalencia",
    //     "description": "ibox instalado en foios en el extremo oeste en la calle regne valencia",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5384722,
    //     "longitude": -0.3622443,
    //     "deviceEUI": "0079e129d522e640",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 302,
    //     "latestFrameCounterFcnt": 344,
    //     "lostFcnt": -702,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 187,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_caminolacruz",
    //     "description": "ibx instalado en foios en el extremo suroeste en la calle camino de la cruz",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5338611,
    //     "longitude": -0.3573831,
    //     "deviceEUI": "0079e129d522e634",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 145,
    //     "prevFrameCounterFcnt": 186,
    //     "latestFrameCounterFcnt": 187,
    //     "lostFcnt": -162678,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb3330b1",
    //     "messageTime": null
    // },
    // {
    //     "id": 189,
    //     "gatewaysId": null,
    //     "name": "Cuadro a.p AE",
    //     "description": "extremo este cercano a burguer king",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-23",
    //     "applicationName": null,
    //     "latitude": 39.424622,
    //     "longitude": -0.40511,
    //     "deviceEUI": "0079e129d522e615",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 190,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_bar",
    //     "description": "ibox al este de paiporta cerca de un bar",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-23",
    //     "applicationName": null,
    //     "latitude": 39.4252645,
    //     "longitude": -0.4089387,
    //     "deviceEUI": "0079e129d522e616",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -90,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 3118,
    //     "prevFrameCounterFcnt": 2,
    //     "latestFrameCounterFcnt": 3,
    //     "lostFcnt": -739446,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca632143f21",
    //     "messageTime": null
    // },
    // {
    //     "id": 191,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_parque",
    //     "description": "ibox al este de paiporta cerca de un parque",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4255038,
    //     "longitude": -0.4108358,
    //     "deviceEUI": "0079e129d522e617",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -107,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2313,
    //     "prevFrameCounterFcnt": 2437,
    //     "latestFrameCounterFcnt": 2438,
    //     "lostFcnt": -1993127,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4ae486",
    //     "messageTime": null
    // },
    // {
    //     "id": 192,
    //     "gatewaysId": null,
    //     "name": "Ibox_Paiporta_Cedisa",
    //     "description": "ibox paiporta en poligono cerca de taller de motos",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4274159,
    //     "longitude": -0.4065676,
    //     "deviceEUI": "0079e129d522e618",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 193,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_faitanar",
    //     "description": "ibox paiporta poligono calle faitanar a mitad",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4286756,
    //     "longitude": -0.4060418,
    //     "deviceEUI": "0079e129d522e620",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -63,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 18,
    //     "latestFrameCounterFcnt": 0,
    //     "lostFcnt": -776,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca632143f21",
    //     "messageTime": null
    // },
    // {
    //     "id": 194,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_lasenia",
    //     "description": "ibox paiporta al sureste en la salida del colegio la senia",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4212418,
    //     "longitude": -0.4151241,
    //     "deviceEUI": "0079e129d522e612",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 195,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_independencia",
    //     "description": "ibox paiporta al noroeste en la calle independencia",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4305412,
    //     "longitude": -0.4217051,
    //     "deviceEUI": "0079e129d522e622",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 197,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_maximiliano",
    //     "description": "ibox paiporta al norte en la calle maximiliano",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4333949,
    //     "longitude": -0.414235,
    //     "deviceEUI": "0079e129d522e625",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 198,
    //     "gatewaysId": null,
    //     "name": "Ibox_Paiporta_Primitiva",
    //     "description": "ibox en el centro norte calle banda primitiva",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4284961,
    //     "longitude": -0.4135304,
    //     "deviceEUI": "0079e129d522e619",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 199,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_acequia",
    //     "description": "ibox paiporta al este en la calle acequia",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4294963,
    //     "longitude": -0.4032379,
    //     "deviceEUI": "0079e129d522e621",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 200,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_acequiadequart",
    //     "description": "ibox paiporta al noreste calle acequia de quart",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4309699,
    //     "longitude": -0.4043883,
    //     "deviceEUI": "0079e129d522e624",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 201,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_acequiafaitanar",
    //     "description": "prueba_taller",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4336567,
    //     "longitude": -0.4040864,
    //     "deviceEUI": "0079e129d522e626",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 3,
    //     "rssi": -65,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 4592,
    //     "prevFrameCounterFcnt": 692,
    //     "latestFrameCounterFcnt": 696,
    //     "lostFcnt": -83622,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb4ae486",
    //     "messageTime": null
    // },
    // {
    //     "id": 202,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_ausiasmarch",
    //     "description": "ibos paiporta centro noroeste en calle ausias march",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4306035,
    //     "longitude": -0.416513,
    //     "deviceEUI": "0079e129d522e623",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 203,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_quart",
    //     "description": "ibox paiporta al suroeste en calle quart de poblet",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4226541,
    //     "longitude": -0.4174941,
    //     "deviceEUI": "0079e129d522e613",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 204,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_polideportivo",
    //     "description": "ibox paiporta  al sur instalado dentro del polideportivo",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4247485,
    //     "longitude": -0.4143086,
    //     "deviceEUI": "0079e129d522e614",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 209,
    //     "gatewaysId": null,
    //     "name": "a079e129d522e616",
    //     "description": "a079e129d522e611",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "31",
    //     "sensorTypeInfo": {
    //         "id": 31,
    //         "name": "iBOX_Paiporta",
    //         "description": "IBOX Ayto. Paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "a079e129d522e611",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 210,
    //     "gatewaysId": null,
    //     "name": "a079e129d522e616",
    //     "description": "ibox instalado en paiporta al este del centro al lado de un burguerking",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "20",
    //     "sensorTypeInfo": {
    //         "id": 20,
    //         "name": "IBOX-PUERTA-TEST",
    //         "description": "Puerta TEST",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-01",
    //     "applicationName": null,
    //     "latitude": 39.422413,
    //     "longitude": -0.405982,
    //     "deviceEUI": "a079e129d522e616",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "a"
    //         },
    //         {
    //             "inputComponent": "b"
    //         },
    //         {
    //             "inputComponent": "c"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 220,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC01",
    //     "description": "constitucion-20",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bd5",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 290,
    //     "prevFrameCounterFcnt": 1339,
    //     "latestFrameCounterFcnt": 1340,
    //     "lostFcnt": 320,
    //     "loraSNR": -16,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 222,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC03",
    //     "description": "constitucion-14",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bd4",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 608,
    //     "prevFrameCounterFcnt": 1668,
    //     "latestFrameCounterFcnt": 1669,
    //     "lostFcnt": 211,
    //     "loraSNR": -12,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 223,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC05",
    //     "description": "constitucion-30",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bd3",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 59,
    //     "prevFrameCounterFcnt": 803,
    //     "latestFrameCounterFcnt": 804,
    //     "lostFcnt": 124,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 224,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC06",
    //     "description": "constitucion-36",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a83f",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 50,
    //     "prevFrameCounterFcnt": 28,
    //     "latestFrameCounterFcnt": 34,
    //     "lostFcnt": -26,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 225,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC09",
    //     "description": "Gandia-29",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a841",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": -112,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 276,
    //     "prevFrameCounterFcnt": 563,
    //     "latestFrameCounterFcnt": 564,
    //     "lostFcnt": -187,
    //     "loraSNR": -13,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 226,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC10",
    //     "description": "Gandia-28",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872776b9",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 9,
    //     "prevFrameCounterFcnt": 55,
    //     "latestFrameCounterFcnt": 1,
    //     "lostFcnt": -67,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 227,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC13",
    //     "description": "convento-51",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872776b8",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 674,
    //     "latestFrameCounterFcnt": 675,
    //     "lostFcnt": 278,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 228,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC15",
    //     "description": "Convento-34",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a397",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 312,
    //     "prevFrameCounterFcnt": 813,
    //     "latestFrameCounterFcnt": 814,
    //     "lostFcnt": -240,
    //     "loraSNR": -18,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 229,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC03",
    //     "description": "Jaime-calafate-2acc",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450031548",
    //     "appEUI": "2",
    //     "appKEY": "C5B55C8B19A92D638F6B67F376110B06",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 311,
    //     "prevFrameCounterFcnt": 1064,
    //     "latestFrameCounterFcnt": 1068,
    //     "lostFcnt": 516,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 230,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC07",
    //     "description": "Jaime-calafate-2",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033989",
    //     "appEUI": "2",
    //     "appKEY": "23435DA6A2AEAC81381E5737A2FF1E6A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 299,
    //     "prevFrameCounterFcnt": 851,
    //     "latestFrameCounterFcnt": 852,
    //     "lostFcnt": 163,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 231,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC12",
    //     "description": "Rei-en-Jaume-14",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034077",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 284,
    //     "prevFrameCounterFcnt": 807,
    //     "latestFrameCounterFcnt": 808,
    //     "lostFcnt": 108,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 232,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC13",
    //     "description": "Rei-en-Jaume-16",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033968",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 286,
    //     "prevFrameCounterFcnt": 834,
    //     "latestFrameCounterFcnt": 835,
    //     "lostFcnt": 189,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 233,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC14",
    //     "description": "Rei-en-Jaume-18",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034101",
    //     "appEUI": "2",
    //     "appKEY": "9BFC7FFB32842F714F940742B515F57D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 303,
    //     "prevFrameCounterFcnt": 835,
    //     "latestFrameCounterFcnt": 836,
    //     "lostFcnt": 138,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 234,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC15",
    //     "description": "Rei-en-Jaume-22",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033917",
    //     "appEUI": "2",
    //     "appKEY": "BEA29B6ACDB3E8F7C2251D8CAEFABB95",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 279,
    //     "prevFrameCounterFcnt": 794,
    //     "latestFrameCounterFcnt": 795,
    //     "lostFcnt": 119,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 235,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC16",
    //     "description": "Rei-en-Jaume-24",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033982",
    //     "appEUI": "app",
    //     "appKEY": "3A29D9FB7ED836C8048ACA3AE368357F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 299,
    //     "prevFrameCounterFcnt": 815,
    //     "latestFrameCounterFcnt": 816,
    //     "lostFcnt": 110,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 236,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC17",
    //     "description": "Rei-en-Jaume-26",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033916",
    //     "appEUI": "app",
    //     "appKEY": "4503E4F51C69D7D6EF3B0C2871CA8241",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 297,
    //     "prevFrameCounterFcnt": 862,
    //     "latestFrameCounterFcnt": 863,
    //     "lostFcnt": 185,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 237,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC18",
    //     "description": "Rei-en-Jaume-30",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033993",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 795,
    //     "latestFrameCounterFcnt": 796,
    //     "lostFcnt": 93,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 238,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC19",
    //     "description": "Rei-en-JAume-44",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034016",
    //     "appEUI": "app",
    //     "appKEY": "0767D7630F287DB586B99FF4B086EF95",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -99,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 790,
    //     "latestFrameCounterFcnt": 791,
    //     "lostFcnt": 94,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 239,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC21",
    //     "description": "Escoleta-80",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034108",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 310,
    //     "prevFrameCounterFcnt": 809,
    //     "latestFrameCounterFcnt": 810,
    //     "lostFcnt": 84,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 240,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC22",
    //     "description": "Escoleta-78",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034031",
    //     "appEUI": null,
    //     "appKEY": "22F8B490D8D0318B12C1961F1F48A772",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -78,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 286,
    //     "prevFrameCounterFcnt": 785,
    //     "latestFrameCounterFcnt": 786,
    //     "lostFcnt": 87,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 241,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC23",
    //     "description": "Escoleta-79",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034040",
    //     "appEUI": "app",
    //     "appKEY": "3E42B8A57822B1C82C5A63BD396ECECD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -95,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 294,
    //     "prevFrameCounterFcnt": 810,
    //     "latestFrameCounterFcnt": 811,
    //     "lostFcnt": 88,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 242,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC24",
    //     "description": "Escoleta-81-A",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033955",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -83,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 270,
    //     "prevFrameCounterFcnt": 757,
    //     "latestFrameCounterFcnt": 758,
    //     "lostFcnt": 81,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 243,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC25",
    //     "description": "Escoleta-77",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033936",
    //     "appEUI": "app",
    //     "appKEY": "8A2BDBE3960C9C476EAA9D57617E6965",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 318,
    //     "prevFrameCounterFcnt": 836,
    //     "latestFrameCounterFcnt": 837,
    //     "lostFcnt": 116,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 244,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC26",
    //     "description": "Escoleta-76-bajo",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028716",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -79,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 294,
    //     "prevFrameCounterFcnt": 783,
    //     "latestFrameCounterFcnt": 784,
    //     "lostFcnt": 87,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 245,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC27",
    //     "description": "Escoleta-76-1",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034095",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -87,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 274,
    //     "prevFrameCounterFcnt": 763,
    //     "latestFrameCounterFcnt": 764,
    //     "lostFcnt": 85,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 246,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC28",
    //     "description": "Escoleta-74",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034110",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -97,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 299,
    //     "prevFrameCounterFcnt": 802,
    //     "latestFrameCounterFcnt": 803,
    //     "lostFcnt": 95,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 247,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC29",
    //     "description": "Escoleta-72",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033952",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -91,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 278,
    //     "prevFrameCounterFcnt": 751,
    //     "latestFrameCounterFcnt": 752,
    //     "lostFcnt": 78,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 248,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC30",
    //     "description": "Escoleta-75",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033925",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 312,
    //     "prevFrameCounterFcnt": 836,
    //     "latestFrameCounterFcnt": 837,
    //     "lostFcnt": 102,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 249,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC31",
    //     "description": "Escoleta-73",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033986",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -85,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 288,
    //     "prevFrameCounterFcnt": 803,
    //     "latestFrameCounterFcnt": 804,
    //     "lostFcnt": 100,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 250,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC32",
    //     "description": "Escoleta-71",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033942",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 271,
    //     "prevFrameCounterFcnt": 784,
    //     "latestFrameCounterFcnt": 785,
    //     "lostFcnt": 98,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 251,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC33",
    //     "description": "Escoleta-67",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034038",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 290,
    //     "prevFrameCounterFcnt": 811,
    //     "latestFrameCounterFcnt": 812,
    //     "lostFcnt": 109,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 252,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC34",
    //     "description": "Escoleta-65",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033979",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -78,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 776,
    //     "latestFrameCounterFcnt": 777,
    //     "lostFcnt": 81,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 253,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC35",
    //     "description": "Escoleta-63",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033970",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -84,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 298,
    //     "prevFrameCounterFcnt": 796,
    //     "latestFrameCounterFcnt": 797,
    //     "lostFcnt": 89,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 254,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC36",
    //     "description": "Escoleta-70",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032093",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -97,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 300,
    //     "prevFrameCounterFcnt": 823,
    //     "latestFrameCounterFcnt": 825,
    //     "lostFcnt": 119,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 255,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC37",
    //     "description": "Esoleta-68",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032107",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 294,
    //     "prevFrameCounterFcnt": 782,
    //     "latestFrameCounterFcnt": 783,
    //     "lostFcnt": 82,
    //     "loraSNR": 12,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 256,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC38",
    //     "description": "Escoleta-66",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034001",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -95,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 277,
    //     "prevFrameCounterFcnt": 800,
    //     "latestFrameCounterFcnt": 801,
    //     "lostFcnt": 113,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 257,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC39",
    //     "description": "Escoleta-64",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034033",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 295,
    //     "prevFrameCounterFcnt": 807,
    //     "latestFrameCounterFcnt": 808,
    //     "lostFcnt": 109,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 258,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC40",
    //     "description": "Escoleta-62",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034037",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -103,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 291,
    //     "prevFrameCounterFcnt": 778,
    //     "latestFrameCounterFcnt": 779,
    //     "lostFcnt": 79,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 259,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC41",
    //     "description": "Escoleta-60",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034010",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -77,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 294,
    //     "prevFrameCounterFcnt": 805,
    //     "latestFrameCounterFcnt": 806,
    //     "lostFcnt": 113,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 260,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC42",
    //     "description": "Escoleta-58",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034074",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -74,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 268,
    //     "prevFrameCounterFcnt": 746,
    //     "latestFrameCounterFcnt": 747,
    //     "lostFcnt": 78,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 261,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC43",
    //     "description": "Escoleta-56",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034013",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -72,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 302,
    //     "prevFrameCounterFcnt": 838,
    //     "latestFrameCounterFcnt": 839,
    //     "lostFcnt": 119,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 262,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC44",
    //     "description": "Escoleta-52",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033997",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -79,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 282,
    //     "prevFrameCounterFcnt": 771,
    //     "latestFrameCounterFcnt": 772,
    //     "lostFcnt": 75,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 263,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC46",
    //     "description": "Escoleta-61",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034000",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 791,
    //     "latestFrameCounterFcnt": 792,
    //     "lostFcnt": 98,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 264,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC47",
    //     "description": "Escoleta-59",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034003",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -102,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 790,
    //     "latestFrameCounterFcnt": 791,
    //     "lostFcnt": 85,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 265,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC48",
    //     "description": "Escoleta-48",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034088",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -89,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 297,
    //     "prevFrameCounterFcnt": 813,
    //     "latestFrameCounterFcnt": 815,
    //     "lostFcnt": 102,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 266,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC49",
    //     "description": "Escoleta-57B",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033972",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -100,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 290,
    //     "prevFrameCounterFcnt": 799,
    //     "latestFrameCounterFcnt": 800,
    //     "lostFcnt": 108,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 267,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC50",
    //     "description": "Escoleta-57",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034071",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -97,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 282,
    //     "prevFrameCounterFcnt": 798,
    //     "latestFrameCounterFcnt": 799,
    //     "lostFcnt": 99,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 268,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC51",
    //     "description": "Escoleta-46",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033957",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -80,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 268,
    //     "prevFrameCounterFcnt": 753,
    //     "latestFrameCounterFcnt": 754,
    //     "lostFcnt": 76,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 269,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC52",
    //     "description": "Escoleta-44",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034115",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 277,
    //     "prevFrameCounterFcnt": 760,
    //     "latestFrameCounterFcnt": 761,
    //     "lostFcnt": 81,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 270,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC54",
    //     "description": "Escoleta-43",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033991",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 786,
    //     "latestFrameCounterFcnt": 787,
    //     "lostFcnt": 100,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 271,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC55",
    //     "description": "Escoleta-38",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034082",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -106,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 266,
    //     "prevFrameCounterFcnt": 741,
    //     "latestFrameCounterFcnt": 742,
    //     "lostFcnt": 76,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 272,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC56",
    //     "description": "Escoleta-36",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034072",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 279,
    //     "prevFrameCounterFcnt": 752,
    //     "latestFrameCounterFcnt": 753,
    //     "lostFcnt": 79,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 273,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC57",
    //     "description": "Escoleta-41",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033912",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -102,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 306,
    //     "prevFrameCounterFcnt": 797,
    //     "latestFrameCounterFcnt": 798,
    //     "lostFcnt": 95,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 274,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC58",
    //     "description": "Escoleta-34",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034070",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -105,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 775,
    //     "latestFrameCounterFcnt": 776,
    //     "lostFcnt": 92,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 275,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC59",
    //     "description": "Escoleta-39",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033976",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 302,
    //     "prevFrameCounterFcnt": 805,
    //     "latestFrameCounterFcnt": 806,
    //     "lostFcnt": 95,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 276,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC60",
    //     "description": "Escoleta-37",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033974",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 799,
    //     "latestFrameCounterFcnt": 800,
    //     "lostFcnt": 100,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 277,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC61",
    //     "description": "Escoleta-32",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032095",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 304,
    //     "prevFrameCounterFcnt": 804,
    //     "latestFrameCounterFcnt": 805,
    //     "lostFcnt": 93,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 278,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC62",
    //     "description": "Escoleta-30",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034015",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 301,
    //     "prevFrameCounterFcnt": 789,
    //     "latestFrameCounterFcnt": 790,
    //     "lostFcnt": 99,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 279,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC63",
    //     "description": "Escoleta-28",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032067",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 292,
    //     "prevFrameCounterFcnt": 823,
    //     "latestFrameCounterFcnt": 824,
    //     "lostFcnt": 136,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 280,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC64",
    //     "description": "Escoleta-35",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034024",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 800,
    //     "latestFrameCounterFcnt": 801,
    //     "lostFcnt": 105,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 281,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC65",
    //     "description": "Escoleta-24",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033964",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 277,
    //     "prevFrameCounterFcnt": 799,
    //     "latestFrameCounterFcnt": 800,
    //     "lostFcnt": 113,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 282,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC66",
    //     "description": "Escoleta-22",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034043",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 286,
    //     "prevFrameCounterFcnt": 859,
    //     "latestFrameCounterFcnt": 861,
    //     "lostFcnt": 160,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 283,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC67",
    //     "description": "Escoleta-29",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034102",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 280,
    //     "prevFrameCounterFcnt": 795,
    //     "latestFrameCounterFcnt": 796,
    //     "lostFcnt": 105,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 284,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC68",
    //     "description": "Escoleta-20",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033963",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -110,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 295,
    //     "prevFrameCounterFcnt": 834,
    //     "latestFrameCounterFcnt": 835,
    //     "lostFcnt": 138,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 285,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC70",
    //     "description": "Escoleta-23",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034034",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -111,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 282,
    //     "prevFrameCounterFcnt": 819,
    //     "latestFrameCounterFcnt": 820,
    //     "lostFcnt": 135,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 286,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC69",
    //     "description": "Escoleta-27",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034096",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 279,
    //     "prevFrameCounterFcnt": 810,
    //     "latestFrameCounterFcnt": 811,
    //     "lostFcnt": 138,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 287,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC71",
    //     "description": "Escoleta-21",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034049",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 280,
    //     "prevFrameCounterFcnt": 797,
    //     "latestFrameCounterFcnt": 798,
    //     "lostFcnt": 105,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 288,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC72",
    //     "description": "Escoleta-19",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033959",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 282,
    //     "prevFrameCounterFcnt": 788,
    //     "latestFrameCounterFcnt": 789,
    //     "lostFcnt": 96,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 289,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC73",
    //     "description": "Escoleta-16",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034094",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 288,
    //     "prevFrameCounterFcnt": 771,
    //     "latestFrameCounterFcnt": 772,
    //     "lostFcnt": 77,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 290,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC74",
    //     "description": "Escoleta-14",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034114",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 303,
    //     "prevFrameCounterFcnt": 811,
    //     "latestFrameCounterFcnt": 812,
    //     "lostFcnt": 113,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 291,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC75",
    //     "description": "Escoleta-15",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033967",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 270,
    //     "prevFrameCounterFcnt": 811,
    //     "latestFrameCounterFcnt": 812,
    //     "lostFcnt": 145,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 292,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC76",
    //     "description": "Escoleta-13",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034014",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 820,
    //     "latestFrameCounterFcnt": 821,
    //     "lostFcnt": 153,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 293,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC77",
    //     "description": "Escoleta-12",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033929",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 330,
    //     "prevFrameCounterFcnt": 878,
    //     "latestFrameCounterFcnt": 879,
    //     "lostFcnt": 140,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 294,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC78",
    //     "description": "Escoleta-10",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034112",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 270,
    //     "prevFrameCounterFcnt": 786,
    //     "latestFrameCounterFcnt": 787,
    //     "lostFcnt": 117,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 295,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC79",
    //     "description": "Escoleta-8",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034064",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 301,
    //     "prevFrameCounterFcnt": 806,
    //     "latestFrameCounterFcnt": 807,
    //     "lostFcnt": 104,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 296,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC80",
    //     "description": "Escoleta-11",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034027",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 299,
    //     "prevFrameCounterFcnt": 806,
    //     "latestFrameCounterFcnt": 807,
    //     "lostFcnt": 89,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 297,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC81",
    //     "description": "Escoleta-6",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033998",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 293,
    //     "prevFrameCounterFcnt": 825,
    //     "latestFrameCounterFcnt": 827,
    //     "lostFcnt": 117,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 298,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC82",
    //     "description": "Escoleta-4",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034019",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 788,
    //     "latestFrameCounterFcnt": 789,
    //     "lostFcnt": 94,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 299,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC83",
    //     "description": "Escoleta-3",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034059",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 281,
    //     "prevFrameCounterFcnt": 794,
    //     "latestFrameCounterFcnt": 795,
    //     "lostFcnt": 126,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 300,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC84",
    //     "description": "Escoleta-5",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034104",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 808,
    //     "latestFrameCounterFcnt": 809,
    //     "lostFcnt": 117,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 301,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC85",
    //     "description": "Escoleta-7",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033914",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -108,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 307,
    //     "prevFrameCounterFcnt": 823,
    //     "latestFrameCounterFcnt": 824,
    //     "lostFcnt": 104,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 302,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC86",
    //     "description": "Escoleta9",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450045529",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 303,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC87",
    //     "description": "Escoleta-69",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034091",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 777,
    //     "latestFrameCounterFcnt": 778,
    //     "lostFcnt": 81,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 304,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC88",
    //     "description": "Escoleta-74bis",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034021",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -82,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 273,
    //     "prevFrameCounterFcnt": 793,
    //     "latestFrameCounterFcnt": 794,
    //     "lostFcnt": 97,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 305,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC89",
    //     "description": "Escoleta-42",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033949",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 271,
    //     "prevFrameCounterFcnt": 763,
    //     "latestFrameCounterFcnt": 764,
    //     "lostFcnt": 85,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 306,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC90",
    //     "description": "Escoleta-53",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034009",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -103,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 313,
    //     "prevFrameCounterFcnt": 841,
    //     "latestFrameCounterFcnt": 842,
    //     "lostFcnt": 122,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 307,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC91",
    //     "description": "Escoleta-45",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034011",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 288,
    //     "prevFrameCounterFcnt": 791,
    //     "latestFrameCounterFcnt": 792,
    //     "lostFcnt": 94,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 308,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC92",
    //     "description": "Escoleta-25",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033948",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 290,
    //     "prevFrameCounterFcnt": 840,
    //     "latestFrameCounterFcnt": 841,
    //     "lostFcnt": 172,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 309,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC93",
    //     "description": "Rei-en-Jaume-10",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033924",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 331,
    //     "prevFrameCounterFcnt": 1029,
    //     "latestFrameCounterFcnt": 1030,
    //     "lostFcnt": 385,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 107,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e566_sensor",
    //     "description": "ibox sensor",
    //     "provider": null,
    //     "userId": 32,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-06-15",
    //     "applicationName": null,
    //     "latitude": 39.120249,
    //     "longitude": -0.454043,
    //     "deviceEUI": "0079e129d522e566",
    //     "appEUI": "12",
    //     "appKEY": "12",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 197579,
    //     "prevFrameCounterFcnt": 1409,
    //     "latestFrameCounterFcnt": 1409,
    //     "lostFcnt": -778212,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb3f1042",
    //     "messageTime": null
    // },
    // {
    //     "id": 108,
    //     "gatewaysId": null,
    //     "name": "testIbox",
    //     "description": "For testing All",
    //     "provider": null,
    //     "userId": 32,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-06-18",
    //     "applicationName": null,
    //     "latitude": 39.427242,
    //     "longitude": -1.114482,
    //     "deviceEUI": "0079e129d522e634",
    //     "appEUI": "12",
    //     "appKEY": "12",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "inputcmp1"
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": "metercmp1"
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 145,
    //     "prevFrameCounterFcnt": 186,
    //     "latestFrameCounterFcnt": 187,
    //     "lostFcnt": -162678,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb3330b1",
    //     "messageTime": null
    // },
    // {
    //     "id": 109,
    //     "gatewaysId": null,
    //     "name": "Sensor_ibox_Alberto",
    //     "description": "IBOX TEST",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "16",
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-06-24",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e566",
    //     "appEUI": "06263577c3bbc9eb",
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "DoorC01"
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": "EnergyMeterVIPAC01"
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 197579,
    //     "prevFrameCounterFcnt": 1409,
    //     "latestFrameCounterFcnt": 1409,
    //     "lostFcnt": -778212,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb3f1042",
    //     "messageTime": null
    // },
    // {
    //     "id": 110,
    //     "gatewaysId": null,
    //     "name": "IBOX1_Biblio",
    //     "description": "iBOX_GESWAT_e647",
    //     "provider": null,
    //     "userId": 36,
    //     "typeSensor": "20",
    //     "sensorTypeInfo": {
    //         "id": 20,
    //         "name": "IBOX-PUERTA-TEST",
    //         "description": "Puerta TEST",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-06-26",
    //     "applicationName": null,
    //     "latitude": 39.567724,
    //     "longitude": -0.622264,
    //     "deviceEUI": "0079e129d522e647",
    //     "appEUI": "06263577c3bbc9eb",
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb66bea3,b827eb6c4e09,dca63214b1d0,b827eb11f2e1",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 115,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e631_doorLibrary",
    //     "description": "iBOX_GESWAT_e631",
    //     "provider": null,
    //     "userId": 77,
    //     "typeSensor": "89",
    //     "sensorTypeInfo": {
    //         "id": 89,
    //         "name": "Olmos_ibox",
    //         "description": null,
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-07-15",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e631",
    //     "appEUI": null,
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": "b827eb524297",
    //     "dr": 3,
    //     "rssi": -125,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 682,
    //     "latestFrameCounterFcnt": 1494,
    //     "lostFcnt": -322249,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb524297",
    //     "messageTime": null
    // },
    // {
    //     "id": 117,
    //     "gatewaysId": null,
    //     "name": "iBOX_Biblioteca",
    //     "description": "Quatretonda iBox Biblioteca",
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": "21",
    //     "sensorTypeInfo": {
    //         "id": 21,
    //         "name": "iBOX_Puerta",
    //         "description": "Ibox Biblioteca",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-07-19",
    //     "applicationName": null,
    //     "latitude": 38.945463,
    //     "longitude": -0.399479,
    //     "deviceEUI": "0079e129d522e629",
    //     "appEUI": "06263577c3bbc9eb",
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "EnergyMeterQUAGWTC01"
    //         },
    //         {
    //             "inputComponent": "EnergyMeterQUAGWTC02"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -57,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 5066,
    //     "prevFrameCounterFcnt": 4373,
    //     "latestFrameCounterFcnt": 4374,
    //     "lostFcnt": -784639,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 118,
    //     "gatewaysId": null,
    //     "name": "iBOX_Ayuntamiento",
    //     "description": "iBOX Ayuntamiento",
    //     "provider": null,
    //     "userId": 41,
    //     "typeSensor": "22",
    //     "sensorTypeInfo": {
    //         "id": 22,
    //         "name": "iBOX_Ayuntamiento",
    //         "description": "iBOX Ayuntamiento",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-07-19",
    //     "applicationName": null,
    //     "latitude": 38.853991,
    //     "longitude": -0.502074,
    //     "deviceEUI": "0079e129d522e630",
    //     "appEUI": "06263577c3bbc9eb",
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "EnergyMeterEARCTMC01"
    //         },
    //         {
    //             "inputComponent": "EnergyMeterEARCTMC02"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -98,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 326,
    //     "prevFrameCounterFcnt": 2407,
    //     "latestFrameCounterFcnt": 2408,
    //     "lostFcnt": -753304,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827ebf0f17b",
    //     "messageTime": null
    // },
    // {
    //     "id": 121,
    //     "gatewaysId": null,
    //     "name": "171fe129d522e000_wifi",
    //     "description": "Wifi Sensor",
    //     "provider": null,
    //     "userId": 36,
    //     "typeSensor": "27",
    //     "sensorTypeInfo": {
    //         "id": 27,
    //         "name": "171fe129d522e000_wifi_Type",
    //         "description": null,
    //         "sensorType": "wifi",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-08-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "171fe129d522e000",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb66bea3,b827eb6c4e09,dca63214b1d0,b827eb11f2e1",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 123,
    //     "gatewaysId": null,
    //     "name": "Alberto_Puerta",
    //     "description": "Test puerta",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "21",
    //     "sensorTypeInfo": {
    //         "id": 21,
    //         "name": "iBOX_Puerta",
    //         "description": "Ibox Biblioteca",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-10-06",
    //     "applicationName": null,
    //     "latitude": 39.592868,
    //     "longitude": -0.544343,
    //     "deviceEUI": "0079e129d522e630",
    //     "appEUI": "06263577C3BBC9EB2FEF99135EF0F6A5",
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "DoorC01"
    //         },
    //         {
    //             "inputComponent": "DoorC02"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -98,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 326,
    //     "prevFrameCounterFcnt": 2407,
    //     "latestFrameCounterFcnt": 2408,
    //     "lostFcnt": -753304,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827ebf0f17b",
    //     "messageTime": null
    // },
    // {
    //     "id": 124,
    //     "gatewaysId": null,
    //     "name": "DCSWAGWS0000000008",
    //     "description": "water castellon itron",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "30",
    //     "sensorTypeInfo": {
    //         "id": 30,
    //         "name": "water_itron",
    //         "description": "for water",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-11-25",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "532253811817804e",
    //     "appEUI": "00000000000000000",
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 125,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e613_N",
    //     "description": "0079e129d522e613_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e613",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 126,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e614_N",
    //     "description": "0079e129d522e614_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e614",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 127,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e615_N",
    //     "description": "0079e129d522e615_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e615",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 128,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e616_N",
    //     "description": "0079e129d522e616_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e616",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -90,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 3118,
    //     "prevFrameCounterFcnt": 2,
    //     "latestFrameCounterFcnt": 3,
    //     "lostFcnt": -739446,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca632143f21",
    //     "messageTime": null
    // },
    // {
    //     "id": 129,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e617_N",
    //     "description": "0079e129d522e617_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e617",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -107,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2313,
    //     "prevFrameCounterFcnt": 2437,
    //     "latestFrameCounterFcnt": 2438,
    //     "lostFcnt": -1993127,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4ae486",
    //     "messageTime": null
    // },
    // {
    //     "id": 131,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e621_ibox",
    //     "description": null,
    //     "provider": null,
    //     "userId": 52,
    //     "typeSensor": "32",
    //     "sensorTypeInfo": {
    //         "id": 32,
    //         "name": "0079e129d522e621_ibox_Type",
    //         "description": null,
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-08",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e621",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 136,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC01",
    //     "description": "Jaume-Calafate-1",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-07",
    //     "applicationName": null,
    //     "latitude": 39.097538,
    //     "longitude": -0.630113,
    //     "deviceEUI": "04b6480450034109",
    //     "appEUI": "app",
    //     "appKEY": "7F612E785A07E056205E9799861F619C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 326,
    //     "prevFrameCounterFcnt": 929,
    //     "latestFrameCounterFcnt": 951,
    //     "lostFcnt": 322,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 141,
    //     "gatewaysId": null,
    //     "name": "171fe129d522e001_wifi",
    //     "description": null,
    //     "provider": null,
    //     "userId": 54,
    //     "typeSensor": "41",
    //     "sensorTypeInfo": {
    //         "id": 41,
    //         "name": "171fe129d522e001_wifi_Type",
    //         "description": null,
    //         "sensorType": "wifi",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-11",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "171fe129d522e001",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -105,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2345,
    //     "prevFrameCounterFcnt": 128722,
    //     "latestFrameCounterFcnt": 128724,
    //     "lostFcnt": -691835,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb481840",
    //     "messageTime": null
    // },
    // {
    //     "id": 142,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC02",
    //     "description": "Jaume-Calafate-3",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-07",
    //     "applicationName": null,
    //     "latitude": 39.097584,
    //     "longitude": -0.630043,
    //     "deviceEUI": "04b6480450034057",
    //     "appEUI": "app",
    //     "appKEY": "CEF522F5F799AE91D158D7DCD72F8B7D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 296,
    //     "prevFrameCounterFcnt": 899,
    //     "latestFrameCounterFcnt": 900,
    //     "lostFcnt": 243,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 143,
    //     "gatewaysId": null,
    //     "name": "Sensor de Oficina Pruebas",
    //     "description": "para probar en el laboratorio",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "42",
    //     "sensorTypeInfo": {
    //         "id": 42,
    //         "name": "ibox prueba OFININA",
    //         "description": "para probar en el laboratorio",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-14",
    //     "applicationName": null,
    //     "latitude": 39.594463203832,
    //     "longitude": -0.55226997501051,
    //     "deviceEUI": "a079e129d522e624",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "door sensor"
    //         },
    //         {
    //             "inputComponent": "current sensor"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 146,
    //     "gatewaysId": null,
    //     "name": "METEOVINSWTC01",
    //     "description": null,
    //     "provider": null,
    //     "userId": 54,
    //     "typeSensor": "47",
    //     "sensorTypeInfo": {
    //         "id": 47,
    //         "name": "METEOGESWAT1",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "Modbus"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-03-11",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "141f3c71bff07fae",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -88,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1511,
    //     "prevFrameCounterFcnt": 1392,
    //     "latestFrameCounterFcnt": 1393,
    //     "lostFcnt": -904159,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb481840",
    //     "messageTime": null
    // },
    // {
    //     "id": 147,
    //     "gatewaysId": null,
    //     "name": "BOYAVINSWTC01",
    //     "description": null,
    //     "provider": null,
    //     "userId": 54,
    //     "typeSensor": "48",
    //     "sensorTypeInfo": {
    //         "id": 48,
    //         "name": "BOYAGESWAT",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-03-12",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0004a30b00f1a9ee",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 78,
    //     "latestFrameCounterFcnt": 79,
    //     "lostFcnt": -5,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb481840",
    //     "messageTime": null
    // },
    // {
    //     "id": 153,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC45",
    //     "description": "Escoleta-54",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-03-31",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034036",
    //     "appEUI": null,
    //     "appKEY": "50AEDDE4585EA2F15399040CF9C5B791",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -71,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 305,
    //     "prevFrameCounterFcnt": 816,
    //     "latestFrameCounterFcnt": 817,
    //     "lostFcnt": 110,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 154,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC04",
    //     "description": "Jaime-calafate-5",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-04-19",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033985",
    //     "appEUI": null,
    //     "appKEY": "5351D39D5D4182D65182C06735052FED",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 316,
    //     "prevFrameCounterFcnt": 536,
    //     "latestFrameCounterFcnt": 548,
    //     "lostFcnt": 71,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 155,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC05",
    //     "description": "Jaime-calafate-7",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-04-19",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034063",
    //     "appEUI": "2",
    //     "appKEY": "74CE37EAA32F093DE218F3577385C655",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 920,
    //     "latestFrameCounterFcnt": 934,
    //     "lostFcnt": 322,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 156,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC06",
    //     "description": "Jaime-calafate-9",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-04-19",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033996",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 342,
    //     "prevFrameCounterFcnt": 893,
    //     "latestFrameCounterFcnt": 895,
    //     "lostFcnt": 156,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 174,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-11",
    //     "description": "ibox instalado en el taller con dev eui a0......611",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-19",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e611",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 175,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-13",
    //     "description": "ibox instalado en el taller con dev eui a0......613",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-19",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e613",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 176,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-15",
    //     "description": "ibox instalado en el taller con dev eui a0......615",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-19",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e615",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 178,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-17",
    //     "description": "a079e129d522e617",
    //     "provider": null,
    //     "userId": 124,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-18",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e617",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -33,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 170,
    //     "latestFrameCounterFcnt": 171,
    //     "lostFcnt": -99540,
    //     "loraSNR": 7,
    //     "networkServerMac": "e45f016c7dbc",
    //     "messageTime": null
    // },
    // {
    //     "id": 179,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-19",
    //     "description": "ibox instalado en el taller con dev eui a0......619",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-18",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e619",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 180,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-21",
    //     "description": "ibox instalado en el taller con dev eui a0......621",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-18",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e621",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 181,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_plazadelacreu",
    //     "description": "ibox intalado en foios en la plaza de la creu en un cuadro en el parque",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5364359,
    //     "longitude": -0.3560338,
    //     "deviceEUI": "0079e129d522e609",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1925,
    //     "prevFrameCounterFcnt": 26,
    //     "latestFrameCounterFcnt": 27,
    //     "lostFcnt": -48751,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 182,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_alcaldefranciscoroceto",
    //     "description": "ibox instalado en mitad de una carretera unidireccional en la calle alcalde francisco roceto",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5359263,
    //     "longitude": -0.356997,
    //     "deviceEUI": "0079e129d522e607",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 558,
    //     "prevFrameCounterFcnt": 39,
    //     "latestFrameCounterFcnt": 41,
    //     "lostFcnt": -73661,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 183,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_ayuntamiento",
    //     "description": "0079e129d522e644",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5376272,
    //     "longitude": -0.3601489,
    //     "deviceEUI": "0079e129d522e644",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7134,
    //     "prevFrameCounterFcnt": 9,
    //     "latestFrameCounterFcnt": 10,
    //     "lostFcnt": -296819,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 184,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_sarieta",
    //     "description": "ibox  instalado en foios en la calle sarieta en el extremo del pueblo",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.539585,
    //     "longitude": -0.3599087,
    //     "deviceEUI": "0079e129d522e646",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 931,
    //     "prevFrameCounterFcnt": 3368,
    //     "latestFrameCounterFcnt": 3369,
    //     "lostFcnt": -66724,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 185,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_agenciaocupacio",
    //     "description": "ibox instalado en foios en el circulo concentrico exterior al lado de la casa de la ocupacio",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5404003,
    //     "longitude": -0.359261,
    //     "deviceEUI": "0079e129d522e641",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -124,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 2875,
    //     "prevFrameCounterFcnt": 4607,
    //     "latestFrameCounterFcnt": 4608,
    //     "lostFcnt": -106188,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 186,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_regnevalencia",
    //     "description": "ibox instalado en foios en el extremo oeste en la calle regne valencia",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5384722,
    //     "longitude": -0.3622443,
    //     "deviceEUI": "0079e129d522e640",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 302,
    //     "latestFrameCounterFcnt": 344,
    //     "lostFcnt": -702,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 187,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_caminolacruz",
    //     "description": "ibx instalado en foios en el extremo suroeste en la calle camino de la cruz",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5338611,
    //     "longitude": -0.3573831,
    //     "deviceEUI": "0079e129d522e634",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 145,
    //     "prevFrameCounterFcnt": 186,
    //     "latestFrameCounterFcnt": 187,
    //     "lostFcnt": -162678,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb3330b1",
    //     "messageTime": null
    // },
    // {
    //     "id": 189,
    //     "gatewaysId": null,
    //     "name": "Cuadro a.p AE",
    //     "description": "extremo este cercano a burguer king",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-23",
    //     "applicationName": null,
    //     "latitude": 39.424622,
    //     "longitude": -0.40511,
    //     "deviceEUI": "0079e129d522e615",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 190,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_bar",
    //     "description": "ibox al este de paiporta cerca de un bar",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-23",
    //     "applicationName": null,
    //     "latitude": 39.4252645,
    //     "longitude": -0.4089387,
    //     "deviceEUI": "0079e129d522e616",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -90,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 3118,
    //     "prevFrameCounterFcnt": 2,
    //     "latestFrameCounterFcnt": 3,
    //     "lostFcnt": -739446,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca632143f21",
    //     "messageTime": null
    // },
    // {
    //     "id": 191,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_parque",
    //     "description": "ibox al este de paiporta cerca de un parque",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4255038,
    //     "longitude": -0.4108358,
    //     "deviceEUI": "0079e129d522e617",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -107,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2313,
    //     "prevFrameCounterFcnt": 2437,
    //     "latestFrameCounterFcnt": 2438,
    //     "lostFcnt": -1993127,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4ae486",
    //     "messageTime": null
    // },
    // {
    //     "id": 192,
    //     "gatewaysId": null,
    //     "name": "Ibox_Paiporta_Cedisa",
    //     "description": "ibox paiporta en poligono cerca de taller de motos",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4274159,
    //     "longitude": -0.4065676,
    //     "deviceEUI": "0079e129d522e618",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 193,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_faitanar",
    //     "description": "ibox paiporta poligono calle faitanar a mitad",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4286756,
    //     "longitude": -0.4060418,
    //     "deviceEUI": "0079e129d522e620",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -63,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 18,
    //     "latestFrameCounterFcnt": 0,
    //     "lostFcnt": -776,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca632143f21",
    //     "messageTime": null
    // },
    // {
    //     "id": 194,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_lasenia",
    //     "description": "ibox paiporta al sureste en la salida del colegio la senia",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4212418,
    //     "longitude": -0.4151241,
    //     "deviceEUI": "0079e129d522e612",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 195,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_independencia",
    //     "description": "ibox paiporta al noroeste en la calle independencia",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4305412,
    //     "longitude": -0.4217051,
    //     "deviceEUI": "0079e129d522e622",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 197,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_maximiliano",
    //     "description": "ibox paiporta al norte en la calle maximiliano",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4333949,
    //     "longitude": -0.414235,
    //     "deviceEUI": "0079e129d522e625",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 198,
    //     "gatewaysId": null,
    //     "name": "Ibox_Paiporta_Primitiva",
    //     "description": "ibox en el centro norte calle banda primitiva",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4284961,
    //     "longitude": -0.4135304,
    //     "deviceEUI": "0079e129d522e619",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 199,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_acequia",
    //     "description": "ibox paiporta al este en la calle acequia",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4294963,
    //     "longitude": -0.4032379,
    //     "deviceEUI": "0079e129d522e621",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 200,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_acequiadequart",
    //     "description": "ibox paiporta al noreste calle acequia de quart",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4309699,
    //     "longitude": -0.4043883,
    //     "deviceEUI": "0079e129d522e624",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 201,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_acequiafaitanar",
    //     "description": "prueba_taller",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4336567,
    //     "longitude": -0.4040864,
    //     "deviceEUI": "0079e129d522e626",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 3,
    //     "rssi": -65,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 4592,
    //     "prevFrameCounterFcnt": 692,
    //     "latestFrameCounterFcnt": 696,
    //     "lostFcnt": -83622,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb4ae486",
    //     "messageTime": null
    // },
    // {
    //     "id": 202,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_ausiasmarch",
    //     "description": "ibos paiporta centro noroeste en calle ausias march",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4306035,
    //     "longitude": -0.416513,
    //     "deviceEUI": "0079e129d522e623",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 203,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_quart",
    //     "description": "ibox paiporta al suroeste en calle quart de poblet",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4226541,
    //     "longitude": -0.4174941,
    //     "deviceEUI": "0079e129d522e613",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 204,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_polideportivo",
    //     "description": "ibox paiporta  al sur instalado dentro del polideportivo",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4247485,
    //     "longitude": -0.4143086,
    //     "deviceEUI": "0079e129d522e614",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 209,
    //     "gatewaysId": null,
    //     "name": "a079e129d522e616",
    //     "description": "a079e129d522e611",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "31",
    //     "sensorTypeInfo": {
    //         "id": 31,
    //         "name": "iBOX_Paiporta",
    //         "description": "IBOX Ayto. Paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "a079e129d522e611",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 210,
    //     "gatewaysId": null,
    //     "name": "a079e129d522e616",
    //     "description": "ibox instalado en paiporta al este del centro al lado de un burguerking",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "20",
    //     "sensorTypeInfo": {
    //         "id": 20,
    //         "name": "IBOX-PUERTA-TEST",
    //         "description": "Puerta TEST",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-01",
    //     "applicationName": null,
    //     "latitude": 39.422413,
    //     "longitude": -0.405982,
    //     "deviceEUI": "a079e129d522e616",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "a"
    //         },
    //         {
    //             "inputComponent": "b"
    //         },
    //         {
    //             "inputComponent": "c"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 220,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC01",
    //     "description": "constitucion-20",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bd5",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 290,
    //     "prevFrameCounterFcnt": 1339,
    //     "latestFrameCounterFcnt": 1340,
    //     "lostFcnt": 320,
    //     "loraSNR": -16,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 222,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC03",
    //     "description": "constitucion-14",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bd4",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 608,
    //     "prevFrameCounterFcnt": 1668,
    //     "latestFrameCounterFcnt": 1669,
    //     "lostFcnt": 211,
    //     "loraSNR": -12,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 223,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC05",
    //     "description": "constitucion-30",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bd3",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 59,
    //     "prevFrameCounterFcnt": 803,
    //     "latestFrameCounterFcnt": 804,
    //     "lostFcnt": 124,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 224,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC06",
    //     "description": "constitucion-36",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a83f",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 50,
    //     "prevFrameCounterFcnt": 28,
    //     "latestFrameCounterFcnt": 34,
    //     "lostFcnt": -26,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 225,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC09",
    //     "description": "Gandia-29",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a841",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": -112,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 276,
    //     "prevFrameCounterFcnt": 563,
    //     "latestFrameCounterFcnt": 564,
    //     "lostFcnt": -187,
    //     "loraSNR": -13,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 226,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC10",
    //     "description": "Gandia-28",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872776b9",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 9,
    //     "prevFrameCounterFcnt": 55,
    //     "latestFrameCounterFcnt": 1,
    //     "lostFcnt": -67,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 227,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC13",
    //     "description": "convento-51",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872776b8",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 674,
    //     "latestFrameCounterFcnt": 675,
    //     "lostFcnt": 278,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 228,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC15",
    //     "description": "Convento-34",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a397",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 312,
    //     "prevFrameCounterFcnt": 813,
    //     "latestFrameCounterFcnt": 814,
    //     "lostFcnt": -240,
    //     "loraSNR": -18,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 229,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC03",
    //     "description": "Jaime-calafate-2acc",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450031548",
    //     "appEUI": "2",
    //     "appKEY": "C5B55C8B19A92D638F6B67F376110B06",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 311,
    //     "prevFrameCounterFcnt": 1064,
    //     "latestFrameCounterFcnt": 1068,
    //     "lostFcnt": 516,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 230,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC07",
    //     "description": "Jaime-calafate-2",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033989",
    //     "appEUI": "2",
    //     "appKEY": "23435DA6A2AEAC81381E5737A2FF1E6A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 299,
    //     "prevFrameCounterFcnt": 851,
    //     "latestFrameCounterFcnt": 852,
    //     "lostFcnt": 163,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 231,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC12",
    //     "description": "Rei-en-Jaume-14",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034077",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 284,
    //     "prevFrameCounterFcnt": 807,
    //     "latestFrameCounterFcnt": 808,
    //     "lostFcnt": 108,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 232,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC13",
    //     "description": "Rei-en-Jaume-16",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033968",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 286,
    //     "prevFrameCounterFcnt": 834,
    //     "latestFrameCounterFcnt": 835,
    //     "lostFcnt": 189,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 233,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC14",
    //     "description": "Rei-en-Jaume-18",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034101",
    //     "appEUI": "2",
    //     "appKEY": "9BFC7FFB32842F714F940742B515F57D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 303,
    //     "prevFrameCounterFcnt": 835,
    //     "latestFrameCounterFcnt": 836,
    //     "lostFcnt": 138,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 234,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC15",
    //     "description": "Rei-en-Jaume-22",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033917",
    //     "appEUI": "2",
    //     "appKEY": "BEA29B6ACDB3E8F7C2251D8CAEFABB95",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 279,
    //     "prevFrameCounterFcnt": 794,
    //     "latestFrameCounterFcnt": 795,
    //     "lostFcnt": 119,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 235,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC16",
    //     "description": "Rei-en-Jaume-24",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033982",
    //     "appEUI": "app",
    //     "appKEY": "3A29D9FB7ED836C8048ACA3AE368357F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 299,
    //     "prevFrameCounterFcnt": 815,
    //     "latestFrameCounterFcnt": 816,
    //     "lostFcnt": 110,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 236,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC17",
    //     "description": "Rei-en-Jaume-26",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033916",
    //     "appEUI": "app",
    //     "appKEY": "4503E4F51C69D7D6EF3B0C2871CA8241",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 297,
    //     "prevFrameCounterFcnt": 862,
    //     "latestFrameCounterFcnt": 863,
    //     "lostFcnt": 185,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 237,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC18",
    //     "description": "Rei-en-Jaume-30",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033993",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 795,
    //     "latestFrameCounterFcnt": 796,
    //     "lostFcnt": 93,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 238,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC19",
    //     "description": "Rei-en-JAume-44",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034016",
    //     "appEUI": "app",
    //     "appKEY": "0767D7630F287DB586B99FF4B086EF95",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -99,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 790,
    //     "latestFrameCounterFcnt": 791,
    //     "lostFcnt": 94,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 239,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC21",
    //     "description": "Escoleta-80",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034108",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 310,
    //     "prevFrameCounterFcnt": 809,
    //     "latestFrameCounterFcnt": 810,
    //     "lostFcnt": 84,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 240,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC22",
    //     "description": "Escoleta-78",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034031",
    //     "appEUI": null,
    //     "appKEY": "22F8B490D8D0318B12C1961F1F48A772",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -78,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 286,
    //     "prevFrameCounterFcnt": 785,
    //     "latestFrameCounterFcnt": 786,
    //     "lostFcnt": 87,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 241,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC23",
    //     "description": "Escoleta-79",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034040",
    //     "appEUI": "app",
    //     "appKEY": "3E42B8A57822B1C82C5A63BD396ECECD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -95,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 294,
    //     "prevFrameCounterFcnt": 810,
    //     "latestFrameCounterFcnt": 811,
    //     "lostFcnt": 88,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 242,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC24",
    //     "description": "Escoleta-81-A",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033955",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -83,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 270,
    //     "prevFrameCounterFcnt": 757,
    //     "latestFrameCounterFcnt": 758,
    //     "lostFcnt": 81,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 243,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC25",
    //     "description": "Escoleta-77",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033936",
    //     "appEUI": "app",
    //     "appKEY": "8A2BDBE3960C9C476EAA9D57617E6965",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 318,
    //     "prevFrameCounterFcnt": 836,
    //     "latestFrameCounterFcnt": 837,
    //     "lostFcnt": 116,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 244,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC26",
    //     "description": "Escoleta-76-bajo",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028716",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -79,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 294,
    //     "prevFrameCounterFcnt": 783,
    //     "latestFrameCounterFcnt": 784,
    //     "lostFcnt": 87,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 245,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC27",
    //     "description": "Escoleta-76-1",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034095",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -87,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 274,
    //     "prevFrameCounterFcnt": 763,
    //     "latestFrameCounterFcnt": 764,
    //     "lostFcnt": 85,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 246,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC28",
    //     "description": "Escoleta-74",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034110",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -97,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 299,
    //     "prevFrameCounterFcnt": 802,
    //     "latestFrameCounterFcnt": 803,
    //     "lostFcnt": 95,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 247,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC29",
    //     "description": "Escoleta-72",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033952",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -91,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 278,
    //     "prevFrameCounterFcnt": 751,
    //     "latestFrameCounterFcnt": 752,
    //     "lostFcnt": 78,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 248,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC30",
    //     "description": "Escoleta-75",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033925",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 312,
    //     "prevFrameCounterFcnt": 836,
    //     "latestFrameCounterFcnt": 837,
    //     "lostFcnt": 102,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 249,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC31",
    //     "description": "Escoleta-73",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033986",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -85,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 288,
    //     "prevFrameCounterFcnt": 803,
    //     "latestFrameCounterFcnt": 804,
    //     "lostFcnt": 100,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 250,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC32",
    //     "description": "Escoleta-71",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033942",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 271,
    //     "prevFrameCounterFcnt": 784,
    //     "latestFrameCounterFcnt": 785,
    //     "lostFcnt": 98,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 251,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC33",
    //     "description": "Escoleta-67",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034038",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 290,
    //     "prevFrameCounterFcnt": 811,
    //     "latestFrameCounterFcnt": 812,
    //     "lostFcnt": 109,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 252,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC34",
    //     "description": "Escoleta-65",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033979",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -78,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 776,
    //     "latestFrameCounterFcnt": 777,
    //     "lostFcnt": 81,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 253,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC35",
    //     "description": "Escoleta-63",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033970",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -84,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 298,
    //     "prevFrameCounterFcnt": 796,
    //     "latestFrameCounterFcnt": 797,
    //     "lostFcnt": 89,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 254,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC36",
    //     "description": "Escoleta-70",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032093",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -97,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 300,
    //     "prevFrameCounterFcnt": 823,
    //     "latestFrameCounterFcnt": 825,
    //     "lostFcnt": 119,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 255,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC37",
    //     "description": "Esoleta-68",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032107",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 294,
    //     "prevFrameCounterFcnt": 782,
    //     "latestFrameCounterFcnt": 783,
    //     "lostFcnt": 82,
    //     "loraSNR": 12,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 256,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC38",
    //     "description": "Escoleta-66",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034001",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -95,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 277,
    //     "prevFrameCounterFcnt": 800,
    //     "latestFrameCounterFcnt": 801,
    //     "lostFcnt": 113,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 257,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC39",
    //     "description": "Escoleta-64",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034033",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 295,
    //     "prevFrameCounterFcnt": 807,
    //     "latestFrameCounterFcnt": 808,
    //     "lostFcnt": 109,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 258,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC40",
    //     "description": "Escoleta-62",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034037",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -103,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 291,
    //     "prevFrameCounterFcnt": 778,
    //     "latestFrameCounterFcnt": 779,
    //     "lostFcnt": 79,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 259,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC41",
    //     "description": "Escoleta-60",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034010",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -77,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 294,
    //     "prevFrameCounterFcnt": 805,
    //     "latestFrameCounterFcnt": 806,
    //     "lostFcnt": 113,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 260,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC42",
    //     "description": "Escoleta-58",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034074",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -74,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 268,
    //     "prevFrameCounterFcnt": 746,
    //     "latestFrameCounterFcnt": 747,
    //     "lostFcnt": 78,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 261,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC43",
    //     "description": "Escoleta-56",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034013",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -72,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 302,
    //     "prevFrameCounterFcnt": 838,
    //     "latestFrameCounterFcnt": 839,
    //     "lostFcnt": 119,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 262,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC44",
    //     "description": "Escoleta-52",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033997",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -79,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 282,
    //     "prevFrameCounterFcnt": 771,
    //     "latestFrameCounterFcnt": 772,
    //     "lostFcnt": 75,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 263,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC46",
    //     "description": "Escoleta-61",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034000",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 791,
    //     "latestFrameCounterFcnt": 792,
    //     "lostFcnt": 98,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 264,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC47",
    //     "description": "Escoleta-59",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034003",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -102,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 790,
    //     "latestFrameCounterFcnt": 791,
    //     "lostFcnt": 85,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 265,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC48",
    //     "description": "Escoleta-48",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034088",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -89,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 297,
    //     "prevFrameCounterFcnt": 813,
    //     "latestFrameCounterFcnt": 815,
    //     "lostFcnt": 102,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 266,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC49",
    //     "description": "Escoleta-57B",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033972",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -100,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 290,
    //     "prevFrameCounterFcnt": 799,
    //     "latestFrameCounterFcnt": 800,
    //     "lostFcnt": 108,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 267,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC50",
    //     "description": "Escoleta-57",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034071",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -97,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 282,
    //     "prevFrameCounterFcnt": 798,
    //     "latestFrameCounterFcnt": 799,
    //     "lostFcnt": 99,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 268,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC51",
    //     "description": "Escoleta-46",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033957",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -80,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 268,
    //     "prevFrameCounterFcnt": 753,
    //     "latestFrameCounterFcnt": 754,
    //     "lostFcnt": 76,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 269,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC52",
    //     "description": "Escoleta-44",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034115",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 277,
    //     "prevFrameCounterFcnt": 760,
    //     "latestFrameCounterFcnt": 761,
    //     "lostFcnt": 81,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 270,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC54",
    //     "description": "Escoleta-43",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033991",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 786,
    //     "latestFrameCounterFcnt": 787,
    //     "lostFcnt": 100,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 271,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC55",
    //     "description": "Escoleta-38",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034082",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -106,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 266,
    //     "prevFrameCounterFcnt": 741,
    //     "latestFrameCounterFcnt": 742,
    //     "lostFcnt": 76,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 272,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC56",
    //     "description": "Escoleta-36",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034072",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 279,
    //     "prevFrameCounterFcnt": 752,
    //     "latestFrameCounterFcnt": 753,
    //     "lostFcnt": 79,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 273,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC57",
    //     "description": "Escoleta-41",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033912",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -102,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 306,
    //     "prevFrameCounterFcnt": 797,
    //     "latestFrameCounterFcnt": 798,
    //     "lostFcnt": 95,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 274,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC58",
    //     "description": "Escoleta-34",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034070",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -105,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 775,
    //     "latestFrameCounterFcnt": 776,
    //     "lostFcnt": 92,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 275,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC59",
    //     "description": "Escoleta-39",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033976",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 302,
    //     "prevFrameCounterFcnt": 805,
    //     "latestFrameCounterFcnt": 806,
    //     "lostFcnt": 95,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 276,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC60",
    //     "description": "Escoleta-37",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033974",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 799,
    //     "latestFrameCounterFcnt": 800,
    //     "lostFcnt": 100,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 277,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC61",
    //     "description": "Escoleta-32",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032095",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 304,
    //     "prevFrameCounterFcnt": 804,
    //     "latestFrameCounterFcnt": 805,
    //     "lostFcnt": 93,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 278,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC62",
    //     "description": "Escoleta-30",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034015",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 301,
    //     "prevFrameCounterFcnt": 789,
    //     "latestFrameCounterFcnt": 790,
    //     "lostFcnt": 99,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 279,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC63",
    //     "description": "Escoleta-28",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032067",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 292,
    //     "prevFrameCounterFcnt": 823,
    //     "latestFrameCounterFcnt": 824,
    //     "lostFcnt": 136,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 280,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC64",
    //     "description": "Escoleta-35",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034024",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 800,
    //     "latestFrameCounterFcnt": 801,
    //     "lostFcnt": 105,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 281,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC65",
    //     "description": "Escoleta-24",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033964",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 277,
    //     "prevFrameCounterFcnt": 799,
    //     "latestFrameCounterFcnt": 800,
    //     "lostFcnt": 113,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 282,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC66",
    //     "description": "Escoleta-22",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034043",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 286,
    //     "prevFrameCounterFcnt": 859,
    //     "latestFrameCounterFcnt": 861,
    //     "lostFcnt": 160,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 283,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC67",
    //     "description": "Escoleta-29",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034102",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 280,
    //     "prevFrameCounterFcnt": 795,
    //     "latestFrameCounterFcnt": 796,
    //     "lostFcnt": 105,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 284,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC68",
    //     "description": "Escoleta-20",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033963",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -110,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 295,
    //     "prevFrameCounterFcnt": 834,
    //     "latestFrameCounterFcnt": 835,
    //     "lostFcnt": 138,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 285,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC70",
    //     "description": "Escoleta-23",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034034",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -111,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 282,
    //     "prevFrameCounterFcnt": 819,
    //     "latestFrameCounterFcnt": 820,
    //     "lostFcnt": 135,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 286,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC69",
    //     "description": "Escoleta-27",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034096",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 279,
    //     "prevFrameCounterFcnt": 810,
    //     "latestFrameCounterFcnt": 811,
    //     "lostFcnt": 138,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 287,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC71",
    //     "description": "Escoleta-21",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034049",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 280,
    //     "prevFrameCounterFcnt": 797,
    //     "latestFrameCounterFcnt": 798,
    //     "lostFcnt": 105,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 288,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC72",
    //     "description": "Escoleta-19",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033959",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 282,
    //     "prevFrameCounterFcnt": 788,
    //     "latestFrameCounterFcnt": 789,
    //     "lostFcnt": 96,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 289,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC73",
    //     "description": "Escoleta-16",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034094",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 288,
    //     "prevFrameCounterFcnt": 771,
    //     "latestFrameCounterFcnt": 772,
    //     "lostFcnt": 77,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 290,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC74",
    //     "description": "Escoleta-14",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034114",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 303,
    //     "prevFrameCounterFcnt": 811,
    //     "latestFrameCounterFcnt": 812,
    //     "lostFcnt": 113,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 291,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC75",
    //     "description": "Escoleta-15",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033967",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 270,
    //     "prevFrameCounterFcnt": 811,
    //     "latestFrameCounterFcnt": 812,
    //     "lostFcnt": 145,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 292,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC76",
    //     "description": "Escoleta-13",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034014",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 820,
    //     "latestFrameCounterFcnt": 821,
    //     "lostFcnt": 153,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 293,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC77",
    //     "description": "Escoleta-12",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033929",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 330,
    //     "prevFrameCounterFcnt": 878,
    //     "latestFrameCounterFcnt": 879,
    //     "lostFcnt": 140,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 294,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC78",
    //     "description": "Escoleta-10",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034112",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 270,
    //     "prevFrameCounterFcnt": 786,
    //     "latestFrameCounterFcnt": 787,
    //     "lostFcnt": 117,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 295,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC79",
    //     "description": "Escoleta-8",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034064",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 301,
    //     "prevFrameCounterFcnt": 806,
    //     "latestFrameCounterFcnt": 807,
    //     "lostFcnt": 104,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 296,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC80",
    //     "description": "Escoleta-11",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034027",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 299,
    //     "prevFrameCounterFcnt": 806,
    //     "latestFrameCounterFcnt": 807,
    //     "lostFcnt": 89,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 297,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC81",
    //     "description": "Escoleta-6",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033998",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 293,
    //     "prevFrameCounterFcnt": 825,
    //     "latestFrameCounterFcnt": 827,
    //     "lostFcnt": 117,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 298,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC82",
    //     "description": "Escoleta-4",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034019",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 788,
    //     "latestFrameCounterFcnt": 789,
    //     "lostFcnt": 94,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 299,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC83",
    //     "description": "Escoleta-3",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034059",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 281,
    //     "prevFrameCounterFcnt": 794,
    //     "latestFrameCounterFcnt": 795,
    //     "lostFcnt": 126,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 300,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC84",
    //     "description": "Escoleta-5",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034104",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 808,
    //     "latestFrameCounterFcnt": 809,
    //     "lostFcnt": 117,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 301,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC85",
    //     "description": "Escoleta-7",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033914",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -108,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 307,
    //     "prevFrameCounterFcnt": 823,
    //     "latestFrameCounterFcnt": 824,
    //     "lostFcnt": 104,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 302,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC86",
    //     "description": "Escoleta9",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450045529",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 303,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC87",
    //     "description": "Escoleta-69",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034091",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 777,
    //     "latestFrameCounterFcnt": 778,
    //     "lostFcnt": 81,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 304,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC88",
    //     "description": "Escoleta-74bis",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034021",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -82,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 273,
    //     "prevFrameCounterFcnt": 793,
    //     "latestFrameCounterFcnt": 794,
    //     "lostFcnt": 97,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 305,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC89",
    //     "description": "Escoleta-42",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033949",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 271,
    //     "prevFrameCounterFcnt": 763,
    //     "latestFrameCounterFcnt": 764,
    //     "lostFcnt": 85,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 306,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC90",
    //     "description": "Escoleta-53",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034009",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -103,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 313,
    //     "prevFrameCounterFcnt": 841,
    //     "latestFrameCounterFcnt": 842,
    //     "lostFcnt": 122,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 307,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC91",
    //     "description": "Escoleta-45",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034011",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 288,
    //     "prevFrameCounterFcnt": 791,
    //     "latestFrameCounterFcnt": 792,
    //     "lostFcnt": 94,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 308,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC92",
    //     "description": "Escoleta-25",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033948",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 290,
    //     "prevFrameCounterFcnt": 840,
    //     "latestFrameCounterFcnt": 841,
    //     "lostFcnt": 172,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 309,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC93",
    //     "description": "Rei-en-Jaume-10",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033924",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 331,
    //     "prevFrameCounterFcnt": 1029,
    //     "latestFrameCounterFcnt": 1030,
    //     "lostFcnt": 385,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 107,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e566_sensor",
    //     "description": "ibox sensor",
    //     "provider": null,
    //     "userId": 32,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-06-15",
    //     "applicationName": null,
    //     "latitude": 39.120249,
    //     "longitude": -0.454043,
    //     "deviceEUI": "0079e129d522e566",
    //     "appEUI": "12",
    //     "appKEY": "12",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 197579,
    //     "prevFrameCounterFcnt": 1409,
    //     "latestFrameCounterFcnt": 1409,
    //     "lostFcnt": -778212,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb3f1042",
    //     "messageTime": null
    // },
    // {
    //     "id": 108,
    //     "gatewaysId": null,
    //     "name": "testIbox",
    //     "description": "For testing All",
    //     "provider": null,
    //     "userId": 32,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-06-18",
    //     "applicationName": null,
    //     "latitude": 39.427242,
    //     "longitude": -1.114482,
    //     "deviceEUI": "0079e129d522e634",
    //     "appEUI": "12",
    //     "appKEY": "12",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "inputcmp1"
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": "metercmp1"
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 145,
    //     "prevFrameCounterFcnt": 186,
    //     "latestFrameCounterFcnt": 187,
    //     "lostFcnt": -162678,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb3330b1",
    //     "messageTime": null
    // },
    // {
    //     "id": 109,
    //     "gatewaysId": null,
    //     "name": "Sensor_ibox_Alberto",
    //     "description": "IBOX TEST",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "16",
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-06-24",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e566",
    //     "appEUI": "06263577c3bbc9eb",
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "DoorC01"
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": "EnergyMeterVIPAC01"
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 197579,
    //     "prevFrameCounterFcnt": 1409,
    //     "latestFrameCounterFcnt": 1409,
    //     "lostFcnt": -778212,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb3f1042",
    //     "messageTime": null
    // },
    // {
    //     "id": 110,
    //     "gatewaysId": null,
    //     "name": "IBOX1_Biblio",
    //     "description": "iBOX_GESWAT_e647",
    //     "provider": null,
    //     "userId": 36,
    //     "typeSensor": "20",
    //     "sensorTypeInfo": {
    //         "id": 20,
    //         "name": "IBOX-PUERTA-TEST",
    //         "description": "Puerta TEST",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-06-26",
    //     "applicationName": null,
    //     "latitude": 39.567724,
    //     "longitude": -0.622264,
    //     "deviceEUI": "0079e129d522e647",
    //     "appEUI": "06263577c3bbc9eb",
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb66bea3,b827eb6c4e09,dca63214b1d0,b827eb11f2e1",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 115,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e631_doorLibrary",
    //     "description": "iBOX_GESWAT_e631",
    //     "provider": null,
    //     "userId": 77,
    //     "typeSensor": "89",
    //     "sensorTypeInfo": {
    //         "id": 89,
    //         "name": "Olmos_ibox",
    //         "description": null,
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-07-15",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e631",
    //     "appEUI": null,
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": "b827eb524297",
    //     "dr": 3,
    //     "rssi": -125,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 682,
    //     "latestFrameCounterFcnt": 1494,
    //     "lostFcnt": -322249,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb524297",
    //     "messageTime": null
    // },
    // {
    //     "id": 117,
    //     "gatewaysId": null,
    //     "name": "iBOX_Biblioteca",
    //     "description": "Quatretonda iBox Biblioteca",
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": "21",
    //     "sensorTypeInfo": {
    //         "id": 21,
    //         "name": "iBOX_Puerta",
    //         "description": "Ibox Biblioteca",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-07-19",
    //     "applicationName": null,
    //     "latitude": 38.945463,
    //     "longitude": -0.399479,
    //     "deviceEUI": "0079e129d522e629",
    //     "appEUI": "06263577c3bbc9eb",
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "EnergyMeterQUAGWTC01"
    //         },
    //         {
    //             "inputComponent": "EnergyMeterQUAGWTC02"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -57,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 5066,
    //     "prevFrameCounterFcnt": 4373,
    //     "latestFrameCounterFcnt": 4374,
    //     "lostFcnt": -784639,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 118,
    //     "gatewaysId": null,
    //     "name": "iBOX_Ayuntamiento",
    //     "description": "iBOX Ayuntamiento",
    //     "provider": null,
    //     "userId": 41,
    //     "typeSensor": "22",
    //     "sensorTypeInfo": {
    //         "id": 22,
    //         "name": "iBOX_Ayuntamiento",
    //         "description": "iBOX Ayuntamiento",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-07-19",
    //     "applicationName": null,
    //     "latitude": 38.853991,
    //     "longitude": -0.502074,
    //     "deviceEUI": "0079e129d522e630",
    //     "appEUI": "06263577c3bbc9eb",
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "EnergyMeterEARCTMC01"
    //         },
    //         {
    //             "inputComponent": "EnergyMeterEARCTMC02"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -98,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 326,
    //     "prevFrameCounterFcnt": 2407,
    //     "latestFrameCounterFcnt": 2408,
    //     "lostFcnt": -753304,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827ebf0f17b",
    //     "messageTime": null
    // },
    // {
    //     "id": 121,
    //     "gatewaysId": null,
    //     "name": "171fe129d522e000_wifi",
    //     "description": "Wifi Sensor",
    //     "provider": null,
    //     "userId": 36,
    //     "typeSensor": "27",
    //     "sensorTypeInfo": {
    //         "id": 27,
    //         "name": "171fe129d522e000_wifi_Type",
    //         "description": null,
    //         "sensorType": "wifi",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-08-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "171fe129d522e000",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb66bea3,b827eb6c4e09,dca63214b1d0,b827eb11f2e1",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 123,
    //     "gatewaysId": null,
    //     "name": "Alberto_Puerta",
    //     "description": "Test puerta",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "21",
    //     "sensorTypeInfo": {
    //         "id": 21,
    //         "name": "iBOX_Puerta",
    //         "description": "Ibox Biblioteca",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-10-06",
    //     "applicationName": null,
    //     "latitude": 39.592868,
    //     "longitude": -0.544343,
    //     "deviceEUI": "0079e129d522e630",
    //     "appEUI": "06263577C3BBC9EB2FEF99135EF0F6A5",
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "DoorC01"
    //         },
    //         {
    //             "inputComponent": "DoorC02"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -98,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 326,
    //     "prevFrameCounterFcnt": 2407,
    //     "latestFrameCounterFcnt": 2408,
    //     "lostFcnt": -753304,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827ebf0f17b",
    //     "messageTime": null
    // },
    // {
    //     "id": 124,
    //     "gatewaysId": null,
    //     "name": "DCSWAGWS0000000008",
    //     "description": "water castellon itron",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "30",
    //     "sensorTypeInfo": {
    //         "id": 30,
    //         "name": "water_itron",
    //         "description": "for water",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-11-25",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "532253811817804e",
    //     "appEUI": "00000000000000000",
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 125,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e613_N",
    //     "description": "0079e129d522e613_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e613",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 126,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e614_N",
    //     "description": "0079e129d522e614_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e614",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 127,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e615_N",
    //     "description": "0079e129d522e615_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e615",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 128,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e616_N",
    //     "description": "0079e129d522e616_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e616",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -90,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 3118,
    //     "prevFrameCounterFcnt": 2,
    //     "latestFrameCounterFcnt": 3,
    //     "lostFcnt": -739446,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca632143f21",
    //     "messageTime": null
    // },
    // {
    //     "id": 129,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e617_N",
    //     "description": "0079e129d522e617_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e617",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -107,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2313,
    //     "prevFrameCounterFcnt": 2437,
    //     "latestFrameCounterFcnt": 2438,
    //     "lostFcnt": -1993127,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4ae486",
    //     "messageTime": null
    // },
    // {
    //     "id": 131,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e621_ibox",
    //     "description": null,
    //     "provider": null,
    //     "userId": 52,
    //     "typeSensor": "32",
    //     "sensorTypeInfo": {
    //         "id": 32,
    //         "name": "0079e129d522e621_ibox_Type",
    //         "description": null,
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-08",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e621",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 136,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC01",
    //     "description": "Jaume-Calafate-1",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-07",
    //     "applicationName": null,
    //     "latitude": 39.097538,
    //     "longitude": -0.630113,
    //     "deviceEUI": "04b6480450034109",
    //     "appEUI": "app",
    //     "appKEY": "7F612E785A07E056205E9799861F619C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 326,
    //     "prevFrameCounterFcnt": 929,
    //     "latestFrameCounterFcnt": 951,
    //     "lostFcnt": 322,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 141,
    //     "gatewaysId": null,
    //     "name": "171fe129d522e001_wifi",
    //     "description": null,
    //     "provider": null,
    //     "userId": 54,
    //     "typeSensor": "41",
    //     "sensorTypeInfo": {
    //         "id": 41,
    //         "name": "171fe129d522e001_wifi_Type",
    //         "description": null,
    //         "sensorType": "wifi",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-11",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "171fe129d522e001",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -105,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2345,
    //     "prevFrameCounterFcnt": 128722,
    //     "latestFrameCounterFcnt": 128724,
    //     "lostFcnt": -691835,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb481840",
    //     "messageTime": null
    // },
    // {
    //     "id": 142,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC02",
    //     "description": "Jaume-Calafate-3",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-07",
    //     "applicationName": null,
    //     "latitude": 39.097584,
    //     "longitude": -0.630043,
    //     "deviceEUI": "04b6480450034057",
    //     "appEUI": "app",
    //     "appKEY": "CEF522F5F799AE91D158D7DCD72F8B7D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 296,
    //     "prevFrameCounterFcnt": 899,
    //     "latestFrameCounterFcnt": 900,
    //     "lostFcnt": 243,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 143,
    //     "gatewaysId": null,
    //     "name": "Sensor de Oficina Pruebas",
    //     "description": "para probar en el laboratorio",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "42",
    //     "sensorTypeInfo": {
    //         "id": 42,
    //         "name": "ibox prueba OFININA",
    //         "description": "para probar en el laboratorio",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-14",
    //     "applicationName": null,
    //     "latitude": 39.594463203832,
    //     "longitude": -0.55226997501051,
    //     "deviceEUI": "a079e129d522e624",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "door sensor"
    //         },
    //         {
    //             "inputComponent": "current sensor"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 146,
    //     "gatewaysId": null,
    //     "name": "METEOVINSWTC01",
    //     "description": null,
    //     "provider": null,
    //     "userId": 54,
    //     "typeSensor": "47",
    //     "sensorTypeInfo": {
    //         "id": 47,
    //         "name": "METEOGESWAT1",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "Modbus"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-03-11",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "141f3c71bff07fae",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -88,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1511,
    //     "prevFrameCounterFcnt": 1392,
    //     "latestFrameCounterFcnt": 1393,
    //     "lostFcnt": -904159,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb481840",
    //     "messageTime": null
    // },
    // {
    //     "id": 147,
    //     "gatewaysId": null,
    //     "name": "BOYAVINSWTC01",
    //     "description": null,
    //     "provider": null,
    //     "userId": 54,
    //     "typeSensor": "48",
    //     "sensorTypeInfo": {
    //         "id": 48,
    //         "name": "BOYAGESWAT",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-03-12",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0004a30b00f1a9ee",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 78,
    //     "latestFrameCounterFcnt": 79,
    //     "lostFcnt": -5,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb481840",
    //     "messageTime": null
    // },
    // {
    //     "id": 153,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC45",
    //     "description": "Escoleta-54",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-03-31",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034036",
    //     "appEUI": null,
    //     "appKEY": "50AEDDE4585EA2F15399040CF9C5B791",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -71,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 305,
    //     "prevFrameCounterFcnt": 816,
    //     "latestFrameCounterFcnt": 817,
    //     "lostFcnt": 110,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 154,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC04",
    //     "description": "Jaime-calafate-5",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-04-19",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033985",
    //     "appEUI": null,
    //     "appKEY": "5351D39D5D4182D65182C06735052FED",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 316,
    //     "prevFrameCounterFcnt": 536,
    //     "latestFrameCounterFcnt": 548,
    //     "lostFcnt": 71,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 155,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC05",
    //     "description": "Jaime-calafate-7",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-04-19",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034063",
    //     "appEUI": "2",
    //     "appKEY": "74CE37EAA32F093DE218F3577385C655",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 920,
    //     "latestFrameCounterFcnt": 934,
    //     "lostFcnt": 322,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 156,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC06",
    //     "description": "Jaime-calafate-9",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-04-19",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033996",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 342,
    //     "prevFrameCounterFcnt": 893,
    //     "latestFrameCounterFcnt": 895,
    //     "lostFcnt": 156,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 174,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-11",
    //     "description": "ibox instalado en el taller con dev eui a0......611",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-19",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e611",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 175,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-13",
    //     "description": "ibox instalado en el taller con dev eui a0......613",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-19",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e613",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 176,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-15",
    //     "description": "ibox instalado en el taller con dev eui a0......615",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-19",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e615",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 178,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-17",
    //     "description": "a079e129d522e617",
    //     "provider": null,
    //     "userId": 124,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-18",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e617",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -33,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 170,
    //     "latestFrameCounterFcnt": 171,
    //     "lostFcnt": -99540,
    //     "loraSNR": 7,
    //     "networkServerMac": "e45f016c7dbc",
    //     "messageTime": null
    // },
    // {
    //     "id": 179,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-19",
    //     "description": "ibox instalado en el taller con dev eui a0......619",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-18",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e619",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 180,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-21",
    //     "description": "ibox instalado en el taller con dev eui a0......621",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-18",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e621",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 181,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_plazadelacreu",
    //     "description": "ibox intalado en foios en la plaza de la creu en un cuadro en el parque",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5364359,
    //     "longitude": -0.3560338,
    //     "deviceEUI": "0079e129d522e609",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1925,
    //     "prevFrameCounterFcnt": 26,
    //     "latestFrameCounterFcnt": 27,
    //     "lostFcnt": -48751,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 182,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_alcaldefranciscoroceto",
    //     "description": "ibox instalado en mitad de una carretera unidireccional en la calle alcalde francisco roceto",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5359263,
    //     "longitude": -0.356997,
    //     "deviceEUI": "0079e129d522e607",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 558,
    //     "prevFrameCounterFcnt": 39,
    //     "latestFrameCounterFcnt": 41,
    //     "lostFcnt": -73661,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 183,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_ayuntamiento",
    //     "description": "0079e129d522e644",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5376272,
    //     "longitude": -0.3601489,
    //     "deviceEUI": "0079e129d522e644",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7134,
    //     "prevFrameCounterFcnt": 9,
    //     "latestFrameCounterFcnt": 10,
    //     "lostFcnt": -296819,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 184,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_sarieta",
    //     "description": "ibox  instalado en foios en la calle sarieta en el extremo del pueblo",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.539585,
    //     "longitude": -0.3599087,
    //     "deviceEUI": "0079e129d522e646",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 931,
    //     "prevFrameCounterFcnt": 3368,
    //     "latestFrameCounterFcnt": 3369,
    //     "lostFcnt": -66724,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 185,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_agenciaocupacio",
    //     "description": "ibox instalado en foios en el circulo concentrico exterior al lado de la casa de la ocupacio",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5404003,
    //     "longitude": -0.359261,
    //     "deviceEUI": "0079e129d522e641",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -124,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 2875,
    //     "prevFrameCounterFcnt": 4607,
    //     "latestFrameCounterFcnt": 4608,
    //     "lostFcnt": -106188,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 186,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_regnevalencia",
    //     "description": "ibox instalado en foios en el extremo oeste en la calle regne valencia",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5384722,
    //     "longitude": -0.3622443,
    //     "deviceEUI": "0079e129d522e640",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 302,
    //     "latestFrameCounterFcnt": 344,
    //     "lostFcnt": -702,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 187,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_caminolacruz",
    //     "description": "ibx instalado en foios en el extremo suroeste en la calle camino de la cruz",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5338611,
    //     "longitude": -0.3573831,
    //     "deviceEUI": "0079e129d522e634",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 145,
    //     "prevFrameCounterFcnt": 186,
    //     "latestFrameCounterFcnt": 187,
    //     "lostFcnt": -162678,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb3330b1",
    //     "messageTime": null
    // },
    // {
    //     "id": 189,
    //     "gatewaysId": null,
    //     "name": "Cuadro a.p AE",
    //     "description": "extremo este cercano a burguer king",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-23",
    //     "applicationName": null,
    //     "latitude": 39.424622,
    //     "longitude": -0.40511,
    //     "deviceEUI": "0079e129d522e615",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 190,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_bar",
    //     "description": "ibox al este de paiporta cerca de un bar",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-23",
    //     "applicationName": null,
    //     "latitude": 39.4252645,
    //     "longitude": -0.4089387,
    //     "deviceEUI": "0079e129d522e616",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -90,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 3118,
    //     "prevFrameCounterFcnt": 2,
    //     "latestFrameCounterFcnt": 3,
    //     "lostFcnt": -739446,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca632143f21",
    //     "messageTime": null
    // },
    // {
    //     "id": 191,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_parque",
    //     "description": "ibox al este de paiporta cerca de un parque",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4255038,
    //     "longitude": -0.4108358,
    //     "deviceEUI": "0079e129d522e617",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -107,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2313,
    //     "prevFrameCounterFcnt": 2437,
    //     "latestFrameCounterFcnt": 2438,
    //     "lostFcnt": -1993127,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4ae486",
    //     "messageTime": null
    // },
    // {
    //     "id": 192,
    //     "gatewaysId": null,
    //     "name": "Ibox_Paiporta_Cedisa",
    //     "description": "ibox paiporta en poligono cerca de taller de motos",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4274159,
    //     "longitude": -0.4065676,
    //     "deviceEUI": "0079e129d522e618",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 193,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_faitanar",
    //     "description": "ibox paiporta poligono calle faitanar a mitad",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4286756,
    //     "longitude": -0.4060418,
    //     "deviceEUI": "0079e129d522e620",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -63,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 18,
    //     "latestFrameCounterFcnt": 0,
    //     "lostFcnt": -776,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca632143f21",
    //     "messageTime": null
    // },
    // {
    //     "id": 194,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_lasenia",
    //     "description": "ibox paiporta al sureste en la salida del colegio la senia",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4212418,
    //     "longitude": -0.4151241,
    //     "deviceEUI": "0079e129d522e612",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 195,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_independencia",
    //     "description": "ibox paiporta al noroeste en la calle independencia",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4305412,
    //     "longitude": -0.4217051,
    //     "deviceEUI": "0079e129d522e622",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 197,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_maximiliano",
    //     "description": "ibox paiporta al norte en la calle maximiliano",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4333949,
    //     "longitude": -0.414235,
    //     "deviceEUI": "0079e129d522e625",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 198,
    //     "gatewaysId": null,
    //     "name": "Ibox_Paiporta_Primitiva",
    //     "description": "ibox en el centro norte calle banda primitiva",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4284961,
    //     "longitude": -0.4135304,
    //     "deviceEUI": "0079e129d522e619",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 199,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_acequia",
    //     "description": "ibox paiporta al este en la calle acequia",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4294963,
    //     "longitude": -0.4032379,
    //     "deviceEUI": "0079e129d522e621",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 200,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_acequiadequart",
    //     "description": "ibox paiporta al noreste calle acequia de quart",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4309699,
    //     "longitude": -0.4043883,
    //     "deviceEUI": "0079e129d522e624",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 201,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_acequiafaitanar",
    //     "description": "prueba_taller",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4336567,
    //     "longitude": -0.4040864,
    //     "deviceEUI": "0079e129d522e626",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 3,
    //     "rssi": -65,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 4592,
    //     "prevFrameCounterFcnt": 692,
    //     "latestFrameCounterFcnt": 696,
    //     "lostFcnt": -83622,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb4ae486",
    //     "messageTime": null
    // },
    // {
    //     "id": 202,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_ausiasmarch",
    //     "description": "ibos paiporta centro noroeste en calle ausias march",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4306035,
    //     "longitude": -0.416513,
    //     "deviceEUI": "0079e129d522e623",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 203,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_quart",
    //     "description": "ibox paiporta al suroeste en calle quart de poblet",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4226541,
    //     "longitude": -0.4174941,
    //     "deviceEUI": "0079e129d522e613",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 204,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_polideportivo",
    //     "description": "ibox paiporta  al sur instalado dentro del polideportivo",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4247485,
    //     "longitude": -0.4143086,
    //     "deviceEUI": "0079e129d522e614",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 209,
    //     "gatewaysId": null,
    //     "name": "a079e129d522e616",
    //     "description": "a079e129d522e611",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "31",
    //     "sensorTypeInfo": {
    //         "id": 31,
    //         "name": "iBOX_Paiporta",
    //         "description": "IBOX Ayto. Paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "a079e129d522e611",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 210,
    //     "gatewaysId": null,
    //     "name": "a079e129d522e616",
    //     "description": "ibox instalado en paiporta al este del centro al lado de un burguerking",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "20",
    //     "sensorTypeInfo": {
    //         "id": 20,
    //         "name": "IBOX-PUERTA-TEST",
    //         "description": "Puerta TEST",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-01",
    //     "applicationName": null,
    //     "latitude": 39.422413,
    //     "longitude": -0.405982,
    //     "deviceEUI": "a079e129d522e616",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "a"
    //         },
    //         {
    //             "inputComponent": "b"
    //         },
    //         {
    //             "inputComponent": "c"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 220,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC01",
    //     "description": "constitucion-20",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bd5",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 290,
    //     "prevFrameCounterFcnt": 1339,
    //     "latestFrameCounterFcnt": 1340,
    //     "lostFcnt": 320,
    //     "loraSNR": -16,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 222,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC03",
    //     "description": "constitucion-14",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bd4",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 608,
    //     "prevFrameCounterFcnt": 1668,
    //     "latestFrameCounterFcnt": 1669,
    //     "lostFcnt": 211,
    //     "loraSNR": -12,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 223,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC05",
    //     "description": "constitucion-30",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bd3",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 59,
    //     "prevFrameCounterFcnt": 803,
    //     "latestFrameCounterFcnt": 804,
    //     "lostFcnt": 124,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 224,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC06",
    //     "description": "constitucion-36",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a83f",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 50,
    //     "prevFrameCounterFcnt": 28,
    //     "latestFrameCounterFcnt": 34,
    //     "lostFcnt": -26,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 225,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC09",
    //     "description": "Gandia-29",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a841",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": -112,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 276,
    //     "prevFrameCounterFcnt": 563,
    //     "latestFrameCounterFcnt": 564,
    //     "lostFcnt": -187,
    //     "loraSNR": -13,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 226,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC10",
    //     "description": "Gandia-28",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872776b9",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 9,
    //     "prevFrameCounterFcnt": 55,
    //     "latestFrameCounterFcnt": 1,
    //     "lostFcnt": -67,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 227,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC13",
    //     "description": "convento-51",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872776b8",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 674,
    //     "latestFrameCounterFcnt": 675,
    //     "lostFcnt": 278,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 228,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC15",
    //     "description": "Convento-34",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a397",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 312,
    //     "prevFrameCounterFcnt": 813,
    //     "latestFrameCounterFcnt": 814,
    //     "lostFcnt": -240,
    //     "loraSNR": -18,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 229,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC03",
    //     "description": "Jaime-calafate-2acc",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450031548",
    //     "appEUI": "2",
    //     "appKEY": "C5B55C8B19A92D638F6B67F376110B06",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 311,
    //     "prevFrameCounterFcnt": 1064,
    //     "latestFrameCounterFcnt": 1068,
    //     "lostFcnt": 516,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 230,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC07",
    //     "description": "Jaime-calafate-2",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033989",
    //     "appEUI": "2",
    //     "appKEY": "23435DA6A2AEAC81381E5737A2FF1E6A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 299,
    //     "prevFrameCounterFcnt": 851,
    //     "latestFrameCounterFcnt": 852,
    //     "lostFcnt": 163,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 231,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC12",
    //     "description": "Rei-en-Jaume-14",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034077",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 284,
    //     "prevFrameCounterFcnt": 807,
    //     "latestFrameCounterFcnt": 808,
    //     "lostFcnt": 108,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 232,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC13",
    //     "description": "Rei-en-Jaume-16",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033968",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 286,
    //     "prevFrameCounterFcnt": 834,
    //     "latestFrameCounterFcnt": 835,
    //     "lostFcnt": 189,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 233,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC14",
    //     "description": "Rei-en-Jaume-18",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034101",
    //     "appEUI": "2",
    //     "appKEY": "9BFC7FFB32842F714F940742B515F57D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 303,
    //     "prevFrameCounterFcnt": 835,
    //     "latestFrameCounterFcnt": 836,
    //     "lostFcnt": 138,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 234,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC15",
    //     "description": "Rei-en-Jaume-22",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033917",
    //     "appEUI": "2",
    //     "appKEY": "BEA29B6ACDB3E8F7C2251D8CAEFABB95",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 279,
    //     "prevFrameCounterFcnt": 794,
    //     "latestFrameCounterFcnt": 795,
    //     "lostFcnt": 119,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 235,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC16",
    //     "description": "Rei-en-Jaume-24",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033982",
    //     "appEUI": "app",
    //     "appKEY": "3A29D9FB7ED836C8048ACA3AE368357F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 299,
    //     "prevFrameCounterFcnt": 815,
    //     "latestFrameCounterFcnt": 816,
    //     "lostFcnt": 110,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 236,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC17",
    //     "description": "Rei-en-Jaume-26",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033916",
    //     "appEUI": "app",
    //     "appKEY": "4503E4F51C69D7D6EF3B0C2871CA8241",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 297,
    //     "prevFrameCounterFcnt": 862,
    //     "latestFrameCounterFcnt": 863,
    //     "lostFcnt": 185,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 237,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC18",
    //     "description": "Rei-en-Jaume-30",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033993",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 795,
    //     "latestFrameCounterFcnt": 796,
    //     "lostFcnt": 93,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 238,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC19",
    //     "description": "Rei-en-JAume-44",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034016",
    //     "appEUI": "app",
    //     "appKEY": "0767D7630F287DB586B99FF4B086EF95",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -99,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 790,
    //     "latestFrameCounterFcnt": 791,
    //     "lostFcnt": 94,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 239,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC21",
    //     "description": "Escoleta-80",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034108",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 310,
    //     "prevFrameCounterFcnt": 809,
    //     "latestFrameCounterFcnt": 810,
    //     "lostFcnt": 84,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 240,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC22",
    //     "description": "Escoleta-78",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034031",
    //     "appEUI": null,
    //     "appKEY": "22F8B490D8D0318B12C1961F1F48A772",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -78,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 286,
    //     "prevFrameCounterFcnt": 785,
    //     "latestFrameCounterFcnt": 786,
    //     "lostFcnt": 87,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 241,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC23",
    //     "description": "Escoleta-79",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034040",
    //     "appEUI": "app",
    //     "appKEY": "3E42B8A57822B1C82C5A63BD396ECECD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -95,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 294,
    //     "prevFrameCounterFcnt": 810,
    //     "latestFrameCounterFcnt": 811,
    //     "lostFcnt": 88,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 242,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC24",
    //     "description": "Escoleta-81-A",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033955",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -83,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 270,
    //     "prevFrameCounterFcnt": 757,
    //     "latestFrameCounterFcnt": 758,
    //     "lostFcnt": 81,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 243,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC25",
    //     "description": "Escoleta-77",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033936",
    //     "appEUI": "app",
    //     "appKEY": "8A2BDBE3960C9C476EAA9D57617E6965",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 318,
    //     "prevFrameCounterFcnt": 836,
    //     "latestFrameCounterFcnt": 837,
    //     "lostFcnt": 116,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 244,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC26",
    //     "description": "Escoleta-76-bajo",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028716",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -79,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 294,
    //     "prevFrameCounterFcnt": 783,
    //     "latestFrameCounterFcnt": 784,
    //     "lostFcnt": 87,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 245,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC27",
    //     "description": "Escoleta-76-1",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034095",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -87,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 274,
    //     "prevFrameCounterFcnt": 763,
    //     "latestFrameCounterFcnt": 764,
    //     "lostFcnt": 85,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 246,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC28",
    //     "description": "Escoleta-74",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034110",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -97,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 299,
    //     "prevFrameCounterFcnt": 802,
    //     "latestFrameCounterFcnt": 803,
    //     "lostFcnt": 95,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 247,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC29",
    //     "description": "Escoleta-72",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033952",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -91,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 278,
    //     "prevFrameCounterFcnt": 751,
    //     "latestFrameCounterFcnt": 752,
    //     "lostFcnt": 78,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 248,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC30",
    //     "description": "Escoleta-75",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033925",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 312,
    //     "prevFrameCounterFcnt": 836,
    //     "latestFrameCounterFcnt": 837,
    //     "lostFcnt": 102,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 249,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC31",
    //     "description": "Escoleta-73",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033986",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -85,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 288,
    //     "prevFrameCounterFcnt": 803,
    //     "latestFrameCounterFcnt": 804,
    //     "lostFcnt": 100,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 250,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC32",
    //     "description": "Escoleta-71",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033942",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 271,
    //     "prevFrameCounterFcnt": 784,
    //     "latestFrameCounterFcnt": 785,
    //     "lostFcnt": 98,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 251,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC33",
    //     "description": "Escoleta-67",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034038",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 290,
    //     "prevFrameCounterFcnt": 811,
    //     "latestFrameCounterFcnt": 812,
    //     "lostFcnt": 109,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 252,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC34",
    //     "description": "Escoleta-65",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033979",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -78,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 776,
    //     "latestFrameCounterFcnt": 777,
    //     "lostFcnt": 81,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 253,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC35",
    //     "description": "Escoleta-63",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033970",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -84,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 298,
    //     "prevFrameCounterFcnt": 796,
    //     "latestFrameCounterFcnt": 797,
    //     "lostFcnt": 89,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 254,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC36",
    //     "description": "Escoleta-70",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032093",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -97,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 300,
    //     "prevFrameCounterFcnt": 823,
    //     "latestFrameCounterFcnt": 825,
    //     "lostFcnt": 119,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 255,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC37",
    //     "description": "Esoleta-68",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032107",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 294,
    //     "prevFrameCounterFcnt": 782,
    //     "latestFrameCounterFcnt": 783,
    //     "lostFcnt": 82,
    //     "loraSNR": 12,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 256,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC38",
    //     "description": "Escoleta-66",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034001",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -95,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 277,
    //     "prevFrameCounterFcnt": 800,
    //     "latestFrameCounterFcnt": 801,
    //     "lostFcnt": 113,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 257,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC39",
    //     "description": "Escoleta-64",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034033",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 295,
    //     "prevFrameCounterFcnt": 807,
    //     "latestFrameCounterFcnt": 808,
    //     "lostFcnt": 109,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 258,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC40",
    //     "description": "Escoleta-62",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034037",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -103,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 291,
    //     "prevFrameCounterFcnt": 778,
    //     "latestFrameCounterFcnt": 779,
    //     "lostFcnt": 79,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 259,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC41",
    //     "description": "Escoleta-60",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034010",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -77,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 294,
    //     "prevFrameCounterFcnt": 805,
    //     "latestFrameCounterFcnt": 806,
    //     "lostFcnt": 113,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 260,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC42",
    //     "description": "Escoleta-58",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034074",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -74,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 268,
    //     "prevFrameCounterFcnt": 746,
    //     "latestFrameCounterFcnt": 747,
    //     "lostFcnt": 78,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 261,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC43",
    //     "description": "Escoleta-56",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034013",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -72,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 302,
    //     "prevFrameCounterFcnt": 838,
    //     "latestFrameCounterFcnt": 839,
    //     "lostFcnt": 119,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 262,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC44",
    //     "description": "Escoleta-52",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033997",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -79,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 282,
    //     "prevFrameCounterFcnt": 771,
    //     "latestFrameCounterFcnt": 772,
    //     "lostFcnt": 75,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 263,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC46",
    //     "description": "Escoleta-61",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034000",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 791,
    //     "latestFrameCounterFcnt": 792,
    //     "lostFcnt": 98,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 264,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC47",
    //     "description": "Escoleta-59",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034003",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -102,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 790,
    //     "latestFrameCounterFcnt": 791,
    //     "lostFcnt": 85,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 265,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC48",
    //     "description": "Escoleta-48",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034088",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -89,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 297,
    //     "prevFrameCounterFcnt": 813,
    //     "latestFrameCounterFcnt": 815,
    //     "lostFcnt": 102,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 266,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC49",
    //     "description": "Escoleta-57B",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033972",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -100,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 290,
    //     "prevFrameCounterFcnt": 799,
    //     "latestFrameCounterFcnt": 800,
    //     "lostFcnt": 108,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 267,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC50",
    //     "description": "Escoleta-57",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034071",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -97,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 282,
    //     "prevFrameCounterFcnt": 798,
    //     "latestFrameCounterFcnt": 799,
    //     "lostFcnt": 99,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 268,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC51",
    //     "description": "Escoleta-46",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033957",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -80,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 268,
    //     "prevFrameCounterFcnt": 753,
    //     "latestFrameCounterFcnt": 754,
    //     "lostFcnt": 76,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 269,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC52",
    //     "description": "Escoleta-44",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034115",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 277,
    //     "prevFrameCounterFcnt": 760,
    //     "latestFrameCounterFcnt": 761,
    //     "lostFcnt": 81,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 270,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC54",
    //     "description": "Escoleta-43",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033991",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 786,
    //     "latestFrameCounterFcnt": 787,
    //     "lostFcnt": 100,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 271,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC55",
    //     "description": "Escoleta-38",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034082",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -106,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 266,
    //     "prevFrameCounterFcnt": 741,
    //     "latestFrameCounterFcnt": 742,
    //     "lostFcnt": 76,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 272,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC56",
    //     "description": "Escoleta-36",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034072",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 279,
    //     "prevFrameCounterFcnt": 752,
    //     "latestFrameCounterFcnt": 753,
    //     "lostFcnt": 79,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 273,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC57",
    //     "description": "Escoleta-41",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033912",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -102,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 306,
    //     "prevFrameCounterFcnt": 797,
    //     "latestFrameCounterFcnt": 798,
    //     "lostFcnt": 95,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 274,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC58",
    //     "description": "Escoleta-34",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034070",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -105,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 775,
    //     "latestFrameCounterFcnt": 776,
    //     "lostFcnt": 92,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 275,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC59",
    //     "description": "Escoleta-39",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033976",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 302,
    //     "prevFrameCounterFcnt": 805,
    //     "latestFrameCounterFcnt": 806,
    //     "lostFcnt": 95,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 276,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC60",
    //     "description": "Escoleta-37",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033974",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 799,
    //     "latestFrameCounterFcnt": 800,
    //     "lostFcnt": 100,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 277,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC61",
    //     "description": "Escoleta-32",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032095",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 304,
    //     "prevFrameCounterFcnt": 804,
    //     "latestFrameCounterFcnt": 805,
    //     "lostFcnt": 93,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 278,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC62",
    //     "description": "Escoleta-30",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034015",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 301,
    //     "prevFrameCounterFcnt": 789,
    //     "latestFrameCounterFcnt": 790,
    //     "lostFcnt": 99,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 279,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC63",
    //     "description": "Escoleta-28",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032067",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 292,
    //     "prevFrameCounterFcnt": 823,
    //     "latestFrameCounterFcnt": 824,
    //     "lostFcnt": 136,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 280,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC64",
    //     "description": "Escoleta-35",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034024",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 800,
    //     "latestFrameCounterFcnt": 801,
    //     "lostFcnt": 105,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 281,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC65",
    //     "description": "Escoleta-24",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033964",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 277,
    //     "prevFrameCounterFcnt": 799,
    //     "latestFrameCounterFcnt": 800,
    //     "lostFcnt": 113,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 282,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC66",
    //     "description": "Escoleta-22",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034043",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 286,
    //     "prevFrameCounterFcnt": 859,
    //     "latestFrameCounterFcnt": 861,
    //     "lostFcnt": 160,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 283,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC67",
    //     "description": "Escoleta-29",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034102",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 280,
    //     "prevFrameCounterFcnt": 795,
    //     "latestFrameCounterFcnt": 796,
    //     "lostFcnt": 105,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 284,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC68",
    //     "description": "Escoleta-20",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033963",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -110,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 295,
    //     "prevFrameCounterFcnt": 834,
    //     "latestFrameCounterFcnt": 835,
    //     "lostFcnt": 138,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 285,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC70",
    //     "description": "Escoleta-23",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034034",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -111,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 282,
    //     "prevFrameCounterFcnt": 819,
    //     "latestFrameCounterFcnt": 820,
    //     "lostFcnt": 135,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 286,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC69",
    //     "description": "Escoleta-27",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034096",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 279,
    //     "prevFrameCounterFcnt": 810,
    //     "latestFrameCounterFcnt": 811,
    //     "lostFcnt": 138,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 287,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC71",
    //     "description": "Escoleta-21",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034049",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 280,
    //     "prevFrameCounterFcnt": 797,
    //     "latestFrameCounterFcnt": 798,
    //     "lostFcnt": 105,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 288,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC72",
    //     "description": "Escoleta-19",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033959",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 282,
    //     "prevFrameCounterFcnt": 788,
    //     "latestFrameCounterFcnt": 789,
    //     "lostFcnt": 96,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 289,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC73",
    //     "description": "Escoleta-16",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034094",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 288,
    //     "prevFrameCounterFcnt": 771,
    //     "latestFrameCounterFcnt": 772,
    //     "lostFcnt": 77,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 290,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC74",
    //     "description": "Escoleta-14",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034114",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 303,
    //     "prevFrameCounterFcnt": 811,
    //     "latestFrameCounterFcnt": 812,
    //     "lostFcnt": 113,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 291,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC75",
    //     "description": "Escoleta-15",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033967",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 270,
    //     "prevFrameCounterFcnt": 811,
    //     "latestFrameCounterFcnt": 812,
    //     "lostFcnt": 145,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 292,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC76",
    //     "description": "Escoleta-13",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034014",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 820,
    //     "latestFrameCounterFcnt": 821,
    //     "lostFcnt": 153,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 293,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC77",
    //     "description": "Escoleta-12",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033929",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 330,
    //     "prevFrameCounterFcnt": 878,
    //     "latestFrameCounterFcnt": 879,
    //     "lostFcnt": 140,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 294,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC78",
    //     "description": "Escoleta-10",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034112",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 270,
    //     "prevFrameCounterFcnt": 786,
    //     "latestFrameCounterFcnt": 787,
    //     "lostFcnt": 117,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 295,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC79",
    //     "description": "Escoleta-8",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034064",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 301,
    //     "prevFrameCounterFcnt": 806,
    //     "latestFrameCounterFcnt": 807,
    //     "lostFcnt": 104,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 296,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC80",
    //     "description": "Escoleta-11",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034027",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 299,
    //     "prevFrameCounterFcnt": 806,
    //     "latestFrameCounterFcnt": 807,
    //     "lostFcnt": 89,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 297,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC81",
    //     "description": "Escoleta-6",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033998",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 293,
    //     "prevFrameCounterFcnt": 825,
    //     "latestFrameCounterFcnt": 827,
    //     "lostFcnt": 117,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 298,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC82",
    //     "description": "Escoleta-4",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034019",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 788,
    //     "latestFrameCounterFcnt": 789,
    //     "lostFcnt": 94,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 299,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC83",
    //     "description": "Escoleta-3",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034059",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 281,
    //     "prevFrameCounterFcnt": 794,
    //     "latestFrameCounterFcnt": 795,
    //     "lostFcnt": 126,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 300,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC84",
    //     "description": "Escoleta-5",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034104",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 808,
    //     "latestFrameCounterFcnt": 809,
    //     "lostFcnt": 117,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 301,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC85",
    //     "description": "Escoleta-7",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033914",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -108,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 307,
    //     "prevFrameCounterFcnt": 823,
    //     "latestFrameCounterFcnt": 824,
    //     "lostFcnt": 104,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 302,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC86",
    //     "description": "Escoleta9",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450045529",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 303,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC87",
    //     "description": "Escoleta-69",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034091",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 777,
    //     "latestFrameCounterFcnt": 778,
    //     "lostFcnt": 81,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 304,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC88",
    //     "description": "Escoleta-74bis",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034021",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -82,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 273,
    //     "prevFrameCounterFcnt": 793,
    //     "latestFrameCounterFcnt": 794,
    //     "lostFcnt": 97,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 305,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC89",
    //     "description": "Escoleta-42",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033949",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 271,
    //     "prevFrameCounterFcnt": 763,
    //     "latestFrameCounterFcnt": 764,
    //     "lostFcnt": 85,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 306,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC90",
    //     "description": "Escoleta-53",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034009",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -103,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 313,
    //     "prevFrameCounterFcnt": 841,
    //     "latestFrameCounterFcnt": 842,
    //     "lostFcnt": 122,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 307,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC91",
    //     "description": "Escoleta-45",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034011",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 288,
    //     "prevFrameCounterFcnt": 791,
    //     "latestFrameCounterFcnt": 792,
    //     "lostFcnt": 94,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 308,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC92",
    //     "description": "Escoleta-25",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033948",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 290,
    //     "prevFrameCounterFcnt": 840,
    //     "latestFrameCounterFcnt": 841,
    //     "lostFcnt": 172,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 309,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC93",
    //     "description": "Rei-en-Jaume-10",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033924",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 331,
    //     "prevFrameCounterFcnt": 1029,
    //     "latestFrameCounterFcnt": 1030,
    //     "lostFcnt": 385,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 107,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e566_sensor",
    //     "description": "ibox sensor",
    //     "provider": null,
    //     "userId": 32,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-06-15",
    //     "applicationName": null,
    //     "latitude": 39.120249,
    //     "longitude": -0.454043,
    //     "deviceEUI": "0079e129d522e566",
    //     "appEUI": "12",
    //     "appKEY": "12",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 197579,
    //     "prevFrameCounterFcnt": 1409,
    //     "latestFrameCounterFcnt": 1409,
    //     "lostFcnt": -778212,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb3f1042",
    //     "messageTime": null
    // },
    // {
    //     "id": 108,
    //     "gatewaysId": null,
    //     "name": "testIbox",
    //     "description": "For testing All",
    //     "provider": null,
    //     "userId": 32,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-06-18",
    //     "applicationName": null,
    //     "latitude": 39.427242,
    //     "longitude": -1.114482,
    //     "deviceEUI": "0079e129d522e634",
    //     "appEUI": "12",
    //     "appKEY": "12",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "inputcmp1"
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": "metercmp1"
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 145,
    //     "prevFrameCounterFcnt": 186,
    //     "latestFrameCounterFcnt": 187,
    //     "lostFcnt": -162678,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb3330b1",
    //     "messageTime": null
    // },
    // {
    //     "id": 109,
    //     "gatewaysId": null,
    //     "name": "Sensor_ibox_Alberto",
    //     "description": "IBOX TEST",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "16",
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-06-24",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e566",
    //     "appEUI": "06263577c3bbc9eb",
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "DoorC01"
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": "EnergyMeterVIPAC01"
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 197579,
    //     "prevFrameCounterFcnt": 1409,
    //     "latestFrameCounterFcnt": 1409,
    //     "lostFcnt": -778212,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb3f1042",
    //     "messageTime": null
    // },
    // {
    //     "id": 110,
    //     "gatewaysId": null,
    //     "name": "IBOX1_Biblio",
    //     "description": "iBOX_GESWAT_e647",
    //     "provider": null,
    //     "userId": 36,
    //     "typeSensor": "20",
    //     "sensorTypeInfo": {
    //         "id": 20,
    //         "name": "IBOX-PUERTA-TEST",
    //         "description": "Puerta TEST",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-06-26",
    //     "applicationName": null,
    //     "latitude": 39.567724,
    //     "longitude": -0.622264,
    //     "deviceEUI": "0079e129d522e647",
    //     "appEUI": "06263577c3bbc9eb",
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb66bea3,b827eb6c4e09,dca63214b1d0,b827eb11f2e1",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 115,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e631_doorLibrary",
    //     "description": "iBOX_GESWAT_e631",
    //     "provider": null,
    //     "userId": 77,
    //     "typeSensor": "89",
    //     "sensorTypeInfo": {
    //         "id": 89,
    //         "name": "Olmos_ibox",
    //         "description": null,
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-07-15",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e631",
    //     "appEUI": null,
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": "b827eb524297",
    //     "dr": 3,
    //     "rssi": -125,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 682,
    //     "latestFrameCounterFcnt": 1494,
    //     "lostFcnt": -322249,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb524297",
    //     "messageTime": null
    // },
    // {
    //     "id": 117,
    //     "gatewaysId": null,
    //     "name": "iBOX_Biblioteca",
    //     "description": "Quatretonda iBox Biblioteca",
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": "21",
    //     "sensorTypeInfo": {
    //         "id": 21,
    //         "name": "iBOX_Puerta",
    //         "description": "Ibox Biblioteca",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-07-19",
    //     "applicationName": null,
    //     "latitude": 38.945463,
    //     "longitude": -0.399479,
    //     "deviceEUI": "0079e129d522e629",
    //     "appEUI": "06263577c3bbc9eb",
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "EnergyMeterQUAGWTC01"
    //         },
    //         {
    //             "inputComponent": "EnergyMeterQUAGWTC02"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -57,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 5066,
    //     "prevFrameCounterFcnt": 4373,
    //     "latestFrameCounterFcnt": 4374,
    //     "lostFcnt": -784639,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 118,
    //     "gatewaysId": null,
    //     "name": "iBOX_Ayuntamiento",
    //     "description": "iBOX Ayuntamiento",
    //     "provider": null,
    //     "userId": 41,
    //     "typeSensor": "22",
    //     "sensorTypeInfo": {
    //         "id": 22,
    //         "name": "iBOX_Ayuntamiento",
    //         "description": "iBOX Ayuntamiento",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-07-19",
    //     "applicationName": null,
    //     "latitude": 38.853991,
    //     "longitude": -0.502074,
    //     "deviceEUI": "0079e129d522e630",
    //     "appEUI": "06263577c3bbc9eb",
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "EnergyMeterEARCTMC01"
    //         },
    //         {
    //             "inputComponent": "EnergyMeterEARCTMC02"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -98,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 326,
    //     "prevFrameCounterFcnt": 2407,
    //     "latestFrameCounterFcnt": 2408,
    //     "lostFcnt": -753304,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827ebf0f17b",
    //     "messageTime": null
    // },
    // {
    //     "id": 121,
    //     "gatewaysId": null,
    //     "name": "171fe129d522e000_wifi",
    //     "description": "Wifi Sensor",
    //     "provider": null,
    //     "userId": 36,
    //     "typeSensor": "27",
    //     "sensorTypeInfo": {
    //         "id": 27,
    //         "name": "171fe129d522e000_wifi_Type",
    //         "description": null,
    //         "sensorType": "wifi",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-08-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "171fe129d522e000",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb66bea3,b827eb6c4e09,dca63214b1d0,b827eb11f2e1",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 123,
    //     "gatewaysId": null,
    //     "name": "Alberto_Puerta",
    //     "description": "Test puerta",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "21",
    //     "sensorTypeInfo": {
    //         "id": 21,
    //         "name": "iBOX_Puerta",
    //         "description": "Ibox Biblioteca",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-10-06",
    //     "applicationName": null,
    //     "latitude": 39.592868,
    //     "longitude": -0.544343,
    //     "deviceEUI": "0079e129d522e630",
    //     "appEUI": "06263577C3BBC9EB2FEF99135EF0F6A5",
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "DoorC01"
    //         },
    //         {
    //             "inputComponent": "DoorC02"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -98,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 326,
    //     "prevFrameCounterFcnt": 2407,
    //     "latestFrameCounterFcnt": 2408,
    //     "lostFcnt": -753304,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827ebf0f17b",
    //     "messageTime": null
    // },
    // {
    //     "id": 124,
    //     "gatewaysId": null,
    //     "name": "DCSWAGWS0000000008",
    //     "description": "water castellon itron",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "30",
    //     "sensorTypeInfo": {
    //         "id": 30,
    //         "name": "water_itron",
    //         "description": "for water",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-11-25",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "532253811817804e",
    //     "appEUI": "00000000000000000",
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 125,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e613_N",
    //     "description": "0079e129d522e613_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e613",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 126,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e614_N",
    //     "description": "0079e129d522e614_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e614",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 127,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e615_N",
    //     "description": "0079e129d522e615_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e615",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 128,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e616_N",
    //     "description": "0079e129d522e616_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e616",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -90,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 3118,
    //     "prevFrameCounterFcnt": 2,
    //     "latestFrameCounterFcnt": 3,
    //     "lostFcnt": -739446,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca632143f21",
    //     "messageTime": null
    // },
    // {
    //     "id": 129,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e617_N",
    //     "description": "0079e129d522e617_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e617",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -107,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2313,
    //     "prevFrameCounterFcnt": 2437,
    //     "latestFrameCounterFcnt": 2438,
    //     "lostFcnt": -1993127,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4ae486",
    //     "messageTime": null
    // },
    // {
    //     "id": 131,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e621_ibox",
    //     "description": null,
    //     "provider": null,
    //     "userId": 52,
    //     "typeSensor": "32",
    //     "sensorTypeInfo": {
    //         "id": 32,
    //         "name": "0079e129d522e621_ibox_Type",
    //         "description": null,
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-08",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e621",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 136,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC01",
    //     "description": "Jaume-Calafate-1",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-07",
    //     "applicationName": null,
    //     "latitude": 39.097538,
    //     "longitude": -0.630113,
    //     "deviceEUI": "04b6480450034109",
    //     "appEUI": "app",
    //     "appKEY": "7F612E785A07E056205E9799861F619C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 326,
    //     "prevFrameCounterFcnt": 929,
    //     "latestFrameCounterFcnt": 951,
    //     "lostFcnt": 322,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 141,
    //     "gatewaysId": null,
    //     "name": "171fe129d522e001_wifi",
    //     "description": null,
    //     "provider": null,
    //     "userId": 54,
    //     "typeSensor": "41",
    //     "sensorTypeInfo": {
    //         "id": 41,
    //         "name": "171fe129d522e001_wifi_Type",
    //         "description": null,
    //         "sensorType": "wifi",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-11",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "171fe129d522e001",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -105,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2345,
    //     "prevFrameCounterFcnt": 128722,
    //     "latestFrameCounterFcnt": 128724,
    //     "lostFcnt": -691835,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb481840",
    //     "messageTime": null
    // },
    // {
    //     "id": 142,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC02",
    //     "description": "Jaume-Calafate-3",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-07",
    //     "applicationName": null,
    //     "latitude": 39.097584,
    //     "longitude": -0.630043,
    //     "deviceEUI": "04b6480450034057",
    //     "appEUI": "app",
    //     "appKEY": "CEF522F5F799AE91D158D7DCD72F8B7D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 296,
    //     "prevFrameCounterFcnt": 899,
    //     "latestFrameCounterFcnt": 900,
    //     "lostFcnt": 243,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 143,
    //     "gatewaysId": null,
    //     "name": "Sensor de Oficina Pruebas",
    //     "description": "para probar en el laboratorio",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "42",
    //     "sensorTypeInfo": {
    //         "id": 42,
    //         "name": "ibox prueba OFININA",
    //         "description": "para probar en el laboratorio",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-14",
    //     "applicationName": null,
    //     "latitude": 39.594463203832,
    //     "longitude": -0.55226997501051,
    //     "deviceEUI": "a079e129d522e624",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "door sensor"
    //         },
    //         {
    //             "inputComponent": "current sensor"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 146,
    //     "gatewaysId": null,
    //     "name": "METEOVINSWTC01",
    //     "description": null,
    //     "provider": null,
    //     "userId": 54,
    //     "typeSensor": "47",
    //     "sensorTypeInfo": {
    //         "id": 47,
    //         "name": "METEOGESWAT1",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "Modbus"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-03-11",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "141f3c71bff07fae",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -88,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1511,
    //     "prevFrameCounterFcnt": 1392,
    //     "latestFrameCounterFcnt": 1393,
    //     "lostFcnt": -904159,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb481840",
    //     "messageTime": null
    // },
    // {
    //     "id": 147,
    //     "gatewaysId": null,
    //     "name": "BOYAVINSWTC01",
    //     "description": null,
    //     "provider": null,
    //     "userId": 54,
    //     "typeSensor": "48",
    //     "sensorTypeInfo": {
    //         "id": 48,
    //         "name": "BOYAGESWAT",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-03-12",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0004a30b00f1a9ee",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 78,
    //     "latestFrameCounterFcnt": 79,
    //     "lostFcnt": -5,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb481840",
    //     "messageTime": null
    // },
    // {
    //     "id": 153,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC45",
    //     "description": "Escoleta-54",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-03-31",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034036",
    //     "appEUI": null,
    //     "appKEY": "50AEDDE4585EA2F15399040CF9C5B791",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -71,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 305,
    //     "prevFrameCounterFcnt": 816,
    //     "latestFrameCounterFcnt": 817,
    //     "lostFcnt": 110,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 154,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC04",
    //     "description": "Jaime-calafate-5",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-04-19",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033985",
    //     "appEUI": null,
    //     "appKEY": "5351D39D5D4182D65182C06735052FED",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 316,
    //     "prevFrameCounterFcnt": 536,
    //     "latestFrameCounterFcnt": 548,
    //     "lostFcnt": 71,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 155,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC05",
    //     "description": "Jaime-calafate-7",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-04-19",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034063",
    //     "appEUI": "2",
    //     "appKEY": "74CE37EAA32F093DE218F3577385C655",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 920,
    //     "latestFrameCounterFcnt": 934,
    //     "lostFcnt": 322,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 156,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC06",
    //     "description": "Jaime-calafate-9",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-04-19",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033996",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 342,
    //     "prevFrameCounterFcnt": 893,
    //     "latestFrameCounterFcnt": 895,
    //     "lostFcnt": 156,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 174,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-11",
    //     "description": "ibox instalado en el taller con dev eui a0......611",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-19",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e611",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 175,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-13",
    //     "description": "ibox instalado en el taller con dev eui a0......613",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-19",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e613",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 176,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-15",
    //     "description": "ibox instalado en el taller con dev eui a0......615",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-19",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e615",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 178,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-17",
    //     "description": "a079e129d522e617",
    //     "provider": null,
    //     "userId": 124,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-18",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e617",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -33,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 170,
    //     "latestFrameCounterFcnt": 171,
    //     "lostFcnt": -99540,
    //     "loraSNR": 7,
    //     "networkServerMac": "e45f016c7dbc",
    //     "messageTime": null
    // },
    // {
    //     "id": 179,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-19",
    //     "description": "ibox instalado en el taller con dev eui a0......619",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-18",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e619",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 180,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-21",
    //     "description": "ibox instalado en el taller con dev eui a0......621",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-18",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e621",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 181,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_plazadelacreu",
    //     "description": "ibox intalado en foios en la plaza de la creu en un cuadro en el parque",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5364359,
    //     "longitude": -0.3560338,
    //     "deviceEUI": "0079e129d522e609",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1925,
    //     "prevFrameCounterFcnt": 26,
    //     "latestFrameCounterFcnt": 27,
    //     "lostFcnt": -48751,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 182,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_alcaldefranciscoroceto",
    //     "description": "ibox instalado en mitad de una carretera unidireccional en la calle alcalde francisco roceto",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5359263,
    //     "longitude": -0.356997,
    //     "deviceEUI": "0079e129d522e607",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 558,
    //     "prevFrameCounterFcnt": 39,
    //     "latestFrameCounterFcnt": 41,
    //     "lostFcnt": -73661,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 183,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_ayuntamiento",
    //     "description": "0079e129d522e644",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5376272,
    //     "longitude": -0.3601489,
    //     "deviceEUI": "0079e129d522e644",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7134,
    //     "prevFrameCounterFcnt": 9,
    //     "latestFrameCounterFcnt": 10,
    //     "lostFcnt": -296819,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 184,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_sarieta",
    //     "description": "ibox  instalado en foios en la calle sarieta en el extremo del pueblo",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.539585,
    //     "longitude": -0.3599087,
    //     "deviceEUI": "0079e129d522e646",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 931,
    //     "prevFrameCounterFcnt": 3368,
    //     "latestFrameCounterFcnt": 3369,
    //     "lostFcnt": -66724,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 185,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_agenciaocupacio",
    //     "description": "ibox instalado en foios en el circulo concentrico exterior al lado de la casa de la ocupacio",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5404003,
    //     "longitude": -0.359261,
    //     "deviceEUI": "0079e129d522e641",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -124,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 2875,
    //     "prevFrameCounterFcnt": 4607,
    //     "latestFrameCounterFcnt": 4608,
    //     "lostFcnt": -106188,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 186,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_regnevalencia",
    //     "description": "ibox instalado en foios en el extremo oeste en la calle regne valencia",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5384722,
    //     "longitude": -0.3622443,
    //     "deviceEUI": "0079e129d522e640",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 302,
    //     "latestFrameCounterFcnt": 344,
    //     "lostFcnt": -702,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 187,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_caminolacruz",
    //     "description": "ibx instalado en foios en el extremo suroeste en la calle camino de la cruz",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5338611,
    //     "longitude": -0.3573831,
    //     "deviceEUI": "0079e129d522e634",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 145,
    //     "prevFrameCounterFcnt": 186,
    //     "latestFrameCounterFcnt": 187,
    //     "lostFcnt": -162678,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb3330b1",
    //     "messageTime": null
    // },
    // {
    //     "id": 189,
    //     "gatewaysId": null,
    //     "name": "Cuadro a.p AE",
    //     "description": "extremo este cercano a burguer king",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-23",
    //     "applicationName": null,
    //     "latitude": 39.424622,
    //     "longitude": -0.40511,
    //     "deviceEUI": "0079e129d522e615",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 190,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_bar",
    //     "description": "ibox al este de paiporta cerca de un bar",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-23",
    //     "applicationName": null,
    //     "latitude": 39.4252645,
    //     "longitude": -0.4089387,
    //     "deviceEUI": "0079e129d522e616",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -90,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 3118,
    //     "prevFrameCounterFcnt": 2,
    //     "latestFrameCounterFcnt": 3,
    //     "lostFcnt": -739446,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca632143f21",
    //     "messageTime": null
    // },
    // {
    //     "id": 191,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_parque",
    //     "description": "ibox al este de paiporta cerca de un parque",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4255038,
    //     "longitude": -0.4108358,
    //     "deviceEUI": "0079e129d522e617",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -107,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2313,
    //     "prevFrameCounterFcnt": 2437,
    //     "latestFrameCounterFcnt": 2438,
    //     "lostFcnt": -1993127,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4ae486",
    //     "messageTime": null
    // },
    // {
    //     "id": 192,
    //     "gatewaysId": null,
    //     "name": "Ibox_Paiporta_Cedisa",
    //     "description": "ibox paiporta en poligono cerca de taller de motos",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4274159,
    //     "longitude": -0.4065676,
    //     "deviceEUI": "0079e129d522e618",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 193,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_faitanar",
    //     "description": "ibox paiporta poligono calle faitanar a mitad",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4286756,
    //     "longitude": -0.4060418,
    //     "deviceEUI": "0079e129d522e620",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -63,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 18,
    //     "latestFrameCounterFcnt": 0,
    //     "lostFcnt": -776,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca632143f21",
    //     "messageTime": null
    // },
    // {
    //     "id": 194,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_lasenia",
    //     "description": "ibox paiporta al sureste en la salida del colegio la senia",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4212418,
    //     "longitude": -0.4151241,
    //     "deviceEUI": "0079e129d522e612",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 195,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_independencia",
    //     "description": "ibox paiporta al noroeste en la calle independencia",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4305412,
    //     "longitude": -0.4217051,
    //     "deviceEUI": "0079e129d522e622",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 197,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_maximiliano",
    //     "description": "ibox paiporta al norte en la calle maximiliano",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4333949,
    //     "longitude": -0.414235,
    //     "deviceEUI": "0079e129d522e625",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 198,
    //     "gatewaysId": null,
    //     "name": "Ibox_Paiporta_Primitiva",
    //     "description": "ibox en el centro norte calle banda primitiva",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4284961,
    //     "longitude": -0.4135304,
    //     "deviceEUI": "0079e129d522e619",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 199,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_acequia",
    //     "description": "ibox paiporta al este en la calle acequia",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4294963,
    //     "longitude": -0.4032379,
    //     "deviceEUI": "0079e129d522e621",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 200,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_acequiadequart",
    //     "description": "ibox paiporta al noreste calle acequia de quart",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4309699,
    //     "longitude": -0.4043883,
    //     "deviceEUI": "0079e129d522e624",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 201,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_acequiafaitanar",
    //     "description": "prueba_taller",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4336567,
    //     "longitude": -0.4040864,
    //     "deviceEUI": "0079e129d522e626",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 3,
    //     "rssi": -65,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 4592,
    //     "prevFrameCounterFcnt": 692,
    //     "latestFrameCounterFcnt": 696,
    //     "lostFcnt": -83622,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb4ae486",
    //     "messageTime": null
    // },
    // {
    //     "id": 202,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_ausiasmarch",
    //     "description": "ibos paiporta centro noroeste en calle ausias march",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4306035,
    //     "longitude": -0.416513,
    //     "deviceEUI": "0079e129d522e623",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 203,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_quart",
    //     "description": "ibox paiporta al suroeste en calle quart de poblet",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4226541,
    //     "longitude": -0.4174941,
    //     "deviceEUI": "0079e129d522e613",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 204,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_polideportivo",
    //     "description": "ibox paiporta  al sur instalado dentro del polideportivo",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4247485,
    //     "longitude": -0.4143086,
    //     "deviceEUI": "0079e129d522e614",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 209,
    //     "gatewaysId": null,
    //     "name": "a079e129d522e616",
    //     "description": "a079e129d522e611",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "31",
    //     "sensorTypeInfo": {
    //         "id": 31,
    //         "name": "iBOX_Paiporta",
    //         "description": "IBOX Ayto. Paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "a079e129d522e611",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 210,
    //     "gatewaysId": null,
    //     "name": "a079e129d522e616",
    //     "description": "ibox instalado en paiporta al este del centro al lado de un burguerking",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "20",
    //     "sensorTypeInfo": {
    //         "id": 20,
    //         "name": "IBOX-PUERTA-TEST",
    //         "description": "Puerta TEST",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-01",
    //     "applicationName": null,
    //     "latitude": 39.422413,
    //     "longitude": -0.405982,
    //     "deviceEUI": "a079e129d522e616",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "a"
    //         },
    //         {
    //             "inputComponent": "b"
    //         },
    //         {
    //             "inputComponent": "c"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 220,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC01",
    //     "description": "constitucion-20",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bd5",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 290,
    //     "prevFrameCounterFcnt": 1339,
    //     "latestFrameCounterFcnt": 1340,
    //     "lostFcnt": 320,
    //     "loraSNR": -16,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 222,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC03",
    //     "description": "constitucion-14",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bd4",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 608,
    //     "prevFrameCounterFcnt": 1668,
    //     "latestFrameCounterFcnt": 1669,
    //     "lostFcnt": 211,
    //     "loraSNR": -12,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 223,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC05",
    //     "description": "constitucion-30",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bd3",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 59,
    //     "prevFrameCounterFcnt": 803,
    //     "latestFrameCounterFcnt": 804,
    //     "lostFcnt": 124,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 224,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC06",
    //     "description": "constitucion-36",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a83f",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 50,
    //     "prevFrameCounterFcnt": 28,
    //     "latestFrameCounterFcnt": 34,
    //     "lostFcnt": -26,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 225,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC09",
    //     "description": "Gandia-29",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a841",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": -112,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 276,
    //     "prevFrameCounterFcnt": 563,
    //     "latestFrameCounterFcnt": 564,
    //     "lostFcnt": -187,
    //     "loraSNR": -13,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 226,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC10",
    //     "description": "Gandia-28",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872776b9",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 9,
    //     "prevFrameCounterFcnt": 55,
    //     "latestFrameCounterFcnt": 1,
    //     "lostFcnt": -67,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 227,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC13",
    //     "description": "convento-51",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872776b8",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 674,
    //     "latestFrameCounterFcnt": 675,
    //     "lostFcnt": 278,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 228,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC15",
    //     "description": "Convento-34",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a397",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 312,
    //     "prevFrameCounterFcnt": 813,
    //     "latestFrameCounterFcnt": 814,
    //     "lostFcnt": -240,
    //     "loraSNR": -18,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 229,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC03",
    //     "description": "Jaime-calafate-2acc",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450031548",
    //     "appEUI": "2",
    //     "appKEY": "C5B55C8B19A92D638F6B67F376110B06",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 311,
    //     "prevFrameCounterFcnt": 1064,
    //     "latestFrameCounterFcnt": 1068,
    //     "lostFcnt": 516,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 230,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC07",
    //     "description": "Jaime-calafate-2",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033989",
    //     "appEUI": "2",
    //     "appKEY": "23435DA6A2AEAC81381E5737A2FF1E6A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 299,
    //     "prevFrameCounterFcnt": 851,
    //     "latestFrameCounterFcnt": 852,
    //     "lostFcnt": 163,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 231,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC12",
    //     "description": "Rei-en-Jaume-14",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034077",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 284,
    //     "prevFrameCounterFcnt": 807,
    //     "latestFrameCounterFcnt": 808,
    //     "lostFcnt": 108,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 232,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC13",
    //     "description": "Rei-en-Jaume-16",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033968",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 286,
    //     "prevFrameCounterFcnt": 834,
    //     "latestFrameCounterFcnt": 835,
    //     "lostFcnt": 189,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 233,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC14",
    //     "description": "Rei-en-Jaume-18",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034101",
    //     "appEUI": "2",
    //     "appKEY": "9BFC7FFB32842F714F940742B515F57D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 303,
    //     "prevFrameCounterFcnt": 835,
    //     "latestFrameCounterFcnt": 836,
    //     "lostFcnt": 138,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 234,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC15",
    //     "description": "Rei-en-Jaume-22",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033917",
    //     "appEUI": "2",
    //     "appKEY": "BEA29B6ACDB3E8F7C2251D8CAEFABB95",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 279,
    //     "prevFrameCounterFcnt": 794,
    //     "latestFrameCounterFcnt": 795,
    //     "lostFcnt": 119,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 235,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC16",
    //     "description": "Rei-en-Jaume-24",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033982",
    //     "appEUI": "app",
    //     "appKEY": "3A29D9FB7ED836C8048ACA3AE368357F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 299,
    //     "prevFrameCounterFcnt": 815,
    //     "latestFrameCounterFcnt": 816,
    //     "lostFcnt": 110,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 236,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC17",
    //     "description": "Rei-en-Jaume-26",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033916",
    //     "appEUI": "app",
    //     "appKEY": "4503E4F51C69D7D6EF3B0C2871CA8241",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 297,
    //     "prevFrameCounterFcnt": 862,
    //     "latestFrameCounterFcnt": 863,
    //     "lostFcnt": 185,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 237,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC18",
    //     "description": "Rei-en-Jaume-30",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033993",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 795,
    //     "latestFrameCounterFcnt": 796,
    //     "lostFcnt": 93,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 238,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC19",
    //     "description": "Rei-en-JAume-44",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034016",
    //     "appEUI": "app",
    //     "appKEY": "0767D7630F287DB586B99FF4B086EF95",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -99,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 790,
    //     "latestFrameCounterFcnt": 791,
    //     "lostFcnt": 94,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 239,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC21",
    //     "description": "Escoleta-80",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034108",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 310,
    //     "prevFrameCounterFcnt": 809,
    //     "latestFrameCounterFcnt": 810,
    //     "lostFcnt": 84,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 240,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC22",
    //     "description": "Escoleta-78",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034031",
    //     "appEUI": null,
    //     "appKEY": "22F8B490D8D0318B12C1961F1F48A772",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -78,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 286,
    //     "prevFrameCounterFcnt": 785,
    //     "latestFrameCounterFcnt": 786,
    //     "lostFcnt": 87,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 241,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC23",
    //     "description": "Escoleta-79",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034040",
    //     "appEUI": "app",
    //     "appKEY": "3E42B8A57822B1C82C5A63BD396ECECD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -95,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 294,
    //     "prevFrameCounterFcnt": 810,
    //     "latestFrameCounterFcnt": 811,
    //     "lostFcnt": 88,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 242,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC24",
    //     "description": "Escoleta-81-A",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033955",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -83,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 270,
    //     "prevFrameCounterFcnt": 757,
    //     "latestFrameCounterFcnt": 758,
    //     "lostFcnt": 81,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 243,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC25",
    //     "description": "Escoleta-77",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033936",
    //     "appEUI": "app",
    //     "appKEY": "8A2BDBE3960C9C476EAA9D57617E6965",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 318,
    //     "prevFrameCounterFcnt": 836,
    //     "latestFrameCounterFcnt": 837,
    //     "lostFcnt": 116,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 244,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC26",
    //     "description": "Escoleta-76-bajo",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028716",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -79,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 294,
    //     "prevFrameCounterFcnt": 783,
    //     "latestFrameCounterFcnt": 784,
    //     "lostFcnt": 87,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 245,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC27",
    //     "description": "Escoleta-76-1",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034095",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -87,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 274,
    //     "prevFrameCounterFcnt": 763,
    //     "latestFrameCounterFcnt": 764,
    //     "lostFcnt": 85,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 246,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC28",
    //     "description": "Escoleta-74",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034110",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -97,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 299,
    //     "prevFrameCounterFcnt": 802,
    //     "latestFrameCounterFcnt": 803,
    //     "lostFcnt": 95,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 247,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC29",
    //     "description": "Escoleta-72",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033952",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -91,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 278,
    //     "prevFrameCounterFcnt": 751,
    //     "latestFrameCounterFcnt": 752,
    //     "lostFcnt": 78,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 248,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC30",
    //     "description": "Escoleta-75",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033925",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 312,
    //     "prevFrameCounterFcnt": 836,
    //     "latestFrameCounterFcnt": 837,
    //     "lostFcnt": 102,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 249,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC31",
    //     "description": "Escoleta-73",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033986",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -85,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 288,
    //     "prevFrameCounterFcnt": 803,
    //     "latestFrameCounterFcnt": 804,
    //     "lostFcnt": 100,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 250,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC32",
    //     "description": "Escoleta-71",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033942",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 271,
    //     "prevFrameCounterFcnt": 784,
    //     "latestFrameCounterFcnt": 785,
    //     "lostFcnt": 98,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 251,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC33",
    //     "description": "Escoleta-67",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034038",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 290,
    //     "prevFrameCounterFcnt": 811,
    //     "latestFrameCounterFcnt": 812,
    //     "lostFcnt": 109,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 252,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC34",
    //     "description": "Escoleta-65",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033979",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -78,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 776,
    //     "latestFrameCounterFcnt": 777,
    //     "lostFcnt": 81,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 253,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC35",
    //     "description": "Escoleta-63",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033970",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -84,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 298,
    //     "prevFrameCounterFcnt": 796,
    //     "latestFrameCounterFcnt": 797,
    //     "lostFcnt": 89,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 254,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC36",
    //     "description": "Escoleta-70",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032093",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -97,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 300,
    //     "prevFrameCounterFcnt": 823,
    //     "latestFrameCounterFcnt": 825,
    //     "lostFcnt": 119,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 255,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC37",
    //     "description": "Esoleta-68",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032107",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 294,
    //     "prevFrameCounterFcnt": 782,
    //     "latestFrameCounterFcnt": 783,
    //     "lostFcnt": 82,
    //     "loraSNR": 12,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 256,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC38",
    //     "description": "Escoleta-66",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034001",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -95,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 277,
    //     "prevFrameCounterFcnt": 800,
    //     "latestFrameCounterFcnt": 801,
    //     "lostFcnt": 113,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 257,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC39",
    //     "description": "Escoleta-64",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034033",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 295,
    //     "prevFrameCounterFcnt": 807,
    //     "latestFrameCounterFcnt": 808,
    //     "lostFcnt": 109,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 258,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC40",
    //     "description": "Escoleta-62",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034037",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -103,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 291,
    //     "prevFrameCounterFcnt": 778,
    //     "latestFrameCounterFcnt": 779,
    //     "lostFcnt": 79,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 259,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC41",
    //     "description": "Escoleta-60",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034010",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -77,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 294,
    //     "prevFrameCounterFcnt": 805,
    //     "latestFrameCounterFcnt": 806,
    //     "lostFcnt": 113,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 260,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC42",
    //     "description": "Escoleta-58",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034074",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -74,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 268,
    //     "prevFrameCounterFcnt": 746,
    //     "latestFrameCounterFcnt": 747,
    //     "lostFcnt": 78,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 261,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC43",
    //     "description": "Escoleta-56",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034013",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -72,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 302,
    //     "prevFrameCounterFcnt": 838,
    //     "latestFrameCounterFcnt": 839,
    //     "lostFcnt": 119,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 262,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC44",
    //     "description": "Escoleta-52",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033997",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -79,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 282,
    //     "prevFrameCounterFcnt": 771,
    //     "latestFrameCounterFcnt": 772,
    //     "lostFcnt": 75,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 263,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC46",
    //     "description": "Escoleta-61",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034000",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 791,
    //     "latestFrameCounterFcnt": 792,
    //     "lostFcnt": 98,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 264,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC47",
    //     "description": "Escoleta-59",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034003",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -102,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 790,
    //     "latestFrameCounterFcnt": 791,
    //     "lostFcnt": 85,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 265,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC48",
    //     "description": "Escoleta-48",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034088",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -89,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 297,
    //     "prevFrameCounterFcnt": 813,
    //     "latestFrameCounterFcnt": 815,
    //     "lostFcnt": 102,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 266,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC49",
    //     "description": "Escoleta-57B",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033972",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -100,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 290,
    //     "prevFrameCounterFcnt": 799,
    //     "latestFrameCounterFcnt": 800,
    //     "lostFcnt": 108,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 267,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC50",
    //     "description": "Escoleta-57",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034071",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -97,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 282,
    //     "prevFrameCounterFcnt": 798,
    //     "latestFrameCounterFcnt": 799,
    //     "lostFcnt": 99,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 268,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC51",
    //     "description": "Escoleta-46",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033957",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -80,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 268,
    //     "prevFrameCounterFcnt": 753,
    //     "latestFrameCounterFcnt": 754,
    //     "lostFcnt": 76,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 269,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC52",
    //     "description": "Escoleta-44",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034115",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 277,
    //     "prevFrameCounterFcnt": 760,
    //     "latestFrameCounterFcnt": 761,
    //     "lostFcnt": 81,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 270,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC54",
    //     "description": "Escoleta-43",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033991",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 786,
    //     "latestFrameCounterFcnt": 787,
    //     "lostFcnt": 100,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 271,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC55",
    //     "description": "Escoleta-38",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034082",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -106,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 266,
    //     "prevFrameCounterFcnt": 741,
    //     "latestFrameCounterFcnt": 742,
    //     "lostFcnt": 76,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 272,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC56",
    //     "description": "Escoleta-36",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034072",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 279,
    //     "prevFrameCounterFcnt": 752,
    //     "latestFrameCounterFcnt": 753,
    //     "lostFcnt": 79,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 273,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC57",
    //     "description": "Escoleta-41",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033912",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -102,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 306,
    //     "prevFrameCounterFcnt": 797,
    //     "latestFrameCounterFcnt": 798,
    //     "lostFcnt": 95,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 274,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC58",
    //     "description": "Escoleta-34",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034070",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -105,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 775,
    //     "latestFrameCounterFcnt": 776,
    //     "lostFcnt": 92,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 275,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC59",
    //     "description": "Escoleta-39",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033976",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 302,
    //     "prevFrameCounterFcnt": 805,
    //     "latestFrameCounterFcnt": 806,
    //     "lostFcnt": 95,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 276,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC60",
    //     "description": "Escoleta-37",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033974",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 799,
    //     "latestFrameCounterFcnt": 800,
    //     "lostFcnt": 100,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 277,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC61",
    //     "description": "Escoleta-32",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032095",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 304,
    //     "prevFrameCounterFcnt": 804,
    //     "latestFrameCounterFcnt": 805,
    //     "lostFcnt": 93,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 278,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC62",
    //     "description": "Escoleta-30",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034015",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 301,
    //     "prevFrameCounterFcnt": 789,
    //     "latestFrameCounterFcnt": 790,
    //     "lostFcnt": 99,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 279,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC63",
    //     "description": "Escoleta-28",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032067",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 292,
    //     "prevFrameCounterFcnt": 823,
    //     "latestFrameCounterFcnt": 824,
    //     "lostFcnt": 136,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 280,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC64",
    //     "description": "Escoleta-35",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034024",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 800,
    //     "latestFrameCounterFcnt": 801,
    //     "lostFcnt": 105,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 281,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC65",
    //     "description": "Escoleta-24",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033964",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 277,
    //     "prevFrameCounterFcnt": 799,
    //     "latestFrameCounterFcnt": 800,
    //     "lostFcnt": 113,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 282,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC66",
    //     "description": "Escoleta-22",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034043",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 286,
    //     "prevFrameCounterFcnt": 859,
    //     "latestFrameCounterFcnt": 861,
    //     "lostFcnt": 160,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 283,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC67",
    //     "description": "Escoleta-29",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034102",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 280,
    //     "prevFrameCounterFcnt": 795,
    //     "latestFrameCounterFcnt": 796,
    //     "lostFcnt": 105,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 284,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC68",
    //     "description": "Escoleta-20",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033963",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -110,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 295,
    //     "prevFrameCounterFcnt": 834,
    //     "latestFrameCounterFcnt": 835,
    //     "lostFcnt": 138,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 285,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC70",
    //     "description": "Escoleta-23",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034034",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -111,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 282,
    //     "prevFrameCounterFcnt": 819,
    //     "latestFrameCounterFcnt": 820,
    //     "lostFcnt": 135,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 286,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC69",
    //     "description": "Escoleta-27",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034096",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 279,
    //     "prevFrameCounterFcnt": 810,
    //     "latestFrameCounterFcnt": 811,
    //     "lostFcnt": 138,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 287,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC71",
    //     "description": "Escoleta-21",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034049",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 280,
    //     "prevFrameCounterFcnt": 797,
    //     "latestFrameCounterFcnt": 798,
    //     "lostFcnt": 105,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 288,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC72",
    //     "description": "Escoleta-19",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033959",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 282,
    //     "prevFrameCounterFcnt": 788,
    //     "latestFrameCounterFcnt": 789,
    //     "lostFcnt": 96,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 289,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC73",
    //     "description": "Escoleta-16",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034094",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 288,
    //     "prevFrameCounterFcnt": 771,
    //     "latestFrameCounterFcnt": 772,
    //     "lostFcnt": 77,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 290,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC74",
    //     "description": "Escoleta-14",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034114",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 303,
    //     "prevFrameCounterFcnt": 811,
    //     "latestFrameCounterFcnt": 812,
    //     "lostFcnt": 113,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 291,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC75",
    //     "description": "Escoleta-15",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033967",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 270,
    //     "prevFrameCounterFcnt": 811,
    //     "latestFrameCounterFcnt": 812,
    //     "lostFcnt": 145,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 292,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC76",
    //     "description": "Escoleta-13",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034014",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 820,
    //     "latestFrameCounterFcnt": 821,
    //     "lostFcnt": 153,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 293,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC77",
    //     "description": "Escoleta-12",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033929",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 330,
    //     "prevFrameCounterFcnt": 878,
    //     "latestFrameCounterFcnt": 879,
    //     "lostFcnt": 140,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 294,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC78",
    //     "description": "Escoleta-10",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034112",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 270,
    //     "prevFrameCounterFcnt": 786,
    //     "latestFrameCounterFcnt": 787,
    //     "lostFcnt": 117,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 295,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC79",
    //     "description": "Escoleta-8",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034064",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 301,
    //     "prevFrameCounterFcnt": 806,
    //     "latestFrameCounterFcnt": 807,
    //     "lostFcnt": 104,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 296,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC80",
    //     "description": "Escoleta-11",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034027",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 299,
    //     "prevFrameCounterFcnt": 806,
    //     "latestFrameCounterFcnt": 807,
    //     "lostFcnt": 89,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 297,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC81",
    //     "description": "Escoleta-6",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033998",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 293,
    //     "prevFrameCounterFcnt": 825,
    //     "latestFrameCounterFcnt": 827,
    //     "lostFcnt": 117,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 298,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC82",
    //     "description": "Escoleta-4",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034019",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 788,
    //     "latestFrameCounterFcnt": 789,
    //     "lostFcnt": 94,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 299,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC83",
    //     "description": "Escoleta-3",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034059",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 281,
    //     "prevFrameCounterFcnt": 794,
    //     "latestFrameCounterFcnt": 795,
    //     "lostFcnt": 126,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 300,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC84",
    //     "description": "Escoleta-5",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034104",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 808,
    //     "latestFrameCounterFcnt": 809,
    //     "lostFcnt": 117,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 301,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC85",
    //     "description": "Escoleta-7",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033914",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -108,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 307,
    //     "prevFrameCounterFcnt": 823,
    //     "latestFrameCounterFcnt": 824,
    //     "lostFcnt": 104,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 302,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC86",
    //     "description": "Escoleta9",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450045529",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 303,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC87",
    //     "description": "Escoleta-69",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034091",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 777,
    //     "latestFrameCounterFcnt": 778,
    //     "lostFcnt": 81,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 304,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC88",
    //     "description": "Escoleta-74bis",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034021",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -82,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 273,
    //     "prevFrameCounterFcnt": 793,
    //     "latestFrameCounterFcnt": 794,
    //     "lostFcnt": 97,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 305,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC89",
    //     "description": "Escoleta-42",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033949",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 271,
    //     "prevFrameCounterFcnt": 763,
    //     "latestFrameCounterFcnt": 764,
    //     "lostFcnt": 85,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 306,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC90",
    //     "description": "Escoleta-53",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034009",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -103,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 313,
    //     "prevFrameCounterFcnt": 841,
    //     "latestFrameCounterFcnt": 842,
    //     "lostFcnt": 122,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 307,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC91",
    //     "description": "Escoleta-45",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034011",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 288,
    //     "prevFrameCounterFcnt": 791,
    //     "latestFrameCounterFcnt": 792,
    //     "lostFcnt": 94,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 308,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC92",
    //     "description": "Escoleta-25",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033948",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 290,
    //     "prevFrameCounterFcnt": 840,
    //     "latestFrameCounterFcnt": 841,
    //     "lostFcnt": 172,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 309,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC93",
    //     "description": "Rei-en-Jaume-10",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033924",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 331,
    //     "prevFrameCounterFcnt": 1029,
    //     "latestFrameCounterFcnt": 1030,
    //     "lostFcnt": 385,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 107,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e566_sensor",
    //     "description": "ibox sensor",
    //     "provider": null,
    //     "userId": 32,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-06-15",
    //     "applicationName": null,
    //     "latitude": 39.120249,
    //     "longitude": -0.454043,
    //     "deviceEUI": "0079e129d522e566",
    //     "appEUI": "12",
    //     "appKEY": "12",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 197579,
    //     "prevFrameCounterFcnt": 1409,
    //     "latestFrameCounterFcnt": 1409,
    //     "lostFcnt": -778212,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb3f1042",
    //     "messageTime": null
    // },
    // {
    //     "id": 108,
    //     "gatewaysId": null,
    //     "name": "testIbox",
    //     "description": "For testing All",
    //     "provider": null,
    //     "userId": 32,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-06-18",
    //     "applicationName": null,
    //     "latitude": 39.427242,
    //     "longitude": -1.114482,
    //     "deviceEUI": "0079e129d522e634",
    //     "appEUI": "12",
    //     "appKEY": "12",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "inputcmp1"
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": "metercmp1"
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 145,
    //     "prevFrameCounterFcnt": 186,
    //     "latestFrameCounterFcnt": 187,
    //     "lostFcnt": -162678,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb3330b1",
    //     "messageTime": null
    // },
    // {
    //     "id": 109,
    //     "gatewaysId": null,
    //     "name": "Sensor_ibox_Alberto",
    //     "description": "IBOX TEST",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "16",
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-06-24",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e566",
    //     "appEUI": "06263577c3bbc9eb",
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "DoorC01"
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": "EnergyMeterVIPAC01"
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 197579,
    //     "prevFrameCounterFcnt": 1409,
    //     "latestFrameCounterFcnt": 1409,
    //     "lostFcnt": -778212,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb3f1042",
    //     "messageTime": null
    // },
    // {
    //     "id": 110,
    //     "gatewaysId": null,
    //     "name": "IBOX1_Biblio",
    //     "description": "iBOX_GESWAT_e647",
    //     "provider": null,
    //     "userId": 36,
    //     "typeSensor": "20",
    //     "sensorTypeInfo": {
    //         "id": 20,
    //         "name": "IBOX-PUERTA-TEST",
    //         "description": "Puerta TEST",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-06-26",
    //     "applicationName": null,
    //     "latitude": 39.567724,
    //     "longitude": -0.622264,
    //     "deviceEUI": "0079e129d522e647",
    //     "appEUI": "06263577c3bbc9eb",
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb66bea3,b827eb6c4e09,dca63214b1d0,b827eb11f2e1",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 115,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e631_doorLibrary",
    //     "description": "iBOX_GESWAT_e631",
    //     "provider": null,
    //     "userId": 77,
    //     "typeSensor": "89",
    //     "sensorTypeInfo": {
    //         "id": 89,
    //         "name": "Olmos_ibox",
    //         "description": null,
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-07-15",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e631",
    //     "appEUI": null,
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": "b827eb524297",
    //     "dr": 3,
    //     "rssi": -125,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 682,
    //     "latestFrameCounterFcnt": 1494,
    //     "lostFcnt": -322249,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb524297",
    //     "messageTime": null
    // },
    // {
    //     "id": 117,
    //     "gatewaysId": null,
    //     "name": "iBOX_Biblioteca",
    //     "description": "Quatretonda iBox Biblioteca",
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": "21",
    //     "sensorTypeInfo": {
    //         "id": 21,
    //         "name": "iBOX_Puerta",
    //         "description": "Ibox Biblioteca",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-07-19",
    //     "applicationName": null,
    //     "latitude": 38.945463,
    //     "longitude": -0.399479,
    //     "deviceEUI": "0079e129d522e629",
    //     "appEUI": "06263577c3bbc9eb",
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "EnergyMeterQUAGWTC01"
    //         },
    //         {
    //             "inputComponent": "EnergyMeterQUAGWTC02"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -57,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 5066,
    //     "prevFrameCounterFcnt": 4373,
    //     "latestFrameCounterFcnt": 4374,
    //     "lostFcnt": -784639,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 118,
    //     "gatewaysId": null,
    //     "name": "iBOX_Ayuntamiento",
    //     "description": "iBOX Ayuntamiento",
    //     "provider": null,
    //     "userId": 41,
    //     "typeSensor": "22",
    //     "sensorTypeInfo": {
    //         "id": 22,
    //         "name": "iBOX_Ayuntamiento",
    //         "description": "iBOX Ayuntamiento",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-07-19",
    //     "applicationName": null,
    //     "latitude": 38.853991,
    //     "longitude": -0.502074,
    //     "deviceEUI": "0079e129d522e630",
    //     "appEUI": "06263577c3bbc9eb",
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "EnergyMeterEARCTMC01"
    //         },
    //         {
    //             "inputComponent": "EnergyMeterEARCTMC02"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -98,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 326,
    //     "prevFrameCounterFcnt": 2407,
    //     "latestFrameCounterFcnt": 2408,
    //     "lostFcnt": -753304,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827ebf0f17b",
    //     "messageTime": null
    // },
    // {
    //     "id": 121,
    //     "gatewaysId": null,
    //     "name": "171fe129d522e000_wifi",
    //     "description": "Wifi Sensor",
    //     "provider": null,
    //     "userId": 36,
    //     "typeSensor": "27",
    //     "sensorTypeInfo": {
    //         "id": 27,
    //         "name": "171fe129d522e000_wifi_Type",
    //         "description": null,
    //         "sensorType": "wifi",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-08-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "171fe129d522e000",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb66bea3,b827eb6c4e09,dca63214b1d0,b827eb11f2e1",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 123,
    //     "gatewaysId": null,
    //     "name": "Alberto_Puerta",
    //     "description": "Test puerta",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "21",
    //     "sensorTypeInfo": {
    //         "id": 21,
    //         "name": "iBOX_Puerta",
    //         "description": "Ibox Biblioteca",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-10-06",
    //     "applicationName": null,
    //     "latitude": 39.592868,
    //     "longitude": -0.544343,
    //     "deviceEUI": "0079e129d522e630",
    //     "appEUI": "06263577C3BBC9EB2FEF99135EF0F6A5",
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "DoorC01"
    //         },
    //         {
    //             "inputComponent": "DoorC02"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -98,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 326,
    //     "prevFrameCounterFcnt": 2407,
    //     "latestFrameCounterFcnt": 2408,
    //     "lostFcnt": -753304,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827ebf0f17b",
    //     "messageTime": null
    // },
    // {
    //     "id": 124,
    //     "gatewaysId": null,
    //     "name": "DCSWAGWS0000000008",
    //     "description": "water castellon itron",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "30",
    //     "sensorTypeInfo": {
    //         "id": 30,
    //         "name": "water_itron",
    //         "description": "for water",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-11-25",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "532253811817804e",
    //     "appEUI": "00000000000000000",
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 125,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e613_N",
    //     "description": "0079e129d522e613_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e613",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 126,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e614_N",
    //     "description": "0079e129d522e614_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e614",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 127,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e615_N",
    //     "description": "0079e129d522e615_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e615",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 128,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e616_N",
    //     "description": "0079e129d522e616_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e616",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -90,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 3118,
    //     "prevFrameCounterFcnt": 2,
    //     "latestFrameCounterFcnt": 3,
    //     "lostFcnt": -739446,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca632143f21",
    //     "messageTime": null
    // },
    // {
    //     "id": 129,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e617_N",
    //     "description": "0079e129d522e617_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e617",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -107,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2313,
    //     "prevFrameCounterFcnt": 2437,
    //     "latestFrameCounterFcnt": 2438,
    //     "lostFcnt": -1993127,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4ae486",
    //     "messageTime": null
    // },
    // {
    //     "id": 131,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e621_ibox",
    //     "description": null,
    //     "provider": null,
    //     "userId": 52,
    //     "typeSensor": "32",
    //     "sensorTypeInfo": {
    //         "id": 32,
    //         "name": "0079e129d522e621_ibox_Type",
    //         "description": null,
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-08",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e621",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 136,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC01",
    //     "description": "Jaume-Calafate-1",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-07",
    //     "applicationName": null,
    //     "latitude": 39.097538,
    //     "longitude": -0.630113,
    //     "deviceEUI": "04b6480450034109",
    //     "appEUI": "app",
    //     "appKEY": "7F612E785A07E056205E9799861F619C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 326,
    //     "prevFrameCounterFcnt": 929,
    //     "latestFrameCounterFcnt": 951,
    //     "lostFcnt": 322,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 141,
    //     "gatewaysId": null,
    //     "name": "171fe129d522e001_wifi",
    //     "description": null,
    //     "provider": null,
    //     "userId": 54,
    //     "typeSensor": "41",
    //     "sensorTypeInfo": {
    //         "id": 41,
    //         "name": "171fe129d522e001_wifi_Type",
    //         "description": null,
    //         "sensorType": "wifi",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-11",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "171fe129d522e001",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -105,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2345,
    //     "prevFrameCounterFcnt": 128722,
    //     "latestFrameCounterFcnt": 128724,
    //     "lostFcnt": -691835,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb481840",
    //     "messageTime": null
    // },
    // {
    //     "id": 142,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC02",
    //     "description": "Jaume-Calafate-3",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-07",
    //     "applicationName": null,
    //     "latitude": 39.097584,
    //     "longitude": -0.630043,
    //     "deviceEUI": "04b6480450034057",
    //     "appEUI": "app",
    //     "appKEY": "CEF522F5F799AE91D158D7DCD72F8B7D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 296,
    //     "prevFrameCounterFcnt": 899,
    //     "latestFrameCounterFcnt": 900,
    //     "lostFcnt": 243,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 143,
    //     "gatewaysId": null,
    //     "name": "Sensor de Oficina Pruebas",
    //     "description": "para probar en el laboratorio",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "42",
    //     "sensorTypeInfo": {
    //         "id": 42,
    //         "name": "ibox prueba OFININA",
    //         "description": "para probar en el laboratorio",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-14",
    //     "applicationName": null,
    //     "latitude": 39.594463203832,
    //     "longitude": -0.55226997501051,
    //     "deviceEUI": "a079e129d522e624",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "door sensor"
    //         },
    //         {
    //             "inputComponent": "current sensor"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 146,
    //     "gatewaysId": null,
    //     "name": "METEOVINSWTC01",
    //     "description": null,
    //     "provider": null,
    //     "userId": 54,
    //     "typeSensor": "47",
    //     "sensorTypeInfo": {
    //         "id": 47,
    //         "name": "METEOGESWAT1",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "Modbus"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-03-11",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "141f3c71bff07fae",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -88,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1511,
    //     "prevFrameCounterFcnt": 1392,
    //     "latestFrameCounterFcnt": 1393,
    //     "lostFcnt": -904159,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb481840",
    //     "messageTime": null
    // },
    // {
    //     "id": 147,
    //     "gatewaysId": null,
    //     "name": "BOYAVINSWTC01",
    //     "description": null,
    //     "provider": null,
    //     "userId": 54,
    //     "typeSensor": "48",
    //     "sensorTypeInfo": {
    //         "id": 48,
    //         "name": "BOYAGESWAT",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-03-12",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0004a30b00f1a9ee",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 78,
    //     "latestFrameCounterFcnt": 79,
    //     "lostFcnt": -5,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb481840",
    //     "messageTime": null
    // },
    // {
    //     "id": 153,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC45",
    //     "description": "Escoleta-54",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-03-31",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034036",
    //     "appEUI": null,
    //     "appKEY": "50AEDDE4585EA2F15399040CF9C5B791",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -71,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 305,
    //     "prevFrameCounterFcnt": 816,
    //     "latestFrameCounterFcnt": 817,
    //     "lostFcnt": 110,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 154,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC04",
    //     "description": "Jaime-calafate-5",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-04-19",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033985",
    //     "appEUI": null,
    //     "appKEY": "5351D39D5D4182D65182C06735052FED",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 316,
    //     "prevFrameCounterFcnt": 536,
    //     "latestFrameCounterFcnt": 548,
    //     "lostFcnt": 71,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 155,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC05",
    //     "description": "Jaime-calafate-7",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-04-19",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034063",
    //     "appEUI": "2",
    //     "appKEY": "74CE37EAA32F093DE218F3577385C655",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 920,
    //     "latestFrameCounterFcnt": 934,
    //     "lostFcnt": 322,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 156,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC06",
    //     "description": "Jaime-calafate-9",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-04-19",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033996",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 342,
    //     "prevFrameCounterFcnt": 893,
    //     "latestFrameCounterFcnt": 895,
    //     "lostFcnt": 156,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 174,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-11",
    //     "description": "ibox instalado en el taller con dev eui a0......611",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-19",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e611",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 175,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-13",
    //     "description": "ibox instalado en el taller con dev eui a0......613",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-19",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e613",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 176,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-15",
    //     "description": "ibox instalado en el taller con dev eui a0......615",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-19",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e615",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 178,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-17",
    //     "description": "a079e129d522e617",
    //     "provider": null,
    //     "userId": 124,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-18",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e617",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -33,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 170,
    //     "latestFrameCounterFcnt": 171,
    //     "lostFcnt": -99540,
    //     "loraSNR": 7,
    //     "networkServerMac": "e45f016c7dbc",
    //     "messageTime": null
    // },
    // {
    //     "id": 179,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-19",
    //     "description": "ibox instalado en el taller con dev eui a0......619",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-18",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e619",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 180,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-21",
    //     "description": "ibox instalado en el taller con dev eui a0......621",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-18",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e621",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 181,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_plazadelacreu",
    //     "description": "ibox intalado en foios en la plaza de la creu en un cuadro en el parque",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5364359,
    //     "longitude": -0.3560338,
    //     "deviceEUI": "0079e129d522e609",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1925,
    //     "prevFrameCounterFcnt": 26,
    //     "latestFrameCounterFcnt": 27,
    //     "lostFcnt": -48751,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 182,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_alcaldefranciscoroceto",
    //     "description": "ibox instalado en mitad de una carretera unidireccional en la calle alcalde francisco roceto",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5359263,
    //     "longitude": -0.356997,
    //     "deviceEUI": "0079e129d522e607",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 558,
    //     "prevFrameCounterFcnt": 39,
    //     "latestFrameCounterFcnt": 41,
    //     "lostFcnt": -73661,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 183,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_ayuntamiento",
    //     "description": "0079e129d522e644",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5376272,
    //     "longitude": -0.3601489,
    //     "deviceEUI": "0079e129d522e644",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7134,
    //     "prevFrameCounterFcnt": 9,
    //     "latestFrameCounterFcnt": 10,
    //     "lostFcnt": -296819,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 184,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_sarieta",
    //     "description": "ibox  instalado en foios en la calle sarieta en el extremo del pueblo",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.539585,
    //     "longitude": -0.3599087,
    //     "deviceEUI": "0079e129d522e646",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 931,
    //     "prevFrameCounterFcnt": 3368,
    //     "latestFrameCounterFcnt": 3369,
    //     "lostFcnt": -66724,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 185,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_agenciaocupacio",
    //     "description": "ibox instalado en foios en el circulo concentrico exterior al lado de la casa de la ocupacio",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5404003,
    //     "longitude": -0.359261,
    //     "deviceEUI": "0079e129d522e641",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -124,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 2875,
    //     "prevFrameCounterFcnt": 4607,
    //     "latestFrameCounterFcnt": 4608,
    //     "lostFcnt": -106188,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 186,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_regnevalencia",
    //     "description": "ibox instalado en foios en el extremo oeste en la calle regne valencia",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5384722,
    //     "longitude": -0.3622443,
    //     "deviceEUI": "0079e129d522e640",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 302,
    //     "latestFrameCounterFcnt": 344,
    //     "lostFcnt": -702,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 187,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_caminolacruz",
    //     "description": "ibx instalado en foios en el extremo suroeste en la calle camino de la cruz",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5338611,
    //     "longitude": -0.3573831,
    //     "deviceEUI": "0079e129d522e634",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 145,
    //     "prevFrameCounterFcnt": 186,
    //     "latestFrameCounterFcnt": 187,
    //     "lostFcnt": -162678,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb3330b1",
    //     "messageTime": null
    // },
    // {
    //     "id": 189,
    //     "gatewaysId": null,
    //     "name": "Cuadro a.p AE",
    //     "description": "extremo este cercano a burguer king",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-23",
    //     "applicationName": null,
    //     "latitude": 39.424622,
    //     "longitude": -0.40511,
    //     "deviceEUI": "0079e129d522e615",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 190,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_bar",
    //     "description": "ibox al este de paiporta cerca de un bar",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-23",
    //     "applicationName": null,
    //     "latitude": 39.4252645,
    //     "longitude": -0.4089387,
    //     "deviceEUI": "0079e129d522e616",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -90,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 3118,
    //     "prevFrameCounterFcnt": 2,
    //     "latestFrameCounterFcnt": 3,
    //     "lostFcnt": -739446,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca632143f21",
    //     "messageTime": null
    // },
    // {
    //     "id": 191,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_parque",
    //     "description": "ibox al este de paiporta cerca de un parque",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4255038,
    //     "longitude": -0.4108358,
    //     "deviceEUI": "0079e129d522e617",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -107,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2313,
    //     "prevFrameCounterFcnt": 2437,
    //     "latestFrameCounterFcnt": 2438,
    //     "lostFcnt": -1993127,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4ae486",
    //     "messageTime": null
    // },
    // {
    //     "id": 192,
    //     "gatewaysId": null,
    //     "name": "Ibox_Paiporta_Cedisa",
    //     "description": "ibox paiporta en poligono cerca de taller de motos",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4274159,
    //     "longitude": -0.4065676,
    //     "deviceEUI": "0079e129d522e618",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 193,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_faitanar",
    //     "description": "ibox paiporta poligono calle faitanar a mitad",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4286756,
    //     "longitude": -0.4060418,
    //     "deviceEUI": "0079e129d522e620",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -63,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 18,
    //     "latestFrameCounterFcnt": 0,
    //     "lostFcnt": -776,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca632143f21",
    //     "messageTime": null
    // },
    // {
    //     "id": 194,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_lasenia",
    //     "description": "ibox paiporta al sureste en la salida del colegio la senia",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4212418,
    //     "longitude": -0.4151241,
    //     "deviceEUI": "0079e129d522e612",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 195,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_independencia",
    //     "description": "ibox paiporta al noroeste en la calle independencia",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4305412,
    //     "longitude": -0.4217051,
    //     "deviceEUI": "0079e129d522e622",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 197,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_maximiliano",
    //     "description": "ibox paiporta al norte en la calle maximiliano",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4333949,
    //     "longitude": -0.414235,
    //     "deviceEUI": "0079e129d522e625",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 198,
    //     "gatewaysId": null,
    //     "name": "Ibox_Paiporta_Primitiva",
    //     "description": "ibox en el centro norte calle banda primitiva",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4284961,
    //     "longitude": -0.4135304,
    //     "deviceEUI": "0079e129d522e619",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 199,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_acequia",
    //     "description": "ibox paiporta al este en la calle acequia",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4294963,
    //     "longitude": -0.4032379,
    //     "deviceEUI": "0079e129d522e621",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 200,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_acequiadequart",
    //     "description": "ibox paiporta al noreste calle acequia de quart",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4309699,
    //     "longitude": -0.4043883,
    //     "deviceEUI": "0079e129d522e624",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 201,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_acequiafaitanar",
    //     "description": "prueba_taller",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4336567,
    //     "longitude": -0.4040864,
    //     "deviceEUI": "0079e129d522e626",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 3,
    //     "rssi": -65,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 4592,
    //     "prevFrameCounterFcnt": 692,
    //     "latestFrameCounterFcnt": 696,
    //     "lostFcnt": -83622,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb4ae486",
    //     "messageTime": null
    // },
    // {
    //     "id": 202,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_ausiasmarch",
    //     "description": "ibos paiporta centro noroeste en calle ausias march",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4306035,
    //     "longitude": -0.416513,
    //     "deviceEUI": "0079e129d522e623",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 203,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_quart",
    //     "description": "ibox paiporta al suroeste en calle quart de poblet",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4226541,
    //     "longitude": -0.4174941,
    //     "deviceEUI": "0079e129d522e613",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 204,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_polideportivo",
    //     "description": "ibox paiporta  al sur instalado dentro del polideportivo",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4247485,
    //     "longitude": -0.4143086,
    //     "deviceEUI": "0079e129d522e614",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 209,
    //     "gatewaysId": null,
    //     "name": "a079e129d522e616",
    //     "description": "a079e129d522e611",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "31",
    //     "sensorTypeInfo": {
    //         "id": 31,
    //         "name": "iBOX_Paiporta",
    //         "description": "IBOX Ayto. Paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "a079e129d522e611",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 210,
    //     "gatewaysId": null,
    //     "name": "a079e129d522e616",
    //     "description": "ibox instalado en paiporta al este del centro al lado de un burguerking",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "20",
    //     "sensorTypeInfo": {
    //         "id": 20,
    //         "name": "IBOX-PUERTA-TEST",
    //         "description": "Puerta TEST",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-01",
    //     "applicationName": null,
    //     "latitude": 39.422413,
    //     "longitude": -0.405982,
    //     "deviceEUI": "a079e129d522e616",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "a"
    //         },
    //         {
    //             "inputComponent": "b"
    //         },
    //         {
    //             "inputComponent": "c"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 220,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC01",
    //     "description": "constitucion-20",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bd5",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 290,
    //     "prevFrameCounterFcnt": 1339,
    //     "latestFrameCounterFcnt": 1340,
    //     "lostFcnt": 320,
    //     "loraSNR": -16,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 222,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC03",
    //     "description": "constitucion-14",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bd4",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 608,
    //     "prevFrameCounterFcnt": 1668,
    //     "latestFrameCounterFcnt": 1669,
    //     "lostFcnt": 211,
    //     "loraSNR": -12,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 223,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC05",
    //     "description": "constitucion-30",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bd3",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 59,
    //     "prevFrameCounterFcnt": 803,
    //     "latestFrameCounterFcnt": 804,
    //     "lostFcnt": 124,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 224,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC06",
    //     "description": "constitucion-36",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a83f",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 50,
    //     "prevFrameCounterFcnt": 28,
    //     "latestFrameCounterFcnt": 34,
    //     "lostFcnt": -26,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 225,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC09",
    //     "description": "Gandia-29",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a841",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": -112,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 276,
    //     "prevFrameCounterFcnt": 563,
    //     "latestFrameCounterFcnt": 564,
    //     "lostFcnt": -187,
    //     "loraSNR": -13,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 226,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC10",
    //     "description": "Gandia-28",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872776b9",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 9,
    //     "prevFrameCounterFcnt": 55,
    //     "latestFrameCounterFcnt": 1,
    //     "lostFcnt": -67,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 227,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC13",
    //     "description": "convento-51",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872776b8",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 674,
    //     "latestFrameCounterFcnt": 675,
    //     "lostFcnt": 278,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 228,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC15",
    //     "description": "Convento-34",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a397",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 312,
    //     "prevFrameCounterFcnt": 813,
    //     "latestFrameCounterFcnt": 814,
    //     "lostFcnt": -240,
    //     "loraSNR": -18,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 229,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC03",
    //     "description": "Jaime-calafate-2acc",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450031548",
    //     "appEUI": "2",
    //     "appKEY": "C5B55C8B19A92D638F6B67F376110B06",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 311,
    //     "prevFrameCounterFcnt": 1064,
    //     "latestFrameCounterFcnt": 1068,
    //     "lostFcnt": 516,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 230,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC07",
    //     "description": "Jaime-calafate-2",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033989",
    //     "appEUI": "2",
    //     "appKEY": "23435DA6A2AEAC81381E5737A2FF1E6A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 299,
    //     "prevFrameCounterFcnt": 851,
    //     "latestFrameCounterFcnt": 852,
    //     "lostFcnt": 163,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 231,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC12",
    //     "description": "Rei-en-Jaume-14",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034077",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 284,
    //     "prevFrameCounterFcnt": 807,
    //     "latestFrameCounterFcnt": 808,
    //     "lostFcnt": 108,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 232,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC13",
    //     "description": "Rei-en-Jaume-16",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033968",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 286,
    //     "prevFrameCounterFcnt": 834,
    //     "latestFrameCounterFcnt": 835,
    //     "lostFcnt": 189,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 233,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC14",
    //     "description": "Rei-en-Jaume-18",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034101",
    //     "appEUI": "2",
    //     "appKEY": "9BFC7FFB32842F714F940742B515F57D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 303,
    //     "prevFrameCounterFcnt": 835,
    //     "latestFrameCounterFcnt": 836,
    //     "lostFcnt": 138,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 234,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC15",
    //     "description": "Rei-en-Jaume-22",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033917",
    //     "appEUI": "2",
    //     "appKEY": "BEA29B6ACDB3E8F7C2251D8CAEFABB95",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 279,
    //     "prevFrameCounterFcnt": 794,
    //     "latestFrameCounterFcnt": 795,
    //     "lostFcnt": 119,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 235,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC16",
    //     "description": "Rei-en-Jaume-24",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033982",
    //     "appEUI": "app",
    //     "appKEY": "3A29D9FB7ED836C8048ACA3AE368357F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 299,
    //     "prevFrameCounterFcnt": 815,
    //     "latestFrameCounterFcnt": 816,
    //     "lostFcnt": 110,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 236,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC17",
    //     "description": "Rei-en-Jaume-26",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033916",
    //     "appEUI": "app",
    //     "appKEY": "4503E4F51C69D7D6EF3B0C2871CA8241",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 297,
    //     "prevFrameCounterFcnt": 862,
    //     "latestFrameCounterFcnt": 863,
    //     "lostFcnt": 185,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 237,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC18",
    //     "description": "Rei-en-Jaume-30",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033993",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 795,
    //     "latestFrameCounterFcnt": 796,
    //     "lostFcnt": 93,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 238,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC19",
    //     "description": "Rei-en-JAume-44",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034016",
    //     "appEUI": "app",
    //     "appKEY": "0767D7630F287DB586B99FF4B086EF95",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -99,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 790,
    //     "latestFrameCounterFcnt": 791,
    //     "lostFcnt": 94,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 239,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC21",
    //     "description": "Escoleta-80",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034108",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 310,
    //     "prevFrameCounterFcnt": 809,
    //     "latestFrameCounterFcnt": 810,
    //     "lostFcnt": 84,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 240,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC22",
    //     "description": "Escoleta-78",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034031",
    //     "appEUI": null,
    //     "appKEY": "22F8B490D8D0318B12C1961F1F48A772",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -78,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 286,
    //     "prevFrameCounterFcnt": 785,
    //     "latestFrameCounterFcnt": 786,
    //     "lostFcnt": 87,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 241,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC23",
    //     "description": "Escoleta-79",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034040",
    //     "appEUI": "app",
    //     "appKEY": "3E42B8A57822B1C82C5A63BD396ECECD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -95,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 294,
    //     "prevFrameCounterFcnt": 810,
    //     "latestFrameCounterFcnt": 811,
    //     "lostFcnt": 88,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 242,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC24",
    //     "description": "Escoleta-81-A",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033955",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -83,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 270,
    //     "prevFrameCounterFcnt": 757,
    //     "latestFrameCounterFcnt": 758,
    //     "lostFcnt": 81,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 243,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC25",
    //     "description": "Escoleta-77",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033936",
    //     "appEUI": "app",
    //     "appKEY": "8A2BDBE3960C9C476EAA9D57617E6965",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 318,
    //     "prevFrameCounterFcnt": 836,
    //     "latestFrameCounterFcnt": 837,
    //     "lostFcnt": 116,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 244,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC26",
    //     "description": "Escoleta-76-bajo",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028716",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -79,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 294,
    //     "prevFrameCounterFcnt": 783,
    //     "latestFrameCounterFcnt": 784,
    //     "lostFcnt": 87,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 245,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC27",
    //     "description": "Escoleta-76-1",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034095",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -87,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 274,
    //     "prevFrameCounterFcnt": 763,
    //     "latestFrameCounterFcnt": 764,
    //     "lostFcnt": 85,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 246,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC28",
    //     "description": "Escoleta-74",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034110",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -97,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 299,
    //     "prevFrameCounterFcnt": 802,
    //     "latestFrameCounterFcnt": 803,
    //     "lostFcnt": 95,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 247,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC29",
    //     "description": "Escoleta-72",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033952",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -91,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 278,
    //     "prevFrameCounterFcnt": 751,
    //     "latestFrameCounterFcnt": 752,
    //     "lostFcnt": 78,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 248,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC30",
    //     "description": "Escoleta-75",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033925",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 312,
    //     "prevFrameCounterFcnt": 836,
    //     "latestFrameCounterFcnt": 837,
    //     "lostFcnt": 102,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 249,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC31",
    //     "description": "Escoleta-73",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033986",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -85,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 288,
    //     "prevFrameCounterFcnt": 803,
    //     "latestFrameCounterFcnt": 804,
    //     "lostFcnt": 100,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 250,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC32",
    //     "description": "Escoleta-71",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033942",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 271,
    //     "prevFrameCounterFcnt": 784,
    //     "latestFrameCounterFcnt": 785,
    //     "lostFcnt": 98,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 251,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC33",
    //     "description": "Escoleta-67",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034038",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 290,
    //     "prevFrameCounterFcnt": 811,
    //     "latestFrameCounterFcnt": 812,
    //     "lostFcnt": 109,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 252,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC34",
    //     "description": "Escoleta-65",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033979",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -78,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 776,
    //     "latestFrameCounterFcnt": 777,
    //     "lostFcnt": 81,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 253,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC35",
    //     "description": "Escoleta-63",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033970",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -84,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 298,
    //     "prevFrameCounterFcnt": 796,
    //     "latestFrameCounterFcnt": 797,
    //     "lostFcnt": 89,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 254,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC36",
    //     "description": "Escoleta-70",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032093",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -97,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 300,
    //     "prevFrameCounterFcnt": 823,
    //     "latestFrameCounterFcnt": 825,
    //     "lostFcnt": 119,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 255,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC37",
    //     "description": "Esoleta-68",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032107",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 294,
    //     "prevFrameCounterFcnt": 782,
    //     "latestFrameCounterFcnt": 783,
    //     "lostFcnt": 82,
    //     "loraSNR": 12,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 256,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC38",
    //     "description": "Escoleta-66",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034001",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -95,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 277,
    //     "prevFrameCounterFcnt": 800,
    //     "latestFrameCounterFcnt": 801,
    //     "lostFcnt": 113,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 257,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC39",
    //     "description": "Escoleta-64",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034033",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 295,
    //     "prevFrameCounterFcnt": 807,
    //     "latestFrameCounterFcnt": 808,
    //     "lostFcnt": 109,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 258,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC40",
    //     "description": "Escoleta-62",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034037",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -103,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 291,
    //     "prevFrameCounterFcnt": 778,
    //     "latestFrameCounterFcnt": 779,
    //     "lostFcnt": 79,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 259,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC41",
    //     "description": "Escoleta-60",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034010",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -77,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 294,
    //     "prevFrameCounterFcnt": 805,
    //     "latestFrameCounterFcnt": 806,
    //     "lostFcnt": 113,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 260,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC42",
    //     "description": "Escoleta-58",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034074",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -74,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 268,
    //     "prevFrameCounterFcnt": 746,
    //     "latestFrameCounterFcnt": 747,
    //     "lostFcnt": 78,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 261,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC43",
    //     "description": "Escoleta-56",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034013",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -72,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 302,
    //     "prevFrameCounterFcnt": 838,
    //     "latestFrameCounterFcnt": 839,
    //     "lostFcnt": 119,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 262,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC44",
    //     "description": "Escoleta-52",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033997",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -79,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 282,
    //     "prevFrameCounterFcnt": 771,
    //     "latestFrameCounterFcnt": 772,
    //     "lostFcnt": 75,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 263,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC46",
    //     "description": "Escoleta-61",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034000",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 791,
    //     "latestFrameCounterFcnt": 792,
    //     "lostFcnt": 98,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 264,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC47",
    //     "description": "Escoleta-59",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034003",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -102,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 790,
    //     "latestFrameCounterFcnt": 791,
    //     "lostFcnt": 85,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 265,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC48",
    //     "description": "Escoleta-48",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034088",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -89,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 297,
    //     "prevFrameCounterFcnt": 813,
    //     "latestFrameCounterFcnt": 815,
    //     "lostFcnt": 102,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 266,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC49",
    //     "description": "Escoleta-57B",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033972",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -100,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 290,
    //     "prevFrameCounterFcnt": 799,
    //     "latestFrameCounterFcnt": 800,
    //     "lostFcnt": 108,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 267,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC50",
    //     "description": "Escoleta-57",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034071",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -97,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 282,
    //     "prevFrameCounterFcnt": 798,
    //     "latestFrameCounterFcnt": 799,
    //     "lostFcnt": 99,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 268,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC51",
    //     "description": "Escoleta-46",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033957",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -80,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 268,
    //     "prevFrameCounterFcnt": 753,
    //     "latestFrameCounterFcnt": 754,
    //     "lostFcnt": 76,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 269,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC52",
    //     "description": "Escoleta-44",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034115",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 277,
    //     "prevFrameCounterFcnt": 760,
    //     "latestFrameCounterFcnt": 761,
    //     "lostFcnt": 81,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 270,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC54",
    //     "description": "Escoleta-43",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033991",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 786,
    //     "latestFrameCounterFcnt": 787,
    //     "lostFcnt": 100,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 271,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC55",
    //     "description": "Escoleta-38",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034082",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -106,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 266,
    //     "prevFrameCounterFcnt": 741,
    //     "latestFrameCounterFcnt": 742,
    //     "lostFcnt": 76,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 272,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC56",
    //     "description": "Escoleta-36",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034072",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 279,
    //     "prevFrameCounterFcnt": 752,
    //     "latestFrameCounterFcnt": 753,
    //     "lostFcnt": 79,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 273,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC57",
    //     "description": "Escoleta-41",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033912",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -102,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 306,
    //     "prevFrameCounterFcnt": 797,
    //     "latestFrameCounterFcnt": 798,
    //     "lostFcnt": 95,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 274,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC58",
    //     "description": "Escoleta-34",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034070",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -105,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 775,
    //     "latestFrameCounterFcnt": 776,
    //     "lostFcnt": 92,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 275,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC59",
    //     "description": "Escoleta-39",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033976",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 302,
    //     "prevFrameCounterFcnt": 805,
    //     "latestFrameCounterFcnt": 806,
    //     "lostFcnt": 95,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 276,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC60",
    //     "description": "Escoleta-37",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033974",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 799,
    //     "latestFrameCounterFcnt": 800,
    //     "lostFcnt": 100,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 277,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC61",
    //     "description": "Escoleta-32",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032095",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 304,
    //     "prevFrameCounterFcnt": 804,
    //     "latestFrameCounterFcnt": 805,
    //     "lostFcnt": 93,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 278,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC62",
    //     "description": "Escoleta-30",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034015",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 301,
    //     "prevFrameCounterFcnt": 789,
    //     "latestFrameCounterFcnt": 790,
    //     "lostFcnt": 99,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 279,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC63",
    //     "description": "Escoleta-28",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032067",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 292,
    //     "prevFrameCounterFcnt": 823,
    //     "latestFrameCounterFcnt": 824,
    //     "lostFcnt": 136,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 280,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC64",
    //     "description": "Escoleta-35",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034024",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 800,
    //     "latestFrameCounterFcnt": 801,
    //     "lostFcnt": 105,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 281,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC65",
    //     "description": "Escoleta-24",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033964",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 277,
    //     "prevFrameCounterFcnt": 799,
    //     "latestFrameCounterFcnt": 800,
    //     "lostFcnt": 113,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 282,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC66",
    //     "description": "Escoleta-22",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034043",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 286,
    //     "prevFrameCounterFcnt": 859,
    //     "latestFrameCounterFcnt": 861,
    //     "lostFcnt": 160,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 283,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC67",
    //     "description": "Escoleta-29",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034102",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 280,
    //     "prevFrameCounterFcnt": 795,
    //     "latestFrameCounterFcnt": 796,
    //     "lostFcnt": 105,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 284,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC68",
    //     "description": "Escoleta-20",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033963",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -110,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 295,
    //     "prevFrameCounterFcnt": 834,
    //     "latestFrameCounterFcnt": 835,
    //     "lostFcnt": 138,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 285,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC70",
    //     "description": "Escoleta-23",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034034",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -111,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 282,
    //     "prevFrameCounterFcnt": 819,
    //     "latestFrameCounterFcnt": 820,
    //     "lostFcnt": 135,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 286,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC69",
    //     "description": "Escoleta-27",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034096",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 279,
    //     "prevFrameCounterFcnt": 810,
    //     "latestFrameCounterFcnt": 811,
    //     "lostFcnt": 138,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 287,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC71",
    //     "description": "Escoleta-21",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034049",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 280,
    //     "prevFrameCounterFcnt": 797,
    //     "latestFrameCounterFcnt": 798,
    //     "lostFcnt": 105,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 288,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC72",
    //     "description": "Escoleta-19",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033959",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 282,
    //     "prevFrameCounterFcnt": 788,
    //     "latestFrameCounterFcnt": 789,
    //     "lostFcnt": 96,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 289,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC73",
    //     "description": "Escoleta-16",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034094",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 288,
    //     "prevFrameCounterFcnt": 771,
    //     "latestFrameCounterFcnt": 772,
    //     "lostFcnt": 77,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 290,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC74",
    //     "description": "Escoleta-14",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034114",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 303,
    //     "prevFrameCounterFcnt": 811,
    //     "latestFrameCounterFcnt": 812,
    //     "lostFcnt": 113,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 291,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC75",
    //     "description": "Escoleta-15",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033967",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 270,
    //     "prevFrameCounterFcnt": 811,
    //     "latestFrameCounterFcnt": 812,
    //     "lostFcnt": 145,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 292,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC76",
    //     "description": "Escoleta-13",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034014",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 820,
    //     "latestFrameCounterFcnt": 821,
    //     "lostFcnt": 153,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 293,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC77",
    //     "description": "Escoleta-12",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033929",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 330,
    //     "prevFrameCounterFcnt": 878,
    //     "latestFrameCounterFcnt": 879,
    //     "lostFcnt": 140,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 294,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC78",
    //     "description": "Escoleta-10",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034112",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 270,
    //     "prevFrameCounterFcnt": 786,
    //     "latestFrameCounterFcnt": 787,
    //     "lostFcnt": 117,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 295,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC79",
    //     "description": "Escoleta-8",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034064",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 301,
    //     "prevFrameCounterFcnt": 806,
    //     "latestFrameCounterFcnt": 807,
    //     "lostFcnt": 104,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 296,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC80",
    //     "description": "Escoleta-11",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034027",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 299,
    //     "prevFrameCounterFcnt": 806,
    //     "latestFrameCounterFcnt": 807,
    //     "lostFcnt": 89,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 297,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC81",
    //     "description": "Escoleta-6",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033998",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 293,
    //     "prevFrameCounterFcnt": 825,
    //     "latestFrameCounterFcnt": 827,
    //     "lostFcnt": 117,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 298,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC82",
    //     "description": "Escoleta-4",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034019",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 788,
    //     "latestFrameCounterFcnt": 789,
    //     "lostFcnt": 94,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 299,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC83",
    //     "description": "Escoleta-3",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034059",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 281,
    //     "prevFrameCounterFcnt": 794,
    //     "latestFrameCounterFcnt": 795,
    //     "lostFcnt": 126,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 300,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC84",
    //     "description": "Escoleta-5",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034104",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 808,
    //     "latestFrameCounterFcnt": 809,
    //     "lostFcnt": 117,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 301,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC85",
    //     "description": "Escoleta-7",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033914",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -108,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 307,
    //     "prevFrameCounterFcnt": 823,
    //     "latestFrameCounterFcnt": 824,
    //     "lostFcnt": 104,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 302,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC86",
    //     "description": "Escoleta9",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450045529",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 303,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC87",
    //     "description": "Escoleta-69",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034091",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 777,
    //     "latestFrameCounterFcnt": 778,
    //     "lostFcnt": 81,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 304,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC88",
    //     "description": "Escoleta-74bis",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034021",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -82,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 273,
    //     "prevFrameCounterFcnt": 793,
    //     "latestFrameCounterFcnt": 794,
    //     "lostFcnt": 97,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 305,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC89",
    //     "description": "Escoleta-42",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033949",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 271,
    //     "prevFrameCounterFcnt": 763,
    //     "latestFrameCounterFcnt": 764,
    //     "lostFcnt": 85,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 306,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC90",
    //     "description": "Escoleta-53",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034009",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -103,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 313,
    //     "prevFrameCounterFcnt": 841,
    //     "latestFrameCounterFcnt": 842,
    //     "lostFcnt": 122,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 307,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC91",
    //     "description": "Escoleta-45",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034011",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 288,
    //     "prevFrameCounterFcnt": 791,
    //     "latestFrameCounterFcnt": 792,
    //     "lostFcnt": 94,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 308,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC92",
    //     "description": "Escoleta-25",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033948",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 290,
    //     "prevFrameCounterFcnt": 840,
    //     "latestFrameCounterFcnt": 841,
    //     "lostFcnt": 172,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 309,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC93",
    //     "description": "Rei-en-Jaume-10",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033924",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 331,
    //     "prevFrameCounterFcnt": 1029,
    //     "latestFrameCounterFcnt": 1030,
    //     "lostFcnt": 385,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    //     {
    //         "id": 310,
    //         "gatewaysId": null,
    //         "name": "WaterExternalSURGWTC94",
    //         "description": "Escoleta-81",
    //         "provider": null,
    //         "userId": 53,
    //         "typeSensor": "33",
    //         "sensorTypeInfo": {
    //             "id": 33,
    //             "name": "Zenner",
    //             "description": "Zenner",
    //             "sensorType": "customsensor",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "04b6480450034012",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": "b827eb8fe7a4",
    //         "dr": 0,
    //         "rssi": -101,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 286,
    //         "prevFrameCounterFcnt": 780,
    //         "latestFrameCounterFcnt": 781,
    //         "lostFcnt": 92,
    //         "loraSNR": 10,
    //         "networkServerMac": "b827eb8fe7a4",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 311,
    //         "gatewaysId": null,
    //         "name": "WaterExternalSURGWTC08",
    //         "description": "ReiJaume6",
    //         "provider": null,
    //         "userId": 53,
    //         "typeSensor": "33",
    //         "sensorTypeInfo": {
    //             "id": 33,
    //             "name": "Zenner",
    //             "description": "Zenner",
    //             "sensorType": "customsensor",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "04b6480450045530",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": [],
    //         "meterComponentNames": [],
    //         "decoderServers": "b827eb8fe7a4",
    //         "dr": null,
    //         "rssi": null,
    //         "frequency": null,
    //         "firstFrameCounterFcnt": null,
    //         "prevFrameCounterFcnt": null,
    //         "latestFrameCounterFcnt": null,
    //         "lostFcnt": null,
    //         "loraSNR": null,
    //         "networkServerMac": null,
    //         "messageTime": null
    //     },
    //     {
    //         "id": 312,
    //         "gatewaysId": null,
    //         "name": "WaterExternalSURGWTC09",
    //         "description": "ReiJaume6Bj",
    //         "provider": null,
    //         "userId": 53,
    //         "typeSensor": "33",
    //         "sensorTypeInfo": {
    //             "id": 33,
    //             "name": "Zenner",
    //             "description": "Zenner",
    //             "sensorType": "customsensor",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "04b6480450045765",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": [],
    //         "meterComponentNames": [],
    //         "decoderServers": "b827eb8fe7a4",
    //         "dr": null,
    //         "rssi": null,
    //         "frequency": null,
    //         "firstFrameCounterFcnt": null,
    //         "prevFrameCounterFcnt": null,
    //         "latestFrameCounterFcnt": null,
    //         "lostFcnt": null,
    //         "loraSNR": null,
    //         "networkServerMac": null,
    //         "messageTime": null
    //     },
    //     {
    //         "id": 313,
    //         "gatewaysId": null,
    //         "name": "WaterExternalSURGWTC10",
    //         "description": "ReienJaume-8",
    //         "provider": null,
    //         "userId": 53,
    //         "typeSensor": "33",
    //         "sensorTypeInfo": {
    //             "id": 33,
    //             "name": "Zenner",
    //             "description": "Zenner",
    //             "sensorType": "customsensor",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "04b6480450034079",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": "b827eb8fe7a4",
    //         "dr": 0,
    //         "rssi": -118,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 265,
    //         "prevFrameCounterFcnt": 869,
    //         "latestFrameCounterFcnt": 871,
    //         "lostFcnt": 259,
    //         "loraSNR": -6,
    //         "networkServerMac": "b827eb8fe7a4",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 314,
    //         "gatewaysId": null,
    //         "name": "WaterExternalSURGWTC11",
    //         "description": "ReienJaume-12",
    //         "provider": null,
    //         "userId": 53,
    //         "typeSensor": "33",
    //         "sensorTypeInfo": {
    //             "id": 33,
    //             "name": "Zenner",
    //             "description": "Zenner",
    //             "sensorType": "customsensor",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "04b6480450034047",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": [],
    //         "meterComponentNames": [],
    //         "decoderServers": "b827eb8fe7a4",
    //         "dr": 0,
    //         "rssi": -117,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 287,
    //         "prevFrameCounterFcnt": 921,
    //         "latestFrameCounterFcnt": 922,
    //         "lostFcnt": 311,
    //         "loraSNR": -1,
    //         "networkServerMac": "b827eb8fe7a4",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 315,
    //         "gatewaysId": null,
    //         "name": "WaterExternalSURGWTC95",
    //         "description": "Escoleta-49",
    //         "provider": null,
    //         "userId": 53,
    //         "typeSensor": "33",
    //         "sensorTypeInfo": {
    //             "id": 33,
    //             "name": "Zenner",
    //             "description": "Zenner",
    //             "sensorType": "customsensor",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "04b6480450028569",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": "b827eb8fe7a4",
    //         "dr": 0,
    //         "rssi": -113,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 261,
    //         "prevFrameCounterFcnt": 765,
    //         "latestFrameCounterFcnt": 766,
    //         "lostFcnt": 102,
    //         "loraSNR": 7,
    //         "networkServerMac": "b827eb8fe7a4",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 316,
    //         "gatewaysId": null,
    //         "name": "WaterExternalSURGWTC96",
    //         "description": "Escoleta-47",
    //         "provider": null,
    //         "userId": 53,
    //         "typeSensor": "33",
    //         "sensorTypeInfo": {
    //             "id": 33,
    //             "name": "Zenner",
    //             "description": "Zenner",
    //             "sensorType": "customsensor",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "04b6480450032074",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": "b827eb8fe7a4",
    //         "dr": 0,
    //         "rssi": -125,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 254,
    //         "prevFrameCounterFcnt": 793,
    //         "latestFrameCounterFcnt": 794,
    //         "lostFcnt": 136,
    //         "loraSNR": 7,
    //         "networkServerMac": "b827eb8fe7a4",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 317,
    //         "gatewaysId": null,
    //         "name": "WaterExternalSURGWTC97",
    //         "description": "Escoleta-40",
    //         "provider": null,
    //         "userId": 53,
    //         "typeSensor": "33",
    //         "sensorTypeInfo": {
    //             "id": 33,
    //             "name": "Zenner",
    //             "description": "Zenner",
    //             "sensorType": "customsensor",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "04b6480450034039",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": "b827eb8fe7a4",
    //         "dr": 0,
    //         "rssi": -111,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 285,
    //         "prevFrameCounterFcnt": 785,
    //         "latestFrameCounterFcnt": 788,
    //         "lostFcnt": 94,
    //         "loraSNR": 8,
    //         "networkServerMac": "b827eb8fe7a4",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 318,
    //         "gatewaysId": null,
    //         "name": "WaterExternalSURGWTC98",
    //         "description": "Escoleta-31",
    //         "provider": null,
    //         "userId": 53,
    //         "typeSensor": "33",
    //         "sensorTypeInfo": {
    //             "id": 33,
    //             "name": "Zenner",
    //             "description": "Zenner",
    //             "sensorType": "customsensor",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "04b6480450034060",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": "b827eb8fe7a4",
    //         "dr": 0,
    //         "rssi": -108,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 264,
    //         "prevFrameCounterFcnt": 746,
    //         "latestFrameCounterFcnt": 747,
    //         "lostFcnt": 83,
    //         "loraSNR": 5,
    //         "networkServerMac": "b827eb8fe7a4",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 319,
    //         "gatewaysId": null,
    //         "name": "WaterExternalSURGWTC100",
    //         "description": "Escoleta-1",
    //         "provider": null,
    //         "userId": 53,
    //         "typeSensor": "33",
    //         "sensorTypeInfo": {
    //             "id": 33,
    //             "name": "Zenner",
    //             "description": "Zenner",
    //             "sensorType": "customsensor",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "04b6480450033981",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": "b827eb8fe7a4",
    //         "dr": 0,
    //         "rssi": -120,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 276,
    //         "prevFrameCounterFcnt": 938,
    //         "latestFrameCounterFcnt": 949,
    //         "lostFcnt": 391,
    //         "loraSNR": -1,
    //         "networkServerMac": "b827eb8fe7a4",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 320,
    //         "gatewaysId": null,
    //         "name": "WaterExternalSURGWTC99",
    //         "description": "Escoleta-33",
    //         "provider": null,
    //         "userId": 53,
    //         "typeSensor": "33",
    //         "sensorTypeInfo": {
    //             "id": 33,
    //             "name": "Zenner",
    //             "description": "Zenner",
    //             "sensorType": "customsensor",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "04b6480450034076",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": "b827eb8fe7a4",
    //         "dr": 0,
    //         "rssi": -119,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 267,
    //         "prevFrameCounterFcnt": 784,
    //         "latestFrameCounterFcnt": 785,
    //         "lostFcnt": 95,
    //         "loraSNR": -1,
    //         "networkServerMac": "b827eb8fe7a4",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 321,
    //         "gatewaysId": null,
    //         "name": "WaterExternalSURGWTC53",
    //         "description": "Escoleta-55",
    //         "provider": null,
    //         "userId": 53,
    //         "typeSensor": "33",
    //         "sensorTypeInfo": {
    //             "id": 33,
    //             "name": "Zenner",
    //             "description": "Zenner",
    //             "sensorType": "customsensor",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "04b6480450033965",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": "b827eb8fe7a4",
    //         "dr": 0,
    //         "rssi": -103,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 286,
    //         "prevFrameCounterFcnt": 798,
    //         "latestFrameCounterFcnt": 799,
    //         "lostFcnt": 89,
    //         "loraSNR": 8,
    //         "networkServerMac": "b827eb8fe7a4",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 322,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC18",
    //         "description": "Convento-15",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb818727a82e",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 6,
    //         "prevFrameCounterFcnt": 22,
    //         "latestFrameCounterFcnt": 23,
    //         "lostFcnt": 15,
    //         "loraSNR": null,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 323,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC19",
    //         "description": "Convento-6",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb818727a829",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 2,
    //         "rssi": -114,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 275,
    //         "prevFrameCounterFcnt": 801,
    //         "latestFrameCounterFcnt": 802,
    //         "lostFcnt": -145,
    //         "loraSNR": -20,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 324,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC20",
    //         "description": "Gandia-3",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb818727a82a",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 2,
    //         "rssi": -112,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 297,
    //         "prevFrameCounterFcnt": 1356,
    //         "latestFrameCounterFcnt": 1357,
    //         "lostFcnt": 325,
    //         "loraSNR": -14,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 325,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC23",
    //         "description": "Gandia-47",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb818727a831",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 58,
    //         "prevFrameCounterFcnt": 133,
    //         "latestFrameCounterFcnt": 138,
    //         "lostFcnt": -49,
    //         "loraSNR": null,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 326,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC24",
    //         "description": "San-Francesc-7",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a46",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": -114,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 336,
    //         "prevFrameCounterFcnt": 72,
    //         "latestFrameCounterFcnt": 73,
    //         "lostFcnt": -770,
    //         "loraSNR": -17,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 327,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC27",
    //         "description": "San-Cristobal-15",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a41",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 4,
    //         "rssi": -112,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 629,
    //         "prevFrameCounterFcnt": 1690,
    //         "latestFrameCounterFcnt": 1691,
    //         "lostFcnt": 298,
    //         "loraSNR": -11,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 328,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC28",
    //         "description": "Pl-Cristo-Empar-7",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a3c",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 5,
    //         "rssi": -110,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 607,
    //         "prevFrameCounterFcnt": 1632,
    //         "latestFrameCounterFcnt": 1633,
    //         "lostFcnt": 181,
    //         "loraSNR": -1,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 329,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC31",
    //         "description": "Nou-4",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a23",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 3,
    //         "rssi": -114,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 627,
    //         "prevFrameCounterFcnt": 1649,
    //         "latestFrameCounterFcnt": 1650,
    //         "lostFcnt": 149,
    //         "loraSNR": -13,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 330,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC32",
    //         "description": "Nou-1-1",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a35",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 4,
    //         "rssi": -114,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 595,
    //         "prevFrameCounterFcnt": 1607,
    //         "latestFrameCounterFcnt": 1613,
    //         "lostFcnt": 685,
    //         "loraSNR": -11,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 331,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC33",
    //         "description": "Nou-1-2",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a22",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 3,
    //         "rssi": -112,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 136,
    //         "prevFrameCounterFcnt": 1160,
    //         "latestFrameCounterFcnt": 1161,
    //         "lostFcnt": 492,
    //         "loraSNR": -18,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 332,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC36",
    //         "description": "Nou-10",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a58",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 2,
    //         "rssi": -115,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 598,
    //         "prevFrameCounterFcnt": 1629,
    //         "latestFrameCounterFcnt": 1630,
    //         "lostFcnt": 325,
    //         "loraSNR": -19,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 333,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC37",
    //         "description": "Nou-24",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a59",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 7,
    //         "prevFrameCounterFcnt": 29,
    //         "latestFrameCounterFcnt": 30,
    //         "lostFcnt": 16,
    //         "loraSNR": null,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 334,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC40",
    //         "description": "Xativa-25",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a5c",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": null,
    //         "rssi": null,
    //         "frequency": null,
    //         "firstFrameCounterFcnt": null,
    //         "prevFrameCounterFcnt": null,
    //         "latestFrameCounterFcnt": null,
    //         "lostFcnt": null,
    //         "loraSNR": null,
    //         "networkServerMac": null,
    //         "messageTime": null
    //     },
    //     {
    //         "id": 335,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC41",
    //         "description": "Xativa-42",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a60",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": null,
    //         "rssi": null,
    //         "frequency": null,
    //         "firstFrameCounterFcnt": null,
    //         "prevFrameCounterFcnt": null,
    //         "latestFrameCounterFcnt": null,
    //         "lostFcnt": null,
    //         "loraSNR": null,
    //         "networkServerMac": null,
    //         "messageTime": null
    //     },
    //     {
    //         "id": 336,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC42",
    //         "description": "Xativa-60",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275bbe",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": null,
    //         "rssi": null,
    //         "frequency": null,
    //         "firstFrameCounterFcnt": null,
    //         "prevFrameCounterFcnt": null,
    //         "latestFrameCounterFcnt": null,
    //         "lostFcnt": null,
    //         "loraSNR": null,
    //         "networkServerMac": null,
    //         "messageTime": null
    //     },
    //     {
    //         "id": 337,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC44",
    //         "description": "CronistaCaspir-32",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a47",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": null,
    //         "prevFrameCounterFcnt": null,
    //         "latestFrameCounterFcnt": 56,
    //         "lostFcnt": 55,
    //         "loraSNR": null,
    //         "networkServerMac": null,
    //         "messageTime": null
    //     },
    //     {
    //         "id": 338,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC45",
    //         "description": "CronistaCaspir-17",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275bc3",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 51,
    //         "prevFrameCounterFcnt": 58,
    //         "latestFrameCounterFcnt": 16,
    //         "lostFcnt": -47,
    //         "loraSNR": null,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 339,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC48",
    //         "description": "CronistaCaspir-19",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a4a",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": -114,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 118,
    //         "prevFrameCounterFcnt": 30,
    //         "latestFrameCounterFcnt": 38,
    //         "lostFcnt": -319,
    //         "loraSNR": -18,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 340,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC49",
    //         "description": "CronistaCaspir-11",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a4b",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": -115,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 278,
    //         "prevFrameCounterFcnt": 395,
    //         "latestFrameCounterFcnt": 404,
    //         "lostFcnt": -46,
    //         "loraSNR": -19,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 341,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC52",
    //         "description": "CronistaCapsir-6",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275bcd",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": -113,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 119,
    //         "prevFrameCounterFcnt": 182,
    //         "latestFrameCounterFcnt": 183,
    //         "lostFcnt": -237,
    //         "loraSNR": -20,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 342,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC54",
    //         "description": "JoseIranzo-57",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a0b",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": null,
    //         "rssi": null,
    //         "frequency": null,
    //         "firstFrameCounterFcnt": null,
    //         "prevFrameCounterFcnt": null,
    //         "latestFrameCounterFcnt": null,
    //         "lostFcnt": null,
    //         "loraSNR": null,
    //         "networkServerMac": null,
    //         "messageTime": null
    //     },
    //     {
    //         "id": 343,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC56",
    //         "description": "CronistaCapsir-4-2",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a1e",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": -112,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 271,
    //         "prevFrameCounterFcnt": 572,
    //         "latestFrameCounterFcnt": 575,
    //         "lostFcnt": 152,
    //         "loraSNR": -18,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 344,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC59",
    //         "description": "Valencia-23-2",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a1c",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 43,
    //         "prevFrameCounterFcnt": 5,
    //         "latestFrameCounterFcnt": 6,
    //         "lostFcnt": -44,
    //         "loraSNR": null,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 345,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC60",
    //         "description": "Pl-lom-5",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a1a",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 3,
    //         "rssi": -114,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 605,
    //         "prevFrameCounterFcnt": 1678,
    //         "latestFrameCounterFcnt": 1679,
    //         "lostFcnt": 263,
    //         "loraSNR": -13,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 346,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC62",
    //         "description": "Pl-Roca-3",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a1b",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 5,
    //         "rssi": -109,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 595,
    //         "prevFrameCounterFcnt": 1656,
    //         "latestFrameCounterFcnt": 1657,
    //         "lostFcnt": 276,
    //         "loraSNR": 0,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 347,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC63",
    //         "description": "Font-1",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a3f",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": -111,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 276,
    //         "prevFrameCounterFcnt": 39,
    //         "latestFrameCounterFcnt": 40,
    //         "lostFcnt": -635,
    //         "loraSNR": -16,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 348,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC66",
    //         "description": "Font-10",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a37",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 2,
    //         "rssi": -115,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 293,
    //         "prevFrameCounterFcnt": 1290,
    //         "latestFrameCounterFcnt": 1291,
    //         "lostFcnt": 590,
    //         "loraSNR": -12,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 349,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC67",
    //         "description": "Romaldo-Soler-Miquel-20",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a36",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 5,
    //         "rssi": -108,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 594,
    //         "prevFrameCounterFcnt": 1668,
    //         "latestFrameCounterFcnt": 1669,
    //         "lostFcnt": 259,
    //         "loraSNR": 1,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 350,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC70",
    //         "description": "JoseIranzo-36-2",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a39",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 5,
    //         "rssi": -112,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 608,
    //         "prevFrameCounterFcnt": 1637,
    //         "latestFrameCounterFcnt": 1651,
    //         "lostFcnt": 357,
    //         "loraSNR": -3,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 351,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC71",
    //         "description": "constitucion-44",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a28",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 11,
    //         "prevFrameCounterFcnt": 179,
    //         "latestFrameCounterFcnt": 191,
    //         "lostFcnt": -22,
    //         "loraSNR": null,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 352,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC74",
    //         "description": "Valencia-1-1",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a17",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": null,
    //         "rssi": null,
    //         "frequency": null,
    //         "firstFrameCounterFcnt": null,
    //         "prevFrameCounterFcnt": null,
    //         "latestFrameCounterFcnt": null,
    //         "lostFcnt": null,
    //         "loraSNR": null,
    //         "networkServerMac": null,
    //         "messageTime": null
    //     },
    //     {
    //         "id": 353,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC75",
    //         "description": "Valencia-1-3",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a16",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": null,
    //         "rssi": null,
    //         "frequency": null,
    //         "firstFrameCounterFcnt": null,
    //         "prevFrameCounterFcnt": null,
    //         "latestFrameCounterFcnt": null,
    //         "lostFcnt": null,
    //         "loraSNR": null,
    //         "networkServerMac": null,
    //         "messageTime": null
    //     },
    //     {
    //         "id": 354,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC77",
    //         "description": "AugustoGomar-4bajo",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a15",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 2,
    //         "rssi": -112,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 626,
    //         "prevFrameCounterFcnt": 1642,
    //         "latestFrameCounterFcnt": 1649,
    //         "lostFcnt": 587,
    //         "loraSNR": -17,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 355,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC78",
    //         "description": "Augusto-Gomar-4-2",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a0e",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 2,
    //         "rssi": -114,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 621,
    //         "prevFrameCounterFcnt": 1656,
    //         "latestFrameCounterFcnt": 1657,
    //         "lostFcnt": 359,
    //         "loraSNR": -20,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 356,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC81",
    //         "description": "Boades-23",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a13",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 2,
    //         "rssi": -114,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 293,
    //         "prevFrameCounterFcnt": 1321,
    //         "latestFrameCounterFcnt": 1333,
    //         "lostFcnt": 575,
    //         "loraSNR": -12,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 357,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC82",
    //         "description": "Boades-15",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a0d",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 5,
    //         "rssi": -114,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 611,
    //         "prevFrameCounterFcnt": 1662,
    //         "latestFrameCounterFcnt": 1663,
    //         "lostFcnt": 322,
    //         "loraSNR": -18,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 358,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC85",
    //         "description": "Romaldo-Soler-Miquel-7",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a09",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 4,
    //         "rssi": -113,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 616,
    //         "prevFrameCounterFcnt": 1670,
    //         "latestFrameCounterFcnt": 1671,
    //         "lostFcnt": 355,
    //         "loraSNR": -6,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 359,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC86",
    //         "description": "Abajo-14",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a08",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 5,
    //         "rssi": -113,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 592,
    //         "prevFrameCounterFcnt": 1620,
    //         "latestFrameCounterFcnt": 1621,
    //         "lostFcnt": 441,
    //         "loraSNR": -4,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 360,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC88",
    //         "description": "Boades-6",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a06",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 5,
    //         "rssi": -113,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 39,
    //         "prevFrameCounterFcnt": 1094,
    //         "latestFrameCounterFcnt": 1095,
    //         "lostFcnt": 379,
    //         "loraSNR": -6,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 361,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC89",
    //         "description": "AgustoGomar-15",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a07",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": -115,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 117,
    //         "prevFrameCounterFcnt": 19,
    //         "latestFrameCounterFcnt": 28,
    //         "lostFcnt": -560,
    //         "loraSNR": -19,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 362,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC90",
    //         "description": "Horno-3",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a51",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 5,
    //         "rssi": -102,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 567,
    //         "prevFrameCounterFcnt": 1572,
    //         "latestFrameCounterFcnt": 1573,
    //         "lostFcnt": 154,
    //         "loraSNR": 5,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 363,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC93",
    //         "description": "Soriano-8",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a4e",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 5,
    //         "rssi": -107,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 589,
    //         "prevFrameCounterFcnt": 1643,
    //         "latestFrameCounterFcnt": 1644,
    //         "lostFcnt": 265,
    //         "loraSNR": 2,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 364,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC94",
    //         "description": "Pl-Olmo-2",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a54",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 3,
    //         "rssi": -113,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 613,
    //         "prevFrameCounterFcnt": 1672,
    //         "latestFrameCounterFcnt": 1673,
    //         "lostFcnt": 265,
    //         "loraSNR": -15,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 365,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC95",
    //         "description": "Meson-25",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a56",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 4,
    //         "rssi": -111,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 622,
    //         "prevFrameCounterFcnt": 1703,
    //         "latestFrameCounterFcnt": 1704,
    //         "lostFcnt": 226,
    //         "loraSNR": -4,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 366,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC98",
    //         "description": "DoctorGomar-1",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a53",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 5,
    //         "rssi": -115,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 620,
    //         "prevFrameCounterFcnt": 1684,
    //         "latestFrameCounterFcnt": 1685,
    //         "lostFcnt": 260,
    //         "loraSNR": -10,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 367,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC101",
    //         "description": "OscarDurich-6",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a52",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 5,
    //         "rssi": -112,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 599,
    //         "prevFrameCounterFcnt": 1658,
    //         "latestFrameCounterFcnt": 1659,
    //         "lostFcnt": 238,
    //         "loraSNR": -11,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 368,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC102",
    //         "description": "OscarDurich-4",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb818727a832",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 5,
    //         "rssi": -103,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 609,
    //         "prevFrameCounterFcnt": 1654,
    //         "latestFrameCounterFcnt": 1655,
    //         "lostFcnt": 349,
    //         "loraSNR": -12,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 369,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC105",
    //         "description": "OscarDurich-7",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb818727a83e",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 5,
    //         "rssi": -114,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 291,
    //         "prevFrameCounterFcnt": 1370,
    //         "latestFrameCounterFcnt": 1371,
    //         "lostFcnt": 278,
    //         "loraSNR": -13,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 370,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC04",
    //         "description": "constitucion-16",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275bce",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 2,
    //         "rssi": -112,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 289,
    //         "prevFrameCounterFcnt": 815,
    //         "latestFrameCounterFcnt": 816,
    //         "lostFcnt": -222,
    //         "loraSNR": -20,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 371,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC07",
    //         "description": "San-Jose-21",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb818727a840",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 1,
    //         "rssi": -113,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 277,
    //         "prevFrameCounterFcnt": 1291,
    //         "latestFrameCounterFcnt": 1292,
    //         "lostFcnt": 559,
    //         "loraSNR": -21,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 372,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC08",
    //         "description": "San-Jose-14",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb81872776b7",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 2,
    //         "rssi": -114,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 295,
    //         "prevFrameCounterFcnt": 830,
    //         "latestFrameCounterFcnt": 831,
    //         "lostFcnt": -252,
    //         "loraSNR": -19,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 373,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC11",
    //         "description": "Gandia-26",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb818727a843",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 78,
    //         "prevFrameCounterFcnt": 684,
    //         "latestFrameCounterFcnt": 685,
    //         "lostFcnt": -2,
    //         "loraSNR": null,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 374,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC12",
    //         "description": "convento-59",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb818727a844",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": -110,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 283,
    //         "prevFrameCounterFcnt": 740,
    //         "latestFrameCounterFcnt": 748,
    //         "lostFcnt": 82,
    //         "loraSNR": -20,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 375,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC16",
    //         "description": "Convento-31",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb818727a81b",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 1,
    //         "rssi": -112,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 257,
    //         "prevFrameCounterFcnt": 936,
    //         "latestFrameCounterFcnt": 952,
    //         "lostFcnt": 114,
    //         "loraSNR": -21,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 376,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC17",
    //         "description": "Convento-19",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb818727a82d",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": -111,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 153,
    //         "prevFrameCounterFcnt": 1158,
    //         "latestFrameCounterFcnt": 1159,
    //         "lostFcnt": 573,
    //         "loraSNR": -20,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 377,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC21",
    //         "description": "Pl-Luis.Guarner-2",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb818727a82f",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": -111,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 115,
    //         "prevFrameCounterFcnt": 120,
    //         "latestFrameCounterFcnt": 121,
    //         "lostFcnt": -412,
    //         "loraSNR": -15,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 378,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC22",
    //         "description": "San-Francesc-13",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb818727a82b",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 2,
    //         "rssi": -115,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 296,
    //         "prevFrameCounterFcnt": 1363,
    //         "latestFrameCounterFcnt": 1364,
    //         "lostFcnt": 228,
    //         "loraSNR": -9,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 379,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC25",
    //         "description": "Pl-Independiencia-9",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a44",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 1,
    //         "rssi": -114,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 597,
    //         "prevFrameCounterFcnt": 639,
    //         "latestFrameCounterFcnt": 641,
    //         "lostFcnt": -467,
    //         "loraSNR": -19,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 380,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC26",
    //         "description": "San-Francisco-1",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a25",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 2,
    //         "rssi": -113,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 300,
    //         "prevFrameCounterFcnt": 1354,
    //         "latestFrameCounterFcnt": 1355,
    //         "lostFcnt": 218,
    //         "loraSNR": -12,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 381,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC29",
    //         "description": "Pl-Cristo-Empar-7",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a27",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 5,
    //         "rssi": -109,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 600,
    //         "prevFrameCounterFcnt": 1684,
    //         "latestFrameCounterFcnt": 1685,
    //         "lostFcnt": 249,
    //         "loraSNR": -10,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 382,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC30",
    //         "description": "Nou-2",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a24",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 1,
    //         "rssi": -113,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 292,
    //         "prevFrameCounterFcnt": 1342,
    //         "latestFrameCounterFcnt": 1343,
    //         "lostFcnt": 294,
    //         "loraSNR": -13,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 383,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC34",
    //         "description": "Nou-3",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a5d",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 43,
    //         "prevFrameCounterFcnt": 28,
    //         "latestFrameCounterFcnt": 29,
    //         "lostFcnt": -23,
    //         "loraSNR": null,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 384,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC35",
    //         "description": "Nou-5",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a57",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": -117,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 138,
    //         "prevFrameCounterFcnt": 1153,
    //         "latestFrameCounterFcnt": 1162,
    //         "lostFcnt": 446,
    //         "loraSNR": -11,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 385,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC38",
    //         "description": "Nou-28",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a5b",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": -114,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 135,
    //         "prevFrameCounterFcnt": 16,
    //         "latestFrameCounterFcnt": 17,
    //         "lostFcnt": -567,
    //         "loraSNR": -14,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 386,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC39",
    //         "description": "Xativa-5",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a5f",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": null,
    //         "rssi": null,
    //         "frequency": null,
    //         "firstFrameCounterFcnt": null,
    //         "prevFrameCounterFcnt": null,
    //         "latestFrameCounterFcnt": null,
    //         "lostFcnt": null,
    //         "loraSNR": null,
    //         "networkServerMac": null,
    //         "messageTime": null
    //     },
    //     {
    //         "id": 387,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC43",
    //         "description": "CronistaCaspir-25",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a62",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 5,
    //         "prevFrameCounterFcnt": 46,
    //         "latestFrameCounterFcnt": 40,
    //         "lostFcnt": 25,
    //         "loraSNR": null,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 388,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC46",
    //         "description": "CronistaCaspir-17",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a48",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": -115,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 14,
    //         "prevFrameCounterFcnt": 18,
    //         "latestFrameCounterFcnt": 57,
    //         "lostFcnt": 23,
    //         "loraSNR": -18,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 389,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC47",
    //         "description": "Valencia-1",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275bc4",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": null,
    //         "rssi": null,
    //         "frequency": null,
    //         "firstFrameCounterFcnt": null,
    //         "prevFrameCounterFcnt": null,
    //         "latestFrameCounterFcnt": null,
    //         "lostFcnt": null,
    //         "loraSNR": null,
    //         "networkServerMac": null,
    //         "messageTime": null
    //     },
    //     {
    //         "id": 390,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC50",
    //         "description": "CronistaCaspir-5-MoblesRoque",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275bc8",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 6,
    //         "prevFrameCounterFcnt": 6,
    //         "latestFrameCounterFcnt": 6,
    //         "lostFcnt": 0,
    //         "loraSNR": null,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 391,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC51",
    //         "description": "CronistaCapsir-8",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a4c",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": null,
    //         "rssi": null,
    //         "frequency": null,
    //         "firstFrameCounterFcnt": null,
    //         "prevFrameCounterFcnt": null,
    //         "latestFrameCounterFcnt": null,
    //         "lostFcnt": null,
    //         "loraSNR": null,
    //         "networkServerMac": null,
    //         "messageTime": null
    //     },
    //     {
    //         "id": 392,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC55",
    //         "description": "CronistaCapsir-4",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a19",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 65,
    //         "prevFrameCounterFcnt": 21,
    //         "latestFrameCounterFcnt": 27,
    //         "lostFcnt": -52,
    //         "loraSNR": null,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 393,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC57",
    //         "description": "Paleontologo-monsen-6",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a1f",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": -112,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 139,
    //         "prevFrameCounterFcnt": 160,
    //         "latestFrameCounterFcnt": 161,
    //         "lostFcnt": -506,
    //         "loraSNR": -20,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 394,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC58",
    //         "description": "Valencia-23",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a21",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": -113,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 275,
    //         "prevFrameCounterFcnt": 48,
    //         "latestFrameCounterFcnt": 3,
    //         "lostFcnt": -319,
    //         "loraSNR": -20,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 395,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC61",
    //         "description": "pl-lom-7",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a20",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 4,
    //         "rssi": -111,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 600,
    //         "prevFrameCounterFcnt": 1614,
    //         "latestFrameCounterFcnt": 1624,
    //         "lostFcnt": 567,
    //         "loraSNR": -16,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 396,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC64",
    //         "description": "Font-6",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a38",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 57,
    //         "prevFrameCounterFcnt": 53,
    //         "latestFrameCounterFcnt": 67,
    //         "lostFcnt": -14,
    //         "loraSNR": null,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 397,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC65",
    //         "description": "Font-8",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a3e",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": -114,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 80,
    //         "prevFrameCounterFcnt": 45,
    //         "latestFrameCounterFcnt": 46,
    //         "lostFcnt": -645,
    //         "loraSNR": -19,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 398,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC68",
    //         "description": "Font-2-2",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a3a",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 44,
    //         "prevFrameCounterFcnt": 22,
    //         "latestFrameCounterFcnt": 26,
    //         "lostFcnt": -35,
    //         "loraSNR": null,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 399,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC69",
    //         "description": "Font-2-1",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a3b",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": null,
    //         "prevFrameCounterFcnt": null,
    //         "latestFrameCounterFcnt": 38,
    //         "lostFcnt": 37,
    //         "loraSNR": null,
    //         "networkServerMac": null,
    //         "messageTime": null
    //     },
    //     {
    //         "id": 400,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC72",
    //         "description": "Nou-18-2",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a26",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": -114,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 176,
    //         "prevFrameCounterFcnt": 76,
    //         "latestFrameCounterFcnt": 77,
    //         "lostFcnt": -369,
    //         "loraSNR": -20,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 401,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC73",
    //         "description": "Nou-18-1",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a11",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 99,
    //         "prevFrameCounterFcnt": 341,
    //         "latestFrameCounterFcnt": 354,
    //         "lostFcnt": 58,
    //         "loraSNR": null,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 402,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC76",
    //         "description": "Xativa-15",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a10",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 57,
    //         "prevFrameCounterFcnt": 31,
    //         "latestFrameCounterFcnt": 32,
    //         "lostFcnt": -79,
    //         "loraSNR": null,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 403,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC79",
    //         "description": "Augusto-Gomar-4-1",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a0f",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 2,
    //         "rssi": -109,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 604,
    //         "prevFrameCounterFcnt": 1650,
    //         "latestFrameCounterFcnt": 1652,
    //         "lostFcnt": 217,
    //         "loraSNR": -15,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 404,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC80",
    //         "description": "AugustoGomar-3",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a14",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 4,
    //         "rssi": -112,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 602,
    //         "prevFrameCounterFcnt": 1648,
    //         "latestFrameCounterFcnt": 1649,
    //         "lostFcnt": 254,
    //         "loraSNR": -8,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 405,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC83",
    //         "description": "Boades-22",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a0a",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 4,
    //         "rssi": -114,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 295,
    //         "prevFrameCounterFcnt": 1358,
    //         "latestFrameCounterFcnt": 1359,
    //         "lostFcnt": 256,
    //         "loraSNR": -15,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 406,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC84",
    //         "description": "Boades-18",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a04",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 1,
    //         "rssi": -112,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 611,
    //         "prevFrameCounterFcnt": 1674,
    //         "latestFrameCounterFcnt": 1675,
    //         "lostFcnt": 436,
    //         "loraSNR": -15,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 407,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC87",
    //         "description": "constitucion-26",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb81872759f4",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 0,
    //         "rssi": -113,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 27,
    //         "prevFrameCounterFcnt": 700,
    //         "latestFrameCounterFcnt": 701,
    //         "lostFcnt": 109,
    //         "loraSNR": -17,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 408,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC91",
    //         "description": "Pintor-VicentePla-1",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb81872759f3",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 5,
    //         "rssi": -107,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 566,
    //         "prevFrameCounterFcnt": 1583,
    //         "latestFrameCounterFcnt": 1584,
    //         "lostFcnt": 173,
    //         "loraSNR": 3,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 409,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC92",
    //         "description": "Horno-7",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb81872759ef",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 5,
    //         "rssi": -103,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 568,
    //         "prevFrameCounterFcnt": 1591,
    //         "latestFrameCounterFcnt": 1592,
    //         "lostFcnt": 167,
    //         "loraSNR": 6,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 410,
    //         "gatewaysId": null,
    //         "name": "WaterExternalLACGWTC96",
    //         "description": "Meson-21",
    //         "provider": null,
    //         "userId": 63,
    //         "typeSensor": "60",
    //         "sensorTypeInfo": {
    //             "id": 60,
    //             "name": "oemsensors",
    //             "description": null,
    //             "sensorType": "oemsensors",
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": "DiehlHRLc G3",
    //         "installationDate": "2021-06-18",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "90dffb8187275a4d",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": "4999",
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": ",b827ebe5f665,dca632cac20e",
    //         "dr": 3,
    //         "rssi": -112,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 603,
    //         "prevFrameCounterFcnt": 1669,
    //         "latestFrameCounterFcnt": 1670,
    //         "lostFcnt": 276,
    //         "loraSNR": -12,
    //         "networkServerMac": "dca632cac20e",
    //         "messageTime": null
    //     },
    //    {
    //         "id": 13551,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC27",
    //         "description": "Sensor ocupacion plaza Calle Juan Ramón 09",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4524162,
    //         "longitude": 39.4875237,
    //         "deviceEUI": "0004a30b0103dcd4",
    //         "appEUI": "App",
    //         "appKEY": "1cd1cd6446e881d3e6b75c43ca91cf28",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 1,
    //         "prevFrameCounterFcnt": 281,
    //         "latestFrameCounterFcnt": 282,
    //         "lostFcnt": 3,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13552,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC28",
    //         "description": "Sensor ocupacion plaza Calle Juan Ramón 10",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4524149,
    //         "longitude": 39.4875485,
    //         "deviceEUI": "0004a30b0104569c",
    //         "appEUI": "App",
    //         "appKEY": "381c8502ed2e75d8b1cc380d4f25d6dd",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 275,
    //         "latestFrameCounterFcnt": 276,
    //         "lostFcnt": 15,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13553,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC29",
    //         "description": "Sensor ocupacion plaza Calle Juan Ramón 11",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4524197,
    //         "longitude": 39.4876107,
    //         "deviceEUI": "0004a30b01044cb7",
    //         "appEUI": "App",
    //         "appKEY": "8a4065d01968efa70d095ebe007a4d65",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 320,
    //         "latestFrameCounterFcnt": 321,
    //         "lostFcnt": 23,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13554,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC30",
    //         "description": "Sensor ocupacion plaza Calle Juan Ramón 12",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.452419,
    //         "longitude": 39.487635,
    //         "deviceEUI": "0004a30b0103fad4",
    //         "appEUI": "App",
    //         "appKEY": "f93745208216149834f23b863ba33bd6",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 288,
    //         "latestFrameCounterFcnt": 289,
    //         "lostFcnt": 9,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13555,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC31",
    //         "description": "Sensor ocupacion plaza Calle Juan Ramón 13",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4524183,
    //         "longitude": 39.4876567,
    //         "deviceEUI": "0004a30b0103e997",
    //         "appEUI": "App",
    //         "appKEY": "d5463ca6d700359447951d2df9391f4b",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 259,
    //         "latestFrameCounterFcnt": 260,
    //         "lostFcnt": 19,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13556,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC32",
    //         "description": "Sensor ocupacion plaza Calle Juan Ramón 14",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4524183,
    //         "longitude": 39.4876764,
    //         "deviceEUI": "0004a30b0103c743",
    //         "appEUI": "App",
    //         "appKEY": "224ac95436e7528d9a0448d16a44c9de",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 329,
    //         "latestFrameCounterFcnt": 330,
    //         "lostFcnt": 60,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13557,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC33",
    //         "description": "Sensor ocupacion plaza Calle Juan Ramón 15",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4523,
    //         "longitude": 39.4873,
    //         "deviceEUI": "0004a30b0103b629",
    //         "appEUI": "App",
    //         "appKEY": "4c220dd278f1d650bb90a4b376193bec",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 284,
    //         "latestFrameCounterFcnt": 285,
    //         "lostFcnt": 35,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13558,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC34",
    //         "description": "Sensor ocupacion plaza Calle Paterna 01",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4511212,
    //         "longitude": 39.485774,
    //         "deviceEUI": "0004a30b0103ded9",
    //         "appEUI": "App",
    //         "appKEY": "983643604683b9c958f5dc93a1d1afac",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 311,
    //         "latestFrameCounterFcnt": 312,
    //         "lostFcnt": 25,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13559,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC35",
    //         "description": "Sensor ocupacion plaza Calle Paterna 02",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4511366,
    //         "longitude": 39.4857942,
    //         "deviceEUI": "0004a30b01031be8",
    //         "appEUI": "App",
    //         "appKEY": "9cf6d46efcdc48eb1cb911ea13883a99",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 18,
    //         "prevFrameCounterFcnt": 281,
    //         "latestFrameCounterFcnt": 282,
    //         "lostFcnt": -4,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13560,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC36",
    //         "description": "Sensor ocupacion plaza Calle Paterna 03",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4511507,
    //         "longitude": 39.4858216,
    //         "deviceEUI": "0004a30b010455df",
    //         "appEUI": "App",
    //         "appKEY": "2302618ce24c192dea46cbe39c6f5c7f",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 18,
    //         "prevFrameCounterFcnt": 329,
    //         "latestFrameCounterFcnt": 330,
    //         "lostFcnt": 50,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13561,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC37",
    //         "description": "Sensor ocupacion plaza Calle Paterna 04",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4511674,
    //         "longitude": 39.4858418,
    //         "deviceEUI": "0004a30b0103c5b4",
    //         "appEUI": "App",
    //         "appKEY": "65a5fce9bccee6cb6391662f68613e7c",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 26,
    //         "prevFrameCounterFcnt": 305,
    //         "latestFrameCounterFcnt": 306,
    //         "lostFcnt": 14,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13562,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC38",
    //         "description": "Sensor ocupacion plaza Calle Paterna 05",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4511815,
    //         "longitude": 39.4858651,
    //         "deviceEUI": "0004a30b01035933",
    //         "appEUI": "App",
    //         "appKEY": "d8e00fb7a2619e6764cdcae882ffcf22",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 15,
    //         "prevFrameCounterFcnt": 279,
    //         "latestFrameCounterFcnt": 280,
    //         "lostFcnt": 15,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13563,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC39",
    //         "description": "Sensor ocupacion plaza Calle Paterna 06",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4511929,
    //         "longitude": 39.485878,
    //         "deviceEUI": "0004a30b0103766d",
    //         "appEUI": "App",
    //         "appKEY": "edf6b52b01031d0c73710f4861058dce",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 295,
    //         "latestFrameCounterFcnt": 296,
    //         "lostFcnt": 18,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13564,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC40",
    //         "description": "Sensor ocupacion plaza Calle Paterna 07",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4512036,
    //         "longitude": 39.4859003,
    //         "deviceEUI": "0004a30b010356a7",
    //         "appEUI": "App",
    //         "appKEY": "dfe7c7747c581be00817ab15ef17bfa3",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 285,
    //         "latestFrameCounterFcnt": 286,
    //         "lostFcnt": 53,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13565,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC41",
    //         "description": "Sensor ocupacion plaza Calle Paterna 08",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4512217,
    //         "longitude": 39.4859235,
    //         "deviceEUI": "0004a30b0104180e",
    //         "appEUI": "App",
    //         "appKEY": "b2e473dce42de461e9c4e7df3f9fd8ba",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 284,
    //         "latestFrameCounterFcnt": 285,
    //         "lostFcnt": 106,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13566,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC42",
    //         "description": "Sensor ocupacion plaza Calle Paterna 09",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4512365,
    //         "longitude": 39.4859422,
    //         "deviceEUI": "0004a30b01038bca",
    //         "appEUI": "App",
    //         "appKEY": "5184a271a962a6952127df7182a6ebf1",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 334,
    //         "latestFrameCounterFcnt": 335,
    //         "lostFcnt": 79,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13567,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC43",
    //         "description": "Sensor ocupacion plaza Calle Paterna 10",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4512512,
    //         "longitude": 39.4859691,
    //         "deviceEUI": "0004a30b0103fb51",
    //         "appEUI": "App",
    //         "appKEY": "1776d8f167117b5aab9e4a1501720ecd",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 245,
    //         "latestFrameCounterFcnt": 248,
    //         "lostFcnt": 26,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13568,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC44",
    //         "description": "Sensor ocupacion plaza Calle Paterna 11",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4512825,
    //         "longitude": 39.4860006,
    //         "deviceEUI": "0004a30b0103b2f9",
    //         "appEUI": "App",
    //         "appKEY": "4511e6caf745d1931cc0c55f53ed2cec",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 330,
    //         "latestFrameCounterFcnt": 333,
    //         "lostFcnt": 61,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13569,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC45",
    //         "description": "Sensor ocupacion plaza Calle Paterna 12",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4510901,
    //         "longitude": 39.4858733,
    //         "deviceEUI": "0004a30b01031906",
    //         "appEUI": "App",
    //         "appKEY": "7c9c79e23c4dc6acdc8b1f2ff77dc268",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 327,
    //         "latestFrameCounterFcnt": 328,
    //         "lostFcnt": 83,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13570,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC46",
    //         "description": "Sensor ocupacion plaza Calle Paterna 13",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4511082,
    //         "longitude": 39.4858951,
    //         "deviceEUI": "0004a30b0104252d",
    //         "appEUI": "App",
    //         "appKEY": "1e983d94430dc382a32e872e36ac6954",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 368,
    //         "latestFrameCounterFcnt": 369,
    //         "lostFcnt": 158,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13571,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC47",
    //         "description": "Sensor ocupacion plaza Calle Paterna 14",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4511162,
    //         "longitude": 39.4859152,
    //         "deviceEUI": "0004a30b01041ac3",
    //         "appEUI": "App",
    //         "appKEY": "1d421848687481bf1ed506484e2620b2",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 299,
    //         "latestFrameCounterFcnt": 301,
    //         "lostFcnt": 75,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13572,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC48",
    //         "description": "Sensor ocupacion plaza Calle Paterna 15",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4511283,
    //         "longitude": 39.4859339,
    //         "deviceEUI": "0004a30b01045fc4",
    //         "appEUI": "App",
    //         "appKEY": "2d09f49276832a8a1df504b02290d3e1",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 323,
    //         "latestFrameCounterFcnt": 324,
    //         "lostFcnt": 103,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13573,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC49",
    //         "description": "Sensor ocupacion plaza Calle Paterna 16",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4511384,
    //         "longitude": 39.485953,
    //         "deviceEUI": "0004a30b01035b97",
    //         "appEUI": "App",
    //         "appKEY": "8128ed8d19a0b73a12fbbcda00a1ebb2",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 2,
    //         "prevFrameCounterFcnt": 331,
    //         "latestFrameCounterFcnt": 332,
    //         "lostFcnt": 112,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13574,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC50",
    //         "description": "Sensor ocupacion plaza Calle Paterna 17",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4511484,
    //         "longitude": 39.4859727,
    //         "deviceEUI": "0004a30b0102e6a6",
    //         "appEUI": "App",
    //         "appKEY": "16c07daea9240cde2046b03c7500abdd",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 1,
    //         "prevFrameCounterFcnt": 339,
    //         "latestFrameCounterFcnt": 340,
    //         "lostFcnt": 94,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13575,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC51",
    //         "description": "Sensor ocupacion plaza Calle Paterna 18",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4511665,
    //         "longitude": 39.4859872,
    //         "deviceEUI": "0004a30b0103898d",
    //         "appEUI": "App",
    //         "appKEY": "2db9fe30c7501648911bb8450811d8b8",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 3,
    //         "prevFrameCounterFcnt": 325,
    //         "latestFrameCounterFcnt": 328,
    //         "lostFcnt": 103,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13576,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC52",
    //         "description": "Sensor ocupacion plaza Calle Paterna 19",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4511793,
    //         "longitude": 39.4860089,
    //         "deviceEUI": "0004a30b010402ec",
    //         "appEUI": "App",
    //         "appKEY": "f83690251b0006df1e6aaa9389f33c03",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 378,
    //         "latestFrameCounterFcnt": 379,
    //         "lostFcnt": 169,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13577,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC53",
    //         "description": "Sensor ocupacion plaza Calle Paterna 20",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4511933,
    //         "longitude": 39.486025,
    //         "deviceEUI": "0004a30b01040495",
    //         "appEUI": "App",
    //         "appKEY": "30a8ef0af71f3a177b25f92259969ad7",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 6,
    //         "prevFrameCounterFcnt": 358,
    //         "latestFrameCounterFcnt": 359,
    //         "lostFcnt": 127,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13578,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC54",
    //         "description": "Sensor ocupacion plaza Calle Paterna 21",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4512068,
    //         "longitude": 39.4860451,
    //         "deviceEUI": "0004a30b0103de98",
    //         "appEUI": "App",
    //         "appKEY": "a0eac4cc24ab2b7172174d6cf606a8b3",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 338,
    //         "latestFrameCounterFcnt": 339,
    //         "lostFcnt": 138,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13579,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC55",
    //         "description": "Sensor ocupacion plaza Calle Paterna 22",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4513613,
    //         "longitude": 39.4860762,
    //         "deviceEUI": "0004a30b01043ef2",
    //         "appEUI": "App",
    //         "appKEY": "5864fafc5df714b4bc13d875c8b84c12",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 325,
    //         "latestFrameCounterFcnt": 326,
    //         "lostFcnt": 100,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13580,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC56",
    //         "description": "Sensor ocupacion plaza Calle Paterna 23",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.45138,
    //         "longitude": 39.4860983,
    //         "deviceEUI": "0004a30b0104418a",
    //         "appEUI": "App",
    //         "appKEY": "e3f5e35fcc21c24a1cad3f8ae24708b2",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 1,
    //         "prevFrameCounterFcnt": 314,
    //         "latestFrameCounterFcnt": 315,
    //         "lostFcnt": 192,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13581,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC57",
    //         "description": "Sensor ocupacion plaza Calle Paterna 24",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4513988,
    //         "longitude": 39.4861249,
    //         "deviceEUI": "0004a30b0104254a",
    //         "appEUI": "App",
    //         "appKEY": "7ed156173fe4f2c0f696fbfdff484d79",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 355,
    //         "latestFrameCounterFcnt": 356,
    //         "lostFcnt": 189,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13582,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC58",
    //         "description": "Sensor ocupacion plaza Calle Paterna 25",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4514108,
    //         "longitude": 39.4861486,
    //         "deviceEUI": "0004a30b0103cde1",
    //         "appEUI": "App",
    //         "appKEY": "6e2f3ee4a5585fc24112c3c4d7bd4fed",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 324,
    //         "latestFrameCounterFcnt": 325,
    //         "lostFcnt": 147,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13583,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC59",
    //         "description": "Sensor ocupacion plaza Calle Paterna 26",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4514269,
    //         "longitude": 39.4861631,
    //         "deviceEUI": "0004a30b010408d6",
    //         "appEUI": "App",
    //         "appKEY": "d39c0584580f24bc9be15a4420126402",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 361,
    //         "latestFrameCounterFcnt": 362,
    //         "lostFcnt": 113,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13584,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC60",
    //         "description": "Sensor ocupacion plaza Calle Paterna 27",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4514424,
    //         "longitude": 39.4861796,
    //         "deviceEUI": "0004a30b01041baa",
    //         "appEUI": "App",
    //         "appKEY": "89a1d7eacb524d5c7f01a6bbe238a86a",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 13,
    //         "prevFrameCounterFcnt": 331,
    //         "latestFrameCounterFcnt": 337,
    //         "lostFcnt": 105,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13585,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC61",
    //         "description": "Sensor ocupacion plaza Calle Paterna 28",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4514591,
    //         "longitude": 39.4862034,
    //         "deviceEUI": "0004a30b01043603",
    //         "appEUI": "App",
    //         "appKEY": "d83d1dc85fa32e5ca974edffaff00e84",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 1,
    //         "prevFrameCounterFcnt": 326,
    //         "latestFrameCounterFcnt": 334,
    //         "lostFcnt": 147,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13586,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC62",
    //         "description": "Sensor ocupacion plaza Calle Paterna 29",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4515158,
    //         "longitude": 39.4862495,
    //         "deviceEUI": "0004a30b0103c5d3",
    //         "appEUI": "App",
    //         "appKEY": "9b49abcd6054775f3c8e73f8ce6db4d0",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 3,
    //         "prevFrameCounterFcnt": 331,
    //         "latestFrameCounterFcnt": 332,
    //         "lostFcnt": 146,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13587,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC63",
    //         "description": "Sensor ocupacion plaza Calle Paterna 30",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4515332,
    //         "longitude": 39.4862723,
    //         "deviceEUI": "0004a30b0103e388",
    //         "appEUI": "App",
    //         "appKEY": "ef28d62f92259bedb00e0546c1380d74",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 324,
    //         "latestFrameCounterFcnt": 325,
    //         "lostFcnt": 148,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13588,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC64",
    //         "description": "Sensor ocupacion plaza Calle Paterna 31",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.451558,
    //         "longitude": 39.4862982,
    //         "deviceEUI": "0004a30b0103ab93",
    //         "appEUI": "App",
    //         "appKEY": "3251f51e1767d150ca8c75affc3f6446",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 366,
    //         "latestFrameCounterFcnt": 367,
    //         "lostFcnt": 159,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13589,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC65",
    //         "description": "Sensor ocupacion plaza Calle Paterna 32",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4515715,
    //         "longitude": 39.486321,
    //         "deviceEUI": "0004a30b0103ddc9",
    //         "appEUI": "App",
    //         "appKEY": "49cbbe3ba9fbb5b21eb4a853fdff4c28",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 3,
    //         "prevFrameCounterFcnt": 363,
    //         "latestFrameCounterFcnt": 364,
    //         "lostFcnt": 187,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13590,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC66",
    //         "description": "Sensor ocupacion plaza Calle Paterna 33",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4515829,
    //         "longitude": 39.4863396,
    //         "deviceEUI": "0004a30b010416eb",
    //         "appEUI": "App",
    //         "appKEY": "3affbe65a12d27badcf5ee34d7f2e39d",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 1,
    //         "prevFrameCounterFcnt": 322,
    //         "latestFrameCounterFcnt": 323,
    //         "lostFcnt": 147,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13591,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC67",
    //         "description": "Sensor ocupacion plaza Calle Paterna 34",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4515983,
    //         "longitude": 39.4863609,
    //         "deviceEUI": "0004a30b0104040b",
    //         "appEUI": "App",
    //         "appKEY": "e5d982b8390359ff70010aed1c38ef64",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 1,
    //         "prevFrameCounterFcnt": 291,
    //         "latestFrameCounterFcnt": 292,
    //         "lostFcnt": 115,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13592,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC68",
    //         "description": "Sensor ocupacion plaza Calle Paterna 35",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4516445,
    //         "longitude": 39.4864261,
    //         "deviceEUI": "0004a30b0103a937",
    //         "appEUI": "App",
    //         "appKEY": "66eccb85675ba3af6ec9cdabb07e27b4",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 331,
    //         "latestFrameCounterFcnt": 332,
    //         "lostFcnt": 105,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13593,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC69",
    //         "description": "Sensor ocupacion plaza Calle Paterna 36",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4516647,
    //         "longitude": 39.4864468,
    //         "deviceEUI": "0004a30b0103cb01",
    //         "appEUI": "App",
    //         "appKEY": "e6c6c05a41c93a9f1f08ecad9ac7f576",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 1,
    //         "prevFrameCounterFcnt": 323,
    //         "latestFrameCounterFcnt": 324,
    //         "lostFcnt": 164,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13594,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC70",
    //         "description": "Sensor ocupacion plaza Calle Paterna 37",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4516834,
    //         "longitude": 39.4864649,
    //         "deviceEUI": "0004a30b0104192e",
    //         "appEUI": "App",
    //         "appKEY": "a010443071317ab2dbbdab8eb7b26267",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 351,
    //         "latestFrameCounterFcnt": 352,
    //         "lostFcnt": 37,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13595,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC71",
    //         "description": "Sensor ocupacion plaza Calle Paterna 38",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4517002,
    //         "longitude": 39.4864877,
    //         "deviceEUI": "0004a30b0103af18",
    //         "appEUI": "App",
    //         "appKEY": "0298911914082fee949d8cf07632783d",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 331,
    //         "latestFrameCounterFcnt": 332,
    //         "lostFcnt": 45,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13596,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC72",
    //         "description": "Sensor ocupacion plaza Calle Paterna 39",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4517149,
    //         "longitude": 39.4865063,
    //         "deviceEUI": "0004a30b01041a3e",
    //         "appEUI": "App",
    //         "appKEY": "e57e4d885b048bf0b2ee7ac33e0f1741",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 309,
    //         "latestFrameCounterFcnt": 310,
    //         "lostFcnt": 28,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13597,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC73",
    //         "description": "Sensor ocupacion plaza Calle Paterna 40",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4517505,
    //         "longitude": 39.4865555,
    //         "deviceEUI": "0004a30b0103cd7b",
    //         "appEUI": "App",
    //         "appKEY": "54c2e9b8d3533263a0e1eb12361c2e6c",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 328,
    //         "latestFrameCounterFcnt": 329,
    //         "lostFcnt": 50,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13598,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC74",
    //         "description": "Sensor ocupacion plaza Calle Paterna 41",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4517726,
    //         "longitude": 39.486591,
    //         "deviceEUI": "0004a30b010378da",
    //         "appEUI": "App",
    //         "appKEY": "c5d1dbc9e6739da3697e31ca52d946de",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 308,
    //         "latestFrameCounterFcnt": 309,
    //         "lostFcnt": 21,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13599,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC75",
    //         "description": "Sensor ocupacion plaza Calle Paterna 42",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4512553,
    //         "longitude": 39.4860872,
    //         "deviceEUI": "0004a30b01042663",
    //         "appEUI": "App",
    //         "appKEY": "b2845394d061b7b03a56ae36042593eb",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 1,
    //         "prevFrameCounterFcnt": 302,
    //         "latestFrameCounterFcnt": 303,
    //         "lostFcnt": 63,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13600,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC76",
    //         "description": "Sensor ocupacion plaza Calle Paterna 43",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4512673,
    //         "longitude": 39.4861068,
    //         "deviceEUI": "0004a30b01037897",
    //         "appEUI": "App",
    //         "appKEY": "32de56dfac14fa874e725f114987bd73",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 281,
    //         "latestFrameCounterFcnt": 282,
    //         "lostFcnt": 35,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13601,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC77",
    //         "description": "Sensor ocupacion plaza Calle Paterna 44",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4512787,
    //         "longitude": 39.4861216,
    //         "deviceEUI": "0004a30b01045ef9",
    //         "appEUI": "App",
    //         "appKEY": "cfb98ec1001b2e600dc0d23080def350",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 274,
    //         "latestFrameCounterFcnt": 275,
    //         "lostFcnt": 28,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13602,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC78",
    //         "description": "Sensor ocupacion plaza Calle Paterna 45",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4512895,
    //         "longitude": 39.486142,
    //         "deviceEUI": "0004a30b01041dcf",
    //         "appEUI": "App",
    //         "appKEY": "80888cae9c8b7acc996e31564d781d37",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 348,
    //         "latestFrameCounterFcnt": 349,
    //         "lostFcnt": 125,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13603,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC79",
    //         "description": "Sensor ocupacion plaza Calle Paterna 46",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4513042,
    //         "longitude": 39.4861612,
    //         "deviceEUI": "0004a30b0103c90b",
    //         "appEUI": "App",
    //         "appKEY": "734219a2057421bcacbdad1dc8f1b9b1",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 315,
    //         "latestFrameCounterFcnt": 316,
    //         "lostFcnt": 94,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13604,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC80",
    //         "description": "Sensor ocupacion plaza Calle Paterna 47",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.451321,
    //         "longitude": 39.4861813,
    //         "deviceEUI": "0004a30b01043305",
    //         "appEUI": "App",
    //         "appKEY": "01428971dc260de05d11c614d0cc8643",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 313,
    //         "latestFrameCounterFcnt": 314,
    //         "lostFcnt": 96,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13605,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC81",
    //         "description": "Sensor ocupacion plaza Calle Paterna 48",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4513311,
    //         "longitude": 39.4861989,
    //         "deviceEUI": "0004a30b010451ec",
    //         "appEUI": "App",
    //         "appKEY": "3ebdd2f2adc8202119813383b3c83ee9",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 1,
    //         "prevFrameCounterFcnt": 285,
    //         "latestFrameCounterFcnt": 287,
    //         "lostFcnt": 144,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13606,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC82",
    //         "description": "Sensor ocupacion plaza Calle Paterna 49",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4513425,
    //         "longitude": 39.4862186,
    //         "deviceEUI": "0004a30b01031fc7",
    //         "appEUI": "App",
    //         "appKEY": "51672f67019e6e240f20b5978d41cde9",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 1,
    //         "prevFrameCounterFcnt": 337,
    //         "latestFrameCounterFcnt": 338,
    //         "lostFcnt": 118,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13607,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC83",
    //         "description": "Sensor ocupacion plaza Calle Paterna 50",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4513579,
    //         "longitude": 39.4862326,
    //         "deviceEUI": "0004a30b0103051c",
    //         "appEUI": "App",
    //         "appKEY": "4d649c17ab43ba092b2163f3bb3f83ad",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 1,
    //         "prevFrameCounterFcnt": 336,
    //         "latestFrameCounterFcnt": 337,
    //         "lostFcnt": 78,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13608,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC84",
    //         "description": "Sensor ocupacion plaza Calle Paterna 51",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4513706,
    //         "longitude": 39.4862517,
    //         "deviceEUI": "0004a30b01040059",
    //         "appEUI": "App",
    //         "appKEY": "8efd9259eded13a3e32aa4af32798bd8",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 332,
    //         "latestFrameCounterFcnt": 333,
    //         "lostFcnt": 56,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13609,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC85",
    //         "description": "Sensor ocupacion plaza Calle Paterna 52",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.451386,
    //         "longitude": 39.4862724,
    //         "deviceEUI": "0004a30b0103b07a",
    //         "appEUI": "App",
    //         "appKEY": "82c18ca93da4da7b5c287f06efe3f2b2",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 304,
    //         "latestFrameCounterFcnt": 305,
    //         "lostFcnt": 70,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13610,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC86",
    //         "description": "Sensor ocupacion plaza Calle Paterna 53",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4514008,
    //         "longitude": 39.4862903,
    //         "deviceEUI": "0004a30b0103e581",
    //         "appEUI": "App",
    //         "appKEY": "2b73dbd8f02a74c753333bfb0e1f3274",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 325,
    //         "latestFrameCounterFcnt": 326,
    //         "lostFcnt": 125,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13611,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC87",
    //         "description": "Sensor ocupacion plaza Calle Paterna 54 PMR",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4514176,
    //         "longitude": 39.4863105,
    //         "deviceEUI": "0004a30b0103f7c0",
    //         "appEUI": "App",
    //         "appKEY": "be897d1de81eed11641bd48335b9537d",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 274,
    //         "latestFrameCounterFcnt": 275,
    //         "lostFcnt": 3,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13612,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC88",
    //         "description": "Sensor ocupacion plaza Calle Paterna 55",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4514323,
    //         "longitude": 39.4863245,
    //         "deviceEUI": "0004a30b010440b2",
    //         "appEUI": "App",
    //         "appKEY": "41acb70356f5540fdc4eaaee9504c6ed",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 1,
    //         "prevFrameCounterFcnt": 470,
    //         "latestFrameCounterFcnt": 471,
    //         "lostFcnt": 16,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13613,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC89",
    //         "description": "Sensor ocupacion plaza Calle Paterna 56",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4514437,
    //         "longitude": 39.4863423,
    //         "deviceEUI": "0004a30b0103cfba",
    //         "appEUI": "App",
    //         "appKEY": "7505990c418885ae7de605fe1ac68ce4",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 294,
    //         "latestFrameCounterFcnt": 295,
    //         "lostFcnt": 41,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13614,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC90",
    //         "description": "Sensor ocupacion plaza Calle Paterna 57",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4514558,
    //         "longitude": 39.4863656,
    //         "deviceEUI": "0004a30b0103e542",
    //         "appEUI": "App",
    //         "appKEY": "8ba3ce49ce3bcce2e436b14e2c5a4f5e",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 318,
    //         "latestFrameCounterFcnt": 319,
    //         "lostFcnt": 65,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13615,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC91",
    //         "description": "Sensor ocupacion plaza Calle Paterna 58",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4514732,
    //         "longitude": 39.4863847,
    //         "deviceEUI": "0004a30b0103a8a8",
    //         "appEUI": "App",
    //         "appKEY": "ed0ce44112d2abdbd6e2f2afd923bd6c",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 280,
    //         "latestFrameCounterFcnt": 282,
    //         "lostFcnt": 67,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13616,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC92",
    //         "description": "Sensor ocupacion plaza Calle Paterna 59",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4514873,
    //         "longitude": 39.4864018,
    //         "deviceEUI": "0004a30b010403ac",
    //         "appEUI": "App",
    //         "appKEY": "c74f0e179b6e14102765bf0d177a448a",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 287,
    //         "latestFrameCounterFcnt": 288,
    //         "lostFcnt": 27,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13617,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC93",
    //         "description": "Sensor ocupacion plaza Calle Paterna 60",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4514973,
    //         "longitude": 39.4864194,
    //         "deviceEUI": "0004a30b0103c1d8",
    //         "appEUI": "App",
    //         "appKEY": "f417e5ac48c6c78a9363970e4489dfb9",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 349,
    //         "latestFrameCounterFcnt": 350,
    //         "lostFcnt": 89,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13618,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC94",
    //         "description": "Sensor ocupacion plaza Calle Paterna 61",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4515107,
    //         "longitude": 39.486438,
    //         "deviceEUI": "0004a30b0103df70",
    //         "appEUI": "App",
    //         "appKEY": "c81c9ff824200acb6f6876955fe2cd3f",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 1,
    //         "prevFrameCounterFcnt": 299,
    //         "latestFrameCounterFcnt": 300,
    //         "lostFcnt": 67,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13619,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC95",
    //         "description": "Sensor ocupacion plaza Calle Paterna 62",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4515282,
    //         "longitude": 39.4864556,
    //         "deviceEUI": "0004a30b01041f85",
    //         "appEUI": "App",
    //         "appKEY": "e656e416204d976cd2b75cc9962fa926",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 302,
    //         "latestFrameCounterFcnt": 303,
    //         "lostFcnt": 116,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13620,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC96",
    //         "description": "Sensor ocupacion plaza Calle Paterna 63",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4515396,
    //         "longitude": 39.4864739,
    //         "deviceEUI": "0004a30b01041ecf",
    //         "appEUI": "App",
    //         "appKEY": "8ba5d41a93fe32c04a58d312d74f41fc",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 313,
    //         "latestFrameCounterFcnt": 314,
    //         "lostFcnt": 33,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13621,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC97",
    //         "description": "Sensor ocupacion plaza Calle Paterna 64",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.451555,
    //         "longitude": 39.4864915,
    //         "deviceEUI": "0004a30b010423da",
    //         "appEUI": "App",
    //         "appKEY": "982ddf53940d487080f91debea46a580",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 321,
    //         "latestFrameCounterFcnt": 322,
    //         "lostFcnt": 27,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13622,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC98",
    //         "description": "Sensor ocupacion plaza Calle Paterna 65",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4515644,
    //         "longitude": 39.4865096,
    //         "deviceEUI": "0004a30b01031e13",
    //         "appEUI": "App",
    //         "appKEY": "8a8a2353e9240685e57874d2a3bf8dec",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 1,
    //         "prevFrameCounterFcnt": 350,
    //         "latestFrameCounterFcnt": 351,
    //         "lostFcnt": 68,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13623,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC99",
    //         "description": "Sensor ocupacion plaza Calle Paterna 66 PMR",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4515885,
    //         "longitude": 39.4865262,
    //         "deviceEUI": "0004a30b0103af21",
    //         "appEUI": "App",
    //         "appKEY": "82d5aaad196e25461df0724e2939e63a",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": [],
    //         "meterComponentNames": [],
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 27,
    //         "prevFrameCounterFcnt": 274,
    //         "latestFrameCounterFcnt": 275,
    //         "lostFcnt": -31,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13624,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC100",
    //         "description": "Sensor ocupacion plaza Calle Paterna 67",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4517126,
    //         "longitude": 39.4867035,
    //         "deviceEUI": "0004a30b0103052f",
    //         "appEUI": "App",
    //         "appKEY": "c6a585ef6ad83f468b86c3187a47044f",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 3,
    //         "prevFrameCounterFcnt": 314,
    //         "latestFrameCounterFcnt": 315,
    //         "lostFcnt": 66,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13625,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC101",
    //         "description": "Sensor ocupacion plaza Calle Paterna 68",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4517226,
    //         "longitude": 39.4867257,
    //         "deviceEUI": "0004a30b0103366b",
    //         "appEUI": "App",
    //         "appKEY": "9ed39159704f17c6516543a77738d055",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 312,
    //         "latestFrameCounterFcnt": 313,
    //         "lostFcnt": 35,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13626,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC102",
    //         "description": "Sensor ocupacion plaza Calle Paterna 69",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4517975,
    //         "longitude": 39.4868191,
    //         "deviceEUI": "0004a30b0103c509",
    //         "appEUI": "App",
    //         "appKEY": "a65e851ead11a3d1e173997503453f0f",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 296,
    //         "latestFrameCounterFcnt": 297,
    //         "lostFcnt": 90,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13627,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC103",
    //         "description": "Sensor ocupacion plaza Calle Paterna 70",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4518082,
    //         "longitude": 39.4868408,
    //         "deviceEUI": "0004a30b01033aa1",
    //         "appEUI": "App",
    //         "appKEY": "bca79210190a1759b3a99d7b8867b7c9",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 23,
    //         "prevFrameCounterFcnt": 315,
    //         "latestFrameCounterFcnt": 316,
    //         "lostFcnt": 9,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13628,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC104",
    //         "description": "Sensor ocupacion plaza Calle Paterna 71",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4519075,
    //         "longitude": 39.4867692,
    //         "deviceEUI": "0004a30b01043da4",
    //         "appEUI": "App",
    //         "appKEY": "597297867fdb65ee96992b79ce660018",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 337,
    //         "latestFrameCounterFcnt": 343,
    //         "lostFcnt": 74,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13629,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC105",
    //         "description": "Sensor ocupacion plaza Calle Paterna 72",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4519196,
    //         "longitude": 39.4867857,
    //         "deviceEUI": "0004a30b010376d9",
    //         "appEUI": "App",
    //         "appKEY": "ff9a5e4d100dee9033a888ff47d50566",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": null,
    //         "rssi": null,
    //         "frequency": null,
    //         "firstFrameCounterFcnt": null,
    //         "prevFrameCounterFcnt": null,
    //         "latestFrameCounterFcnt": null,
    //         "lostFcnt": null,
    //         "loraSNR": null,
    //         "networkServerMac": null,
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13630,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC106",
    //         "description": "Sensor ocupacion plaza Calle Paterna 73",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4519293,
    //         "longitude": 39.4868007,
    //         "deviceEUI": "0004a30b01041efb",
    //         "appEUI": "App",
    //         "appKEY": "0bdadc4904864b7a224fba2185565708",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 1,
    //         "prevFrameCounterFcnt": 338,
    //         "latestFrameCounterFcnt": 339,
    //         "lostFcnt": 98,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13631,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC107",
    //         "description": "Sensor ocupacion plaza Calle Paterna 74",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.451942,
    //         "longitude": 39.4868169,
    //         "deviceEUI": "0004a30b01038b3b",
    //         "appEUI": "App",
    //         "appKEY": "21cf288310ceb4500fc90f0e88c8d13c",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 326,
    //         "latestFrameCounterFcnt": 327,
    //         "lostFcnt": 62,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13632,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC108",
    //         "description": "Sensor ocupacion plaza Calle Paterna 75",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4518799,
    //         "longitude": 39.486934,
    //         "deviceEUI": "0004a30b01035c0a",
    //         "appEUI": "App",
    //         "appKEY": "6a4990be739d9ebb905d6d05415e76f5",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 343,
    //         "latestFrameCounterFcnt": 344,
    //         "lostFcnt": 63,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13633,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC109",
    //         "description": "Sensor ocupacion plaza Calle Paterna 76",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4519836,
    //         "longitude": 39.4868723,
    //         "deviceEUI": "0004a30b0102ea7d",
    //         "appEUI": "App",
    //         "appKEY": "494b03b2b55349e0fd72fff16909a24c",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 1,
    //         "prevFrameCounterFcnt": 326,
    //         "latestFrameCounterFcnt": 329,
    //         "lostFcnt": 45,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13634,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC110",
    //         "description": "Sensor ocupacion plaza Calle Paterna 77",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4520634,
    //         "longitude": 39.4869773,
    //         "deviceEUI": "0004a30b0103e5cc",
    //         "appEUI": "App",
    //         "appKEY": "f33327b2fc1a562a74812077e2c4efc4",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 1,
    //         "prevFrameCounterFcnt": 244,
    //         "latestFrameCounterFcnt": 245,
    //         "lostFcnt": 29,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13635,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC111",
    //         "description": "Sensor ocupacion plaza Calle Paterna 78",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.452098,
    //         "longitude": 39.4870276,
    //         "deviceEUI": "0004a30b010403ba",
    //         "appEUI": "App",
    //         "appKEY": "dc327a34eb95bc13d32204e118da3789",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 308,
    //         "latestFrameCounterFcnt": 309,
    //         "lostFcnt": 51,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13636,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC112",
    //         "description": "Sensor ocupacion plaza Calle Paterna 79",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4519694,
    //         "longitude": 39.4870536,
    //         "deviceEUI": "0004a30b0104237c",
    //         "appEUI": "App",
    //         "appKEY": "5d9be376f360da5515acfd05b333e019",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 262,
    //         "latestFrameCounterFcnt": 263,
    //         "lostFcnt": 39,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13637,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC113",
    //         "description": "Sensor ocupacion plaza Calle Paterna 80",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4519802,
    //         "longitude": 39.4870702,
    //         "deviceEUI": "0004a30b0103b32a",
    //         "appEUI": "App",
    //         "appKEY": "e1108e6bbe8f0c92af7f353105d044ce",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 301,
    //         "latestFrameCounterFcnt": 302,
    //         "lostFcnt": 17,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13638,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC114",
    //         "description": "Sensor ocupacion plaza Calle Paterna 81",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4519929,
    //         "longitude": 39.4870898,
    //         "deviceEUI": "0004a30b01040b21",
    //         "appEUI": "App",
    //         "appKEY": "f01558af7a0bd7f70275f2bd4bc0438f",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 300,
    //         "latestFrameCounterFcnt": 301,
    //         "lostFcnt": 14,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13639,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC115",
    //         "description": "Sensor ocupacion plaza Calle Paterna 82",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4520063,
    //         "longitude": 39.4871095,
    //         "deviceEUI": "0004a30b0104251c",
    //         "appEUI": "App",
    //         "appKEY": "76ed7bd7059a143b82566c1889ab688e",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 294,
    //         "latestFrameCounterFcnt": 295,
    //         "lostFcnt": 44,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13640,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC116",
    //         "description": "Sensor ocupacion plaza Calle Paterna 83",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4520251,
    //         "longitude": 39.4871292,
    //         "deviceEUI": "0004a30b0104098f",
    //         "appEUI": "App",
    //         "appKEY": "605e3b630713d0096525ef10a12addec",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 981,
    //         "latestFrameCounterFcnt": 983,
    //         "lostFcnt": 70,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13641,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC117",
    //         "description": "Sensor ocupacion plaza Calle Paterna 84",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4518427,
    //         "longitude": 39.48668,
    //         "deviceEUI": "0004a30b0103f7c2",
    //         "appEUI": "App",
    //         "appKEY": "f2b7e5c8e3e17c2faa58b261727abbd1",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 267,
    //         "latestFrameCounterFcnt": 268,
    //         "lostFcnt": 10,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13642,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC118",
    //         "description": "Sensor ocupacion plaza Calle Paterna 85",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4518561,
    //         "longitude": 39.4867007,
    //         "deviceEUI": "0004a30b0103ff45",
    //         "appEUI": "App",
    //         "appKEY": "c1ff037c32d70cc27fc7737cf223e56c",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 292,
    //         "latestFrameCounterFcnt": 293,
    //         "lostFcnt": 9,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13643,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC119",
    //         "description": "Sensor ocupacion plaza Calle Paterna 86",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4518661,
    //         "longitude": 39.4867162,
    //         "deviceEUI": "0004a30b0103a2df",
    //         "appEUI": "App",
    //         "appKEY": "a3075549df41dc1cc40a8755765bb7b9",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 2,
    //         "prevFrameCounterFcnt": 313,
    //         "latestFrameCounterFcnt": 314,
    //         "lostFcnt": 45,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13644,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC120",
    //         "description": "Sensor ocupacion plaza Calle Paterna 87 PMR",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4518809,
    //         "longitude": 39.4867291,
    //         "deviceEUI": "0004a30b010428c7",
    //         "appEUI": "App",
    //         "appKEY": "6ca41793aa73ed8889b63279d2cd5c6c",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": [],
    //         "meterComponentNames": [],
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 319,
    //         "latestFrameCounterFcnt": 320,
    //         "lostFcnt": 27,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13645,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC121",
    //         "description": "Sensor ocupacion plaza Calle Paterna 88",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4518956,
    //         "longitude": 39.4867483,
    //         "deviceEUI": "0004a30b0104178f",
    //         "appEUI": "App",
    //         "appKEY": "b261e45180f1395bcd7651ccbd208b02",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 327,
    //         "latestFrameCounterFcnt": 328,
    //         "lostFcnt": 44,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13646,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC122",
    //         "description": "Sensor ocupacion plaza Calle Paterna 89",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4519104,
    //         "longitude": 39.4867592,
    //         "deviceEUI": "0004a30b01042065",
    //         "appEUI": "App",
    //         "appKEY": "cff469f997a9e5e4e59e5e7e9f03fdeb",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 297,
    //         "latestFrameCounterFcnt": 301,
    //         "lostFcnt": 82,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13647,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC123",
    //         "description": "Sensor ocupacion plaza Calle Paterna 90",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4519225,
    //         "longitude": 39.4867861,
    //         "deviceEUI": "0004a30b0104177d",
    //         "appEUI": "App",
    //         "appKEY": "6a8daaf826a8a60163d4335e6a634341",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 266,
    //         "latestFrameCounterFcnt": 267,
    //         "lostFcnt": 131,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13648,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC124",
    //         "description": "Sensor ocupacion plaza Calle Paterna 91",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4519325,
    //         "longitude": 39.4868068,
    //         "deviceEUI": "0004a30b01035499",
    //         "appEUI": "App",
    //         "appKEY": "bfc6f9fff2631d62959373a495fe6bd4",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 333,
    //         "latestFrameCounterFcnt": 334,
    //         "lostFcnt": 87,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13649,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC125",
    //         "description": "Sensor ocupacion plaza Calle Paterna 92",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4519486,
    //         "longitude": 39.4868363,
    //         "deviceEUI": "0004a30b0103fd20",
    //         "appEUI": "App",
    //         "appKEY": "2349de1f0477f5fa0cd6dcc1e68fcd14",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 333,
    //         "latestFrameCounterFcnt": 334,
    //         "lostFcnt": 68,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13650,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC126",
    //         "description": "Sensor ocupacion plaza Calle Paterna 93",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4519781,
    //         "longitude": 39.4868777,
    //         "deviceEUI": "0004a30b0103c690",
    //         "appEUI": "App",
    //         "appKEY": "63f084e54e1b29c15a025cafc1f44b35",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 18,
    //         "prevFrameCounterFcnt": 246,
    //         "latestFrameCounterFcnt": 247,
    //         "lostFcnt": -9,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13651,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC127",
    //         "description": "Sensor ocupacion plaza Calle Paterna 94",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4520613,
    //         "longitude": 39.4869807,
    //         "deviceEUI": "0004a30b0103dc83",
    //         "appEUI": "App",
    //         "appKEY": "daf837e83908578b07c1dd0739e9b017",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 351,
    //         "latestFrameCounterFcnt": 352,
    //         "lostFcnt": 55,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13652,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC128",
    //         "description": "Sensor ocupacion plaza Calle Paterna 95",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4520921,
    //         "longitude": 39.4870231,
    //         "deviceEUI": "0004a30b0103cd1a",
    //         "appEUI": "App",
    //         "appKEY": "ef6059db10afbb3896cd3411bad90ab3",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 29,
    //         "prevFrameCounterFcnt": 290,
    //         "latestFrameCounterFcnt": 292,
    //         "lostFcnt": 14,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13653,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC129",
    //         "description": "Sensor ocupacion plaza Calle Paterna 96",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4521055,
    //         "longitude": 39.4870459,
    //         "deviceEUI": "0004a30b010455b6",
    //         "appEUI": "App",
    //         "appKEY": "11efb6a899e1262dce6b5ac35cb5dbc4",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 6,
    //         "prevFrameCounterFcnt": 326,
    //         "latestFrameCounterFcnt": 328,
    //         "lostFcnt": 51,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13654,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC130",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 01",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4519616,
    //         "longitude": 39.4872357,
    //         "deviceEUI": "0004a30b01043fdd",
    //         "appEUI": "App",
    //         "appKEY": "904d5f5c1c0413ea904903e59abaf10d",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 3,
    //         "prevFrameCounterFcnt": 297,
    //         "latestFrameCounterFcnt": 298,
    //         "lostFcnt": 94,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13655,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC131",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 02",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4519227,
    //         "longitude": 39.4872527,
    //         "deviceEUI": "0004a30b0104208c",
    //         "appEUI": "App",
    //         "appKEY": "cb36984cb06fb67a423340941a12751d",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 2,
    //         "prevFrameCounterFcnt": 367,
    //         "latestFrameCounterFcnt": 370,
    //         "lostFcnt": 202,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13656,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC132",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 03",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4518879,
    //         "longitude": 39.4872646,
    //         "deviceEUI": "0004a30b0103f76a",
    //         "appEUI": "App",
    //         "appKEY": "fe3ae720565bb95bd59f25d4cb06de08",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 269,
    //         "latestFrameCounterFcnt": 270,
    //         "lostFcnt": 198,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13657,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC133",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 04",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4518537,
    //         "longitude": 39.4872843,
    //         "deviceEUI": "0004a30b0103aefc",
    //         "appEUI": "App",
    //         "appKEY": "12524902b6d811e9d09c29aa9d610caf",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 272,
    //         "latestFrameCounterFcnt": 275,
    //         "lostFcnt": 92,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13658,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC134",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 05",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4517464,
    //         "longitude": 39.4873329,
    //         "deviceEUI": "0004a30b01043ec1",
    //         "appEUI": "App",
    //         "appKEY": "d9d40db4be1186d45721483395420083",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 343,
    //         "latestFrameCounterFcnt": 346,
    //         "lostFcnt": 220,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13659,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC135",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 06",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4517108,
    //         "longitude": 39.487349,
    //         "deviceEUI": "0004a30b01033e52",
    //         "appEUI": "App",
    //         "appKEY": "1d72560d402ad4ca7ad7bbfd55c44928",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 373,
    //         "latestFrameCounterFcnt": 374,
    //         "lostFcnt": 190,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13660,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC136",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 07",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4516813,
    //         "longitude": 39.4873645,
    //         "deviceEUI": "0004a30b0103cfc2",
    //         "appEUI": "App",
    //         "appKEY": "9944d3818859200f25a7f5a716924ec5",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 2,
    //         "prevFrameCounterFcnt": 542,
    //         "latestFrameCounterFcnt": 543,
    //         "lostFcnt": 381,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13661,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC137",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 08",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4516478,
    //         "longitude": 39.4873826,
    //         "deviceEUI": "0004a30b0103e5e7",
    //         "appEUI": "App",
    //         "appKEY": "abc89b7e684c84b98032874831043ac3",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 269,
    //         "latestFrameCounterFcnt": 270,
    //         "lostFcnt": 76,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13662,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC138",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 09",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4516149,
    //         "longitude": 39.4873982,
    //         "deviceEUI": "0004a30b0103ec6f",
    //         "appEUI": "App",
    //         "appKEY": "47ad1363e4497ead3c28f1dcf10939f2",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 3,
    //         "prevFrameCounterFcnt": 228,
    //         "latestFrameCounterFcnt": 283,
    //         "lostFcnt": 210,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13663,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC139",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 10",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4515801,
    //         "longitude": 39.4874111,
    //         "deviceEUI": "0004a30b0103ff06",
    //         "appEUI": "App",
    //         "appKEY": "fb30807c8e96954e0a3ed28230a9637b",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 1,
    //         "prevFrameCounterFcnt": 322,
    //         "latestFrameCounterFcnt": 323,
    //         "lostFcnt": 129,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13664,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC140",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 11",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4515512,
    //         "longitude": 39.487423,
    //         "deviceEUI": "0004a30b01045182",
    //         "appEUI": "App",
    //         "appKEY": "07901ab03984d302fe0f414dc36b83b7",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 321,
    //         "latestFrameCounterFcnt": 322,
    //         "lostFcnt": 78,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13665,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC141",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 12",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4515164,
    //         "longitude": 39.4874344,
    //         "deviceEUI": "0004a30b0103e5e9",
    //         "appEUI": "App",
    //         "appKEY": "bd6a7d9f454da86a93fb43fc1e9bc19a",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 315,
    //         "latestFrameCounterFcnt": 316,
    //         "lostFcnt": 59,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13666,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC142",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 13",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4514681,
    //         "longitude": 39.4874473,
    //         "deviceEUI": "0004a30b010320cd",
    //         "appEUI": "App",
    //         "appKEY": "25ef1915355d827bbe9dc62a7dd364b5",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 314,
    //         "latestFrameCounterFcnt": 315,
    //         "lostFcnt": 40,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13667,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC143",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 14",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4515244,
    //         "longitude": 39.4875001,
    //         "deviceEUI": "0004a30b01044d94",
    //         "appEUI": "App",
    //         "appKEY": "361285f99797d7924806a3f170112d97",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 1,
    //         "prevFrameCounterFcnt": 320,
    //         "latestFrameCounterFcnt": 321,
    //         "lostFcnt": 64,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13668,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC144",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 15",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4515539,
    //         "longitude": 39.4874866,
    //         "deviceEUI": "0004a30b010422c5",
    //         "appEUI": "App",
    //         "appKEY": "7d00ca2cbde9acfd9e3f9487b9a8588d",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 280,
    //         "latestFrameCounterFcnt": 281,
    //         "lostFcnt": 43,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13669,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC145",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 16",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4515799,
    //         "longitude": 39.4874763,
    //         "deviceEUI": "0004a30b01035922",
    //         "appEUI": "App",
    //         "appKEY": "dd41a4ca88bf4a586219d3fbde36ec30",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 297,
    //         "latestFrameCounterFcnt": 298,
    //         "lostFcnt": 89,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13670,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC146",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 17",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4516175,
    //         "longitude": 39.4874613,
    //         "deviceEUI": "0004a30b0103709d",
    //         "appEUI": "App",
    //         "appKEY": "f90583816d22337715a88993dc24b324",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 373,
    //         "latestFrameCounterFcnt": 374,
    //         "lostFcnt": 179,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13671,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC147",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 18",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4516523,
    //         "longitude": 39.4874432,
    //         "deviceEUI": "0004a30b010372dd",
    //         "appEUI": "App",
    //         "appKEY": "3858dc8271644bdbeb7d57150f1f9949",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 331,
    //         "latestFrameCounterFcnt": 332,
    //         "lostFcnt": 107,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13672,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC148",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 19",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4516812,
    //         "longitude": 39.4874287,
    //         "deviceEUI": "0004a30b0103ae97",
    //         "appEUI": "App",
    //         "appKEY": "470997ab6a87f3abbbea75aeabe08023",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 1,
    //         "prevFrameCounterFcnt": 94,
    //         "latestFrameCounterFcnt": 98,
    //         "lostFcnt": 58,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13673,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC149",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 20",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4517187,
    //         "longitude": 39.4874173,
    //         "deviceEUI": "0004a30b01041848",
    //         "appEUI": "App",
    //         "appKEY": "44603ab97a74a37ee0feb1a63915a154",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 314,
    //         "latestFrameCounterFcnt": 321,
    //         "lostFcnt": 126,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13674,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC150",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 21",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4517542,
    //         "longitude": 39.4874007,
    //         "deviceEUI": "0004a30b0103203a",
    //         "appEUI": "App",
    //         "appKEY": "17e062e025140b16978a356d5976bbd6",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 2,
    //         "prevFrameCounterFcnt": 299,
    //         "latestFrameCounterFcnt": 300,
    //         "lostFcnt": 104,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13675,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC151",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 22",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4517844,
    //         "longitude": 39.4873878,
    //         "deviceEUI": "0004a30b01035d4a",
    //         "appEUI": "App",
    //         "appKEY": "b2e9343ff1c9e0c0aee308a82b61c652",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 324,
    //         "latestFrameCounterFcnt": 326,
    //         "lostFcnt": 68,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13676,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC152",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 23",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4518186,
    //         "longitude": 39.4873718,
    //         "deviceEUI": "0004a30b0102eae0",
    //         "appEUI": "App",
    //         "appKEY": "41dffb4a55a7e003e6bd09320bc96322",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 251,
    //         "latestFrameCounterFcnt": 252,
    //         "lostFcnt": 62,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13677,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC153",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 24",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4518474,
    //         "longitude": 39.4873588,
    //         "deviceEUI": "0004a30b010370a9",
    //         "appEUI": "App",
    //         "appKEY": "8c2b5149f7b60f9d4e1b61e9b51a164a",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 374,
    //         "latestFrameCounterFcnt": 376,
    //         "lostFcnt": 179,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13678,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC154",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 25",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4518749,
    //         "longitude": 39.4873454,
    //         "deviceEUI": "0004a30b0104370c",
    //         "appEUI": "App",
    //         "appKEY": "d4682fe96744fbe6343b4174f4525440",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": null,
    //         "rssi": null,
    //         "frequency": null,
    //         "firstFrameCounterFcnt": null,
    //         "prevFrameCounterFcnt": null,
    //         "latestFrameCounterFcnt": null,
    //         "lostFcnt": null,
    //         "loraSNR": null,
    //         "networkServerMac": null,
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13679,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC155",
    //         "description": "Sensor ocupacion plaza Calle San Cayetano 26 PMR",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4519078,
    //         "longitude": 39.4873283,
    //         "deviceEUI": "0004a30b010304f2",
    //         "appEUI": "App",
    //         "appKEY": "65bcc97038f94dc3b54b89d1287a6b9c",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 46,
    //         "prevFrameCounterFcnt": 397,
    //         "latestFrameCounterFcnt": 398,
    //         "lostFcnt": 45,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13680,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC156",
    //         "description": "Sensor ocupacion plaza Calle Juan Ramón 16 Cordón",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.45226,
    //         "longitude": 39.48773,
    //         "deviceEUI": "0004a30b010376bc",
    //         "appEUI": "App",
    //         "appKEY": "43794c781aad911ffdea7a05dfa27324",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 418,
    //         "latestFrameCounterFcnt": 419,
    //         "lostFcnt": 25,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13681,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC157",
    //         "description": "Sensor ocupacion plaza Calle Juan Ramón 17 Cordón",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.45224,
    //         "longitude": 39.48775,
    //         "deviceEUI": "0004a30b01035dd2",
    //         "appEUI": "App",
    //         "appKEY": "9a60d17c836901ee3d56374d12b0b791",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 363,
    //         "latestFrameCounterFcnt": 364,
    //         "lostFcnt": 12,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13682,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC158",
    //         "description": "Sensor ocupacion plaza Calle Juan Ramón 18 Cordón",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.45222,
    //         "longitude": 39.48778,
    //         "deviceEUI": "0004a30b010370e4",
    //         "appEUI": "App",
    //         "appKEY": "908325b32d57cc626abf892ea098d401",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 5,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 355,
    //         "latestFrameCounterFcnt": 356,
    //         "lostFcnt": 4,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13683,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC159",
    //         "description": "Sensor ocupacion plaza Calle Juan Ramón 19 Cordón",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.45219,
    //         "longitude": 39.4878,
    //         "deviceEUI": "0004a30b0104062a",
    //         "appEUI": "App",
    //         "appKEY": "c2c932811ae6b49a4da6258c8de3e625",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 314,
    //         "latestFrameCounterFcnt": 315,
    //         "lostFcnt": 9,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13684,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC160",
    //         "description": "Sensor ocupacion plaza Calle Juan Ramón 20 Cordón",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4521,
    //         "longitude": 39.48782,
    //         "deviceEUI": "0004a30b010434c0",
    //         "appEUI": "App",
    //         "appKEY": "1f255c9b7f64de7f00fe7a26c4904632",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 285,
    //         "latestFrameCounterFcnt": 286,
    //         "lostFcnt": 11,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13685,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC161",
    //         "description": "Sensor ocupacion plaza Calle Juan Ramón 21 Cordón",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.45199,
    //         "longitude": 39.48783,
    //         "deviceEUI": "0004a30b01043a34",
    //         "appEUI": "App",
    //         "appKEY": "8bac4311a6c6340d47af01c159cdbf4d",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 277,
    //         "latestFrameCounterFcnt": 278,
    //         "lostFcnt": 4,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13686,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC162",
    //         "description": "Sensor ocupacion plaza Calle Juan Ramón 22 Cordón",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.45195,
    //         "longitude": 39.48783,
    //         "deviceEUI": "0004a30b0103db1d",
    //         "appEUI": "App",
    //         "appKEY": "704b0afe0d57319048314de7b1c8c51c",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 438,
    //         "latestFrameCounterFcnt": 439,
    //         "lostFcnt": 31,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13687,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC163",
    //         "description": "Sensor ocupacion plaza Calle Juan Ramón 23 Cordón",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.4519,
    //         "longitude": 39.48784,
    //         "deviceEUI": "0004a30b0102fef6",
    //         "appEUI": "App",
    //         "appKEY": "dd5968a4c3ba38e8d997b66c9b55e85b",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 328,
    //         "latestFrameCounterFcnt": 329,
    //         "lostFcnt": 43,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13688,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC164",
    //         "description": "Sensor ocupacion plaza Calle Juan Ramón 24 Cordón",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.45177,
    //         "longitude": 39.48783,
    //         "deviceEUI": "0004a30b0103d063",
    //         "appEUI": "App",
    //         "appKEY": "fabafac0aaba00af435b1805194d3f20",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 1,
    //         "prevFrameCounterFcnt": 332,
    //         "latestFrameCounterFcnt": 333,
    //         "lostFcnt": 53,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13689,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC165",
    //         "description": "Sensor ocupacion plaza Calle Juan Ramón 25 Cordón",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.45173,
    //         "longitude": 39.48783,
    //         "deviceEUI": "0004a30b010437b9",
    //         "appEUI": "App",
    //         "appKEY": "452da15ce18e3c59f162165c0d8965e9",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868300000,
    //         "firstFrameCounterFcnt": 21,
    //         "prevFrameCounterFcnt": 317,
    //         "latestFrameCounterFcnt": 318,
    //         "lostFcnt": -26,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13690,
    //         "gatewaysId": null,
    //         "name": "PARKINGSPOTMASPPC166",
    //         "description": "Sensor ocupacion plaza Calle Juan Ramón 26 Cordón",
    //         "provider": null,
    //         "userId": 166,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-21",
    //         "applicationName": null,
    //         "latitude": -0.45168,
    //         "longitude": 39.48782,
    //         "deviceEUI": "0004a30b01032046",
    //         "appEUI": "App",
    //         "appKEY": "a2b1871a68e91c83bc823f2f0953a5cb",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": null,
    //         "meterComponentNames": null,
    //         "decoderServers": null,
    //         "dr": 0,
    //         "rssi": null,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": 0,
    //         "prevFrameCounterFcnt": 319,
    //         "latestFrameCounterFcnt": 320,
    //         "lostFcnt": 26,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eb920a92",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13691,
    //         "gatewaysId": null,
    //         "name": "Temperature_Sensor_40",
    //         "description": "Casa de la Música",
    //         "provider": null,
    //         "userId": 123,
    //         "typeSensor": "",
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2022-12-26",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "141f98d33680bd40",
    //         "appEUI": null,
    //         "appKEY": "06263577C3BBC9EB2FEF99135EF0F6A5",
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": null,
    //         "inputComponentNames": [],
    //         "meterComponentNames": [],
    //         "decoderServers": null,
    //         "dr": 5,
    //         "rssi": -111,
    //         "frequency": 868500000,
    //         "firstFrameCounterFcnt": null,
    //         "prevFrameCounterFcnt": 69544,
    //         "latestFrameCounterFcnt": 69545,
    //         "lostFcnt": 14015,
    //         "loraSNR": 4,
    //         "networkServerMac": "e45f0144a565",
    //         "messageTime": null
    //     },
    //     {
    //         "id": 13692,
    //         "gatewaysId": null,
    //         "name": "Display_Led_7008",
    //         "description": "181f3c71bff07008",
    //         "provider": null,
    //         "userId": 122,
    //         "typeSensor": null,
    //         "sensorTypeInfo": {
    //             "id": null,
    //             "name": null,
    //             "description": null,
    //             "sensorType": null,
    //             "componentType": null,
    //             "applicationName": null
    //         },
    //         "sensorModelName": null,
    //         "installationDate": "2023-01-02",
    //         "applicationName": null,
    //         "latitude": null,
    //         "longitude": null,
    //         "deviceEUI": "181f3c71bff07008",
    //         "appEUI": null,
    //         "appKEY": null,
    //         "encryptionKey": null,
    //         "sensorFromPort": null,
    //         "sensorFromChirpstack": "chirpstack",
    //         "inputComponentNames": [],
    //         "meterComponentNames": [],
    //         "decoderServers": null,
    //         "dr": 3,
    //         "rssi": null,
    //         "frequency": 868100000,
    //         "firstFrameCounterFcnt": 179,
    //         "prevFrameCounterFcnt": 58398,
    //         "latestFrameCounterFcnt": 58399,
    //         "lostFcnt": 328,
    //         "loraSNR": null,
    //         "networkServerMac": "b827eba1dcdb",
    //         "messageTime": null
    //     },
    //     [
    // {
    //     "id": 107,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e566_sensor",
    //     "description": "ibox sensor",
    //     "provider": null,
    //     "userId": 32,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-06-15",
    //     "applicationName": null,
    //     "latitude": 39.120249,
    //     "longitude": -0.454043,
    //     "deviceEUI": "0079e129d522e566",
    //     "appEUI": "12",
    //     "appKEY": "12",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 197579,
    //     "prevFrameCounterFcnt": 1409,
    //     "latestFrameCounterFcnt": 1409,
    //     "lostFcnt": -778212,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb3f1042",
    //     "messageTime": null
    // },
    // {
    //     "id": 108,
    //     "gatewaysId": null,
    //     "name": "testIbox",
    //     "description": "For testing All",
    //     "provider": null,
    //     "userId": 32,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-06-18",
    //     "applicationName": null,
    //     "latitude": 39.427242,
    //     "longitude": -1.114482,
    //     "deviceEUI": "0079e129d522e634",
    //     "appEUI": "12",
    //     "appKEY": "12",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "inputcmp1"
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": "metercmp1"
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 145,
    //     "prevFrameCounterFcnt": 186,
    //     "latestFrameCounterFcnt": 187,
    //     "lostFcnt": -162678,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb3330b1",
    //     "messageTime": null
    // },
    // {
    //     "id": 109,
    //     "gatewaysId": null,
    //     "name": "Sensor_ibox_Alberto",
    //     "description": "IBOX TEST",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "16",
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-06-24",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e566",
    //     "appEUI": "06263577c3bbc9eb",
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "DoorC01"
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": "EnergyMeterVIPAC01"
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 197579,
    //     "prevFrameCounterFcnt": 1409,
    //     "latestFrameCounterFcnt": 1409,
    //     "lostFcnt": -778212,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb3f1042",
    //     "messageTime": null
    // },
    // {
    //     "id": 110,
    //     "gatewaysId": null,
    //     "name": "IBOX1_Biblio",
    //     "description": "iBOX_GESWAT_e647",
    //     "provider": null,
    //     "userId": 36,
    //     "typeSensor": "20",
    //     "sensorTypeInfo": {
    //         "id": 20,
    //         "name": "IBOX-PUERTA-TEST",
    //         "description": "Puerta TEST",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-06-26",
    //     "applicationName": null,
    //     "latitude": 39.567724,
    //     "longitude": -0.622264,
    //     "deviceEUI": "0079e129d522e647",
    //     "appEUI": "06263577c3bbc9eb",
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb66bea3,b827eb6c4e09,dca63214b1d0,b827eb11f2e1",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 115,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e631_doorLibrary",
    //     "description": "iBOX_GESWAT_e631",
    //     "provider": null,
    //     "userId": 77,
    //     "typeSensor": "89",
    //     "sensorTypeInfo": {
    //         "id": 89,
    //         "name": "Olmos_ibox",
    //         "description": null,
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-07-15",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e631",
    //     "appEUI": null,
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": "b827eb524297",
    //     "dr": 3,
    //     "rssi": -125,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 682,
    //     "latestFrameCounterFcnt": 1494,
    //     "lostFcnt": -322249,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb524297",
    //     "messageTime": null
    // },
    // {
    //     "id": 117,
    //     "gatewaysId": null,
    //     "name": "iBOX_Biblioteca",
    //     "description": "Quatretonda iBox Biblioteca",
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": "21",
    //     "sensorTypeInfo": {
    //         "id": 21,
    //         "name": "iBOX_Puerta",
    //         "description": "Ibox Biblioteca",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-07-19",
    //     "applicationName": null,
    //     "latitude": 38.945463,
    //     "longitude": -0.399479,
    //     "deviceEUI": "0079e129d522e629",
    //     "appEUI": "06263577c3bbc9eb",
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "EnergyMeterQUAGWTC01"
    //         },
    //         {
    //             "inputComponent": "EnergyMeterQUAGWTC02"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -57,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 5066,
    //     "prevFrameCounterFcnt": 4681,
    //     "latestFrameCounterFcnt": 4682,
    //     "lostFcnt": -784374,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 118,
    //     "gatewaysId": null,
    //     "name": "iBOX_Ayuntamiento",
    //     "description": "iBOX Ayuntamiento",
    //     "provider": null,
    //     "userId": 41,
    //     "typeSensor": "22",
    //     "sensorTypeInfo": {
    //         "id": 22,
    //         "name": "iBOX_Ayuntamiento",
    //         "description": "iBOX Ayuntamiento",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-07-19",
    //     "applicationName": null,
    //     "latitude": 38.853991,
    //     "longitude": -0.502074,
    //     "deviceEUI": "0079e129d522e630",
    //     "appEUI": "06263577c3bbc9eb",
    //     "appKEY": "06263577c3bbc9eb2fef99135ef0f6a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "EnergyMeterEARCTMC01"
    //         },
    //         {
    //             "inputComponent": "EnergyMeterEARCTMC02"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -98,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 326,
    //     "prevFrameCounterFcnt": 2833,
    //     "latestFrameCounterFcnt": 2834,
    //     "lostFcnt": -753301,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827ebf0f17b",
    //     "messageTime": null
    // },
    // {
    //     "id": 121,
    //     "gatewaysId": null,
    //     "name": "171fe129d522e000_wifi",
    //     "description": "Wifi Sensor",
    //     "provider": null,
    //     "userId": 36,
    //     "typeSensor": "27",
    //     "sensorTypeInfo": {
    //         "id": 27,
    //         "name": "171fe129d522e000_wifi_Type",
    //         "description": null,
    //         "sensorType": "wifi",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-08-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "171fe129d522e000",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb66bea3,b827eb6c4e09,dca63214b1d0,b827eb11f2e1",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 123,
    //     "gatewaysId": null,
    //     "name": "Alberto_Puerta",
    //     "description": "Test puerta",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "21",
    //     "sensorTypeInfo": {
    //         "id": 21,
    //         "name": "iBOX_Puerta",
    //         "description": "Ibox Biblioteca",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-10-06",
    //     "applicationName": null,
    //     "latitude": 39.592868,
    //     "longitude": -0.544343,
    //     "deviceEUI": "0079e129d522e630",
    //     "appEUI": "06263577C3BBC9EB2FEF99135EF0F6A5",
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "DoorC01"
    //         },
    //         {
    //             "inputComponent": "DoorC02"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -98,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 326,
    //     "prevFrameCounterFcnt": 2833,
    //     "latestFrameCounterFcnt": 2834,
    //     "lostFcnt": -753301,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827ebf0f17b",
    //     "messageTime": null
    // },
    // {
    //     "id": 124,
    //     "gatewaysId": null,
    //     "name": "DCSWAGWS0000000008",
    //     "description": "water castellon itron",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "30",
    //     "sensorTypeInfo": {
    //         "id": 30,
    //         "name": "water_itron",
    //         "description": "for water",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-11-25",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "532253811817804e",
    //     "appEUI": "00000000000000000",
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 125,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e613_N",
    //     "description": "0079e129d522e613_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e613",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 126,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e614_N",
    //     "description": "0079e129d522e614_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e614",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 127,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e615_N",
    //     "description": "0079e129d522e615_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e615",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 128,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e616_N",
    //     "description": "0079e129d522e616_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e616",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -90,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 3118,
    //     "prevFrameCounterFcnt": 2,
    //     "latestFrameCounterFcnt": 3,
    //     "lostFcnt": -739446,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca632143f21",
    //     "messageTime": null
    // },
    // {
    //     "id": 129,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e617_N",
    //     "description": "0079e129d522e617_desc",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "15",
    //     "sensorTypeInfo": {
    //         "id": 15,
    //         "name": "Ibox01",
    //         "description": "Ibox Type Sensor",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2020-12-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e617",
    //     "appEUI": null,
    //     "appKEY": "00000000000000000000000000000000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -107,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2313,
    //     "prevFrameCounterFcnt": 2437,
    //     "latestFrameCounterFcnt": 2438,
    //     "lostFcnt": -1993127,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4ae486",
    //     "messageTime": null
    // },
    // {
    //     "id": 131,
    //     "gatewaysId": null,
    //     "name": "0079e129d522e621_ibox",
    //     "description": null,
    //     "provider": null,
    //     "userId": 52,
    //     "typeSensor": "32",
    //     "sensorTypeInfo": {
    //         "id": 32,
    //         "name": "0079e129d522e621_ibox_Type",
    //         "description": null,
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-08",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0079e129d522e621",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 136,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC01",
    //     "description": "Jaume-Calafate-1",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-07",
    //     "applicationName": null,
    //     "latitude": 39.097538,
    //     "longitude": -0.630113,
    //     "deviceEUI": "04b6480450034109",
    //     "appEUI": "app",
    //     "appKEY": "7F612E785A07E056205E9799861F619C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 326,
    //     "prevFrameCounterFcnt": 929,
    //     "latestFrameCounterFcnt": 951,
    //     "lostFcnt": 322,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 141,
    //     "gatewaysId": null,
    //     "name": "171fe129d522e001_wifi",
    //     "description": null,
    //     "provider": null,
    //     "userId": 54,
    //     "typeSensor": "41",
    //     "sensorTypeInfo": {
    //         "id": 41,
    //         "name": "171fe129d522e001_wifi_Type",
    //         "description": null,
    //         "sensorType": "wifi",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-11",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "171fe129d522e001",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -105,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 2345,
    //     "prevFrameCounterFcnt": 128854,
    //     "latestFrameCounterFcnt": 128855,
    //     "lostFcnt": -691832,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb481840",
    //     "messageTime": null
    // },
    // {
    //     "id": 142,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC02",
    //     "description": "Jaume-Calafate-3",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-07",
    //     "applicationName": null,
    //     "latitude": 39.097584,
    //     "longitude": -0.630043,
    //     "deviceEUI": "04b6480450034057",
    //     "appEUI": "app",
    //     "appKEY": "CEF522F5F799AE91D158D7DCD72F8B7D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 296,
    //     "prevFrameCounterFcnt": 899,
    //     "latestFrameCounterFcnt": 900,
    //     "lostFcnt": 243,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 143,
    //     "gatewaysId": null,
    //     "name": "Sensor de Oficina Pruebas",
    //     "description": "para probar en el laboratorio",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "42",
    //     "sensorTypeInfo": {
    //         "id": 42,
    //         "name": "ibox prueba OFININA",
    //         "description": "para probar en el laboratorio",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-02-14",
    //     "applicationName": null,
    //     "latitude": 39.594463203832,
    //     "longitude": -0.55226997501051,
    //     "deviceEUI": "a079e129d522e624",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "door sensor"
    //         },
    //         {
    //             "inputComponent": "current sensor"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 146,
    //     "gatewaysId": null,
    //     "name": "METEOVINSWTC01",
    //     "description": null,
    //     "provider": null,
    //     "userId": 54,
    //     "typeSensor": "47",
    //     "sensorTypeInfo": {
    //         "id": 47,
    //         "name": "METEOGESWAT1",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "Modbus"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-03-11",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "141f3c71bff07fae",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -88,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1511,
    //     "prevFrameCounterFcnt": 1561,
    //     "latestFrameCounterFcnt": 1562,
    //     "lostFcnt": -904149,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb481840",
    //     "messageTime": null
    // },
    // {
    //     "id": 147,
    //     "gatewaysId": null,
    //     "name": "BOYAVINSWTC01",
    //     "description": null,
    //     "provider": null,
    //     "userId": 54,
    //     "typeSensor": "48",
    //     "sensorTypeInfo": {
    //         "id": 48,
    //         "name": "BOYAGESWAT",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-03-12",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "0004a30b00f1a9ee",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 78,
    //     "latestFrameCounterFcnt": 79,
    //     "lostFcnt": -5,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb481840",
    //     "messageTime": null
    // },
    // {
    //     "id": 153,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC45",
    //     "description": "Escoleta-54",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-03-31",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034036",
    //     "appEUI": null,
    //     "appKEY": "50AEDDE4585EA2F15399040CF9C5B791",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -71,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 305,
    //     "prevFrameCounterFcnt": 816,
    //     "latestFrameCounterFcnt": 817,
    //     "lostFcnt": 110,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 154,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC04",
    //     "description": "Jaime-calafate-5",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-04-19",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033985",
    //     "appEUI": null,
    //     "appKEY": "5351D39D5D4182D65182C06735052FED",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 316,
    //     "prevFrameCounterFcnt": 536,
    //     "latestFrameCounterFcnt": 548,
    //     "lostFcnt": 71,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 155,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC05",
    //     "description": "Jaime-calafate-7",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-04-19",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034063",
    //     "appEUI": "2",
    //     "appKEY": "74CE37EAA32F093DE218F3577385C655",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 920,
    //     "latestFrameCounterFcnt": 934,
    //     "lostFcnt": 322,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 156,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC06",
    //     "description": "Jaime-calafate-9",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-04-19",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033996",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 342,
    //     "prevFrameCounterFcnt": 893,
    //     "latestFrameCounterFcnt": 895,
    //     "lostFcnt": 156,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 174,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-11",
    //     "description": "ibox instalado en el taller con dev eui a0......611",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-19",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e611",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 175,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-13",
    //     "description": "ibox instalado en el taller con dev eui a0......613",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-19",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e613",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 176,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-15",
    //     "description": "ibox instalado en el taller con dev eui a0......615",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-19",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e615",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 178,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-17",
    //     "description": "a079e129d522e617",
    //     "provider": null,
    //     "userId": 124,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-18",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e617",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -33,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 170,
    //     "latestFrameCounterFcnt": 171,
    //     "lostFcnt": -99540,
    //     "loraSNR": 7,
    //     "networkServerMac": "e45f016c7dbc",
    //     "messageTime": null
    // },
    // {
    //     "id": 179,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-19",
    //     "description": "ibox instalado en el taller con dev eui a0......619",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-18",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e619",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 180,
    //     "gatewaysId": null,
    //     "name": "ibox_taller_a0-21",
    //     "description": "ibox instalado en el taller con dev eui a0......621",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "51",
    //     "sensorTypeInfo": {
    //         "id": 51,
    //         "name": "sensor_pruebas_taller",
    //         "description": "sensores de prueba de taller",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-18",
    //     "applicationName": null,
    //     "latitude": 39.5880143,
    //     "longitude": -0.5540687,
    //     "deviceEUI": "a079e129d522e621",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 181,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_plazadelacreu",
    //     "description": "ibox intalado en foios en la plaza de la creu en un cuadro en el parque",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5364359,
    //     "longitude": -0.3560338,
    //     "deviceEUI": "0079e129d522e609",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1925,
    //     "prevFrameCounterFcnt": 26,
    //     "latestFrameCounterFcnt": 27,
    //     "lostFcnt": -48751,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 182,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_alcaldefranciscoroceto",
    //     "description": "ibox instalado en mitad de una carretera unidireccional en la calle alcalde francisco roceto",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5359263,
    //     "longitude": -0.356997,
    //     "deviceEUI": "0079e129d522e607",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 558,
    //     "prevFrameCounterFcnt": 39,
    //     "latestFrameCounterFcnt": 41,
    //     "lostFcnt": -73661,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 183,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_ayuntamiento",
    //     "description": "0079e129d522e644",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5376272,
    //     "longitude": -0.3601489,
    //     "deviceEUI": "0079e129d522e644",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -124,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7134,
    //     "prevFrameCounterFcnt": 47,
    //     "latestFrameCounterFcnt": 48,
    //     "lostFcnt": -296818,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 184,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_sarieta",
    //     "description": "ibox  instalado en foios en la calle sarieta en el extremo del pueblo",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.539585,
    //     "longitude": -0.3599087,
    //     "deviceEUI": "0079e129d522e646",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 931,
    //     "prevFrameCounterFcnt": 3368,
    //     "latestFrameCounterFcnt": 3369,
    //     "lostFcnt": -66724,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 185,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_agenciaocupacio",
    //     "description": "ibox instalado en foios en el circulo concentrico exterior al lado de la casa de la ocupacio",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5404003,
    //     "longitude": -0.359261,
    //     "deviceEUI": "0079e129d522e641",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -124,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 2875,
    //     "prevFrameCounterFcnt": 4607,
    //     "latestFrameCounterFcnt": 4608,
    //     "lostFcnt": -106188,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 186,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_regnevalencia",
    //     "description": "ibox instalado en foios en el extremo oeste en la calle regne valencia",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5384722,
    //     "longitude": -0.3622443,
    //     "deviceEUI": "0079e129d522e640",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 302,
    //     "latestFrameCounterFcnt": 344,
    //     "lostFcnt": -702,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827ebf9a086",
    //     "messageTime": null
    // },
    // {
    //     "id": 187,
    //     "gatewaysId": null,
    //     "name": "ibox_foios_caminolacruz",
    //     "description": "ibx instalado en foios en el extremo suroeste en la calle camino de la cruz",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "50",
    //     "sensorTypeInfo": {
    //         "id": 50,
    //         "name": "Ibox_Foios",
    //         "description": "grupo de ibox instalados en foios",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-16",
    //     "applicationName": null,
    //     "latitude": 39.5338611,
    //     "longitude": -0.3573831,
    //     "deviceEUI": "0079e129d522e634",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 145,
    //     "prevFrameCounterFcnt": 186,
    //     "latestFrameCounterFcnt": 187,
    //     "lostFcnt": -162678,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb3330b1",
    //     "messageTime": null
    // },
    // {
    //     "id": 189,
    //     "gatewaysId": null,
    //     "name": "Cuadro a.p AE",
    //     "description": "extremo este cercano a burguer king",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-23",
    //     "applicationName": null,
    //     "latitude": 39.424622,
    //     "longitude": -0.40511,
    //     "deviceEUI": "0079e129d522e615",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 190,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_bar",
    //     "description": "ibox al este de paiporta cerca de un bar",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-23",
    //     "applicationName": null,
    //     "latitude": 39.4252645,
    //     "longitude": -0.4089387,
    //     "deviceEUI": "0079e129d522e616",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -90,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 3118,
    //     "prevFrameCounterFcnt": 2,
    //     "latestFrameCounterFcnt": 3,
    //     "lostFcnt": -739446,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca632143f21",
    //     "messageTime": null
    // },
    // {
    //     "id": 191,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_parque",
    //     "description": "ibox al este de paiporta cerca de un parque",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4255038,
    //     "longitude": -0.4108358,
    //     "deviceEUI": "0079e129d522e617",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -107,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2313,
    //     "prevFrameCounterFcnt": 2437,
    //     "latestFrameCounterFcnt": 2438,
    //     "lostFcnt": -1993127,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4ae486",
    //     "messageTime": null
    // },
    // {
    //     "id": 192,
    //     "gatewaysId": null,
    //     "name": "Ibox_Paiporta_Cedisa",
    //     "description": "ibox paiporta en poligono cerca de taller de motos",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4274159,
    //     "longitude": -0.4065676,
    //     "deviceEUI": "0079e129d522e618",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 193,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_faitanar",
    //     "description": "ibox paiporta poligono calle faitanar a mitad",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4286756,
    //     "longitude": -0.4060418,
    //     "deviceEUI": "0079e129d522e620",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 5,
    //     "rssi": -63,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 18,
    //     "latestFrameCounterFcnt": 0,
    //     "lostFcnt": -776,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca632143f21",
    //     "messageTime": null
    // },
    // {
    //     "id": 194,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_lasenia",
    //     "description": "ibox paiporta al sureste en la salida del colegio la senia",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4212418,
    //     "longitude": -0.4151241,
    //     "deviceEUI": "0079e129d522e612",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 195,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_independencia",
    //     "description": "ibox paiporta al noroeste en la calle independencia",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4305412,
    //     "longitude": -0.4217051,
    //     "deviceEUI": "0079e129d522e622",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 197,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_maximiliano",
    //     "description": "ibox paiporta al norte en la calle maximiliano",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4333949,
    //     "longitude": -0.414235,
    //     "deviceEUI": "0079e129d522e625",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 198,
    //     "gatewaysId": null,
    //     "name": "Ibox_Paiporta_Primitiva",
    //     "description": "ibox en el centro norte calle banda primitiva",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4284961,
    //     "longitude": -0.4135304,
    //     "deviceEUI": "0079e129d522e619",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 199,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_acequia",
    //     "description": "ibox paiporta al este en la calle acequia",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4294963,
    //     "longitude": -0.4032379,
    //     "deviceEUI": "0079e129d522e621",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 200,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_acequiadequart",
    //     "description": "ibox paiporta al noreste calle acequia de quart",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4309699,
    //     "longitude": -0.4043883,
    //     "deviceEUI": "0079e129d522e624",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 201,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_acequiafaitanar",
    //     "description": "prueba_taller",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4336567,
    //     "longitude": -0.4040864,
    //     "deviceEUI": "0079e129d522e626",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": 3,
    //     "rssi": -65,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 4592,
    //     "prevFrameCounterFcnt": 692,
    //     "latestFrameCounterFcnt": 696,
    //     "lostFcnt": -83622,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb4ae486",
    //     "messageTime": null
    // },
    // {
    //     "id": 202,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_ausiasmarch",
    //     "description": "ibos paiporta centro noroeste en calle ausias march",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4306035,
    //     "longitude": -0.416513,
    //     "deviceEUI": "0079e129d522e623",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 203,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_quart",
    //     "description": "ibox paiporta al suroeste en calle quart de poblet",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4226541,
    //     "longitude": -0.4174941,
    //     "deviceEUI": "0079e129d522e613",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 204,
    //     "gatewaysId": null,
    //     "name": "Ibox_paiporta_polideportivo",
    //     "description": "ibox paiporta  al sur instalado dentro del polideportivo",
    //     "provider": null,
    //     "userId": 58,
    //     "typeSensor": "54",
    //     "sensorTypeInfo": {
    //         "id": 54,
    //         "name": "ibox paiorta",
    //         "description": "ibox en paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-05-04",
    //     "applicationName": null,
    //     "latitude": 39.4247485,
    //     "longitude": -0.4143086,
    //     "deviceEUI": "0079e129d522e614",
    //     "appEUI": null,
    //     "appKEY": " 06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 209,
    //     "gatewaysId": null,
    //     "name": "a079e129d522e616",
    //     "description": "a079e129d522e611",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "31",
    //     "sensorTypeInfo": {
    //         "id": 31,
    //         "name": "iBOX_Paiporta",
    //         "description": "IBOX Ayto. Paiporta",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "a079e129d522e611",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": null
    //         },
    //         {
    //             "inputComponent": null
    //         }
    //     ],
    //     "meterComponentNames": [
    //         {
    //             "meterComponent": null
    //         }
    //     ],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 210,
    //     "gatewaysId": null,
    //     "name": "a079e129d522e616",
    //     "description": "ibox instalado en paiporta al este del centro al lado de un burguerking",
    //     "provider": null,
    //     "userId": null,
    //     "typeSensor": "20",
    //     "sensorTypeInfo": {
    //         "id": 20,
    //         "name": "IBOX-PUERTA-TEST",
    //         "description": "Puerta TEST",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-01",
    //     "applicationName": null,
    //     "latitude": 39.422413,
    //     "longitude": -0.405982,
    //     "deviceEUI": "a079e129d522e616",
    //     "appEUI": null,
    //     "appKEY": "06 26 35 77 c3 bb c9 eb 2f ef 99 13 5e f0 f6 a5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [
    //         {
    //             "inputComponent": "a"
    //         },
    //         {
    //             "inputComponent": "b"
    //         },
    //         {
    //             "inputComponent": "c"
    //         }
    //     ],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 220,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC01",
    //     "description": "constitucion-20",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bd5",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 290,
    //     "prevFrameCounterFcnt": 1339,
    //     "latestFrameCounterFcnt": 1340,
    //     "lostFcnt": 320,
    //     "loraSNR": -16,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 222,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC03",
    //     "description": "constitucion-14",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bd4",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 608,
    //     "prevFrameCounterFcnt": 1668,
    //     "latestFrameCounterFcnt": 1669,
    //     "lostFcnt": 211,
    //     "loraSNR": -12,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 223,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC05",
    //     "description": "constitucion-30",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bd3",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 59,
    //     "prevFrameCounterFcnt": 803,
    //     "latestFrameCounterFcnt": 804,
    //     "lostFcnt": 124,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 224,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC06",
    //     "description": "constitucion-36",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a83f",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 50,
    //     "prevFrameCounterFcnt": 28,
    //     "latestFrameCounterFcnt": 34,
    //     "lostFcnt": -26,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 225,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC09",
    //     "description": "Gandia-29",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a841",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": -112,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 276,
    //     "prevFrameCounterFcnt": 564,
    //     "latestFrameCounterFcnt": 565,
    //     "lostFcnt": -187,
    //     "loraSNR": -13,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 226,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC10",
    //     "description": "Gandia-28",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872776b9",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 9,
    //     "prevFrameCounterFcnt": 55,
    //     "latestFrameCounterFcnt": 1,
    //     "lostFcnt": -67,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 227,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC13",
    //     "description": "convento-51",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872776b8",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 674,
    //     "latestFrameCounterFcnt": 675,
    //     "lostFcnt": 278,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 228,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC15",
    //     "description": "Convento-34",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a397",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 312,
    //     "prevFrameCounterFcnt": 813,
    //     "latestFrameCounterFcnt": 814,
    //     "lostFcnt": -240,
    //     "loraSNR": -18,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 229,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC03",
    //     "description": "Jaime-calafate-2acc",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450031548",
    //     "appEUI": "2",
    //     "appKEY": "C5B55C8B19A92D638F6B67F376110B06",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 311,
    //     "prevFrameCounterFcnt": 1064,
    //     "latestFrameCounterFcnt": 1068,
    //     "lostFcnt": 516,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 230,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC07",
    //     "description": "Jaime-calafate-2",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033989",
    //     "appEUI": "2",
    //     "appKEY": "23435DA6A2AEAC81381E5737A2FF1E6A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 299,
    //     "prevFrameCounterFcnt": 851,
    //     "latestFrameCounterFcnt": 852,
    //     "lostFcnt": 163,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 231,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC12",
    //     "description": "Rei-en-Jaume-14",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034077",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 284,
    //     "prevFrameCounterFcnt": 807,
    //     "latestFrameCounterFcnt": 808,
    //     "lostFcnt": 108,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 232,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC13",
    //     "description": "Rei-en-Jaume-16",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033968",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 286,
    //     "prevFrameCounterFcnt": 834,
    //     "latestFrameCounterFcnt": 835,
    //     "lostFcnt": 189,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 233,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC14",
    //     "description": "Rei-en-Jaume-18",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034101",
    //     "appEUI": "2",
    //     "appKEY": "9BFC7FFB32842F714F940742B515F57D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 303,
    //     "prevFrameCounterFcnt": 835,
    //     "latestFrameCounterFcnt": 836,
    //     "lostFcnt": 138,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 234,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC15",
    //     "description": "Rei-en-Jaume-22",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033917",
    //     "appEUI": "2",
    //     "appKEY": "BEA29B6ACDB3E8F7C2251D8CAEFABB95",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 279,
    //     "prevFrameCounterFcnt": 794,
    //     "latestFrameCounterFcnt": 795,
    //     "lostFcnt": 119,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 235,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC16",
    //     "description": "Rei-en-Jaume-24",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033982",
    //     "appEUI": "app",
    //     "appKEY": "3A29D9FB7ED836C8048ACA3AE368357F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 299,
    //     "prevFrameCounterFcnt": 815,
    //     "latestFrameCounterFcnt": 816,
    //     "lostFcnt": 110,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 236,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC17",
    //     "description": "Rei-en-Jaume-26",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033916",
    //     "appEUI": "app",
    //     "appKEY": "4503E4F51C69D7D6EF3B0C2871CA8241",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 297,
    //     "prevFrameCounterFcnt": 862,
    //     "latestFrameCounterFcnt": 863,
    //     "lostFcnt": 185,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 237,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC18",
    //     "description": "Rei-en-Jaume-30",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033993",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 795,
    //     "latestFrameCounterFcnt": 796,
    //     "lostFcnt": 93,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 238,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC19",
    //     "description": "Rei-en-JAume-44",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034016",
    //     "appEUI": "app",
    //     "appKEY": "0767D7630F287DB586B99FF4B086EF95",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -99,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 790,
    //     "latestFrameCounterFcnt": 791,
    //     "lostFcnt": 94,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 239,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC21",
    //     "description": "Escoleta-80",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034108",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 310,
    //     "prevFrameCounterFcnt": 809,
    //     "latestFrameCounterFcnt": 810,
    //     "lostFcnt": 84,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 240,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC22",
    //     "description": "Escoleta-78",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034031",
    //     "appEUI": null,
    //     "appKEY": "22F8B490D8D0318B12C1961F1F48A772",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -78,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 286,
    //     "prevFrameCounterFcnt": 785,
    //     "latestFrameCounterFcnt": 786,
    //     "lostFcnt": 87,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 241,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC23",
    //     "description": "Escoleta-79",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034040",
    //     "appEUI": "app",
    //     "appKEY": "3E42B8A57822B1C82C5A63BD396ECECD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -95,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 294,
    //     "prevFrameCounterFcnt": 810,
    //     "latestFrameCounterFcnt": 811,
    //     "lostFcnt": 88,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 242,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC24",
    //     "description": "Escoleta-81-A",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033955",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -83,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 270,
    //     "prevFrameCounterFcnt": 757,
    //     "latestFrameCounterFcnt": 758,
    //     "lostFcnt": 81,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 243,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC25",
    //     "description": "Escoleta-77",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033936",
    //     "appEUI": "app",
    //     "appKEY": "8A2BDBE3960C9C476EAA9D57617E6965",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 318,
    //     "prevFrameCounterFcnt": 836,
    //     "latestFrameCounterFcnt": 837,
    //     "lostFcnt": 116,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 244,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC26",
    //     "description": "Escoleta-76-bajo",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028716",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -79,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 294,
    //     "prevFrameCounterFcnt": 783,
    //     "latestFrameCounterFcnt": 784,
    //     "lostFcnt": 87,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 245,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC27",
    //     "description": "Escoleta-76-1",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034095",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -87,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 274,
    //     "prevFrameCounterFcnt": 763,
    //     "latestFrameCounterFcnt": 764,
    //     "lostFcnt": 85,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 246,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC28",
    //     "description": "Escoleta-74",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034110",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -97,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 299,
    //     "prevFrameCounterFcnt": 802,
    //     "latestFrameCounterFcnt": 803,
    //     "lostFcnt": 95,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 247,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC29",
    //     "description": "Escoleta-72",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033952",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -91,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 278,
    //     "prevFrameCounterFcnt": 751,
    //     "latestFrameCounterFcnt": 752,
    //     "lostFcnt": 78,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 248,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC30",
    //     "description": "Escoleta-75",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033925",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 312,
    //     "prevFrameCounterFcnt": 836,
    //     "latestFrameCounterFcnt": 837,
    //     "lostFcnt": 102,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 249,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC31",
    //     "description": "Escoleta-73",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033986",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -85,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 288,
    //     "prevFrameCounterFcnt": 803,
    //     "latestFrameCounterFcnt": 804,
    //     "lostFcnt": 100,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 250,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC32",
    //     "description": "Escoleta-71",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033942",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 271,
    //     "prevFrameCounterFcnt": 784,
    //     "latestFrameCounterFcnt": 785,
    //     "lostFcnt": 98,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 251,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC33",
    //     "description": "Escoleta-67",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034038",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 290,
    //     "prevFrameCounterFcnt": 811,
    //     "latestFrameCounterFcnt": 812,
    //     "lostFcnt": 109,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 252,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC34",
    //     "description": "Escoleta-65",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033979",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -78,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 776,
    //     "latestFrameCounterFcnt": 777,
    //     "lostFcnt": 81,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 253,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC35",
    //     "description": "Escoleta-63",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033970",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -84,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 298,
    //     "prevFrameCounterFcnt": 796,
    //     "latestFrameCounterFcnt": 797,
    //     "lostFcnt": 89,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 254,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC36",
    //     "description": "Escoleta-70",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032093",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -97,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 300,
    //     "prevFrameCounterFcnt": 823,
    //     "latestFrameCounterFcnt": 825,
    //     "lostFcnt": 119,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 255,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC37",
    //     "description": "Esoleta-68",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032107",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 294,
    //     "prevFrameCounterFcnt": 782,
    //     "latestFrameCounterFcnt": 783,
    //     "lostFcnt": 82,
    //     "loraSNR": 12,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 256,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC38",
    //     "description": "Escoleta-66",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034001",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -95,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 277,
    //     "prevFrameCounterFcnt": 800,
    //     "latestFrameCounterFcnt": 801,
    //     "lostFcnt": 113,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 257,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC39",
    //     "description": "Escoleta-64",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034033",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 295,
    //     "prevFrameCounterFcnt": 807,
    //     "latestFrameCounterFcnt": 808,
    //     "lostFcnt": 109,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 258,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC40",
    //     "description": "Escoleta-62",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034037",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -103,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 291,
    //     "prevFrameCounterFcnt": 778,
    //     "latestFrameCounterFcnt": 779,
    //     "lostFcnt": 79,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 259,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC41",
    //     "description": "Escoleta-60",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034010",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -77,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 294,
    //     "prevFrameCounterFcnt": 805,
    //     "latestFrameCounterFcnt": 806,
    //     "lostFcnt": 113,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 260,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC42",
    //     "description": "Escoleta-58",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034074",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -74,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 268,
    //     "prevFrameCounterFcnt": 746,
    //     "latestFrameCounterFcnt": 747,
    //     "lostFcnt": 78,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 261,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC43",
    //     "description": "Escoleta-56",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034013",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -72,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 302,
    //     "prevFrameCounterFcnt": 838,
    //     "latestFrameCounterFcnt": 839,
    //     "lostFcnt": 119,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 262,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC44",
    //     "description": "Escoleta-52",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033997",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -79,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 282,
    //     "prevFrameCounterFcnt": 771,
    //     "latestFrameCounterFcnt": 772,
    //     "lostFcnt": 75,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 263,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC46",
    //     "description": "Escoleta-61",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034000",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 791,
    //     "latestFrameCounterFcnt": 792,
    //     "lostFcnt": 98,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 264,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC47",
    //     "description": "Escoleta-59",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034003",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -102,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 790,
    //     "latestFrameCounterFcnt": 791,
    //     "lostFcnt": 85,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 265,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC48",
    //     "description": "Escoleta-48",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034088",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -89,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 297,
    //     "prevFrameCounterFcnt": 813,
    //     "latestFrameCounterFcnt": 815,
    //     "lostFcnt": 102,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 266,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC49",
    //     "description": "Escoleta-57B",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033972",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -100,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 290,
    //     "prevFrameCounterFcnt": 799,
    //     "latestFrameCounterFcnt": 800,
    //     "lostFcnt": 108,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 267,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC50",
    //     "description": "Escoleta-57",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034071",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -97,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 282,
    //     "prevFrameCounterFcnt": 798,
    //     "latestFrameCounterFcnt": 799,
    //     "lostFcnt": 99,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 268,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC51",
    //     "description": "Escoleta-46",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033957",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -80,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 268,
    //     "prevFrameCounterFcnt": 753,
    //     "latestFrameCounterFcnt": 754,
    //     "lostFcnt": 76,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 269,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC52",
    //     "description": "Escoleta-44",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034115",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 277,
    //     "prevFrameCounterFcnt": 760,
    //     "latestFrameCounterFcnt": 761,
    //     "lostFcnt": 81,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 270,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC54",
    //     "description": "Escoleta-43",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033991",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 786,
    //     "latestFrameCounterFcnt": 787,
    //     "lostFcnt": 100,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 271,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC55",
    //     "description": "Escoleta-38",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034082",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -106,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 266,
    //     "prevFrameCounterFcnt": 741,
    //     "latestFrameCounterFcnt": 742,
    //     "lostFcnt": 76,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 272,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC56",
    //     "description": "Escoleta-36",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034072",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 279,
    //     "prevFrameCounterFcnt": 752,
    //     "latestFrameCounterFcnt": 753,
    //     "lostFcnt": 79,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 273,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC57",
    //     "description": "Escoleta-41",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033912",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -102,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 306,
    //     "prevFrameCounterFcnt": 797,
    //     "latestFrameCounterFcnt": 798,
    //     "lostFcnt": 95,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 274,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC58",
    //     "description": "Escoleta-34",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034070",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -105,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 775,
    //     "latestFrameCounterFcnt": 776,
    //     "lostFcnt": 92,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 275,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC59",
    //     "description": "Escoleta-39",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033976",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 302,
    //     "prevFrameCounterFcnt": 805,
    //     "latestFrameCounterFcnt": 806,
    //     "lostFcnt": 95,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 276,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC60",
    //     "description": "Escoleta-37",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033974",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 799,
    //     "latestFrameCounterFcnt": 800,
    //     "lostFcnt": 100,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 277,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC61",
    //     "description": "Escoleta-32",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032095",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 304,
    //     "prevFrameCounterFcnt": 804,
    //     "latestFrameCounterFcnt": 805,
    //     "lostFcnt": 93,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 278,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC62",
    //     "description": "Escoleta-30",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034015",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 301,
    //     "prevFrameCounterFcnt": 789,
    //     "latestFrameCounterFcnt": 790,
    //     "lostFcnt": 99,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 279,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC63",
    //     "description": "Escoleta-28",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032067",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 292,
    //     "prevFrameCounterFcnt": 823,
    //     "latestFrameCounterFcnt": 824,
    //     "lostFcnt": 136,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 280,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC64",
    //     "description": "Escoleta-35",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034024",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 800,
    //     "latestFrameCounterFcnt": 801,
    //     "lostFcnt": 105,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 281,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC65",
    //     "description": "Escoleta-24",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033964",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 277,
    //     "prevFrameCounterFcnt": 799,
    //     "latestFrameCounterFcnt": 800,
    //     "lostFcnt": 113,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 282,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC66",
    //     "description": "Escoleta-22",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034043",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 286,
    //     "prevFrameCounterFcnt": 859,
    //     "latestFrameCounterFcnt": 861,
    //     "lostFcnt": 160,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 283,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC67",
    //     "description": "Escoleta-29",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034102",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 280,
    //     "prevFrameCounterFcnt": 795,
    //     "latestFrameCounterFcnt": 796,
    //     "lostFcnt": 105,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 284,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC68",
    //     "description": "Escoleta-20",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033963",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -110,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 295,
    //     "prevFrameCounterFcnt": 834,
    //     "latestFrameCounterFcnt": 835,
    //     "lostFcnt": 138,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 285,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC70",
    //     "description": "Escoleta-23",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034034",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -111,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 282,
    //     "prevFrameCounterFcnt": 819,
    //     "latestFrameCounterFcnt": 820,
    //     "lostFcnt": 135,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 286,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC69",
    //     "description": "Escoleta-27",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034096",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 279,
    //     "prevFrameCounterFcnt": 810,
    //     "latestFrameCounterFcnt": 811,
    //     "lostFcnt": 138,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 287,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC71",
    //     "description": "Escoleta-21",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034049",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 280,
    //     "prevFrameCounterFcnt": 797,
    //     "latestFrameCounterFcnt": 798,
    //     "lostFcnt": 105,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 288,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC72",
    //     "description": "Escoleta-19",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033959",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 282,
    //     "prevFrameCounterFcnt": 788,
    //     "latestFrameCounterFcnt": 789,
    //     "lostFcnt": 96,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 289,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC73",
    //     "description": "Escoleta-16",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034094",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 288,
    //     "prevFrameCounterFcnt": 771,
    //     "latestFrameCounterFcnt": 772,
    //     "lostFcnt": 77,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 290,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC74",
    //     "description": "Escoleta-14",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034114",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 303,
    //     "prevFrameCounterFcnt": 811,
    //     "latestFrameCounterFcnt": 812,
    //     "lostFcnt": 113,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 291,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC75",
    //     "description": "Escoleta-15",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033967",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 270,
    //     "prevFrameCounterFcnt": 811,
    //     "latestFrameCounterFcnt": 812,
    //     "lostFcnt": 145,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 292,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC76",
    //     "description": "Escoleta-13",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034014",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 820,
    //     "latestFrameCounterFcnt": 821,
    //     "lostFcnt": 153,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 293,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC77",
    //     "description": "Escoleta-12",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033929",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 330,
    //     "prevFrameCounterFcnt": 878,
    //     "latestFrameCounterFcnt": 879,
    //     "lostFcnt": 140,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 294,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC78",
    //     "description": "Escoleta-10",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034112",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 270,
    //     "prevFrameCounterFcnt": 786,
    //     "latestFrameCounterFcnt": 787,
    //     "lostFcnt": 117,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 295,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC79",
    //     "description": "Escoleta-8",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034064",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 301,
    //     "prevFrameCounterFcnt": 806,
    //     "latestFrameCounterFcnt": 807,
    //     "lostFcnt": 104,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 296,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC80",
    //     "description": "Escoleta-11",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034027",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 299,
    //     "prevFrameCounterFcnt": 806,
    //     "latestFrameCounterFcnt": 807,
    //     "lostFcnt": 89,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 297,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC81",
    //     "description": "Escoleta-6",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033998",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 293,
    //     "prevFrameCounterFcnt": 825,
    //     "latestFrameCounterFcnt": 827,
    //     "lostFcnt": 117,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 298,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC82",
    //     "description": "Escoleta-4",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034019",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 788,
    //     "latestFrameCounterFcnt": 789,
    //     "lostFcnt": 94,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 299,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC83",
    //     "description": "Escoleta-3",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034059",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 281,
    //     "prevFrameCounterFcnt": 794,
    //     "latestFrameCounterFcnt": 795,
    //     "lostFcnt": 126,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 300,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC84",
    //     "description": "Escoleta-5",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034104",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 808,
    //     "latestFrameCounterFcnt": 809,
    //     "lostFcnt": 117,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 301,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC85",
    //     "description": "Escoleta-7",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033914",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -108,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 307,
    //     "prevFrameCounterFcnt": 823,
    //     "latestFrameCounterFcnt": 824,
    //     "lostFcnt": 104,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 302,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC86",
    //     "description": "Escoleta9",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450045529",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 303,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC87",
    //     "description": "Escoleta-69",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034091",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 777,
    //     "latestFrameCounterFcnt": 778,
    //     "lostFcnt": 81,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 304,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC88",
    //     "description": "Escoleta-74bis",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034021",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -82,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 273,
    //     "prevFrameCounterFcnt": 793,
    //     "latestFrameCounterFcnt": 794,
    //     "lostFcnt": 97,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 305,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC89",
    //     "description": "Escoleta-42",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033949",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 271,
    //     "prevFrameCounterFcnt": 763,
    //     "latestFrameCounterFcnt": 764,
    //     "lostFcnt": 85,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 306,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC90",
    //     "description": "Escoleta-53",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034009",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -103,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 313,
    //     "prevFrameCounterFcnt": 841,
    //     "latestFrameCounterFcnt": 842,
    //     "lostFcnt": 122,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 307,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC91",
    //     "description": "Escoleta-45",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034011",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 288,
    //     "prevFrameCounterFcnt": 791,
    //     "latestFrameCounterFcnt": 792,
    //     "lostFcnt": 94,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 308,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC92",
    //     "description": "Escoleta-25",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033948",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 290,
    //     "prevFrameCounterFcnt": 840,
    //     "latestFrameCounterFcnt": 841,
    //     "lostFcnt": 172,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 309,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC93",
    //     "description": "Rei-en-Jaume-10",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033924",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 331,
    //     "prevFrameCounterFcnt": 1029,
    //     "latestFrameCounterFcnt": 1030,
    //     "lostFcnt": 385,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 310,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC94",
    //     "description": "Escoleta-81",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034012",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 286,
    //     "prevFrameCounterFcnt": 780,
    //     "latestFrameCounterFcnt": 781,
    //     "lostFcnt": 92,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 311,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC08",
    //     "description": "ReiJaume6",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450045530",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 312,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC09",
    //     "description": "ReiJaume6Bj",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450045765",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 313,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC10",
    //     "description": "ReienJaume-8",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034079",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 265,
    //     "prevFrameCounterFcnt": 869,
    //     "latestFrameCounterFcnt": 871,
    //     "lostFcnt": 259,
    //     "loraSNR": -6,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 314,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC11",
    //     "description": "ReienJaume-12",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034047",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 922,
    //     "latestFrameCounterFcnt": 923,
    //     "lostFcnt": 311,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 315,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC95",
    //     "description": "Escoleta-49",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028569",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 261,
    //     "prevFrameCounterFcnt": 765,
    //     "latestFrameCounterFcnt": 766,
    //     "lostFcnt": 102,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 316,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC96",
    //     "description": "Escoleta-47",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450032074",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 254,
    //     "prevFrameCounterFcnt": 793,
    //     "latestFrameCounterFcnt": 794,
    //     "lostFcnt": 136,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 317,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC97",
    //     "description": "Escoleta-40",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034039",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 285,
    //     "prevFrameCounterFcnt": 785,
    //     "latestFrameCounterFcnt": 788,
    //     "lostFcnt": 94,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 318,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC98",
    //     "description": "Escoleta-31",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034060",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -108,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 264,
    //     "prevFrameCounterFcnt": 746,
    //     "latestFrameCounterFcnt": 747,
    //     "lostFcnt": 83,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 319,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC100",
    //     "description": "Escoleta-1",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033981",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 276,
    //     "prevFrameCounterFcnt": 938,
    //     "latestFrameCounterFcnt": 949,
    //     "lostFcnt": 391,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 320,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC99",
    //     "description": "Escoleta-33",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450034076",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 267,
    //     "prevFrameCounterFcnt": 784,
    //     "latestFrameCounterFcnt": 785,
    //     "lostFcnt": 95,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 321,
    //     "gatewaysId": null,
    //     "name": "WaterExternalSURGWTC53",
    //     "description": "Escoleta-55",
    //     "provider": null,
    //     "userId": 53,
    //     "typeSensor": "33",
    //     "sensorTypeInfo": {
    //         "id": 33,
    //         "name": "Zenner",
    //         "description": "Zenner",
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450033965",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb8fe7a4",
    //     "dr": 0,
    //     "rssi": -103,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 286,
    //     "prevFrameCounterFcnt": 798,
    //     "latestFrameCounterFcnt": 799,
    //     "lostFcnt": 89,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb8fe7a4",
    //     "messageTime": null
    // },
    // {
    //     "id": 322,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC18",
    //     "description": "Convento-15",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a82e",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 22,
    //     "latestFrameCounterFcnt": 23,
    //     "lostFcnt": 15,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 323,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC19",
    //     "description": "Convento-6",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a829",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 2,
    //     "rssi": -114,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 275,
    //     "prevFrameCounterFcnt": 801,
    //     "latestFrameCounterFcnt": 802,
    //     "lostFcnt": -145,
    //     "loraSNR": -20,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 324,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC20",
    //     "description": "Gandia-3",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a82a",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 2,
    //     "rssi": -112,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 297,
    //     "prevFrameCounterFcnt": 1357,
    //     "latestFrameCounterFcnt": 1358,
    //     "lostFcnt": 325,
    //     "loraSNR": -14,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 325,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC23",
    //     "description": "Gandia-47",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a831",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 58,
    //     "prevFrameCounterFcnt": 133,
    //     "latestFrameCounterFcnt": 138,
    //     "lostFcnt": -49,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 326,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC24",
    //     "description": "San-Francesc-7",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a46",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 336,
    //     "prevFrameCounterFcnt": 72,
    //     "latestFrameCounterFcnt": 73,
    //     "lostFcnt": -770,
    //     "loraSNR": -17,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 327,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC27",
    //     "description": "San-Cristobal-15",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a41",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -112,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 629,
    //     "prevFrameCounterFcnt": 1690,
    //     "latestFrameCounterFcnt": 1691,
    //     "lostFcnt": 298,
    //     "loraSNR": -11,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 328,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC28",
    //     "description": "Pl-Cristo-Empar-7",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a3c",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 5,
    //     "rssi": -110,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 607,
    //     "prevFrameCounterFcnt": 1632,
    //     "latestFrameCounterFcnt": 1633,
    //     "lostFcnt": 181,
    //     "loraSNR": -1,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 329,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC31",
    //     "description": "Nou-4",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a23",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 3,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 627,
    //     "prevFrameCounterFcnt": 1649,
    //     "latestFrameCounterFcnt": 1650,
    //     "lostFcnt": 149,
    //     "loraSNR": -13,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 330,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC32",
    //     "description": "Nou-1-1",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a35",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 595,
    //     "prevFrameCounterFcnt": 1607,
    //     "latestFrameCounterFcnt": 1613,
    //     "lostFcnt": 685,
    //     "loraSNR": -11,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 331,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC33",
    //     "description": "Nou-1-2",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a22",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 3,
    //     "rssi": -112,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 136,
    //     "prevFrameCounterFcnt": 1160,
    //     "latestFrameCounterFcnt": 1161,
    //     "lostFcnt": 492,
    //     "loraSNR": -18,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 332,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC36",
    //     "description": "Nou-10",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a58",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 2,
    //     "rssi": -115,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 598,
    //     "prevFrameCounterFcnt": 1629,
    //     "latestFrameCounterFcnt": 1630,
    //     "lostFcnt": 325,
    //     "loraSNR": -19,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 333,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC37",
    //     "description": "Nou-24",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a59",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 29,
    //     "latestFrameCounterFcnt": 30,
    //     "lostFcnt": 16,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 334,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC40",
    //     "description": "Xativa-25",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a5c",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 335,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC41",
    //     "description": "Xativa-42",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a60",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 336,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC42",
    //     "description": "Xativa-60",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bbe",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 337,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC44",
    //     "description": "CronistaCaspir-32",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a47",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": 56,
    //     "lostFcnt": 55,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 338,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC45",
    //     "description": "CronistaCaspir-17",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bc3",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 51,
    //     "prevFrameCounterFcnt": 58,
    //     "latestFrameCounterFcnt": 16,
    //     "lostFcnt": -47,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 339,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC48",
    //     "description": "CronistaCaspir-19",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a4a",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 118,
    //     "prevFrameCounterFcnt": 30,
    //     "latestFrameCounterFcnt": 38,
    //     "lostFcnt": -319,
    //     "loraSNR": -18,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 340,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC49",
    //     "description": "CronistaCaspir-11",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a4b",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 278,
    //     "prevFrameCounterFcnt": 395,
    //     "latestFrameCounterFcnt": 404,
    //     "lostFcnt": -46,
    //     "loraSNR": -19,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 341,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC52",
    //     "description": "CronistaCapsir-6",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bcd",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 119,
    //     "prevFrameCounterFcnt": 182,
    //     "latestFrameCounterFcnt": 183,
    //     "lostFcnt": -237,
    //     "loraSNR": -20,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 342,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC54",
    //     "description": "JoseIranzo-57",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a0b",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 343,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC56",
    //     "description": "CronistaCapsir-4-2",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a1e",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": -112,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 271,
    //     "prevFrameCounterFcnt": 572,
    //     "latestFrameCounterFcnt": 575,
    //     "lostFcnt": 152,
    //     "loraSNR": -18,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 344,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC59",
    //     "description": "Valencia-23-2",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a1c",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 43,
    //     "prevFrameCounterFcnt": 5,
    //     "latestFrameCounterFcnt": 6,
    //     "lostFcnt": -44,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 345,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC60",
    //     "description": "Pl-lom-5",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a1a",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 3,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 605,
    //     "prevFrameCounterFcnt": 1678,
    //     "latestFrameCounterFcnt": 1679,
    //     "lostFcnt": 263,
    //     "loraSNR": -13,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 346,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC62",
    //     "description": "Pl-Roca-3",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a1b",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 5,
    //     "rssi": -109,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 595,
    //     "prevFrameCounterFcnt": 1656,
    //     "latestFrameCounterFcnt": 1657,
    //     "lostFcnt": 276,
    //     "loraSNR": 0,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 347,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC63",
    //     "description": "Font-1",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a3f",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 276,
    //     "prevFrameCounterFcnt": 39,
    //     "latestFrameCounterFcnt": 40,
    //     "lostFcnt": -635,
    //     "loraSNR": -16,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 348,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC66",
    //     "description": "Font-10",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a37",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 2,
    //     "rssi": -115,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 293,
    //     "prevFrameCounterFcnt": 1290,
    //     "latestFrameCounterFcnt": 1291,
    //     "lostFcnt": 590,
    //     "loraSNR": -12,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 349,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC67",
    //     "description": "Romaldo-Soler-Miquel-20",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a36",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 5,
    //     "rssi": -108,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 594,
    //     "prevFrameCounterFcnt": 1668,
    //     "latestFrameCounterFcnt": 1669,
    //     "lostFcnt": 259,
    //     "loraSNR": 1,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 350,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC70",
    //     "description": "JoseIranzo-36-2",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a39",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 5,
    //     "rssi": -112,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 608,
    //     "prevFrameCounterFcnt": 1637,
    //     "latestFrameCounterFcnt": 1651,
    //     "lostFcnt": 357,
    //     "loraSNR": -3,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 351,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC71",
    //     "description": "constitucion-44",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a28",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 11,
    //     "prevFrameCounterFcnt": 179,
    //     "latestFrameCounterFcnt": 191,
    //     "lostFcnt": -22,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 352,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC74",
    //     "description": "Valencia-1-1",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a17",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 353,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC75",
    //     "description": "Valencia-1-3",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a16",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 354,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC77",
    //     "description": "AugustoGomar-4bajo",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a15",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 2,
    //     "rssi": -112,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 626,
    //     "prevFrameCounterFcnt": 1642,
    //     "latestFrameCounterFcnt": 1649,
    //     "lostFcnt": 587,
    //     "loraSNR": -17,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 355,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC78",
    //     "description": "Augusto-Gomar-4-2",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a0e",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 2,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 621,
    //     "prevFrameCounterFcnt": 1657,
    //     "latestFrameCounterFcnt": 1658,
    //     "lostFcnt": 359,
    //     "loraSNR": -20,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 356,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC81",
    //     "description": "Boades-23",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a13",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 2,
    //     "rssi": -114,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 293,
    //     "prevFrameCounterFcnt": 1321,
    //     "latestFrameCounterFcnt": 1333,
    //     "lostFcnt": 575,
    //     "loraSNR": -12,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 357,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC82",
    //     "description": "Boades-15",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a0d",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 5,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 611,
    //     "prevFrameCounterFcnt": 1662,
    //     "latestFrameCounterFcnt": 1663,
    //     "lostFcnt": 322,
    //     "loraSNR": -18,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 358,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC85",
    //     "description": "Romaldo-Soler-Miquel-7",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a09",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -113,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 616,
    //     "prevFrameCounterFcnt": 1670,
    //     "latestFrameCounterFcnt": 1671,
    //     "lostFcnt": 355,
    //     "loraSNR": -6,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 359,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC86",
    //     "description": "Abajo-14",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a08",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 592,
    //     "prevFrameCounterFcnt": 1620,
    //     "latestFrameCounterFcnt": 1621,
    //     "lostFcnt": 441,
    //     "loraSNR": -4,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 360,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC88",
    //     "description": "Boades-6",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a06",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 39,
    //     "prevFrameCounterFcnt": 1094,
    //     "latestFrameCounterFcnt": 1095,
    //     "lostFcnt": 379,
    //     "loraSNR": -6,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 361,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC89",
    //     "description": "AgustoGomar-15",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a07",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 117,
    //     "prevFrameCounterFcnt": 19,
    //     "latestFrameCounterFcnt": 28,
    //     "lostFcnt": -560,
    //     "loraSNR": -19,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 362,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC90",
    //     "description": "Horno-3",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a51",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 5,
    //     "rssi": -102,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 567,
    //     "prevFrameCounterFcnt": 1572,
    //     "latestFrameCounterFcnt": 1573,
    //     "lostFcnt": 154,
    //     "loraSNR": 5,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 363,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC93",
    //     "description": "Soriano-8",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a4e",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 5,
    //     "rssi": -107,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 589,
    //     "prevFrameCounterFcnt": 1643,
    //     "latestFrameCounterFcnt": 1644,
    //     "lostFcnt": 265,
    //     "loraSNR": 2,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 364,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC94",
    //     "description": "Pl-Olmo-2",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a54",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 3,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 613,
    //     "prevFrameCounterFcnt": 1672,
    //     "latestFrameCounterFcnt": 1673,
    //     "lostFcnt": 265,
    //     "loraSNR": -15,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 365,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC95",
    //     "description": "Meson-25",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a56",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -111,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 622,
    //     "prevFrameCounterFcnt": 1703,
    //     "latestFrameCounterFcnt": 1704,
    //     "lostFcnt": 226,
    //     "loraSNR": -4,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 366,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC98",
    //     "description": "DoctorGomar-1",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a53",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 620,
    //     "prevFrameCounterFcnt": 1684,
    //     "latestFrameCounterFcnt": 1685,
    //     "lostFcnt": 260,
    //     "loraSNR": -10,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 367,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC101",
    //     "description": "OscarDurich-6",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a52",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 5,
    //     "rssi": -112,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 599,
    //     "prevFrameCounterFcnt": 1658,
    //     "latestFrameCounterFcnt": 1659,
    //     "lostFcnt": 238,
    //     "loraSNR": -11,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 368,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC102",
    //     "description": "OscarDurich-4",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a832",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 5,
    //     "rssi": -103,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 609,
    //     "prevFrameCounterFcnt": 1654,
    //     "latestFrameCounterFcnt": 1655,
    //     "lostFcnt": 349,
    //     "loraSNR": -12,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 369,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC105",
    //     "description": "OscarDurich-7",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a83e",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 5,
    //     "rssi": -114,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 291,
    //     "prevFrameCounterFcnt": 1370,
    //     "latestFrameCounterFcnt": 1371,
    //     "lostFcnt": 278,
    //     "loraSNR": -13,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 370,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC04",
    //     "description": "constitucion-16",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bce",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 2,
    //     "rssi": -112,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 289,
    //     "prevFrameCounterFcnt": 816,
    //     "latestFrameCounterFcnt": 817,
    //     "lostFcnt": -222,
    //     "loraSNR": -20,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 371,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC07",
    //     "description": "San-Jose-21",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a840",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 1,
    //     "rssi": -113,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 277,
    //     "prevFrameCounterFcnt": 1291,
    //     "latestFrameCounterFcnt": 1292,
    //     "lostFcnt": 559,
    //     "loraSNR": -21,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 372,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC08",
    //     "description": "San-Jose-14",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872776b7",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 2,
    //     "rssi": -114,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 295,
    //     "prevFrameCounterFcnt": 830,
    //     "latestFrameCounterFcnt": 831,
    //     "lostFcnt": -252,
    //     "loraSNR": -19,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 373,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC11",
    //     "description": "Gandia-26",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a843",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 684,
    //     "latestFrameCounterFcnt": 685,
    //     "lostFcnt": -2,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 374,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC12",
    //     "description": "convento-59",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a844",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": -110,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 283,
    //     "prevFrameCounterFcnt": 740,
    //     "latestFrameCounterFcnt": 748,
    //     "lostFcnt": 82,
    //     "loraSNR": -20,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 375,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC16",
    //     "description": "Convento-31",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a81b",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 1,
    //     "rssi": -112,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 257,
    //     "prevFrameCounterFcnt": 936,
    //     "latestFrameCounterFcnt": 952,
    //     "lostFcnt": 114,
    //     "loraSNR": -21,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 376,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC17",
    //     "description": "Convento-19",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a82d",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": -111,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 153,
    //     "prevFrameCounterFcnt": 1158,
    //     "latestFrameCounterFcnt": 1159,
    //     "lostFcnt": 573,
    //     "loraSNR": -20,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 377,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC21",
    //     "description": "Pl-Luis.Guarner-2",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a82f",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 115,
    //     "prevFrameCounterFcnt": 120,
    //     "latestFrameCounterFcnt": 121,
    //     "lostFcnt": -412,
    //     "loraSNR": -15,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 378,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC22",
    //     "description": "San-Francesc-13",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a82b",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 2,
    //     "rssi": -115,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 296,
    //     "prevFrameCounterFcnt": 1363,
    //     "latestFrameCounterFcnt": 1364,
    //     "lostFcnt": 228,
    //     "loraSNR": -9,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 379,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC25",
    //     "description": "Pl-Independiencia-9",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a44",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 1,
    //     "rssi": -114,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 597,
    //     "prevFrameCounterFcnt": 639,
    //     "latestFrameCounterFcnt": 641,
    //     "lostFcnt": -467,
    //     "loraSNR": -19,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 380,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC26",
    //     "description": "San-Francisco-1",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a25",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 2,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 300,
    //     "prevFrameCounterFcnt": 1354,
    //     "latestFrameCounterFcnt": 1355,
    //     "lostFcnt": 218,
    //     "loraSNR": -12,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 381,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC29",
    //     "description": "Pl-Cristo-Empar-7",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a27",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 5,
    //     "rssi": -109,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 600,
    //     "prevFrameCounterFcnt": 1684,
    //     "latestFrameCounterFcnt": 1685,
    //     "lostFcnt": 249,
    //     "loraSNR": -10,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 382,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC30",
    //     "description": "Nou-2",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a24",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 1,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 292,
    //     "prevFrameCounterFcnt": 1342,
    //     "latestFrameCounterFcnt": 1343,
    //     "lostFcnt": 294,
    //     "loraSNR": -13,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 383,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC34",
    //     "description": "Nou-3",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a5d",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 43,
    //     "prevFrameCounterFcnt": 28,
    //     "latestFrameCounterFcnt": 29,
    //     "lostFcnt": -23,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 384,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC35",
    //     "description": "Nou-5",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a57",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 138,
    //     "prevFrameCounterFcnt": 1153,
    //     "latestFrameCounterFcnt": 1162,
    //     "lostFcnt": 446,
    //     "loraSNR": -11,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 385,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC38",
    //     "description": "Nou-28",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a5b",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 135,
    //     "prevFrameCounterFcnt": 16,
    //     "latestFrameCounterFcnt": 17,
    //     "lostFcnt": -567,
    //     "loraSNR": -14,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 386,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC39",
    //     "description": "Xativa-5",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a5f",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 387,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC43",
    //     "description": "CronistaCaspir-25",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a62",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 5,
    //     "prevFrameCounterFcnt": 46,
    //     "latestFrameCounterFcnt": 40,
    //     "lostFcnt": 25,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 388,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC46",
    //     "description": "CronistaCaspir-17",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a48",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 18,
    //     "latestFrameCounterFcnt": 57,
    //     "lostFcnt": 23,
    //     "loraSNR": -18,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 389,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC47",
    //     "description": "Valencia-1",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bc4",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 390,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC50",
    //     "description": "CronistaCaspir-5-MoblesRoque",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bc8",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 6,
    //     "latestFrameCounterFcnt": 6,
    //     "lostFcnt": 0,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 391,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC51",
    //     "description": "CronistaCapsir-8",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a4c",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 392,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC55",
    //     "description": "CronistaCapsir-4",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a19",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 65,
    //     "prevFrameCounterFcnt": 21,
    //     "latestFrameCounterFcnt": 27,
    //     "lostFcnt": -52,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 393,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC57",
    //     "description": "Paleontologo-monsen-6",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a1f",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": -112,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 139,
    //     "prevFrameCounterFcnt": 160,
    //     "latestFrameCounterFcnt": 161,
    //     "lostFcnt": -506,
    //     "loraSNR": -20,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 394,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC58",
    //     "description": "Valencia-23",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a21",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 275,
    //     "prevFrameCounterFcnt": 48,
    //     "latestFrameCounterFcnt": 3,
    //     "lostFcnt": -319,
    //     "loraSNR": -20,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 395,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC61",
    //     "description": "pl-lom-7",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a20",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -111,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 600,
    //     "prevFrameCounterFcnt": 1614,
    //     "latestFrameCounterFcnt": 1624,
    //     "lostFcnt": 567,
    //     "loraSNR": -16,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 396,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC64",
    //     "description": "Font-6",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a38",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 57,
    //     "prevFrameCounterFcnt": 53,
    //     "latestFrameCounterFcnt": 67,
    //     "lostFcnt": -14,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 397,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC65",
    //     "description": "Font-8",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a3e",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 45,
    //     "latestFrameCounterFcnt": 46,
    //     "lostFcnt": -645,
    //     "loraSNR": -19,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 398,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC68",
    //     "description": "Font-2-2",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a3a",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 44,
    //     "prevFrameCounterFcnt": 22,
    //     "latestFrameCounterFcnt": 26,
    //     "lostFcnt": -35,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 399,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC69",
    //     "description": "Font-2-1",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a3b",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": 38,
    //     "lostFcnt": 37,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 400,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC72",
    //     "description": "Nou-18-2",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a26",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 176,
    //     "prevFrameCounterFcnt": 76,
    //     "latestFrameCounterFcnt": 77,
    //     "lostFcnt": -369,
    //     "loraSNR": -20,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 401,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC73",
    //     "description": "Nou-18-1",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a11",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 99,
    //     "prevFrameCounterFcnt": 341,
    //     "latestFrameCounterFcnt": 354,
    //     "lostFcnt": 58,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 402,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC76",
    //     "description": "Xativa-15",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a10",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 57,
    //     "prevFrameCounterFcnt": 31,
    //     "latestFrameCounterFcnt": 32,
    //     "lostFcnt": -79,
    //     "loraSNR": null,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 403,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC79",
    //     "description": "Augusto-Gomar-4-1",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a0f",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 2,
    //     "rssi": -109,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 604,
    //     "prevFrameCounterFcnt": 1650,
    //     "latestFrameCounterFcnt": 1652,
    //     "lostFcnt": 217,
    //     "loraSNR": -15,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 404,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC80",
    //     "description": "AugustoGomar-3",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a14",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -112,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 602,
    //     "prevFrameCounterFcnt": 1648,
    //     "latestFrameCounterFcnt": 1649,
    //     "lostFcnt": 254,
    //     "loraSNR": -8,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 405,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC83",
    //     "description": "Boades-22",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a0a",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 295,
    //     "prevFrameCounterFcnt": 1359,
    //     "latestFrameCounterFcnt": 1360,
    //     "lostFcnt": 256,
    //     "loraSNR": -15,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 406,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC84",
    //     "description": "Boades-18",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a04",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 1,
    //     "rssi": -112,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 611,
    //     "prevFrameCounterFcnt": 1674,
    //     "latestFrameCounterFcnt": 1675,
    //     "lostFcnt": 436,
    //     "loraSNR": -15,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 407,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC87",
    //     "description": "constitucion-26",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872759f4",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 27,
    //     "prevFrameCounterFcnt": 700,
    //     "latestFrameCounterFcnt": 701,
    //     "lostFcnt": 109,
    //     "loraSNR": -17,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 408,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC91",
    //     "description": "Pintor-VicentePla-1",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872759f3",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 5,
    //     "rssi": -107,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 566,
    //     "prevFrameCounterFcnt": 1583,
    //     "latestFrameCounterFcnt": 1584,
    //     "lostFcnt": 173,
    //     "loraSNR": 3,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 409,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC92",
    //     "description": "Horno-7",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872759ef",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 5,
    //     "rssi": -103,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 568,
    //     "prevFrameCounterFcnt": 1592,
    //     "latestFrameCounterFcnt": 1593,
    //     "lostFcnt": 167,
    //     "loraSNR": 6,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 410,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC96",
    //     "description": "Meson-21",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a4d",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 3,
    //     "rssi": -112,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 603,
    //     "prevFrameCounterFcnt": 1669,
    //     "latestFrameCounterFcnt": 1670,
    //     "lostFcnt": 276,
    //     "loraSNR": -12,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 411,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC97",
    //     "description": "Abajo-19",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a55",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 5,
    //     "rssi": -112,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 608,
    //     "prevFrameCounterFcnt": 1687,
    //     "latestFrameCounterFcnt": 1688,
    //     "lostFcnt": 267,
    //     "loraSNR": -2,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 412,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC99",
    //     "description": "DoctorGomar-3-1",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a45",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 599,
    //     "prevFrameCounterFcnt": 1649,
    //     "latestFrameCounterFcnt": 1650,
    //     "lostFcnt": 275,
    //     "loraSNR": -3,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 413,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC100",
    //     "description": "DoctorGomar-3-2",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275a40",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 5,
    //     "rssi": -114,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 620,
    //     "prevFrameCounterFcnt": 1689,
    //     "latestFrameCounterFcnt": 1690,
    //     "lostFcnt": 289,
    //     "loraSNR": -9,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 414,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC103",
    //     "description": "OscarDurich-14",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a83a",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 4,
    //     "rssi": -114,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 622,
    //     "prevFrameCounterFcnt": 1736,
    //     "latestFrameCounterFcnt": 1737,
    //     "lostFcnt": 272,
    //     "loraSNR": -12,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 415,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC106",
    //     "description": "Pl-DoctorGomar-2-2",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a839",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 618,
    //     "prevFrameCounterFcnt": 1677,
    //     "latestFrameCounterFcnt": 1678,
    //     "lostFcnt": 257,
    //     "loraSNR": -12,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 416,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC107",
    //     "description": "OscarDurich-5",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a83c",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 5,
    //     "rssi": -112,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 584,
    //     "prevFrameCounterFcnt": 1646,
    //     "latestFrameCounterFcnt": 1647,
    //     "lostFcnt": 286,
    //     "loraSNR": -8,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 417,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC108",
    //     "description": "OscarDurich-9",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a838",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 606,
    //     "prevFrameCounterFcnt": 1681,
    //     "latestFrameCounterFcnt": 1682,
    //     "lostFcnt": 294,
    //     "loraSNR": -10,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 418,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC109",
    //     "description": "OscarDurich-15",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a83b",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 5,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 600,
    //     "prevFrameCounterFcnt": 1670,
    //     "latestFrameCounterFcnt": 1671,
    //     "lostFcnt": 307,
    //     "loraSNR": -15,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 419,
    //     "gatewaysId": null,
    //     "name": "WaterExternalLACGWTC110",
    //     "description": "OscarDurich-8",
    //     "provider": null,
    //     "userId": 63,
    //     "typeSensor": "60",
    //     "sensorTypeInfo": {
    //         "id": 60,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-18",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a83d",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebe5f665,dca632cac20e",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 613,
    //     "prevFrameCounterFcnt": 1696,
    //     "latestFrameCounterFcnt": 1697,
    //     "lostFcnt": 270,
    //     "loraSNR": -11,
    //     "networkServerMac": "dca632cac20e",
    //     "messageTime": null
    // },
    // {
    //     "id": 421,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC16",
    //     "description": "C16",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d78f",
    //     "appEUI": "2",
    //     "appKEY": "A902DF1B5E29A624D4C8D0BCF20D18BD",
    //     "encryptionKey": "36B064FB05E392D92E79D02684317244",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1033,
    //     "prevFrameCounterFcnt": 2971,
    //     "latestFrameCounterFcnt": 2972,
    //     "lostFcnt": 686,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 422,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC17",
    //     "description": "C17",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000c55d",
    //     "appEUI": "2",
    //     "appKEY": "305EE678B4252F4B3EA6C42E52947B29",
    //     "encryptionKey": "39DCD8D4E2063888B97FCCEEB794D9C1",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1050,
    //     "prevFrameCounterFcnt": 2988,
    //     "latestFrameCounterFcnt": 2989,
    //     "lostFcnt": 572,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 423,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC18",
    //     "description": "C18",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8bd",
    //     "appEUI": "2",
    //     "appKEY": "31D9F166A649D736E9332B56EE810EB8",
    //     "encryptionKey": "B30A2FF7D51931EFC4B030BBEF15BEF0",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 2,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1023,
    //     "prevFrameCounterFcnt": 2961,
    //     "latestFrameCounterFcnt": 2962,
    //     "lostFcnt": 563,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 424,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC19",
    //     "description": "C19",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8d0",
    //     "appEUI": "2",
    //     "appKEY": "78D283B70166220ED6EC072CAF13D10C",
    //     "encryptionKey": "CB438760784F1DB28381F07FA71B94C4",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 3,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1025,
    //     "prevFrameCounterFcnt": 2961,
    //     "latestFrameCounterFcnt": 2963,
    //     "lostFcnt": 827,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 425,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC20",
    //     "description": "C20",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8d1",
    //     "appEUI": "2",
    //     "appKEY": "C4063885B6FF5B0B260A923C9ABBE8E1",
    //     "encryptionKey": "333F2E83808C632B47DF280B011D84D0",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1024,
    //     "prevFrameCounterFcnt": 2963,
    //     "latestFrameCounterFcnt": 2964,
    //     "lostFcnt": 1065,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 426,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC21",
    //     "description": "C21",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8d5",
    //     "appEUI": "2",
    //     "appKEY": "28EBA462935A7E55D8F69DFFBC5A754B",
    //     "encryptionKey": "5A014577351DF9E4C8FCE7B47968249B",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1027,
    //     "prevFrameCounterFcnt": 2962,
    //     "latestFrameCounterFcnt": 2964,
    //     "lostFcnt": 758,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 427,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC22",
    //     "description": "C22",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8a6",
    //     "appEUI": "2",
    //     "appKEY": "F1B0AC93503799DD7EAFBDE7CA9E99C7",
    //     "encryptionKey": "C7F4E739D92F506C0DD0E811FED5551B",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 607,
    //     "prevFrameCounterFcnt": 1126,
    //     "latestFrameCounterFcnt": 1127,
    //     "lostFcnt": 91,
    //     "loraSNR": -21,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 428,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC23",
    //     "description": "C23",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000e02a",
    //     "appEUI": "2",
    //     "appKEY": "9C2AD5B0A26C3E668001264BAA8B23C6",
    //     "encryptionKey": "9E78FBC1BA7D855656E4260D9BD15EB5",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1048,
    //     "prevFrameCounterFcnt": 2984,
    //     "latestFrameCounterFcnt": 2985,
    //     "lostFcnt": 1179,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 429,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC24",
    //     "description": "C24",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8ae",
    //     "appEUI": "2",
    //     "appKEY": "E64F27BB8E0341AA9058A77F7510AB26",
    //     "encryptionKey": "F79069A30866803F36B720AD306730C3",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1048,
    //     "prevFrameCounterFcnt": 2977,
    //     "latestFrameCounterFcnt": 2985,
    //     "lostFcnt": 1034,
    //     "loraSNR": -19,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 430,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC25",
    //     "description": "C25",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8ad",
    //     "appEUI": "2",
    //     "appKEY": "23663BDE0FEA92D9EA7167ADAA4F07B2",
    //     "encryptionKey": "09F5E09E3BB829DDC23E121B26209ADA",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 161,
    //     "prevFrameCounterFcnt": 2072,
    //     "latestFrameCounterFcnt": 2073,
    //     "lostFcnt": 1268,
    //     "loraSNR": -19,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 431,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC26",
    //     "description": "C26",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8b6",
    //     "appEUI": "2",
    //     "appKEY": "F9845BCD5DF74B21452DD59714BF412C",
    //     "encryptionKey": "98744B69875F63CCBECC51270E7F2D89",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1025,
    //     "prevFrameCounterFcnt": 2963,
    //     "latestFrameCounterFcnt": 2964,
    //     "lostFcnt": 820,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 432,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC27",
    //     "description": "C27",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8a7",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1023,
    //     "prevFrameCounterFcnt": 2961,
    //     "latestFrameCounterFcnt": 2962,
    //     "lostFcnt": 481,
    //     "loraSNR": -19,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 433,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC28",
    //     "description": "C28",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8d6",
    //     "appEUI": "2",
    //     "appKEY": "47FD54ED8FC28FF908B17FB93208028D",
    //     "encryptionKey": "5C8EB0F1AACC39C779F577EA63FEBCBC",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1052,
    //     "prevFrameCounterFcnt": 2988,
    //     "latestFrameCounterFcnt": 2989,
    //     "lostFcnt": 628,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 434,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC29",
    //     "description": "C29",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8c9",
    //     "appEUI": "2",
    //     "appKEY": "02C39471A0E2FC8D8761ECDFCEB18084",
    //     "encryptionKey": "1F6D0FDBE39281F4B76FD90787753654",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1051,
    //     "prevFrameCounterFcnt": 2988,
    //     "latestFrameCounterFcnt": 2989,
    //     "lostFcnt": 429,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 435,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC30",
    //     "description": "C30",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8d4",
    //     "appEUI": "2",
    //     "appKEY": "A734F342114922813CBC70C39CCC7DCA",
    //     "encryptionKey": "384F2868AF4C9C51A4AE599122130DCB",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 2,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1051,
    //     "prevFrameCounterFcnt": 2973,
    //     "latestFrameCounterFcnt": 2976,
    //     "lostFcnt": 578,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 436,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC31",
    //     "description": "C31",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8f3",
    //     "appEUI": "2",
    //     "appKEY": "6B9FAF850A2F20D64366D3856A30992B",
    //     "encryptionKey": "E458096617778FCA514389448EE4D2DD",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1033,
    //     "prevFrameCounterFcnt": 2970,
    //     "latestFrameCounterFcnt": 2971,
    //     "lostFcnt": 603,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 437,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC32",
    //     "description": "C32",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8cd",
    //     "appEUI": "2",
    //     "appKEY": "FFC16AD31C9BCA472B1DC0B042B180D1",
    //     "encryptionKey": "F8B5792AC69798CF5727E95BDF668C2E",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1050,
    //     "prevFrameCounterFcnt": 2987,
    //     "latestFrameCounterFcnt": 2988,
    //     "lostFcnt": 322,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 438,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC33",
    //     "description": "C33",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8c7",
    //     "appEUI": "2",
    //     "appKEY": "40BDEAF360508EE1A74C18BD99D52D14",
    //     "encryptionKey": "F169C5744A0719C6E7F553DCC145CF0E",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1050,
    //     "prevFrameCounterFcnt": 2988,
    //     "latestFrameCounterFcnt": 2989,
    //     "lostFcnt": 340,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 439,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC34",
    //     "description": "C34",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8e8",
    //     "appEUI": "2",
    //     "appKEY": "030186FEB74F957AAC6AD0007AE3791A",
    //     "encryptionKey": "80AB9B96261AF4F95972FA9F57DBBC27",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -106,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1033,
    //     "prevFrameCounterFcnt": 2971,
    //     "latestFrameCounterFcnt": 2972,
    //     "lostFcnt": 394,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 440,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC35",
    //     "description": "C35",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8ed",
    //     "appEUI": "2",
    //     "appKEY": "5C2CF23CE4ADE6D4673565845898CA8E",
    //     "encryptionKey": "0E10747BE02542CE264A1C6080247824",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1034,
    //     "prevFrameCounterFcnt": 2972,
    //     "latestFrameCounterFcnt": 2973,
    //     "lostFcnt": 458,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 441,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC36",
    //     "description": "C36",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8e7",
    //     "appEUI": "2",
    //     "appKEY": "F1413DDC82F28A94C968284C6CFC716A",
    //     "encryptionKey": "37E5A9B85ACF43E75C305BFAD5DD06E7",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1034,
    //     "prevFrameCounterFcnt": 2971,
    //     "latestFrameCounterFcnt": 2972,
    //     "lostFcnt": 530,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 442,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC37",
    //     "description": "C37",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8fb",
    //     "appEUI": "2",
    //     "appKEY": "8CFA813F0126712DF6A738F929032F2D",
    //     "encryptionKey": "9AE2341FE1D56EB418497CB046D88296",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -116,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1032,
    //     "prevFrameCounterFcnt": 2970,
    //     "latestFrameCounterFcnt": 2971,
    //     "lostFcnt": 549,
    //     "loraSNR": 0,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 443,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC38",
    //     "description": "C38",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d78a",
    //     "appEUI": "2",
    //     "appKEY": "C67DF851A4AFEDD67B7603DE12DFB940",
    //     "encryptionKey": "BE6C838CF66890370E87133122646A20",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1029,
    //     "prevFrameCounterFcnt": 2967,
    //     "latestFrameCounterFcnt": 2968,
    //     "lostFcnt": 390,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 444,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC39",
    //     "description": "C39",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d789",
    //     "appEUI": "2",
    //     "appKEY": "522D7C6583B89479C24425F2C75C32F7",
    //     "encryptionKey": "B8C7875986BE13E96AFB8D028501C640",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1032,
    //     "prevFrameCounterFcnt": 2971,
    //     "latestFrameCounterFcnt": 2972,
    //     "lostFcnt": 390,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 445,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC40",
    //     "description": "C40",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d780",
    //     "appEUI": "2",
    //     "appKEY": "7CA6D3FB3E6573E4712CD36A76E78DC5",
    //     "encryptionKey": "13E9A652D72424B661AA4245626689A1",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -114,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1033,
    //     "prevFrameCounterFcnt": 2971,
    //     "latestFrameCounterFcnt": 2972,
    //     "lostFcnt": 352,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 446,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC41",
    //     "description": "C41",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d729",
    //     "appEUI": "2",
    //     "appKEY": "18A08B432C5BD983119D649263589082",
    //     "encryptionKey": "99FD70FCD00F8239C462652533F73B68",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1033,
    //     "prevFrameCounterFcnt": 2970,
    //     "latestFrameCounterFcnt": 2971,
    //     "lostFcnt": 1250,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 447,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC42",
    //     "description": "C42",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d7ff",
    //     "appEUI": "2",
    //     "appKEY": "2BF563C54FF2F45673DAD0F32072DB9E",
    //     "encryptionKey": "634199BF97EED4CB35261DC660024E4C",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1033,
    //     "prevFrameCounterFcnt": 2970,
    //     "latestFrameCounterFcnt": 2971,
    //     "lostFcnt": 522,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 448,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC43",
    //     "description": "C43",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d73a",
    //     "appEUI": "2",
    //     "appKEY": "0AB596EFDEE4038815BF742B317C8D0D",
    //     "encryptionKey": "7B4E92E218BFF7DEF8E75E4C6DBF1B72",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1050,
    //     "prevFrameCounterFcnt": 2988,
    //     "latestFrameCounterFcnt": 2989,
    //     "lostFcnt": 802,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 449,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC44",
    //     "description": "C44",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d7f7",
    //     "appEUI": "2",
    //     "appKEY": "E823D1CF9722E9891E1E2616FE26DA3A",
    //     "encryptionKey": "AC0D5E3494BA5FBD53D12110F2324972",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1032,
    //     "prevFrameCounterFcnt": 2971,
    //     "latestFrameCounterFcnt": 2972,
    //     "lostFcnt": 428,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 450,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC45",
    //     "description": "C45",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000c639",
    //     "appEUI": "2",
    //     "appKEY": "33778175AB054775597BD47CB21003B7",
    //     "encryptionKey": "2F5C97F4FBF05E886543A0C00D8D8BB1",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 3,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1051,
    //     "prevFrameCounterFcnt": 2987,
    //     "latestFrameCounterFcnt": 2989,
    //     "lostFcnt": 518,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 451,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC46",
    //     "description": "C46",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000c551",
    //     "appEUI": "2",
    //     "appKEY": "4963E7567B26D0D0AB0D734E97FC73C4",
    //     "encryptionKey": "85263A3A5E0E9ABC82B80FFBB14137A2",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 4,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1050,
    //     "prevFrameCounterFcnt": 2987,
    //     "latestFrameCounterFcnt": 2988,
    //     "lostFcnt": 656,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 452,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC47",
    //     "description": "C47",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000c6e0",
    //     "appEUI": "2",
    //     "appKEY": "8E2262BFD52B4244B08ED44AA2B524C3",
    //     "encryptionKey": "0D836EBC8D7D21C3237778CBE582ED76",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1050,
    //     "prevFrameCounterFcnt": 2988,
    //     "latestFrameCounterFcnt": 2989,
    //     "lostFcnt": 480,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 453,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC48",
    //     "description": "C48",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000c560",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1050,
    //     "prevFrameCounterFcnt": 2988,
    //     "latestFrameCounterFcnt": 2989,
    //     "lostFcnt": 547,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 454,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC49",
    //     "description": "C49",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000c8c1",
    //     "appEUI": "2",
    //     "appKEY": "7BE4471B40618CD306819968F19B8F2E",
    //     "encryptionKey": "E4F139C3E44CFF303FBF92866A1307A4",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 3,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1050,
    //     "prevFrameCounterFcnt": 2988,
    //     "latestFrameCounterFcnt": 2989,
    //     "lostFcnt": 490,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 455,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC50",
    //     "description": "C50",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d734",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 4,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1049,
    //     "prevFrameCounterFcnt": 2986,
    //     "latestFrameCounterFcnt": 2987,
    //     "lostFcnt": 577,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 456,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC01",
    //     "description": "C01",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000c4f9",
    //     "appEUI": "2",
    //     "appKEY": "DBF3EE058DC123C105FB9828B2980AA0",
    //     "encryptionKey": "80670BBAD14B46424E1DD14AD3C31B6B",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 2,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1051,
    //     "prevFrameCounterFcnt": 2989,
    //     "latestFrameCounterFcnt": 2990,
    //     "lostFcnt": 646,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 457,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC02",
    //     "description": "C02",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000c543",
    //     "appEUI": "2",
    //     "appKEY": "46EF42EC6191B9EA34A45693297F7C17",
    //     "encryptionKey": "68A5BF3349B6D285292C67F14204C5CE",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 2,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1052,
    //     "prevFrameCounterFcnt": 2990,
    //     "latestFrameCounterFcnt": 2991,
    //     "lostFcnt": 423,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 458,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC56",
    //     "description": "C56",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000e021",
    //     "appEUI": "2",
    //     "appKEY": "A2FE789558655E888A19ACDCA9FE4FD1",
    //     "encryptionKey": "9487C12AFB4C3D2FD355897F3FAB0AF3",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 3,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2950,
    //     "latestFrameCounterFcnt": 2951,
    //     "lostFcnt": 498,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 459,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC58",
    //     "description": "C58",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8d3",
    //     "appEUI": "2",
    //     "appKEY": "5EEC716A5DE2A3B62E1FF510D69F9572",
    //     "encryptionKey": "9088010A04F3565A7A15B99D2FCDA089",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 500,
    //     "prevFrameCounterFcnt": 2438,
    //     "latestFrameCounterFcnt": 2439,
    //     "lostFcnt": 375,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 460,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC63",
    //     "description": "C63",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000c62f",
    //     "appEUI": "2",
    //     "appKEY": "70E011A2DC6D910A5A33047AD17E471E",
    //     "encryptionKey": "20D2042D3D6C85FB34E88B5872FF7EBD",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 3,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1012,
    //     "prevFrameCounterFcnt": 2951,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 537,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 461,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC64",
    //     "description": "C64",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8bb",
    //     "appEUI": "2",
    //     "appKEY": "2DFCD0D624C759834EA48BA5B1AA13DF",
    //     "encryptionKey": "2B6E194B22F973433501B88931296D8D",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 2,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2951,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 668,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 462,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC65",
    //     "description": "C65",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d73b",
    //     "appEUI": "2",
    //     "appKEY": "E7648F7A9B1FAA0B3D069ECD8FCEB1FA",
    //     "encryptionKey": "7F0B25C02871EF4CC7CECCF7FAF4B8C0",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 4,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2951,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 598,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 463,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC03",
    //     "description": "C03",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000c4ed",
    //     "appEUI": "2",
    //     "appKEY": "0616A8F900A8583C676D3D0DACE35896",
    //     "encryptionKey": "E72C43AA2769E70CF625FADCE127485C",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1037,
    //     "prevFrameCounterFcnt": 1743,
    //     "latestFrameCounterFcnt": 1754,
    //     "lostFcnt": 178,
    //     "loraSNR": -19,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 464,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC05",
    //     "description": "C05",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000c4f2",
    //     "appEUI": "2",
    //     "appKEY": "69DF875DEB839C2E0B03106E65721244",
    //     "encryptionKey": "07C01EFB31A2FAAED5E2DBC26C34C36D",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1052,
    //     "prevFrameCounterFcnt": 2990,
    //     "latestFrameCounterFcnt": 2991,
    //     "lostFcnt": 575,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 465,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC66",
    //     "description": "C66",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8b1",
    //     "appEUI": "2",
    //     "appKEY": "206D441B9FD15E0A5CA754352288A733",
    //     "encryptionKey": "9EE3B9FE5EEF9581AA06053D68357376",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 902,
    //     "prevFrameCounterFcnt": 2839,
    //     "latestFrameCounterFcnt": 2841,
    //     "lostFcnt": 825,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 466,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC67",
    //     "description": "C67",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8b7",
    //     "appEUI": "2",
    //     "appKEY": "8CADB92E55B5320E80D1A81AD1E5C4E5",
    //     "encryptionKey": "0E83AAB9358DD29F91EA56A3BA7BDB59",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 4,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1012,
    //     "prevFrameCounterFcnt": 2951,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 448,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 467,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC68",
    //     "description": "C68",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d742",
    //     "appEUI": "2",
    //     "appKEY": "05FE56F493B36636FA4CCD1B818ADF8C",
    //     "encryptionKey": "814CBC9227073832F2719288DABCC860",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 4,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1011,
    //     "prevFrameCounterFcnt": 2949,
    //     "latestFrameCounterFcnt": 2950,
    //     "lostFcnt": 612,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 468,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC69",
    //     "description": "C69",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8ab",
    //     "appEUI": "2",
    //     "appKEY": "655C77C6F01885419A9EBA77D3FED92C",
    //     "encryptionKey": "EF8EB2FBEA37C8D54E59541ADE1F9332",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2951,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 648,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 469,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC70",
    //     "description": "C70",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d743",
    //     "appEUI": "2",
    //     "appKEY": "02E6992ED4A1F2FE58E55C3AFCB418F1",
    //     "encryptionKey": " DCE3C37EC757131272F1022891AB936A",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 2,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1015,
    //     "prevFrameCounterFcnt": 2950,
    //     "latestFrameCounterFcnt": 2951,
    //     "lostFcnt": 546,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 470,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC71",
    //     "description": "C71",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d76e",
    //     "appEUI": "2",
    //     "appKEY": "FAF3B17DB5ED22461E609BB7B39B455B",
    //     "encryptionKey": "8D64A844922D2351A35425F2E2F9F0CC",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1012,
    //     "prevFrameCounterFcnt": 2950,
    //     "latestFrameCounterFcnt": 2951,
    //     "lostFcnt": 605,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 471,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC72",
    //     "description": "C72",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d745",
    //     "appEUI": "2",
    //     "appKEY": "C812BAECD0BA863699F497089DDCA6CD",
    //     "encryptionKey": "D688812C340B8E3FD2A54C60440FD668",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2948,
    //     "latestFrameCounterFcnt": 2949,
    //     "lostFcnt": 557,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 472,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC73",
    //     "description": "C73",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8b5",
    //     "appEUI": "2",
    //     "appKEY": "A625D1F27850D6F90EBA3CC8FFB1E4D9",
    //     "encryptionKey": "4B16CA98EE212C90DA050DFF26B0BA86",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1012,
    //     "prevFrameCounterFcnt": 2949,
    //     "latestFrameCounterFcnt": 2950,
    //     "lostFcnt": 386,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 473,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC74",
    //     "description": "C74",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000deb7",
    //     "appEUI": "2",
    //     "appKEY": "BF99A3975E95171BCD0A0C01FD44B62E",
    //     "encryptionKey": "BE3EDAB3310568C13DA5F6200BFFAF47",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1014,
    //     "prevFrameCounterFcnt": 2951,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 454,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 474,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC75",
    //     "description": "C75",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d72f",
    //     "appEUI": "2",
    //     "appKEY": "83199F208DEE9FB4CE1EABC82CCF7E1E",
    //     "encryptionKey": "BF3BE01715B2CCAE6231FE13A9EB05FC",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2951,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 443,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 475,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC77",
    //     "description": "C77",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d773",
    //     "appEUI": "2",
    //     "appKEY": "1F28EB7A25091526041F84D5326ECC84",
    //     "encryptionKey": "265E788C8DE0C3985655AF7CEB704E5C",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1015,
    //     "prevFrameCounterFcnt": 2951,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 377,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 476,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC78",
    //     "description": "C78",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000deae",
    //     "appEUI": "2",
    //     "appKEY": "3245FBC9A5EC33AEFC4013D4F15EDAE7",
    //     "encryptionKey": "E758F5E61FDDDA34CB653E73244B8789",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2950,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 616,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 477,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC79",
    //     "description": "C79",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d76a",
    //     "appEUI": "2",
    //     "appKEY": "8E5D9953CCA2EE6669BF2B2B06FB6592",
    //     "encryptionKey": "881E39EE189BBFA38158244F542AA1DC",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 4,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2951,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 651,
    //     "loraSNR": 0,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 478,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC80",
    //     "description": "C80",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000bf20",
    //     "appEUI": "2",
    //     "appKEY": "16EE4E634F569404F3763AA2A26367F8",
    //     "encryptionKey": "A036D1D4771222BA2750550CE9083E1A",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1012,
    //     "prevFrameCounterFcnt": 2950,
    //     "latestFrameCounterFcnt": 2951,
    //     "lostFcnt": 501,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 479,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC81",
    //     "description": "C81",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000e02c",
    //     "appEUI": "2",
    //     "appKEY": "441F957D4CCEE5C53D2552CB5DFF4C81",
    //     "encryptionKey": "00D74D6D80D862D54D47E0C2D604FDB2",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2951,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 511,
    //     "loraSNR": 0,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 480,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC82",
    //     "description": "C82",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000dbc0",
    //     "appEUI": "2",
    //     "appKEY": "80C0C73B74DE0B6664D599DF9735A3A3",
    //     "encryptionKey": "DCABB8D9CEE17DD324CAE49DBAD3766D",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2951,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 576,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 481,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC83",
    //     "description": "C83",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d76f",
    //     "appEUI": "2",
    //     "appKEY": "48F5A39B1E7FE1D5E19331A2DCB16CF1",
    //     "encryptionKey": "5E9182DEB83803D20D0C7375FFA46EB3",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1012,
    //     "prevFrameCounterFcnt": 2948,
    //     "latestFrameCounterFcnt": 2949,
    //     "lostFcnt": 828,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 482,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC84",
    //     "description": "C84",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000dead",
    //     "appEUI": "2",
    //     "appKEY": "68E52B9654A79C0DC39A74B4FF50E77F",
    //     "encryptionKey": "E6135413F932C5C0E7E561B45BBD28DE",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2951,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 519,
    //     "loraSNR": -6,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 483,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC85",
    //     "description": "C85",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000e028",
    //     "appEUI": "2",
    //     "appKEY": "8807D50AFEE33C06373EB3F882002E65",
    //     "encryptionKey": "27F9055913B6653E692F2EE514BF71BB",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2951,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 397,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 484,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC86",
    //     "description": "C86",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8ca",
    //     "appEUI": "2",
    //     "appKEY": "91B2E618BA34D5EF4DE3A8CCBA76F95C",
    //     "encryptionKey": "D55A9B0F173968F14E1161208BE30C4A",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2949,
    //     "latestFrameCounterFcnt": 2950,
    //     "lostFcnt": 626,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 485,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC87",
    //     "description": "C87",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8cb",
    //     "appEUI": "2",
    //     "appKEY": "500D969A0FD812075CEC4F7C2438EA95",
    //     "encryptionKey": "9F3819BD6953662814E55C1B921BEC6A",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2951,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 451,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 486,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC88",
    //     "description": "C88",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8ce",
    //     "appEUI": "2",
    //     "appKEY": "77695EBD696A70DD23EB8444D8DA3C40",
    //     "encryptionKey": "6571C44996E2DCC85B4273A9F9E09523",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 4,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2950,
    //     "latestFrameCounterFcnt": 2951,
    //     "lostFcnt": 400,
    //     "loraSNR": -6,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 487,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC89",
    //     "description": "C89",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000e020",
    //     "appEUI": "2",
    //     "appKEY": "A37125B964C92A84207CF39FC16B7D46",
    //     "encryptionKey": "0A76492E3944A475522A3236231B07E0",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2950,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 672,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 488,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC76",
    //     "description": "C76",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d73d",
    //     "appEUI": "2",
    //     "appKEY": "A4A934A374FFB5D797A189CEA1D22AFE",
    //     "encryptionKey": "5F0AC189627B72B15DA2F116BFD1ABEE",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 500,
    //     "prevFrameCounterFcnt": 2438,
    //     "latestFrameCounterFcnt": 2439,
    //     "lostFcnt": 441,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 489,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC90",
    //     "description": "C90",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d73f",
    //     "appEUI": "2",
    //     "appKEY": "5A8DF0CF5622CFB4DDCAC1FEBE3C3FA1",
    //     "encryptionKey": "EC10C386A8D7D411F6B5397F2D6F2D80",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2949,
    //     "latestFrameCounterFcnt": 2950,
    //     "lostFcnt": 743,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 490,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC91",
    //     "description": "C91",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000e02d",
    //     "appEUI": "2",
    //     "appKEY": "E2ED65DC83915A2C2929CAA255BCAD10",
    //     "encryptionKey": "D3A2860E7FF52563461635F8EF3E65C4",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2950,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 966,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 491,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC92",
    //     "description": "C92",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8c1",
    //     "appEUI": "2",
    //     "appKEY": "E812C3B83F350D02F133F243B5C61055",
    //     "encryptionKey": "5100E87DC283D63B1005EFE1FAE47BAE",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1014,
    //     "prevFrameCounterFcnt": 2950,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 1067,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 492,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC93",
    //     "description": "C93",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000e02e",
    //     "appEUI": "2",
    //     "appKEY": "05426E2299F136FC888518B352CFD1E0",
    //     "encryptionKey": "EB9ED16BD128636438116D0A65A125E7",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1015,
    //     "prevFrameCounterFcnt": 2946,
    //     "latestFrameCounterFcnt": 2950,
    //     "lostFcnt": 1152,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 493,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC94",
    //     "description": "C94",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000e01b",
    //     "appEUI": "2",
    //     "appKEY": "B921EC3F10AB44988B8CD5C4F6C33597",
    //     "encryptionKey": "AB5D9468B0C79E338B0465F1092E0394",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 929,
    //     "prevFrameCounterFcnt": 2837,
    //     "latestFrameCounterFcnt": 2842,
    //     "lostFcnt": 1304,
    //     "loraSNR": -22,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 494,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC95",
    //     "description": "C95",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000e024",
    //     "appEUI": "2",
    //     "appKEY": "5A57EAA262B8A5B3CA1CF06271A7DAB5",
    //     "encryptionKey": "627431EF00D5EA0C56294109110E233C",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 3,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2951,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 569,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 495,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC96",
    //     "description": "C96",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000e027",
    //     "appEUI": "2",
    //     "appKEY": "49A1D0CA7890DFE709B7E60D0087F3B8",
    //     "encryptionKey": "AE838933D8436EDCFD8798FBD05BB94D",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1015,
    //     "prevFrameCounterFcnt": 2949,
    //     "latestFrameCounterFcnt": 2950,
    //     "lostFcnt": 1034,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 496,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC97",
    //     "description": "C97",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000e01f",
    //     "appEUI": "2",
    //     "appKEY": "9A1BD459D7689EA1AD425199A2D1DFA9",
    //     "encryptionKey": "3048C3FA5B8F2F7999C77C514422A72A",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1014,
    //     "prevFrameCounterFcnt": 2949,
    //     "latestFrameCounterFcnt": 2950,
    //     "lostFcnt": 1081,
    //     "loraSNR": -19,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 497,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC98",
    //     "description": "C98",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000e029",
    //     "appEUI": "2",
    //     "appKEY": "EE927A03A2345A8066187035C0466A1C",
    //     "encryptionKey": "40B229585CD20A84F953875A0C5CA235",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2950,
    //     "latestFrameCounterFcnt": 2951,
    //     "lostFcnt": 564,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 498,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC100",
    //     "description": "C100",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d736",
    //     "appEUI": "2",
    //     "appKEY": "25A1CAA5B7CB4B3C862E83163F3C4D38",
    //     "encryptionKey": "260676FA78A2BDC0E956B66FD3AB8555",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 902,
    //     "prevFrameCounterFcnt": 2840,
    //     "latestFrameCounterFcnt": 2841,
    //     "lostFcnt": 557,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 499,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC101",
    //     "description": "C101",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000e01c",
    //     "appEUI": "2",
    //     "appKEY": "1A20D17728F0DF84E501296125723A68",
    //     "encryptionKey": "0046A56F7C8F59B01A6CA8FA03C9FDE1",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 4,
    //     "rssi": -117,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1012,
    //     "prevFrameCounterFcnt": 2950,
    //     "latestFrameCounterFcnt": 2951,
    //     "lostFcnt": 423,
    //     "loraSNR": 0,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 500,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC102",
    //     "description": "C102",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000e018",
    //     "appEUI": "2",
    //     "appKEY": "AD9739EAB42DC4AB7A7E78A45661DB0A",
    //     "encryptionKey": "7BAAB27BE75EFE57FA4549E183AA91EB",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1012,
    //     "prevFrameCounterFcnt": 2946,
    //     "latestFrameCounterFcnt": 2947,
    //     "lostFcnt": 1017,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 501,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC103",
    //     "description": "C103",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000e022",
    //     "appEUI": "2",
    //     "appKEY": "4E931863F7C6B046A8354ED9678A1639",
    //     "encryptionKey": "221C0E087226FD77FD32515AC98B6D14",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 2,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2950,
    //     "latestFrameCounterFcnt": 2951,
    //     "lostFcnt": 572,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 502,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC99",
    //     "description": "C99",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000e01e",
    //     "appEUI": "2",
    //     "appKEY": "9B1E79146048E42E446740C6635B0952",
    //     "encryptionKey": "D8238E2910C958A6455FCB86D5F8DB62",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 501,
    //     "prevFrameCounterFcnt": 2439,
    //     "latestFrameCounterFcnt": 2440,
    //     "lostFcnt": 964,
    //     "loraSNR": -19,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 503,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC104",
    //     "description": "C104",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d732",
    //     "appEUI": "2",
    //     "appKEY": "12DA4EFC3921C0451A405982020C2F9D",
    //     "encryptionKey": "7EEA758A2BA18255EFCA8E28D41315C4",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 3,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2950,
    //     "latestFrameCounterFcnt": 2951,
    //     "lostFcnt": 610,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 504,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC105",
    //     "description": "C105",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d740",
    //     "appEUI": "2",
    //     "appKEY": "74C514BF82987811AC5FFDC423F7DDBE",
    //     "encryptionKey": "016763BBEBE4C14B171E475C458BC88C",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2950,
    //     "latestFrameCounterFcnt": 2951,
    //     "lostFcnt": 449,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 505,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC106",
    //     "description": "C106",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000e023",
    //     "appEUI": "2",
    //     "appKEY": "0A4B74581448D17326C8B799EB9F2C7A",
    //     "encryptionKey": "50AF8129243DA13605612209769750E1",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 3,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2950,
    //     "latestFrameCounterFcnt": 2951,
    //     "lostFcnt": 630,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 506,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC107",
    //     "description": "C107",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d764",
    //     "appEUI": "2",
    //     "appKEY": "C044956AA6FC161477C65181DD0608FA",
    //     "encryptionKey": "F9E52EA04BD0D1B7B2A082B44CCA6FA9",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 4,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2949,
    //     "latestFrameCounterFcnt": 2950,
    //     "lostFcnt": 742,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 507,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC108",
    //     "description": "C108",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d75f",
    //     "appEUI": "2",
    //     "appKEY": "85980958ABBB75E912CF7DDB5DDE8DB6",
    //     "encryptionKey": "64F2A8123A4397253309EEA414D472A2",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1011,
    //     "prevFrameCounterFcnt": 2949,
    //     "latestFrameCounterFcnt": 2950,
    //     "lostFcnt": 326,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 508,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC109",
    //     "description": "C109",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d72e",
    //     "appEUI": "2",
    //     "appKEY": "5610F513352DA8BB94777DD7D5D783D3",
    //     "encryptionKey": "12400BF3A2A3993986E9EDD16535C903",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2950,
    //     "latestFrameCounterFcnt": 2951,
    //     "lostFcnt": 879,
    //     "loraSNR": 0,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 509,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC51",
    //     "description": "C51",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d731",
    //     "appEUI": "2",
    //     "appKEY": "9DBC0FB5973D00AABE27C1273234E232",
    //     "encryptionKey": "8DC6170A9F772E6D21698A7EC61709D0",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1049,
    //     "prevFrameCounterFcnt": 2988,
    //     "latestFrameCounterFcnt": 2989,
    //     "lostFcnt": 473,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 510,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC11",
    //     "description": "C11",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000c4fb",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1052,
    //     "prevFrameCounterFcnt": 2985,
    //     "latestFrameCounterFcnt": 2988,
    //     "lostFcnt": 396,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 511,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC12",
    //     "description": "C12",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000c54c",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1051,
    //     "prevFrameCounterFcnt": 2990,
    //     "latestFrameCounterFcnt": 2991,
    //     "lostFcnt": 473,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 512,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC52",
    //     "description": "C52",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d730",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 4,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1049,
    //     "prevFrameCounterFcnt": 2986,
    //     "latestFrameCounterFcnt": 2988,
    //     "lostFcnt": 529,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 513,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC53",
    //     "description": "C53",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000e026",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 4,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1050,
    //     "prevFrameCounterFcnt": 2988,
    //     "latestFrameCounterFcnt": 2989,
    //     "lostFcnt": 481,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 514,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC54",
    //     "description": "C54",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d735",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 4,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1049,
    //     "prevFrameCounterFcnt": 2987,
    //     "latestFrameCounterFcnt": 2988,
    //     "lostFcnt": 559,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 515,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC55",
    //     "description": "C55",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000e02f",
    //     "appEUI": "2",
    //     "appKEY": "434D560211F7E470895EF87643BFC029",
    //     "encryptionKey": "FAB7E832DB191534B2F9F713D6CD74AC",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1050,
    //     "prevFrameCounterFcnt": 2988,
    //     "latestFrameCounterFcnt": 2989,
    //     "lostFcnt": 418,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 516,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC110",
    //     "description": "000781370000D774",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d774",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1012,
    //     "prevFrameCounterFcnt": 2951,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 468,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 517,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC57",
    //     "description": "000781370000D8C3",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d8c3",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2951,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 423,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 518,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC111",
    //     "description": "000781370000D765",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d765",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1012,
    //     "prevFrameCounterFcnt": 2950,
    //     "latestFrameCounterFcnt": 2951,
    //     "lostFcnt": 319,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 519,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC112",
    //     "description": "000781370000D73E",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d73e",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2951,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 385,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 520,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC113",
    //     "description": "000781370000D770",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d770",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -116,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2951,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 455,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 521,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC114",
    //     "description": "000781370000D744",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d744",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2951,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 632,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 522,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC60",
    //     "description": "000781370000C4E8",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000c4e8",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1012,
    //     "prevFrameCounterFcnt": 2949,
    //     "latestFrameCounterFcnt": 2950,
    //     "lostFcnt": 450,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 523,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC61",
    //     "description": "000781370000C641",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000c641",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 4,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2951,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 412,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 524,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC62",
    //     "description": "000781370000C640",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000c640",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2948,
    //     "latestFrameCounterFcnt": 2950,
    //     "lostFcnt": 630,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 525,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC04",
    //     "description": "C04",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000c54a",
    //     "appEUI": "2",
    //     "appKEY": "DD634BB213135DFD6A262B82C3EC9950",
    //     "encryptionKey": "D42554632EB4CF06967AFBEA58109824",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1045,
    //     "prevFrameCounterFcnt": 847,
    //     "latestFrameCounterFcnt": 850,
    //     "lostFcnt": -666,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 526,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC06",
    //     "description": "C06",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000c547",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1051,
    //     "prevFrameCounterFcnt": 2988,
    //     "latestFrameCounterFcnt": 2989,
    //     "lostFcnt": 373,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 527,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC07",
    //     "description": "C07",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000c4f7",
    //     "appEUI": "2",
    //     "appKEY": "22652A53DCF9CFDDFCF895AADCBE2F3A",
    //     "encryptionKey": "FE9E6AE03CE698F9C80025CA8F855DB9",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1052,
    //     "prevFrameCounterFcnt": 2990,
    //     "latestFrameCounterFcnt": 2991,
    //     "lostFcnt": 555,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 528,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC08",
    //     "description": "C08",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000c500",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1052,
    //     "prevFrameCounterFcnt": 2990,
    //     "latestFrameCounterFcnt": 2991,
    //     "lostFcnt": 337,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 529,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC09",
    //     "description": "C09",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000c549",
    //     "appEUI": "2",
    //     "appKEY": "40AC8F0F6175E35885434826338633DA",
    //     "encryptionKey": "731FD184E0550EDB746A89FC048E307A",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -96,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1052,
    //     "prevFrameCounterFcnt": 2990,
    //     "latestFrameCounterFcnt": 2991,
    //     "lostFcnt": 353,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 530,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC10",
    //     "description": "C10",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000c630",
    //     "appEUI": "2",
    //     "appKEY": "860D364644C0582BA660DD1D01D49038",
    //     "encryptionKey": "E49FAE5062B28A9E491E7D86E06068AE",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -111,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1052,
    //     "prevFrameCounterFcnt": 2989,
    //     "latestFrameCounterFcnt": 2990,
    //     "lostFcnt": 344,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 531,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC13",
    //     "description": "C13",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d77e",
    //     "appEUI": "2",
    //     "appKEY": "EDEC8F804F2DF0053D37E25E1975C12C",
    //     "encryptionKey": "1010A9ECE0D7CCD6424E73DBEB4C8534",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1033,
    //     "prevFrameCounterFcnt": 2971,
    //     "latestFrameCounterFcnt": 2972,
    //     "lostFcnt": 439,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 532,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC14",
    //     "description": "C14",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000cde9",
    //     "appEUI": "2",
    //     "appKEY": "5BCD2705CA9F3B110A3A79ADC145AE5B",
    //     "encryptionKey": "4612E566A3B8AFDA2743430120B222F2",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1050,
    //     "prevFrameCounterFcnt": 2989,
    //     "latestFrameCounterFcnt": 2990,
    //     "lostFcnt": 745,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 533,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC15",
    //     "description": "C15",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000c8b9",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1051,
    //     "prevFrameCounterFcnt": 2989,
    //     "latestFrameCounterFcnt": 2990,
    //     "lostFcnt": 422,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 534,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC115",
    //     "description": "000781370000D7F5",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d7f5",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 3,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2950,
    //     "latestFrameCounterFcnt": 2952,
    //     "lostFcnt": 613,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 535,
    //     "gatewaysId": null,
    //     "name": "WaterExternalHISGOC116",
    //     "description": "000781370000D720",
    //     "provider": null,
    //     "userId": 64,
    //     "typeSensor": "61",
    //     "sensorTypeInfo": {
    //         "id": 61,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "ItronCyble5",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000d720",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb5a4b0e",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1013,
    //     "prevFrameCounterFcnt": 2950,
    //     "latestFrameCounterFcnt": 2951,
    //     "lostFcnt": 319,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb5a4b0e",
    //     "messageTime": null
    // },
    // {
    //     "id": 640,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC02",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a742",
    //     "appEUI": "2",
    //     "appKEY": "FF297EFAE0C6423D62639ABF3434BF09",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -89,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 591,
    //     "prevFrameCounterFcnt": 227,
    //     "latestFrameCounterFcnt": 228,
    //     "lostFcnt": -971,
    //     "loraSNR": 13,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 641,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC05",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a745",
    //     "appEUI": "2",
    //     "appKEY": "2C6F3E2EC9934FE1627B6DE6AFF7C189",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -95,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 592,
    //     "prevFrameCounterFcnt": 227,
    //     "latestFrameCounterFcnt": 228,
    //     "lostFcnt": -967,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 642,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC06",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a441",
    //     "appEUI": "2",
    //     "appKEY": "6969FE3FE6B833588F31EE1CBAF9E4FC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -92,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 588,
    //     "prevFrameCounterFcnt": 227,
    //     "latestFrameCounterFcnt": 228,
    //     "lostFcnt": -966,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 643,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC09",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a43e",
    //     "appEUI": "2",
    //     "appKEY": "71ECDBBF560026C0FD2CBE6A8947E93B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -94,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 593,
    //     "prevFrameCounterFcnt": 224,
    //     "latestFrameCounterFcnt": 225,
    //     "lostFcnt": -984,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 644,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC10",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a43c",
    //     "appEUI": "2",
    //     "appKEY": "BF003DB7D47B7D9977FB386D6C7CF39F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -86,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 600,
    //     "prevFrameCounterFcnt": 222,
    //     "latestFrameCounterFcnt": 223,
    //     "lostFcnt": -994,
    //     "loraSNR": 12,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 645,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC13",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a44d",
    //     "appEUI": "2",
    //     "appKEY": "FB33DE1071D92D205D57304BE4E7E5B9",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -89,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 586,
    //     "prevFrameCounterFcnt": 224,
    //     "latestFrameCounterFcnt": 225,
    //     "lostFcnt": -956,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 646,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC14",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a44a",
    //     "appEUI": "2",
    //     "appKEY": "201347DE3E0DF0B1C0E530738B25FCE6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -105,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 599,
    //     "prevFrameCounterFcnt": 230,
    //     "latestFrameCounterFcnt": 231,
    //     "lostFcnt": -991,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 647,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC17",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a444",
    //     "appEUI": "2",
    //     "appKEY": "C373F817D9E057CCD211CA832AF938A1",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -84,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 601,
    //     "prevFrameCounterFcnt": 236,
    //     "latestFrameCounterFcnt": 237,
    //     "lostFcnt": -975,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 648,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC20",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a442",
    //     "appEUI": "2",
    //     "appKEY": "A165EA8787A724F05994C275D2B51B4B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -101,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 588,
    //     "prevFrameCounterFcnt": 226,
    //     "latestFrameCounterFcnt": 227,
    //     "lostFcnt": -963,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 649,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC21",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a427",
    //     "appEUI": "2",
    //     "appKEY": "B2AD70639824BEBEF7C5482D4BE370EF",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -96,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 590,
    //     "prevFrameCounterFcnt": 225,
    //     "latestFrameCounterFcnt": 226,
    //     "lostFcnt": -967,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 650,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC24",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a422",
    //     "appEUI": "2",
    //     "appKEY": "C26FD37DBE32EBA88E87CAE0D5E46F6B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -95,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 594,
    //     "prevFrameCounterFcnt": 229,
    //     "latestFrameCounterFcnt": 230,
    //     "lostFcnt": -973,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 651,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC25",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a423",
    //     "appEUI": "2",
    //     "appKEY": "782B96292DDF95535BDD9C2604C2DCF4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -92,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 592,
    //     "prevFrameCounterFcnt": 241,
    //     "latestFrameCounterFcnt": 242,
    //     "lostFcnt": -973,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 652,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC28",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a42a",
    //     "appEUI": "2",
    //     "appKEY": "3E51122386FBCA535370556EB3755E9D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -96,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 590,
    //     "prevFrameCounterFcnt": 226,
    //     "latestFrameCounterFcnt": 227,
    //     "lostFcnt": -970,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 653,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC30",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a42c",
    //     "appEUI": "2",
    //     "appKEY": "41F4D3B182FB03A223377C0B37E834DD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -90,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 590,
    //     "prevFrameCounterFcnt": 222,
    //     "latestFrameCounterFcnt": 224,
    //     "lostFcnt": -965,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 654,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC31",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a738",
    //     "appEUI": "2",
    //     "appKEY": "645E2609D4D637D3672D4A13000214EE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -95,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 596,
    //     "prevFrameCounterFcnt": 234,
    //     "latestFrameCounterFcnt": 235,
    //     "lostFcnt": -955,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 655,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC33",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a73a",
    //     "appEUI": "2",
    //     "appKEY": "EAC6F131896BC5836CE61F105E2FC32E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -92,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 595,
    //     "prevFrameCounterFcnt": 228,
    //     "latestFrameCounterFcnt": 229,
    //     "lostFcnt": -956,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 656,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC34",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a739",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -88,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 596,
    //     "prevFrameCounterFcnt": 1152,
    //     "latestFrameCounterFcnt": 1153,
    //     "lostFcnt": 44,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 657,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC37",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a450",
    //     "appEUI": "2",
    //     "appKEY": "B8273A6D70720146537CE1449F999C3C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -96,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 612,
    //     "prevFrameCounterFcnt": 230,
    //     "latestFrameCounterFcnt": 231,
    //     "lostFcnt": -991,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 658,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC38",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a44e",
    //     "appEUI": "2",
    //     "appKEY": "65E341B10F884F0A0E920143D0DCD472",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -101,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 601,
    //     "prevFrameCounterFcnt": 237,
    //     "latestFrameCounterFcnt": 238,
    //     "lostFcnt": -997,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 659,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC41",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a453",
    //     "appEUI": "2",
    //     "appKEY": "F5AC70BCBF87A4E3ED6390F5D49382FD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -101,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 608,
    //     "prevFrameCounterFcnt": 248,
    //     "latestFrameCounterFcnt": 249,
    //     "lostFcnt": -956,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 660,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC43",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a457",
    //     "appEUI": "2",
    //     "appKEY": "BC60B9E9CFAE27D6E52570FE533807EF",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -97,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 601,
    //     "prevFrameCounterFcnt": 231,
    //     "latestFrameCounterFcnt": 232,
    //     "lostFcnt": -980,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 661,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC44",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a455",
    //     "appEUI": "2",
    //     "appKEY": "E4FF6D0DD9420DC283D30EB282F6D621",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -99,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 596,
    //     "prevFrameCounterFcnt": 239,
    //     "latestFrameCounterFcnt": 240,
    //     "lostFcnt": -953,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 662,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC46",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a72d",
    //     "appEUI": "2",
    //     "appKEY": "7FE3CDDB475AC3879C1E835BF8F20621",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -103,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 599,
    //     "prevFrameCounterFcnt": 229,
    //     "latestFrameCounterFcnt": 230,
    //     "lostFcnt": -961,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 663,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC47",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a438",
    //     "appEUI": "2",
    //     "appKEY": "6B239754B554CA9651FFE7E11DD36EC4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -98,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 599,
    //     "prevFrameCounterFcnt": 230,
    //     "latestFrameCounterFcnt": 231,
    //     "lostFcnt": -966,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 664,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC50",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a43b",
    //     "appEUI": "2",
    //     "appKEY": "B1D6F0854BCB2EA9AF63C8448FCAFA24",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -102,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 600,
    //     "prevFrameCounterFcnt": 234,
    //     "latestFrameCounterFcnt": 235,
    //     "lostFcnt": -984,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 665,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC54",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a73e",
    //     "appEUI": "2",
    //     "appKEY": "529ABC4BCAFB45E9F084D7416C538C02",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 606,
    //     "prevFrameCounterFcnt": 228,
    //     "latestFrameCounterFcnt": 229,
    //     "lostFcnt": -982,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 666,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC55",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a73c",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -99,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 591,
    //     "prevFrameCounterFcnt": 1143,
    //     "latestFrameCounterFcnt": 1144,
    //     "lostFcnt": 59,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 667,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC58",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727643c",
    //     "appEUI": "2",
    //     "appKEY": "EA2B7EB3C6D6E2DBB6DF948B605CFC24",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -101,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 589,
    //     "prevFrameCounterFcnt": 235,
    //     "latestFrameCounterFcnt": 236,
    //     "lostFcnt": -950,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 668,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC59",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187276433",
    //     "appEUI": "2",
    //     "appKEY": "B7E4CB2BA2FAA4355B29D0E282EF76EC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -100,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 600,
    //     "prevFrameCounterFcnt": 223,
    //     "latestFrameCounterFcnt": 224,
    //     "lostFcnt": -984,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 669,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC63",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a734",
    //     "appEUI": "2",
    //     "appKEY": "6F7F8210E5B99B64B5FA20C4876888E6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -101,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 597,
    //     "prevFrameCounterFcnt": 225,
    //     "latestFrameCounterFcnt": 226,
    //     "lostFcnt": -970,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 670,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC64",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a733",
    //     "appEUI": "2",
    //     "appKEY": "98E437E251DA1C45FA3E9649B60F938D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -94,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 613,
    //     "prevFrameCounterFcnt": 231,
    //     "latestFrameCounterFcnt": 232,
    //     "lostFcnt": -987,
    //     "loraSNR": 12,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 671,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC65",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a732",
    //     "appEUI": "2",
    //     "appKEY": "EBE7B53875E8F1C72C827022C1DAED41",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -95,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 609,
    //     "prevFrameCounterFcnt": 231,
    //     "latestFrameCounterFcnt": 232,
    //     "lostFcnt": -963,
    //     "loraSNR": 13,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 672,
    //     "gatewaysId": "20",
    //     "name": "WaterExternalPOSGWTC69",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a431",
    //     "appEUI": "2",
    //     "appKEY": "EB1CA5624E66A4B0004BF17304F3A286",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -102,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 592,
    //     "prevFrameCounterFcnt": 232,
    //     "latestFrameCounterFcnt": 233,
    //     "lostFcnt": -965,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 673,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC70",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a42f",
    //     "appEUI": "2",
    //     "appKEY": "B4A4B1399A1FD3BBFB34EADC1D09DF7E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -102,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 599,
    //     "prevFrameCounterFcnt": 233,
    //     "latestFrameCounterFcnt": 234,
    //     "lostFcnt": -979,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 674,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC73",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a433",
    //     "appEUI": "2",
    //     "appKEY": "270BF27B8F4C737BF3410EF08864A1F0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -95,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 609,
    //     "prevFrameCounterFcnt": 242,
    //     "latestFrameCounterFcnt": 243,
    //     "lostFcnt": -983,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 675,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC74",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a435",
    //     "appEUI": "2",
    //     "appKEY": "7B1274186A20292346EE3F90F76313E3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -99,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 601,
    //     "prevFrameCounterFcnt": 244,
    //     "latestFrameCounterFcnt": 245,
    //     "lostFcnt": -973,
    //     "loraSNR": 12,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 676,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC77",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187275bd6",
    //     "appEUI": "2",
    //     "appKEY": "15D8492AF6D34804DBF84464EAA01603",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 667,
    //     "prevFrameCounterFcnt": 212,
    //     "latestFrameCounterFcnt": 213,
    //     "lostFcnt": -962,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 677,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC78",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727643f",
    //     "appEUI": "2",
    //     "appKEY": "43528BE074F866EF6C7323477296F714",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -105,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 600,
    //     "prevFrameCounterFcnt": 225,
    //     "latestFrameCounterFcnt": 226,
    //     "lostFcnt": -971,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 678,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC81",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a730",
    //     "appEUI": "2",
    //     "appKEY": "61F991D31DFFEC010E7A03B57205DD8C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -102,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 587,
    //     "prevFrameCounterFcnt": 237,
    //     "latestFrameCounterFcnt": 238,
    //     "lostFcnt": -954,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 679,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC82",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187276434",
    //     "appEUI": "2",
    //     "appKEY": "432CC6633FD6EC17E06D8218593BF7FC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -94,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 595,
    //     "prevFrameCounterFcnt": 227,
    //     "latestFrameCounterFcnt": 228,
    //     "lostFcnt": -973,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 680,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC85",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a72b",
    //     "appEUI": "2",
    //     "appKEY": "139A9C6E5D9E1519C9A2981EEDE73386",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -102,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 620,
    //     "prevFrameCounterFcnt": 238,
    //     "latestFrameCounterFcnt": 239,
    //     "lostFcnt": -997,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 681,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC03",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a743",
    //     "appEUI": "2",
    //     "appKEY": "BFB232D94C45C894FBBD4F30328AC8F8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -86,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 593,
    //     "prevFrameCounterFcnt": 226,
    //     "latestFrameCounterFcnt": 227,
    //     "lostFcnt": -975,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 682,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC04",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a744",
    //     "appEUI": "2",
    //     "appKEY": "50506B1F234C9EA27CA36557785DCBF2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -95,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 591,
    //     "prevFrameCounterFcnt": 220,
    //     "latestFrameCounterFcnt": 221,
    //     "lostFcnt": -982,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 683,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC07",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a440",
    //     "appEUI": "2",
    //     "appKEY": "9C34EECE2FCCC4AF45A52D67AA2D67B2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -93,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 591,
    //     "prevFrameCounterFcnt": 233,
    //     "latestFrameCounterFcnt": 234,
    //     "lostFcnt": -947,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 684,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC08",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a43f",
    //     "appEUI": "2",
    //     "appKEY": "63E84320D9195A90AD2787CA4D9BDA0C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -99,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 604,
    //     "prevFrameCounterFcnt": 232,
    //     "latestFrameCounterFcnt": 233,
    //     "lostFcnt": -976,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 685,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC11",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a447",
    //     "appEUI": "2",
    //     "appKEY": "EE9652A5DFAF5ED457E1881ABA7D7BE6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -94,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 596,
    //     "prevFrameCounterFcnt": 232,
    //     "latestFrameCounterFcnt": 233,
    //     "lostFcnt": -980,
    //     "loraSNR": 12,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 686,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC12",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a448",
    //     "appEUI": "2",
    //     "appKEY": "4C48EED110A4F49AEC1798957B897246",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -94,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 593,
    //     "prevFrameCounterFcnt": 234,
    //     "latestFrameCounterFcnt": 235,
    //     "lostFcnt": -966,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 687,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC15",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a44c",
    //     "appEUI": "2",
    //     "appKEY": "D869C78B82F06648EF3C2DB9800284F1",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -99,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 588,
    //     "prevFrameCounterFcnt": 235,
    //     "latestFrameCounterFcnt": 236,
    //     "lostFcnt": -978,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 688,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC16",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a446",
    //     "appEUI": "2",
    //     "appKEY": "E478F9B8E371728BB8F2158C7396C37F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -96,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 590,
    //     "prevFrameCounterFcnt": 242,
    //     "latestFrameCounterFcnt": 243,
    //     "lostFcnt": -963,
    //     "loraSNR": 12,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 689,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC18",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a443",
    //     "appEUI": "2",
    //     "appKEY": "DC2E7B6D9591EDCEE3C0CDB6DA82A0C7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -91,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 592,
    //     "prevFrameCounterFcnt": 235,
    //     "latestFrameCounterFcnt": 236,
    //     "lostFcnt": -966,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 690,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC19",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a445",
    //     "appEUI": "2",
    //     "appKEY": "6AD14BC205248D45F9BC80D4B086C4A4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -95,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 591,
    //     "prevFrameCounterFcnt": 221,
    //     "latestFrameCounterFcnt": 222,
    //     "lostFcnt": -966,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 691,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC22",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a425",
    //     "appEUI": "2",
    //     "appKEY": "73A22B045180F0135BF8C9D28BD18D65",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -86,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 591,
    //     "prevFrameCounterFcnt": 230,
    //     "latestFrameCounterFcnt": 231,
    //     "lostFcnt": -963,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 692,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC23",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a426",
    //     "appEUI": "2",
    //     "appKEY": "030A1127E8B9465CE7B81536D2C44974",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -90,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 591,
    //     "prevFrameCounterFcnt": 227,
    //     "latestFrameCounterFcnt": 228,
    //     "lostFcnt": -955,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 693,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC26",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a428",
    //     "appEUI": "2",
    //     "appKEY": "68BD7EBD8026435665D3C61AE520DAB2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -91,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 587,
    //     "prevFrameCounterFcnt": 227,
    //     "latestFrameCounterFcnt": 228,
    //     "lostFcnt": -979,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 694,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC27",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a429",
    //     "appEUI": "2",
    //     "appKEY": "BABC37A79531FFC576E30CF96897BE30",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -90,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 590,
    //     "prevFrameCounterFcnt": 234,
    //     "latestFrameCounterFcnt": 235,
    //     "lostFcnt": -966,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 695,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC29",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a42b",
    //     "appEUI": "2",
    //     "appKEY": "7DE5EC708722074F6B1CE11B568B7F3F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -88,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 592,
    //     "prevFrameCounterFcnt": 231,
    //     "latestFrameCounterFcnt": 232,
    //     "lostFcnt": -981,
    //     "loraSNR": 13,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 696,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC32",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a737",
    //     "appEUI": "2",
    //     "appKEY": "C79C3951ADB45D96801625E13B118A66",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -81,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 641,
    //     "prevFrameCounterFcnt": 224,
    //     "latestFrameCounterFcnt": 225,
    //     "lostFcnt": -1029,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 697,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC35",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a73b",
    //     "appEUI": "2",
    //     "appKEY": "D60396393950B1CA96CB34197A6DE915",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -89,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 588,
    //     "prevFrameCounterFcnt": 226,
    //     "latestFrameCounterFcnt": 227,
    //     "lostFcnt": -949,
    //     "loraSNR": 12,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 698,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC36",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a73d",
    //     "appEUI": "2",
    //     "appKEY": "EEFF970BE73B258D250CF720832925F2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -87,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 587,
    //     "prevFrameCounterFcnt": 224,
    //     "latestFrameCounterFcnt": 225,
    //     "lostFcnt": -966,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 699,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC39",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a451",
    //     "appEUI": "2",
    //     "appKEY": "A1FBDD817F2FCFD958CC7B34ED84B595",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -101,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 587,
    //     "prevFrameCounterFcnt": 232,
    //     "latestFrameCounterFcnt": 233,
    //     "lostFcnt": -963,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 700,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC40",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a452",
    //     "appEUI": "2",
    //     "appKEY": "8D19E5C36438BD96D72E906C521EA18A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -100,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 591,
    //     "prevFrameCounterFcnt": 232,
    //     "latestFrameCounterFcnt": 233,
    //     "lostFcnt": -950,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 701,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC42",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a454",
    //     "appEUI": "2",
    //     "appKEY": "93BF66E3300F3DE2B562C6EB3F3A49B8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -101,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 610,
    //     "prevFrameCounterFcnt": 227,
    //     "latestFrameCounterFcnt": 228,
    //     "lostFcnt": -995,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 702,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC45",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a72e",
    //     "appEUI": "2",
    //     "appKEY": "D43C498BC2673C919442326CB5F6C4C9",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -99,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 595,
    //     "prevFrameCounterFcnt": 227,
    //     "latestFrameCounterFcnt": 228,
    //     "lostFcnt": -988,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 703,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC48",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a439",
    //     "appEUI": "2",
    //     "appKEY": "9392E1D39A4917BCEC6479C23CAE21BD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -101,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 595,
    //     "prevFrameCounterFcnt": 234,
    //     "latestFrameCounterFcnt": 235,
    //     "lostFcnt": -975,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 704,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC49",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a43a",
    //     "appEUI": "2",
    //     "appKEY": "C35FB8432DB3571B9EDF0C397F55B1A9",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -100,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 599,
    //     "prevFrameCounterFcnt": 241,
    //     "latestFrameCounterFcnt": 242,
    //     "lostFcnt": -953,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 705,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC52",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a740",
    //     "appEUI": "2",
    //     "appKEY": "DF0A8500CD51B65C6FC266D9D984A781",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -105,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 587,
    //     "prevFrameCounterFcnt": 230,
    //     "latestFrameCounterFcnt": 231,
    //     "lostFcnt": -968,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 706,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC53",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a73f",
    //     "appEUI": "2",
    //     "appKEY": "8A92D3938777914B4EC5358336D9AC98",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -96,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 591,
    //     "prevFrameCounterFcnt": 222,
    //     "latestFrameCounterFcnt": 223,
    //     "lostFcnt": -960,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 707,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC56",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727792e",
    //     "appEUI": "2",
    //     "appKEY": "BB0C3202997CAA61C885DD32062FE32F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -98,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 602,
    //     "prevFrameCounterFcnt": 228,
    //     "latestFrameCounterFcnt": 229,
    //     "lostFcnt": -978,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 708,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC57",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187277930",
    //     "appEUI": "2",
    //     "appKEY": "A24FF075524263598B5C37207777F3E2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -94,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 610,
    //     "prevFrameCounterFcnt": 234,
    //     "latestFrameCounterFcnt": 235,
    //     "lostFcnt": -1003,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 709,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC60",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187276978",
    //     "appEUI": "2",
    //     "appKEY": "0ADB1F7094F22B604F17AD512BA0F36B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -106,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 598,
    //     "prevFrameCounterFcnt": 225,
    //     "latestFrameCounterFcnt": 226,
    //     "lostFcnt": -964,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 710,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC61",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a736",
    //     "appEUI": "2",
    //     "appKEY": "B4A550F9600B1FE1CC171C1CC57F0621",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -99,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 594,
    //     "prevFrameCounterFcnt": 237,
    //     "latestFrameCounterFcnt": 238,
    //     "lostFcnt": -963,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 711,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC62",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a735",
    //     "appEUI": "2",
    //     "appKEY": "B604C62B6058B70F16393F42D79C7DDC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -97,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 594,
    //     "prevFrameCounterFcnt": 233,
    //     "latestFrameCounterFcnt": 234,
    //     "lostFcnt": -970,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 712,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC66",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a436",
    //     "appEUI": "2",
    //     "appKEY": "54AEDCBF48C592F3599C86C61E8410FE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -101,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 598,
    //     "prevFrameCounterFcnt": 235,
    //     "latestFrameCounterFcnt": 236,
    //     "lostFcnt": -975,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 713,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC67",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a437",
    //     "appEUI": "2",
    //     "appKEY": "BBB08E0EF2BEF2918E867DE213FCF351",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -95,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 600,
    //     "prevFrameCounterFcnt": 233,
    //     "latestFrameCounterFcnt": 234,
    //     "lostFcnt": -991,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 714,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC68",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a432",
    //     "appEUI": "2",
    //     "appKEY": "381539040215F57625C235DCA8010AEF",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -99,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 611,
    //     "prevFrameCounterFcnt": 241,
    //     "latestFrameCounterFcnt": 242,
    //     "lostFcnt": -972,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 715,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC71",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a456",
    //     "appEUI": "2",
    //     "appKEY": "C00A45CD37341AA1509EC09A61C50A6C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -112,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 591,
    //     "prevFrameCounterFcnt": 242,
    //     "latestFrameCounterFcnt": 243,
    //     "lostFcnt": -866,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 716,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC72",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a434",
    //     "appEUI": "2",
    //     "appKEY": "91D1399C83CF3D2B674C60643BD84249",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -106,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 589,
    //     "prevFrameCounterFcnt": 241,
    //     "latestFrameCounterFcnt": 242,
    //     "lostFcnt": -957,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 717,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC75",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a42d",
    //     "appEUI": "2",
    //     "appKEY": "BEED9FCC1174E515A0085FA860173CB6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -112,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 595,
    //     "prevFrameCounterFcnt": 231,
    //     "latestFrameCounterFcnt": 232,
    //     "lostFcnt": -985,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 718,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC76",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a42e",
    //     "appEUI": "2",
    //     "appKEY": "229883AB9A4E053F8B28641D63919139",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -102,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 605,
    //     "prevFrameCounterFcnt": 228,
    //     "latestFrameCounterFcnt": 229,
    //     "lostFcnt": -989,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 719,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC79",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a72f",
    //     "appEUI": "2",
    //     "appKEY": "E8371C5CF623B703AFD72C9940D0A798",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -99,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 595,
    //     "prevFrameCounterFcnt": 239,
    //     "latestFrameCounterFcnt": 240,
    //     "lostFcnt": -961,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 720,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC80",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872776ba",
    //     "appEUI": "2",
    //     "appKEY": "35A115D5F38F24941EF8DFC8399FBEAC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -100,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 604,
    //     "prevFrameCounterFcnt": 223,
    //     "latestFrameCounterFcnt": 224,
    //     "lostFcnt": -963,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 721,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC83",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a731",
    //     "appEUI": "2",
    //     "appKEY": "A9E5E57011BF173CB9A2C07AE5276805",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -102,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 599,
    //     "prevFrameCounterFcnt": 239,
    //     "latestFrameCounterFcnt": 240,
    //     "lostFcnt": -970,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 722,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC84",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187276979",
    //     "appEUI": "2",
    //     "appKEY": "A2AB4AD7BF0C8FF8AAC7A79AB3BB4400",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -100,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 595,
    //     "prevFrameCounterFcnt": 235,
    //     "latestFrameCounterFcnt": 236,
    //     "lostFcnt": -983,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 723,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC51",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-06-30",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a43d",
    //     "appEUI": "2",
    //     "appKEY": "E2D572FF3FECDD99C35E9AC60426CC94",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 555,
    //     "prevFrameCounterFcnt": 238,
    //     "latestFrameCounterFcnt": 239,
    //     "lostFcnt": -921,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 724,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC01",
    //     "description": null,
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": "64",
    //     "sensorTypeInfo": {
    //         "id": 64,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a741",
    //     "appEUI": "2",
    //     "appKEY": "055D7C65776EF66242EE2860A3A4911B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": -114,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 597,
    //     "prevFrameCounterFcnt": 222,
    //     "latestFrameCounterFcnt": 223,
    //     "lostFcnt": -980,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 725,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC13",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a424",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 70,
    //     "latestFrameCounterFcnt": 100,
    //     "lostFcnt": 29,
    //     "loraSNR": -19,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 726,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC14",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a421",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 402,
    //     "prevFrameCounterFcnt": 84,
    //     "latestFrameCounterFcnt": 85,
    //     "lostFcnt": -327,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 727,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC18",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3c7",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 396,
    //     "prevFrameCounterFcnt": 96,
    //     "latestFrameCounterFcnt": 236,
    //     "lostFcnt": -174,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 728,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC19",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3c6",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 402,
    //     "prevFrameCounterFcnt": 95,
    //     "latestFrameCounterFcnt": 232,
    //     "lostFcnt": -193,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 729,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC20",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3b9",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 421,
    //     "prevFrameCounterFcnt": 87,
    //     "latestFrameCounterFcnt": 223,
    //     "lostFcnt": -220,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 730,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC21",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3b8",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 433,
    //     "prevFrameCounterFcnt": 109,
    //     "latestFrameCounterFcnt": 358,
    //     "lostFcnt": -101,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 731,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC22",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3b7",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 421,
    //     "prevFrameCounterFcnt": 432,
    //     "latestFrameCounterFcnt": 82,
    //     "lostFcnt": -341,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 732,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC23",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3b6",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 415,
    //     "prevFrameCounterFcnt": 89,
    //     "latestFrameCounterFcnt": 228,
    //     "lostFcnt": -207,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 733,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC24",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3b5",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 399,
    //     "prevFrameCounterFcnt": 88,
    //     "latestFrameCounterFcnt": 89,
    //     "lostFcnt": -334,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 734,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC25",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3b4",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -108,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 403,
    //     "prevFrameCounterFcnt": 188,
    //     "latestFrameCounterFcnt": 189,
    //     "lostFcnt": -239,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 735,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC26",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3b3",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -111,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 400,
    //     "prevFrameCounterFcnt": 89,
    //     "latestFrameCounterFcnt": 90,
    //     "lostFcnt": -331,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 736,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC27",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3b1",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 410,
    //     "prevFrameCounterFcnt": 104,
    //     "latestFrameCounterFcnt": 105,
    //     "lostFcnt": -328,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 737,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC28",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3b0",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -111,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 393,
    //     "prevFrameCounterFcnt": 101,
    //     "latestFrameCounterFcnt": 102,
    //     "lostFcnt": -312,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 738,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC29",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3af",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 397,
    //     "prevFrameCounterFcnt": 94,
    //     "latestFrameCounterFcnt": 230,
    //     "lostFcnt": -192,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 739,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC30",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3ae",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 414,
    //     "prevFrameCounterFcnt": 111,
    //     "latestFrameCounterFcnt": 112,
    //     "lostFcnt": -326,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 740,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC31",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3ad",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 428,
    //     "prevFrameCounterFcnt": 61,
    //     "latestFrameCounterFcnt": 196,
    //     "lostFcnt": -253,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 741,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC32",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3ac",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 408,
    //     "prevFrameCounterFcnt": 71,
    //     "latestFrameCounterFcnt": 210,
    //     "lostFcnt": -221,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 742,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC33",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3ab",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 414,
    //     "prevFrameCounterFcnt": 85,
    //     "latestFrameCounterFcnt": 227,
    //     "lostFcnt": -204,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 743,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC37",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a405",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 49,
    //     "prevFrameCounterFcnt": 94,
    //     "latestFrameCounterFcnt": 238,
    //     "lostFcnt": 174,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 744,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC38",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a404",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -116,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 415,
    //     "prevFrameCounterFcnt": 83,
    //     "latestFrameCounterFcnt": 84,
    //     "lostFcnt": -352,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 745,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC39",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a403",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 415,
    //     "prevFrameCounterFcnt": 242,
    //     "latestFrameCounterFcnt": 243,
    //     "lostFcnt": -195,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 746,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC42",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a400",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 427,
    //     "prevFrameCounterFcnt": 75,
    //     "latestFrameCounterFcnt": 77,
    //     "lostFcnt": -369,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 747,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC43",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3ff",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 58,
    //     "prevFrameCounterFcnt": 95,
    //     "latestFrameCounterFcnt": 97,
    //     "lostFcnt": 30,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 748,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC44",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a414",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 393,
    //     "prevFrameCounterFcnt": 47,
    //     "latestFrameCounterFcnt": 48,
    //     "lostFcnt": -367,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 749,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC45",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3df",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 199,
    //     "prevFrameCounterFcnt": 65,
    //     "latestFrameCounterFcnt": 67,
    //     "lostFcnt": -140,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 750,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC50",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3c2",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 395,
    //     "prevFrameCounterFcnt": 69,
    //     "latestFrameCounterFcnt": 203,
    //     "lostFcnt": -207,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 751,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC51",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3bd",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 427,
    //     "prevFrameCounterFcnt": 102,
    //     "latestFrameCounterFcnt": 250,
    //     "lostFcnt": -201,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 752,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC52",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3c3",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 458,
    //     "prevFrameCounterFcnt": 97,
    //     "latestFrameCounterFcnt": 98,
    //     "lostFcnt": -382,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 753,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC53",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3c5",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 449,
    //     "prevFrameCounterFcnt": 450,
    //     "latestFrameCounterFcnt": 459,
    //     "lostFcnt": 8,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 754,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC54",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3c0",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 448,
    //     "prevFrameCounterFcnt": 46,
    //     "latestFrameCounterFcnt": 49,
    //     "lostFcnt": -415,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 755,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC55",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3db",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 413,
    //     "prevFrameCounterFcnt": 67,
    //     "latestFrameCounterFcnt": 221,
    //     "lostFcnt": -212,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 756,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC56",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3dc",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 415,
    //     "prevFrameCounterFcnt": 108,
    //     "latestFrameCounterFcnt": 109,
    //     "lostFcnt": -331,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 757,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC57",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3d9",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 425,
    //     "prevFrameCounterFcnt": 91,
    //     "latestFrameCounterFcnt": 230,
    //     "lostFcnt": -219,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 758,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC58",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3d5",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 419,
    //     "prevFrameCounterFcnt": 72,
    //     "latestFrameCounterFcnt": 73,
    //     "lostFcnt": -368,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 759,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC59",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3b2",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 412,
    //     "prevFrameCounterFcnt": 67,
    //     "latestFrameCounterFcnt": 68,
    //     "lostFcnt": -366,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 760,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC60",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a41d",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 409,
    //     "prevFrameCounterFcnt": 88,
    //     "latestFrameCounterFcnt": 227,
    //     "lostFcnt": -205,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 761,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC61",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a41c",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 398,
    //     "prevFrameCounterFcnt": 99,
    //     "latestFrameCounterFcnt": 233,
    //     "lostFcnt": -183,
    //     "loraSNR": -20,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 762,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC62",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a41b",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 222,
    //     "prevFrameCounterFcnt": 222,
    //     "latestFrameCounterFcnt": 222,
    //     "lostFcnt": 0,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 763,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC63",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a41a",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -116,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 404,
    //     "prevFrameCounterFcnt": 112,
    //     "latestFrameCounterFcnt": 113,
    //     "lostFcnt": -313,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 764,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC65",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a418",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -116,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 425,
    //     "prevFrameCounterFcnt": 244,
    //     "latestFrameCounterFcnt": 245,
    //     "lostFcnt": -205,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 765,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC66",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a417",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 225,
    //     "prevFrameCounterFcnt": 80,
    //     "latestFrameCounterFcnt": 220,
    //     "lostFcnt": -25,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 766,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC67",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a416",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 64,
    //     "latestFrameCounterFcnt": 65,
    //     "lostFcnt": -146,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 767,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC68",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a415",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 209,
    //     "prevFrameCounterFcnt": 60,
    //     "latestFrameCounterFcnt": 61,
    //     "lostFcnt": -166,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 768,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC69",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a413",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 228,
    //     "prevFrameCounterFcnt": 68,
    //     "latestFrameCounterFcnt": 202,
    //     "lostFcnt": -48,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 769,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC70",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a412",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 45,
    //     "prevFrameCounterFcnt": 90,
    //     "latestFrameCounterFcnt": 227,
    //     "lostFcnt": 160,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 770,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC71",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a411",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 225,
    //     "prevFrameCounterFcnt": 38,
    //     "latestFrameCounterFcnt": 134,
    //     "lostFcnt": -104,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 771,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC72",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a410",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 399,
    //     "prevFrameCounterFcnt": 87,
    //     "latestFrameCounterFcnt": 227,
    //     "lostFcnt": -181,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 772,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC73",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a40f",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 428,
    //     "prevFrameCounterFcnt": 249,
    //     "latestFrameCounterFcnt": 250,
    //     "lostFcnt": -198,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 773,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC74",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a40e",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 401,
    //     "prevFrameCounterFcnt": 104,
    //     "latestFrameCounterFcnt": 237,
    //     "lostFcnt": -189,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 774,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC75",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a40d",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 405,
    //     "prevFrameCounterFcnt": 86,
    //     "latestFrameCounterFcnt": 88,
    //     "lostFcnt": -334,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 775,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC76",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a40c",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 432,
    //     "prevFrameCounterFcnt": 113,
    //     "latestFrameCounterFcnt": 257,
    //     "lostFcnt": -194,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 776,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC77",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a40a",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 415,
    //     "prevFrameCounterFcnt": 110,
    //     "latestFrameCounterFcnt": 111,
    //     "lostFcnt": -325,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 777,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC78",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a409",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 421,
    //     "prevFrameCounterFcnt": 54,
    //     "latestFrameCounterFcnt": 191,
    //     "lostFcnt": -258,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 778,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC79",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3aa",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 409,
    //     "prevFrameCounterFcnt": 93,
    //     "latestFrameCounterFcnt": 234,
    //     "lostFcnt": -191,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 779,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC80",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3a9",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 423,
    //     "prevFrameCounterFcnt": 99,
    //     "latestFrameCounterFcnt": 245,
    //     "lostFcnt": -191,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 780,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC81",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3a8",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 404,
    //     "prevFrameCounterFcnt": 63,
    //     "latestFrameCounterFcnt": 73,
    //     "lostFcnt": -345,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 781,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC83",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3a6",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 400,
    //     "prevFrameCounterFcnt": 58,
    //     "latestFrameCounterFcnt": 59,
    //     "lostFcnt": -364,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 782,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC84",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a5ee",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 407,
    //     "prevFrameCounterFcnt": 53,
    //     "latestFrameCounterFcnt": 55,
    //     "lostFcnt": -368,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 783,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC85",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a5ec",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 403,
    //     "prevFrameCounterFcnt": 46,
    //     "latestFrameCounterFcnt": 47,
    //     "lostFcnt": -369,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 784,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC86",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3ef",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 3,
    //     "prevFrameCounterFcnt": 188,
    //     "latestFrameCounterFcnt": 289,
    //     "lostFcnt": 268,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 785,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC87",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3ed",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 47,
    //     "prevFrameCounterFcnt": 85,
    //     "latestFrameCounterFcnt": 86,
    //     "lostFcnt": 25,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 786,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC88",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3ee",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 389,
    //     "prevFrameCounterFcnt": 92,
    //     "latestFrameCounterFcnt": 93,
    //     "lostFcnt": -304,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 787,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC89",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3e9",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 397,
    //     "prevFrameCounterFcnt": 61,
    //     "latestFrameCounterFcnt": 195,
    //     "lostFcnt": -217,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 788,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC92",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3eb",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 392,
    //     "prevFrameCounterFcnt": 102,
    //     "latestFrameCounterFcnt": 103,
    //     "lostFcnt": -312,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 789,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC93",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3ea",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 790,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC94",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3e7",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 144,
    //     "prevFrameCounterFcnt": 100,
    //     "latestFrameCounterFcnt": 234,
    //     "lostFcnt": 73,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 791,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC96",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3e5",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 395,
    //     "prevFrameCounterFcnt": 64,
    //     "latestFrameCounterFcnt": 147,
    //     "lostFcnt": -250,
    //     "loraSNR": -19,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 792,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC97",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3e4",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 443,
    //     "prevFrameCounterFcnt": 52,
    //     "latestFrameCounterFcnt": 53,
    //     "lostFcnt": -410,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 793,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC102",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3fb",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 794,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC108",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLc G3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3f8",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -103,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 184,
    //     "prevFrameCounterFcnt": 90,
    //     "latestFrameCounterFcnt": 91,
    //     "lostFcnt": -113,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 795,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC49",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3dd",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 390,
    //     "prevFrameCounterFcnt": 51,
    //     "latestFrameCounterFcnt": 52,
    //     "lostFcnt": -357,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 796,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC82",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3a7",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 54,
    //     "prevFrameCounterFcnt": 94,
    //     "latestFrameCounterFcnt": 98,
    //     "lostFcnt": 29,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 797,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC101",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3fc",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 397,
    //     "prevFrameCounterFcnt": 36,
    //     "latestFrameCounterFcnt": 43,
    //     "lostFcnt": -358,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 798,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC105",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3f5",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 399,
    //     "prevFrameCounterFcnt": 88,
    //     "latestFrameCounterFcnt": 90,
    //     "lostFcnt": -327,
    //     "loraSNR": -19,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 799,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC02",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3c1",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -116,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 55,
    //     "prevFrameCounterFcnt": 19,
    //     "latestFrameCounterFcnt": 22,
    //     "lostFcnt": -38,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 800,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC03",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3bf",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 801,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC04",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3be",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 411,
    //     "prevFrameCounterFcnt": 107,
    //     "latestFrameCounterFcnt": 109,
    //     "lostFcnt": -312,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 802,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC05",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3bc",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 402,
    //     "prevFrameCounterFcnt": 105,
    //     "latestFrameCounterFcnt": 246,
    //     "lostFcnt": -177,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 803,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC06",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3bb",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 396,
    //     "prevFrameCounterFcnt": 56,
    //     "latestFrameCounterFcnt": 57,
    //     "lostFcnt": -359,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 804,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC07",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3ba",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 202,
    //     "latestFrameCounterFcnt": 203,
    //     "lostFcnt": 173,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 805,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC08",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3f4",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 58,
    //     "prevFrameCounterFcnt": 100,
    //     "latestFrameCounterFcnt": 101,
    //     "lostFcnt": 33,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 806,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC09",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3f3",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 387,
    //     "prevFrameCounterFcnt": 131,
    //     "latestFrameCounterFcnt": 229,
    //     "lostFcnt": -172,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 807,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC10",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3f2",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 808,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC11",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3f1",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 415,
    //     "prevFrameCounterFcnt": 101,
    //     "latestFrameCounterFcnt": 102,
    //     "lostFcnt": -327,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 809,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC12",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3f0",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 177,
    //     "latestFrameCounterFcnt": 116,
    //     "lostFcnt": -62,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 810,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC34",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a40b",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 417,
    //     "prevFrameCounterFcnt": 88,
    //     "latestFrameCounterFcnt": 226,
    //     "lostFcnt": -207,
    //     "loraSNR": -19,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 811,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC35",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a407",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 398,
    //     "prevFrameCounterFcnt": 83,
    //     "latestFrameCounterFcnt": 85,
    //     "lostFcnt": -327,
    //     "loraSNR": -21,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 812,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC36",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a406",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 397,
    //     "prevFrameCounterFcnt": 91,
    //     "latestFrameCounterFcnt": 228,
    //     "lostFcnt": -179,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 813,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC40",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a402",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 40,
    //     "prevFrameCounterFcnt": 83,
    //     "latestFrameCounterFcnt": 219,
    //     "lostFcnt": 168,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 814,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC41",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a401",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 287,
    //     "prevFrameCounterFcnt": 287,
    //     "latestFrameCounterFcnt": 297,
    //     "lostFcnt": 9,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 815,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC46",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3e2",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 421,
    //     "prevFrameCounterFcnt": 43,
    //     "latestFrameCounterFcnt": 44,
    //     "lostFcnt": -388,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 816,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC47",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3e1",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 416,
    //     "prevFrameCounterFcnt": 90,
    //     "latestFrameCounterFcnt": 91,
    //     "lostFcnt": -345,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 817,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC48",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3de",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 390,
    //     "prevFrameCounterFcnt": 66,
    //     "latestFrameCounterFcnt": 68,
    //     "lostFcnt": -335,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 818,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC64",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a419",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 819,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC90",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3e8",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 408,
    //     "prevFrameCounterFcnt": 77,
    //     "latestFrameCounterFcnt": 212,
    //     "lostFcnt": -219,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 820,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC91",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3ec",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 821,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC95",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3e6",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 52,
    //     "prevFrameCounterFcnt": 93,
    //     "latestFrameCounterFcnt": 228,
    //     "lostFcnt": 160,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 822,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC98",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3e3",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 407,
    //     "prevFrameCounterFcnt": 89,
    //     "latestFrameCounterFcnt": 227,
    //     "lostFcnt": -186,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 823,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC99",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3fe",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 416,
    //     "prevFrameCounterFcnt": 82,
    //     "latestFrameCounterFcnt": 84,
    //     "lostFcnt": -350,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 824,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC100",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3fd",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 408,
    //     "prevFrameCounterFcnt": 82,
    //     "latestFrameCounterFcnt": 83,
    //     "lostFcnt": -346,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 825,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC103",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3fa",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 60,
    //     "prevFrameCounterFcnt": 101,
    //     "latestFrameCounterFcnt": 102,
    //     "lostFcnt": 27,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 826,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC106",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3f7",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 405,
    //     "prevFrameCounterFcnt": 90,
    //     "latestFrameCounterFcnt": 91,
    //     "lostFcnt": -334,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 827,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC107",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3f9",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 404,
    //     "prevFrameCounterFcnt": 89,
    //     "latestFrameCounterFcnt": 232,
    //     "lostFcnt": -177,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 828,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC01",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-12",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3c4",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 396,
    //     "prevFrameCounterFcnt": 78,
    //     "latestFrameCounterFcnt": 80,
    //     "lostFcnt": -330,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 829,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC15",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a420",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 273,
    //     "prevFrameCounterFcnt": 283,
    //     "latestFrameCounterFcnt": 44,
    //     "lostFcnt": -232,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 830,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC16",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a41f",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 395,
    //     "prevFrameCounterFcnt": 108,
    //     "latestFrameCounterFcnt": 243,
    //     "lostFcnt": -173,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 831,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC17",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a41e",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 402,
    //     "prevFrameCounterFcnt": 99,
    //     "latestFrameCounterFcnt": 101,
    //     "lostFcnt": -320,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 832,
    //     "gatewaysId": null,
    //     "name": "WaterExternalMOEGWTC104",
    //     "description": null,
    //     "provider": null,
    //     "userId": 67,
    //     "typeSensor": "65",
    //     "sensorTypeInfo": {
    //         "id": 65,
    //         "name": "oemsensors",
    //         "description": null,
    //         "sensorType": "oemsensors",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DiehlHRLcG3",
    //     "installationDate": "2021-07-17",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb818727a3f6",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": null,
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 412,
    //     "prevFrameCounterFcnt": 112,
    //     "latestFrameCounterFcnt": 250,
    //     "lostFcnt": -176,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb47efb7",
    //     "messageTime": null
    // },
    // {
    //     "id": 834,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC03",
    //     "description": "Contador_GESWAT_6209",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026209",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -60,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 743,
    //     "latestFrameCounterFcnt": 744,
    //     "lostFcnt": 193,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 835,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC01",
    //     "description": "Contador_GESWAT_7647",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450027647",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 278,
    //     "prevFrameCounterFcnt": 953,
    //     "latestFrameCounterFcnt": 954,
    //     "lostFcnt": 389,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 836,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC02",
    //     "description": "Contador_GESWAT_8641",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028641",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 202,
    //     "prevFrameCounterFcnt": 721,
    //     "latestFrameCounterFcnt": 722,
    //     "lostFcnt": 193,
    //     "loraSNR": 0,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 837,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC04",
    //     "description": "Contador_GESWAT_5793",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025793",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -102,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 861,
    //     "latestFrameCounterFcnt": 862,
    //     "lostFcnt": 251,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 838,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC05",
    //     "description": "Contador_GESWAT_5955",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025955",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 229,
    //     "prevFrameCounterFcnt": 832,
    //     "latestFrameCounterFcnt": 833,
    //     "lostFcnt": 239,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 839,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC06",
    //     "description": "Contador_GESWAT_8499",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028499",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -99,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 237,
    //     "prevFrameCounterFcnt": 810,
    //     "latestFrameCounterFcnt": 811,
    //     "lostFcnt": 251,
    //     "loraSNR": 12,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 840,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC07",
    //     "description": "Contador_GESWAT_5551",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025551",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 214,
    //     "prevFrameCounterFcnt": 771,
    //     "latestFrameCounterFcnt": 773,
    //     "lostFcnt": 216,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 841,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC08",
    //     "description": "Contador_GESWAT_6132",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026132",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 243,
    //     "prevFrameCounterFcnt": 826,
    //     "latestFrameCounterFcnt": 827,
    //     "lostFcnt": 232,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 842,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC09",
    //     "description": "Contador_GESWAT_8945",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028945",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 843,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC10",
    //     "description": "Contador_GESWAT_8594",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028594",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 206,
    //     "prevFrameCounterFcnt": 753,
    //     "latestFrameCounterFcnt": 754,
    //     "lostFcnt": 222,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 844,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC11",
    //     "description": "Contador_GESWAT_8822",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028822",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 196,
    //     "prevFrameCounterFcnt": 711,
    //     "latestFrameCounterFcnt": 712,
    //     "lostFcnt": 189,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 845,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC12",
    //     "description": "Contador_GESWAT_8798",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028798",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 667,
    //     "prevFrameCounterFcnt": 1210,
    //     "latestFrameCounterFcnt": 1211,
    //     "lostFcnt": 204,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 846,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC13",
    //     "description": "Contador_GESWAT_8942",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028942",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -112,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 222,
    //     "prevFrameCounterFcnt": 747,
    //     "latestFrameCounterFcnt": 748,
    //     "lostFcnt": 192,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 847,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC14",
    //     "description": "Contador_GESWAT_8920",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028920",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 194,
    //     "prevFrameCounterFcnt": 753,
    //     "latestFrameCounterFcnt": 754,
    //     "lostFcnt": 233,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 848,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC15",
    //     "description": "Contador_GESWAT_8913",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028913",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 227,
    //     "prevFrameCounterFcnt": 804,
    //     "latestFrameCounterFcnt": 805,
    //     "lostFcnt": 214,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 849,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC16",
    //     "description": "Contador_GESWAT_8824",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028824",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -91,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 198,
    //     "prevFrameCounterFcnt": 764,
    //     "latestFrameCounterFcnt": 765,
    //     "lostFcnt": 212,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 850,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC17",
    //     "description": "Contador_GESWAT_8644",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028644",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -91,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 197,
    //     "prevFrameCounterFcnt": 713,
    //     "latestFrameCounterFcnt": 714,
    //     "lostFcnt": 195,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 851,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC18",
    //     "description": "Contador_GESWAT_8649",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028649",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 239,
    //     "prevFrameCounterFcnt": 824,
    //     "latestFrameCounterFcnt": 825,
    //     "lostFcnt": 238,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 852,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC19",
    //     "description": "Contador_GESWAT_8584",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028584",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 200,
    //     "prevFrameCounterFcnt": 785,
    //     "latestFrameCounterFcnt": 786,
    //     "lostFcnt": 235,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 853,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC20",
    //     "description": "Contador_GESWAT_8586",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028586",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -106,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 202,
    //     "prevFrameCounterFcnt": 762,
    //     "latestFrameCounterFcnt": 763,
    //     "lostFcnt": 234,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 854,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC21",
    //     "description": "Contador_GESWAT_8909",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028909",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 221,
    //     "prevFrameCounterFcnt": 806,
    //     "latestFrameCounterFcnt": 807,
    //     "lostFcnt": 241,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 855,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC22",
    //     "description": "Contador_GESWAT_8838",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028838",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -110,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 223,
    //     "prevFrameCounterFcnt": 741,
    //     "latestFrameCounterFcnt": 742,
    //     "lostFcnt": 204,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 856,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC23",
    //     "description": "Contador_GESWAT_8750",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028750",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -97,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 218,
    //     "prevFrameCounterFcnt": 781,
    //     "latestFrameCounterFcnt": 782,
    //     "lostFcnt": 220,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 857,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC24",
    //     "description": "Contador_GESWAT_8616",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028616",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 193,
    //     "prevFrameCounterFcnt": 734,
    //     "latestFrameCounterFcnt": 735,
    //     "lostFcnt": 226,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 858,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC25",
    //     "description": "Contador_GESWAT_8529",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028529",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 205,
    //     "prevFrameCounterFcnt": 774,
    //     "latestFrameCounterFcnt": 775,
    //     "lostFcnt": 251,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 859,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC26",
    //     "description": "Contador_GESWAT_8557",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028557",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 227,
    //     "prevFrameCounterFcnt": 772,
    //     "latestFrameCounterFcnt": 773,
    //     "lostFcnt": 228,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 860,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC27",
    //     "description": "Contador_GESWAT_8561",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028561",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 224,
    //     "prevFrameCounterFcnt": 774,
    //     "latestFrameCounterFcnt": 775,
    //     "lostFcnt": 223,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 861,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC28",
    //     "description": "Contador_GESWAT_8780",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028780",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -94,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 191,
    //     "prevFrameCounterFcnt": 724,
    //     "latestFrameCounterFcnt": 725,
    //     "lostFcnt": 191,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 862,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC29",
    //     "description": "Contador_GESWAT_8898",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028898",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 243,
    //     "prevFrameCounterFcnt": 834,
    //     "latestFrameCounterFcnt": 835,
    //     "lostFcnt": 268,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 863,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC30",
    //     "description": "Contador_GESWAT_8797",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028797",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 227,
    //     "prevFrameCounterFcnt": 768,
    //     "latestFrameCounterFcnt": 769,
    //     "lostFcnt": 222,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 864,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC31",
    //     "description": "Contador_GESWAT_8856",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028856",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 865,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC32",
    //     "description": "Contador_GESWAT_9121",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450029121",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 217,
    //     "prevFrameCounterFcnt": 781,
    //     "latestFrameCounterFcnt": 782,
    //     "lostFcnt": 248,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 866,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC33",
    //     "description": "Contador_GESWAT_8630",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028630",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 242,
    //     "prevFrameCounterFcnt": 813,
    //     "latestFrameCounterFcnt": 814,
    //     "lostFcnt": 227,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 867,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC34",
    //     "description": "Contador_GESWAT_8536",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028536",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -108,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 208,
    //     "prevFrameCounterFcnt": 751,
    //     "latestFrameCounterFcnt": 752,
    //     "lostFcnt": 218,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 868,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC35",
    //     "description": "Contador_GESWAT_8766",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028766",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 209,
    //     "prevFrameCounterFcnt": 802,
    //     "latestFrameCounterFcnt": 803,
    //     "lostFcnt": 272,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 869,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC36",
    //     "description": "Contador_GESWAT_9120",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450029120",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -95,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 190,
    //     "prevFrameCounterFcnt": 699,
    //     "latestFrameCounterFcnt": 700,
    //     "lostFcnt": 185,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 870,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC37",
    //     "description": "Contador_GESWAT_8781",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028781",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -100,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 188,
    //     "prevFrameCounterFcnt": 686,
    //     "latestFrameCounterFcnt": 687,
    //     "lostFcnt": 178,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 871,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC38",
    //     "description": "Contador_GESWAT_8947",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028947",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 230,
    //     "prevFrameCounterFcnt": 780,
    //     "latestFrameCounterFcnt": 781,
    //     "lostFcnt": 209,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 872,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC39",
    //     "description": "Contador_GESWAT_8812",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028812",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -108,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 214,
    //     "prevFrameCounterFcnt": 747,
    //     "latestFrameCounterFcnt": 748,
    //     "lostFcnt": 216,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 873,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC40",
    //     "description": "Contador_GESWAT_8837",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028837",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 207,
    //     "prevFrameCounterFcnt": 779,
    //     "latestFrameCounterFcnt": 780,
    //     "lostFcnt": 249,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 874,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC41",
    //     "description": "Contador_GESWAT_5058",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025058",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 198,
    //     "prevFrameCounterFcnt": 744,
    //     "latestFrameCounterFcnt": 745,
    //     "lostFcnt": 217,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 875,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC42",
    //     "description": "Contador_GESWAT_5828",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025828",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 206,
    //     "prevFrameCounterFcnt": 760,
    //     "latestFrameCounterFcnt": 761,
    //     "lostFcnt": 228,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 876,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC43",
    //     "description": "Contador_GESWAT_5492",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025492",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -94,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 213,
    //     "prevFrameCounterFcnt": 731,
    //     "latestFrameCounterFcnt": 732,
    //     "lostFcnt": 194,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 877,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC44",
    //     "description": "Contador_GESWAT_6134",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026134",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -93,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 626,
    //     "prevFrameCounterFcnt": 1147,
    //     "latestFrameCounterFcnt": 1148,
    //     "lostFcnt": 194,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 878,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC45",
    //     "description": "Contador_GESWAT_8997",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028997",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -97,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 213,
    //     "prevFrameCounterFcnt": 723,
    //     "latestFrameCounterFcnt": 724,
    //     "lostFcnt": 198,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 879,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC46",
    //     "description": "Contador_GESWAT_6217",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026217",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -93,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 639,
    //     "prevFrameCounterFcnt": 1200,
    //     "latestFrameCounterFcnt": 1201,
    //     "lostFcnt": 235,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 880,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC47",
    //     "description": "Contador_GESWAT_5711",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025711",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -99,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 651,
    //     "prevFrameCounterFcnt": 1155,
    //     "latestFrameCounterFcnt": 1156,
    //     "lostFcnt": 181,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 881,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC48",
    //     "description": "Contador_GESWAT_5599",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025599",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -93,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 218,
    //     "prevFrameCounterFcnt": 751,
    //     "latestFrameCounterFcnt": 752,
    //     "lostFcnt": 213,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 882,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC49",
    //     "description": "Contador_GESWAT_4835",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450024835",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 788,
    //     "latestFrameCounterFcnt": 789,
    //     "lostFcnt": 248,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 883,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC50",
    //     "description": "Contador_GESWAT_5075",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025075",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 207,
    //     "prevFrameCounterFcnt": 756,
    //     "latestFrameCounterFcnt": 758,
    //     "lostFcnt": 230,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 884,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC51",
    //     "description": "Contador_GESWAT_5062",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025062",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 771,
    //     "latestFrameCounterFcnt": 772,
    //     "lostFcnt": 235,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 885,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC52",
    //     "description": "Contador_GESWAT_6001",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026001",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 886,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC53",
    //     "description": "Contador_GESWAT_5761",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025761",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 223,
    //     "prevFrameCounterFcnt": 759,
    //     "latestFrameCounterFcnt": 760,
    //     "lostFcnt": 209,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 887,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC54",
    //     "description": "Contador_GESWAT_5866",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025866",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -93,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 203,
    //     "prevFrameCounterFcnt": 734,
    //     "latestFrameCounterFcnt": 735,
    //     "lostFcnt": 212,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 888,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC55",
    //     "description": "Contador_GESWAT_5961",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025961",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -104,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 212,
    //     "prevFrameCounterFcnt": 732,
    //     "latestFrameCounterFcnt": 733,
    //     "lostFcnt": 202,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 889,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC56",
    //     "description": "Contador_GESWAT_8933",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028933",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -102,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 220,
    //     "prevFrameCounterFcnt": 764,
    //     "latestFrameCounterFcnt": 765,
    //     "lostFcnt": 216,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 890,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC57",
    //     "description": "Contador_GESWAT_5661",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025661",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -94,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 212,
    //     "prevFrameCounterFcnt": 754,
    //     "latestFrameCounterFcnt": 755,
    //     "lostFcnt": 216,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 891,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC58",
    //     "description": "Contador_GESWAT_4871",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450024871",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -110,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 210,
    //     "prevFrameCounterFcnt": 732,
    //     "latestFrameCounterFcnt": 733,
    //     "lostFcnt": 203,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 892,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC59",
    //     "description": "Contador_GESWAT_4803",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450024803",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 199,
    //     "prevFrameCounterFcnt": 731,
    //     "latestFrameCounterFcnt": 732,
    //     "lostFcnt": 206,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 893,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC60",
    //     "description": "Contador_GESWAT_5946",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025946",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 224,
    //     "prevFrameCounterFcnt": 767,
    //     "latestFrameCounterFcnt": 768,
    //     "lostFcnt": 212,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 894,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC61",
    //     "description": "Contador_GESWAT_4876",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450024876",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 192,
    //     "prevFrameCounterFcnt": 745,
    //     "latestFrameCounterFcnt": 746,
    //     "lostFcnt": 228,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 895,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC62",
    //     "description": "Contador_GESWAT_5481",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025481",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 206,
    //     "prevFrameCounterFcnt": 734,
    //     "latestFrameCounterFcnt": 735,
    //     "lostFcnt": 207,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 896,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC63",
    //     "description": "Contador_GESWAT_5412",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025412",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 221,
    //     "prevFrameCounterFcnt": 821,
    //     "latestFrameCounterFcnt": 822,
    //     "lostFcnt": 272,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 897,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC64",
    //     "description": "Contador_GESWAT_5102",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025102",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -106,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 221,
    //     "prevFrameCounterFcnt": 789,
    //     "latestFrameCounterFcnt": 790,
    //     "lostFcnt": 245,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 898,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC65",
    //     "description": "Contador_GESWAT_6191",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026191",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 199,
    //     "prevFrameCounterFcnt": 741,
    //     "latestFrameCounterFcnt": 742,
    //     "lostFcnt": 217,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 899,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC66",
    //     "description": "Contador_GESWAT_4927",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450024927",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -108,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 229,
    //     "prevFrameCounterFcnt": 844,
    //     "latestFrameCounterFcnt": 846,
    //     "lostFcnt": 289,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 900,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC67",
    //     "description": "Contador_GESWAT_5030",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025030",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 762,
    //     "latestFrameCounterFcnt": 763,
    //     "lostFcnt": 227,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 901,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC68",
    //     "description": "Contador_GESWAT_5079",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025079",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -96,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 213,
    //     "prevFrameCounterFcnt": 752,
    //     "latestFrameCounterFcnt": 753,
    //     "lostFcnt": 213,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 902,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC69",
    //     "description": "Contador_GESWAT_5155",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025155",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -81,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 207,
    //     "prevFrameCounterFcnt": 716,
    //     "latestFrameCounterFcnt": 717,
    //     "lostFcnt": 171,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 903,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC70",
    //     "description": "Contador_GESWAT_6086",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026086",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 904,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC71",
    //     "description": "Contador_GESWAT_9113",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450029113",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 231,
    //     "prevFrameCounterFcnt": 832,
    //     "latestFrameCounterFcnt": 833,
    //     "lostFcnt": 245,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 905,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC72",
    //     "description": "Contador_GESWAT_5572",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025572",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -102,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 206,
    //     "prevFrameCounterFcnt": 754,
    //     "latestFrameCounterFcnt": 755,
    //     "lostFcnt": 226,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 906,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC73",
    //     "description": "Contador_GESWAT_5029",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025029",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 213,
    //     "prevFrameCounterFcnt": 765,
    //     "latestFrameCounterFcnt": 766,
    //     "lostFcnt": 236,
    //     "loraSNR": 0,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 907,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC74",
    //     "description": "Contador_GESWAT_5537",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025537",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 217,
    //     "prevFrameCounterFcnt": 777,
    //     "latestFrameCounterFcnt": 778,
    //     "lostFcnt": 224,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 908,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC75",
    //     "description": "Contador_GESWAT_4997",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450024997",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 652,
    //     "prevFrameCounterFcnt": 1208,
    //     "latestFrameCounterFcnt": 1209,
    //     "lostFcnt": 230,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 909,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC76",
    //     "description": "Contador_GESWAT_5727",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025727",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 910,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC77",
    //     "description": "Contador_GESWAT_5843",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025843",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 911,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC78",
    //     "description": "Contador_GESWAT_5842",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025842",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -103,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 196,
    //     "prevFrameCounterFcnt": 742,
    //     "latestFrameCounterFcnt": 743,
    //     "lostFcnt": 218,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 912,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC79",
    //     "description": "Contador_GESWAT_4870",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450024870",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -103,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 207,
    //     "prevFrameCounterFcnt": 744,
    //     "latestFrameCounterFcnt": 745,
    //     "lostFcnt": 217,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 913,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC80",
    //     "description": "Contador_GESWAT_5570",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025570",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 205,
    //     "prevFrameCounterFcnt": 773,
    //     "latestFrameCounterFcnt": 774,
    //     "lostFcnt": 213,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 914,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC81",
    //     "description": "Contador_GESWAT_4973",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450024973",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 229,
    //     "prevFrameCounterFcnt": 802,
    //     "latestFrameCounterFcnt": 803,
    //     "lostFcnt": 226,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 915,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC82",
    //     "description": "Contador_GESWAT_6105",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026105",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -100,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 214,
    //     "prevFrameCounterFcnt": 739,
    //     "latestFrameCounterFcnt": 740,
    //     "lostFcnt": 201,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 916,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC83",
    //     "description": "Contador_GESWAT_5266",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025266",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 217,
    //     "prevFrameCounterFcnt": 810,
    //     "latestFrameCounterFcnt": 811,
    //     "lostFcnt": 226,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 917,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC84",
    //     "description": "Contador_GESWAT_5597",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025597",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -89,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 171,
    //     "prevFrameCounterFcnt": 717,
    //     "latestFrameCounterFcnt": 718,
    //     "lostFcnt": 221,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 918,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC85",
    //     "description": "Contador_GESWAT_5806",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025806",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -89,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 203,
    //     "prevFrameCounterFcnt": 722,
    //     "latestFrameCounterFcnt": 723,
    //     "lostFcnt": 198,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 919,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC86",
    //     "description": "Contador_GESWAT_4694",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450024694",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 219,
    //     "prevFrameCounterFcnt": 753,
    //     "latestFrameCounterFcnt": 754,
    //     "lostFcnt": 211,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 920,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC87",
    //     "description": "Contador_GESWAT_9054",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450029054",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 209,
    //     "prevFrameCounterFcnt": 753,
    //     "latestFrameCounterFcnt": 754,
    //     "lostFcnt": 206,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 921,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC88",
    //     "description": "Contador_GESWAT_6093",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026093",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 209,
    //     "prevFrameCounterFcnt": 788,
    //     "latestFrameCounterFcnt": 789,
    //     "lostFcnt": 257,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 922,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC89",
    //     "description": "Contador_GESWAT_6051",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026051",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -91,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 217,
    //     "prevFrameCounterFcnt": 736,
    //     "latestFrameCounterFcnt": 737,
    //     "lostFcnt": 188,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 923,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC90",
    //     "description": "Contador_GESWAT_5815",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025815",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -103,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 217,
    //     "prevFrameCounterFcnt": 789,
    //     "latestFrameCounterFcnt": 790,
    //     "lostFcnt": 246,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 924,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC91",
    //     "description": "Contador_GESWAT_8829",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028829",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -104,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 217,
    //     "prevFrameCounterFcnt": 736,
    //     "latestFrameCounterFcnt": 737,
    //     "lostFcnt": 200,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 925,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC92",
    //     "description": "Contador_GESWAT_6089",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026089",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 218,
    //     "prevFrameCounterFcnt": 756,
    //     "latestFrameCounterFcnt": 757,
    //     "lostFcnt": 221,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 926,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC93",
    //     "description": "Contador_GESWAT_5111",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025111",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 228,
    //     "prevFrameCounterFcnt": 789,
    //     "latestFrameCounterFcnt": 790,
    //     "lostFcnt": 235,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 927,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC94",
    //     "description": "Contador_GESWAT_5144",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025144",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -116,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 623,
    //     "prevFrameCounterFcnt": 1166,
    //     "latestFrameCounterFcnt": 1167,
    //     "lostFcnt": 219,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 928,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC95",
    //     "description": "Contador_GESWAT_6077",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026077",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 225,
    //     "prevFrameCounterFcnt": 758,
    //     "latestFrameCounterFcnt": 759,
    //     "lostFcnt": 208,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 929,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC96",
    //     "description": "Contador_GESWAT_9118",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450029118",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 193,
    //     "prevFrameCounterFcnt": 717,
    //     "latestFrameCounterFcnt": 718,
    //     "lostFcnt": 212,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 930,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC97",
    //     "description": "Contador_GESWAT_5926",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025926",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -102,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 197,
    //     "prevFrameCounterFcnt": 703,
    //     "latestFrameCounterFcnt": 704,
    //     "lostFcnt": 190,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 931,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC98",
    //     "description": "Contador_GESWAT_5209",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025209",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 213,
    //     "prevFrameCounterFcnt": 764,
    //     "latestFrameCounterFcnt": 765,
    //     "lostFcnt": 227,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 932,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC99",
    //     "description": "Contador_GESWAT_5618",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025618",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -116,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 218,
    //     "prevFrameCounterFcnt": 763,
    //     "latestFrameCounterFcnt": 764,
    //     "lostFcnt": 228,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 933,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC102",
    //     "description": "Contador_GESWAT_5621",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025621",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 220,
    //     "prevFrameCounterFcnt": 759,
    //     "latestFrameCounterFcnt": 760,
    //     "lostFcnt": 213,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 934,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC103",
    //     "description": "Contador_GESWAT_8659",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028659",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 587,
    //     "prevFrameCounterFcnt": 1114,
    //     "latestFrameCounterFcnt": 1115,
    //     "lostFcnt": 210,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 935,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC104",
    //     "description": "Contador_GESWAT_6084",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026084",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -105,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 213,
    //     "prevFrameCounterFcnt": 787,
    //     "latestFrameCounterFcnt": 788,
    //     "lostFcnt": 234,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 936,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC105",
    //     "description": "Contador_GESWAT_8577",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028577",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 199,
    //     "prevFrameCounterFcnt": 735,
    //     "latestFrameCounterFcnt": 738,
    //     "lostFcnt": 219,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 937,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC106",
    //     "description": "Contador_GESWAT_5007",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025007",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -112,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 623,
    //     "prevFrameCounterFcnt": 1199,
    //     "latestFrameCounterFcnt": 1200,
    //     "lostFcnt": 244,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 938,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC107",
    //     "description": "Contador_GESWAT_5080",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025080",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 202,
    //     "prevFrameCounterFcnt": 732,
    //     "latestFrameCounterFcnt": 733,
    //     "lostFcnt": 204,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 939,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC108",
    //     "description": "Contador_GESWAT_8794",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028794",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 210,
    //     "prevFrameCounterFcnt": 766,
    //     "latestFrameCounterFcnt": 767,
    //     "lostFcnt": 231,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 940,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC109",
    //     "description": "Contador_GESWAT_6148",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026148",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -104,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 201,
    //     "prevFrameCounterFcnt": 738,
    //     "latestFrameCounterFcnt": 739,
    //     "lostFcnt": 215,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 941,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC110",
    //     "description": "Contador_GESWAT_6099",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026099",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 200,
    //     "prevFrameCounterFcnt": 784,
    //     "latestFrameCounterFcnt": 785,
    //     "lostFcnt": 256,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 942,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC111",
    //     "description": "Contador_GESWAT_5176",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025176",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 198,
    //     "prevFrameCounterFcnt": 761,
    //     "latestFrameCounterFcnt": 762,
    //     "lostFcnt": 234,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 943,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC112",
    //     "description": "Contador_GESWAT_5785",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025785",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 204,
    //     "prevFrameCounterFcnt": 720,
    //     "latestFrameCounterFcnt": 721,
    //     "lostFcnt": 196,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 944,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC113",
    //     "description": "Contador_GESWAT_4937",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450024937",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -104,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 577,
    //     "prevFrameCounterFcnt": 1124,
    //     "latestFrameCounterFcnt": 1125,
    //     "lostFcnt": 222,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 945,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC114",
    //     "description": "Contador_GESWAT_4955",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450024955",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -97,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 553,
    //     "prevFrameCounterFcnt": 1075,
    //     "latestFrameCounterFcnt": 1076,
    //     "lostFcnt": 196,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 946,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC115",
    //     "description": "Contador_GESWAT_8876",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028876",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 759,
    //     "latestFrameCounterFcnt": 760,
    //     "lostFcnt": 219,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 947,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC116",
    //     "description": "Contador_GESWAT_6139",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026139",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 206,
    //     "prevFrameCounterFcnt": 758,
    //     "latestFrameCounterFcnt": 759,
    //     "lostFcnt": 235,
    //     "loraSNR": 0,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 948,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC117",
    //     "description": "Contador_GESWAT_4975",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450024975",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -102,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 188,
    //     "prevFrameCounterFcnt": 728,
    //     "latestFrameCounterFcnt": 729,
    //     "lostFcnt": 209,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 949,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC118",
    //     "description": "Contador_GESWAT_5104",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025104",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 188,
    //     "prevFrameCounterFcnt": 701,
    //     "latestFrameCounterFcnt": 702,
    //     "lostFcnt": 194,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 950,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC119",
    //     "description": "Contador_GESWAT_4949",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450024949",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -83,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 195,
    //     "prevFrameCounterFcnt": 720,
    //     "latestFrameCounterFcnt": 721,
    //     "lostFcnt": 196,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 951,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC120",
    //     "description": "Contador_GESWAT_5675",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025675",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -90,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 769,
    //     "latestFrameCounterFcnt": 770,
    //     "lostFcnt": 213,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 952,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC121",
    //     "description": "Contador_GESWAT_6192",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026192",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -100,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 192,
    //     "prevFrameCounterFcnt": 693,
    //     "latestFrameCounterFcnt": 694,
    //     "lostFcnt": 181,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 953,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC122",
    //     "description": "Contador_GESWAT_4909",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450024909",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 227,
    //     "prevFrameCounterFcnt": 805,
    //     "latestFrameCounterFcnt": 806,
    //     "lostFcnt": 226,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 954,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC123",
    //     "description": "Contador_GESWAT_9049",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450029049",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -99,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 213,
    //     "prevFrameCounterFcnt": 748,
    //     "latestFrameCounterFcnt": 749,
    //     "lostFcnt": 206,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 955,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC124",
    //     "description": "Contador_GESWAT_8832",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028832",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 204,
    //     "prevFrameCounterFcnt": 730,
    //     "latestFrameCounterFcnt": 731,
    //     "lostFcnt": 202,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 956,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC125",
    //     "description": "Contador_GESWAT_4922",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450024922",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 195,
    //     "prevFrameCounterFcnt": 734,
    //     "latestFrameCounterFcnt": 735,
    //     "lostFcnt": 215,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 957,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC126",
    //     "description": "Contador_GESWAT_4834",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450024834",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 222,
    //     "prevFrameCounterFcnt": 782,
    //     "latestFrameCounterFcnt": 783,
    //     "lostFcnt": 225,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 958,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC127",
    //     "description": "Contador_GESWAT_4924",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450024924",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -108,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 221,
    //     "prevFrameCounterFcnt": 790,
    //     "latestFrameCounterFcnt": 791,
    //     "lostFcnt": 235,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 959,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC128",
    //     "description": "Contador_GESWAT_5688",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025688",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 769,
    //     "latestFrameCounterFcnt": 770,
    //     "lostFcnt": 226,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 960,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC129",
    //     "description": "Contador_GESWAT_6215",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026215",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -104,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 202,
    //     "prevFrameCounterFcnt": 735,
    //     "latestFrameCounterFcnt": 736,
    //     "lostFcnt": 209,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 961,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC130",
    //     "description": "Contador_GESWAT_5081",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025081",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -97,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 201,
    //     "prevFrameCounterFcnt": 738,
    //     "latestFrameCounterFcnt": 739,
    //     "lostFcnt": 202,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 962,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC131",
    //     "description": "Contador_GESWAT_5788",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025788",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 204,
    //     "prevFrameCounterFcnt": 739,
    //     "latestFrameCounterFcnt": 742,
    //     "lostFcnt": 205,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 963,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC133",
    //     "description": "Contador_GESWAT_5221",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025221",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -112,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 222,
    //     "prevFrameCounterFcnt": 818,
    //     "latestFrameCounterFcnt": 819,
    //     "lostFcnt": 255,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 964,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC134",
    //     "description": "Contador_GESWAT_6042",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026042",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 196,
    //     "prevFrameCounterFcnt": 709,
    //     "latestFrameCounterFcnt": 710,
    //     "lostFcnt": 190,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 965,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC135",
    //     "description": "Contador_GESWAT_5869",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025869",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -96,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 759,
    //     "latestFrameCounterFcnt": 760,
    //     "lostFcnt": 204,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 966,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC136",
    //     "description": "Contador_GESWAT_6170",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026170",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -100,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 207,
    //     "prevFrameCounterFcnt": 735,
    //     "latestFrameCounterFcnt": 736,
    //     "lostFcnt": 199,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 967,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC137",
    //     "description": "Contador_GESWAT_6094",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026094",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 229,
    //     "prevFrameCounterFcnt": 806,
    //     "latestFrameCounterFcnt": 807,
    //     "lostFcnt": 236,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 968,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC138",
    //     "description": "Contador_GESWAT_4918",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450024918",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 223,
    //     "prevFrameCounterFcnt": 780,
    //     "latestFrameCounterFcnt": 781,
    //     "lostFcnt": 222,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 969,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC139",
    //     "description": "Contador_GESWAT_5630",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025630",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -103,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 235,
    //     "prevFrameCounterFcnt": 811,
    //     "latestFrameCounterFcnt": 812,
    //     "lostFcnt": 215,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 970,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC140",
    //     "description": "Contador_GESWAT_5950",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025950",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -89,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 207,
    //     "prevFrameCounterFcnt": 744,
    //     "latestFrameCounterFcnt": 745,
    //     "lostFcnt": 207,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 971,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC141",
    //     "description": "Contador_GESWAT_5890",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025890",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -99,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 231,
    //     "prevFrameCounterFcnt": 802,
    //     "latestFrameCounterFcnt": 803,
    //     "lostFcnt": 224,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 972,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC142",
    //     "description": "Contador_GESWAT_5682",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025682",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 241,
    //     "prevFrameCounterFcnt": 784,
    //     "latestFrameCounterFcnt": 785,
    //     "lostFcnt": 223,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 973,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC143",
    //     "description": "Contador_GESWAT_6219",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026219",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 208,
    //     "prevFrameCounterFcnt": 737,
    //     "latestFrameCounterFcnt": 738,
    //     "lostFcnt": 214,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 974,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC144",
    //     "description": "Contador_GESWAT_8502",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450028502",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 975,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC146",
    //     "description": "Contador_GESWAT_5488",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025488",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 976,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC147",
    //     "description": "Contador_GESWAT_6210",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026210",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 226,
    //     "prevFrameCounterFcnt": 817,
    //     "latestFrameCounterFcnt": 818,
    //     "lostFcnt": 230,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 977,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC148",
    //     "description": "Contador_GESWAT_6172",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026172",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -94,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 197,
    //     "prevFrameCounterFcnt": 705,
    //     "latestFrameCounterFcnt": 707,
    //     "lostFcnt": 191,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 978,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC149",
    //     "description": "Contador_GESWAT_6057",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026057",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -95,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 222,
    //     "prevFrameCounterFcnt": 810,
    //     "latestFrameCounterFcnt": 812,
    //     "lostFcnt": 240,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 979,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC50",
    //     "description": "Contador_GESWAT_6088",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026088",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 238,
    //     "prevFrameCounterFcnt": 820,
    //     "latestFrameCounterFcnt": 821,
    //     "lostFcnt": 233,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 980,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC151",
    //     "description": "Contador_GESWAT_5671",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025671",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -102,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 200,
    //     "prevFrameCounterFcnt": 713,
    //     "latestFrameCounterFcnt": 714,
    //     "lostFcnt": 190,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 981,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC152",
    //     "description": "Contador_GESWAT_5818",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025818",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 593,
    //     "prevFrameCounterFcnt": 1108,
    //     "latestFrameCounterFcnt": 1109,
    //     "lostFcnt": 195,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 982,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC153",
    //     "description": "Contador_GESWAT_5010",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025010",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 189,
    //     "prevFrameCounterFcnt": 801,
    //     "latestFrameCounterFcnt": 802,
    //     "lostFcnt": 255,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 983,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC154",
    //     "description": "Contador_GESWAT_5288",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025288",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 224,
    //     "prevFrameCounterFcnt": 792,
    //     "latestFrameCounterFcnt": 793,
    //     "lostFcnt": 243,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 984,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC155",
    //     "description": "Contador_GESWAT_4852",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450024852",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -112,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 199,
    //     "prevFrameCounterFcnt": 729,
    //     "latestFrameCounterFcnt": 730,
    //     "lostFcnt": 209,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 985,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC156",
    //     "description": "Contador_GESWAT_4869",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450024869",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 598,
    //     "prevFrameCounterFcnt": 1127,
    //     "latestFrameCounterFcnt": 1128,
    //     "lostFcnt": 202,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 986,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC157",
    //     "description": "Contador_GESWAT_5022",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025022",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 239,
    //     "prevFrameCounterFcnt": 830,
    //     "latestFrameCounterFcnt": 831,
    //     "lostFcnt": 257,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 987,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC158",
    //     "description": "Contador_GESWAT_6054",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026054",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -85,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 195,
    //     "prevFrameCounterFcnt": 727,
    //     "latestFrameCounterFcnt": 728,
    //     "lostFcnt": 211,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 988,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC159",
    //     "description": "Contador_GESWAT_6221",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026221",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -103,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 217,
    //     "prevFrameCounterFcnt": 782,
    //     "latestFrameCounterFcnt": 783,
    //     "lostFcnt": 235,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 989,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC160",
    //     "description": "Contador_GESWAT_4873",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450024873",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 220,
    //     "prevFrameCounterFcnt": 782,
    //     "latestFrameCounterFcnt": 783,
    //     "lostFcnt": 228,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 990,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC161",
    //     "description": "Contador_GESWAT_5829",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025829",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 214,
    //     "prevFrameCounterFcnt": 771,
    //     "latestFrameCounterFcnt": 772,
    //     "lostFcnt": 210,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 991,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC162",
    //     "description": "Contador_GESWAT_5033",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025033",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 224,
    //     "prevFrameCounterFcnt": 779,
    //     "latestFrameCounterFcnt": 780,
    //     "lostFcnt": 212,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 992,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC163",
    //     "description": "Contador_GESWAT_4966",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450024966",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 205,
    //     "prevFrameCounterFcnt": 751,
    //     "latestFrameCounterFcnt": 752,
    //     "lostFcnt": 212,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 993,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC164",
    //     "description": "Contador_GESWAT_5672",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025672",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 206,
    //     "prevFrameCounterFcnt": 766,
    //     "latestFrameCounterFcnt": 767,
    //     "lostFcnt": 229,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 994,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC165",
    //     "description": "Contador_GESWAT_5202",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025202",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -91,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 198,
    //     "prevFrameCounterFcnt": 705,
    //     "latestFrameCounterFcnt": 706,
    //     "lostFcnt": 195,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 995,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC166",
    //     "description": "Contador_GESWAT_5103",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025103",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 204,
    //     "prevFrameCounterFcnt": 709,
    //     "latestFrameCounterFcnt": 710,
    //     "lostFcnt": 180,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 996,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC167",
    //     "description": "Contador_GESWAT_5674",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025674",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 778,
    //     "latestFrameCounterFcnt": 779,
    //     "lostFcnt": 223,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 997,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC168",
    //     "description": "Contador_GESWAT_4719",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450024719",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -106,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 207,
    //     "prevFrameCounterFcnt": 739,
    //     "latestFrameCounterFcnt": 740,
    //     "lostFcnt": 209,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 998,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC169",
    //     "description": "Contador_GESWAT_5786",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025786",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 204,
    //     "prevFrameCounterFcnt": 726,
    //     "latestFrameCounterFcnt": 728,
    //     "lostFcnt": 195,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 999,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC170",
    //     "description": "Contador_GESWAT_6000",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026000",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -111,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 779,
    //     "latestFrameCounterFcnt": 780,
    //     "lostFcnt": 215,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 1000,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC171",
    //     "description": "Contador_GESWAT_5753",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025753",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 208,
    //     "prevFrameCounterFcnt": 773,
    //     "latestFrameCounterFcnt": 774,
    //     "lostFcnt": 242,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 1001,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC172",
    //     "description": "Contador_GESWAT_6165",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026165",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -103,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 201,
    //     "prevFrameCounterFcnt": 734,
    //     "latestFrameCounterFcnt": 735,
    //     "lostFcnt": 210,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 1002,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC173",
    //     "description": "Contador_GESWAT_5281",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025281",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -108,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 555,
    //     "prevFrameCounterFcnt": 1050,
    //     "latestFrameCounterFcnt": 1051,
    //     "lostFcnt": 179,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 1003,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC174",
    //     "description": "Contador_GESWAT_6096",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026096",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -90,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 197,
    //     "prevFrameCounterFcnt": 740,
    //     "latestFrameCounterFcnt": 741,
    //     "lostFcnt": 218,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 1004,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC175",
    //     "description": "Contador_GESWAT_5333",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025333",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -107,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 213,
    //     "prevFrameCounterFcnt": 761,
    //     "latestFrameCounterFcnt": 762,
    //     "lostFcnt": 220,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 1005,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC176",
    //     "description": "Contador_GESWAT_5916",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025916",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 226,
    //     "prevFrameCounterFcnt": 772,
    //     "latestFrameCounterFcnt": 773,
    //     "lostFcnt": 224,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 1006,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC177",
    //     "description": "Contador_GESWAT_5260",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025260",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -102,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 206,
    //     "prevFrameCounterFcnt": 730,
    //     "latestFrameCounterFcnt": 732,
    //     "lostFcnt": 203,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 1007,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC178",
    //     "description": "Contador_GESWAT_5115",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025115",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 201,
    //     "prevFrameCounterFcnt": 742,
    //     "latestFrameCounterFcnt": 743,
    //     "lostFcnt": 217,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 1008,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC179",
    //     "description": "Contador_GESWAT_5329",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025329",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -87,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 219,
    //     "prevFrameCounterFcnt": 765,
    //     "latestFrameCounterFcnt": 766,
    //     "lostFcnt": 210,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 1009,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC180",
    //     "description": "Contador_GESWAT_6171",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450026171",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -103,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 169,
    //     "prevFrameCounterFcnt": 707,
    //     "latestFrameCounterFcnt": 708,
    //     "lostFcnt": 205,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 1010,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC181",
    //     "description": "Contador_GESWAT_5206",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025206",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -81,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 200,
    //     "prevFrameCounterFcnt": 746,
    //     "latestFrameCounterFcnt": 747,
    //     "lostFcnt": 215,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 1011,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC182",
    //     "description": "Contador_GESWAT_7697",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450027697",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -101,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 751,
    //     "latestFrameCounterFcnt": 752,
    //     "lostFcnt": 223,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 1012,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC183",
    //     "description": "Contador_GESWAT_5015",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025015",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 214,
    //     "prevFrameCounterFcnt": 767,
    //     "latestFrameCounterFcnt": 768,
    //     "lostFcnt": 224,
    //     "loraSNR": -6,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 1013,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC184",
    //     "description": "Contador_GESWAT_5004",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025004",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -108,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 209,
    //     "prevFrameCounterFcnt": 775,
    //     "latestFrameCounterFcnt": 776,
    //     "lostFcnt": 240,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 1014,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCASGWTC185",
    //     "description": "Contador_GESWAT_5696",
    //     "provider": null,
    //     "userId": 69,
    //     "typeSensor": "70",
    //     "sensorTypeInfo": {
    //         "id": 70,
    //         "name": "WATERMETERZENNER",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-09",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "04b6480450025696",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827eb82cffc",
    //     "dr": 0,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 218,
    //     "prevFrameCounterFcnt": 780,
    //     "latestFrameCounterFcnt": 781,
    //     "lostFcnt": 238,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb82cffc",
    //     "messageTime": null
    // },
    // {
    //     "id": 1015,
    //     "gatewaysId": null,
    //     "name": "demo sensor",
    //     "description": "",
    //     "provider": null,
    //     "userId": 71,
    //     "typeSensor": "71",
    //     "sensorTypeInfo": {
    //         "id": 71,
    //         "name": "ibox_alacante",
    //         "description": "alacante sensor Type",
    //         "sensorType": "ibox",
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-09-24",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "7923732023",
    //     "appEUI": null,
    //     "appKEY": "32382302328372327362",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": null,
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 1018,
    //     "gatewaysId": null,
    //     "name": "000781370000A337",
    //     "description": "000781370000A337_cyble5",
    //     "provider": null,
    //     "userId": 36,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-13",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000a337",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "chirpstack",
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb66bea3,b827eb6c4e09,dca63214b1d0,b827eb11f2e1",
    //     "dr": 5,
    //     "rssi": -99,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1178,
    //     "prevFrameCounterFcnt": 2301,
    //     "latestFrameCounterFcnt": 2326,
    //     "lostFcnt": 421,
    //     "loraSNR": 12,
    //     "networkServerMac": "b827eb66bea3",
    //     "messageTime": null
    // },
    // {
    //     "id": 1027,
    //     "gatewaysId": null,
    //     "name": "WaterExternalVIGWTC01",
    //     "description": "El-Montgo-28",
    //     "provider": null,
    //     "userId": 36,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-19",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "000781370000a348",
    //     "appEUI": "2",
    //     "appKEY": "CF9B0531DB3486B45043A82BCBD286FD",
    //     "encryptionKey": "028E71ADFA7F63B0B9EDA1E85F892FED",
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb66bea3,b827eb6c4e09,dca63214b1d0,b827eb11f2e1,e45f016cdcdd",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1171,
    //     "prevFrameCounterFcnt": 2319,
    //     "latestFrameCounterFcnt": 2390,
    //     "lostFcnt": 471,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb66bea3",
    //     "messageTime": null
    // },
    // {
    //     "id": 1038,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0001",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-25",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042af2",
    //     "appEUI": "app",
    //     "appKEY": "618E3100304D52D8B3D31EDE446A1363",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 1039,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0002",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-25",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042aee",
    //     "appEUI": "app",
    //     "appKEY": "DD31C8D00218DA59FA2C940B02FBBDEF",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 1040,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0003",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-25",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b1d",
    //     "appEUI": "app",
    //     "appKEY": "61B0AA95BBD3C36A48DDCCD8AA8058C1",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 2902,
    //     "latestFrameCounterFcnt": 2903,
    //     "lostFcnt": 600,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1041,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0004",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-25",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b1e",
    //     "appEUI": "app",
    //     "appKEY": "A51A94CFFE4F11543CEF7C6555FC1CB3",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 653,
    //     "latestFrameCounterFcnt": 654,
    //     "lostFcnt": -1481,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1042,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0005",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-25",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042af6",
    //     "appEUI": "app",
    //     "appKEY": "1E91F5A50CE4C668F7045CB17B714095",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4110,
    //     "latestFrameCounterFcnt": 4111,
    //     "lostFcnt": 945,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1043,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0006",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-25",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b17",
    //     "appEUI": "app",
    //     "appKEY": "5E721E863DB95652E1536A81E42C6364",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4110,
    //     "latestFrameCounterFcnt": 4112,
    //     "lostFcnt": 1675,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1044,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0007",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-25",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042aef",
    //     "appEUI": "app",
    //     "appKEY": "2DEEDC80A01498B1A1B66856200D78EC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4109,
    //     "latestFrameCounterFcnt": 4110,
    //     "lostFcnt": 1362,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1045,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0008",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-25",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042af3",
    //     "appEUI": "app",
    //     "appKEY": "2C7ACED4F6FE041A3A605E8D7C9F3C80",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4110,
    //     "latestFrameCounterFcnt": 4111,
    //     "lostFcnt": 735,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1046,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0009",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-25",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b1b",
    //     "appEUI": "app",
    //     "appKEY": "A3A32D137223E4FEC1A3F63D023C6183",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 1699,
    //     "latestFrameCounterFcnt": 1701,
    //     "lostFcnt": -68,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1047,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0010",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-25",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042aec",
    //     "appEUI": "app",
    //     "appKEY": "839BE1E244E5A411AC2686FA97498563",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4092,
    //     "latestFrameCounterFcnt": 4093,
    //     "lostFcnt": 694,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1048,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0011",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042af8",
    //     "appEUI": "app",
    //     "appKEY": "765C9FD2E2E33D4E6D4AF15DF48CE5D0",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4110,
    //     "latestFrameCounterFcnt": 4111,
    //     "lostFcnt": 1373,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1049,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0012",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042af0",
    //     "appEUI": "app",
    //     "appKEY": "8B94459A9661D36DF12F49E1A88EC365",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4109,
    //     "latestFrameCounterFcnt": 4111,
    //     "lostFcnt": 806,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1050,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0013",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042aea",
    //     "appEUI": "app",
    //     "appKEY": "66FA3DEE8300DA41EEC381F92DFC30CB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4109,
    //     "latestFrameCounterFcnt": 4110,
    //     "lostFcnt": 1309,
    //     "loraSNR": -20,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1051,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0014",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b12",
    //     "appEUI": "app",
    //     "appKEY": "32BE92CF6335EABEAA2D7E7AE6DB433B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4110,
    //     "latestFrameCounterFcnt": 4112,
    //     "lostFcnt": 822,
    //     "loraSNR": -19,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1052,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0015",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042aed",
    //     "appEUI": "app",
    //     "appKEY": "18993CA7B4BD54459FDD53D221947C70",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4091,
    //     "latestFrameCounterFcnt": 4094,
    //     "lostFcnt": 844,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1053,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0016",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b1c",
    //     "appEUI": "app",
    //     "appKEY": "00EE10EB021FA74E1132342465FE7313",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4101,
    //     "latestFrameCounterFcnt": 4102,
    //     "lostFcnt": 807,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1054,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0017",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b13",
    //     "appEUI": "app",
    //     "appKEY": "A1883A327B564A19B7FAD99FCBAB06A9",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4109,
    //     "latestFrameCounterFcnt": 4110,
    //     "lostFcnt": 1269,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1055,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0018",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042af7",
    //     "appEUI": "app",
    //     "appKEY": "2BED7A481118F078B9B12E943002250D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4092,
    //     "latestFrameCounterFcnt": 4094,
    //     "lostFcnt": 755,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1056,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0019",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042aeb",
    //     "appEUI": "app",
    //     "appKEY": "9CE539FB1C12A99396B66C27E2BC0D5D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4092,
    //     "latestFrameCounterFcnt": 4094,
    //     "lostFcnt": 814,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1057,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0020",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042af1",
    //     "appEUI": "app",
    //     "appKEY": "8B83D5EAC8EF209D15888FC44C36131D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4102,
    //     "latestFrameCounterFcnt": 4103,
    //     "lostFcnt": 662,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1058,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0021",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043032",
    //     "appEUI": "app",
    //     "appKEY": "B3425DFDA6BC6412CD556770768A135C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 263,
    //     "prevFrameCounterFcnt": 4091,
    //     "latestFrameCounterFcnt": 4092,
    //     "lostFcnt": 1376,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1059,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0022",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004305e",
    //     "appEUI": "app",
    //     "appKEY": "7E2D05ECA8AAC6111B4BACFF2D4A723D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 263,
    //     "prevFrameCounterFcnt": 4091,
    //     "latestFrameCounterFcnt": 4093,
    //     "lostFcnt": 1385,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1060,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0023",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043027",
    //     "appEUI": "app",
    //     "appKEY": "80A238C32030F20F9FB21BDF62117FC2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 263,
    //     "prevFrameCounterFcnt": 4091,
    //     "latestFrameCounterFcnt": 4092,
    //     "lostFcnt": 977,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1061,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0024",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fed",
    //     "appEUI": "app",
    //     "appKEY": "EA1B1C3AC593579E4315759AD93EB685",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 264,
    //     "prevFrameCounterFcnt": 4091,
    //     "latestFrameCounterFcnt": 4093,
    //     "lostFcnt": 979,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1062,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0025",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b0c",
    //     "appEUI": "app",
    //     "appKEY": "B1570273A7BCF364FE81C57FD98CE6F4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 263,
    //     "prevFrameCounterFcnt": 4090,
    //     "latestFrameCounterFcnt": 4092,
    //     "lostFcnt": 1174,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1063,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0026",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043056",
    //     "appEUI": "app",
    //     "appKEY": "263078B2949C3AF344697D530B225BDC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4091,
    //     "latestFrameCounterFcnt": 4092,
    //     "lostFcnt": 820,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1064,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0027",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200430ae",
    //     "appEUI": "app",
    //     "appKEY": "6D852DCBEDD898F87A78450917B7E938",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 263,
    //     "prevFrameCounterFcnt": 4091,
    //     "latestFrameCounterFcnt": 4092,
    //     "lostFcnt": 1514,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1065,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0028",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043037",
    //     "appEUI": "app",
    //     "appKEY": "77DA8C887776C74E44DE6F0D02794E44",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 262,
    //     "prevFrameCounterFcnt": 4090,
    //     "latestFrameCounterFcnt": 4091,
    //     "lostFcnt": 1025,
    //     "loraSNR": -19,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1066,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0029",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b0d",
    //     "appEUI": "app",
    //     "appKEY": "898751919F1F70B079D3116D5681BD68",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 262,
    //     "prevFrameCounterFcnt": 4091,
    //     "latestFrameCounterFcnt": 4093,
    //     "lostFcnt": 1570,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1067,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0030",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fe9",
    //     "appEUI": "app",
    //     "appKEY": "0AF785E714B2C4F735D279D4BD5E8D3F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 263,
    //     "prevFrameCounterFcnt": 4091,
    //     "latestFrameCounterFcnt": 4092,
    //     "lostFcnt": 1005,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1068,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0031",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b26",
    //     "appEUI": "app",
    //     "appKEY": "BE008DAE838FA9DB13501ADCD3316B2D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4091,
    //     "latestFrameCounterFcnt": 4092,
    //     "lostFcnt": 942,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1069,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0032",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b29",
    //     "appEUI": "app",
    //     "appKEY": "F2B9B43AE7416BF8F0ECE8CA9B7F33CB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4091,
    //     "latestFrameCounterFcnt": 4092,
    //     "lostFcnt": 824,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1070,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0033",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b28",
    //     "appEUI": "app",
    //     "appKEY": "44CC322E0DCB4C122C32744721225EA4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4102,
    //     "latestFrameCounterFcnt": 4104,
    //     "lostFcnt": 806,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1071,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0034",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b30",
    //     "appEUI": "app",
    //     "appKEY": "708285A38490C88EC9C6479B55968F12",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -116,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4101,
    //     "latestFrameCounterFcnt": 4102,
    //     "lostFcnt": 850,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1072,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0035",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042afd",
    //     "appEUI": "app",
    //     "appKEY": "48A0F5505493B1852959A54320B83CB0",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 1073,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0036",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042ff2",
    //     "appEUI": "app",
    //     "appKEY": "63D728DE8E29B8DF6554581F5175CB02",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4091,
    //     "latestFrameCounterFcnt": 4092,
    //     "lostFcnt": 861,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1074,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0037",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b2e",
    //     "appEUI": "app",
    //     "appKEY": "21C95EE592A31C3FF8E90F33542CD4E7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4102,
    //     "latestFrameCounterFcnt": 4103,
    //     "lostFcnt": 680,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1075,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0038",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b0e",
    //     "appEUI": "app",
    //     "appKEY": "CB68976038BC286458B1D6E74955D46B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4092,
    //     "latestFrameCounterFcnt": 4093,
    //     "lostFcnt": 817,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1076,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0039",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b34",
    //     "appEUI": "app",
    //     "appKEY": "9E4A0E26063E9C1DB80FCFD58EA40642",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4102,
    //     "latestFrameCounterFcnt": 4104,
    //     "lostFcnt": 849,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1077,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0040",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b2d",
    //     "appEUI": "app",
    //     "appKEY": "5712CE59CA6AA03073B97AD436CCCF2E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4099,
    //     "latestFrameCounterFcnt": 4100,
    //     "lostFcnt": 773,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1078,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0041",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b24",
    //     "appEUI": "app",
    //     "appKEY": "7E566D74A998E7D058F84E159841EA76",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 262,
    //     "prevFrameCounterFcnt": 4090,
    //     "latestFrameCounterFcnt": 4091,
    //     "lostFcnt": 1037,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1079,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0042",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b10",
    //     "appEUI": "app",
    //     "appKEY": "D36C393FC44C61D4CFE24B0C9ED8639E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 1080,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0043",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b0b",
    //     "appEUI": "app",
    //     "appKEY": "6268CB7F391346627525026B357C6081",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 254,
    //     "prevFrameCounterFcnt": 4082,
    //     "latestFrameCounterFcnt": 4083,
    //     "lostFcnt": 878,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1081,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0044",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b11",
    //     "appEUI": "app",
    //     "appKEY": "735FF10C6B4C72D46668884DBAB8805B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 262,
    //     "prevFrameCounterFcnt": 4090,
    //     "latestFrameCounterFcnt": 4091,
    //     "lostFcnt": 1057,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1082,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0045",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b08",
    //     "appEUI": "app",
    //     "appKEY": "0F33FF6EDBA17AB711E26C9D81C12B4E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 253,
    //     "prevFrameCounterFcnt": 4081,
    //     "latestFrameCounterFcnt": 4082,
    //     "lostFcnt": 1080,
    //     "loraSNR": -19,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1083,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0046",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b06",
    //     "appEUI": "app",
    //     "appKEY": "6B9046614D560D3D635D88D8CE070E56",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 262,
    //     "prevFrameCounterFcnt": 4090,
    //     "latestFrameCounterFcnt": 4091,
    //     "lostFcnt": 1022,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1084,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0047",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b2a",
    //     "appEUI": "app",
    //     "appKEY": "CFF165C8F59176BE5EBBB00CC933A99B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 4072,
    //     "latestFrameCounterFcnt": 4073,
    //     "lostFcnt": 1378,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1085,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0048",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b2c",
    //     "appEUI": "app",
    //     "appKEY": "AEC60C75CAD86571B22421B1634D1FAF",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 254,
    //     "prevFrameCounterFcnt": 4082,
    //     "latestFrameCounterFcnt": 4083,
    //     "lostFcnt": 811,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1086,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0049",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b04",
    //     "appEUI": "app",
    //     "appKEY": "2B47AAC0155D2F704D48ADD640969F51",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 253,
    //     "prevFrameCounterFcnt": 4081,
    //     "latestFrameCounterFcnt": 4082,
    //     "lostFcnt": 846,
    //     "loraSNR": -20,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1087,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0050",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b0a",
    //     "appEUI": "app",
    //     "appKEY": "A6E46364080E003E4EC9912D60C96368",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 253,
    //     "prevFrameCounterFcnt": 4080,
    //     "latestFrameCounterFcnt": 4081,
    //     "lostFcnt": 974,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1088,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0051",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b20",
    //     "appEUI": "app",
    //     "appKEY": "88012FA0752BFB7B405AB6C063401083",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 253,
    //     "prevFrameCounterFcnt": 4077,
    //     "latestFrameCounterFcnt": 4078,
    //     "lostFcnt": 1333,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1089,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0052",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b2b",
    //     "appEUI": "app",
    //     "appKEY": "935ADFF1861AB32695CD2A7DD01E9C23",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 263,
    //     "prevFrameCounterFcnt": 4090,
    //     "latestFrameCounterFcnt": 4091,
    //     "lostFcnt": 1128,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1090,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0053",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b09",
    //     "appEUI": "app",
    //     "appKEY": "DEE2AEA8E14F58D52FF0B064B6E8BE1C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 253,
    //     "prevFrameCounterFcnt": 4081,
    //     "latestFrameCounterFcnt": 4082,
    //     "lostFcnt": 1108,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1091,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0054",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b32",
    //     "appEUI": "app",
    //     "appKEY": "BB045DFF69E206B240C44516451B9606",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 253,
    //     "prevFrameCounterFcnt": 4080,
    //     "latestFrameCounterFcnt": 4081,
    //     "lostFcnt": 902,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1092,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0055",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b07",
    //     "appEUI": "app",
    //     "appKEY": "156B314E26D32D6FF00BC56553502A12",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 254,
    //     "prevFrameCounterFcnt": 4082,
    //     "latestFrameCounterFcnt": 4083,
    //     "lostFcnt": 1105,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1093,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0056",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b2f",
    //     "appEUI": "app",
    //     "appKEY": "B44404016316A0A79992DD05BE39640C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 255,
    //     "prevFrameCounterFcnt": 4082,
    //     "latestFrameCounterFcnt": 4083,
    //     "lostFcnt": 923,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1094,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0057",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b22",
    //     "appEUI": "app",
    //     "appKEY": "090983EAA4FCD09620E5ED0A8940002F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 252,
    //     "prevFrameCounterFcnt": 4081,
    //     "latestFrameCounterFcnt": 4083,
    //     "lostFcnt": 724,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1095,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0058",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b05",
    //     "appEUI": "app",
    //     "appKEY": "FC71EED54AC10CE72BA0B61076C36596",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 253,
    //     "prevFrameCounterFcnt": 4081,
    //     "latestFrameCounterFcnt": 4082,
    //     "lostFcnt": 777,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1096,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0059",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b1f",
    //     "appEUI": "app",
    //     "appKEY": "300BF3733BFE386E4CA1DD1FAA2C7338",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 254,
    //     "prevFrameCounterFcnt": 4079,
    //     "latestFrameCounterFcnt": 4080,
    //     "lostFcnt": 1969,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1097,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0060",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b7f",
    //     "appEUI": "app",
    //     "appKEY": "85B035B9D11475D4BF280EEF5A10D243",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 254,
    //     "prevFrameCounterFcnt": 4078,
    //     "latestFrameCounterFcnt": 4079,
    //     "lostFcnt": 995,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1098,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0061",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b14",
    //     "appEUI": "app",
    //     "appKEY": "92432323C69263572712D1E9763C00DB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 4073,
    //     "latestFrameCounterFcnt": 4075,
    //     "lostFcnt": 858,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1099,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0062",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b27",
    //     "appEUI": "app",
    //     "appKEY": "C5A0BA81B8CFC199FB0465465275EA75",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 253,
    //     "prevFrameCounterFcnt": 4081,
    //     "latestFrameCounterFcnt": 4083,
    //     "lostFcnt": 825,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1100,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0063",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b1a",
    //     "appEUI": "app",
    //     "appKEY": "D3D0E9DC3D91849FFAB1811626273E38",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 252,
    //     "prevFrameCounterFcnt": 4080,
    //     "latestFrameCounterFcnt": 4081,
    //     "lostFcnt": 874,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1101,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0064",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043093",
    //     "appEUI": "app",
    //     "appKEY": "DDA3752321BE6352D1D7585D26B5967E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 252,
    //     "prevFrameCounterFcnt": 4081,
    //     "latestFrameCounterFcnt": 4083,
    //     "lostFcnt": 843,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1102,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0065",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043080",
    //     "appEUI": "app",
    //     "appKEY": "D191816A47592C4404B6BAF64F272CD8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 245,
    //     "prevFrameCounterFcnt": 4073,
    //     "latestFrameCounterFcnt": 4074,
    //     "lostFcnt": 812,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1103,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0066",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b31",
    //     "appEUI": "app",
    //     "appKEY": "ACF8DD5ED6A21A04A82A234B03125926",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 245,
    //     "prevFrameCounterFcnt": 4072,
    //     "latestFrameCounterFcnt": 4074,
    //     "lostFcnt": 892,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1104,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0067",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b23",
    //     "appEUI": "app",
    //     "appKEY": "AF493A3F5C370C318EDD50720FD3A907",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 253,
    //     "prevFrameCounterFcnt": 4081,
    //     "latestFrameCounterFcnt": 4082,
    //     "lostFcnt": 814,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1105,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0068",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b25",
    //     "appEUI": "app",
    //     "appKEY": "737A90417B71740F6E9D403F6E59D4CA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 252,
    //     "prevFrameCounterFcnt": 4081,
    //     "latestFrameCounterFcnt": 4083,
    //     "lostFcnt": 851,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1106,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0069",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043077",
    //     "appEUI": "app",
    //     "appKEY": "4940C30859895FB926C933491CFD04D6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 245,
    //     "prevFrameCounterFcnt": 4073,
    //     "latestFrameCounterFcnt": 4074,
    //     "lostFcnt": 878,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1107,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0070",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004308f",
    //     "appEUI": "app",
    //     "appKEY": "B1752459F8D844420BBDDDC9DB7B743A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 245,
    //     "prevFrameCounterFcnt": 4073,
    //     "latestFrameCounterFcnt": 4074,
    //     "lostFcnt": 1091,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1108,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0071",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b21",
    //     "appEUI": "app",
    //     "appKEY": "CFF2818FC8A1421DA28B594D476A5DBF",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 245,
    //     "prevFrameCounterFcnt": 4073,
    //     "latestFrameCounterFcnt": 4074,
    //     "lostFcnt": 1628,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1109,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0072",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b16",
    //     "appEUI": "app",
    //     "appKEY": "35AF1C92E6A8C9A6CB9AD57B2A1EBB94",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 4073,
    //     "latestFrameCounterFcnt": 4075,
    //     "lostFcnt": 897,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1110,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0073",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004305f",
    //     "appEUI": "app",
    //     "appKEY": "5081A3E9181243513256497F14D7D7DB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 245,
    //     "prevFrameCounterFcnt": 4073,
    //     "latestFrameCounterFcnt": 4074,
    //     "lostFcnt": 1050,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1111,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0074",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043090",
    //     "appEUI": "app",
    //     "appKEY": "23AC549782C3E8535976D279E879C231",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 4073,
    //     "latestFrameCounterFcnt": 4075,
    //     "lostFcnt": 1267,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1112,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0075",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043076",
    //     "appEUI": "app",
    //     "appKEY": "601D5BB8FFB615926ECE2D55E3B420BC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 246,
    //     "prevFrameCounterFcnt": 2169,
    //     "latestFrameCounterFcnt": 2170,
    //     "lostFcnt": 329,
    //     "loraSNR": -19,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1113,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0076",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b0f",
    //     "appEUI": "app",
    //     "appKEY": "2D7D8311BFF6B908BFAA8C18E04BDFED",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 243,
    //     "prevFrameCounterFcnt": 4071,
    //     "latestFrameCounterFcnt": 4072,
    //     "lostFcnt": 777,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1114,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0077",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b36",
    //     "appEUI": "app",
    //     "appKEY": "6CD2E82FFCCFA3A8838E175563DFFF15",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 245,
    //     "prevFrameCounterFcnt": 3229,
    //     "latestFrameCounterFcnt": 3230,
    //     "lostFcnt": 462,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1115,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0078",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042ff8",
    //     "appEUI": "app",
    //     "appKEY": "F3E3AD9D1B326DCC1F1F387C027494B2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 245,
    //     "prevFrameCounterFcnt": 4073,
    //     "latestFrameCounterFcnt": 4074,
    //     "lostFcnt": 1058,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1116,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0079",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043084",
    //     "appEUI": "app",
    //     "appKEY": "3E48E588F38AEE307A5765DE234D4880",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 4072,
    //     "latestFrameCounterFcnt": 4073,
    //     "lostFcnt": 1086,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1117,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0080",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043085",
    //     "appEUI": "app",
    //     "appKEY": "3FF55147F4277B85F4562E241D3A0B79",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 245,
    //     "prevFrameCounterFcnt": 4072,
    //     "latestFrameCounterFcnt": 4073,
    //     "lostFcnt": 1027,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1118,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0081",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004308a",
    //     "appEUI": "app",
    //     "appKEY": "26B227D14D72E77F119ACD5332A1952E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 214,
    //     "prevFrameCounterFcnt": 4042,
    //     "latestFrameCounterFcnt": 4043,
    //     "lostFcnt": 1017,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1119,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0082",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004307f",
    //     "appEUI": "app",
    //     "appKEY": "696B72A46421ED804B14CD8C8E59DAF6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4026,
    //     "latestFrameCounterFcnt": 4040,
    //     "lostFcnt": 1621,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1120,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0083",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004306c",
    //     "appEUI": "app",
    //     "appKEY": "B39F6973B2B6CCB0B8EEE00172D3E9FF",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4042,
    //     "latestFrameCounterFcnt": 4043,
    //     "lostFcnt": 2151,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1121,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0084",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b37",
    //     "appEUI": "app",
    //     "appKEY": "55DFD5963FCCFE39D05941CE14926ACE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 214,
    //     "prevFrameCounterFcnt": 4042,
    //     "latestFrameCounterFcnt": 4043,
    //     "lostFcnt": 911,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1122,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0085",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043094",
    //     "appEUI": "app",
    //     "appKEY": "FC1489BB204BE037FAC13833406201F4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4042,
    //     "latestFrameCounterFcnt": 4043,
    //     "lostFcnt": 1513,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1123,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0086",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004307a",
    //     "appEUI": "app",
    //     "appKEY": "01F1BB5036AD93DBD1055FFCA03F959F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 214,
    //     "prevFrameCounterFcnt": 4042,
    //     "latestFrameCounterFcnt": 4043,
    //     "lostFcnt": 1545,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1124,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0087",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004308e",
    //     "appEUI": "app",
    //     "appKEY": "68FB8F93E9A2BEAF108E265CCBD639D0",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 214,
    //     "prevFrameCounterFcnt": 4041,
    //     "latestFrameCounterFcnt": 4042,
    //     "lostFcnt": 1189,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1125,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0088",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043070",
    //     "appEUI": "app",
    //     "appKEY": "4ABC3A7E278C3D5B280FF39C277BB0D5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 197,
    //     "prevFrameCounterFcnt": 4025,
    //     "latestFrameCounterFcnt": 4026,
    //     "lostFcnt": 1235,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1126,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0089",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004307e",
    //     "appEUI": "app",
    //     "appKEY": "0E26C65F021912A742DB62A894A4F456",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 217,
    //     "prevFrameCounterFcnt": 1440,
    //     "latestFrameCounterFcnt": 1441,
    //     "lostFcnt": 305,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1127,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0090",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004306f",
    //     "appEUI": "app",
    //     "appKEY": "EC87BBDB7951F26F8472E0D92D0FFDF6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 214,
    //     "prevFrameCounterFcnt": 4042,
    //     "latestFrameCounterFcnt": 4043,
    //     "lostFcnt": 1360,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1128,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0091",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043079",
    //     "appEUI": "app",
    //     "appKEY": "7F8BAD6C04C4ED59D60B2D85FC2D5EBD",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 214,
    //     "prevFrameCounterFcnt": 4043,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 1045,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1129,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0092",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043089",
    //     "appEUI": "app",
    //     "appKEY": "C1F5DE10BB3BD6E9FCD53A246A53A629",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4042,
    //     "latestFrameCounterFcnt": 4043,
    //     "lostFcnt": 1172,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1130,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0093",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043072",
    //     "appEUI": "app",
    //     "appKEY": "F019DC8F9E1F6C350E4FA312E688E9A7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 214,
    //     "prevFrameCounterFcnt": 4043,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 879,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1131,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0094",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004306e",
    //     "appEUI": "app",
    //     "appKEY": "0A66E719DAFA794BEDA4C9ACFBEA5DF2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4043,
    //     "latestFrameCounterFcnt": 4044,
    //     "lostFcnt": 704,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1132,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0095",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004306b",
    //     "appEUI": "app",
    //     "appKEY": "BD5330980A3AF0D4266838C22F464B9B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 214,
    //     "prevFrameCounterFcnt": 4042,
    //     "latestFrameCounterFcnt": 4043,
    //     "lostFcnt": 1711,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1133,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0096",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043095",
    //     "appEUI": "app",
    //     "appKEY": "522686C4398112F30B0AF0A054885219",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 247,
    //     "prevFrameCounterFcnt": 162,
    //     "latestFrameCounterFcnt": 165,
    //     "lostFcnt": -1845,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1134,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0097",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004307b",
    //     "appEUI": "app",
    //     "appKEY": "ECD937B0D530B41C119F3E648B02CA1D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4043,
    //     "latestFrameCounterFcnt": 4044,
    //     "lostFcnt": 1563,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1135,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0098",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043067",
    //     "appEUI": "app",
    //     "appKEY": "E4C5142BF1D44DC0205BE016B9D3232D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4043,
    //     "latestFrameCounterFcnt": 4044,
    //     "lostFcnt": 1381,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1136,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0099",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043066",
    //     "appEUI": "app",
    //     "appKEY": "6A65AFB373D397DF1FD9A10C744B9AA1",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 214,
    //     "prevFrameCounterFcnt": 4035,
    //     "latestFrameCounterFcnt": 4038,
    //     "lostFcnt": 2158,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1137,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0100",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043071",
    //     "appEUI": "app",
    //     "appKEY": "39F462122F17B516F89988191D6EE382",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 163,
    //     "prevFrameCounterFcnt": 2011,
    //     "latestFrameCounterFcnt": 2040,
    //     "lostFcnt": 701,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1138,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0101",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043074",
    //     "appEUI": "app",
    //     "appKEY": "392336FE874E394724F09DC5CB6DAD6D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4043,
    //     "latestFrameCounterFcnt": 4044,
    //     "lostFcnt": 992,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1139,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0102",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043075",
    //     "appEUI": "app",
    //     "appKEY": "0FFC68F07C139CAD1C0BAE23B2F2B400",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4042,
    //     "latestFrameCounterFcnt": 4043,
    //     "lostFcnt": 926,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1140,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0103",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004307d",
    //     "appEUI": "app",
    //     "appKEY": "941A41897E470900A419CC1DCF1FAB04",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 632,
    //     "latestFrameCounterFcnt": 634,
    //     "lostFcnt": -1483,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1141,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0104",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043088",
    //     "appEUI": "app",
    //     "appKEY": "ECA4945CE8647464CA4FB8C0FF0F0AC7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4041,
    //     "latestFrameCounterFcnt": 4042,
    //     "lostFcnt": 2218,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1142,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0105",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fe8",
    //     "appEUI": "app",
    //     "appKEY": "D44EBBB286C80BA2C4F81298609CAB69",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 214,
    //     "prevFrameCounterFcnt": 1829,
    //     "latestFrameCounterFcnt": 1830,
    //     "lostFcnt": -677,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1143,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0106",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043069",
    //     "appEUI": "app",
    //     "appKEY": "DBC65D9785EA09C5A86CE97BD1EE66B5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4043,
    //     "latestFrameCounterFcnt": 4044,
    //     "lostFcnt": 820,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1144,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0107",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004306a",
    //     "appEUI": "app",
    //     "appKEY": "D783444F4F690F05ABF1F6B053AEFBFA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4042,
    //     "latestFrameCounterFcnt": 4044,
    //     "lostFcnt": 889,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1145,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0108",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043097",
    //     "appEUI": "app",
    //     "appKEY": "D573912C94B6166C395F57CA0D1F6502",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4043,
    //     "latestFrameCounterFcnt": 4044,
    //     "lostFcnt": 1117,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1146,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0109",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043068",
    //     "appEUI": "app",
    //     "appKEY": "665AF467AE4D48464FA168C5B6344329",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4043,
    //     "latestFrameCounterFcnt": 4044,
    //     "lostFcnt": 1115,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1147,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0110",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004302a",
    //     "appEUI": "app",
    //     "appKEY": "7191F206BCD2C098FC4ED7FE287D2A06",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4043,
    //     "latestFrameCounterFcnt": 4044,
    //     "lostFcnt": 974,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1148,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0111",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043073",
    //     "appEUI": "app",
    //     "appKEY": "8B762C824FED44B6C608D9B6B3A77F11",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 2621,
    //     "latestFrameCounterFcnt": 2622,
    //     "lostFcnt": 1066,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1149,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0112",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042b35",
    //     "appEUI": "app",
    //     "appKEY": "94023369E438997EBDFC96618B9FF640",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 526,
    //     "latestFrameCounterFcnt": 542,
    //     "lostFcnt": -1054,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1150,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0113",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043019",
    //     "appEUI": "app",
    //     "appKEY": "00554281F5895D5398665155696D5255",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4044,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 885,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1151,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0114",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004307c",
    //     "appEUI": "app",
    //     "appKEY": "505564E486CB904BDE047737081C003A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4044,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 865,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1152,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0115",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043021",
    //     "appEUI": "app",
    //     "appKEY": "55B15F118C3B394C8AE6C4016F751B99",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 2207,
    //     "latestFrameCounterFcnt": 2208,
    //     "lostFcnt": -487,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1153,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0116",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043078",
    //     "appEUI": "app",
    //     "appKEY": "A6421A667D404527777B8676ED2952B1",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4044,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 1667,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1154,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0117",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004306d",
    //     "appEUI": "app",
    //     "appKEY": "801D41977EAF2ABBD1EC32FA3EC16944",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4043,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 1168,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1155,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0118",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004302b",
    //     "appEUI": "app",
    //     "appKEY": "09ADFD45D0AD0A94BAB03FD346CD1244",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4044,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 758,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1156,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0119",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042ff7",
    //     "appEUI": "app",
    //     "appKEY": "1953CF2FD12196D526AD970FB1CDC85B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 601,
    //     "latestFrameCounterFcnt": 602,
    //     "lostFcnt": 117,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1157,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0120",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004302c",
    //     "appEUI": "app",
    //     "appKEY": "1E776674182435FB60C3F496B8652DAD",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4044,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 1550,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1158,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0121",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200430b1",
    //     "appEUI": "app",
    //     "appKEY": "0385BD677A82808ED9CC1FBC383461B9",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 217,
    //     "prevFrameCounterFcnt": 4044,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 1395,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1159,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0122",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043030",
    //     "appEUI": "app",
    //     "appKEY": "CF99F6411463BC43F3EEBE494029CBC3",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4040,
    //     "latestFrameCounterFcnt": 4041,
    //     "lostFcnt": 963,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1160,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0123",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043092",
    //     "appEUI": "app",
    //     "appKEY": "D447927A719586553BAC2BE6352C2381",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4044,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 1575,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1161,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0124",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043082",
    //     "appEUI": "app",
    //     "appKEY": "405B7E87731E85CB5925923C8A91CAAA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4043,
    //     "latestFrameCounterFcnt": 4046,
    //     "lostFcnt": 954,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1162,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0125",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043096",
    //     "appEUI": "app",
    //     "appKEY": "CD27C0A635DB1D3C44E82A49EC9F8B36",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4044,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 951,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1163,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0126",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004301f",
    //     "appEUI": "app",
    //     "appKEY": "6BBD0FA8F35D9DC3FB51A8F3C4CC57E4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4044,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 1159,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1164,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0127",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200430a5",
    //     "appEUI": "app",
    //     "appKEY": "F891449F0A44E045FA5EEFDC295A0A2B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 174,
    //     "prevFrameCounterFcnt": 1764,
    //     "latestFrameCounterFcnt": 1765,
    //     "lostFcnt": -258,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1165,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0128",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200430b2",
    //     "appEUI": "app",
    //     "appKEY": "A5DC559F87975B0873184CBA7841DD8F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4044,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 1563,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1166,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0129",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004301e",
    //     "appEUI": "app",
    //     "appKEY": "D90DC652A31D4809E1DFDAED73E470D9",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 236,
    //     "prevFrameCounterFcnt": 2021,
    //     "latestFrameCounterFcnt": 2023,
    //     "lostFcnt": -229,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1167,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0130",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043031",
    //     "appEUI": "app",
    //     "appKEY": "E9B10EAA4A34D18F92802786867F8DB8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 242,
    //     "prevFrameCounterFcnt": 4071,
    //     "latestFrameCounterFcnt": 4073,
    //     "lostFcnt": 969,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1168,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0131",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200430b3",
    //     "appEUI": "app",
    //     "appKEY": "E0E547A31FAEB6E5F804D70BF79AA04B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 243,
    //     "prevFrameCounterFcnt": 4071,
    //     "latestFrameCounterFcnt": 4072,
    //     "lostFcnt": 939,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1169,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0132",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200430ac",
    //     "appEUI": "app",
    //     "appKEY": "A16A5C1259D16F05E0A489833CA25D1D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 243,
    //     "prevFrameCounterFcnt": 4071,
    //     "latestFrameCounterFcnt": 4072,
    //     "lostFcnt": 1179,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1170,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0133",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043087",
    //     "appEUI": "app",
    //     "appKEY": "D5F98297ECE81FA691F4186852CD456F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 243,
    //     "prevFrameCounterFcnt": 4071,
    //     "latestFrameCounterFcnt": 4072,
    //     "lostFcnt": 1064,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1171,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0134",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043091",
    //     "appEUI": "app",
    //     "appKEY": "148463D67CF900595E352ABB0778D63B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 243,
    //     "prevFrameCounterFcnt": 4069,
    //     "latestFrameCounterFcnt": 4070,
    //     "lostFcnt": 1069,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1172,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0135",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043083",
    //     "appEUI": "app",
    //     "appKEY": "947C3F9BD19FC0BC389BF1046FC1A1EA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 243,
    //     "prevFrameCounterFcnt": 4071,
    //     "latestFrameCounterFcnt": 4072,
    //     "lostFcnt": 746,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1173,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0136",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004308d",
    //     "appEUI": "app",
    //     "appKEY": "822037805F7760D9B2D70A8BA4C63E9E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4044,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 803,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1174,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0137",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200430ab",
    //     "appEUI": "app",
    //     "appKEY": "9E98DE63D7D81AD5396DC6390230462A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 4072,
    //     "latestFrameCounterFcnt": 4074,
    //     "lostFcnt": 1013,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1175,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0138",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043026",
    //     "appEUI": "app",
    //     "appKEY": "0B2CA8A98EA5A90113BC8E714BCE5051",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 243,
    //     "prevFrameCounterFcnt": 4071,
    //     "latestFrameCounterFcnt": 4072,
    //     "lostFcnt": 965,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1176,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0139",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004302f",
    //     "appEUI": "app",
    //     "appKEY": "EEAA2201003548511C2CF7006EC42F4B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 310,
    //     "prevFrameCounterFcnt": 4071,
    //     "latestFrameCounterFcnt": 4072,
    //     "lostFcnt": 2145,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1177,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0140",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043025",
    //     "appEUI": "app",
    //     "appKEY": "2CD6D8CB6B35E00B5956645CFE2BE481",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 243,
    //     "prevFrameCounterFcnt": 4071,
    //     "latestFrameCounterFcnt": 4072,
    //     "lostFcnt": 1029,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1178,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0141",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043086",
    //     "appEUI": "app",
    //     "appKEY": "4FA5DFFFEA54B2273FF7774E7B5B2871",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4091,
    //     "latestFrameCounterFcnt": 4092,
    //     "lostFcnt": 716,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1179,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0142",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004301a",
    //     "appEUI": "app",
    //     "appKEY": "944F4E7AA2D62623E0207990281DAB97",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 4072,
    //     "latestFrameCounterFcnt": 4073,
    //     "lostFcnt": 1650,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1180,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0143",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042ffc",
    //     "appEUI": "app",
    //     "appKEY": "DC36CA2310F5D91BC0A7CE9FD78026B8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 4071,
    //     "latestFrameCounterFcnt": 4073,
    //     "lostFcnt": 962,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1181,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0144",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004308b",
    //     "appEUI": "app",
    //     "appKEY": "193A71EB64F6F5B26D29DF969FB1E60B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 243,
    //     "prevFrameCounterFcnt": 4072,
    //     "latestFrameCounterFcnt": 4074,
    //     "lostFcnt": 917,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1182,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0145",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042ff3",
    //     "appEUI": "app",
    //     "appKEY": "DD165918EBA4DACD1568B23FD0E10C26",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 1438,
    //     "latestFrameCounterFcnt": 1439,
    //     "lostFcnt": 228,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1183,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0146",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200430aa",
    //     "appEUI": "app",
    //     "appKEY": "C5A3D0EE03177494FE20084A750A9ACB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 4072,
    //     "latestFrameCounterFcnt": 4073,
    //     "lostFcnt": 999,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1184,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0147",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043046",
    //     "appEUI": "app",
    //     "appKEY": "DE77414ACC768455BBAD893CB42EF87A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 4072,
    //     "latestFrameCounterFcnt": 4073,
    //     "lostFcnt": 1018,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1185,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0148",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043065",
    //     "appEUI": "app",
    //     "appKEY": "A5A8A744943DD567D8A6B5285648C859",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 4072,
    //     "latestFrameCounterFcnt": 4073,
    //     "lostFcnt": 888,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1186,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0149",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200430ad",
    //     "appEUI": "app",
    //     "appKEY": "3A7374C85D4AD7B56ED199163607C961",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4092,
    //     "latestFrameCounterFcnt": 4094,
    //     "lostFcnt": 761,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1187,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0150",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004304a",
    //     "appEUI": "app",
    //     "appKEY": "A563020EAB3A89DAFC82FB10F75A1818",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 4072,
    //     "latestFrameCounterFcnt": 4073,
    //     "lostFcnt": 1056,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1188,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0151",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004308c",
    //     "appEUI": "app",
    //     "appKEY": "CD3E9AEC6A88D94C740979D7E1557FAD",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 4068,
    //     "latestFrameCounterFcnt": 4073,
    //     "lostFcnt": 1171,
    //     "loraSNR": -20,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1189,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0152",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004303d",
    //     "appEUI": "app",
    //     "appKEY": "591BFA3D16E31BBC4146A903F9DD65C0",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 1700,
    //     "latestFrameCounterFcnt": 1701,
    //     "lostFcnt": -707,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1190,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0153",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043039",
    //     "appEUI": "app",
    //     "appKEY": "767A9FCAC8C4E3CFDC01ABB0BC87DD7D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4092,
    //     "latestFrameCounterFcnt": 4093,
    //     "lostFcnt": 882,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1191,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0154",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004302d",
    //     "appEUI": "app",
    //     "appKEY": "71BB366DBCE50839019ADCBE334ABE46",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 4071,
    //     "latestFrameCounterFcnt": 4072,
    //     "lostFcnt": 1197,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1192,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0155",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fee",
    //     "appEUI": "app",
    //     "appKEY": "F3CCFFA7C6A20291A71AFAB9711B4E2E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 4071,
    //     "latestFrameCounterFcnt": 4072,
    //     "lostFcnt": 1083,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1193,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0156",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200430b0",
    //     "appEUI": "app",
    //     "appKEY": "B0F90DAA56B95D7791E6F32A27E26581",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 4072,
    //     "latestFrameCounterFcnt": 4073,
    //     "lostFcnt": 778,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1194,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0157",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043081",
    //     "appEUI": "app",
    //     "appKEY": "BF160FCF19747CF46E1B068290A6CE14",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 4072,
    //     "latestFrameCounterFcnt": 4073,
    //     "lostFcnt": 1329,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1195,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0158",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043023",
    //     "appEUI": "app",
    //     "appKEY": "530C14D50DC56C45A0EF3A78F62B46BF",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 3,
    //     "prevFrameCounterFcnt": 194,
    //     "latestFrameCounterFcnt": 195,
    //     "lostFcnt": -169,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1196,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0159",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043022",
    //     "appEUI": "app",
    //     "appKEY": "7AAE226D2CB348B442D5ECFD40EC7FAB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 4072,
    //     "latestFrameCounterFcnt": 4073,
    //     "lostFcnt": 1356,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1197,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0160",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043053",
    //     "appEUI": "app",
    //     "appKEY": "30F3F003605B0B3A15248420DD8C4973",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 1198,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0161",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043057",
    //     "appEUI": "app",
    //     "appKEY": "CDF855628F9D7C8ECF7001E4C0BA5B00",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 139,
    //     "prevFrameCounterFcnt": 3967,
    //     "latestFrameCounterFcnt": 3968,
    //     "lostFcnt": 1128,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1199,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0162",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004304d",
    //     "appEUI": "app",
    //     "appKEY": "94A1C3BF415D61C79BED6D00F3592AD2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 135,
    //     "prevFrameCounterFcnt": 554,
    //     "latestFrameCounterFcnt": 568,
    //     "lostFcnt": -742,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1200,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0163",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043045",
    //     "appEUI": "app",
    //     "appKEY": "CAD929088DA5A781EB8413DD9693115D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 142,
    //     "prevFrameCounterFcnt": 3967,
    //     "latestFrameCounterFcnt": 3968,
    //     "lostFcnt": 1307,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1201,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0164",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043058",
    //     "appEUI": "app",
    //     "appKEY": "55AB2C73AD18C4DF09CEC2DDEE34BD5F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 139,
    //     "prevFrameCounterFcnt": 3961,
    //     "latestFrameCounterFcnt": 3963,
    //     "lostFcnt": 1260,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1202,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0165",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043047",
    //     "appEUI": "app",
    //     "appKEY": "8FAF973E574E4C4D258B35D5A3EC7372",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 135,
    //     "prevFrameCounterFcnt": 38,
    //     "latestFrameCounterFcnt": 58,
    //     "lostFcnt": -797,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1203,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0166",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004303e",
    //     "appEUI": "app",
    //     "appKEY": "361CE70C79AD309700CE009938315811",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 129,
    //     "prevFrameCounterFcnt": 3957,
    //     "latestFrameCounterFcnt": 3958,
    //     "lostFcnt": 791,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1204,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0167",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004305b",
    //     "appEUI": "app",
    //     "appKEY": "149B840A0898406B41D96923F27685FF",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 139,
    //     "prevFrameCounterFcnt": 3967,
    //     "latestFrameCounterFcnt": 3968,
    //     "lostFcnt": 1017,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1205,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0168",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042ffd",
    //     "appEUI": "app",
    //     "appKEY": "C5BE779A7C1E8430FD81D2B1AF2B204F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 139,
    //     "prevFrameCounterFcnt": 1783,
    //     "latestFrameCounterFcnt": 1784,
    //     "lostFcnt": -96,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1206,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0169",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004304e",
    //     "appEUI": "app",
    //     "appKEY": "6B0DF7E4F9CAC4738556E4DBDC366EBC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 139,
    //     "prevFrameCounterFcnt": 3967,
    //     "latestFrameCounterFcnt": 3968,
    //     "lostFcnt": 1168,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1207,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0170",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043041",
    //     "appEUI": "app",
    //     "appKEY": "E604C32C745FD919190800AA75DDE20D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 139,
    //     "prevFrameCounterFcnt": 3967,
    //     "latestFrameCounterFcnt": 3968,
    //     "lostFcnt": 1000,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1208,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0171",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043034",
    //     "appEUI": "app",
    //     "appKEY": "43F80EC542F4D51448C8ACC89A0D9F18",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 175,
    //     "prevFrameCounterFcnt": 4003,
    //     "latestFrameCounterFcnt": 4004,
    //     "lostFcnt": 1060,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1209,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0172",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043043",
    //     "appEUI": "app",
    //     "appKEY": "3AE7A08C74645B00A3FCC423CBE71266",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 176,
    //     "prevFrameCounterFcnt": 4002,
    //     "latestFrameCounterFcnt": 4003,
    //     "lostFcnt": 847,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1210,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0173",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004305d",
    //     "appEUI": "app",
    //     "appKEY": "4D1ECF402C5900B9621FCDF6B6CF5312",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 137,
    //     "prevFrameCounterFcnt": 3905,
    //     "latestFrameCounterFcnt": 3906,
    //     "lostFcnt": 2276,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1211,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0174",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004304c",
    //     "appEUI": "app",
    //     "appKEY": "D755142D3637CC48DF8CD70AD54A38FD",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 138,
    //     "prevFrameCounterFcnt": 3966,
    //     "latestFrameCounterFcnt": 3967,
    //     "lostFcnt": 1027,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1212,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0175",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043042",
    //     "appEUI": "app",
    //     "appKEY": "AE81D79C9AA88FDB1A23C4B24F6B8613",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 175,
    //     "prevFrameCounterFcnt": 3904,
    //     "latestFrameCounterFcnt": 3906,
    //     "lostFcnt": 1403,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1213,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0176",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043063",
    //     "appEUI": "app",
    //     "appKEY": "6067D2B5C30348FF55C333FFCF7CF0E5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 175,
    //     "prevFrameCounterFcnt": 4003,
    //     "latestFrameCounterFcnt": 4004,
    //     "lostFcnt": 1209,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1214,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0177",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004305c",
    //     "appEUI": "app",
    //     "appKEY": "872E67F36F7A4EC44C03CB41FFFBAEFE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 176,
    //     "prevFrameCounterFcnt": 4004,
    //     "latestFrameCounterFcnt": 4005,
    //     "lostFcnt": 819,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1215,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0178",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004303c",
    //     "appEUI": "app",
    //     "appKEY": "EF9D73D55B22CFBE863F6E87D4D08C58",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 175,
    //     "prevFrameCounterFcnt": 4003,
    //     "latestFrameCounterFcnt": 4004,
    //     "lostFcnt": 1508,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1216,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0179",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004303b",
    //     "appEUI": "app",
    //     "appKEY": "732A2EEC7948409AF393817BE774AF22",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 175,
    //     "prevFrameCounterFcnt": 4003,
    //     "latestFrameCounterFcnt": 4006,
    //     "lostFcnt": 1418,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1217,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0180",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043044",
    //     "appEUI": "app",
    //     "appKEY": "C729BF0D915BDE5F8A9529E3B49E6B09",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 175,
    //     "prevFrameCounterFcnt": 4004,
    //     "latestFrameCounterFcnt": 4006,
    //     "lostFcnt": 2059,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1218,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0181",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043038",
    //     "appEUI": "app",
    //     "appKEY": "09AE3AD287DC4147DAE4F6F69238DC8B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 176,
    //     "prevFrameCounterFcnt": 4002,
    //     "latestFrameCounterFcnt": 4005,
    //     "lostFcnt": 1027,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1219,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0182",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004304b",
    //     "appEUI": "app",
    //     "appKEY": "480097A0A6A7C09D49B4B234495AB1CF",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 176,
    //     "prevFrameCounterFcnt": 4005,
    //     "latestFrameCounterFcnt": 4007,
    //     "lostFcnt": 1615,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1220,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0183",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043064",
    //     "appEUI": "app",
    //     "appKEY": "96249B372AAEC2B0591C678C459A6520",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 176,
    //     "prevFrameCounterFcnt": 4004,
    //     "latestFrameCounterFcnt": 4005,
    //     "lostFcnt": 1039,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1221,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0184",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043048",
    //     "appEUI": "app",
    //     "appKEY": "21EA19700A75CB98EB6C77DEF54CB044",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 176,
    //     "prevFrameCounterFcnt": 4004,
    //     "latestFrameCounterFcnt": 4005,
    //     "lostFcnt": 843,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1222,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0185",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004304f",
    //     "appEUI": "app",
    //     "appKEY": "C015D5E49FDC47236BE72356C21104CA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 176,
    //     "prevFrameCounterFcnt": 4004,
    //     "latestFrameCounterFcnt": 4005,
    //     "lostFcnt": 727,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1223,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0186",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043028",
    //     "appEUI": "app",
    //     "appKEY": "12A22B3568CB8F57B282BFBECEEC88D7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 176,
    //     "prevFrameCounterFcnt": 4005,
    //     "latestFrameCounterFcnt": 4007,
    //     "lostFcnt": 1120,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1224,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0187",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043035",
    //     "appEUI": "app",
    //     "appKEY": "A8D250266B2E6B478C9441FE0F07DD65",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 176,
    //     "prevFrameCounterFcnt": 4005,
    //     "latestFrameCounterFcnt": 4007,
    //     "lostFcnt": 906,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1225,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0188",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004303f",
    //     "appEUI": "app",
    //     "appKEY": "37AD582016D09E5654E1C1FB9AB1CB36",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 176,
    //     "prevFrameCounterFcnt": 4004,
    //     "latestFrameCounterFcnt": 4005,
    //     "lostFcnt": 842,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1226,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0189",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004301c",
    //     "appEUI": "app",
    //     "appKEY": "0CED3B0C896A9927BB96A034A0512C26",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 176,
    //     "prevFrameCounterFcnt": 4004,
    //     "latestFrameCounterFcnt": 4005,
    //     "lostFcnt": 1398,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1227,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0190",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043024",
    //     "appEUI": "app",
    //     "appKEY": "A708F77405FBDE3F7681549F7DABEFB2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 176,
    //     "prevFrameCounterFcnt": 4004,
    //     "latestFrameCounterFcnt": 4005,
    //     "lostFcnt": 1026,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1228,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0191",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043060",
    //     "appEUI": "app",
    //     "appKEY": "EF22D8FC37D06550C77EC4E7DDBD26A8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 176,
    //     "prevFrameCounterFcnt": 4004,
    //     "latestFrameCounterFcnt": 4005,
    //     "lostFcnt": 895,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1229,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0192",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043049",
    //     "appEUI": "app",
    //     "appKEY": "6F8538C97FF0C870EFEC9FEA16568809",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 176,
    //     "prevFrameCounterFcnt": 4005,
    //     "latestFrameCounterFcnt": 4007,
    //     "lostFcnt": 986,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1230,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0193",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043052",
    //     "appEUI": "app",
    //     "appKEY": "10363B9F87728A5EC32CDA3F56194026",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 176,
    //     "prevFrameCounterFcnt": 4004,
    //     "latestFrameCounterFcnt": 4005,
    //     "lostFcnt": 936,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1231,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0194",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004301b",
    //     "appEUI": "app",
    //     "appKEY": "14D21265BF1045C4E5CF0F00F9146E60",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 4005,
    //     "latestFrameCounterFcnt": 4006,
    //     "lostFcnt": 1025,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1232,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0195",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043040",
    //     "appEUI": "app",
    //     "appKEY": "1DD24E3CF4E68CFA6CE99FE10863BD12",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 176,
    //     "prevFrameCounterFcnt": 4004,
    //     "latestFrameCounterFcnt": 4005,
    //     "lostFcnt": 752,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1233,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0196",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043036",
    //     "appEUI": "app",
    //     "appKEY": "BA6CB243EE1A5D1F52298E7D2620F8F9",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 176,
    //     "prevFrameCounterFcnt": 3999,
    //     "latestFrameCounterFcnt": 4000,
    //     "lostFcnt": 1764,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1234,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0197",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043029",
    //     "appEUI": "app",
    //     "appKEY": "43651447C6E717897DEE961837E97777",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 2718,
    //     "latestFrameCounterFcnt": 2719,
    //     "lostFcnt": 198,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1235,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0198",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004303a",
    //     "appEUI": "app",
    //     "appKEY": "C369EFB7BCFADA99886323DCC77E25BA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 4005,
    //     "latestFrameCounterFcnt": 4006,
    //     "lostFcnt": 961,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1236,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0199",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004302e",
    //     "appEUI": "app",
    //     "appKEY": "8E6AE9A3EE9B10004B5D588EC9C14824",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 4005,
    //     "latestFrameCounterFcnt": 4006,
    //     "lostFcnt": 1106,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1237,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0200",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043033",
    //     "appEUI": "app",
    //     "appKEY": "34A75A302B30A4ECB0F2962C40DB3504",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 617,
    //     "latestFrameCounterFcnt": 618,
    //     "lostFcnt": -869,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1238,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0201",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043002",
    //     "appEUI": "app",
    //     "appKEY": "8E6667F9E9701A83C041BC8793788AE0",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 2133,
    //     "latestFrameCounterFcnt": 2134,
    //     "lostFcnt": -330,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1239,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0202",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fea",
    //     "appEUI": "app",
    //     "appKEY": "5D77F55385008C1750E1C70CBF989B3E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 4005,
    //     "latestFrameCounterFcnt": 4006,
    //     "lostFcnt": 928,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1240,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0203",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004300b",
    //     "appEUI": "app",
    //     "appKEY": "726EAA2440EB2743FF3CA72E8C58A95D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 4004,
    //     "latestFrameCounterFcnt": 4006,
    //     "lostFcnt": 1723,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1241,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0204",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043007",
    //     "appEUI": "app",
    //     "appKEY": "E5334F8AC94FC0B6BC8A4C01E4D5E021",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 4006,
    //     "latestFrameCounterFcnt": 4008,
    //     "lostFcnt": 1774,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1242,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0205",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043005",
    //     "appEUI": "app",
    //     "appKEY": "EE7489F381B9A2FD994DBB45CAC81BB4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 908,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1243,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0206",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043012",
    //     "appEUI": "app",
    //     "appKEY": "5601BC7304776807D6353C6836E6F791",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 1270,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1244,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0207",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043000",
    //     "appEUI": "app",
    //     "appKEY": "B4F8B8FE2CB272559DD6E7ABEE1E7AD9",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 4006,
    //     "latestFrameCounterFcnt": 4008,
    //     "lostFcnt": 1222,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1245,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0208",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042ffe",
    //     "appEUI": "app",
    //     "appKEY": "45BE61ADB920064E6904783B11DD9DAA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 2213,
    //     "latestFrameCounterFcnt": 2218,
    //     "lostFcnt": 7,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1246,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0209",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043004",
    //     "appEUI": "app",
    //     "appKEY": "D2E7D459240E392DA34A507686EA9EE9",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 718,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1247,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0210",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042ff9",
    //     "appEUI": "app",
    //     "appKEY": "C1AF54C76CD71FF78FEDB48DE057D650",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 700,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1248,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0211",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004300f",
    //     "appEUI": "app",
    //     "appKEY": "50CE53EA39872783382BDF1461976490",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 176,
    //     "prevFrameCounterFcnt": 4004,
    //     "latestFrameCounterFcnt": 4005,
    //     "lostFcnt": 816,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1249,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0212",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042ff0",
    //     "appEUI": "app",
    //     "appKEY": "A5719D8BC6FAFB82BB312862D3427DCF",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 4005,
    //     "latestFrameCounterFcnt": 4006,
    //     "lostFcnt": 797,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1250,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0213",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042feb",
    //     "appEUI": "app",
    //     "appKEY": "EBACA78CA40703E53E1F68F5A77C723B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 4005,
    //     "latestFrameCounterFcnt": 4006,
    //     "lostFcnt": 1363,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1251,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0214",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042ff5",
    //     "appEUI": "app",
    //     "appKEY": "0042D8323397B5DB39B1D0632B58A362",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 1017,
    //     "latestFrameCounterFcnt": 1019,
    //     "lostFcnt": -150,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1252,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0215",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042ff6",
    //     "appEUI": "app",
    //     "appKEY": "F5FF03FF284CA2E429A9748F69EE856C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 4005,
    //     "latestFrameCounterFcnt": 4006,
    //     "lostFcnt": 1734,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1253,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0216",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043006",
    //     "appEUI": "app",
    //     "appKEY": "C73FAD3F39844C8D39B090415B875304",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 4005,
    //     "latestFrameCounterFcnt": 4006,
    //     "lostFcnt": 2561,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1254,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0217",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042ffa",
    //     "appEUI": "app",
    //     "appKEY": "28B55740F0C905FA3C5418C62FE6DAAB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 4005,
    //     "latestFrameCounterFcnt": 4006,
    //     "lostFcnt": 1297,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1255,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0218",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fef",
    //     "appEUI": "app",
    //     "appKEY": "7FAEFC0375ACB657C9F002DD82995715",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 4006,
    //     "latestFrameCounterFcnt": 4008,
    //     "lostFcnt": 1355,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1256,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0219",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fec",
    //     "appEUI": "app",
    //     "appKEY": "6B89A808B088B68FD33FE56B3E237EE7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 185,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4016,
    //     "lostFcnt": 1619,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1257,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0220",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042ffb",
    //     "appEUI": "app",
    //     "appKEY": "B762BBCE2C4E32B9553227585C0632E2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 4005,
    //     "latestFrameCounterFcnt": 4006,
    //     "lostFcnt": 1805,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1258,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0221",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043010",
    //     "appEUI": "app",
    //     "appKEY": "DDCA452A6385AA5B4CFA6FA5BD882215",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 185,
    //     "prevFrameCounterFcnt": 1465,
    //     "latestFrameCounterFcnt": 1466,
    //     "lostFcnt": 137,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1259,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0222",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043001",
    //     "appEUI": "app",
    //     "appKEY": "32B157795339CA9772B768A0B4479424",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 1054,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1260,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0223",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043009",
    //     "appEUI": "app",
    //     "appKEY": "3ADA527D5CBF7A84E8197F51F0B364DF",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 185,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4016,
    //     "lostFcnt": 1008,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1261,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0224",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004300e",
    //     "appEUI": "app",
    //     "appKEY": "514FB139E4D098DBA900ACDA1FB521AE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 1163,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1262,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0225",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043003",
    //     "appEUI": "app",
    //     "appKEY": "61B943BB9F5F603D7EBE7CDD242028AB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 927,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1263,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0226",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fff",
    //     "appEUI": "app",
    //     "appKEY": "86E70C82A6E1A2F8B2A208A875761FCE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 3996,
    //     "latestFrameCounterFcnt": 3997,
    //     "lostFcnt": 1545,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1264,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0227",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042ff4",
    //     "appEUI": "app",
    //     "appKEY": "FC63A4BB71C367F4C6949EE1ECE1F59C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 185,
    //     "prevFrameCounterFcnt": 4013,
    //     "latestFrameCounterFcnt": 4014,
    //     "lostFcnt": 953,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1265,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0228",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043018",
    //     "appEUI": "app",
    //     "appKEY": "0B5D2DEA95B7CA06431538E8A9B3D60A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 836,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1266,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0229",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043013",
    //     "appEUI": "app",
    //     "appKEY": "19937417C10DE5F932FA511794A59A1E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 1783,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1267,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0230",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042ff1",
    //     "appEUI": "app",
    //     "appKEY": "C8DE402239AC45544BFD7BB71C31E8B2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 185,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4016,
    //     "lostFcnt": 770,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1268,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0231",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004300a",
    //     "appEUI": "app",
    //     "appKEY": "0B1F141C34D69AC0C2AFF2F3DC63C348",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 185,
    //     "prevFrameCounterFcnt": 4013,
    //     "latestFrameCounterFcnt": 4014,
    //     "lostFcnt": 1234,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1269,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0232",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043014",
    //     "appEUI": "app",
    //     "appKEY": "6BB01347081755308F03DF3DC61F2C18",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 1292,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1270,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0233",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043020",
    //     "appEUI": "app",
    //     "appKEY": "C2FD16EB15EFCACE2715FAAA117290E6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 3941,
    //     "latestFrameCounterFcnt": 3954,
    //     "lostFcnt": 1427,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1271,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0234",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043008",
    //     "appEUI": "app",
    //     "appKEY": "A0FEADC5765B21D9E47698524485C713",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 190,
    //     "prevFrameCounterFcnt": 4015,
    //     "latestFrameCounterFcnt": 4016,
    //     "lostFcnt": 1469,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1272,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0235",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004300d",
    //     "appEUI": "app",
    //     "appKEY": "D1897F549D87539C73EFDD097A04B1AD",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 187,
    //     "prevFrameCounterFcnt": 4015,
    //     "latestFrameCounterFcnt": 4016,
    //     "lostFcnt": 1975,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1273,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0236",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004300c",
    //     "appEUI": "app",
    //     "appKEY": "4CB84D08B502855145E7522062CE9A77",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 187,
    //     "prevFrameCounterFcnt": 4015,
    //     "latestFrameCounterFcnt": 4016,
    //     "lostFcnt": 1017,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1274,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0237",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043017",
    //     "appEUI": "app",
    //     "appKEY": "8764DA76C4327C93E614CAE0B053A5F0",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 1416,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1275,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0238",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043011",
    //     "appEUI": "app",
    //     "appKEY": "015565AE6F334F022174F7CB09BFB5DB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 185,
    //     "prevFrameCounterFcnt": 4013,
    //     "latestFrameCounterFcnt": 4014,
    //     "lostFcnt": 950,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1276,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0239",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043015",
    //     "appEUI": "app",
    //     "appKEY": "09071D36AED278F788C77CB71364BE5A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 1486,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1277,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0240",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720043016",
    //     "appEUI": "app",
    //     "appKEY": "6BD405A2FC6513676C7F10A140E7C92D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4013,
    //     "latestFrameCounterFcnt": 4014,
    //     "lostFcnt": 911,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1278,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0241",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042953",
    //     "appEUI": "app",
    //     "appKEY": "CC62BA7BB01B9729563B593B01400AE2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -108,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 697,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1279,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0242",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042911",
    //     "appEUI": "app",
    //     "appKEY": "28E00444585C6A7FC520CF69B959B51E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -114,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 878,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1280,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0243",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004295a",
    //     "appEUI": "app",
    //     "appKEY": "C9D30A8E202A9857BF347085F8351209",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -108,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 722,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1281,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0244",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042957",
    //     "appEUI": "app",
    //     "appKEY": "ABE5073517BF84D07D7F0836CCFA2D4B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 814,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1282,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0245",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042910",
    //     "appEUI": "app",
    //     "appKEY": "DF0AC93D5F97A89B22AFC3C175453DB3",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 699,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1283,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0246",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042952",
    //     "appEUI": "app",
    //     "appKEY": "28C7A20A684DEB7F93DFF9DA0CCBEE5A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 912,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1284,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0247",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042902",
    //     "appEUI": "app",
    //     "appKEY": "34AFA83367E4B1B5AC9DB0AFD6D58CF2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 720,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1285,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0248",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042909",
    //     "appEUI": "app",
    //     "appKEY": "C27D16CAD81934D09151D17ABE880B6E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 687,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1286,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0249",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042955",
    //     "appEUI": "app",
    //     "appKEY": "A5C58677F7E80E20CCC3C059A9388958",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -112,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 687,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1287,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0250",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428f7",
    //     "appEUI": "app",
    //     "appKEY": "6F3EC89FD7026E4EF4AEE2DAA5D39247",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4223,
    //     "latestFrameCounterFcnt": 4225,
    //     "lostFcnt": 671,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1288,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0251",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004290b",
    //     "appEUI": "app",
    //     "appKEY": "6F8F8A69B4D42E10D478B567B7A97EAE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -103,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4214,
    //     "latestFrameCounterFcnt": 4215,
    //     "lostFcnt": 642,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1289,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0252",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042950",
    //     "appEUI": "app",
    //     "appKEY": "556D49378870844305EDEFD92BD18672",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -109,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 676,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1290,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0253",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042958",
    //     "appEUI": "app",
    //     "appKEY": "7175FEE2C5AE10647E9D366612A1CBDA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4224,
    //     "lostFcnt": 710,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1291,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0254",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042942",
    //     "appEUI": "app",
    //     "appKEY": "439F49319622DFC6A662EDA53CD3D2AA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 1292,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0255",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042931",
    //     "appEUI": "app",
    //     "appKEY": "688093148D167349AE85001842107640",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4214,
    //     "latestFrameCounterFcnt": 4215,
    //     "lostFcnt": 742,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1293,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0256",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042954",
    //     "appEUI": "app",
    //     "appKEY": "112CFCE6BA1FA586BB86250A6C0F4114",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 755,
    //     "loraSNR": 0,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1294,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0257",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004294c",
    //     "appEUI": "app",
    //     "appKEY": "0D34E109BBDB3C1F5264A734CAB664DD",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4214,
    //     "latestFrameCounterFcnt": 4215,
    //     "lostFcnt": 703,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1295,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0258",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004294d",
    //     "appEUI": "app",
    //     "appKEY": "E6C054720EE81C6425D53E3F9ECD4024",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4220,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 707,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1296,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0259",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428f0",
    //     "appEUI": "app",
    //     "appKEY": "193E8460D7F14CE13E51E0CBFC4B99D4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 898,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1297,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0260",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042934",
    //     "appEUI": "app",
    //     "appKEY": "9AAE3757343EE0FBC0281EC658419DD3",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -100,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 641,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1298,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0261",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004294f",
    //     "appEUI": "app",
    //     "appKEY": "F505A698516D8422AD75CA49A968C034",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 4211,
    //     "latestFrameCounterFcnt": 4212,
    //     "lostFcnt": 988,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1299,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0262",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428fc",
    //     "appEUI": "app",
    //     "appKEY": "B725F3DD17565AE03E2D342592FE0BF8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 1801,
    //     "latestFrameCounterFcnt": 1802,
    //     "lostFcnt": 183,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1300,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0263",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042947",
    //     "appEUI": "app",
    //     "appKEY": "6E6ADBBE6A55CD437C1409C9B1A348FB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -102,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 665,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1301,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0264",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042901",
    //     "appEUI": "app",
    //     "appKEY": "39EFD7C7E8BA482F6F9E91BB98A5C08E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 770,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1302,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0265",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042907",
    //     "appEUI": "app",
    //     "appKEY": "43387574A1718449D9C9F20AC1DD3AA8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 658,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1303,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0266",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004290e",
    //     "appEUI": "app",
    //     "appKEY": "ADE6C9BA0993505CC3AF1151545E936C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -112,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 692,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1304,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0267",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004294e",
    //     "appEUI": "app",
    //     "appKEY": "0BDFA7B0574FF37208316957E30535BF",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4221,
    //     "latestFrameCounterFcnt": 4222,
    //     "lostFcnt": 825,
    //     "loraSNR": -6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1305,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0268",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042905",
    //     "appEUI": "app",
    //     "appKEY": "CB1B90997F131A80BF4CCEBD87CC8682",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -111,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 645,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1306,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0269",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042959",
    //     "appEUI": "app",
    //     "appKEY": "DEDE8612BEE7CD68832F4E5AB14F499F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4214,
    //     "latestFrameCounterFcnt": 4216,
    //     "lostFcnt": 763,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1307,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0270",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042948",
    //     "appEUI": "app",
    //     "appKEY": "295B9239C1F639BE5774DE90A11F480C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 4208,
    //     "latestFrameCounterFcnt": 4209,
    //     "lostFcnt": 964,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1308,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0271",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429a2",
    //     "appEUI": "app",
    //     "appKEY": "E71A54ECF0D19391400228BD56BBCCB2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -96,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 659,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1309,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0272",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042951",
    //     "appEUI": "app",
    //     "appKEY": "E9DCD73C4B1477EFFE47A1214734547A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 886,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1310,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0273",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042946",
    //     "appEUI": "app",
    //     "appKEY": "609536C69594EC8D237E2FE8E461164D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -108,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4224,
    //     "lostFcnt": 666,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1311,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0274",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042906",
    //     "appEUI": "app",
    //     "appKEY": "CB5C92B7C10F8038766064DAF03C055F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 786,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1312,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0275",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042956",
    //     "appEUI": "app",
    //     "appKEY": "AE4D1AB3CC25D0E31C64FFDCED49663D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4223,
    //     "latestFrameCounterFcnt": 4225,
    //     "lostFcnt": 861,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1313,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0276",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004294b",
    //     "appEUI": "app",
    //     "appKEY": "B918CDA225017A8EA5FF641C52E2AEC4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -107,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 665,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1314,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0277",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428fd",
    //     "appEUI": "app",
    //     "appKEY": "D84F892E31F7F00B9B1C73213508108B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -108,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 679,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1315,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0278",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042949",
    //     "appEUI": "app",
    //     "appKEY": "316E885D88E3E232EF762C87B5201110",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 755,
    //     "loraSNR": 0,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1316,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0279",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004294a",
    //     "appEUI": "app",
    //     "appKEY": "8318AC07AEC3DF47842DACDCA251B447",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 1029,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1317,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0280",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004290f",
    //     "appEUI": "app",
    //     "appKEY": "38865AD02774C608145BF82F95A0CC4A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 723,
    //     "loraSNR": 0,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1318,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0281",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428e2",
    //     "appEUI": "app",
    //     "appKEY": "C2D7FC121A7BBFFD1F02902A32965159",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 2953,
    //     "latestFrameCounterFcnt": 2954,
    //     "lostFcnt": 321,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1319,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0282",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004292b",
    //     "appEUI": "app",
    //     "appKEY": "1F91D3208875EFC5052AFFF4D562D988",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4224,
    //     "lostFcnt": 750,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1320,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0283",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428eb",
    //     "appEUI": "app",
    //     "appKEY": "365A91675D95F047A46553A2873EDDF2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 795,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1321,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0284",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428e1",
    //     "appEUI": "app",
    //     "appKEY": "FF67FE530A8489334474F1CE9AAD1EF5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -112,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 780,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1322,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0285",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200430a4",
    //     "appEUI": "app",
    //     "appKEY": "C787D33088A3E660DEF04BC9F5ED6368",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 775,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1323,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0286",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428ef",
    //     "appEUI": "app",
    //     "appKEY": "2DCC987CC6893DAEECBC8E0776387E84",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4215,
    //     "latestFrameCounterFcnt": 4216,
    //     "lostFcnt": 967,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1324,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0287",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428ec",
    //     "appEUI": "app",
    //     "appKEY": "E00BED5C8BF68B359CDC4A85300EDFB2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4223,
    //     "latestFrameCounterFcnt": 4225,
    //     "lostFcnt": 830,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1325,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0288",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042939",
    //     "appEUI": "app",
    //     "appKEY": "E79CAC6DC6057C24736766372889C98C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -116,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 743,
    //     "loraSNR": 0,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1326,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0289",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428f4",
    //     "appEUI": "app",
    //     "appKEY": "B12C356504F76935EC0ED3DAA9417ED2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -100,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 639,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1327,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0290",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004290c",
    //     "appEUI": "app",
    //     "appKEY": "1EEA595593B1D769B47C8728F0D8F736",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4215,
    //     "lostFcnt": 769,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1328,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0291",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428f5",
    //     "appEUI": "app",
    //     "appKEY": "6222744FBC49669F4C9854EDFB51B87E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -101,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 675,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1329,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0292",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042930",
    //     "appEUI": "app",
    //     "appKEY": "B6112452333661EFC1D82389F334408B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -114,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 721,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1330,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0293",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428f1",
    //     "appEUI": "app",
    //     "appKEY": "443F170A622F20F056304113327695EA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 861,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1331,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0294",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042945",
    //     "appEUI": "app",
    //     "appKEY": "AEDEF59C02B027D6B023BD10A8FD4C97",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -103,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 657,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1332,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0295",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042944",
    //     "appEUI": "app",
    //     "appKEY": "E68787F148CFA9EFE684DF16E04CEFC9",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4214,
    //     "latestFrameCounterFcnt": 4215,
    //     "lostFcnt": 812,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1333,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0296",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004293c",
    //     "appEUI": "app",
    //     "appKEY": "28B86D82FE513F5E08B3838F912E3878",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 796,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1334,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0297",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042940",
    //     "appEUI": "app",
    //     "appKEY": "52494C40E55DF2C2E77BB206D2D98C8C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4224,
    //     "lostFcnt": 716,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1335,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0298",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042943",
    //     "appEUI": "app",
    //     "appKEY": "7FFF911A491344FAB785101E4AC98B56",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -112,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 675,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1336,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0299",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042908",
    //     "appEUI": "app",
    //     "appKEY": "833CF40DF40EF09B4E068E765AB2E922",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4224,
    //     "lostFcnt": 704,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1337,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0300",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004290a",
    //     "appEUI": "app",
    //     "appKEY": "3C508F37075DEBE4476B5565980952C0",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -102,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 656,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1338,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0301",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004295b",
    //     "appEUI": "app",
    //     "appKEY": "9CEA2A7C73260A52986E4FEC14652615",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 655,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1339,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0302",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004293a",
    //     "appEUI": "app",
    //     "appKEY": "F52903CA8BF88C9C6F7B3D62C8ED9278",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4214,
    //     "latestFrameCounterFcnt": 4215,
    //     "lostFcnt": 876,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1340,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0303",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428e0",
    //     "appEUI": "app",
    //     "appKEY": "4D2EEE6A886D48D0AA03D0E74D9E0DAA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -105,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 709,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1341,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0304",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042933",
    //     "appEUI": "app",
    //     "appKEY": "3C2F01275776B9D924CC3F0557BCE62B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 20,
    //     "prevFrameCounterFcnt": 4219,
    //     "latestFrameCounterFcnt": 4220,
    //     "lostFcnt": 818,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1342,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0305",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004293b",
    //     "appEUI": "app",
    //     "appKEY": "9B3A4A8E02374351BDF286069B72812F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 749,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1343,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0306",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200430a9",
    //     "appEUI": "app",
    //     "appKEY": "FDB87855F892DF7019D5A0D978AB6D26",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4224,
    //     "lostFcnt": 779,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1344,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0307",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428e8",
    //     "appEUI": "app",
    //     "appKEY": "0408D99B49E01AB1F336864FF24CFA40",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -106,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 657,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1345,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0308",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004293d",
    //     "appEUI": "app",
    //     "appKEY": "41370703180C8B44452DDE6E33CB42DC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -111,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4214,
    //     "latestFrameCounterFcnt": 4215,
    //     "lostFcnt": 754,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1346,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0309",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428f8",
    //     "appEUI": "app",
    //     "appKEY": "607727F69D8A270A2F74935201A2757F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -108,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 4212,
    //     "latestFrameCounterFcnt": 4213,
    //     "lostFcnt": 668,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1347,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0310",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042936",
    //     "appEUI": "app",
    //     "appKEY": "273C895578BB83C0E677C766347E7E93",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -108,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4224,
    //     "lostFcnt": 708,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1348,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0311",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004309d",
    //     "appEUI": "app",
    //     "appKEY": "FF96D5FFA4437E78E65564B584E0534E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 705,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1349,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0312",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428e5",
    //     "appEUI": "app",
    //     "appKEY": "6CF26D10A6CCC136D99A9DF24E074753",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -114,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 709,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1350,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0313",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042935",
    //     "appEUI": "app",
    //     "appKEY": "C4F7BB800C7DE8D0537FD7E3DBF30C3A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4221,
    //     "latestFrameCounterFcnt": 4222,
    //     "lostFcnt": 932,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1351,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0314",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428ea",
    //     "appEUI": "app",
    //     "appKEY": "4A82134600EE5FC07C1082B62BD77595",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4224,
    //     "lostFcnt": 682,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1352,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0315",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004293e",
    //     "appEUI": "app",
    //     "appKEY": "F6B093CE57515132125B84AC2D4ED623",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4214,
    //     "latestFrameCounterFcnt": 4215,
    //     "lostFcnt": 906,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1353,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0316",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042938",
    //     "appEUI": "app",
    //     "appKEY": "3B560419908481551AB0FDF041BDC393",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -110,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4221,
    //     "latestFrameCounterFcnt": 4222,
    //     "lostFcnt": 761,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1354,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0317",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042937",
    //     "appEUI": "app",
    //     "appKEY": "F28AD676DC2B4419E01CB8D56DF45A39",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 4212,
    //     "latestFrameCounterFcnt": 4213,
    //     "lostFcnt": 710,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1355,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0318",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428f3",
    //     "appEUI": "app",
    //     "appKEY": "A9E58AFB1C9DE8E9A0F29BBE8D636FA4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 675,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1356,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0319",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004293f",
    //     "appEUI": "app",
    //     "appKEY": "8FBEAFE364CC969BA4833912174B7A72",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 698,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1357,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0320",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004292f",
    //     "appEUI": "app",
    //     "appKEY": "7875D64341A3208F00C5E8BC5F0D58D3",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 1612,
    //     "latestFrameCounterFcnt": 1613,
    //     "lostFcnt": 168,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1358,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0321",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004309b",
    //     "appEUI": "app",
    //     "appKEY": "3A7CEFF4B7A4F1EE9ABDA6F9542643F4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -105,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 4216,
    //     "latestFrameCounterFcnt": 4217,
    //     "lostFcnt": 660,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1359,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0322",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200430a2",
    //     "appEUI": "app",
    //     "appKEY": "CF5A7681A8A4C5610A0B9B6EA88266E5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4214,
    //     "latestFrameCounterFcnt": 4216,
    //     "lostFcnt": 786,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1360,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0323",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004292c",
    //     "appEUI": "app",
    //     "appKEY": "000E93DC14B9BB8CAF3534DAA298CBEE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -111,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 657,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1361,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0324",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042941",
    //     "appEUI": "app",
    //     "appKEY": "9E5CF22C6378BA854F36DBFDAC29E252",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -114,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4224,
    //     "lostFcnt": 705,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1362,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0325",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004292e",
    //     "appEUI": "app",
    //     "appKEY": "B018189D413CDEC6CB5910086D0ADA28",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -106,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 1667,
    //     "latestFrameCounterFcnt": 1668,
    //     "lostFcnt": 124,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1363,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0326",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428f2",
    //     "appEUI": "app",
    //     "appKEY": "CE43D4EF5274807AD581FD207C5922AE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -100,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 657,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1364,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0327",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428ee",
    //     "appEUI": "app",
    //     "appKEY": "BE0CA096C92FC733EC4F9796357213E7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 853,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1365,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0328",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428e7",
    //     "appEUI": "app",
    //     "appKEY": "E79D7FB7CF0FE45622C13FB9EEACA7BE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 406,
    //     "latestFrameCounterFcnt": 407,
    //     "lostFcnt": 44,
    //     "loraSNR": -5,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 1366,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0329",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004292d",
    //     "appEUI": "app",
    //     "appKEY": "61399C5E5187F840C3F0447338AB63EB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 705,
    //     "loraSNR": -6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1367,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0330",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428e4",
    //     "appEUI": "app",
    //     "appKEY": "4A98717FD792065813851E7BF6BEFCC7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -112,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 4215,
    //     "latestFrameCounterFcnt": 4216,
    //     "lostFcnt": 762,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1368,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0331",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428ed",
    //     "appEUI": "app",
    //     "appKEY": "9D98EBD98D03917C54B2968E547C6DC7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 685,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1369,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0332",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428e9",
    //     "appEUI": "app",
    //     "appKEY": "8D7C09E05E3AABCDB669D47DB2FCFCC6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -112,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4214,
    //     "latestFrameCounterFcnt": 4215,
    //     "lostFcnt": 664,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1370,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0333",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428e3",
    //     "appEUI": "app",
    //     "appKEY": "A8F171FEE3D0C204A027548B8B7ABCA1",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 867,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1371,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0334",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004309f",
    //     "appEUI": "app",
    //     "appKEY": "CE0B1CD33CE15B97FD5A8C091F5BF965",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 699,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1372,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0335",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004309e",
    //     "appEUI": "app",
    //     "appKEY": "FDBB47965D6B46FCD3F14DE37C612BFE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 883,
    //     "loraSNR": 0,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1373,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0336",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200430a0",
    //     "appEUI": "app",
    //     "appKEY": "222EBEE3E08CC981C54C8269CECA4CED",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -107,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 699,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1374,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0337",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004309c",
    //     "appEUI": "app",
    //     "appKEY": "4924E5A714FFC2636D02EAD7D1973278",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -101,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 645,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1375,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0338",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004295e",
    //     "appEUI": "app",
    //     "appKEY": "AD2431B868E90975105823F8A3754692",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4224,
    //     "lostFcnt": 811,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1376,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0339",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042989",
    //     "appEUI": "app",
    //     "appKEY": "5CBCF8D54A0B6EA72751B3920EB2AF6E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4223,
    //     "latestFrameCounterFcnt": 4225,
    //     "lostFcnt": 870,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1377,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0340",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042988",
    //     "appEUI": "app",
    //     "appKEY": "DB9C34EB8C19084CA0B15DB1F5E4CAB1",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -109,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 641,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1378,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0341",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042968",
    //     "appEUI": "app",
    //     "appKEY": "A9F7D3E14D778EDA9D09B26B08D7781E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4214,
    //     "latestFrameCounterFcnt": 4215,
    //     "lostFcnt": 860,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1379,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0342",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429bc",
    //     "appEUI": "app",
    //     "appKEY": "483D2F97FA7AA81F2ACFC8CDA564157D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 896,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1380,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0343",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004297f",
    //     "appEUI": "app",
    //     "appKEY": "A01088EE95692A51B68BFF83C5BD0C9A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4224,
    //     "lostFcnt": 841,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1381,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0344",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004296c",
    //     "appEUI": "app",
    //     "appKEY": "D945B4FDBC87BCB078CAFB4444A8817B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 862,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1382,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0345",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004296b",
    //     "appEUI": "app",
    //     "appKEY": "67A9EAFE71216157DA238893FD2E9123",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 732,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1383,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0346",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042974",
    //     "appEUI": "app",
    //     "appKEY": "8674FEB6CD642184B8508C40A4AD6F3D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 823,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1384,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0347",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042969",
    //     "appEUI": "app",
    //     "appKEY": "E0CDC741C92C2605DB17B19A11A7C3F4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 1137,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1385,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0348",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042966",
    //     "appEUI": "app",
    //     "appKEY": "184B9CE909379912E10A7B6BDB710E99",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -112,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4224,
    //     "lostFcnt": 656,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1386,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0349",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004295c",
    //     "appEUI": "app",
    //     "appKEY": "585C7ADA4A2ACAEF7B3B706B4734900C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4214,
    //     "latestFrameCounterFcnt": 4216,
    //     "lostFcnt": 899,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1387,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0350",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004291a",
    //     "appEUI": "app",
    //     "appKEY": "E160C21F8DCF24C9CFAFBB5777C84A00",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 946,
    //     "loraSNR": 0,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1388,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0351",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004298a",
    //     "appEUI": "app",
    //     "appKEY": "AEDC65B12EA0AE675D1FDE480C84C3AE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 805,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1389,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0352",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042913",
    //     "appEUI": "app",
    //     "appKEY": "E384D6AAF843C6E1BAFFE968D500E507",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4214,
    //     "latestFrameCounterFcnt": 4216,
    //     "lostFcnt": 879,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1390,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0353",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042987",
    //     "appEUI": "app",
    //     "appKEY": "B94B15473BDD2F9C689F6FD4591CABA2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 904,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1391,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0354",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004297c",
    //     "appEUI": "app",
    //     "appKEY": "F1A949E897D2756514ED39ACD94F6B73",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 853,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1392,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0355",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042980",
    //     "appEUI": "app",
    //     "appKEY": "E59C6250F22F41C40B5E2A6C5D105D5A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 920,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1393,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0356",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042970",
    //     "appEUI": "app",
    //     "appKEY": "1CE365B1FD4963FA3A95598B29B19214",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4224,
    //     "lostFcnt": 1006,
    //     "loraSNR": -6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1394,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0357",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042926",
    //     "appEUI": "app",
    //     "appKEY": "FA181CF22E5F800DA509BB1ADEE90C4D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 199,
    //     "prevFrameCounterFcnt": 376,
    //     "latestFrameCounterFcnt": 378,
    //     "lostFcnt": 11,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1395,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0358",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042983",
    //     "appEUI": "app",
    //     "appKEY": "B2937663A46ABAFDFD61CD4B0AAA9BBE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 1396,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0359",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042964",
    //     "appEUI": "app",
    //     "appKEY": "299B63788C7195164891BD1CEF59A7DA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 833,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1397,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0360",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004292a",
    //     "appEUI": "app",
    //     "appKEY": "0BA109D9B5F138D6A97BB660BB680348",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4224,
    //     "lostFcnt": 855,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1398,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0361",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004297d",
    //     "appEUI": "app",
    //     "appKEY": "AB5279A55897CF7F42055E657FC0173A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 936,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1399,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0362",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042963",
    //     "appEUI": "app",
    //     "appKEY": "8474B65FFA41ADD4481A3BBFEE60F219",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4221,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 930,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1400,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0363",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042961",
    //     "appEUI": "app",
    //     "appKEY": "5DB958961570314A55E11E07388AE788",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -97,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 656,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1401,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0364",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004295f",
    //     "appEUI": "app",
    //     "appKEY": "44D43E2832538150697AB9B6C2244736",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 713,
    //     "loraSNR": -6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1402,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0365",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004291d",
    //     "appEUI": "app",
    //     "appKEY": "F33F00DBC4044D13C7A05CD8F6C0C6D8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 904,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1403,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0366",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042915",
    //     "appEUI": "app",
    //     "appKEY": "0D9F62C6DD0A2517723BA181120FE2C1",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 866,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1404,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0367",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042967",
    //     "appEUI": "app",
    //     "appKEY": "2DF26789C4C2D20AEB21DFF6E8D279D4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 841,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1405,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0368",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042984",
    //     "appEUI": "app",
    //     "appKEY": "8C6D58CE927D8D0EEA82969D090E6429",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4224,
    //     "lostFcnt": 764,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1406,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0369",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042985",
    //     "appEUI": "app",
    //     "appKEY": "B39CE9EEDA2DFA70D2EE8B14AAC0D70B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 943,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1407,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0370",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042927",
    //     "appEUI": "app",
    //     "appKEY": "A1626D7AE513C7B623ACFA32E94E2C12",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 830,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1408,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0371",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042973",
    //     "appEUI": "app",
    //     "appKEY": "99BE67DF33C250A0976C86B96FC4BC8B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 779,
    //     "loraSNR": 0,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1409,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0372",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042975",
    //     "appEUI": "app",
    //     "appKEY": "EF4B71E0EAA9BCBEA872EB126640B708",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 805,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1410,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0373",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004297e",
    //     "appEUI": "app",
    //     "appKEY": "6E14FB5727316AC98F0DBCB1D6F9F3BF",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 4214,
    //     "latestFrameCounterFcnt": 4216,
    //     "lostFcnt": 1027,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1411,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0374",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004297b",
    //     "appEUI": "app",
    //     "appKEY": "25058502A34B5EEAA7FC2248BAA814C2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4224,
    //     "lostFcnt": 909,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1412,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0375",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042922",
    //     "appEUI": "app",
    //     "appKEY": "FBA73E6DE58B47792B5F86608E2EF852",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 803,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1413,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0376",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042962",
    //     "appEUI": "app",
    //     "appKEY": "4E9EBA5930E195F4A310D3CF29EB1551",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 1012,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1414,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0377",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042965",
    //     "appEUI": "app",
    //     "appKEY": "53B8D76AD4AD864DD6E37E405F683491",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 824,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1415,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0378",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429a1",
    //     "appEUI": "app",
    //     "appKEY": "3B51991C239C79EEC6D73F94DA11F6CB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -112,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 775,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1416,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0379",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004290d",
    //     "appEUI": "app",
    //     "appKEY": "4BBBAD7D0596C9A3D4518FC576B32EAE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -112,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4221,
    //     "latestFrameCounterFcnt": 4222,
    //     "lostFcnt": 755,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1417,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0380",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042929",
    //     "appEUI": "app",
    //     "appKEY": "0873576C73A8F24D588F54DAFEB6806A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 834,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1418,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0381",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042960",
    //     "appEUI": "app",
    //     "appKEY": "F51CF66077F49680BDB6AE4E768C02E8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4223,
    //     "latestFrameCounterFcnt": 4225,
    //     "lostFcnt": 834,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1419,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0382",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004291f",
    //     "appEUI": "app",
    //     "appKEY": "26C8BC0AB5E4B9374FC1B0C001E4EF06",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -112,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 680,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1420,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0383",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428fa",
    //     "appEUI": "app",
    //     "appKEY": "F2834F6011E5511866C4C826249F659C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4223,
    //     "latestFrameCounterFcnt": 4225,
    //     "lostFcnt": 764,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1421,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0384",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042972",
    //     "appEUI": "app",
    //     "appKEY": "90942FF786BBB8194157ABD6B50CCDCF",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 908,
    //     "loraSNR": -6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1422,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0385",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428fe",
    //     "appEUI": "app",
    //     "appKEY": "155785847381BC8772D99A84FB746EDA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4221,
    //     "latestFrameCounterFcnt": 4222,
    //     "lostFcnt": 858,
    //     "loraSNR": 0,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1423,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0386",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428ff",
    //     "appEUI": "app",
    //     "appKEY": "694B7C6418887A4108A684CF1150469B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -114,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 784,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1424,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0387",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004295d",
    //     "appEUI": "app",
    //     "appKEY": "06122D8806CECC02F4F610438A39C0EF",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 4211,
    //     "latestFrameCounterFcnt": 4213,
    //     "lostFcnt": 985,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1425,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0388",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042978",
    //     "appEUI": "app",
    //     "appKEY": "9B9BBFDEB783B474A6F48DF0A12BD02E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -112,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4223,
    //     "latestFrameCounterFcnt": 4225,
    //     "lostFcnt": 787,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1426,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0389",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042979",
    //     "appEUI": "app",
    //     "appKEY": "7EF73700BE23FFAECDBC3A210A228580",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4223,
    //     "latestFrameCounterFcnt": 4225,
    //     "lostFcnt": 864,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1427,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0390",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004297a",
    //     "appEUI": "app",
    //     "appKEY": "2CAD47B0C6625DAB9C5C78E74926533D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -110,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4224,
    //     "lostFcnt": 722,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1428,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0391",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042976",
    //     "appEUI": "app",
    //     "appKEY": "CB75C88F92967482F7D07ACF91B6F610",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 1038,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1429,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0392",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004296a",
    //     "appEUI": "app",
    //     "appKEY": "C61D98A3BD2ECEF9C8648F4B0E777907",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 4221,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 724,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1430,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0393",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042918",
    //     "appEUI": "app",
    //     "appKEY": "A495703B4356422BFD0F281B742E3579",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 686,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1431,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0394",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004291e",
    //     "appEUI": "app",
    //     "appKEY": "9E3F5CF38EB4387B0AB5907F7B606351",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -109,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4223,
    //     "latestFrameCounterFcnt": 4225,
    //     "lostFcnt": 659,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1432,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0395",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428f9",
    //     "appEUI": "app",
    //     "appKEY": "9A42577F3126D51F0D83D14417409BE8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 729,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1433,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0396",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004291c",
    //     "appEUI": "app",
    //     "appKEY": "23BFD9AB16CD1BEC42D69C4237BE6F51",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4223,
    //     "latestFrameCounterFcnt": 4225,
    //     "lostFcnt": 791,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1434,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0397",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042982",
    //     "appEUI": "app",
    //     "appKEY": "83CF4F3CB9E6D90CBBC235001E213ACA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 724,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1435,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0398",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042920",
    //     "appEUI": "app",
    //     "appKEY": "08B1370FE3CF132791A44C0DDAD25BAC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4158,
    //     "latestFrameCounterFcnt": 4159,
    //     "lostFcnt": 1244,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1436,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0399",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200428fb",
    //     "appEUI": "app",
    //     "appKEY": "EAC89B80B4E29EE442185E0FDDF417B2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 2,
    //     "prevFrameCounterFcnt": 2433,
    //     "latestFrameCounterFcnt": 2434,
    //     "lostFcnt": 1070,
    //     "loraSNR": -20,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1437,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0400",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042900",
    //     "appEUI": "app",
    //     "appKEY": "65722B802C24F4DA1F9FD55E783EFAC3",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4157,
    //     "latestFrameCounterFcnt": 4158,
    //     "lostFcnt": 1279,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1438,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0401",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042912",
    //     "appEUI": "app",
    //     "appKEY": "C9FC2FB4188941C9EC5459F80E7C88D5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4156,
    //     "latestFrameCounterFcnt": 4158,
    //     "lostFcnt": 1545,
    //     "loraSNR": -19,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1439,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0402",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042904",
    //     "appEUI": "app",
    //     "appKEY": "D3FC1C6F1BBD16E6E39020EC5858F168",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4158,
    //     "latestFrameCounterFcnt": 4159,
    //     "lostFcnt": 712,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1440,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0403",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042921",
    //     "appEUI": "app",
    //     "appKEY": "8F5634FAB13780A9A858E4A61A950705",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4157,
    //     "latestFrameCounterFcnt": 4158,
    //     "lostFcnt": 1008,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1441,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0404",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042919",
    //     "appEUI": "app",
    //     "appKEY": "7B54958449E070A2ABFFB3C89686D32C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4158,
    //     "latestFrameCounterFcnt": 4160,
    //     "lostFcnt": 1658,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1442,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0405",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042928",
    //     "appEUI": "app",
    //     "appKEY": "457282B92FD023CBE2E137111BA94B8C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4158,
    //     "latestFrameCounterFcnt": 4159,
    //     "lostFcnt": 994,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1443,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0406",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042924",
    //     "appEUI": "app",
    //     "appKEY": "A1E7060E5C36973598554B6250A83B97",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4156,
    //     "latestFrameCounterFcnt": 4157,
    //     "lostFcnt": 1593,
    //     "loraSNR": -20,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1444,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0407",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042971",
    //     "appEUI": "app",
    //     "appKEY": "593A72F70C22D5C7AD6640432728CA5B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4157,
    //     "latestFrameCounterFcnt": 4158,
    //     "lostFcnt": 778,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1445,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0408",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004296f",
    //     "appEUI": "app",
    //     "appKEY": "03E12B9B0436116D04F7E411EC27DB0A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4158,
    //     "latestFrameCounterFcnt": 4160,
    //     "lostFcnt": 1025,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1446,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0409",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042916",
    //     "appEUI": "app",
    //     "appKEY": "83AB96374E68E715991D355006273C6B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4157,
    //     "latestFrameCounterFcnt": 4158,
    //     "lostFcnt": 1038,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1447,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0410",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004291b",
    //     "appEUI": "app",
    //     "appKEY": "B876BEC0E6ABF3A22022F8B3958307B1",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2,
    //     "prevFrameCounterFcnt": 4158,
    //     "latestFrameCounterFcnt": 4159,
    //     "lostFcnt": 1515,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1448,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0411",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004296e",
    //     "appEUI": "app",
    //     "appKEY": "46B05D3E0A813803C0924E0E46546CD9",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 1010,
    //     "latestFrameCounterFcnt": 1011,
    //     "lostFcnt": 175,
    //     "loraSNR": -6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1449,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0412",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042914",
    //     "appEUI": "app",
    //     "appKEY": "83B80F220D7A20CE62B9DA123C4D5111",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -116,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4158,
    //     "latestFrameCounterFcnt": 4160,
    //     "lostFcnt": 1042,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1450,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0413",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042903",
    //     "appEUI": "app",
    //     "appKEY": "C9D118E376B998125FEA8E43B0786E99",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 2,
    //     "prevFrameCounterFcnt": 4157,
    //     "latestFrameCounterFcnt": 4158,
    //     "lostFcnt": 930,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1451,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0414",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042923",
    //     "appEUI": "app",
    //     "appKEY": "410F33948694A2A5BCC9AAD6FE10F337",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 2065,
    //     "latestFrameCounterFcnt": 2066,
    //     "lostFcnt": 250,
    //     "loraSNR": -19,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1452,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0415",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004296d",
    //     "appEUI": "app",
    //     "appKEY": "5B6BEFD415B0ED91BC5E2B2ACC889BF2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4158,
    //     "latestFrameCounterFcnt": 4159,
    //     "lostFcnt": 868,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1453,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0416",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042925",
    //     "appEUI": "app",
    //     "appKEY": "4F19F5E94784E4C04A7BBC68811D1325",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 2041,
    //     "latestFrameCounterFcnt": 2042,
    //     "lostFcnt": 397,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1454,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0417",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042917",
    //     "appEUI": "app",
    //     "appKEY": "9F8500375290CC755C3E42A9DD8EC905",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4158,
    //     "latestFrameCounterFcnt": 4159,
    //     "lostFcnt": 1057,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1455,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0418",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424f2",
    //     "appEUI": "app",
    //     "appKEY": "26734472477C31536C72EC3795D016A0",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4158,
    //     "latestFrameCounterFcnt": 4159,
    //     "lostFcnt": 1772,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1456,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0419",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424a9",
    //     "appEUI": "app",
    //     "appKEY": "07C85E88D518DE4E8E15284681C1A3A9",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4157,
    //     "latestFrameCounterFcnt": 4158,
    //     "lostFcnt": 1183,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1457,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0420",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424e4",
    //     "appEUI": "app",
    //     "appKEY": "C398765047927C639A871884EF0DDFB5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4158,
    //     "latestFrameCounterFcnt": 4160,
    //     "lostFcnt": 742,
    //     "loraSNR": -6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1458,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0421",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424f0",
    //     "appEUI": "app",
    //     "appKEY": "63E0F3C5559BED644D94FEB455652A1A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4157,
    //     "latestFrameCounterFcnt": 4158,
    //     "lostFcnt": 881,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1459,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0422",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a19",
    //     "appEUI": "app",
    //     "appKEY": "406AA6CEBAFCCF0B0A216A385C6A2DB7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4158,
    //     "latestFrameCounterFcnt": 4159,
    //     "lostFcnt": 957,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1460,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0423",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424e8",
    //     "appEUI": "app",
    //     "appKEY": "320171841E0B5D3A24577CED43E03A8D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4157,
    //     "latestFrameCounterFcnt": 4158,
    //     "lostFcnt": 1229,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1461,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0424",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424ee",
    //     "appEUI": "app",
    //     "appKEY": "11503EE047F97ED5F01A2AE2708290C6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4157,
    //     "latestFrameCounterFcnt": 4158,
    //     "lostFcnt": 1041,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1462,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0425",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004249f",
    //     "appEUI": "app",
    //     "appKEY": "FC8CD1120E066CAB03B26866B9F56BD7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4157,
    //     "latestFrameCounterFcnt": 4158,
    //     "lostFcnt": 1064,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1463,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0426",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424eb",
    //     "appEUI": "app",
    //     "appKEY": "C20F2ED3A4534AE5E9AB3509DD482DC1",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 2074,
    //     "latestFrameCounterFcnt": 2075,
    //     "lostFcnt": 217,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1464,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0427",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424a8",
    //     "appEUI": "app",
    //     "appKEY": "13548DB8954DCAF10F6F412CF69DA5A9",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4157,
    //     "latestFrameCounterFcnt": 4158,
    //     "lostFcnt": 1278,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1465,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0428",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424f7",
    //     "appEUI": "app",
    //     "appKEY": "096BE63AA4A0163F0EEC7E8F6515F548",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4158,
    //     "latestFrameCounterFcnt": 4159,
    //     "lostFcnt": 880,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1466,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0429",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424ac",
    //     "appEUI": "app",
    //     "appKEY": "4C0916E13A84B292C8AA3844901A0FB3",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -116,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4158,
    //     "latestFrameCounterFcnt": 4160,
    //     "lostFcnt": 838,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1467,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0430",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424aa",
    //     "appEUI": "app",
    //     "appKEY": "6EEFC266F95CC72101AF87A961C041D9",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4158,
    //     "latestFrameCounterFcnt": 4159,
    //     "lostFcnt": 971,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1468,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0431",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a58",
    //     "appEUI": "app",
    //     "appKEY": "31FD4190ED40032B830B7AAB2582B3AD",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 2,
    //     "prevFrameCounterFcnt": 4158,
    //     "latestFrameCounterFcnt": 4159,
    //     "lostFcnt": 882,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1469,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0432",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a44",
    //     "appEUI": "app",
    //     "appKEY": "4CBB3818C27882DE04B277BD86B25427",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4158,
    //     "latestFrameCounterFcnt": 4159,
    //     "lostFcnt": 925,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1470,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0433",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424e3",
    //     "appEUI": "app",
    //     "appKEY": "D8A76AA7BCC184066A20FFD2F919AC81",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4158,
    //     "latestFrameCounterFcnt": 4160,
    //     "lostFcnt": 726,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1471,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0434",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424f3",
    //     "appEUI": "app",
    //     "appKEY": "19E8E5A8365C926C8B02936F216F41EB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4158,
    //     "latestFrameCounterFcnt": 4160,
    //     "lostFcnt": 810,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1472,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0435",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424ed",
    //     "appEUI": "app",
    //     "appKEY": "6A86F1EBF380F0146947EB56586057BA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4158,
    //     "latestFrameCounterFcnt": 4159,
    //     "lostFcnt": 922,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1473,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0436",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a0c",
    //     "appEUI": "app",
    //     "appKEY": "81DBC908CCB009A15F2A4090DF04C83E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4158,
    //     "latestFrameCounterFcnt": 4159,
    //     "lostFcnt": 1457,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1474,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0437",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a3e",
    //     "appEUI": "app",
    //     "appKEY": "7D0359FBA03B19A1D817A325876FA742",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4157,
    //     "latestFrameCounterFcnt": 4158,
    //     "lostFcnt": 1625,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1475,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0438",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424f6",
    //     "appEUI": "app",
    //     "appKEY": "B1FE6A28A04AE5423ED6645FEDB46C23",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -114,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4159,
    //     "latestFrameCounterFcnt": 4162,
    //     "lostFcnt": 769,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1476,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0439",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424a1",
    //     "appEUI": "app",
    //     "appKEY": "C8B82B9B8996817C22C6907805F5FFED",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4167,
    //     "latestFrameCounterFcnt": 4168,
    //     "lostFcnt": 855,
    //     "loraSNR": -6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1477,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0440",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424a3",
    //     "appEUI": "app",
    //     "appKEY": "81BDF00C18C7722493CE12B90AFA3CDA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4159,
    //     "latestFrameCounterFcnt": 4160,
    //     "lostFcnt": 855,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1478,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0441",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424e6",
    //     "appEUI": "app",
    //     "appKEY": "76E4D0E4E28C3E683628F3B7365C2BED",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4165,
    //     "latestFrameCounterFcnt": 4166,
    //     "lostFcnt": 1746,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1479,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0442",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424e5",
    //     "appEUI": "app",
    //     "appKEY": "1AE11563D05EA00B7DD9983DBBC8DCDC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4159,
    //     "latestFrameCounterFcnt": 4160,
    //     "lostFcnt": 1087,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1480,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0443",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424a0",
    //     "appEUI": "app",
    //     "appKEY": "E260AF3493600B04117A79DA93B7BBA2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4159,
    //     "latestFrameCounterFcnt": 4160,
    //     "lostFcnt": 1548,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1481,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0444",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424f4",
    //     "appEUI": "app",
    //     "appKEY": "EAF9E84D13710DE39DE6C05A0EDBC213",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4167,
    //     "latestFrameCounterFcnt": 4168,
    //     "lostFcnt": 793,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1482,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0445",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424ef",
    //     "appEUI": "app",
    //     "appKEY": "2463946D56CF241ACC6EFEABD8B833B5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4158,
    //     "latestFrameCounterFcnt": 4159,
    //     "lostFcnt": 1006,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1483,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0446",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042498",
    //     "appEUI": "app",
    //     "appKEY": "DAAFE2CE9C43320074502DE45BD901AA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4160,
    //     "latestFrameCounterFcnt": 4162,
    //     "lostFcnt": 762,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1484,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0447",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004249a",
    //     "appEUI": "app",
    //     "appKEY": "9E12850699836819BE51B02666650713",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4167,
    //     "latestFrameCounterFcnt": 4168,
    //     "lostFcnt": 972,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1485,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0448",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424df",
    //     "appEUI": "app",
    //     "appKEY": "D8F2B04D3BF26F7A661D6B1FAFCA5603",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4160,
    //     "latestFrameCounterFcnt": 4162,
    //     "lostFcnt": 939,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1486,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0449",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004249e",
    //     "appEUI": "app",
    //     "appKEY": "29D58F4FE50B17E2D03D8A1CEE112992",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 4161,
    //     "latestFrameCounterFcnt": 4163,
    //     "lostFcnt": 1798,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1487,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0450",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424ea",
    //     "appEUI": "app",
    //     "appKEY": "BDDEC95D91A9A5856C6DA0D00DBFD78C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4159,
    //     "latestFrameCounterFcnt": 4160,
    //     "lostFcnt": 975,
    //     "loraSNR": -20,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1488,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0451",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424a6",
    //     "appEUI": "app",
    //     "appKEY": "65BCE2D65FCB6D4F0D92CEECC0D70086",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 1656,
    //     "latestFrameCounterFcnt": 1659,
    //     "lostFcnt": 677,
    //     "loraSNR": -19,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1489,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0452",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042499",
    //     "appEUI": "app",
    //     "appKEY": "CE874AB5EEA32839B76B455448053EBD",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4160,
    //     "latestFrameCounterFcnt": 4162,
    //     "lostFcnt": 864,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1490,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0453",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424e9",
    //     "appEUI": "app",
    //     "appKEY": "DB6AE52B168264C3C3236E5A46E0D1FE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4167,
    //     "latestFrameCounterFcnt": 4168,
    //     "lostFcnt": 1554,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1491,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0454",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424de",
    //     "appEUI": "app",
    //     "appKEY": "02ADF8F94ED91CE1E70A3F8D4615E0EC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4153,
    //     "latestFrameCounterFcnt": 4154,
    //     "lostFcnt": 962,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1492,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0455",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004249d",
    //     "appEUI": "app",
    //     "appKEY": "4FFA3F0C2DC6A3BC6E9607D3BA759CDD",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4158,
    //     "latestFrameCounterFcnt": 4160,
    //     "lostFcnt": 866,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1493,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0456",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004249c",
    //     "appEUI": "app",
    //     "appKEY": "5F6654812D174144BFA8F7D16FECE3C8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4160,
    //     "latestFrameCounterFcnt": 4162,
    //     "lostFcnt": 759,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1494,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0457",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424ab",
    //     "appEUI": "app",
    //     "appKEY": "8F54E820353A2C06F8D1CE562FC103FB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4166,
    //     "latestFrameCounterFcnt": 4167,
    //     "lostFcnt": 863,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1495,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0458",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424d9",
    //     "appEUI": "app",
    //     "appKEY": "99D764E920AFD358C35BCB42C3CB788D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4167,
    //     "latestFrameCounterFcnt": 4168,
    //     "lostFcnt": 793,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1496,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0459",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424d2",
    //     "appEUI": "app",
    //     "appKEY": "8367C10D3E55F1146A492340B7A84026",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4166,
    //     "latestFrameCounterFcnt": 4167,
    //     "lostFcnt": 1273,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1497,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0460",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424e2",
    //     "appEUI": "app",
    //     "appKEY": "17F2D819A39F2465ECE1B316DA92F7F8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4168,
    //     "latestFrameCounterFcnt": 4170,
    //     "lostFcnt": 1324,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1498,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0461",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a40",
    //     "appEUI": "app",
    //     "appKEY": "89C35ACD45EF12D602E34676F93403F6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 9,
    //     "prevFrameCounterFcnt": 4168,
    //     "latestFrameCounterFcnt": 4170,
    //     "lostFcnt": 1247,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1499,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0462",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424a7",
    //     "appEUI": "app",
    //     "appKEY": "14833A1290E3A912D5C3069091697265",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 4167,
    //     "latestFrameCounterFcnt": 4168,
    //     "lostFcnt": 886,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1500,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0463",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424da",
    //     "appEUI": "app",
    //     "appKEY": "8A31A57A12493F2496AD401FB3866470",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 2143,
    //     "latestFrameCounterFcnt": 2144,
    //     "lostFcnt": 887,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1501,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0464",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424d5",
    //     "appEUI": "app",
    //     "appKEY": "742E33C6A0953CA7F161BB1EBBD72BDB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4166,
    //     "latestFrameCounterFcnt": 4167,
    //     "lostFcnt": 831,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1502,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0465",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424d7",
    //     "appEUI": "app",
    //     "appKEY": "1AE089A69068260BB30EC6DA5FD65D59",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 4167,
    //     "latestFrameCounterFcnt": 4168,
    //     "lostFcnt": 839,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1503,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0466",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a12",
    //     "appEUI": "app",
    //     "appKEY": "2440F071D71935D287279BEEBC320EC0",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 4168,
    //     "latestFrameCounterFcnt": 4170,
    //     "lostFcnt": 1772,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1504,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0467",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a4c",
    //     "appEUI": "app",
    //     "appKEY": "3B56847C04B74EEE5C50E165698F3627",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4167,
    //     "latestFrameCounterFcnt": 4168,
    //     "lostFcnt": 921,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1505,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0468",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004298e",
    //     "appEUI": "app",
    //     "appKEY": "1DF57AC6060819F611D3DF76AA39DB25",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 12,
    //     "prevFrameCounterFcnt": 4162,
    //     "latestFrameCounterFcnt": 4163,
    //     "lostFcnt": 1128,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1506,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0469",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a50",
    //     "appEUI": "app",
    //     "appKEY": "26C8F848696A30A84A3BF6BB08ADF050",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 4160,
    //     "latestFrameCounterFcnt": 4168,
    //     "lostFcnt": 1906,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1507,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0470",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424d8",
    //     "appEUI": "app",
    //     "appKEY": "80E7644E26FC0C45F5B8C7C8E216E787",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4162,
    //     "latestFrameCounterFcnt": 4163,
    //     "lostFcnt": 1165,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1508,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0471",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424a5",
    //     "appEUI": "app",
    //     "appKEY": "D586E2E6A83FE47727528720A6B06CFC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 3673,
    //     "latestFrameCounterFcnt": 3674,
    //     "lostFcnt": 642,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1509,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0472",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004249b",
    //     "appEUI": "app",
    //     "appKEY": "735DE4FB50FDB38388BFA5CCE838165F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4167,
    //     "latestFrameCounterFcnt": 4168,
    //     "lostFcnt": 758,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1510,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0473",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a64",
    //     "appEUI": "app",
    //     "appKEY": "6D701E5EE8C930391D554E3100172490",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 4165,
    //     "latestFrameCounterFcnt": 4167,
    //     "lostFcnt": 942,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1511,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0474",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042993",
    //     "appEUI": "app",
    //     "appKEY": "494D1FBAB3F659685E55701E9E8484BF",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4167,
    //     "latestFrameCounterFcnt": 4168,
    //     "lostFcnt": 1050,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1512,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0475",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424cf",
    //     "appEUI": "app",
    //     "appKEY": "7009B33E98A290A90211AFBF59D892FA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 24,
    //     "prevFrameCounterFcnt": 4167,
    //     "latestFrameCounterFcnt": 4168,
    //     "lostFcnt": 1301,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1513,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0476",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424a4",
    //     "appEUI": "app",
    //     "appKEY": "A29F36795BCB7EB3A415072ADB3E1983",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4160,
    //     "latestFrameCounterFcnt": 4163,
    //     "lostFcnt": 1246,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1514,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0477",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424a2",
    //     "appEUI": "app",
    //     "appKEY": "50E16456DDE63465331DC493AEA7C4F6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 4167,
    //     "latestFrameCounterFcnt": 4168,
    //     "lostFcnt": 803,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1515,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0478",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042996",
    //     "appEUI": "app",
    //     "appKEY": "8D487B788950B2D907A9D0AD03B50FAD",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 2,
    //     "prevFrameCounterFcnt": 4092,
    //     "latestFrameCounterFcnt": 4093,
    //     "lostFcnt": 858,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1516,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0479",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004298d",
    //     "appEUI": "app",
    //     "appKEY": "A1E042519F798D2174830128B27F9134",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4090,
    //     "latestFrameCounterFcnt": 4092,
    //     "lostFcnt": 1378,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1517,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0480",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424db",
    //     "appEUI": "app",
    //     "appKEY": "A9D96B68E338E6A515CB967E9D3BB24D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4092,
    //     "latestFrameCounterFcnt": 4094,
    //     "lostFcnt": 783,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1518,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0481",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042992",
    //     "appEUI": "app",
    //     "appKEY": "5E7CE38E4C0C6BF0571D7D3DAE2A2994",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 4067,
    //     "latestFrameCounterFcnt": 4068,
    //     "lostFcnt": 1231,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1519,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0482",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424d3",
    //     "appEUI": "app",
    //     "appKEY": "537447639430240D3544124D5AA756A6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4091,
    //     "latestFrameCounterFcnt": 4092,
    //     "lostFcnt": 760,
    //     "loraSNR": -6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1520,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0483",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424ce",
    //     "appEUI": "app",
    //     "appKEY": "37644D6E5BA7D357C36F2BAD44083787",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -112,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4091,
    //     "latestFrameCounterFcnt": 4092,
    //     "lostFcnt": 633,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1521,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0484",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424dc",
    //     "appEUI": "app",
    //     "appKEY": "46ED3AB52E29246DA005EAC2176ABD79",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 252,
    //     "prevFrameCounterFcnt": 1575,
    //     "latestFrameCounterFcnt": 1576,
    //     "lostFcnt": 294,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1522,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0485",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424f1",
    //     "appEUI": "app",
    //     "appKEY": "67CA52D705BDA14F82AD28D088119B3B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4092,
    //     "latestFrameCounterFcnt": 4094,
    //     "lostFcnt": 825,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1523,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0486",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042997",
    //     "appEUI": "app",
    //     "appKEY": "BA6077A4427AE97DC2AD66F3D734E807",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 3,
    //     "prevFrameCounterFcnt": 4092,
    //     "latestFrameCounterFcnt": 4094,
    //     "lostFcnt": 862,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1524,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0487",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424c7",
    //     "appEUI": "app",
    //     "appKEY": "2E30783F9484C992A89B1F303B3E4C8A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4091,
    //     "latestFrameCounterFcnt": 4092,
    //     "lostFcnt": 699,
    //     "loraSNR": 0,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1525,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0488",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424dd",
    //     "appEUI": "app",
    //     "appKEY": "3CBDDA8125C356D3F485D090B17FD1D4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4092,
    //     "latestFrameCounterFcnt": 4094,
    //     "lostFcnt": 776,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1526,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0489",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042991",
    //     "appEUI": "app",
    //     "appKEY": "61ED6FBB3BB580D747A0273ED8838BC8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -114,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4092,
    //     "latestFrameCounterFcnt": 4093,
    //     "lostFcnt": 751,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1527,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0490",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424d1",
    //     "appEUI": "app",
    //     "appKEY": "407B17424BE922C8245CCCD8439A869C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4092,
    //     "latestFrameCounterFcnt": 4093,
    //     "lostFcnt": 817,
    //     "loraSNR": -6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1528,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0491",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424ec",
    //     "appEUI": "app",
    //     "appKEY": "90ECDCDDE5D2EE8C0A08F9198408773E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -103,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 2603,
    //     "latestFrameCounterFcnt": 2604,
    //     "lostFcnt": 161,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1529,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0492",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424d4",
    //     "appEUI": "app",
    //     "appKEY": "5617EC94437AC11835593C46072CBC61",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4092,
    //     "latestFrameCounterFcnt": 4093,
    //     "lostFcnt": 768,
    //     "loraSNR": -6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1530,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0493",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004299b",
    //     "appEUI": "app",
    //     "appKEY": "0D7B47E3A9EA25B98903BF0D348BDF49",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4092,
    //     "latestFrameCounterFcnt": 4093,
    //     "lostFcnt": 1164,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1531,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0494",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424c6",
    //     "appEUI": "app",
    //     "appKEY": "06C77A5FE41E809C82FDF6641C51F147",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 4,
    //     "prevFrameCounterFcnt": 4092,
    //     "latestFrameCounterFcnt": 4094,
    //     "lostFcnt": 822,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1532,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0495",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004298c",
    //     "appEUI": "app",
    //     "appKEY": "5A7E989BAD3742CE55EE3FC25B2AA829",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4091,
    //     "latestFrameCounterFcnt": 4092,
    //     "lostFcnt": 845,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1533,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0496",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424d0",
    //     "appEUI": "app",
    //     "appKEY": "84F61A272D57BB576A06985FF129717D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 263,
    //     "prevFrameCounterFcnt": 4089,
    //     "latestFrameCounterFcnt": 4091,
    //     "lostFcnt": 1048,
    //     "loraSNR": -19,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1534,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0497",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424f5",
    //     "appEUI": "app",
    //     "appKEY": "C64CFB7287B60FC27482C4959C41DD4C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4091,
    //     "latestFrameCounterFcnt": 4092,
    //     "lostFcnt": 785,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1535,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0498",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004299d",
    //     "appEUI": "app",
    //     "appKEY": "31D2955F21240A7C52A487D0A8076549",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4138,
    //     "latestFrameCounterFcnt": 4139,
    //     "lostFcnt": 1050,
    //     "loraSNR": -19,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1536,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0499",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004299a",
    //     "appEUI": "app",
    //     "appKEY": "0289897020E405D48E3109E443515C32",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4138,
    //     "latestFrameCounterFcnt": 4139,
    //     "lostFcnt": 1025,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1537,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0500",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004299f",
    //     "appEUI": "app",
    //     "appKEY": "0F09EABBE2A4F1008BB4B34DEBDF7F6E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 263,
    //     "prevFrameCounterFcnt": 4091,
    //     "latestFrameCounterFcnt": 4092,
    //     "lostFcnt": 912,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1538,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0501",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a0e",
    //     "appEUI": "app",
    //     "appKEY": "6545255090795DD0E54ED279138CE838",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4111,
    //     "latestFrameCounterFcnt": 4112,
    //     "lostFcnt": 729,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1539,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0502",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424cb",
    //     "appEUI": "app",
    //     "appKEY": "8C05C82E55687315EE1182AD8628D347",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4141,
    //     "lostFcnt": 722,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1540,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0503",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004299c",
    //     "appEUI": "app",
    //     "appKEY": "6C8CF2C80FF5F2164A6C694F2F483D24",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4138,
    //     "latestFrameCounterFcnt": 4139,
    //     "lostFcnt": 1273,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1541,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0504",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042994",
    //     "appEUI": "app",
    //     "appKEY": "FF32BBCD8B19B9159587B600F85B321E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4112,
    //     "latestFrameCounterFcnt": 4114,
    //     "lostFcnt": 867,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1542,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0505",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429a3",
    //     "appEUI": "app",
    //     "appKEY": "1A58D7CD3FD83151FF1286D70A30B795",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 197,
    //     "prevFrameCounterFcnt": 732,
    //     "latestFrameCounterFcnt": 755,
    //     "lostFcnt": 40,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1543,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0506",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424cd",
    //     "appEUI": "app",
    //     "appKEY": "A895186CAC262B3D67C3D112AA45743A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -111,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4111,
    //     "latestFrameCounterFcnt": 4112,
    //     "lostFcnt": 1141,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1544,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0507",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424cc",
    //     "appEUI": "app",
    //     "appKEY": "0B180C9EFF61641DEBA0E83CF70E55E9",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4138,
    //     "latestFrameCounterFcnt": 4139,
    //     "lostFcnt": 1273,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1545,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0508",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a35",
    //     "appEUI": "app",
    //     "appKEY": "19CD1FBE9BED3DDD4F1403729D471AA8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 252,
    //     "prevFrameCounterFcnt": 4081,
    //     "latestFrameCounterFcnt": 4083,
    //     "lostFcnt": 1162,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1546,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0509",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a2f",
    //     "appEUI": "app",
    //     "appKEY": "E5CACEA137315C2455377462666A4702",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4111,
    //     "latestFrameCounterFcnt": 4112,
    //     "lostFcnt": 752,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1547,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0510",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a11",
    //     "appEUI": "app",
    //     "appKEY": "942E230E5D5E7AF7DE9DC5AD1CD80623",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4111,
    //     "latestFrameCounterFcnt": 4112,
    //     "lostFcnt": 839,
    //     "loraSNR": -6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1548,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0511",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424e0",
    //     "appEUI": "app",
    //     "appKEY": "96E8B75FB8B3EF769B880E9785D8692F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4111,
    //     "latestFrameCounterFcnt": 4112,
    //     "lostFcnt": 717,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1549,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0512",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424d6",
    //     "appEUI": "app",
    //     "appKEY": "F4217404A4FCBF749144144C4B6A84F8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4111,
    //     "latestFrameCounterFcnt": 4112,
    //     "lostFcnt": 842,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1550,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0513",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429a5",
    //     "appEUI": "app",
    //     "appKEY": "172FB0D7850DBB4834AE371EB04828A0",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 4072,
    //     "latestFrameCounterFcnt": 4073,
    //     "lostFcnt": 972,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1551,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0514",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042977",
    //     "appEUI": "app",
    //     "appKEY": "99A5320CD4501E420260C5D677498B61",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 34,
    //     "latestFrameCounterFcnt": 38,
    //     "lostFcnt": -1205,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1552,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0515",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042995",
    //     "appEUI": "app",
    //     "appKEY": "8BEA4D638382B31B0FA52EB04DA89506",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4088,
    //     "latestFrameCounterFcnt": 4092,
    //     "lostFcnt": 890,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1553,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0516",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424c9",
    //     "appEUI": "app",
    //     "appKEY": "6626AC6305A5964BA473F297D9F22469",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 262,
    //     "prevFrameCounterFcnt": 4090,
    //     "latestFrameCounterFcnt": 4091,
    //     "lostFcnt": 1184,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1554,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0517",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a97",
    //     "appEUI": "app",
    //     "appKEY": "29B4E6711B9BB19C268B4C64C22D983C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 1393,
    //     "latestFrameCounterFcnt": 1394,
    //     "lostFcnt": -670,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1555,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0518",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a42",
    //     "appEUI": "app",
    //     "appKEY": "DEF8686337A5D4F73F2C0EA61DA5A7EE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -111,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4138,
    //     "latestFrameCounterFcnt": 4139,
    //     "lostFcnt": 857,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1556,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0519",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a56",
    //     "appEUI": "app",
    //     "appKEY": "1C1B60A252238C4D6E25D9DFA78E9FA2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 877,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1557,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0520",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042999",
    //     "appEUI": "app",
    //     "appKEY": "5AC3BF5F3AD884AFBCBD7479F5087C2D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 1194,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1558,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0521",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004298b",
    //     "appEUI": "app",
    //     "appKEY": "7A2BEFC4CE634830623800ECBD49B775",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 859,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1559,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0522",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042998",
    //     "appEUI": "app",
    //     "appKEY": "352FB421A9A6A18159899B770CC67459",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2,
    //     "prevFrameCounterFcnt": 4111,
    //     "latestFrameCounterFcnt": 4112,
    //     "lostFcnt": 780,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1560,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0523",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a14",
    //     "appEUI": "app",
    //     "appKEY": "E2D1B5A982DCB702FBA452AC3000EB5D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 822,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1561,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0524",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a62",
    //     "appEUI": "app",
    //     "appKEY": "EC9DF1F2EBF77DCA8EA2693F0343C9FB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 4140,
    //     "latestFrameCounterFcnt": 4142,
    //     "lostFcnt": 951,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1562,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0525",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a4e",
    //     "appEUI": "app",
    //     "appKEY": "9092CD2B2CC6A16B17DE25D42AB7F23A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4111,
    //     "latestFrameCounterFcnt": 4112,
    //     "lostFcnt": 949,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1563,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0526",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042986",
    //     "appEUI": "app",
    //     "appKEY": "A4D0B1D00840E59F1C085A92C9BF0C75",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 777,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1564,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0527",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429bb",
    //     "appEUI": "app",
    //     "appKEY": "F4480DCE4B36DACB1AE75CA86F501492",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 842,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1565,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0528",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004298f",
    //     "appEUI": "app",
    //     "appKEY": "143A095796918BCCBF811D2B5B1D0DD5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 1304,
    //     "latestFrameCounterFcnt": 1305,
    //     "lostFcnt": 467,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1566,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0529",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042990",
    //     "appEUI": "app",
    //     "appKEY": "F593488F03805061425B0ED308B09999",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -116,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 32,
    //     "prevFrameCounterFcnt": 4138,
    //     "latestFrameCounterFcnt": 4139,
    //     "lostFcnt": 876,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1567,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0530",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042981",
    //     "appEUI": "app",
    //     "appKEY": "48F6E6643DB6B55A0966C77FCC94411C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -101,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 647,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1568,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0531",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424c8",
    //     "appEUI": "app",
    //     "appKEY": "5A729D1A6553D20CB531417BCE454616",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -111,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4115,
    //     "latestFrameCounterFcnt": 4116,
    //     "lostFcnt": 653,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1569,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0532",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424ca",
    //     "appEUI": "app",
    //     "appKEY": "FA3622AADE58F4E5DBC056A1E24417AE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4149,
    //     "latestFrameCounterFcnt": 4151,
    //     "lostFcnt": 895,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1570,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0533",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429a0",
    //     "appEUI": "app",
    //     "appKEY": "55A0471AAEB4898C80E0C794AAF709D8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 252,
    //     "prevFrameCounterFcnt": 4081,
    //     "latestFrameCounterFcnt": 4083,
    //     "lostFcnt": 742,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1571,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0534",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004299e",
    //     "appEUI": "app",
    //     "appKEY": "26C383F87D31AFC9C4878200376CAC3A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -107,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 4072,
    //     "latestFrameCounterFcnt": 4073,
    //     "lostFcnt": 740,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1572,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0535",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429a4",
    //     "appEUI": "app",
    //     "appKEY": "85C9F52AD7CB83602BEF3959E5DC8C4F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -104,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4100,
    //     "latestFrameCounterFcnt": 4101,
    //     "lostFcnt": 650,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1573,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0536",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424e7",
    //     "appEUI": "app",
    //     "appKEY": "4D1F313692F8756199829C9BCAB5D882",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4149,
    //     "latestFrameCounterFcnt": 4150,
    //     "lostFcnt": 1793,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1574,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0537",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424e1",
    //     "appEUI": "app",
    //     "appKEY": "B70CC2D8F71AEAFA1DB6C4D52738DE4E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4149,
    //     "latestFrameCounterFcnt": 4151,
    //     "lostFcnt": 967,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1575,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0538",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a99",
    //     "appEUI": "app",
    //     "appKEY": "405C8F04752B4F81EE91AAD842EA8254",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 687,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1576,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0539",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a52",
    //     "appEUI": "app",
    //     "appKEY": "3D266C230251DF2F55C54039AEC1D87D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4149,
    //     "latestFrameCounterFcnt": 4150,
    //     "lostFcnt": 895,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1577,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0540",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a55",
    //     "appEUI": "app",
    //     "appKEY": "B3B95E56A755B389B026784C1DEAA01B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -111,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4148,
    //     "latestFrameCounterFcnt": 4149,
    //     "lostFcnt": 785,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1578,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0541",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a6b",
    //     "appEUI": "app",
    //     "appKEY": "AE297C1808BBBCFDC3158429EF9E4BB1",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 1403,
    //     "latestFrameCounterFcnt": 1419,
    //     "lostFcnt": -271,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1579,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0542",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a3d",
    //     "appEUI": "app",
    //     "appKEY": "45EAD8E096763805FEC1875E406E5CAC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4138,
    //     "latestFrameCounterFcnt": 4139,
    //     "lostFcnt": 820,
    //     "loraSNR": -6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1580,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0543",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a5d",
    //     "appEUI": "app",
    //     "appKEY": "A5F5038525D3C5B90F601F64377454A6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4149,
    //     "latestFrameCounterFcnt": 4151,
    //     "lostFcnt": 930,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1581,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0544",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a53",
    //     "appEUI": "app",
    //     "appKEY": "624E7137F2BABC637006BF8D8BF3E081",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4138,
    //     "latestFrameCounterFcnt": 4139,
    //     "lostFcnt": 997,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1582,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0545",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a69",
    //     "appEUI": "app",
    //     "appKEY": "9EA94A2CF89A3AB26717263140356F4D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4149,
    //     "latestFrameCounterFcnt": 4150,
    //     "lostFcnt": 891,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1583,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0546",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a57",
    //     "appEUI": "app",
    //     "appKEY": "2371A176DF3EA98CCC9A427387C95366",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 262,
    //     "prevFrameCounterFcnt": 4089,
    //     "latestFrameCounterFcnt": 4090,
    //     "lostFcnt": 975,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1584,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0547",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a49",
    //     "appEUI": "app",
    //     "appKEY": "72974FF7FCD682E528D36E85315CA471",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4149,
    //     "latestFrameCounterFcnt": 4150,
    //     "lostFcnt": 818,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1585,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0548",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a59",
    //     "appEUI": "app",
    //     "appKEY": "F33BD9C3CC6994736874BC848FFC7B66",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 874,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1586,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0549",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a4f",
    //     "appEUI": "app",
    //     "appKEY": "8D0A7B3349F98F4DEBAE6D7DACD9E7FD",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4111,
    //     "latestFrameCounterFcnt": 4112,
    //     "lostFcnt": 697,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1587,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0550",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a3f",
    //     "appEUI": "app",
    //     "appKEY": "F5E508E01CD95782AEF389F6D9DB48A8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 1233,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1588,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0551",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a67",
    //     "appEUI": "app",
    //     "appKEY": "BE66B356722EF8EB15467695B8A3303F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4138,
    //     "latestFrameCounterFcnt": 4139,
    //     "lostFcnt": 683,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1589,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0552",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a63",
    //     "appEUI": "app",
    //     "appKEY": "8D081C2792C70216657E397CF6AE8FE5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 774,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1590,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0553",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a47",
    //     "appEUI": "app",
    //     "appKEY": "3071AE05DEAD0F6D3F417C91CE1FD2FB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 2,
    //     "prevFrameCounterFcnt": 4106,
    //     "latestFrameCounterFcnt": 4107,
    //     "lostFcnt": 2221,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1591,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0554",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a45",
    //     "appEUI": "app",
    //     "appKEY": "437D6D64F019F2E88D6B70A0641135AA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4149,
    //     "latestFrameCounterFcnt": 4151,
    //     "lostFcnt": 1012,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1592,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0555",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a4d",
    //     "appEUI": "app",
    //     "appKEY": "DBA87042ACE957135C1CC35E596509D2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4149,
    //     "latestFrameCounterFcnt": 4151,
    //     "lostFcnt": 1073,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1593,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0556",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a51",
    //     "appEUI": "app",
    //     "appKEY": "90D3B447302EC4891EA4283E308C4430",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 856,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1594,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0557",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a0a",
    //     "appEUI": "app",
    //     "appKEY": "035C75BBBBDC6815899B3BB2D3F87735",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 187,
    //     "prevFrameCounterFcnt": 4015,
    //     "latestFrameCounterFcnt": 4016,
    //     "lostFcnt": 1540,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1595,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0558",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a0d",
    //     "appEUI": "app",
    //     "appKEY": "2D08B2EAE566315943EFB929CFF04538",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 187,
    //     "prevFrameCounterFcnt": 4016,
    //     "latestFrameCounterFcnt": 4018,
    //     "lostFcnt": 1420,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1596,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0559",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a22",
    //     "appEUI": "app",
    //     "appKEY": "FFF483951C692CC8BEF44B979CFB5BB1",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 2711,
    //     "latestFrameCounterFcnt": 2713,
    //     "lostFcnt": -30,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1597,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0560",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a48",
    //     "appEUI": "app",
    //     "appKEY": "525F0EA8056C2D3714FB838AC57207FB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 2,
    //     "prevFrameCounterFcnt": 488,
    //     "latestFrameCounterFcnt": 651,
    //     "lostFcnt": 304,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1598,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0561",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a5e",
    //     "appEUI": "app",
    //     "appKEY": "F37E0649E52129F1B04EB07953474909",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 173,
    //     "prevFrameCounterFcnt": 4001,
    //     "latestFrameCounterFcnt": 4002,
    //     "lostFcnt": 1251,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1599,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0562",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a0b",
    //     "appEUI": "app",
    //     "appKEY": "48E0189C6EC64326B4650499D871FE1C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 214,
    //     "prevFrameCounterFcnt": 1283,
    //     "latestFrameCounterFcnt": 1284,
    //     "lostFcnt": -315,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1600,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0563",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a38",
    //     "appEUI": "app",
    //     "appKEY": "97F84F67AA42A5B704FF86ECD3D28D04",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4042,
    //     "latestFrameCounterFcnt": 4044,
    //     "lostFcnt": 1391,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1601,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0564",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a29",
    //     "appEUI": "app",
    //     "appKEY": "F164BCC3816C75059693BEDF451E6895",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4043,
    //     "latestFrameCounterFcnt": 4044,
    //     "lostFcnt": 821,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1602,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0565",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a6c",
    //     "appEUI": "app",
    //     "appKEY": "7EA5797126CB9F7854A6A877C5FEF162",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 182,
    //     "prevFrameCounterFcnt": 2048,
    //     "latestFrameCounterFcnt": 2055,
    //     "lostFcnt": 74,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1603,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0566",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a41",
    //     "appEUI": "app",
    //     "appKEY": "9D416D8F3F269D797455FA48943C4C23",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 1963,
    //     "latestFrameCounterFcnt": 1965,
    //     "lostFcnt": -265,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1604,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0567",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a13",
    //     "appEUI": "app",
    //     "appKEY": "3988296CD25E91CA5D1C3260C4F7CC5F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 187,
    //     "prevFrameCounterFcnt": 4015,
    //     "latestFrameCounterFcnt": 4016,
    //     "lostFcnt": 702,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1605,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0568",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a10",
    //     "appEUI": "app",
    //     "appKEY": "91EC493C4AF031D1C2DF4EDE9718956C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 202,
    //     "prevFrameCounterFcnt": 4029,
    //     "latestFrameCounterFcnt": 4031,
    //     "lostFcnt": 1812,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1606,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0569",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a30",
    //     "appEUI": "app",
    //     "appKEY": "BC8A1FDDB477E7E6C9FA970D40E67079",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 208,
    //     "prevFrameCounterFcnt": 4024,
    //     "latestFrameCounterFcnt": 4026,
    //     "lostFcnt": 2073,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1607,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0570",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a4a",
    //     "appEUI": "app",
    //     "appKEY": "D267763DCCA1093EDC11BD35DA8B72F6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4042,
    //     "latestFrameCounterFcnt": 4043,
    //     "lostFcnt": 1144,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1608,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0571",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a4b",
    //     "appEUI": "app",
    //     "appKEY": "0550318271F36C55F9D0594D97D3002C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 214,
    //     "prevFrameCounterFcnt": 4042,
    //     "latestFrameCounterFcnt": 4043,
    //     "lostFcnt": 1090,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1609,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0572",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a2d",
    //     "appEUI": "app",
    //     "appKEY": "323BF5377CE235D82349D663468FA43E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 214,
    //     "prevFrameCounterFcnt": 4042,
    //     "latestFrameCounterFcnt": 4043,
    //     "lostFcnt": 2354,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1610,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0573",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a24",
    //     "appEUI": "app",
    //     "appKEY": "16812C85ABDF8F1B675863B8355894FB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 182,
    //     "prevFrameCounterFcnt": 1544,
    //     "latestFrameCounterFcnt": 1545,
    //     "lostFcnt": 82,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1611,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0574",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a23",
    //     "appEUI": "app",
    //     "appKEY": "80A19C34702BB6FE92AA6EF69E2C1CD6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 369,
    //     "latestFrameCounterFcnt": 370,
    //     "lostFcnt": 313,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1612,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0575",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a46",
    //     "appEUI": "app",
    //     "appKEY": "45A8319E7E66C5D0A504C21EDED2F2FE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 1613,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0576",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a43",
    //     "appEUI": "app",
    //     "appKEY": "14173A61A008C0B107422AAB04C69D6F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 187,
    //     "prevFrameCounterFcnt": 4015,
    //     "latestFrameCounterFcnt": 4016,
    //     "lostFcnt": 846,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1614,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0577",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a27",
    //     "appEUI": "app",
    //     "appKEY": "B300EA29294DC997ABB23BBB8A1332F7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 337,
    //     "prevFrameCounterFcnt": 431,
    //     "latestFrameCounterFcnt": 441,
    //     "lostFcnt": -524,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1615,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0578",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a32",
    //     "appEUI": "app",
    //     "appKEY": "D33CB32DE357054826EC391CBD2C4E7F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 84,
    //     "latestFrameCounterFcnt": 86,
    //     "lostFcnt": -1129,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1616,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0579",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a28",
    //     "appEUI": "app",
    //     "appKEY": "59239618588C0B31852954251E01AA14",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4043,
    //     "latestFrameCounterFcnt": 4044,
    //     "lostFcnt": 1669,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1617,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0580",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a3a",
    //     "appEUI": "app",
    //     "appKEY": "447CFD1E5049ABB06271D14523AC1E9D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4043,
    //     "latestFrameCounterFcnt": 4044,
    //     "lostFcnt": 733,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1618,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0581",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a33",
    //     "appEUI": "app",
    //     "appKEY": "EF27A28611A60FE64581847964F96966",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 25,
    //     "latestFrameCounterFcnt": 27,
    //     "lostFcnt": -1087,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1619,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0582",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a2c",
    //     "appEUI": "app",
    //     "appKEY": "26EE8192F63678E94E5C66DE0F39BDBA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4044,
    //     "latestFrameCounterFcnt": 4046,
    //     "lostFcnt": 1399,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1620,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0583",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a3c",
    //     "appEUI": "app",
    //     "appKEY": "2B1EA0DF00723606FE84F3C5D1C71DDD",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4044,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 1163,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1621,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0584",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a0f",
    //     "appEUI": "app",
    //     "appKEY": "E8322858E1E80E383B72481FD16614FF",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4044,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 804,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1622,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0585",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a39",
    //     "appEUI": "app",
    //     "appKEY": "C33F3D7811554E041E76E71880F372D0",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 217,
    //     "prevFrameCounterFcnt": 3754,
    //     "latestFrameCounterFcnt": 3755,
    //     "lostFcnt": 1016,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1623,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0586",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a2b",
    //     "appEUI": "app",
    //     "appKEY": "B5029C75C891347B18D57397D4E87832",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4041,
    //     "latestFrameCounterFcnt": 4044,
    //     "lostFcnt": 1498,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1624,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0587",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a25",
    //     "appEUI": "app",
    //     "appKEY": "2BC4F6940F5B60369CF5D53606A72FEC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4044,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 1457,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1625,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0588",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a2e",
    //     "appEUI": "app",
    //     "appKEY": "3F9B13CC60C322014BB6843C3A449458",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4044,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 879,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1626,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0589",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a16",
    //     "appEUI": "app",
    //     "appKEY": "FCC4161AC43AA2CBE11CD5BAD2BBB887",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 1806,
    //     "latestFrameCounterFcnt": 1809,
    //     "lostFcnt": 158,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1627,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0590",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a34",
    //     "appEUI": "app",
    //     "appKEY": "34A8DA3F5AEF563D63A8B77A2D8BDC9D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 3617,
    //     "latestFrameCounterFcnt": 3618,
    //     "lostFcnt": 1084,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1628,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0591",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a26",
    //     "appEUI": "app",
    //     "appKEY": "7F99DD92F50D6D64D6550379EDE04FA0",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 3117,
    //     "latestFrameCounterFcnt": 3118,
    //     "lostFcnt": 686,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1629,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0592",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a15",
    //     "appEUI": "app",
    //     "appKEY": "068FDF714AD422C3CCDB0270AA9EC08F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 214,
    //     "prevFrameCounterFcnt": 4042,
    //     "latestFrameCounterFcnt": 4043,
    //     "lostFcnt": 1146,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1630,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0593",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a2a",
    //     "appEUI": "app",
    //     "appKEY": "75B478BCD206A0BFE363D5D203CED14F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4043,
    //     "latestFrameCounterFcnt": 4044,
    //     "lostFcnt": 1062,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1631,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0594",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a31",
    //     "appEUI": "app",
    //     "appKEY": "7CB955E9E0EFE3655CB90E6C7139F545",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 202,
    //     "prevFrameCounterFcnt": 2720,
    //     "latestFrameCounterFcnt": 2721,
    //     "lostFcnt": -115,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1632,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0595",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a54",
    //     "appEUI": "app",
    //     "appKEY": "32CC62A5E9D9873ADFACA696BBACB8EF",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 2142,
    //     "latestFrameCounterFcnt": 2144,
    //     "lostFcnt": -228,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1633,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0596",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a3b",
    //     "appEUI": "app",
    //     "appKEY": "ED146ABCF5DB448ACCF519ED2E34583A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 164,
    //     "prevFrameCounterFcnt": 90,
    //     "latestFrameCounterFcnt": 95,
    //     "lostFcnt": -648,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1634,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0597",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a1b",
    //     "appEUI": "app",
    //     "appKEY": "57BC87662F8C49E8A67F8B8BFCA7838F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 1635,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0598",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a18",
    //     "appEUI": "app",
    //     "appKEY": "74A6324D4AAF9461EEE96E78DF098BB4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 217,
    //     "prevFrameCounterFcnt": 4045,
    //     "latestFrameCounterFcnt": 4047,
    //     "lostFcnt": 949,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1636,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0599",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a17",
    //     "appEUI": "app",
    //     "appKEY": "101CCDE2EEFEA2EB284AFB775DF61040",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 168,
    //     "prevFrameCounterFcnt": 5,
    //     "latestFrameCounterFcnt": 6,
    //     "lostFcnt": -383,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1637,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0600",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fe2",
    //     "appEUI": "app",
    //     "appKEY": "B4E8705ABE4561D25C99832615F5D817",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4044,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 1897,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1638,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0601",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fdc",
    //     "appEUI": "app",
    //     "appKEY": "433CB75EF604DDAE6EC4C51FD0BF888E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 1639,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0602",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429f9",
    //     "appEUI": "app",
    //     "appKEY": "BAAF5910997592484AA5473067E7F68E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4044,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 908,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1640,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0603",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a09",
    //     "appEUI": "app",
    //     "appKEY": "848BAA9BDDCB474A9CDA2808A84D790A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4045,
    //     "latestFrameCounterFcnt": 4047,
    //     "lostFcnt": 1358,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1641,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0604",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a1f",
    //     "appEUI": "app",
    //     "appKEY": "2B12D66B2832995692DA729EBC1D6CD8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4044,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 791,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1642,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0605",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429e4",
    //     "appEUI": "app",
    //     "appKEY": "6990FB63A8447E02FC2F591669C9E4CC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 263,
    //     "prevFrameCounterFcnt": 1862,
    //     "latestFrameCounterFcnt": 1864,
    //     "lostFcnt": 459,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1643,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0606",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429f6",
    //     "appEUI": "app",
    //     "appKEY": "59BD82D6EEED79B92D119034502075CB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4044,
    //     "latestFrameCounterFcnt": 4046,
    //     "lostFcnt": 1162,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1644,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0607",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a07",
    //     "appEUI": "app",
    //     "appKEY": "D35075CA061AC73F419AA17C2881682D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4044,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 1334,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1645,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0608",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429ed",
    //     "appEUI": "app",
    //     "appKEY": "D6C80D3B2E65D19223848D4F17CC2DC2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 759,
    //     "latestFrameCounterFcnt": 761,
    //     "lostFcnt": 504,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1646,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0609",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429da",
    //     "appEUI": "app",
    //     "appKEY": "7C58332D66AD9FF5327E1EE13D0EC22F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4043,
    //     "latestFrameCounterFcnt": 4044,
    //     "lostFcnt": 1117,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1647,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0610",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429aa",
    //     "appEUI": "app",
    //     "appKEY": "2C70F3048945D6AFCDA38F2005A576FB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 202,
    //     "prevFrameCounterFcnt": 4030,
    //     "latestFrameCounterFcnt": 4031,
    //     "lostFcnt": 1870,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1648,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0611",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a00",
    //     "appEUI": "app",
    //     "appKEY": "24CA25BD2DC82CDA7834E69593056444",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4044,
    //     "latestFrameCounterFcnt": 4046,
    //     "lostFcnt": 945,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1649,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0612",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429fe",
    //     "appEUI": "app",
    //     "appKEY": "7169EB9A4A29DB0D64B3E3D783D673ED",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4043,
    //     "latestFrameCounterFcnt": 4044,
    //     "lostFcnt": 874,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1650,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0613",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429e7",
    //     "appEUI": "app",
    //     "appKEY": "E18F7DEE6C6C4BCF9A18BACE361153B5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 215,
    //     "prevFrameCounterFcnt": 4016,
    //     "latestFrameCounterFcnt": 4029,
    //     "lostFcnt": 1225,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1651,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0614",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429fc",
    //     "appEUI": "app",
    //     "appKEY": "9EDE5FBED556A11D42634F25F5C941A7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 226,
    //     "prevFrameCounterFcnt": 2589,
    //     "latestFrameCounterFcnt": 2590,
    //     "lostFcnt": 550,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1652,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0615",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429af",
    //     "appEUI": "app",
    //     "appKEY": "13C95B6E68C97D35C7BDC283427C4F44",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 1519,
    //     "latestFrameCounterFcnt": 1521,
    //     "lostFcnt": -223,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1653,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0616",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429fa",
    //     "appEUI": "app",
    //     "appKEY": "49CBF4A65AB4A4639B594073D19D0ED3",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4044,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 1379,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1654,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0617",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a37",
    //     "appEUI": "app",
    //     "appKEY": "5F82781F6C1CFD77511CE77FC5F437CE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 217,
    //     "prevFrameCounterFcnt": 4043,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 948,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1655,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0618",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a02",
    //     "appEUI": "app",
    //     "appKEY": "7D0747DA69F3D3E632B3EBF2073CD891",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 10,
    //     "prevFrameCounterFcnt": 106,
    //     "latestFrameCounterFcnt": 112,
    //     "lostFcnt": -11,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1656,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0619",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429fd",
    //     "appEUI": "app",
    //     "appKEY": "287E54114E6635CCB9E4DA09365383E0",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 253,
    //     "prevFrameCounterFcnt": 4081,
    //     "latestFrameCounterFcnt": 4083,
    //     "lostFcnt": 822,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1657,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0620",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429ee",
    //     "appEUI": "app",
    //     "appKEY": "94558EB94422A2B032A52FE1E11EAE7D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 245,
    //     "prevFrameCounterFcnt": 4067,
    //     "latestFrameCounterFcnt": 4070,
    //     "lostFcnt": 1986,
    //     "loraSNR": -19,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1658,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0621",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429f1",
    //     "appEUI": "app",
    //     "appKEY": "5D98896D63236983078CCF7AD38399D7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 1659,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0622",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a05",
    //     "appEUI": "app",
    //     "appKEY": "D8E3EE537A95404A201031EF57720532",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 244,
    //     "prevFrameCounterFcnt": 4072,
    //     "latestFrameCounterFcnt": 4073,
    //     "lostFcnt": 1394,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1660,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0623",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a1e",
    //     "appEUI": "app",
    //     "appKEY": "95A6C0824D1D1C5BDA78A2FD8DE73BA7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 263,
    //     "prevFrameCounterFcnt": 4091,
    //     "latestFrameCounterFcnt": 4092,
    //     "lostFcnt": 832,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1661,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0624",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a20",
    //     "appEUI": "app",
    //     "appKEY": "AC02032BCFBC862987DB7DEB7325D2B7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 263,
    //     "prevFrameCounterFcnt": 4091,
    //     "latestFrameCounterFcnt": 4092,
    //     "lostFcnt": 1117,
    //     "loraSNR": -19,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1662,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0625",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429fb",
    //     "appEUI": "app",
    //     "appKEY": "BF328128F39CE1715A0D62F58B9B1E64",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 4044,
    //     "latestFrameCounterFcnt": 4045,
    //     "lostFcnt": 790,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1663,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0626",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429e5",
    //     "appEUI": "app",
    //     "appKEY": "DB484E07DD984C25083E32199F17AC1D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 252,
    //     "prevFrameCounterFcnt": 4080,
    //     "latestFrameCounterFcnt": 4081,
    //     "lostFcnt": 775,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1664,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0627",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a21",
    //     "appEUI": "app",
    //     "appKEY": "3ECA096D26F0AF5D791D9F52A6A79B41",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 5,
    //     "prevFrameCounterFcnt": 1244,
    //     "latestFrameCounterFcnt": 1245,
    //     "lostFcnt": 421,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1665,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0628",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a01",
    //     "appEUI": "app",
    //     "appKEY": "F28E89CE1DB83272AA30F9D3DA864978",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 256,
    //     "prevFrameCounterFcnt": 4083,
    //     "latestFrameCounterFcnt": 4084,
    //     "lostFcnt": 1652,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1666,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0629",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a1d",
    //     "appEUI": "app",
    //     "appKEY": "6B05AC668EEFF93DC4E792CE7ACD507C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 137,
    //     "latestFrameCounterFcnt": 147,
    //     "lostFcnt": -707,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1667,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0630",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429ea",
    //     "appEUI": "app",
    //     "appKEY": "74812C7DEC017DF8B60713A708395E20",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 91,
    //     "prevFrameCounterFcnt": 1012,
    //     "latestFrameCounterFcnt": 1013,
    //     "lostFcnt": 335,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1668,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0631",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429f3",
    //     "appEUI": "app",
    //     "appKEY": "472C589945F59813C330000F9FA12719",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 262,
    //     "prevFrameCounterFcnt": 4090,
    //     "latestFrameCounterFcnt": 4091,
    //     "lostFcnt": 931,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1669,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0632",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a1c",
    //     "appEUI": "app",
    //     "appKEY": "B7E41C4C90C8894C29CA894A385FBCD6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 253,
    //     "prevFrameCounterFcnt": 4081,
    //     "latestFrameCounterFcnt": 4082,
    //     "lostFcnt": 914,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1670,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0633",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a08",
    //     "appEUI": "app",
    //     "appKEY": "9ABA94753C52E13C4393B54D6F6B1479",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 216,
    //     "prevFrameCounterFcnt": 2208,
    //     "latestFrameCounterFcnt": 2210,
    //     "lostFcnt": -744,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1671,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0634",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a1a",
    //     "appEUI": "app",
    //     "appKEY": "F3A58D12B2DC4BA5992B6DF11739A8C1",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 1672,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0635",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a03",
    //     "appEUI": "app",
    //     "appKEY": "B93225E55C16CC0865F1F2FCE3AB33E9",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 202,
    //     "prevFrameCounterFcnt": 4030,
    //     "latestFrameCounterFcnt": 4031,
    //     "lostFcnt": 1221,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1673,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0636",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429e8",
    //     "appEUI": "app",
    //     "appKEY": "5D865B11AB8E7E42C3A0E77FB6D1E349",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 252,
    //     "prevFrameCounterFcnt": 4080,
    //     "latestFrameCounterFcnt": 4081,
    //     "lostFcnt": 888,
    //     "loraSNR": -6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1674,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0637",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429ff",
    //     "appEUI": "app",
    //     "appKEY": "2DBB9AC685B948B196B57C1B923B521D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 195,
    //     "prevFrameCounterFcnt": 4023,
    //     "latestFrameCounterFcnt": 4024,
    //     "lostFcnt": 1045,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1675,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0638",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429f8",
    //     "appEUI": "app",
    //     "appKEY": "890AF7280E91CFCB9FD093B85504D0F8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 1676,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0639",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429d8",
    //     "appEUI": "app",
    //     "appKEY": "0D0FCDE8479B92BAAF193B5268B6BB24",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 196,
    //     "prevFrameCounterFcnt": 4024,
    //     "latestFrameCounterFcnt": 4025,
    //     "lostFcnt": 1261,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1677,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0640",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429df",
    //     "appEUI": "app",
    //     "appKEY": "432AEB01DD93D7D708E81382F5FE4852",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 195,
    //     "prevFrameCounterFcnt": 4015,
    //     "latestFrameCounterFcnt": 4017,
    //     "lostFcnt": 1329,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1678,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0641",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429e2",
    //     "appEUI": "app",
    //     "appKEY": "79374F58A02D018B97644E0768974310",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 195,
    //     "prevFrameCounterFcnt": 4023,
    //     "latestFrameCounterFcnt": 4024,
    //     "lostFcnt": 967,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1679,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0642",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429f2",
    //     "appEUI": "app",
    //     "appKEY": "5963B89DB298727D57C00A50C95CCF77",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 195,
    //     "prevFrameCounterFcnt": 1148,
    //     "latestFrameCounterFcnt": 1149,
    //     "lostFcnt": -795,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1680,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0643",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a04",
    //     "appEUI": "app",
    //     "appKEY": "DF44123FC5B9EC99B66F38B689DE6CA4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 196,
    //     "prevFrameCounterFcnt": 4024,
    //     "latestFrameCounterFcnt": 4025,
    //     "lostFcnt": 752,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1681,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0644",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429f5",
    //     "appEUI": "app",
    //     "appKEY": "2D19B457E6A4C42452D68B2E0B84807F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 196,
    //     "prevFrameCounterFcnt": 4024,
    //     "latestFrameCounterFcnt": 4025,
    //     "lostFcnt": 696,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1682,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0645",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429eb",
    //     "appEUI": "app",
    //     "appKEY": "58A4E2A769FCE210E470ACDF449839BF",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 187,
    //     "prevFrameCounterFcnt": 4016,
    //     "latestFrameCounterFcnt": 4018,
    //     "lostFcnt": 998,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1683,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0646",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429e3",
    //     "appEUI": "app",
    //     "appKEY": "8A310ADBE03BFB1EA544E0F800CF1AEE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 196,
    //     "prevFrameCounterFcnt": 4023,
    //     "latestFrameCounterFcnt": 4024,
    //     "lostFcnt": 1405,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1684,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0647",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429f4",
    //     "appEUI": "app",
    //     "appKEY": "22C7AA064ED8979A1A13C11A82DED528",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 195,
    //     "prevFrameCounterFcnt": 2263,
    //     "latestFrameCounterFcnt": 2264,
    //     "lostFcnt": 180,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1685,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0648",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429f7",
    //     "appEUI": "app",
    //     "appKEY": "2ED7CA3F75E9FC2D06DA94B2B69D28F7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 195,
    //     "prevFrameCounterFcnt": 4023,
    //     "latestFrameCounterFcnt": 4024,
    //     "lostFcnt": 1291,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1686,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0649",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429db",
    //     "appEUI": "app",
    //     "appKEY": "E25EDDDB0898992A4B2D366A13BAB98E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 187,
    //     "prevFrameCounterFcnt": 4002,
    //     "latestFrameCounterFcnt": 4010,
    //     "lostFcnt": 1372,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1687,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0650",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429f0",
    //     "appEUI": "app",
    //     "appKEY": "EB207645E81AE441BBA5734C3075F5ED",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 195,
    //     "prevFrameCounterFcnt": 4023,
    //     "latestFrameCounterFcnt": 4024,
    //     "lostFcnt": 2308,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1688,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0651",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429de",
    //     "appEUI": "app",
    //     "appKEY": "D3CA417F194FB839ED7E2085127A50D8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 195,
    //     "prevFrameCounterFcnt": 4024,
    //     "latestFrameCounterFcnt": 4026,
    //     "lostFcnt": 1085,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1689,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0652",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429e6",
    //     "appEUI": "app",
    //     "appKEY": "D8FDE19CE00ADED9CAFDE9A4D9C56E4E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 187,
    //     "prevFrameCounterFcnt": 4008,
    //     "latestFrameCounterFcnt": 4009,
    //     "lostFcnt": 1765,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1690,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0653",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429e9",
    //     "appEUI": "app",
    //     "appKEY": "7AE2B4E9AA37A89DCDE98904863888BC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 195,
    //     "prevFrameCounterFcnt": 4023,
    //     "latestFrameCounterFcnt": 4024,
    //     "lostFcnt": 1429,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1691,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0654",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429d6",
    //     "appEUI": "app",
    //     "appKEY": "F4C3B304908EEE85AE7939763B0332DD",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 187,
    //     "prevFrameCounterFcnt": 4015,
    //     "latestFrameCounterFcnt": 4016,
    //     "lostFcnt": 788,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1692,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0655",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429e0",
    //     "appEUI": "app",
    //     "appKEY": "7482E982BD1AFE54BC686C5A4F6C14FF",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 195,
    //     "prevFrameCounterFcnt": 4023,
    //     "latestFrameCounterFcnt": 4024,
    //     "lostFcnt": 1369,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1693,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0656",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429d7",
    //     "appEUI": "app",
    //     "appKEY": "F2CE7A6EE348749A775DB287C5AEA1F4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 195,
    //     "prevFrameCounterFcnt": 4023,
    //     "latestFrameCounterFcnt": 4024,
    //     "lostFcnt": 1105,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1694,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0657",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429dd",
    //     "appEUI": "app",
    //     "appKEY": "24930AA46887A1C9EC650AC6E82568B3",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 197,
    //     "prevFrameCounterFcnt": 4025,
    //     "latestFrameCounterFcnt": 4026,
    //     "lostFcnt": 2011,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1695,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0658",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429c8",
    //     "appEUI": "app",
    //     "appKEY": "F8A6E9A8816AD3B17B4EBE36771B5D81",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 196,
    //     "prevFrameCounterFcnt": 4024,
    //     "latestFrameCounterFcnt": 4025,
    //     "lostFcnt": 978,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1696,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0659",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429e1",
    //     "appEUI": "app",
    //     "appKEY": "58488CAEEB6896744B0D83596CBB4D84",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 196,
    //     "prevFrameCounterFcnt": 4024,
    //     "latestFrameCounterFcnt": 4025,
    //     "lostFcnt": 1415,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1697,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0660",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429c5",
    //     "appEUI": "app",
    //     "appKEY": "A9E3030B1E16642BE4F568E63B9A4FB4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 197,
    //     "prevFrameCounterFcnt": 1482,
    //     "latestFrameCounterFcnt": 1483,
    //     "lostFcnt": -584,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1698,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0661",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429cd",
    //     "appEUI": "app",
    //     "appKEY": "681E05F0C87B7A33DD7ECEBBD326A537",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 197,
    //     "prevFrameCounterFcnt": 4022,
    //     "latestFrameCounterFcnt": 4025,
    //     "lostFcnt": 1042,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1699,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0662",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429c1",
    //     "appEUI": "app",
    //     "appKEY": "19DB57B28735B552B9775A03BE355FC4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 197,
    //     "prevFrameCounterFcnt": 4025,
    //     "latestFrameCounterFcnt": 4026,
    //     "lostFcnt": 1089,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1700,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0663",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429dc",
    //     "appEUI": "app",
    //     "appKEY": "9AE671E1C5180CF3BAEEF55E59AA41BA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 197,
    //     "prevFrameCounterFcnt": 3999,
    //     "latestFrameCounterFcnt": 4007,
    //     "lostFcnt": 1798,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1701,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0664",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429ae",
    //     "appEUI": "app",
    //     "appKEY": "E1C650F4CB13079D82B8FCE10BF4DE2D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 197,
    //     "prevFrameCounterFcnt": 4025,
    //     "latestFrameCounterFcnt": 4026,
    //     "lostFcnt": 1193,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1702,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0665",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429ec",
    //     "appEUI": "app",
    //     "appKEY": "2081808F93150CBDA06763BBBE047631",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 196,
    //     "prevFrameCounterFcnt": 4024,
    //     "latestFrameCounterFcnt": 4025,
    //     "lostFcnt": 1078,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1703,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0666",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429b3",
    //     "appEUI": "app",
    //     "appKEY": "E6463FA32691364F938B8DBF27360B8C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 196,
    //     "prevFrameCounterFcnt": 4024,
    //     "latestFrameCounterFcnt": 4025,
    //     "lostFcnt": 1490,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1704,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0667",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a06",
    //     "appEUI": "app",
    //     "appKEY": "202B8328BD5303B89431A24F65B76F32",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 196,
    //     "prevFrameCounterFcnt": 4024,
    //     "latestFrameCounterFcnt": 4025,
    //     "lostFcnt": 905,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1705,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0668",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429ca",
    //     "appEUI": "app",
    //     "appKEY": "F368F259E4C0367BC953BB32045936AF",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 195,
    //     "prevFrameCounterFcnt": 4024,
    //     "latestFrameCounterFcnt": 4026,
    //     "lostFcnt": 1471,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1706,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0669",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429d1",
    //     "appEUI": "app",
    //     "appKEY": "B9A18E11358008ED674969E8F0BC93E7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 196,
    //     "prevFrameCounterFcnt": 4024,
    //     "latestFrameCounterFcnt": 4025,
    //     "lostFcnt": 1368,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1707,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0670",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429bf",
    //     "appEUI": "app",
    //     "appKEY": "770405ED540F064D3FB36C84D335DD40",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 196,
    //     "prevFrameCounterFcnt": 4024,
    //     "latestFrameCounterFcnt": 4025,
    //     "lostFcnt": 1157,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1708,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0671",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429d5",
    //     "appEUI": "app",
    //     "appKEY": "61D7483ABD760C620336E353F0C385C7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 187,
    //     "prevFrameCounterFcnt": 4015,
    //     "latestFrameCounterFcnt": 4016,
    //     "lostFcnt": 1122,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1709,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0672",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429b2",
    //     "appEUI": "app",
    //     "appKEY": "FA29D19A34EC6EB44C1ED20655475CBE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 195,
    //     "prevFrameCounterFcnt": 4023,
    //     "latestFrameCounterFcnt": 4024,
    //     "lostFcnt": 1377,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1710,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0673",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429d9",
    //     "appEUI": "app",
    //     "appKEY": "6E0E382E95CA7DAF8FCFCCEDF24B7FE4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 196,
    //     "prevFrameCounterFcnt": 4024,
    //     "latestFrameCounterFcnt": 4025,
    //     "lostFcnt": 2124,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1711,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0674",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429c0",
    //     "appEUI": "app",
    //     "appKEY": "1276AF0483B3D007051C7E3073E7E1C9",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 195,
    //     "prevFrameCounterFcnt": 4023,
    //     "latestFrameCounterFcnt": 4024,
    //     "lostFcnt": 1799,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1712,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0675",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fd3",
    //     "appEUI": "app",
    //     "appKEY": "C6DEE8A9A8AC522D63DDB9962C33BEBA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 196,
    //     "prevFrameCounterFcnt": 4024,
    //     "latestFrameCounterFcnt": 4025,
    //     "lostFcnt": 974,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1713,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0676",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fd8",
    //     "appEUI": "app",
    //     "appKEY": "ED2063D0906A77A30C81F8277B0C3862",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 196,
    //     "prevFrameCounterFcnt": 4024,
    //     "latestFrameCounterFcnt": 4025,
    //     "lostFcnt": 819,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1714,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0677",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429b0",
    //     "appEUI": "app",
    //     "appKEY": "34D43AEA87E2AA2E17142DEB06A01289",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 197,
    //     "prevFrameCounterFcnt": 4025,
    //     "latestFrameCounterFcnt": 4026,
    //     "lostFcnt": 1454,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1715,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0678",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429a6",
    //     "appEUI": "app",
    //     "appKEY": "01C3AB3F151FEA090A57A29121E1E320",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 197,
    //     "prevFrameCounterFcnt": 389,
    //     "latestFrameCounterFcnt": 435,
    //     "lostFcnt": -1120,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1716,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0679",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429b7",
    //     "appEUI": "app",
    //     "appKEY": "987F97F8288D426908B47AE5E8F5CDAD",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 197,
    //     "prevFrameCounterFcnt": 4026,
    //     "latestFrameCounterFcnt": 4028,
    //     "lostFcnt": 2163,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1717,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0680",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429b6",
    //     "appEUI": "app",
    //     "appKEY": "04EAD6D146388D54FBF1C5F07B401E56",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 197,
    //     "prevFrameCounterFcnt": 4025,
    //     "latestFrameCounterFcnt": 4026,
    //     "lostFcnt": 840,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1718,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0681",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429b1",
    //     "appEUI": "app",
    //     "appKEY": "711700C15B7AFEC00624C3B2FAAD630A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 204,
    //     "prevFrameCounterFcnt": 4033,
    //     "latestFrameCounterFcnt": 4035,
    //     "lostFcnt": 751,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1719,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0682",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429d4",
    //     "appEUI": "app",
    //     "appKEY": "B109B6401B3CF1332E361A2C1D622706",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 199,
    //     "prevFrameCounterFcnt": 4022,
    //     "latestFrameCounterFcnt": 4023,
    //     "lostFcnt": 1525,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1720,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0683",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fdd",
    //     "appEUI": "app",
    //     "appKEY": "27AFA6B2ABDA1716DBDE972DD1A01C69",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 197,
    //     "prevFrameCounterFcnt": 4026,
    //     "latestFrameCounterFcnt": 4028,
    //     "lostFcnt": 1332,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1721,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0684",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429c2",
    //     "appEUI": "app",
    //     "appKEY": "73D49F9D55BE258AF5178B4E98A4D761",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 197,
    //     "prevFrameCounterFcnt": 4016,
    //     "latestFrameCounterFcnt": 4023,
    //     "lostFcnt": 789,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1722,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0685",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429b5",
    //     "appEUI": "app",
    //     "appKEY": "F7001298F5E63CD523C597DD56ECFA17",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 204,
    //     "prevFrameCounterFcnt": 4032,
    //     "latestFrameCounterFcnt": 4033,
    //     "lostFcnt": 1184,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1723,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0686",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429cb",
    //     "appEUI": "app",
    //     "appKEY": "7D219EE721123FE519F6D7038CB0B5AE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 196,
    //     "prevFrameCounterFcnt": 4024,
    //     "latestFrameCounterFcnt": 4025,
    //     "lostFcnt": 1174,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1724,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0687",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429d2",
    //     "appEUI": "app",
    //     "appKEY": "991E7CF67A5F1C77F5A2B52298938401",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 198,
    //     "prevFrameCounterFcnt": 4020,
    //     "latestFrameCounterFcnt": 4022,
    //     "lostFcnt": 981,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1725,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0688",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429be",
    //     "appEUI": "app",
    //     "appKEY": "4428610B68985A4840CB9012E2230A9A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 197,
    //     "prevFrameCounterFcnt": 4024,
    //     "latestFrameCounterFcnt": 4025,
    //     "lostFcnt": 1813,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1726,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0689",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429ba",
    //     "appEUI": "app",
    //     "appKEY": "A6A711AFD65E20BA60612A5F47B6C469",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 204,
    //     "prevFrameCounterFcnt": 4033,
    //     "latestFrameCounterFcnt": 4035,
    //     "lostFcnt": 1510,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1727,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0690",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429c4",
    //     "appEUI": "app",
    //     "appKEY": "E98281B1D90A5EE892554469FE9C6005",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 204,
    //     "prevFrameCounterFcnt": 4033,
    //     "latestFrameCounterFcnt": 4035,
    //     "lostFcnt": 1143,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1728,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0691",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429b8",
    //     "appEUI": "app",
    //     "appKEY": "6EBF98B120C55E01FEABD5D04D11805D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 204,
    //     "prevFrameCounterFcnt": 4032,
    //     "latestFrameCounterFcnt": 4033,
    //     "lostFcnt": 1361,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1729,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0692",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429ce",
    //     "appEUI": "app",
    //     "appKEY": "B92A41A9994CDF762B815309ED32E03D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 204,
    //     "prevFrameCounterFcnt": 4033,
    //     "latestFrameCounterFcnt": 4035,
    //     "lostFcnt": 1526,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1730,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0693",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429c3",
    //     "appEUI": "app",
    //     "appKEY": "1B18E34E1C56AB0961FF55368F1ADE30",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 204,
    //     "prevFrameCounterFcnt": 4033,
    //     "latestFrameCounterFcnt": 4035,
    //     "lostFcnt": 1218,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1731,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0694",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429ef",
    //     "appEUI": "app",
    //     "appKEY": "5D2C482456D92BBC2B001E19567151A1",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 205,
    //     "prevFrameCounterFcnt": 4033,
    //     "latestFrameCounterFcnt": 4034,
    //     "lostFcnt": 1326,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1732,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0695",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429bd",
    //     "appEUI": "app",
    //     "appKEY": "4C2C54A01C9A4E58E4D976F04AC777FE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 204,
    //     "prevFrameCounterFcnt": 4032,
    //     "latestFrameCounterFcnt": 4033,
    //     "lostFcnt": 1614,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1733,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0696",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429ad",
    //     "appEUI": "app",
    //     "appKEY": "0953978D18A12DE15C38BB693715A568",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 205,
    //     "prevFrameCounterFcnt": 4032,
    //     "latestFrameCounterFcnt": 4034,
    //     "lostFcnt": 1272,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1734,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0697",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fd7",
    //     "appEUI": "app",
    //     "appKEY": "0160961382AC8D133295B9538D490FCB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 196,
    //     "prevFrameCounterFcnt": 4025,
    //     "latestFrameCounterFcnt": 4027,
    //     "lostFcnt": 1061,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1735,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0698",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a36",
    //     "appEUI": "app",
    //     "appKEY": "354499EEBDB1BFC6126DCA9B774E9617",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 204,
    //     "prevFrameCounterFcnt": 4032,
    //     "latestFrameCounterFcnt": 4033,
    //     "lostFcnt": 1593,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1736,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0699",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429ac",
    //     "appEUI": "app",
    //     "appKEY": "28493E0B30CBAA9E8E6CB88027418667",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 205,
    //     "prevFrameCounterFcnt": 4027,
    //     "latestFrameCounterFcnt": 4034,
    //     "lostFcnt": 1252,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1737,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0700",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429ab",
    //     "appEUI": "app",
    //     "appKEY": "A787BD246481E8CB7330B5057B37EE56",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 205,
    //     "prevFrameCounterFcnt": 4033,
    //     "latestFrameCounterFcnt": 4034,
    //     "lostFcnt": 1179,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1738,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0701",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fe6",
    //     "appEUI": "app",
    //     "appKEY": "8CFF8E314945BDA8406094C6DCFA5277",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 206,
    //     "prevFrameCounterFcnt": 4026,
    //     "latestFrameCounterFcnt": 4034,
    //     "lostFcnt": 1681,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1739,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0702",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429c9",
    //     "appEUI": "app",
    //     "appKEY": "76D4DA903317F20EEA95E2A39EFA8A89",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 205,
    //     "prevFrameCounterFcnt": 1620,
    //     "latestFrameCounterFcnt": 1624,
    //     "lostFcnt": 294,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1740,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0703",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429cf",
    //     "appEUI": "app",
    //     "appKEY": "B01EA2B72080697013F2B4CBCB4925AB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 204,
    //     "prevFrameCounterFcnt": 4032,
    //     "latestFrameCounterFcnt": 4033,
    //     "lostFcnt": 1247,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1741,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0704",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429b4",
    //     "appEUI": "app",
    //     "appKEY": "6462C50F78BDA193919A60D7887D67E1",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 187,
    //     "prevFrameCounterFcnt": 4015,
    //     "latestFrameCounterFcnt": 4016,
    //     "lostFcnt": 798,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1742,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0705",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042a68",
    //     "appEUI": "app",
    //     "appKEY": "822437980C4A1A1FAF5C3434E29FFF5D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 204,
    //     "prevFrameCounterFcnt": 4032,
    //     "latestFrameCounterFcnt": 4033,
    //     "lostFcnt": 934,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1743,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0706",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fe1",
    //     "appEUI": "app",
    //     "appKEY": "2D33BAACC7ADF702A85B2AE3F4A58F13",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 197,
    //     "prevFrameCounterFcnt": 4025,
    //     "latestFrameCounterFcnt": 4026,
    //     "lostFcnt": 1238,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1744,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0707",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429d0",
    //     "appEUI": "app",
    //     "appKEY": "8A237102B115B20A8057601DFED7F726",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 205,
    //     "prevFrameCounterFcnt": 4033,
    //     "latestFrameCounterFcnt": 4034,
    //     "lostFcnt": 1275,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1745,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0708",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429d3",
    //     "appEUI": "app",
    //     "appKEY": "A9FF9277C0535A936D6BFC39B5B433EC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 205,
    //     "prevFrameCounterFcnt": 4033,
    //     "latestFrameCounterFcnt": 4034,
    //     "lostFcnt": 2078,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1746,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0709",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429a8",
    //     "appEUI": "app",
    //     "appKEY": "449B8CEAB3D1195F3DA8014D617B766A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 205,
    //     "prevFrameCounterFcnt": 1445,
    //     "latestFrameCounterFcnt": 1446,
    //     "lostFcnt": -173,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1747,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0710",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429a9",
    //     "appEUI": "app",
    //     "appKEY": "F7A40CFB169854EB4626AB1AF934C5BD",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 206,
    //     "prevFrameCounterFcnt": 4032,
    //     "latestFrameCounterFcnt": 4034,
    //     "lostFcnt": 1641,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1748,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0711",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429c7",
    //     "appEUI": "app",
    //     "appKEY": "78C95F5F812DCDFDDF0A4A8219B8ADCF",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 205,
    //     "prevFrameCounterFcnt": 4033,
    //     "latestFrameCounterFcnt": 4034,
    //     "lostFcnt": 848,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1749,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0712",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fe7",
    //     "appEUI": "app",
    //     "appKEY": "89FCDE4C554F8D9B85FB675F9059F3DD",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 205,
    //     "prevFrameCounterFcnt": 712,
    //     "latestFrameCounterFcnt": 713,
    //     "lostFcnt": -1188,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1750,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0713",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429cc",
    //     "appEUI": "app",
    //     "appKEY": "AADA5ECBAFF5F31A6E46509EB74AFFF6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 204,
    //     "prevFrameCounterFcnt": 4033,
    //     "latestFrameCounterFcnt": 4035,
    //     "lostFcnt": 1232,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1751,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0714",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429b9",
    //     "appEUI": "app",
    //     "appKEY": "9528D310DFF4714608791FF1EF01F888",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 205,
    //     "prevFrameCounterFcnt": 4028,
    //     "latestFrameCounterFcnt": 4030,
    //     "lostFcnt": 1435,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1752,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0715",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429a7",
    //     "appEUI": "app",
    //     "appKEY": "0779A6DC0F390E6C3CCA08350215427F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 196,
    //     "prevFrameCounterFcnt": 4025,
    //     "latestFrameCounterFcnt": 4027,
    //     "lostFcnt": 707,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1753,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0716",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200429c6",
    //     "appEUI": "app",
    //     "appKEY": "AA3F902FB00AC6B6A8CCA2009D7B54C0",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 205,
    //     "prevFrameCounterFcnt": 4030,
    //     "latestFrameCounterFcnt": 4031,
    //     "lostFcnt": 1684,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1754,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0717",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f23",
    //     "appEUI": "app",
    //     "appKEY": "1BFF89A265DFAE399FB7DF959919DF17",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2,
    //     "prevFrameCounterFcnt": 4111,
    //     "latestFrameCounterFcnt": 4112,
    //     "lostFcnt": 1007,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1755,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0718",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fd5",
    //     "appEUI": "app",
    //     "appKEY": "847F3C27BC8EC6AE2C8CEDA18D1A6D71",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4111,
    //     "latestFrameCounterFcnt": 4112,
    //     "lostFcnt": 1571,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1756,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0719",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fbc",
    //     "appEUI": "app",
    //     "appKEY": "D4704A71D4FAE91591B9EA65669B0D84",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4109,
    //     "latestFrameCounterFcnt": 4110,
    //     "lostFcnt": 992,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1757,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0720",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f8a",
    //     "appEUI": "app",
    //     "appKEY": "2ABEF86F556D1D81FA4CEBE8C8CBEB96",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4111,
    //     "latestFrameCounterFcnt": 4112,
    //     "lostFcnt": 744,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1758,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0721",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fd1",
    //     "appEUI": "app",
    //     "appKEY": "6F6DD718AA7506D84056A176EDDCB03C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4110,
    //     "latestFrameCounterFcnt": 4111,
    //     "lostFcnt": 865,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1759,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0722",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f31",
    //     "appEUI": "app",
    //     "appKEY": "3ABE2471EE385DE0249E8AD117698E6A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4111,
    //     "latestFrameCounterFcnt": 4112,
    //     "lostFcnt": 816,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1760,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0723",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f57",
    //     "appEUI": "app",
    //     "appKEY": "98FB248EEE0F1D77EC48E3B6E6578163",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4107,
    //     "latestFrameCounterFcnt": 4110,
    //     "lostFcnt": 1220,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1761,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0724",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f73",
    //     "appEUI": "app",
    //     "appKEY": "DAFD9F2E99C55A4ADACC50CD83B449D6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4111,
    //     "latestFrameCounterFcnt": 4113,
    //     "lostFcnt": 942,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1762,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0725",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fbd",
    //     "appEUI": "app",
    //     "appKEY": "F099719718764F6BD1670634D42C2709",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4110,
    //     "latestFrameCounterFcnt": 4112,
    //     "lostFcnt": 778,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1763,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0726",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fab",
    //     "appEUI": "app",
    //     "appKEY": "92CE46B6561B0BB203309D17633AA245",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4111,
    //     "latestFrameCounterFcnt": 4113,
    //     "lostFcnt": 929,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1764,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0727",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fda",
    //     "appEUI": "app",
    //     "appKEY": "63B7378D6EF0DA0C189B2341731B6F12",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -117,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4110,
    //     "latestFrameCounterFcnt": 4111,
    //     "lostFcnt": 956,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1765,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0728",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f47",
    //     "appEUI": "app",
    //     "appKEY": "BFE4C1C8C522F713E62AAABB03DCE4BE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4111,
    //     "latestFrameCounterFcnt": 4113,
    //     "lostFcnt": 844,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1766,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0729",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f76",
    //     "appEUI": "app",
    //     "appKEY": "3B42B9B8F913F8698AF86E7AD3DE506E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4110,
    //     "latestFrameCounterFcnt": 4111,
    //     "lostFcnt": 831,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1767,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0730",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f82",
    //     "appEUI": "app",
    //     "appKEY": "9DDDBDE04FEDC377092CF5FD41AD7AC6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4110,
    //     "latestFrameCounterFcnt": 4111,
    //     "lostFcnt": 800,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1768,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0731",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fd0",
    //     "appEUI": "app",
    //     "appKEY": "C42E262C60CE902A2BFC2464E1EE4596",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4110,
    //     "latestFrameCounterFcnt": 4111,
    //     "lostFcnt": 740,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1769,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0732",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f32",
    //     "appEUI": "app",
    //     "appKEY": "5989FBEFDA459AAF809D0323573F37D6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4110,
    //     "latestFrameCounterFcnt": 4111,
    //     "lostFcnt": 808,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1770,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0733",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f34",
    //     "appEUI": "app",
    //     "appKEY": "DC9D19C3AE54C3DB414B62EEDD7438D6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4110,
    //     "latestFrameCounterFcnt": 4111,
    //     "lostFcnt": 872,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1771,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0734",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f7a",
    //     "appEUI": "app",
    //     "appKEY": "9AD25F2A8318C2199B23BBA08C1EED55",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2,
    //     "prevFrameCounterFcnt": 4109,
    //     "latestFrameCounterFcnt": 4110,
    //     "lostFcnt": 875,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1772,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0735",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fa1",
    //     "appEUI": "app",
    //     "appKEY": "3BA72C01E4CDDEA9D09ECF9A077EAC2A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4110,
    //     "latestFrameCounterFcnt": 4111,
    //     "lostFcnt": 1398,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1773,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0736",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f26",
    //     "appEUI": "app",
    //     "appKEY": "99F9358773D7F7A8A4A1136A6F02A4A8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 9,
    //     "prevFrameCounterFcnt": 4140,
    //     "latestFrameCounterFcnt": 4141,
    //     "lostFcnt": 905,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1774,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0737",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f37",
    //     "appEUI": "app",
    //     "appKEY": "86A51F1FC8563BFC17AA42ACF5D41529",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4141,
    //     "lostFcnt": 871,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1775,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0738",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f39",
    //     "appEUI": "app",
    //     "appKEY": "0CA0E8760705D69D70521674966102CD",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -112,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 695,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1776,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0739",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fe4",
    //     "appEUI": "app",
    //     "appKEY": "B5A72D6A5C1CF630E45543278FEB68BA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 765,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1777,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0740",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f33",
    //     "appEUI": "app",
    //     "appKEY": "29D1CE376B9DE502B388A375B061D436",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 4140,
    //     "latestFrameCounterFcnt": 4141,
    //     "lostFcnt": 819,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1778,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0741",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fe3",
    //     "appEUI": "app",
    //     "appKEY": "1664CAEE3ED4C28438632BA174DC0BE4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4140,
    //     "latestFrameCounterFcnt": 4142,
    //     "lostFcnt": 835,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1779,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0742",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fcf",
    //     "appEUI": "app",
    //     "appKEY": "E98DDFB6B6DC710821BEB0B0D5096646",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 834,
    //     "loraSNR": -6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1780,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0743",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fd9",
    //     "appEUI": "app",
    //     "appKEY": "FA194FC6CC042B79627C246DD98B5C53",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 692,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1781,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0744",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f24",
    //     "appEUI": "app",
    //     "appKEY": "CC4AA69308D050E22FBDE74D40A8CC3B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 815,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1782,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0745",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f22",
    //     "appEUI": "app",
    //     "appKEY": "DF059BBCB94A2A6FF8356EF90CD296E7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -116,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 842,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1783,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0746",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fdf",
    //     "appEUI": "app",
    //     "appKEY": "71DA0D168F41BD31CA41AF08887CB488",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4138,
    //     "latestFrameCounterFcnt": 4139,
    //     "lostFcnt": 772,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1784,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0747",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fde",
    //     "appEUI": "app",
    //     "appKEY": "C2FD43B2E4D1349F704647FE2EA7BA3E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4138,
    //     "latestFrameCounterFcnt": 4139,
    //     "lostFcnt": 764,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1785,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0748",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fd2",
    //     "appEUI": "app",
    //     "appKEY": "8C76A65CAE300178296026DC30DDEC34",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -96,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 645,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1786,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0749",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fe5",
    //     "appEUI": "app",
    //     "appKEY": "1602A9C9209C396284D9B6B00BE7E65D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4138,
    //     "latestFrameCounterFcnt": 4139,
    //     "lostFcnt": 1732,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1787,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0750",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f36",
    //     "appEUI": "app",
    //     "appKEY": "84E47A039D0DCA9EB735351536B3A3C3",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4138,
    //     "latestFrameCounterFcnt": 4139,
    //     "lostFcnt": 1102,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1788,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0751",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f20",
    //     "appEUI": "app",
    //     "appKEY": "E609625D9B2E904CDFA66252C5867F5F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4111,
    //     "latestFrameCounterFcnt": 4112,
    //     "lostFcnt": 868,
    //     "loraSNR": -6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1789,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0752",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fd4",
    //     "appEUI": "app",
    //     "appKEY": "67D8EB02FA5C454086EC24F380BC5291",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4111,
    //     "latestFrameCounterFcnt": 4112,
    //     "lostFcnt": 742,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1790,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0753",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fdb",
    //     "appEUI": "app",
    //     "appKEY": "068699DA89C6A3747537C6D0EB2C2BF1",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4138,
    //     "latestFrameCounterFcnt": 4139,
    //     "lostFcnt": 781,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1791,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0754",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f27",
    //     "appEUI": "app",
    //     "appKEY": "BEE5C4BC797D10FD56FFA591DF6E3AD2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4138,
    //     "latestFrameCounterFcnt": 4139,
    //     "lostFcnt": 875,
    //     "loraSNR": -6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1792,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0755",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fe0",
    //     "appEUI": "app",
    //     "appKEY": "6596A5B823224008DB8E14191B741025",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4149,
    //     "latestFrameCounterFcnt": 4150,
    //     "lostFcnt": 1126,
    //     "loraSNR": -19,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1793,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0756",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f2a",
    //     "appEUI": "app",
    //     "appKEY": "81A9660B1E84189CA3058491FDCF466A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4148,
    //     "latestFrameCounterFcnt": 4149,
    //     "lostFcnt": 736,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1794,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0757",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f63",
    //     "appEUI": "app",
    //     "appKEY": "B41921DA9B457E614D3AA79564AA7132",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 2,
    //     "prevFrameCounterFcnt": 4149,
    //     "latestFrameCounterFcnt": 4150,
    //     "lostFcnt": 1065,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1795,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0758",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fc0",
    //     "appEUI": "app",
    //     "appKEY": "F00C05A0837C7A2C4B8B8029E8EF3000",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4149,
    //     "latestFrameCounterFcnt": 4150,
    //     "lostFcnt": 1282,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1796,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0759",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f2b",
    //     "appEUI": "app",
    //     "appKEY": "C3FC8CDB1AE60AD4FAA3C76DB07C0D7D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 42,
    //     "prevFrameCounterFcnt": 662,
    //     "latestFrameCounterFcnt": 666,
    //     "lostFcnt": -125,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1797,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0760",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f2d",
    //     "appEUI": "app",
    //     "appKEY": "22F35686B40A6E755BC4B7190855E637",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 686,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1798,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0761",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f2e",
    //     "appEUI": "app",
    //     "appKEY": "C76DB37FF9FA6DBE5E3ECE71739148F9",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4148,
    //     "latestFrameCounterFcnt": 4149,
    //     "lostFcnt": 814,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1799,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0762",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f4f",
    //     "appEUI": "app",
    //     "appKEY": "826F6B4DC24F1CBD74BEE1505709BF0D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 1477,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1800,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0763",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f38",
    //     "appEUI": "app",
    //     "appKEY": "BEFD779755A89714A00CD86C2DD2AC9F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -107,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 4140,
    //     "latestFrameCounterFcnt": 4141,
    //     "lostFcnt": 621,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1801,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0764",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f88",
    //     "appEUI": "app",
    //     "appKEY": "6F41B8F8EC415A71E6AFFAE833E4C02E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -108,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 660,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1802,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0765",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f29",
    //     "appEUI": "app",
    //     "appKEY": "EEB991D25C704DAA8A458DA278076AF8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -100,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 622,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1803,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0766",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f30",
    //     "appEUI": "app",
    //     "appKEY": "0DF2691BE51C7C47EAFBD498CC46309C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4111,
    //     "latestFrameCounterFcnt": 4112,
    //     "lostFcnt": 993,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1804,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0767",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f4b",
    //     "appEUI": "app",
    //     "appKEY": "3BD9F9EEF547068A43E1F49913B04B8A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 779,
    //     "loraSNR": 0,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1805,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0768",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f92",
    //     "appEUI": "app",
    //     "appKEY": "7E8AF66A336C244B97E8AB6AC8B08DFC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 4140,
    //     "latestFrameCounterFcnt": 4142,
    //     "lostFcnt": 829,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1806,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0769",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f58",
    //     "appEUI": "app",
    //     "appKEY": "46FE36C7406D78F302CB26C37DF20CC1",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4139,
    //     "latestFrameCounterFcnt": 4140,
    //     "lostFcnt": 673,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1807,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0770",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f21",
    //     "appEUI": "app",
    //     "appKEY": "E3D7A32D4E92FD4D89B9FB1466A49C09",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4140,
    //     "latestFrameCounterFcnt": 4142,
    //     "lostFcnt": 844,
    //     "loraSNR": -6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1808,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0771",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f25",
    //     "appEUI": "app",
    //     "appKEY": "E342929479732CD02983E56073A4A1B2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4138,
    //     "latestFrameCounterFcnt": 4139,
    //     "lostFcnt": 774,
    //     "loraSNR": -6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1809,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0772",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fd6",
    //     "appEUI": "app",
    //     "appKEY": "3AB1929A1A39B858374D3455B04C7CCF",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4140,
    //     "latestFrameCounterFcnt": 4142,
    //     "lostFcnt": 842,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1810,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0773",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f65",
    //     "appEUI": "app",
    //     "appKEY": "8CF824BC2AD1423AD2C590114B19D4D9",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 4140,
    //     "latestFrameCounterFcnt": 4141,
    //     "lostFcnt": 1361,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1811,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0774",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f8e",
    //     "appEUI": "app",
    //     "appKEY": "0512EDD65E9482EDE6170850A1E53B5D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4148,
    //     "latestFrameCounterFcnt": 4149,
    //     "lostFcnt": 769,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1812,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0775",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f61",
    //     "appEUI": "app",
    //     "appKEY": "3A8152CAA658AEA9931FE6EA0EC4D117",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4149,
    //     "latestFrameCounterFcnt": 4150,
    //     "lostFcnt": 985,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1813,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0776",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f54",
    //     "appEUI": "app",
    //     "appKEY": "F9C9486478525529D5AD20A56218B55D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 3856,
    //     "latestFrameCounterFcnt": 3858,
    //     "lostFcnt": 1021,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1814,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0777",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f55",
    //     "appEUI": "app",
    //     "appKEY": "DB5F3BD8BD26B98207C9C72975FF53D0",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4150,
    //     "latestFrameCounterFcnt": 4151,
    //     "lostFcnt": 1355,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1815,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0778",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f35",
    //     "appEUI": "app",
    //     "appKEY": "5F10EB848F4841AE413DB028ECD69604",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4150,
    //     "latestFrameCounterFcnt": 4151,
    //     "lostFcnt": 873,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1816,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0779",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f62",
    //     "appEUI": "app",
    //     "appKEY": "919F3333D6F32A31EAEE20782B675A88",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 21,
    //     "prevFrameCounterFcnt": 4150,
    //     "latestFrameCounterFcnt": 4151,
    //     "lostFcnt": 2373,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1817,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0780",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f74",
    //     "appEUI": "app",
    //     "appKEY": "BC962E4DE155243186260D310267CACC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4150,
    //     "latestFrameCounterFcnt": 4151,
    //     "lostFcnt": 1410,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1818,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0781",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f6d",
    //     "appEUI": "app",
    //     "appKEY": "B79C9955E203D2F55D4B54A1830A015C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4150,
    //     "latestFrameCounterFcnt": 4151,
    //     "lostFcnt": 1134,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1819,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0782",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f53",
    //     "appEUI": "app",
    //     "appKEY": "B76EE3CF1D38426A0519FFAE8C0842CC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 1387,
    //     "latestFrameCounterFcnt": 1388,
    //     "lostFcnt": -1603,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1820,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0783",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f51",
    //     "appEUI": "app",
    //     "appKEY": "2D86803D378BF1281061D8E6C708E296",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4147,
    //     "latestFrameCounterFcnt": 4149,
    //     "lostFcnt": 2420,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1821,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0784",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f4d",
    //     "appEUI": "app",
    //     "appKEY": "A17519AA20D2336B51856A56642152B9",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4150,
    //     "latestFrameCounterFcnt": 4152,
    //     "lostFcnt": 1273,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1822,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0785",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f3c",
    //     "appEUI": "app",
    //     "appKEY": "AF9CCDC081DF0D16D34A1B7604286E70",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2,
    //     "prevFrameCounterFcnt": 4149,
    //     "latestFrameCounterFcnt": 4151,
    //     "lostFcnt": 848,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1823,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0786",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f5c",
    //     "appEUI": "app",
    //     "appKEY": "70BF188F7272E4D6FACCF7C807D9F83C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4149,
    //     "latestFrameCounterFcnt": 4150,
    //     "lostFcnt": 952,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1824,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0787",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f64",
    //     "appEUI": "app",
    //     "appKEY": "570B762EE068DD5D90D1266CA70C743E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4149,
    //     "latestFrameCounterFcnt": 4151,
    //     "lostFcnt": 1640,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1825,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0788",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f4e",
    //     "appEUI": "app",
    //     "appKEY": "1101707B9B606921C0607683CF71970E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 1420,
    //     "latestFrameCounterFcnt": 1421,
    //     "lostFcnt": -231,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1826,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0789",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f66",
    //     "appEUI": "app",
    //     "appKEY": "9C093D1C6EA96B007ED4057EC6310AC0",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4150,
    //     "latestFrameCounterFcnt": 4151,
    //     "lostFcnt": 815,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1827,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0790",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f5d",
    //     "appEUI": "app",
    //     "appKEY": "51FBD9041F2F354E5657DB744AD4E0BF",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4150,
    //     "latestFrameCounterFcnt": 4151,
    //     "lostFcnt": 746,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1828,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0791",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f40",
    //     "appEUI": "app",
    //     "appKEY": "A2A3085AD7B5BBD7D32A7E56BBF0A00D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 3,
    //     "prevFrameCounterFcnt": 1644,
    //     "latestFrameCounterFcnt": 1646,
    //     "lostFcnt": 575,
    //     "loraSNR": -19,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1829,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0792",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f6f",
    //     "appEUI": "app",
    //     "appKEY": "D2C9C2F7A8DDFB7DA25F643CBCBA23D2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4150,
    //     "latestFrameCounterFcnt": 4151,
    //     "lostFcnt": 1475,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1830,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0793",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f5b",
    //     "appEUI": "app",
    //     "appKEY": "9162FDD4A8FD7E6630399A0F6F51E115",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4149,
    //     "latestFrameCounterFcnt": 4150,
    //     "lostFcnt": 967,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1831,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0794",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f60",
    //     "appEUI": "app",
    //     "appKEY": "BDE6E96EBD83B1C3A773EBF751ADE383",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4148,
    //     "latestFrameCounterFcnt": 4149,
    //     "lostFcnt": 830,
    //     "loraSNR": -8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1832,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0795",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f87",
    //     "appEUI": "app",
    //     "appKEY": "3E6EEFF76A27D25CC78ED3052C4BB6DA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 1257,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1833,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0796",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f84",
    //     "appEUI": "app",
    //     "appKEY": "6DA1CF83494948092EA377C1A32E7020",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 185,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4016,
    //     "lostFcnt": 876,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1834,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0797",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f41",
    //     "appEUI": "app",
    //     "appKEY": "3796CE9E95A7BB749BB24B743D023F7B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 667,
    //     "latestFrameCounterFcnt": 668,
    //     "lostFcnt": -1637,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1835,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0798",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f59",
    //     "appEUI": "app",
    //     "appKEY": "5D70325987C4890EDF0058A26A50AAB1",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 1387,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1836,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0799",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f5f",
    //     "appEUI": "app",
    //     "appKEY": "6DC152BE334F5341E986BE4109B0DF74",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4009,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 2119,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1837,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0800",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f95",
    //     "appEUI": "app",
    //     "appKEY": "D6D07ADA7BB833EDAB441BAEAABA290D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 187,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 1113,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1838,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0801",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f45",
    //     "appEUI": "app",
    //     "appKEY": "BBFDEC1015E535E091E07CD814F6601A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 1270,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1839,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0802",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f89",
    //     "appEUI": "app",
    //     "appKEY": "7F93ABBE113353FA20C2031D3A354E8A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 1128,
    //     "latestFrameCounterFcnt": 1129,
    //     "lostFcnt": 136,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1840,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0803",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f52",
    //     "appEUI": "app",
    //     "appKEY": "2562578C8C0384EAF362865990296C20",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 178,
    //     "prevFrameCounterFcnt": 4006,
    //     "latestFrameCounterFcnt": 4007,
    //     "lostFcnt": 773,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1841,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0804",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f50",
    //     "appEUI": "app",
    //     "appKEY": "D2C639066D2917C0B9BE67AB2D5A72B7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 178,
    //     "prevFrameCounterFcnt": 4006,
    //     "latestFrameCounterFcnt": 4007,
    //     "lostFcnt": 1187,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1842,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0805",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f4c",
    //     "appEUI": "app",
    //     "appKEY": "98FDB6707E79AD530703B42560A8F326",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 185,
    //     "prevFrameCounterFcnt": 4013,
    //     "latestFrameCounterFcnt": 4016,
    //     "lostFcnt": 1187,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1843,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0806",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f49",
    //     "appEUI": "app",
    //     "appKEY": "B89B60E6337D5608E6F997AF656A4DEE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 4005,
    //     "latestFrameCounterFcnt": 4006,
    //     "lostFcnt": 1337,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1844,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0807",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f44",
    //     "appEUI": "app",
    //     "appKEY": "A2C21F36CD11DEACA90824DD6942E422",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4003,
    //     "latestFrameCounterFcnt": 4006,
    //     "lostFcnt": 2239,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1845,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0808",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f5e",
    //     "appEUI": "app",
    //     "appKEY": "5414CD503C7A894C82AC1BF63146509A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 178,
    //     "prevFrameCounterFcnt": 4006,
    //     "latestFrameCounterFcnt": 4008,
    //     "lostFcnt": 1182,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1846,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0809",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f56",
    //     "appEUI": "app",
    //     "appKEY": "64EE2DAC0B8BE425F33217D562416F23",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 178,
    //     "prevFrameCounterFcnt": 4006,
    //     "latestFrameCounterFcnt": 4007,
    //     "lostFcnt": 1414,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1847,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0810",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f90",
    //     "appEUI": "app",
    //     "appKEY": "81FEAC53C136E08AE0BC0B88A2F18149",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 178,
    //     "prevFrameCounterFcnt": 4006,
    //     "latestFrameCounterFcnt": 4007,
    //     "lostFcnt": 901,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1848,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0811",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f46",
    //     "appEUI": "app",
    //     "appKEY": "139DE9F00AAA1F1426DF92BC60908F20",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 3910,
    //     "latestFrameCounterFcnt": 3911,
    //     "lostFcnt": 781,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1849,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0812",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f4a",
    //     "appEUI": "app",
    //     "appKEY": "A35981EFDBD304A5D32DF0B77A1B2F8E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 178,
    //     "prevFrameCounterFcnt": 4006,
    //     "latestFrameCounterFcnt": 4007,
    //     "lostFcnt": 1508,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1850,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0813",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f42",
    //     "appEUI": "app",
    //     "appKEY": "A4BB0DC805D5F5E69F83B0DAD2EFEF7B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 178,
    //     "prevFrameCounterFcnt": 4006,
    //     "latestFrameCounterFcnt": 4007,
    //     "lostFcnt": 1799,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1851,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0814",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f48",
    //     "appEUI": "app",
    //     "appKEY": "F0D50ACABC850C0F761E5C603D5880ED",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 185,
    //     "prevFrameCounterFcnt": 4013,
    //     "latestFrameCounterFcnt": 4014,
    //     "lostFcnt": 2804,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1852,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0815",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f91",
    //     "appEUI": "app",
    //     "appKEY": "1DAB229F3D5475243772F127D13373D0",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 182,
    //     "prevFrameCounterFcnt": 4006,
    //     "latestFrameCounterFcnt": 4007,
    //     "lostFcnt": 1309,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1853,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0816",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f96",
    //     "appEUI": "app",
    //     "appKEY": "5F6B7A8F8C5EB78365A45E23A1030772",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 1243,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1854,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0817",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f9a",
    //     "appEUI": "app",
    //     "appKEY": "16B2E5B528423F2DD50F88D0699CF19A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 185,
    //     "prevFrameCounterFcnt": 4013,
    //     "latestFrameCounterFcnt": 4014,
    //     "lostFcnt": 2374,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1855,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0818",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f3e",
    //     "appEUI": "app",
    //     "appKEY": "36CB71788FADBBEC695DE5CD4C55B2BB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4012,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 1276,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1856,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0819",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f3d",
    //     "appEUI": "app",
    //     "appKEY": "FA4232C97A86BB4821E7B65CFC250156",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4013,
    //     "latestFrameCounterFcnt": 4014,
    //     "lostFcnt": 1176,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1857,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0820",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f71",
    //     "appEUI": "app",
    //     "appKEY": "EB1FA6BA82EA13DEB8360B8B569DDE13",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 4005,
    //     "latestFrameCounterFcnt": 4006,
    //     "lostFcnt": 1322,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1858,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0821",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f94",
    //     "appEUI": "app",
    //     "appKEY": "42FBB826B72A7BE84236053ACA8447F6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 1568,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1859,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0822",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f85",
    //     "appEUI": "app",
    //     "appKEY": "2C381EC73D45560D74FB6D8EB84A0727",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 4005,
    //     "latestFrameCounterFcnt": 4006,
    //     "lostFcnt": 1136,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1860,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0823",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f8c",
    //     "appEUI": "app",
    //     "appKEY": "7309610DAA4FCAB186CBCE5F748F885A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 1138,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1861,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0824",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f43",
    //     "appEUI": "app",
    //     "appKEY": "AF7F24EEC11BED1A5DCF81CA95F2645E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 1270,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1862,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0825",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f9e",
    //     "appEUI": "app",
    //     "appKEY": "646763EED8BEC23742623D9DD34B1857",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 4005,
    //     "latestFrameCounterFcnt": 4006,
    //     "lostFcnt": 1760,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1863,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0826",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f99",
    //     "appEUI": "app",
    //     "appKEY": "B3EDF4AD3930331F75EA39A7F1FEDD11",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4015,
    //     "latestFrameCounterFcnt": 4017,
    //     "lostFcnt": 809,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1864,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0827",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f5a",
    //     "appEUI": "app",
    //     "appKEY": "087D3C4375B5806859ACD15A46DE8F36",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 138,
    //     "prevFrameCounterFcnt": 3966,
    //     "latestFrameCounterFcnt": 3967,
    //     "lostFcnt": 921,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1865,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0828",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f3a",
    //     "appEUI": "app",
    //     "appKEY": "E885D626A766C24E6E485F64A32713F5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 1571,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1866,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0829",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f3f",
    //     "appEUI": "app",
    //     "appKEY": "720B1E9B58669CD13F4235ECDB981F2C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 1129,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1867,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0830",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f78",
    //     "appEUI": "app",
    //     "appKEY": "2214CA2F11FB17FADD15BEFDCD7C401F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 178,
    //     "prevFrameCounterFcnt": 4006,
    //     "latestFrameCounterFcnt": 4007,
    //     "lostFcnt": 996,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1868,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0831",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f8d",
    //     "appEUI": "app",
    //     "appKEY": "783186DAC4C4BF0D3FEFA6319FD08B9E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 1875,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1869,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0832",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f3b",
    //     "appEUI": "app",
    //     "appKEY": "7B988A495537E0E20B1032C8E70A5E09",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 178,
    //     "prevFrameCounterFcnt": 4006,
    //     "latestFrameCounterFcnt": 4007,
    //     "lostFcnt": 686,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1870,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0833",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f9d",
    //     "appEUI": "app",
    //     "appKEY": "6EC85DDFA62CAECA756BC5108AEA9480",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 125,
    //     "prevFrameCounterFcnt": 3954,
    //     "latestFrameCounterFcnt": 3956,
    //     "lostFcnt": 1426,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1871,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0834",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f72",
    //     "appEUI": "app",
    //     "appKEY": "7D1D7B3B1DBABBBCF1D0E5EA6C778D09",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 760,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1872,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0835",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f77",
    //     "appEUI": "app",
    //     "appKEY": "8CA86F68FCABEEB20EDE241F4408767B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 138,
    //     "prevFrameCounterFcnt": 3967,
    //     "latestFrameCounterFcnt": 3969,
    //     "lostFcnt": 1276,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1873,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0836",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f7b",
    //     "appEUI": "app",
    //     "appKEY": "C2E748A9C5E0076397D17FC2E22BD3ED",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 187,
    //     "prevFrameCounterFcnt": 4016,
    //     "latestFrameCounterFcnt": 4018,
    //     "lostFcnt": 996,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1874,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0837",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f9c",
    //     "appEUI": "app",
    //     "appKEY": "23D2CC3BB321B4C563DB83F2F3FBC1BB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 205,
    //     "prevFrameCounterFcnt": 4034,
    //     "latestFrameCounterFcnt": 4036,
    //     "lostFcnt": 1197,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1875,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0838",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fbe",
    //     "appEUI": "app",
    //     "appKEY": "FA0C1DCEA35A6CEF0E42AE229B74083A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 205,
    //     "prevFrameCounterFcnt": 1054,
    //     "latestFrameCounterFcnt": 1055,
    //     "lostFcnt": -1412,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1876,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0839",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f97",
    //     "appEUI": "app",
    //     "appKEY": "8EB754E1B4D77F69DCED60F8B28F3450",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 187,
    //     "prevFrameCounterFcnt": 4016,
    //     "latestFrameCounterFcnt": 4018,
    //     "lostFcnt": 866,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1877,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0840",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fcd",
    //     "appEUI": "app",
    //     "appKEY": "E016EC814AF2FD50D3989D44A32714FD",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 173,
    //     "prevFrameCounterFcnt": 863,
    //     "latestFrameCounterFcnt": 867,
    //     "lostFcnt": 119,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1878,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0841",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f6e",
    //     "appEUI": "app",
    //     "appKEY": "E21051A52709D397A9E6CCDF39244308",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 197,
    //     "prevFrameCounterFcnt": 4026,
    //     "latestFrameCounterFcnt": 4028,
    //     "lostFcnt": 625,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1879,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0842",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f81",
    //     "appEUI": "app",
    //     "appKEY": "6DF57D30941939C22E2D429B0155DA66",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 205,
    //     "prevFrameCounterFcnt": 4033,
    //     "latestFrameCounterFcnt": 4034,
    //     "lostFcnt": 926,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1880,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0843",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f9b",
    //     "appEUI": "app",
    //     "appKEY": "7EF0D203DECE941A7A2FD25D3F761652",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 205,
    //     "prevFrameCounterFcnt": 4033,
    //     "latestFrameCounterFcnt": 4034,
    //     "lostFcnt": 769,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1881,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0844",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fc9",
    //     "appEUI": "app",
    //     "appKEY": "7AA09E3C51A9AD3EC01CADEF57235537",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 187,
    //     "prevFrameCounterFcnt": 4016,
    //     "latestFrameCounterFcnt": 4018,
    //     "lostFcnt": 1099,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1882,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0845",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f98",
    //     "appEUI": "app",
    //     "appKEY": "107F4F40F4218467BE2C4A865CD35E7C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 377,
    //     "prevFrameCounterFcnt": 4033,
    //     "latestFrameCounterFcnt": 4034,
    //     "lostFcnt": 2177,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1883,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0846",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fbf",
    //     "appEUI": "app",
    //     "appKEY": "D70A6DD74079E8E2317E1B40C4F546EC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 205,
    //     "prevFrameCounterFcnt": 816,
    //     "latestFrameCounterFcnt": 818,
    //     "lostFcnt": -1029,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1884,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0847",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f7f",
    //     "appEUI": "app",
    //     "appKEY": "8C36AA173CA7DAC59B3DBDE0D5D221AC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 212,
    //     "prevFrameCounterFcnt": 157,
    //     "latestFrameCounterFcnt": 158,
    //     "lostFcnt": -1187,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1885,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0848",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004301d",
    //     "appEUI": "app",
    //     "appKEY": "C838B3D8A85AE1AD39D10398389F3165",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 205,
    //     "prevFrameCounterFcnt": 56,
    //     "latestFrameCounterFcnt": 65,
    //     "lostFcnt": -1240,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1886,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0849",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f93",
    //     "appEUI": "app",
    //     "appKEY": "CA8D4819057BD0CE4E7EDDF7840553C4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 188,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4016,
    //     "lostFcnt": 1607,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1887,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0850",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f7d",
    //     "appEUI": "app",
    //     "appKEY": "38FC71266B53CE202C67527F48E4E2DE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 187,
    //     "prevFrameCounterFcnt": 4015,
    //     "latestFrameCounterFcnt": 4016,
    //     "lostFcnt": 907,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1888,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0851",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f8f",
    //     "appEUI": "app",
    //     "appKEY": "3DA5059E9595CCEFDB451905AC843C95",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 187,
    //     "prevFrameCounterFcnt": 4015,
    //     "latestFrameCounterFcnt": 4016,
    //     "lostFcnt": 1575,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1889,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0852",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f8b",
    //     "appEUI": "app",
    //     "appKEY": "786F1D6A27531F9DB1B431F44A63AF85",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 202,
    //     "prevFrameCounterFcnt": 1394,
    //     "latestFrameCounterFcnt": 1395,
    //     "lostFcnt": -338,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1890,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0853",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fb9",
    //     "appEUI": "app",
    //     "appKEY": "7FC60139B15C4F8D89745285FC828D4B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 205,
    //     "prevFrameCounterFcnt": 2226,
    //     "latestFrameCounterFcnt": 2227,
    //     "lostFcnt": -746,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1891,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0854",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f86",
    //     "appEUI": "app",
    //     "appKEY": "4590670723B3707C0CF5193DBEE007E5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 187,
    //     "prevFrameCounterFcnt": 4016,
    //     "latestFrameCounterFcnt": 4018,
    //     "lostFcnt": 1249,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1892,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0855",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f80",
    //     "appEUI": "app",
    //     "appKEY": "66AF2C59094FF462FAB32E268EB7F107",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 206,
    //     "prevFrameCounterFcnt": 4034,
    //     "latestFrameCounterFcnt": 4035,
    //     "lostFcnt": 1097,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1893,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0856",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fba",
    //     "appEUI": "app",
    //     "appKEY": "0616720E78EACC4DEA28220D8CFD4E21",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 207,
    //     "prevFrameCounterFcnt": 2210,
    //     "latestFrameCounterFcnt": 2211,
    //     "lostFcnt": 137,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1894,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0857",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f6c",
    //     "appEUI": "app",
    //     "appKEY": "B78A02E405DAC312E01834360506B246",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 207,
    //     "prevFrameCounterFcnt": 4035,
    //     "latestFrameCounterFcnt": 4036,
    //     "lostFcnt": 839,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1895,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0858",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fbb",
    //     "appEUI": "app",
    //     "appKEY": "CB7B1F4213B1D2A7DD5D83755595A288",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 187,
    //     "prevFrameCounterFcnt": 4015,
    //     "latestFrameCounterFcnt": 4016,
    //     "lostFcnt": 1529,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1896,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0859",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f6a",
    //     "appEUI": "app",
    //     "appKEY": "63E57D8D6599E1340A2FFD92BDCF7DF4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 207,
    //     "prevFrameCounterFcnt": 203,
    //     "latestFrameCounterFcnt": 211,
    //     "lostFcnt": -1943,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1897,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0860",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fb3",
    //     "appEUI": "app",
    //     "appKEY": "F470E804924C55000ECAE45DA58DB180",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 5,
    //     "prevFrameCounterFcnt": 953,
    //     "latestFrameCounterFcnt": 954,
    //     "lostFcnt": 673,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1898,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0861",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fc7",
    //     "appEUI": "app",
    //     "appKEY": "36148ADC923E1D21ED69A0B54BC507A6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 207,
    //     "prevFrameCounterFcnt": 216,
    //     "latestFrameCounterFcnt": 217,
    //     "lostFcnt": -2383,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1899,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0862",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fc3",
    //     "appEUI": "app",
    //     "appKEY": "CE84E0C385312E1DF58F92DFF70DEE91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 206,
    //     "prevFrameCounterFcnt": 4035,
    //     "latestFrameCounterFcnt": 4037,
    //     "lostFcnt": 935,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1900,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0863",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fb7",
    //     "appEUI": "app",
    //     "appKEY": "4C54D111D442CEE8095610C9FB982670",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 207,
    //     "prevFrameCounterFcnt": 2681,
    //     "latestFrameCounterFcnt": 2682,
    //     "lostFcnt": -191,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1901,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0864",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f70",
    //     "appEUI": "app",
    //     "appKEY": "6E8132940DDC146F80EE477B51495AFE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 205,
    //     "prevFrameCounterFcnt": 4033,
    //     "latestFrameCounterFcnt": 4034,
    //     "lostFcnt": 817,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1902,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0865",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f69",
    //     "appEUI": "app",
    //     "appKEY": "E67BB80F1C21D624E0FD7E17FDFDF81F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 207,
    //     "prevFrameCounterFcnt": 4035,
    //     "latestFrameCounterFcnt": 4036,
    //     "lostFcnt": 911,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1903,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0866",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f79",
    //     "appEUI": "app",
    //     "appKEY": "932BAF140BB17F6D6569F26FEBE6ACD5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 186,
    //     "prevFrameCounterFcnt": 4014,
    //     "latestFrameCounterFcnt": 4015,
    //     "lostFcnt": 755,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1904,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0867",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f6b",
    //     "appEUI": "app",
    //     "appKEY": "7FB83617C633276D2B590E545E23A318",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 207,
    //     "prevFrameCounterFcnt": 4035,
    //     "latestFrameCounterFcnt": 4036,
    //     "lostFcnt": 2436,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1905,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0868",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fc4",
    //     "appEUI": "app",
    //     "appKEY": "E65F0D399AAA323E31EB53198EF6330D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 206,
    //     "prevFrameCounterFcnt": 4031,
    //     "latestFrameCounterFcnt": 4034,
    //     "lostFcnt": 1297,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1906,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0869",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fcb",
    //     "appEUI": "app",
    //     "appKEY": "538220A15FBEAF3F068C0AAB03CB65CB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 208,
    //     "prevFrameCounterFcnt": 4035,
    //     "latestFrameCounterFcnt": 4036,
    //     "lostFcnt": 1531,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1907,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0870",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f83",
    //     "appEUI": "app",
    //     "appKEY": "B5C20420197E2080893F5E28518D736B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 206,
    //     "prevFrameCounterFcnt": 4034,
    //     "latestFrameCounterFcnt": 4035,
    //     "lostFcnt": 1752,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1908,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0871",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f75",
    //     "appEUI": "app",
    //     "appKEY": "3AD0E678C63E4BA8E69722F0C7D3AD08",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 207,
    //     "prevFrameCounterFcnt": 4035,
    //     "latestFrameCounterFcnt": 4036,
    //     "lostFcnt": 1097,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1909,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0872",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f7e",
    //     "appEUI": "app",
    //     "appKEY": "A965F2463C9E137875FB3C71B6836111",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 207,
    //     "prevFrameCounterFcnt": 4035,
    //     "latestFrameCounterFcnt": 4036,
    //     "lostFcnt": 910,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1910,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0873",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f68",
    //     "appEUI": "app",
    //     "appKEY": "5156C5FCFD869F02FD7617E5604D3F59",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 207,
    //     "prevFrameCounterFcnt": 4035,
    //     "latestFrameCounterFcnt": 4036,
    //     "lostFcnt": 1332,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1911,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0874",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f7c",
    //     "appEUI": "app",
    //     "appKEY": "658C6468C8D84D0149FF8D7BF5F50CA1",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 206,
    //     "prevFrameCounterFcnt": 4035,
    //     "latestFrameCounterFcnt": 4037,
    //     "lostFcnt": 834,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1912,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0875",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004245e",
    //     "appEUI": "app",
    //     "appKEY": "D01C5D99B14BC612C3EED111830116D8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4167,
    //     "latestFrameCounterFcnt": 4168,
    //     "lostFcnt": 1026,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1913,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0876",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fc6",
    //     "appEUI": "app",
    //     "appKEY": "D0FD08D2680FCC1C8F75E7C895CB6E9A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 4166,
    //     "latestFrameCounterFcnt": 4167,
    //     "lostFcnt": 1356,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1914,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0877",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fb1",
    //     "appEUI": "app",
    //     "appKEY": "DCBDBF819B225CDCDAA74CC23B98B208",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 4166,
    //     "latestFrameCounterFcnt": 4168,
    //     "lostFcnt": 874,
    //     "loraSNR": -11,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1915,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0878",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042478",
    //     "appEUI": "app",
    //     "appKEY": "7757D541F2EACC4C45E9B018F171BC81",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4167,
    //     "latestFrameCounterFcnt": 4168,
    //     "lostFcnt": 1639,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1916,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0879",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fb2",
    //     "appEUI": "app",
    //     "appKEY": "BA2A661ACE6B0E0BEEFE07DB60D2183B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 10,
    //     "prevFrameCounterFcnt": 4168,
    //     "latestFrameCounterFcnt": 4170,
    //     "lostFcnt": 1195,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1917,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0880",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fa5",
    //     "appEUI": "app",
    //     "appKEY": "CFF3F9E7512A8DC8E30216E9E04194D1",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4168,
    //     "latestFrameCounterFcnt": 4170,
    //     "lostFcnt": 922,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1918,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0881",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004245d",
    //     "appEUI": "app",
    //     "appKEY": "5E945FB54B4D9D3F6EAE6CAC996CD22C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4168,
    //     "latestFrameCounterFcnt": 4170,
    //     "lostFcnt": 1056,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1919,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0882",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042460",
    //     "appEUI": "app",
    //     "appKEY": "46463A22832EEF7DC664BA848340DFC3",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 4168,
    //     "latestFrameCounterFcnt": 4170,
    //     "lostFcnt": 1113,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1920,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0883",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fa7",
    //     "appEUI": "app",
    //     "appKEY": "D56631682CA13B67984AC81C17ECC07D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 10,
    //     "prevFrameCounterFcnt": 4168,
    //     "latestFrameCounterFcnt": 4170,
    //     "lostFcnt": 943,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1921,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0884",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fb5",
    //     "appEUI": "app",
    //     "appKEY": "5518670CEB328A449C835D41C9E9CFF7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4168,
    //     "latestFrameCounterFcnt": 4170,
    //     "lostFcnt": 919,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1922,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0885",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fb8",
    //     "appEUI": "app",
    //     "appKEY": "8E62BBD0F82CCE46CAD19896D0F0E9F2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 4164,
    //     "latestFrameCounterFcnt": 4168,
    //     "lostFcnt": 2114,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1923,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0886",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fa4",
    //     "appEUI": "app",
    //     "appKEY": "D86CF8D18CA53669FE4A27DC3983C7BC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4166,
    //     "latestFrameCounterFcnt": 4167,
    //     "lostFcnt": 859,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1924,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0887",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fa0",
    //     "appEUI": "app",
    //     "appKEY": "04AE75D6F1E7A070F795AC873AA89037",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4206,
    //     "latestFrameCounterFcnt": 4207,
    //     "lostFcnt": 752,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1925,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0888",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f67",
    //     "appEUI": "app",
    //     "appKEY": "B04F85A1B78C1BADFE4539D3FD52698D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4207,
    //     "latestFrameCounterFcnt": 4209,
    //     "lostFcnt": 884,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1926,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0889",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fc1",
    //     "appEUI": "app",
    //     "appKEY": "A4931E577144BA0A0A118D52041AFFFB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -116,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 4207,
    //     "latestFrameCounterFcnt": 4209,
    //     "lostFcnt": 769,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1927,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0890",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fce",
    //     "appEUI": "app",
    //     "appKEY": "5C5716969B77939A3E98084322020AAE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4206,
    //     "latestFrameCounterFcnt": 4207,
    //     "lostFcnt": 682,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1928,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0891",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fcc",
    //     "appEUI": "app",
    //     "appKEY": "31B52F960EAF12A66A38AEA80B174556",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -107,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4207,
    //     "latestFrameCounterFcnt": 4209,
    //     "lostFcnt": 646,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1929,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0892",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fc2",
    //     "appEUI": "app",
    //     "appKEY": "94E5864D7ED094CED9801253C239E6D6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -112,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4206,
    //     "latestFrameCounterFcnt": 4207,
    //     "lostFcnt": 699,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1930,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0893",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042faa",
    //     "appEUI": "app",
    //     "appKEY": "90538862F47B1904E4654F267E7E0461",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4206,
    //     "latestFrameCounterFcnt": 4207,
    //     "lostFcnt": 952,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1931,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0894",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042f9f",
    //     "appEUI": "app",
    //     "appKEY": "221E0A046BB866EDDE6AD4C33494C754",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4204,
    //     "latestFrameCounterFcnt": 4205,
    //     "lostFcnt": 882,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1932,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0895",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004245c",
    //     "appEUI": "app",
    //     "appKEY": "DC70B0D5AE3C8842ADF527EFFCDDDD45",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4159,
    //     "latestFrameCounterFcnt": 4160,
    //     "lostFcnt": 730,
    //     "loraSNR": -6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1933,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0896",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042454",
    //     "appEUI": "app",
    //     "appKEY": "B65056A3443BDF7E859B5ED17B569BA0",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 4207,
    //     "latestFrameCounterFcnt": 4209,
    //     "lostFcnt": 977,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1934,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0897",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fa8",
    //     "appEUI": "app",
    //     "appKEY": "F5789AD97597298FC94D29100E2ED404",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -114,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4207,
    //     "latestFrameCounterFcnt": 4209,
    //     "lostFcnt": 763,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1935,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0898",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fc8",
    //     "appEUI": "app",
    //     "appKEY": "CA49C11A8F3DD6ADE74EFD747CF6CA3B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4201,
    //     "latestFrameCounterFcnt": 4202,
    //     "lostFcnt": 882,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1936,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0899",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fca",
    //     "appEUI": "app",
    //     "appKEY": "5DD0BF9994FEDADFEEDDFB6FCA872F90",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4206,
    //     "latestFrameCounterFcnt": 4207,
    //     "lostFcnt": 893,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1937,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0900",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042453",
    //     "appEUI": "app",
    //     "appKEY": "FA7A5CC6315708A9E436C98E3CB81176",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4206,
    //     "latestFrameCounterFcnt": 4207,
    //     "lostFcnt": 860,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1938,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0901",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fb6",
    //     "appEUI": "app",
    //     "appKEY": "E71EECB057222527A14C11822225FAAB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -84,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 4207,
    //     "latestFrameCounterFcnt": 4208,
    //     "lostFcnt": 644,
    //     "loraSNR": 12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1939,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0902",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004245f",
    //     "appEUI": "app",
    //     "appKEY": "E93C8774225B87DC7CDD5F476C9B69D3",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4206,
    //     "latestFrameCounterFcnt": 4207,
    //     "lostFcnt": 843,
    //     "loraSNR": -13,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1940,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0903",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fa6",
    //     "appEUI": "app",
    //     "appKEY": "58183928BCAEBE6609611E6A3E955EC8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 1605,
    //     "latestFrameCounterFcnt": 1611,
    //     "lostFcnt": 820,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1941,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0904",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fc5",
    //     "appEUI": "app",
    //     "appKEY": "90A7D3689ACCC3635D3291320D6ED0B8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4206,
    //     "latestFrameCounterFcnt": 4207,
    //     "lostFcnt": 946,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1942,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0905",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fb4",
    //     "appEUI": "app",
    //     "appKEY": "17C0CC6E4A15CA319D17D4A9A1FAFF65",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 4206,
    //     "latestFrameCounterFcnt": 4207,
    //     "lostFcnt": 929,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1943,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0906",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fa3",
    //     "appEUI": "app",
    //     "appKEY": "447DDF10EE3B598520AB51FAC5A0DCA6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4206,
    //     "latestFrameCounterFcnt": 4207,
    //     "lostFcnt": 1103,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1944,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0907",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042faf",
    //     "appEUI": "app",
    //     "appKEY": "18C61FCBCC6E33D9B4AB2C6E74638C2F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4206,
    //     "latestFrameCounterFcnt": 4207,
    //     "lostFcnt": 913,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1945,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0908",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fa2",
    //     "appEUI": "app",
    //     "appKEY": "0DECE0C9135E3DAA9642500A7E2F4FA8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4207,
    //     "latestFrameCounterFcnt": 4209,
    //     "lostFcnt": 826,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1946,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0909",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004244f",
    //     "appEUI": "app",
    //     "appKEY": "A099F927BB8356A1DD5B134A833E15AD",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -116,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4206,
    //     "latestFrameCounterFcnt": 4207,
    //     "lostFcnt": 676,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1947,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0910",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fae",
    //     "appEUI": "app",
    //     "appKEY": "6825010CCA0F26F342C5AC72710DDDFD",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 4206,
    //     "latestFrameCounterFcnt": 4207,
    //     "lostFcnt": 979,
    //     "loraSNR": -17,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1948,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0911",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fac",
    //     "appEUI": "app",
    //     "appKEY": "689B7AAF5D8C475E5BE3D88E3C7E64DF",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4206,
    //     "latestFrameCounterFcnt": 4207,
    //     "lostFcnt": 842,
    //     "loraSNR": -3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1949,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0912",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fa9",
    //     "appEUI": "app",
    //     "appKEY": "8628800AFD9AC44A208E4EB9358AEC09",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4202,
    //     "latestFrameCounterFcnt": 4203,
    //     "lostFcnt": 990,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1950,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0913",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fb0",
    //     "appEUI": "app",
    //     "appKEY": "9FADD81133AAA81098827955CC4F25E3",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4206,
    //     "latestFrameCounterFcnt": 4207,
    //     "lostFcnt": 1252,
    //     "loraSNR": -15,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1951,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0914",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042fad",
    //     "appEUI": "app",
    //     "appKEY": "8878147256AC2854732A57441A47A048",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 4206,
    //     "latestFrameCounterFcnt": 4207,
    //     "lostFcnt": 858,
    //     "loraSNR": -12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1952,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0915",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042441",
    //     "appEUI": "app",
    //     "appKEY": "3F38E30226C42DBC014261F37E7435AE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -103,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 4216,
    //     "latestFrameCounterFcnt": 4218,
    //     "lostFcnt": 672,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1953,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0916",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042468",
    //     "appEUI": "app",
    //     "appKEY": "8153310F2290D522A83F53D2DF1AD2AB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -65,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 4215,
    //     "latestFrameCounterFcnt": 4216,
    //     "lostFcnt": 647,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1954,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0917",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042480",
    //     "appEUI": "app",
    //     "appKEY": "59FF6A90831736E79C62CAAE6460BCD4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 4215,
    //     "latestFrameCounterFcnt": 4216,
    //     "lostFcnt": 706,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1955,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0918",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042431",
    //     "appEUI": "app",
    //     "appKEY": "25E659FDBBC35C248C5ABE13C2352070",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -116,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 4216,
    //     "latestFrameCounterFcnt": 4217,
    //     "lostFcnt": 766,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1956,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0919",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004243b",
    //     "appEUI": "app",
    //     "appKEY": "A1801D772668A7B55699E45AC0858BEB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -89,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 4216,
    //     "latestFrameCounterFcnt": 4218,
    //     "lostFcnt": 646,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1957,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0920",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424bc",
    //     "appEUI": "app",
    //     "appKEY": "DFB7F9D6221F5046016FF66F9E86ABA3",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -103,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 4216,
    //     "latestFrameCounterFcnt": 4218,
    //     "lostFcnt": 649,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1958,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0921",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042439",
    //     "appEUI": "app",
    //     "appKEY": "8DBE99A432E0D67DA711522C58C58BD4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -90,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 18,
    //     "prevFrameCounterFcnt": 4215,
    //     "latestFrameCounterFcnt": 4216,
    //     "lostFcnt": 662,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1959,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0922",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042449",
    //     "appEUI": "app",
    //     "appKEY": "532BF9672F9FCFF1C9597A51AAA461FE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -87,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 4216,
    //     "latestFrameCounterFcnt": 4218,
    //     "lostFcnt": 639,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1960,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0923",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042440",
    //     "appEUI": "app",
    //     "appKEY": "7CB7D5B3812D57DD7F989F241A94B6C2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -92,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 4216,
    //     "latestFrameCounterFcnt": 4217,
    //     "lostFcnt": 645,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1961,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0924",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042432",
    //     "appEUI": "app",
    //     "appKEY": "CAB483D316EAFB6EE74AD48978436C6C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -83,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 4216,
    //     "latestFrameCounterFcnt": 4217,
    //     "lostFcnt": 647,
    //     "loraSNR": 12,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1962,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0925",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424b8",
    //     "appEUI": "app",
    //     "appKEY": "752B092C667458B1B29794D6EE79CC24",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 4217,
    //     "latestFrameCounterFcnt": 4218,
    //     "lostFcnt": 921,
    //     "loraSNR": -5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1963,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0926",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042464",
    //     "appEUI": "app",
    //     "appKEY": "B9B6C8F1D45AE46C1DD1F83608A51B5E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -92,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 4216,
    //     "latestFrameCounterFcnt": 4217,
    //     "lostFcnt": 651,
    //     "loraSNR": 10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1964,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0927",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004248d",
    //     "appEUI": "app",
    //     "appKEY": "669017DB7676D1130C7D1FB829EBE9DA",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -112,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 4216,
    //     "latestFrameCounterFcnt": 4218,
    //     "lostFcnt": 690,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1965,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0928",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004244a",
    //     "appEUI": "app",
    //     "appKEY": "551B896D8A394950095445A0E1FA0817",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -81,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 4215,
    //     "latestFrameCounterFcnt": 4216,
    //     "lostFcnt": 641,
    //     "loraSNR": 11,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1966,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0929",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004243a",
    //     "appEUI": "app",
    //     "appKEY": "46AD090D3F2E8CA9EC775204E1639C6E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 4216,
    //     "latestFrameCounterFcnt": 4217,
    //     "lostFcnt": 726,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1967,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0930",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004243f",
    //     "appEUI": "app",
    //     "appKEY": "D68596F6961F50658EE04610788D27BB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -116,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 18,
    //     "prevFrameCounterFcnt": 4213,
    //     "latestFrameCounterFcnt": 4214,
    //     "lostFcnt": 867,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1968,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0931",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004245a",
    //     "appEUI": "app",
    //     "appKEY": "5184BC410B8E315AC4C774D4CF0F8453",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -110,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 18,
    //     "prevFrameCounterFcnt": 4217,
    //     "latestFrameCounterFcnt": 4218,
    //     "lostFcnt": 723,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1969,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0932",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042455",
    //     "appEUI": "app",
    //     "appKEY": "0E61F4B597A003258ED6ED034BB90ED6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -99,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 4216,
    //     "latestFrameCounterFcnt": 4217,
    //     "lostFcnt": 659,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1970,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0933",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042430",
    //     "appEUI": "app",
    //     "appKEY": "C48C84F0D63B9120E0F8998A6D653E43",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -101,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 4216,
    //     "latestFrameCounterFcnt": 4217,
    //     "lostFcnt": 652,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1971,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0934",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042493",
    //     "appEUI": "app",
    //     "appKEY": "6A5FC6EF1228AEE3E3E8FD0A4FA366F4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 4217,
    //     "latestFrameCounterFcnt": 4218,
    //     "lostFcnt": 715,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1972,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0935",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042458",
    //     "appEUI": "app",
    //     "appKEY": "0751CF3CAFF9DE98C18510A045A2A1CD",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 4217,
    //     "latestFrameCounterFcnt": 4218,
    //     "lostFcnt": 1194,
    //     "loraSNR": -2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1973,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0936",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004245b",
    //     "appEUI": "app",
    //     "appKEY": "3EE319F2B16B37BC98ACB13924324BAC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -83,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 4214,
    //     "latestFrameCounterFcnt": 4216,
    //     "lostFcnt": 659,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1974,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0937",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042457",
    //     "appEUI": "app",
    //     "appKEY": "32FE9AE51F282CD4108DE0001F79E908",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -94,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 4216,
    //     "latestFrameCounterFcnt": 4217,
    //     "lostFcnt": 641,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1975,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0938",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042433",
    //     "appEUI": "app",
    //     "appKEY": "5A0791C6461DA47D999875F3B7140C38",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -112,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 18,
    //     "prevFrameCounterFcnt": 4218,
    //     "latestFrameCounterFcnt": 4220,
    //     "lostFcnt": 663,
    //     "loraSNR": 4,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1976,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0939",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042451",
    //     "appEUI": "app",
    //     "appKEY": "8178369E7E23DF3DE700882C03820B77",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 4222,
    //     "latestFrameCounterFcnt": 4223,
    //     "lostFcnt": 1236,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1977,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0940",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042437",
    //     "appEUI": "app",
    //     "appKEY": "31934311B2DEB1A2CA7D2E05351273E5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 4217,
    //     "latestFrameCounterFcnt": 4218,
    //     "lostFcnt": 673,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1978,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0941",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042450",
    //     "appEUI": "app",
    //     "appKEY": "BB1C02B4561DDB432130D195C3890D22",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -109,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 4216,
    //     "latestFrameCounterFcnt": 4217,
    //     "lostFcnt": 811,
    //     "loraSNR": 6,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1979,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0942",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042435",
    //     "appEUI": "app",
    //     "appKEY": "D1AC79B30E7DFABEC7281AB0445DEDA7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 4217,
    //     "latestFrameCounterFcnt": 4219,
    //     "lostFcnt": 898,
    //     "loraSNR": -10,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1980,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0943",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042445",
    //     "appEUI": "app",
    //     "appKEY": "91F3689E52E03B87BD0B4679D09A8800",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 4217,
    //     "latestFrameCounterFcnt": 4218,
    //     "lostFcnt": 1030,
    //     "loraSNR": -4,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1981,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0944",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004244b",
    //     "appEUI": "app",
    //     "appKEY": "2D1F0DD07DE1F5A56630AE3D43872C3C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -102,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 4216,
    //     "latestFrameCounterFcnt": 4217,
    //     "lostFcnt": 652,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1982,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0945",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004248c",
    //     "appEUI": "app",
    //     "appKEY": "48FBA8F4AA489B18C5313D71CD4F0940",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 4216,
    //     "latestFrameCounterFcnt": 4217,
    //     "lostFcnt": 734,
    //     "loraSNR": 2,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1983,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0946",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042462",
    //     "appEUI": "app",
    //     "appKEY": "A38E63FA92C061DE4E1DD2AE1AC3E767",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -114,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 18,
    //     "prevFrameCounterFcnt": 4218,
    //     "latestFrameCounterFcnt": 4220,
    //     "lostFcnt": 823,
    //     "loraSNR": 3,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1984,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0947",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042456",
    //     "appEUI": "app",
    //     "appKEY": "2C36F7B9D7ED450000E168C3BCC75FE5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -116,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 18,
    //     "prevFrameCounterFcnt": 4218,
    //     "latestFrameCounterFcnt": 4220,
    //     "lostFcnt": 865,
    //     "loraSNR": 1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1985,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0948",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042438",
    //     "appEUI": "app",
    //     "appKEY": "66ABCFEA3EB4E797F9D713FD3AB7AEC7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 4216,
    //     "latestFrameCounterFcnt": 4217,
    //     "lostFcnt": 800,
    //     "loraSNR": -1,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1986,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0949",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042459",
    //     "appEUI": "app",
    //     "appKEY": "E2F4AFEF66599C499446FDFAAA519550",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -90,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 4215,
    //     "latestFrameCounterFcnt": 4216,
    //     "lostFcnt": 672,
    //     "loraSNR": 8,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1987,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0950",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004244e",
    //     "appEUI": "app",
    //     "appKEY": "24F372E238E23D59295E582ED23A129B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -107,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 4216,
    //     "latestFrameCounterFcnt": 4217,
    //     "lostFcnt": 642,
    //     "loraSNR": 7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1988,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0951",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042492",
    //     "appEUI": "app",
    //     "appKEY": "D781E1E24AC9008BFF385FEB10323F0C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 4215,
    //     "latestFrameCounterFcnt": 4216,
    //     "lostFcnt": 920,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1989,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0952",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042436",
    //     "appEUI": "app",
    //     "appKEY": "735980430A43F1F8F9257433334F33EC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 18,
    //     "prevFrameCounterFcnt": 4218,
    //     "latestFrameCounterFcnt": 4220,
    //     "lostFcnt": 707,
    //     "loraSNR": 5,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1990,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0953",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042446",
    //     "appEUI": "app",
    //     "appKEY": "2ED3613740575C5710A810315C30ED0E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -84,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 4216,
    //     "latestFrameCounterFcnt": 4217,
    //     "lostFcnt": 648,
    //     "loraSNR": 9,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1991,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0954",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004243d",
    //     "appEUI": "app",
    //     "appKEY": "EED0C945552F8E9A6EC9452C64668F21",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 4217,
    //     "latestFrameCounterFcnt": 4218,
    //     "lostFcnt": 1207,
    //     "loraSNR": -7,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1992,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0955",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042491",
    //     "appEUI": "app",
    //     "appKEY": "8726049CFD7881A58C6E3594A7EB34CC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 119,
    //     "prevFrameCounterFcnt": 3947,
    //     "latestFrameCounterFcnt": 3948,
    //     "lostFcnt": 1122,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1993,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0956",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004248e",
    //     "appEUI": "app",
    //     "appKEY": "65727B2B235767388D56F390B888AC4F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 119,
    //     "prevFrameCounterFcnt": 1083,
    //     "latestFrameCounterFcnt": 1084,
    //     "lostFcnt": -190,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1994,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0957",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200430a1",
    //     "appEUI": "app",
    //     "appKEY": "CB45311E497AB5B76CAAD60DF941FD5E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 1995,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0958",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042434",
    //     "appEUI": "app",
    //     "appKEY": "80E2B35AE0E1E92CE4221E59F4EA2793",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 3745,
    //     "latestFrameCounterFcnt": 3746,
    //     "lostFcnt": 722,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1996,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0959",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004244c",
    //     "appEUI": "app",
    //     "appKEY": "46D023EEAC461DD94878CF28A4A5663E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 2902,
    //     "latestFrameCounterFcnt": 2903,
    //     "lostFcnt": 599,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1997,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0960",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004247d",
    //     "appEUI": "app",
    //     "appKEY": "EDC8823D98C043B75028B3E987191074",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 2902,
    //     "latestFrameCounterFcnt": 2903,
    //     "lostFcnt": 1076,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1998,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0961",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042472",
    //     "appEUI": "app",
    //     "appKEY": "E4CEE69FB2C552596B8B2BC5DFC49761",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 3764,
    //     "latestFrameCounterFcnt": 3765,
    //     "lostFcnt": 780,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 1999,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0962",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004248a",
    //     "appEUI": "app",
    //     "appKEY": "AAD7606369CEA4EC53652234E5D6DC21",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 3708,
    //     "latestFrameCounterFcnt": 3709,
    //     "lostFcnt": 921,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2000,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0963",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004243e",
    //     "appEUI": "app",
    //     "appKEY": "DFE70C3243B02F5BF5D96BB079E43F2E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 2768,
    //     "latestFrameCounterFcnt": 2770,
    //     "lostFcnt": 851,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2001,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0964",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042448",
    //     "appEUI": "app",
    //     "appKEY": "057F1B5A70C6382C76E8037CBB4AC074",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 109,
    //     "prevFrameCounterFcnt": 3937,
    //     "latestFrameCounterFcnt": 3938,
    //     "lostFcnt": 756,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2002,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0965",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042481",
    //     "appEUI": "app",
    //     "appKEY": "EECE61E985EDCBC41E0E192EAD04B98B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 128,
    //     "prevFrameCounterFcnt": 3956,
    //     "latestFrameCounterFcnt": 3957,
    //     "lostFcnt": 1041,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2003,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0966",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042452",
    //     "appEUI": "app",
    //     "appKEY": "2C065B1E8B3720F379903A217A840D6B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 2756,
    //     "latestFrameCounterFcnt": 2757,
    //     "lostFcnt": 868,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2004,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0967",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042447",
    //     "appEUI": "app",
    //     "appKEY": "4FC91DA06B31718107717CD129E30221",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 120,
    //     "prevFrameCounterFcnt": 3948,
    //     "latestFrameCounterFcnt": 3949,
    //     "lostFcnt": 966,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2005,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0968",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004248b",
    //     "appEUI": "app",
    //     "appKEY": "C5101DFE71A8E9F3F38C9C855C71E125",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 119,
    //     "prevFrameCounterFcnt": 3947,
    //     "latestFrameCounterFcnt": 3948,
    //     "lostFcnt": 729,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2006,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0969",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004243c",
    //     "appEUI": "app",
    //     "appKEY": "8C15A87B4AA28AC471F9202B504AEC48",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 128,
    //     "prevFrameCounterFcnt": 3956,
    //     "latestFrameCounterFcnt": 3957,
    //     "lostFcnt": 627,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2007,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0970",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042487",
    //     "appEUI": "app",
    //     "appKEY": "7FE9F93A44E560FB76D301A7A40AC328",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 129,
    //     "prevFrameCounterFcnt": 3950,
    //     "latestFrameCounterFcnt": 3952,
    //     "lostFcnt": 1431,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2008,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0971",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004247f",
    //     "appEUI": "app",
    //     "appKEY": "3BC68101AF4E25491533A4E35DF92197",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 119,
    //     "prevFrameCounterFcnt": 3947,
    //     "latestFrameCounterFcnt": 3948,
    //     "lostFcnt": 1208,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2009,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0972",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042490",
    //     "appEUI": "app",
    //     "appKEY": "FE997F1C693AF5D82B4BEC9225536A4B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 119,
    //     "prevFrameCounterFcnt": 3948,
    //     "latestFrameCounterFcnt": 3950,
    //     "lostFcnt": 1377,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2010,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0973",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004244d",
    //     "appEUI": "app",
    //     "appKEY": "555612415465B5B2911E7F1131D7BAE8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 127,
    //     "prevFrameCounterFcnt": 3956,
    //     "latestFrameCounterFcnt": 3958,
    //     "lostFcnt": 734,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2011,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0974",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042442",
    //     "appEUI": "app",
    //     "appKEY": "5D8AD2CB533D963FF402B34742AE1D28",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 3408,
    //     "latestFrameCounterFcnt": 3410,
    //     "lostFcnt": 1346,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2012,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0975",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004247b",
    //     "appEUI": "app",
    //     "appKEY": "EFEE611DE36FA25A11D836B1954FC208",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 129,
    //     "prevFrameCounterFcnt": 3942,
    //     "latestFrameCounterFcnt": 3947,
    //     "lostFcnt": 1681,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2013,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0976",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042483",
    //     "appEUI": "app",
    //     "appKEY": "B297EE134AC40F7BB1BC59E0E5E9897B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 130,
    //     "prevFrameCounterFcnt": 3958,
    //     "latestFrameCounterFcnt": 3959,
    //     "lostFcnt": 694,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2014,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0977",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004248f",
    //     "appEUI": "app",
    //     "appKEY": "B0387B553E067B58367F2400540AC891",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 139,
    //     "prevFrameCounterFcnt": 685,
    //     "latestFrameCounterFcnt": 686,
    //     "lostFcnt": -889,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2015,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0978",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042465",
    //     "appEUI": "app",
    //     "appKEY": "D5F0DF5E55DC619CFC1C7F750AD9D3EC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 130,
    //     "prevFrameCounterFcnt": 3958,
    //     "latestFrameCounterFcnt": 3959,
    //     "lostFcnt": 799,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2016,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0979",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042469",
    //     "appEUI": "app",
    //     "appKEY": "511450C6D15743F9516C648365880565",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 137,
    //     "prevFrameCounterFcnt": 3965,
    //     "latestFrameCounterFcnt": 3966,
    //     "lostFcnt": 1300,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2017,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0980",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042463",
    //     "appEUI": "app",
    //     "appKEY": "F22E6AD477E3BDFABDA0D01F79421AD4",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 137,
    //     "prevFrameCounterFcnt": 3965,
    //     "latestFrameCounterFcnt": 3966,
    //     "lostFcnt": 1557,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2018,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0981",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424b6",
    //     "appEUI": "app",
    //     "appKEY": "4709611A848E03FF92050D97ECE030D6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 137,
    //     "prevFrameCounterFcnt": 3965,
    //     "latestFrameCounterFcnt": 3966,
    //     "lostFcnt": 1573,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2019,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0982",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004246f",
    //     "appEUI": "app",
    //     "appKEY": "CAD139A46AD85AEFD3F64417202FC9DD",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 137,
    //     "prevFrameCounterFcnt": 3965,
    //     "latestFrameCounterFcnt": 3966,
    //     "lostFcnt": 1831,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2020,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0983",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042443",
    //     "appEUI": "app",
    //     "appKEY": "C46FA977B941B0C8C3EB4BB1EE17743B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 129,
    //     "prevFrameCounterFcnt": 3957,
    //     "latestFrameCounterFcnt": 3958,
    //     "lostFcnt": 651,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2021,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0984",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042466",
    //     "appEUI": "app",
    //     "appKEY": "F4384653B3B517CF6BC35F85F3E3CC9A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 50,
    //     "prevFrameCounterFcnt": 3857,
    //     "latestFrameCounterFcnt": 3859,
    //     "lostFcnt": 666,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2022,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0985",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042482",
    //     "appEUI": "app",
    //     "appKEY": "6B604528D1F9330D48BA8D826C1A1356",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 120,
    //     "prevFrameCounterFcnt": 3948,
    //     "latestFrameCounterFcnt": 3949,
    //     "lostFcnt": 1083,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2023,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0986",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004247c",
    //     "appEUI": "app",
    //     "appKEY": "B965650F539DEE459A16F01B8FBDD205",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 122,
    //     "prevFrameCounterFcnt": 3946,
    //     "latestFrameCounterFcnt": 3948,
    //     "lostFcnt": 1740,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2024,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0987",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004246e",
    //     "appEUI": "app",
    //     "appKEY": "3DEF4F3ACF75F7BEAB6CEC6DDBDC8848",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 120,
    //     "prevFrameCounterFcnt": 3948,
    //     "latestFrameCounterFcnt": 3949,
    //     "lostFcnt": 603,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2025,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0988",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004246a",
    //     "appEUI": "app",
    //     "appKEY": "6DB8BCBACCDD061FF8F322B528A409F5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 137,
    //     "prevFrameCounterFcnt": 3962,
    //     "latestFrameCounterFcnt": 3963,
    //     "lostFcnt": 1544,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2026,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0989",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042488",
    //     "appEUI": "app",
    //     "appKEY": "C87AF7038D88E306B8F876536A3E3066",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 129,
    //     "prevFrameCounterFcnt": 3957,
    //     "latestFrameCounterFcnt": 3958,
    //     "lostFcnt": 669,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2027,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0990",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004247e",
    //     "appEUI": "app",
    //     "appKEY": "BBD42DA5EC33B0E25D07853476E287D9",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 128,
    //     "prevFrameCounterFcnt": 3956,
    //     "latestFrameCounterFcnt": 3957,
    //     "lostFcnt": 1058,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2028,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0991",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042489",
    //     "appEUI": "app",
    //     "appKEY": "A1F0912CF1EEF4A63654B5CF859D8AF3",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 120,
    //     "prevFrameCounterFcnt": 3948,
    //     "latestFrameCounterFcnt": 3949,
    //     "lostFcnt": 759,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2029,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0992",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042467",
    //     "appEUI": "app",
    //     "appKEY": "DF57A1FD6D8D14902578BA225039B275",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 137,
    //     "prevFrameCounterFcnt": 3965,
    //     "latestFrameCounterFcnt": 3966,
    //     "lostFcnt": 1943,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2030,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0993",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042444",
    //     "appEUI": "app",
    //     "appKEY": "09347357A2D370799A52BB660E92B2E5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 128,
    //     "prevFrameCounterFcnt": 3955,
    //     "latestFrameCounterFcnt": 3957,
    //     "lostFcnt": 831,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2031,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0994",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424c1",
    //     "appEUI": "app",
    //     "appKEY": "431618C57E2068B8A4B19AD9237419D2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 138,
    //     "prevFrameCounterFcnt": 53,
    //     "latestFrameCounterFcnt": 56,
    //     "lostFcnt": -1522,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2032,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0995",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042474",
    //     "appEUI": "app",
    //     "appKEY": "A18C35D6448BEEA6AC4D6E2A9B112614",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 139,
    //     "prevFrameCounterFcnt": 3967,
    //     "latestFrameCounterFcnt": 3968,
    //     "lostFcnt": 1343,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2033,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0996",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424be",
    //     "appEUI": "app",
    //     "appKEY": "776CF91527B4D6E31551DCA8A2CE4DB3",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 138,
    //     "prevFrameCounterFcnt": 3966,
    //     "latestFrameCounterFcnt": 3967,
    //     "lostFcnt": 1534,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2034,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0997",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042473",
    //     "appEUI": "app",
    //     "appKEY": "D8DBD4683E35624B4C0DE792D8FA3669",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 138,
    //     "prevFrameCounterFcnt": 3965,
    //     "latestFrameCounterFcnt": 3967,
    //     "lostFcnt": 1373,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2035,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0998",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042485",
    //     "appEUI": "app",
    //     "appKEY": "0BB4A87A6E55428063B5C1AC7AC8AFAD",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 139,
    //     "prevFrameCounterFcnt": 3967,
    //     "latestFrameCounterFcnt": 3968,
    //     "lostFcnt": 1690,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2036,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC0999",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424bf",
    //     "appEUI": "app",
    //     "appKEY": "4CD0D5951C92E32F85A430C0583ABB72",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 137,
    //     "prevFrameCounterFcnt": 3965,
    //     "latestFrameCounterFcnt": 3966,
    //     "lostFcnt": 1071,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2037,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1000",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004246d",
    //     "appEUI": "app",
    //     "appKEY": "447EAABD954F63B83D7D6E960E60AE27",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 138,
    //     "prevFrameCounterFcnt": 3966,
    //     "latestFrameCounterFcnt": 3967,
    //     "lostFcnt": 1477,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2038,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1001",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004246b",
    //     "appEUI": "app",
    //     "appKEY": "FDDA12B8D92E6FE3071CB6DDAA47B2F5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 138,
    //     "prevFrameCounterFcnt": 3967,
    //     "latestFrameCounterFcnt": 3969,
    //     "lostFcnt": 1993,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2039,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1002",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424c3",
    //     "appEUI": "app",
    //     "appKEY": "1816124564F8B3CF01BCD6F28A4B15E7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 129,
    //     "prevFrameCounterFcnt": 3957,
    //     "latestFrameCounterFcnt": 3958,
    //     "lostFcnt": 674,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2040,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1003",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424bb",
    //     "appEUI": "app",
    //     "appKEY": "948DB712EA25C9294ED12717C24F27D5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 137,
    //     "prevFrameCounterFcnt": 3966,
    //     "latestFrameCounterFcnt": 3968,
    //     "lostFcnt": 1463,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2041,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1004",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004246c",
    //     "appEUI": "app",
    //     "appKEY": "43FDBDBE37EC045EE1A0070E9837D800",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 127,
    //     "prevFrameCounterFcnt": 3956,
    //     "latestFrameCounterFcnt": 3958,
    //     "lostFcnt": 746,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2042,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1005",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424b3",
    //     "appEUI": "app",
    //     "appKEY": "2FF55C1539DA7B9F011D61CA255D11D0",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 137,
    //     "prevFrameCounterFcnt": 3966,
    //     "latestFrameCounterFcnt": 3968,
    //     "lostFcnt": 1220,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2043,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1006",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424c2",
    //     "appEUI": "app",
    //     "appKEY": "3589C024ECC6CBA5F4E51D59AB7FA672",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 130,
    //     "prevFrameCounterFcnt": 3958,
    //     "latestFrameCounterFcnt": 3959,
    //     "lostFcnt": 768,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2044,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1007",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424bd",
    //     "appEUI": "app",
    //     "appKEY": "87F23A6E0C9CF90E1831A8397CBB45AC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 129,
    //     "prevFrameCounterFcnt": 3957,
    //     "latestFrameCounterFcnt": 3958,
    //     "lostFcnt": 1557,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2045,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1008",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042470",
    //     "appEUI": "app",
    //     "appKEY": "DBA20A6A9F1A8F234E7954AED8627FB5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 137,
    //     "prevFrameCounterFcnt": 3966,
    //     "latestFrameCounterFcnt": 3968,
    //     "lostFcnt": 1177,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2046,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1009",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042479",
    //     "appEUI": "app",
    //     "appKEY": "E4E7C74B483B5FDE650A478BF9F3E8F2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 137,
    //     "prevFrameCounterFcnt": 3899,
    //     "latestFrameCounterFcnt": 3900,
    //     "lostFcnt": 1456,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2047,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1010",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424c0",
    //     "appEUI": "app",
    //     "appKEY": "8E98B9D39F10D91E59C089F9685B33C6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 130,
    //     "prevFrameCounterFcnt": 3958,
    //     "latestFrameCounterFcnt": 3959,
    //     "lostFcnt": 859,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2048,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1011",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042471",
    //     "appEUI": "app",
    //     "appKEY": "E8BE6405F4440C248378AA08C1FAE48F",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 90,
    //     "prevFrameCounterFcnt": 3897,
    //     "latestFrameCounterFcnt": 3898,
    //     "lostFcnt": 2570,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2049,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1012",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424b9",
    //     "appEUI": "app",
    //     "appKEY": "2C1996962F319B98275C36A73FB08885",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 137,
    //     "prevFrameCounterFcnt": 3965,
    //     "latestFrameCounterFcnt": 3966,
    //     "lostFcnt": 1253,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2050,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1013",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042484",
    //     "appEUI": "app",
    //     "appKEY": "EAE73001AC11F0F5E2CC7F530CED992E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 129,
    //     "prevFrameCounterFcnt": 3957,
    //     "latestFrameCounterFcnt": 3958,
    //     "lostFcnt": 642,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2051,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1014",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d72004247a",
    //     "appEUI": "app",
    //     "appKEY": "05D6526FF1BC8EFE5C4DE611B78867EB",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 137,
    //     "prevFrameCounterFcnt": 3966,
    //     "latestFrameCounterFcnt": 3968,
    //     "lostFcnt": 1185,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2052,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1015",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424af",
    //     "appEUI": "app",
    //     "appKEY": "8FDA5196523948CC3573B74DB2C85F3B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 139,
    //     "prevFrameCounterFcnt": 3967,
    //     "latestFrameCounterFcnt": 3968,
    //     "lostFcnt": 856,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2053,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1016",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042476",
    //     "appEUI": "app",
    //     "appKEY": "096A5F63AABC50FD89804CAFD84FBD18",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 3,
    //     "latestFrameCounterFcnt": 13,
    //     "lostFcnt": -67,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2054,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1017",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424ba",
    //     "appEUI": "app",
    //     "appKEY": "9BD5AE0CB02B4198B6CAF1B560C6D863",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 139,
    //     "prevFrameCounterFcnt": 3967,
    //     "latestFrameCounterFcnt": 3968,
    //     "lostFcnt": 1357,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2055,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1018",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042495",
    //     "appEUI": "app",
    //     "appKEY": "89121BFFB890D1B93748E8DEB7B11FF9",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 142,
    //     "prevFrameCounterFcnt": 3939,
    //     "latestFrameCounterFcnt": 3941,
    //     "lostFcnt": 1519,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2056,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1019",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042477",
    //     "appEUI": "app",
    //     "appKEY": "22CBD18D41B8EA497EE5B2BCF1BD0542",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 145,
    //     "prevFrameCounterFcnt": 362,
    //     "latestFrameCounterFcnt": 363,
    //     "lostFcnt": -91,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2057,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1020",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424b2",
    //     "appEUI": "app",
    //     "appKEY": "1F6F9634124678F89B66EA5B89F43A9D",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 4005,
    //     "latestFrameCounterFcnt": 4006,
    //     "lostFcnt": 1386,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2058,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1021",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042475",
    //     "appEUI": "app",
    //     "appKEY": "864B0B61DEC49030B97E9C1E324D497B",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 176,
    //     "prevFrameCounterFcnt": 4004,
    //     "latestFrameCounterFcnt": 4005,
    //     "lostFcnt": 1458,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2059,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1022",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424c4",
    //     "appEUI": "app",
    //     "appKEY": "C59BE98A004AD484F28B5A878F0A5A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 176,
    //     "prevFrameCounterFcnt": 4004,
    //     "latestFrameCounterFcnt": 4005,
    //     "lostFcnt": 1102,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2060,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1023",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424ad",
    //     "appEUI": "app",
    //     "appKEY": "D0405B813520EC422BD4E43AE29C3A60",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 128,
    //     "prevFrameCounterFcnt": 3956,
    //     "latestFrameCounterFcnt": 3957,
    //     "lostFcnt": 1173,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2061,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1024",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424ae",
    //     "appEUI": "app",
    //     "appKEY": "0E9CDD589EFB8624EDD2F53082890BF6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 139,
    //     "prevFrameCounterFcnt": 3968,
    //     "latestFrameCounterFcnt": 3970,
    //     "lostFcnt": 1216,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2062,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1025",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424b4",
    //     "appEUI": "app",
    //     "appKEY": "ED4BDE2D03329F699D57BF5FDFDECA77",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 139,
    //     "prevFrameCounterFcnt": 3967,
    //     "latestFrameCounterFcnt": 3968,
    //     "lostFcnt": 1371,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2063,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1026",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424b1",
    //     "appEUI": "app",
    //     "appKEY": "37B620B0B3B2C9C8A106106DFB46F2FC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 4005,
    //     "latestFrameCounterFcnt": 4006,
    //     "lostFcnt": 1229,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2064,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1027",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042496",
    //     "appEUI": "app",
    //     "appKEY": "CB4DED0D8ECF839BE852420FDEC4ABE6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 3941,
    //     "latestFrameCounterFcnt": 3942,
    //     "lostFcnt": 1424,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2065,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1028",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042494",
    //     "appEUI": "app",
    //     "appKEY": "4C9A35B06D22FA146591016ACF94F9D6",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 4004,
    //     "latestFrameCounterFcnt": 4005,
    //     "lostFcnt": 954,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2066,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1029",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424b5",
    //     "appEUI": "app",
    //     "appKEY": "20823088C9540C47A376830D6FCBEEB7",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 4005,
    //     "latestFrameCounterFcnt": 4006,
    //     "lostFcnt": 1360,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2067,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1030",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424b0",
    //     "appEUI": "app",
    //     "appKEY": "193344B13A0CE999CF1DD47CD46DCB88",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 177,
    //     "prevFrameCounterFcnt": 4005,
    //     "latestFrameCounterFcnt": 4006,
    //     "lostFcnt": 1172,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2068,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1031",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d720042497",
    //     "appEUI": "app",
    //     "appKEY": "72AC8A2A9086C3400BDA54512DC3CDF1",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 176,
    //     "prevFrameCounterFcnt": 4005,
    //     "latestFrameCounterFcnt": 4007,
    //     "lostFcnt": 845,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2069,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1032",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424b7",
    //     "appEUI": "app",
    //     "appKEY": "FADFAE49332C825E169F94F0E4928560",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 176,
    //     "prevFrameCounterFcnt": 4004,
    //     "latestFrameCounterFcnt": 4005,
    //     "lostFcnt": 1216,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2070,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1033",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "70b3d5d7200424c5",
    //     "appEUI": "app",
    //     "appKEY": "F6E76C8CF227CD6FDBCC0746CD9305CC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 139,
    //     "prevFrameCounterFcnt": 3967,
    //     "latestFrameCounterFcnt": 3968,
    //     "lostFcnt": 1487,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2071,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1034",
    //     "description": "GARCIA LORCA, 2 BAJO",
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "e41e0a90000019c6",
    //     "appEUI": "1",
    //     "appKEY": "BBB89C92863E944612E163811948BD2A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 686,
    //     "latestFrameCounterFcnt": 687,
    //     "lostFcnt": 142,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2072,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1035",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "e41e0a90000019db",
    //     "appEUI": "app",
    //     "appKEY": "10985FD2A7309B441BA13A5E51D6B863",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2073,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1036",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "e41e0a90000014c4",
    //     "appEUI": "app",
    //     "appKEY": "F624EC99B3620D4519E2DC713584372E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2074,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1037",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "e41e0a9000000d75",
    //     "appEUI": "app",
    //     "appKEY": "4CC8CD62B1269B465251077A04230230",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2075,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1038",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "e41e0a900000179e",
    //     "appEUI": "app",
    //     "appKEY": "C8E85EF26C3D9F4526413C03C00C6FD9",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2076,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1039",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "e41e0a90000012eb",
    //     "appEUI": "app",
    //     "appKEY": "09247059536B08471E623CF521C868E8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2077,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1040",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "e41e0a90000008da",
    //     "appEUI": "app",
    //     "appKEY": "6FC4FBE9325807416482014B25218302",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2078,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1041",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "e41e0a9000000908",
    //     "appEUI": "app",
    //     "appKEY": "47F488B95B5F01476E521ADBB0CD605A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2079,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1042",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "e41e0a90000017da",
    //     "appEUI": "app",
    //     "appKEY": "8B8895822C34944210316EF1E596E0A2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2080,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1043",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "e41e0a9000000d85",
    //     "appEUI": "app",
    //     "appKEY": "EEE87AA2030C9C449CB1AA6E894AA7F5",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2081,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1044",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "e41e0a90000017db",
    //     "appEUI": "app",
    //     "appKEY": "E4A8A002D73591462A413AF100E1178A",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2082,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1045",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "e41e0a9000001324",
    //     "appEUI": "app",
    //     "appKEY": "CC08ABD2D12E96464731D368B6B780E8",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2083,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1046",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "e41e0a900000196c",
    //     "appEUI": "app",
    //     "appKEY": "2328E552003E9C411A41741A91BC1E9C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2084,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1047",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "e41e0a9000000e51",
    //     "appEUI": "app",
    //     "appKEY": "E534C0992A530F4566A25690C664EB9C",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2085,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1048",
    //     "description": "MESTRE GINER, 8 BAJO",
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "e41e0a9000001362",
    //     "appEUI": "app",
    //     "appKEY": "96D4B6F9DB570E428D62C7FFB6397954",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2086,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1049",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "e41e0a900000132b",
    //     "appEUI": "app",
    //     "appKEY": "A5B83D72582891494191EA9C65EE62EC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2087,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1050",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "e41e0a9000001319",
    //     "appEUI": "app",
    //     "appKEY": "B9C86DB2A92094484AB1B2AD95DCCCCE",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2088,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1051",
    //     "description": "GARCIA LORCA, 13 BAJO",
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "e41e0a90000009b6",
    //     "appEUI": "app",
    //     "appKEY": "84D89CD2F42D92437F61B8B8652577EC",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 686,
    //     "latestFrameCounterFcnt": 687,
    //     "lostFcnt": 118,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb4b07fe",
    //     "messageTime": null
    // },
    // {
    //     "id": 2089,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1052",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "e41e0a90000014e9",
    //     "appEUI": "app",
    //     "appKEY": "29D406192951074877C2BDEA4943C0E2",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2090,
    //     "gatewaysId": null,
    //     "name": "WaterExternaQUAGWTC1053",
    //     "description": null,
    //     "provider": null,
    //     "userId": 40,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-10-27",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "e41e0a9000001331",
    //     "appEUI": "app",
    //     "appKEY": "6CD816023021934445A1872A0517C19E",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb4b07fe",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2508,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC01",
    //     "description": "90DFFB81872871DE",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871de",
    //     "appEUI": "2",
    //     "appKEY": "B6857B3CA3061432154D05C77CEA6BF0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 2,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 79,
    //     "prevFrameCounterFcnt": 317,
    //     "latestFrameCounterFcnt": 319,
    //     "lostFcnt": -327,
    //     "loraSNR": -16,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2509,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC02",
    //     "description": "90DFFB81872871DF",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871df",
    //     "appEUI": "2",
    //     "appKEY": "68941F02E42850AC260A3E3C0C21732D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 3,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 1092,
    //     "latestFrameCounterFcnt": 1093,
    //     "lostFcnt": 546,
    //     "loraSNR": -12,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2510,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC03",
    //     "description": "90DFFB81872871E0",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871e0",
    //     "appEUI": "2",
    //     "appKEY": "A0A23C23A9821607F6090C6CF7A1EDCB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 3,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 84,
    //     "prevFrameCounterFcnt": 1085,
    //     "latestFrameCounterFcnt": 1089,
    //     "lostFcnt": 612,
    //     "loraSNR": -13,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2511,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC04",
    //     "description": "90DFFB81872871E1",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871e1",
    //     "appEUI": "2",
    //     "appKEY": "36A135DFF7332C4BA1FD1B23C4859DCC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 10,
    //     "prevFrameCounterFcnt": 1050,
    //     "latestFrameCounterFcnt": 1052,
    //     "lostFcnt": 307,
    //     "loraSNR": -1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2512,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC05",
    //     "description": "90DFFB81872871E3",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871e3",
    //     "appEUI": "2",
    //     "appKEY": "CE8CB1DEA8E6F6894FAA2DE9E849AB71",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 10,
    //     "prevFrameCounterFcnt": 1044,
    //     "latestFrameCounterFcnt": 1045,
    //     "lostFcnt": 419,
    //     "loraSNR": -7,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2513,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC06",
    //     "description": "90DFFB81872871E4",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871e4",
    //     "appEUI": "2",
    //     "appKEY": "553D2F02C4F09CCC485A3FEA2A4B731A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 2,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 79,
    //     "prevFrameCounterFcnt": 1077,
    //     "latestFrameCounterFcnt": 1078,
    //     "lostFcnt": 648,
    //     "loraSNR": -13,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2514,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC07",
    //     "description": "90DFFB81872871E5",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871e5",
    //     "appEUI": "2",
    //     "appKEY": "E3C1788AF23BE86CE7E0ABAD67A9ADCD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 4,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 86,
    //     "prevFrameCounterFcnt": 1122,
    //     "latestFrameCounterFcnt": 1123,
    //     "lostFcnt": 466,
    //     "loraSNR": -8,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2515,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC08",
    //     "description": "90DFFB81872871E6",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871e6",
    //     "appEUI": "2",
    //     "appKEY": "719CC6A7BFD7EB0C6D93FE26798278A5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 2,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1105,
    //     "latestFrameCounterFcnt": 1107,
    //     "lostFcnt": 415,
    //     "loraSNR": -13,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2516,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC09",
    //     "description": "90DFFB81872871E9",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871e9",
    //     "appEUI": "2",
    //     "appKEY": "3E73E32647FD22D305E815FFEF5D4011",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 92,
    //     "prevFrameCounterFcnt": 1161,
    //     "latestFrameCounterFcnt": 1162,
    //     "lostFcnt": 296,
    //     "loraSNR": -7,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2517,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC10",
    //     "description": "90DFFB81872871EA",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871ea",
    //     "appEUI": "2",
    //     "appKEY": "76DF08C632934A6CA7D6674EFAD2493D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 2,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 729,
    //     "latestFrameCounterFcnt": 730,
    //     "lostFcnt": 326,
    //     "loraSNR": -10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2518,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC100",
    //     "description": "90DFFB8187286DC4",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286dc4",
    //     "appEUI": "2",
    //     "appKEY": "783D3F6E5BB6C96F76A83F7682BF6051",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 59,
    //     "prevFrameCounterFcnt": 129,
    //     "latestFrameCounterFcnt": 131,
    //     "lostFcnt": 33,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2519,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC101",
    //     "description": "90DFFB8187286D95",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286d95",
    //     "appEUI": "2",
    //     "appKEY": "F55F868C1D329EA55C7A0B5CF588A0C6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 89,
    //     "prevFrameCounterFcnt": 1154,
    //     "latestFrameCounterFcnt": 1155,
    //     "lostFcnt": 242,
    //     "loraSNR": -4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2520,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC102",
    //     "description": "90DFFB818728717B",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728717b",
    //     "appEUI": "2",
    //     "appKEY": "6F4500AF87713F4B703173AD161BD193",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 3,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 97,
    //     "prevFrameCounterFcnt": 1138,
    //     "latestFrameCounterFcnt": 1139,
    //     "lostFcnt": 418,
    //     "loraSNR": -10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2521,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC103",
    //     "description": "90DFFB818728717Conso",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728717c",
    //     "appEUI": "2",
    //     "appKEY": "9CD47D61411A5AC0CDE796CB7C68E490",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -91,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1077,
    //     "latestFrameCounterFcnt": 1078,
    //     "lostFcnt": 170,
    //     "loraSNR": 9,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2522,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC104",
    //     "description": "90DFFB818728717D",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728717d",
    //     "appEUI": "2",
    //     "appKEY": "AF230F1278A1401C16EDA283DEE0DD48",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 86,
    //     "prevFrameCounterFcnt": 312,
    //     "latestFrameCounterFcnt": 313,
    //     "lostFcnt": -248,
    //     "loraSNR": -19,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2523,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC105",
    //     "description": "90DFFB818728717E",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728717e",
    //     "appEUI": "2",
    //     "appKEY": "FBAF63B0D35C3EDDFF8E9AD542806A15",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 89,
    //     "prevFrameCounterFcnt": 1169,
    //     "latestFrameCounterFcnt": 1170,
    //     "lostFcnt": 291,
    //     "loraSNR": -1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2524,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC106",
    //     "description": "90DFFB818728717F",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728717f",
    //     "appEUI": "2",
    //     "appKEY": "67639D69808040E9D733527EF60264B3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 93,
    //     "prevFrameCounterFcnt": 407,
    //     "latestFrameCounterFcnt": 408,
    //     "lostFcnt": 71,
    //     "loraSNR": -17,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2525,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC107",
    //     "description": "90DFFB8187287180",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287180",
    //     "appEUI": "2",
    //     "appKEY": "FEB9B7F9B13285C09945DABC3A475814",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 4,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1163,
    //     "latestFrameCounterFcnt": 1164,
    //     "lostFcnt": 318,
    //     "loraSNR": -5,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2526,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC108",
    //     "description": "90DFFB8187287181",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287181",
    //     "appEUI": "2",
    //     "appKEY": "2A4DF8E8F15BEC640D705E55F9676DD2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -102,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1080,
    //     "latestFrameCounterFcnt": 1081,
    //     "lostFcnt": 151,
    //     "loraSNR": 11,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2527,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC109",
    //     "description": "90DFFB8187287183",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287183",
    //     "appEUI": "2",
    //     "appKEY": "089FAD426BB6FCA8D1A4EB12CF3DAFCD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 83,
    //     "prevFrameCounterFcnt": 1128,
    //     "latestFrameCounterFcnt": 1129,
    //     "lostFcnt": 266,
    //     "loraSNR": -1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2528,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC11",
    //     "description": "90DFFB81872871EB",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871eb",
    //     "appEUI": "2",
    //     "appKEY": "E11088D453A926993A5AC45AC15097AF",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 10,
    //     "prevFrameCounterFcnt": 1067,
    //     "latestFrameCounterFcnt": 1068,
    //     "lostFcnt": 203,
    //     "loraSNR": 4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2529,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC110",
    //     "description": "90DFFB8187287184",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287184",
    //     "appEUI": "2",
    //     "appKEY": "3BF369211413DB5F36B0388C9F5B3512",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -101,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 12,
    //     "prevFrameCounterFcnt": 1006,
    //     "latestFrameCounterFcnt": 1007,
    //     "lostFcnt": 144,
    //     "loraSNR": 11,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2530,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC111",
    //     "description": "90DFFB8187287185",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287185",
    //     "appEUI": "2",
    //     "appKEY": "8A40C48B68485CCEAAF0112CD5827AB3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 1066,
    //     "latestFrameCounterFcnt": 1067,
    //     "lostFcnt": 280,
    //     "loraSNR": -1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2531,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC112",
    //     "description": "90DFFB8187287186",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287186",
    //     "appEUI": "2",
    //     "appKEY": "CBEF1CB71BCCBC806E28FCA954CF33AA",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 84,
    //     "prevFrameCounterFcnt": 1149,
    //     "latestFrameCounterFcnt": 1150,
    //     "lostFcnt": 216,
    //     "loraSNR": -1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2532,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC113",
    //     "description": "90DFFB8187287187",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287187",
    //     "appEUI": "2",
    //     "appKEY": "959C9AE24BAE7E57C39A03254F300659",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 3,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 86,
    //     "prevFrameCounterFcnt": 1113,
    //     "latestFrameCounterFcnt": 1114,
    //     "lostFcnt": 411,
    //     "loraSNR": 0,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2533,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC114",
    //     "description": "90DFFB8187287188",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287188",
    //     "appEUI": "2",
    //     "appKEY": "92D2DCDE51CFCE468D1F82118BBE8B87",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 86,
    //     "prevFrameCounterFcnt": 1138,
    //     "latestFrameCounterFcnt": 1139,
    //     "lostFcnt": 245,
    //     "loraSNR": -2,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2534,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC115",
    //     "description": "90DFFB8187287189",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287189",
    //     "appEUI": "2",
    //     "appKEY": "0724D6FB3FC83244625E45B0330C79C6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1154,
    //     "latestFrameCounterFcnt": 1156,
    //     "lostFcnt": 285,
    //     "loraSNR": -5,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2535,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC116",
    //     "description": "90DFFB818728718C",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728718c",
    //     "appEUI": "2",
    //     "appKEY": "A0DACD982D1EC0823821E530ED94B173",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1117,
    //     "latestFrameCounterFcnt": 1118,
    //     "lostFcnt": 282,
    //     "loraSNR": 0,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2536,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC117",
    //     "description": "90DFFB818728718D",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728718d",
    //     "appEUI": "2",
    //     "appKEY": "C7ED80B2CB75D26BB7364497318A710B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 89,
    //     "prevFrameCounterFcnt": 1171,
    //     "latestFrameCounterFcnt": 1172,
    //     "lostFcnt": 293,
    //     "loraSNR": -3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2537,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC118",
    //     "description": "90DFFB818728718E",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728718e",
    //     "appEUI": "2",
    //     "appKEY": "FA9657EBB862BE9BBA54F859A4FA0315",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 88,
    //     "prevFrameCounterFcnt": 1178,
    //     "latestFrameCounterFcnt": 1179,
    //     "lostFcnt": 297,
    //     "loraSNR": -1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2538,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC119",
    //     "description": "90DFFB818728718F",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728718f",
    //     "appEUI": "2",
    //     "appKEY": "370A1FFBE23887CBA927F600E21ADFBA",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 83,
    //     "prevFrameCounterFcnt": 1164,
    //     "latestFrameCounterFcnt": 1165,
    //     "lostFcnt": 235,
    //     "loraSNR": 1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2539,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC12",
    //     "description": "90DFFB81872871EC",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871ec",
    //     "appEUI": "2",
    //     "appKEY": "49EEA38D731811828C33A512C90DE858",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 1121,
    //     "latestFrameCounterFcnt": 1122,
    //     "lostFcnt": 263,
    //     "loraSNR": -5,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2540,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC120",
    //     "description": "90DFFB8187287191",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287191",
    //     "appEUI": "2",
    //     "appKEY": "DEDFCD5026205758D0C602E27D48D898",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 89,
    //     "prevFrameCounterFcnt": 1156,
    //     "latestFrameCounterFcnt": 1157,
    //     "lostFcnt": 271,
    //     "loraSNR": -4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2541,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC121",
    //     "description": "90DFFB8187287192",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287192",
    //     "appEUI": "2",
    //     "appKEY": "1E7F8B6CCD8A068FD70E07B55363CEB7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -94,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1092,
    //     "latestFrameCounterFcnt": 1093,
    //     "lostFcnt": 160,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2542,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC122",
    //     "description": "90DFFB8187287196",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287196",
    //     "appEUI": "2",
    //     "appKEY": "EC571770BA7330C99F471C1E97222583",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -93,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 79,
    //     "prevFrameCounterFcnt": 1079,
    //     "latestFrameCounterFcnt": 1080,
    //     "lostFcnt": 158,
    //     "loraSNR": 8,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2543,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC123",
    //     "description": "90DFFB8187287197",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287197",
    //     "appEUI": "2",
    //     "appKEY": "1C2BD8596AA1BE2C1C85002EC24AA3FD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -106,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1097,
    //     "latestFrameCounterFcnt": 1098,
    //     "lostFcnt": 189,
    //     "loraSNR": 9,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2544,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC124",
    //     "description": "90DFFB818728728C",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728728c",
    //     "appEUI": "2",
    //     "appKEY": "8E77BD876C3DA6EFCCFCB3C1000AF6B4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -99,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1080,
    //     "latestFrameCounterFcnt": 1081,
    //     "lostFcnt": 152,
    //     "loraSNR": 8,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2545,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC125",
    //     "description": "90DFFB8187287296",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287296",
    //     "appEUI": "2",
    //     "appKEY": "810E117F1B119818DA654C641A7E0EB3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 1167,
    //     "latestFrameCounterFcnt": 1168,
    //     "lostFcnt": 270,
    //     "loraSNR": 3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2546,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC126",
    //     "description": "90DFFB8187280518",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187280518",
    //     "appEUI": "2",
    //     "appKEY": "240DE26DECBD71D3689221B734F15782",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -75,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1079,
    //     "latestFrameCounterFcnt": 1080,
    //     "lostFcnt": 152,
    //     "loraSNR": 9,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2547,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC127",
    //     "description": "90DFFB81872844C0",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872844c0",
    //     "appEUI": "2",
    //     "appKEY": "D5A7D6E32DD46EFAE917E59F748C21D0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -124,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 83,
    //     "prevFrameCounterFcnt": 1134,
    //     "latestFrameCounterFcnt": 1135,
    //     "lostFcnt": 358,
    //     "loraSNR": -6,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2548,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC128",
    //     "description": "90DFFB818728711F",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728711f",
    //     "appEUI": "2",
    //     "appKEY": "A29C8C19E352FF604E0EA18F0A469D06",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 86,
    //     "prevFrameCounterFcnt": 1169,
    //     "latestFrameCounterFcnt": 1170,
    //     "lostFcnt": 210,
    //     "loraSNR": 4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2549,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC129",
    //     "description": "90DFFB8187287120",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287120",
    //     "appEUI": "2",
    //     "appKEY": "A6496A834A78B9131AD13DF50943BDE2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 87,
    //     "prevFrameCounterFcnt": 1158,
    //     "latestFrameCounterFcnt": 1159,
    //     "lostFcnt": 212,
    //     "loraSNR": 2,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2550,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC13",
    //     "description": "90DFFB81872871ED",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871ed",
    //     "appEUI": "2",
    //     "appKEY": "AC7025C17B8F433487B54C03C59E4474",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 3,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 52,
    //     "prevFrameCounterFcnt": 874,
    //     "latestFrameCounterFcnt": 875,
    //     "lostFcnt": 385,
    //     "loraSNR": -13,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2551,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC130",
    //     "description": "90DFFB8187287121",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287121",
    //     "appEUI": "2",
    //     "appKEY": "AEFD0A000B8AAB12869C6CF7F70BEEC2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1138,
    //     "latestFrameCounterFcnt": 1139,
    //     "lostFcnt": 220,
    //     "loraSNR": -4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2552,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC131",
    //     "description": "90DFFB8187287122",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287122",
    //     "appEUI": "2",
    //     "appKEY": "DE48D5357DBA2F91BA9F613DE38E997C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -91,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 521,
    //     "latestFrameCounterFcnt": 522,
    //     "lostFcnt": -403,
    //     "loraSNR": 8,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2553,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC132",
    //     "description": "90DFFB8187287123",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287123",
    //     "appEUI": "2",
    //     "appKEY": "68873F6A0A864BAF48D3DF02E6C7F9C7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1105,
    //     "latestFrameCounterFcnt": 1106,
    //     "lostFcnt": 183,
    //     "loraSNR": 4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2554,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC133",
    //     "description": "90DFFB8187287124",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287124",
    //     "appEUI": "2",
    //     "appKEY": "ED4F6EE9E56E9C4032C24863B75D0FEA",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -112,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 11,
    //     "prevFrameCounterFcnt": 1037,
    //     "latestFrameCounterFcnt": 1038,
    //     "lostFcnt": 167,
    //     "loraSNR": 6,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2555,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC134",
    //     "description": "90DFFB8187287125",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287125",
    //     "appEUI": "2",
    //     "appKEY": "10786CC7760304326FC39C85C8345F04",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 93,
    //     "prevFrameCounterFcnt": 1177,
    //     "latestFrameCounterFcnt": 1179,
    //     "lostFcnt": 265,
    //     "loraSNR": 1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2556,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC135",
    //     "description": "90DFFB8187287126",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287126",
    //     "appEUI": "2",
    //     "appKEY": "47960213F18FBD29C8E7982269AB5EF0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 1,
    //     "latestFrameCounterFcnt": 1,
    //     "lostFcnt": 0,
    //     "loraSNR": -18,
    //     "networkServerMac": "b827eb7a5c58",
    //     "messageTime": null
    // },
    // {
    //     "id": 2557,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC136",
    //     "description": "90DFFB8187287127",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287127",
    //     "appEUI": "2",
    //     "appKEY": "623CC9A21DCE76B1C239CABBDA6AD7F0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1099,
    //     "latestFrameCounterFcnt": 1100,
    //     "lostFcnt": 179,
    //     "loraSNR": 6,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2558,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC137",
    //     "description": "90DFFB8187287128",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287128",
    //     "appEUI": "2",
    //     "appKEY": "227AF03DD01A026BAD429B2BC6CC6E06",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 86,
    //     "prevFrameCounterFcnt": 1173,
    //     "latestFrameCounterFcnt": 1174,
    //     "lostFcnt": 220,
    //     "loraSNR": 4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2559,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC138",
    //     "description": "90DFFB8187287129",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287129",
    //     "appEUI": "2",
    //     "appKEY": "07FCEE015CF39999817BAC9CE289FF04",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 4,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 86,
    //     "prevFrameCounterFcnt": 1119,
    //     "latestFrameCounterFcnt": 1120,
    //     "lostFcnt": 540,
    //     "loraSNR": -10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2560,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC139",
    //     "description": "90DFFB818728713A",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728713a",
    //     "appEUI": "2",
    //     "appKEY": "91F9F4AB1CF50DE19EE324FFB64B458B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -107,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1086,
    //     "latestFrameCounterFcnt": 1087,
    //     "lostFcnt": 181,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2561,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC14",
    //     "description": "90DFFB81872871EE",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871ee",
    //     "appEUI": "2",
    //     "appKEY": "CDFDB05211A0FF503486C32A080D7848",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 86,
    //     "prevFrameCounterFcnt": 1136,
    //     "latestFrameCounterFcnt": 1137,
    //     "lostFcnt": 310,
    //     "loraSNR": -6,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2562,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC140",
    //     "description": "90DFFB8187287164",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287164",
    //     "appEUI": "2",
    //     "appKEY": "648A38DD69A28BE422D92AFAA34D8238",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 1,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 86,
    //     "prevFrameCounterFcnt": 47,
    //     "latestFrameCounterFcnt": 103,
    //     "lostFcnt": -428,
    //     "loraSNR": -18,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2563,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC141",
    //     "description": "90DFFB8187287171",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287171",
    //     "appEUI": "2",
    //     "appKEY": "A058B7584609FC8F81144A40105B45CE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 87,
    //     "prevFrameCounterFcnt": 1129,
    //     "latestFrameCounterFcnt": 1130,
    //     "lostFcnt": 301,
    //     "loraSNR": 3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2564,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC142",
    //     "description": "90DFFB8187287173",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287173",
    //     "appEUI": "2",
    //     "appKEY": "704968E9A91303E5AE3C3BA1ED11817B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 2,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 986,
    //     "latestFrameCounterFcnt": 987,
    //     "lostFcnt": 351,
    //     "loraSNR": -16,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2565,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC143",
    //     "description": "90DFFB8187287174",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287174",
    //     "appEUI": "2",
    //     "appKEY": "9A0FB6FD420C590D10C1AA20E671DDF3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 79,
    //     "prevFrameCounterFcnt": 361,
    //     "latestFrameCounterFcnt": 362,
    //     "lostFcnt": -236,
    //     "loraSNR": -15,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2566,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC144",
    //     "description": "90DFFB8187287175",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287175",
    //     "appEUI": "2",
    //     "appKEY": "F98986B84D7C6D0AC775A489B0989210",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 1082,
    //     "latestFrameCounterFcnt": 1084,
    //     "lostFcnt": 296,
    //     "loraSNR": 0,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2567,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC145",
    //     "description": "90DFFB8187287176",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287176",
    //     "appEUI": "2",
    //     "appKEY": "3163032A827764FBCC358F47F2213553",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 10,
    //     "prevFrameCounterFcnt": 1069,
    //     "latestFrameCounterFcnt": 1070,
    //     "lostFcnt": 222,
    //     "loraSNR": -1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2568,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC146",
    //     "description": "90DFFB8187287177",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287177",
    //     "appEUI": "2",
    //     "appKEY": "04730791F9E2A279AB58E6E8CE473AB0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -108,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 1086,
    //     "latestFrameCounterFcnt": 1087,
    //     "lostFcnt": 157,
    //     "loraSNR": 7,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2569,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC147",
    //     "description": "90DFFB8187287178",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287178",
    //     "appEUI": "2",
    //     "appKEY": "03A58BC8D126C0F9FA22928E73211752",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 88,
    //     "prevFrameCounterFcnt": 1138,
    //     "latestFrameCounterFcnt": 1139,
    //     "lostFcnt": 250,
    //     "loraSNR": 3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2570,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC148",
    //     "description": "90DFFB8187287179",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287179",
    //     "appEUI": "2",
    //     "appKEY": "1ED2203C6BA58CF78B177E142318DECE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 1132,
    //     "latestFrameCounterFcnt": 1133,
    //     "lostFcnt": 252,
    //     "loraSNR": 3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2571,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC149",
    //     "description": "90DFFB818728717A",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728717a",
    //     "appEUI": "2",
    //     "appKEY": "D5579EAA55FF966ACE76FD21995D13E6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 89,
    //     "prevFrameCounterFcnt": 1138,
    //     "latestFrameCounterFcnt": 1139,
    //     "lostFcnt": 344,
    //     "loraSNR": -5,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2572,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC15",
    //     "description": "90DFFB81872871EF",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871ef",
    //     "appEUI": "2",
    //     "appKEY": "AD732D79032C2FB2A98E679D302B3ED7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 92,
    //     "prevFrameCounterFcnt": 1134,
    //     "latestFrameCounterFcnt": 1135,
    //     "lostFcnt": 178,
    //     "loraSNR": 3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2573,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC150",
    //     "description": "90DFFB8187287182",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287182",
    //     "appEUI": "2",
    //     "appKEY": "2185DD84F96B3C307AF2BB50F4F572C0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2574,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC151",
    //     "description": "90DFFB81872842D5",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872842d5",
    //     "appEUI": "2",
    //     "appKEY": "7CE351F3B09D1D26FBDF1FDEED5B87A4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 83,
    //     "prevFrameCounterFcnt": 1135,
    //     "latestFrameCounterFcnt": 1136,
    //     "lostFcnt": 287,
    //     "loraSNR": 1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2575,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC152",
    //     "description": "90DFFB81872842D9",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872842d9",
    //     "appEUI": "2",
    //     "appKEY": "4A013E03F5D459EC5E4BD382BFE3EBED",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -100,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 90,
    //     "prevFrameCounterFcnt": 1096,
    //     "latestFrameCounterFcnt": 1097,
    //     "lostFcnt": 157,
    //     "loraSNR": 8,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2576,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC153",
    //     "description": "90DFFB81872842DA",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872842da",
    //     "appEUI": "2",
    //     "appKEY": "8133A483854157A0E1AC76178EAF53BD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -95,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 1032,
    //     "latestFrameCounterFcnt": 1033,
    //     "lostFcnt": 142,
    //     "loraSNR": 8,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2577,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC154",
    //     "description": "90DFFB81872842DB",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872842db",
    //     "appEUI": "2",
    //     "appKEY": "14180CF8C5910035E6261EEF3CA9E20D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 83,
    //     "prevFrameCounterFcnt": 1154,
    //     "latestFrameCounterFcnt": 1155,
    //     "lostFcnt": 277,
    //     "loraSNR": -2,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2578,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC155",
    //     "description": "90DFFB81872843CC",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872843cc",
    //     "appEUI": "2",
    //     "appKEY": "5D8AEDF92E509483E1AB7FCE25C88A84",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 515,
    //     "latestFrameCounterFcnt": 516,
    //     "lostFcnt": -97,
    //     "loraSNR": -6,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2579,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC156",
    //     "description": "90DFFB818728638B",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728638b",
    //     "appEUI": "2",
    //     "appKEY": "B668260D7BB1C5CE37D9E4BC0C1E7D56",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 130,
    //     "latestFrameCounterFcnt": 131,
    //     "lostFcnt": -263,
    //     "loraSNR": -19,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2580,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC157",
    //     "description": "90DFFB8187286391",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286391",
    //     "appEUI": "2",
    //     "appKEY": "467DF85A7B7299445F2834EA7FDA354A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1137,
    //     "latestFrameCounterFcnt": 1145,
    //     "lostFcnt": 231,
    //     "loraSNR": -6,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2581,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC158",
    //     "description": "90DFFB8187286399",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286399",
    //     "appEUI": "2",
    //     "appKEY": "2CA7A796949091D536E0890A6EB23949",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 1,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 134,
    //     "latestFrameCounterFcnt": 135,
    //     "lostFcnt": -295,
    //     "loraSNR": -12,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2582,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC159",
    //     "description": "90DFFB818728639C",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728639c",
    //     "appEUI": "2",
    //     "appKEY": "5CF2C235E46753DA61E798BDB89059FE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -85,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1077,
    //     "latestFrameCounterFcnt": 1078,
    //     "lostFcnt": 152,
    //     "loraSNR": 8,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2583,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC16",
    //     "description": "90DFFB81872871F0",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871f0",
    //     "appEUI": "2",
    //     "appKEY": "92ED96D8B8BCE4AD807173C2BC62ED39",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 3,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1089,
    //     "latestFrameCounterFcnt": 1090,
    //     "lostFcnt": 486,
    //     "loraSNR": -10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2584,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC160",
    //     "description": "90DFFB8187286861",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286861",
    //     "appEUI": "2",
    //     "appKEY": "FF96E66BAC14A5FC59C1C0E0856896F2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 93,
    //     "prevFrameCounterFcnt": 1165,
    //     "latestFrameCounterFcnt": 1166,
    //     "lostFcnt": 237,
    //     "loraSNR": 3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2585,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC161",
    //     "description": "90DFFB8187286C42",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286c42",
    //     "appEUI": "2",
    //     "appKEY": "42BCA9A537FB1805813839E9C33E6891",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 3,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 84,
    //     "prevFrameCounterFcnt": 1167,
    //     "latestFrameCounterFcnt": 1168,
    //     "lostFcnt": 243,
    //     "loraSNR": -9,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2586,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC162",
    //     "description": "90DFFB8187286C48",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286c48",
    //     "appEUI": "2",
    //     "appKEY": "64A64B0B1748A58E5A0A3E5C7BB12BA5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -93,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1071,
    //     "latestFrameCounterFcnt": 1072,
    //     "lostFcnt": 161,
    //     "loraSNR": 9,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2587,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC163",
    //     "description": "90DFFB8187286C51",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286c51",
    //     "appEUI": "2",
    //     "appKEY": "281B0ADB98180A12BA6FD58E0A20630E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 4,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1120,
    //     "latestFrameCounterFcnt": 1121,
    //     "lostFcnt": 256,
    //     "loraSNR": -9,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2588,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC164",
    //     "description": "90DFFB8187286ECE",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286ece",
    //     "appEUI": "2",
    //     "appKEY": "AF4DC2B13B214BF8CFC36C58572AB222",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -89,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1075,
    //     "latestFrameCounterFcnt": 1077,
    //     "lostFcnt": 158,
    //     "loraSNR": 11,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2589,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC165",
    //     "description": "90DFFB8187286F08",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286f08",
    //     "appEUI": "2",
    //     "appKEY": "6050030326456E6A289735CD217D1984",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1159,
    //     "latestFrameCounterFcnt": 1160,
    //     "lostFcnt": 291,
    //     "loraSNR": 2,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2590,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC166",
    //     "description": "90DFFB8187286F09",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286f09",
    //     "appEUI": "2",
    //     "appKEY": "93DEB2CF624F6824B0313E381F88A4F7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -81,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1080,
    //     "latestFrameCounterFcnt": 1081,
    //     "lostFcnt": 153,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2591,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC167",
    //     "description": "90DFFB8187286F0B",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286f0b",
    //     "appEUI": "2",
    //     "appKEY": "CB0CACD9A8086C91F397518B3E60FF3B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -78,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1079,
    //     "latestFrameCounterFcnt": 1080,
    //     "lostFcnt": 153,
    //     "loraSNR": 11,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2592,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC168",
    //     "description": "90DFFB8187286F0D",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286f0d",
    //     "appEUI": "2",
    //     "appKEY": "FBBB5B61864095FFD330246D4DE80BF7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -88,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1075,
    //     "latestFrameCounterFcnt": 1076,
    //     "lostFcnt": 166,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2593,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC169",
    //     "description": "90DFFB8187286F45",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286f45",
    //     "appEUI": "2",
    //     "appKEY": "D6D454EAB37159202FE00DC8DA28B149",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 86,
    //     "prevFrameCounterFcnt": 1146,
    //     "latestFrameCounterFcnt": 1147,
    //     "lostFcnt": 208,
    //     "loraSNR": 2,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2594,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC17",
    //     "description": "90DFFB81872871F1",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871f1",
    //     "appEUI": "2",
    //     "appKEY": "05E131A6BFCFBF62649C74536C4BEC83",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 2,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1118,
    //     "latestFrameCounterFcnt": 1119,
    //     "lostFcnt": 361,
    //     "loraSNR": -10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2595,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC170",
    //     "description": "90DFFB8187286F48",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286f48",
    //     "appEUI": "2",
    //     "appKEY": "968F5274248B8998B33CD58B67804413",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -95,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1072,
    //     "latestFrameCounterFcnt": 1073,
    //     "lostFcnt": 167,
    //     "loraSNR": 8,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2596,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC171",
    //     "description": "90DFFB8187286F49",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286f49",
    //     "appEUI": "2",
    //     "appKEY": "3AB0BA92468213062B84F88606C7C6B4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1130,
    //     "latestFrameCounterFcnt": 1131,
    //     "lostFcnt": 303,
    //     "loraSNR": -1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2597,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC172",
    //     "description": "90DFFB8187286F4C",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286f4c",
    //     "appEUI": "2",
    //     "appKEY": "488515AB8BE6364098EC738020576E5F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1157,
    //     "latestFrameCounterFcnt": 1158,
    //     "lostFcnt": 298,
    //     "loraSNR": -6,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2598,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC173",
    //     "description": "90DFFB81872871D3",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871d3",
    //     "appEUI": "2",
    //     "appKEY": "E0EC9BC0D92205173CF4A439D1DC590F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1162,
    //     "latestFrameCounterFcnt": 1163,
    //     "lostFcnt": 284,
    //     "loraSNR": -5,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2599,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC174",
    //     "description": "90DFFB81872871D8",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871d8",
    //     "appEUI": "2",
    //     "appKEY": "BFD8EBF337492DCB78F8F3FA262E26CA",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 84,
    //     "prevFrameCounterFcnt": 1148,
    //     "latestFrameCounterFcnt": 1149,
    //     "lostFcnt": 249,
    //     "loraSNR": -3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2600,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC175",
    //     "description": "90DFFB81872871DB",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871db",
    //     "appEUI": "2",
    //     "appKEY": "6F581411BA397902EEC36F9641BF166C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 1129,
    //     "latestFrameCounterFcnt": 1130,
    //     "lostFcnt": 303,
    //     "loraSNR": -1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2601,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC176",
    //     "description": "90DFFB8187280507",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187280507",
    //     "appEUI": "2",
    //     "appKEY": "B00D6A05A36BB899B17094949B0FAF35",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1131,
    //     "latestFrameCounterFcnt": 1132,
    //     "lostFcnt": 197,
    //     "loraSNR": 1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2602,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC177",
    //     "description": "90DFFB818728050A",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728050a",
    //     "appEUI": "2",
    //     "appKEY": "FF33CD551696B1A653B190B3AF5C3EBD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 1058,
    //     "latestFrameCounterFcnt": 1059,
    //     "lostFcnt": 290,
    //     "loraSNR": 0,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2603,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC178",
    //     "description": "90DFFB818728050B",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728050b",
    //     "appEUI": "2",
    //     "appKEY": "C1D159EAC94D000112A29206D72F02D4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -122,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 86,
    //     "prevFrameCounterFcnt": 1138,
    //     "latestFrameCounterFcnt": 1139,
    //     "lostFcnt": 274,
    //     "loraSNR": -3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2604,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC179",
    //     "description": "90DFFB818728050C",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728050c",
    //     "appEUI": "2",
    //     "appKEY": "2C0E1148C06DA1D8D8DA13900717D97F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1131,
    //     "latestFrameCounterFcnt": 1132,
    //     "lostFcnt": 232,
    //     "loraSNR": -1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2605,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC18",
    //     "description": "90DFFB81872871F2",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871f2",
    //     "appEUI": "2",
    //     "appKEY": "E56D1627CBC0ACB97BB90DF99E62F018",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1154,
    //     "latestFrameCounterFcnt": 1155,
    //     "lostFcnt": 226,
    //     "loraSNR": -4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2606,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC180",
    //     "description": "90DFFB8187280515",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187280515",
    //     "appEUI": "2",
    //     "appKEY": "584DBEE4BF82DD27CADBBF1D522D6D3A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1142,
    //     "latestFrameCounterFcnt": 1143,
    //     "lostFcnt": 233,
    //     "loraSNR": 3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2607,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC181",
    //     "description": "90DFFB818728051D",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728051d",
    //     "appEUI": "2",
    //     "appKEY": "1E0AFD5084516AF2E79133128A624F6A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 89,
    //     "prevFrameCounterFcnt": 1129,
    //     "latestFrameCounterFcnt": 1130,
    //     "lostFcnt": 196,
    //     "loraSNR": -3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2608,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC182",
    //     "description": "90DFFB818728051F",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728051f",
    //     "appEUI": "2",
    //     "appKEY": "AC9588F8BA144121688D37D5DC81C374",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 84,
    //     "prevFrameCounterFcnt": 1153,
    //     "latestFrameCounterFcnt": 1154,
    //     "lostFcnt": 219,
    //     "loraSNR": -5,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2609,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC183",
    //     "description": "90DFFB81872829D0",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872829d0",
    //     "appEUI": "2",
    //     "appKEY": "A02BB462D422B1CCC9EA650A3DB8BC0D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2610,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC184",
    //     "description": "90DFFB81872829D6",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872829d6",
    //     "appEUI": "2",
    //     "appKEY": "7F0EBFF60C5933146DDF59A44DFBCF39",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 12,
    //     "prevFrameCounterFcnt": 1076,
    //     "latestFrameCounterFcnt": 1077,
    //     "lostFcnt": 205,
    //     "loraSNR": -3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2611,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC185",
    //     "description": "90DFFB81872829D7",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872829d7",
    //     "appEUI": "2",
    //     "appKEY": "55B340CA256AF27E3F215D3CD8D10BCD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 11,
    //     "prevFrameCounterFcnt": 1057,
    //     "latestFrameCounterFcnt": 1058,
    //     "lostFcnt": 180,
    //     "loraSNR": -1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2612,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC186",
    //     "description": "90DFFB8187282E08",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187282e08",
    //     "appEUI": "2",
    //     "appKEY": "22CB22193525728FC8659828159AE13F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 86,
    //     "prevFrameCounterFcnt": 1143,
    //     "latestFrameCounterFcnt": 1144,
    //     "lostFcnt": 235,
    //     "loraSNR": -3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2613,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC187",
    //     "description": "90DFFB81872843C9",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872843c9",
    //     "appEUI": "2",
    //     "appKEY": "4A91A2CC7F3AC02B73EE97CBCAD9E01A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 92,
    //     "prevFrameCounterFcnt": 1159,
    //     "latestFrameCounterFcnt": 1160,
    //     "lostFcnt": 261,
    //     "loraSNR": 1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2614,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC188",
    //     "description": "90DFFB81872843CE",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872843ce",
    //     "appEUI": "2",
    //     "appKEY": "847F51C5F65174F46C366D5361BDF9A6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 10,
    //     "prevFrameCounterFcnt": 1082,
    //     "latestFrameCounterFcnt": 1083,
    //     "lostFcnt": 242,
    //     "loraSNR": -9,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2615,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC189",
    //     "description": "90DFFB81872843F2",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872843f2",
    //     "appEUI": "2",
    //     "appKEY": "32B4806F50560FB8FFDE8622F1CCC4E8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 89,
    //     "prevFrameCounterFcnt": 1115,
    //     "latestFrameCounterFcnt": 1117,
    //     "lostFcnt": 323,
    //     "loraSNR": -6,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2616,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC19",
    //     "description": "90DFFB81872871F3",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871f3",
    //     "appEUI": "2",
    //     "appKEY": "3194730FE4B7F27A2F88D852429885EC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1157,
    //     "latestFrameCounterFcnt": 1158,
    //     "lostFcnt": 296,
    //     "loraSNR": -1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2617,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC190",
    //     "description": "90DFFB81872844BA",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872844ba",
    //     "appEUI": "2",
    //     "appKEY": "520AC6F5E0BDC1F06B5291EDB25F81B8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -116,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1152,
    //     "latestFrameCounterFcnt": 1153,
    //     "lostFcnt": 207,
    //     "loraSNR": 4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2618,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC191",
    //     "description": "90DFFB818728495F",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728495f",
    //     "appEUI": "2",
    //     "appKEY": "25291ECD4C91365F2AAC44F51897FBE7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 1164,
    //     "latestFrameCounterFcnt": 1165,
    //     "lostFcnt": 262,
    //     "loraSNR": 2,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2619,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC192",
    //     "description": "90DFFB8187284961",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187284961",
    //     "appEUI": "2",
    //     "appKEY": "BE52A38874671705AE1B6C35E3C8621E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1145,
    //     "latestFrameCounterFcnt": 1146,
    //     "lostFcnt": 257,
    //     "loraSNR": 2,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2620,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC193",
    //     "description": "90DFFB8187286859",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286859",
    //     "appEUI": "2",
    //     "appKEY": "7E23D6E60FCD3696F0E1DDD7557AA32C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 87,
    //     "prevFrameCounterFcnt": 1160,
    //     "latestFrameCounterFcnt": 1161,
    //     "lostFcnt": 246,
    //     "loraSNR": -1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2621,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC194",
    //     "description": "90DFFB818728685A",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728685a",
    //     "appEUI": "2",
    //     "appKEY": "8955D57E6D88ACC02B8A66C1437C7DD1",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 92,
    //     "prevFrameCounterFcnt": 1180,
    //     "latestFrameCounterFcnt": 1181,
    //     "lostFcnt": 274,
    //     "loraSNR": 3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2622,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC195",
    //     "description": "90DFFB818728685F",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728685f",
    //     "appEUI": "2",
    //     "appKEY": "210844CBA807A2B110AEFFED7A5E007D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1147,
    //     "latestFrameCounterFcnt": 1148,
    //     "lostFcnt": 520,
    //     "loraSNR": -3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2623,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC196",
    //     "description": "90DFFB8187286C4B",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286c4b",
    //     "appEUI": "2",
    //     "appKEY": "C3166277A88993F8828CAA802622309A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 84,
    //     "prevFrameCounterFcnt": 1154,
    //     "latestFrameCounterFcnt": 1155,
    //     "lostFcnt": 225,
    //     "loraSNR": 4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2624,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC197",
    //     "description": "90DFFB8187287378",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287378",
    //     "appEUI": "2",
    //     "appKEY": "8150F9B5775A7398ADE71F6F02CDC102",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 9,
    //     "prevFrameCounterFcnt": 1062,
    //     "latestFrameCounterFcnt": 1063,
    //     "lostFcnt": 236,
    //     "loraSNR": 1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2625,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC198",
    //     "description": "90DFFB8187287379",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287379",
    //     "appEUI": "2",
    //     "appKEY": "8A74A96506ED7F1FF547F959F19561FC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 88,
    //     "prevFrameCounterFcnt": 1164,
    //     "latestFrameCounterFcnt": 1165,
    //     "lostFcnt": 266,
    //     "loraSNR": -4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2626,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC199",
    //     "description": "90DFFB818728737A",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728737a",
    //     "appEUI": "2",
    //     "appKEY": "9941B50EAAC3BC0CCC5DE620B2EE4DB5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -116,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1111,
    //     "latestFrameCounterFcnt": 1112,
    //     "lostFcnt": 188,
    //     "loraSNR": 2,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2627,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC20",
    //     "description": "90DFFB81872871F4",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871f4",
    //     "appEUI": "2",
    //     "appKEY": "C995ACF5F8CD5626319FD1FD9324452A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 84,
    //     "prevFrameCounterFcnt": 1119,
    //     "latestFrameCounterFcnt": 1120,
    //     "lostFcnt": 435,
    //     "loraSNR": 4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2628,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC200",
    //     "description": "90DFFB818728737B",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728737b",
    //     "appEUI": "2",
    //     "appKEY": "1E42CBAB59AD8F69E018974C81AB8E29",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 83,
    //     "prevFrameCounterFcnt": 1125,
    //     "latestFrameCounterFcnt": 1126,
    //     "lostFcnt": 275,
    //     "loraSNR": 1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2629,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC201",
    //     "description": "90DFFB81872871C0",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871c0",
    //     "appEUI": "2",
    //     "appKEY": "474C937E2BBC499C631B80D3963C7B8C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 1145,
    //     "latestFrameCounterFcnt": 1146,
    //     "lostFcnt": 289,
    //     "loraSNR": -2,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2630,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC202",
    //     "description": "90DFFB81872871C1",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871c1",
    //     "appEUI": "2",
    //     "appKEY": "B2DC2F7E41876EC99897E5D42FCC6702",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 1135,
    //     "latestFrameCounterFcnt": 1136,
    //     "lostFcnt": 241,
    //     "loraSNR": 4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2631,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC203",
    //     "description": "90DFFB81872871C3",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871c3",
    //     "appEUI": "2",
    //     "appKEY": "5CEF77BE5658A86F7A932A9CF96CBF2B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 86,
    //     "prevFrameCounterFcnt": 1152,
    //     "latestFrameCounterFcnt": 1153,
    //     "lostFcnt": 201,
    //     "loraSNR": 0,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2632,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC204",
    //     "description": "90DFFB81872871C4",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871c4",
    //     "appEUI": "2",
    //     "appKEY": "18C8BB045E4932553B3E15AC141827DC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 88,
    //     "prevFrameCounterFcnt": 1175,
    //     "latestFrameCounterFcnt": 1176,
    //     "lostFcnt": 258,
    //     "loraSNR": 1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2633,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC205",
    //     "description": "90DFFB81872871C5",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871c5",
    //     "appEUI": "2",
    //     "appKEY": "76D1AF263FB3C375000C550C8248CD5A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 954,
    //     "latestFrameCounterFcnt": 955,
    //     "lostFcnt": 184,
    //     "loraSNR": 0,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2634,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC206",
    //     "description": "90DFFB81872871C6",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871c6",
    //     "appEUI": "2",
    //     "appKEY": "936B92082E9FBE9E3F27E4EC7E832457",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 89,
    //     "prevFrameCounterFcnt": 1155,
    //     "latestFrameCounterFcnt": 1156,
    //     "lostFcnt": 246,
    //     "loraSNR": -1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2635,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC207",
    //     "description": "90DFFB81872871C8",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871c8",
    //     "appEUI": "2",
    //     "appKEY": "E9355AC9B5889D38DCB58DC8523EEB51",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 1072,
    //     "latestFrameCounterFcnt": 1073,
    //     "lostFcnt": 245,
    //     "loraSNR": 0,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2636,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC208",
    //     "description": "90DFFB81872871C9",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871c9",
    //     "appEUI": "2",
    //     "appKEY": "EFB6A3BEA45E98F47EE8261069647199",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 88,
    //     "prevFrameCounterFcnt": 1152,
    //     "latestFrameCounterFcnt": 1153,
    //     "lostFcnt": 240,
    //     "loraSNR": 0,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2637,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC209",
    //     "description": "90DFFB81872871CA",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871ca",
    //     "appEUI": "2",
    //     "appKEY": "A9375D764A45FC09E7EB9CDE03218549",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1115,
    //     "latestFrameCounterFcnt": 1117,
    //     "lostFcnt": 184,
    //     "loraSNR": 1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2638,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC21",
    //     "description": "90DFFB81872871F5",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871f5",
    //     "appEUI": "2",
    //     "appKEY": "C08E326EEE2C93D093CB6187351A60D3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 4,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 1078,
    //     "latestFrameCounterFcnt": 1079,
    //     "lostFcnt": 295,
    //     "loraSNR": -4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2639,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC210",
    //     "description": "90DFFB81872871CB",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871cb",
    //     "appEUI": "2",
    //     "appKEY": "8A317BE3565B8FADB0AE7A2206EFBA0C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 1152,
    //     "latestFrameCounterFcnt": 1153,
    //     "lostFcnt": 280,
    //     "loraSNR": 3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2640,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC211",
    //     "description": "90DFFB81872871CC",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871cc",
    //     "appEUI": "2",
    //     "appKEY": "DADFE7994E2DA66764152035CA26E3A7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 1131,
    //     "latestFrameCounterFcnt": 1132,
    //     "lostFcnt": 321,
    //     "loraSNR": -6,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2641,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC212",
    //     "description": "90DFFB81872871CD",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871cd",
    //     "appEUI": "2",
    //     "appKEY": "599BE098962FE84E46729502E2BD656F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 88,
    //     "prevFrameCounterFcnt": 1176,
    //     "latestFrameCounterFcnt": 1177,
    //     "lostFcnt": 234,
    //     "loraSNR": 2,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2642,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC213",
    //     "description": "90DFFB81872871CE",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871ce",
    //     "appEUI": "2",
    //     "appKEY": "4AC9C30712C3D48044CF6ABE93FD5FB0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -124,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 10,
    //     "prevFrameCounterFcnt": 1079,
    //     "latestFrameCounterFcnt": 1080,
    //     "lostFcnt": 261,
    //     "loraSNR": -5,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2643,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC214",
    //     "description": "90DFFB81872871CF",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871cf",
    //     "appEUI": "2",
    //     "appKEY": "1C4038BF4D0C0C67F4B612A074B06CB1",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 96,
    //     "prevFrameCounterFcnt": 1168,
    //     "latestFrameCounterFcnt": 1169,
    //     "lostFcnt": 264,
    //     "loraSNR": 1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2644,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC215",
    //     "description": "90DFFB81872871D0",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871d0",
    //     "appEUI": "2",
    //     "appKEY": "C8DB58A33185EBC2189764C50050DEBE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 90,
    //     "prevFrameCounterFcnt": 1152,
    //     "latestFrameCounterFcnt": 1153,
    //     "lostFcnt": 238,
    //     "loraSNR": -5,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2645,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC216",
    //     "description": "90DFFB81872871D1",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871d1",
    //     "appEUI": "2",
    //     "appKEY": "C42F151013FF553058DC97C3D78181A3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 1144,
    //     "latestFrameCounterFcnt": 1145,
    //     "lostFcnt": 232,
    //     "loraSNR": -6,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2646,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC217",
    //     "description": "90DFFB81872871D2",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871d2",
    //     "appEUI": "2",
    //     "appKEY": "F0684F348E20D1A47527F5037E9104D4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 87,
    //     "prevFrameCounterFcnt": 1156,
    //     "latestFrameCounterFcnt": 1158,
    //     "lostFcnt": 263,
    //     "loraSNR": -2,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2647,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC218",
    //     "description": "90DFFB81872871D4",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871d4",
    //     "appEUI": "2",
    //     "appKEY": "4624B02187F96639377A9239D64553FB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 93,
    //     "prevFrameCounterFcnt": 1113,
    //     "latestFrameCounterFcnt": 1114,
    //     "lostFcnt": 527,
    //     "loraSNR": -8,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2648,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC219",
    //     "description": "90DFFB81872871D5",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871d5",
    //     "appEUI": "2",
    //     "appKEY": "2EDB93BBA6131B69C97CE7698FD39C89",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -114,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 86,
    //     "prevFrameCounterFcnt": 1137,
    //     "latestFrameCounterFcnt": 1138,
    //     "lostFcnt": 199,
    //     "loraSNR": 5,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2649,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC22",
    //     "description": "90DFFB81872871FA",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871fa",
    //     "appEUI": "2",
    //     "appKEY": "D24F22FA96AF18CA1D0E97BEF94069D3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 87,
    //     "prevFrameCounterFcnt": 1166,
    //     "latestFrameCounterFcnt": 1167,
    //     "lostFcnt": 291,
    //     "loraSNR": 5,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2650,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC220",
    //     "description": "90DFFB81872871D6",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871d6",
    //     "appEUI": "2",
    //     "appKEY": "1C6EF7446AC52F4E54BF9E7F76D8D55C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 4,
    //     "rssi": -125,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 88,
    //     "prevFrameCounterFcnt": 1110,
    //     "latestFrameCounterFcnt": 1111,
    //     "lostFcnt": 394,
    //     "loraSNR": -9,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2651,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC221",
    //     "description": "90DFFB81872871D7",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871d7",
    //     "appEUI": "2",
    //     "appKEY": "B1FEBC17C42E03E13E146705C2FE847F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 9,
    //     "prevFrameCounterFcnt": 1082,
    //     "latestFrameCounterFcnt": 1083,
    //     "lostFcnt": 272,
    //     "loraSNR": -8,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2652,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC222",
    //     "description": "90DFFB81872871D9",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871d9",
    //     "appEUI": "2",
    //     "appKEY": "15501D48CF995F088699B2AB03170D3F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 3,
    //     "prevFrameCounterFcnt": 219,
    //     "latestFrameCounterFcnt": 221,
    //     "lostFcnt": 128,
    //     "loraSNR": -15,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2653,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC223",
    //     "description": "90DFFB81872871DA",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871da",
    //     "appEUI": "2",
    //     "appKEY": "E36953F412F1987180161D02377E973E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -124,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 1133,
    //     "latestFrameCounterFcnt": 1134,
    //     "lostFcnt": 317,
    //     "loraSNR": -5,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2654,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC224",
    //     "description": "90DFFB81872871DC",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871dc",
    //     "appEUI": "2",
    //     "appKEY": "37CDB6EB21DAF19042BACD1716B11C7C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1126,
    //     "latestFrameCounterFcnt": 1127,
    //     "lostFcnt": 340,
    //     "loraSNR": -12,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2655,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC225",
    //     "description": "90DFFB81872871DD",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871dd",
    //     "appEUI": "2",
    //     "appKEY": "651F2E345F5C4293E955947E95935FAE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 1168,
    //     "latestFrameCounterFcnt": 1169,
    //     "lostFcnt": 238,
    //     "loraSNR": -1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2656,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC226",
    //     "description": "90DFFB81872860D0",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860d0",
    //     "appEUI": "2",
    //     "appKEY": "3BB3469EE73BC2F938F0092B1942EF97",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 142,
    //     "prevFrameCounterFcnt": 144,
    //     "latestFrameCounterFcnt": 149,
    //     "lostFcnt": -114,
    //     "loraSNR": -19,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2657,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC227",
    //     "description": "90DFFB81872860D1",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860d1",
    //     "appEUI": "2",
    //     "appKEY": "21AAF478A6D06E1211CA10B8783B755A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 91,
    //     "prevFrameCounterFcnt": 1154,
    //     "latestFrameCounterFcnt": 1155,
    //     "lostFcnt": 251,
    //     "loraSNR": -4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2658,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC228",
    //     "description": "90DFFB81872860D2",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860d2",
    //     "appEUI": "2",
    //     "appKEY": "543BAEC029256F77F22AA4CA8424749D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1109,
    //     "latestFrameCounterFcnt": 1110,
    //     "lostFcnt": 324,
    //     "loraSNR": -15,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2659,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC229",
    //     "description": "90DFFB81872860D3",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860d3",
    //     "appEUI": "2",
    //     "appKEY": "9252B65AB67F0361A1B9F028193E36AE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 4,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1170,
    //     "latestFrameCounterFcnt": 1171,
    //     "lostFcnt": 267,
    //     "loraSNR": 0,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2660,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC23",
    //     "description": "90DFFB81872871FD",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871fd",
    //     "appEUI": "2",
    //     "appKEY": "33F22A4A7DDA432A6BA13146130C67EF",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 83,
    //     "prevFrameCounterFcnt": 1138,
    //     "latestFrameCounterFcnt": 1139,
    //     "lostFcnt": 394,
    //     "loraSNR": -5,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2661,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC230",
    //     "description": "90DFFB81872860D4",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860d4",
    //     "appEUI": "2",
    //     "appKEY": "FB48656E30A91E16C8E13D6E1CE72968",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 2,
    //     "rssi": -125,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 86,
    //     "prevFrameCounterFcnt": 1155,
    //     "latestFrameCounterFcnt": 1183,
    //     "lostFcnt": 263,
    //     "loraSNR": -15,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2662,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC231",
    //     "description": "90DFFB81872860D5",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860d5",
    //     "appEUI": "2",
    //     "appKEY": "9B00562B45B48C26687CD4F722AB9696",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -75,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 11,
    //     "prevFrameCounterFcnt": 1006,
    //     "latestFrameCounterFcnt": 1007,
    //     "lostFcnt": 136,
    //     "loraSNR": 11,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2663,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC232",
    //     "description": "90DFFB81872860D6",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860d6",
    //     "appEUI": "2",
    //     "appKEY": "FC04D03B923DF43B0C81E5B57D3E8369",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -111,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 11,
    //     "prevFrameCounterFcnt": 1016,
    //     "latestFrameCounterFcnt": 1017,
    //     "lostFcnt": 147,
    //     "loraSNR": 7,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2664,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC233",
    //     "description": "90DFFB81872860D7",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860d7",
    //     "appEUI": "2",
    //     "appKEY": "14189C173EF24D5CC6EED0AA59CE9853",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -106,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1081,
    //     "latestFrameCounterFcnt": 1082,
    //     "lostFcnt": 158,
    //     "loraSNR": 7,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2665,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC234",
    //     "description": "90DFFB81872860D8",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860d8",
    //     "appEUI": "2",
    //     "appKEY": "EA865F93969F82D227A97BC3B16C4E60",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -83,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1071,
    //     "latestFrameCounterFcnt": 1072,
    //     "lostFcnt": 156,
    //     "loraSNR": 8,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2666,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC235",
    //     "description": "90DFFB81872860D9",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860d9",
    //     "appEUI": "2",
    //     "appKEY": "E9DEDFC017C0FB643A44B9648C4B8DFC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -100,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 84,
    //     "prevFrameCounterFcnt": 1085,
    //     "latestFrameCounterFcnt": 1086,
    //     "lostFcnt": 153,
    //     "loraSNR": 11,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2667,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC236",
    //     "description": "90DFFB81872860DA",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860da",
    //     "appEUI": "2",
    //     "appKEY": "3ED35015602BCB78859DD542D1B96755",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -91,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1077,
    //     "latestFrameCounterFcnt": 1078,
    //     "lostFcnt": 172,
    //     "loraSNR": 9,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2668,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC237",
    //     "description": "90DFFB81872860DB",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860db",
    //     "appEUI": "2",
    //     "appKEY": "CA705E88E821FD4B2D056DFA2F2F44CA",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 1154,
    //     "latestFrameCounterFcnt": 1155,
    //     "lostFcnt": 265,
    //     "loraSNR": -3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2669,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC238",
    //     "description": "90DFFB81872860DC",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860dc",
    //     "appEUI": "2",
    //     "appKEY": "97473A47B852342197AD85DE9616D3D5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -126,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 56,
    //     "prevFrameCounterFcnt": 199,
    //     "latestFrameCounterFcnt": 200,
    //     "lostFcnt": -96,
    //     "loraSNR": -9,
    //     "networkServerMac": "b827eb7a5c58",
    //     "messageTime": null
    // },
    // {
    //     "id": 2670,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC239",
    //     "description": "90DFFB81872860DD",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860dd",
    //     "appEUI": "2",
    //     "appKEY": "D23656C3821D80B4622F9C2F6605BC86",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 2,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1087,
    //     "latestFrameCounterFcnt": 1088,
    //     "lostFcnt": 425,
    //     "loraSNR": -12,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2671,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC24",
    //     "description": "90DFFB81872871FF",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871ff",
    //     "appEUI": "2",
    //     "appKEY": "0AA3C599A36B41E6D28050282BCF5D48",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1131,
    //     "latestFrameCounterFcnt": 1132,
    //     "lostFcnt": 259,
    //     "loraSNR": -3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2672,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC240",
    //     "description": "90DFFB81872860DE",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860de",
    //     "appEUI": "2",
    //     "appKEY": "4275CF894BB1E2EBADE027CC8CBF58CB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 90,
    //     "prevFrameCounterFcnt": 1203,
    //     "latestFrameCounterFcnt": 1204,
    //     "lostFcnt": 285,
    //     "loraSNR": -9,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2673,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC241",
    //     "description": "90DFFB81872860DF",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860df",
    //     "appEUI": "2",
    //     "appKEY": "5A895C2FBA12DC1EDF461B245FBBA2DB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 3,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 145,
    //     "prevFrameCounterFcnt": 1101,
    //     "latestFrameCounterFcnt": 1102,
    //     "lostFcnt": 353,
    //     "loraSNR": -8,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2674,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC242",
    //     "description": "90DFFB81872860E0o",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860e0",
    //     "appEUI": "2",
    //     "appKEY": "462BCCBBD088B3788B27E5BB2283A7BA",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 2,
    //     "rssi": -125,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 84,
    //     "prevFrameCounterFcnt": 1131,
    //     "latestFrameCounterFcnt": 1132,
    //     "lostFcnt": 389,
    //     "loraSNR": -10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2675,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC243",
    //     "description": "90DFFB81872871B8",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871b8",
    //     "appEUI": "2",
    //     "appKEY": "9D339350BEF1AD661599A8C06FA461A5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 2,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 1017,
    //     "latestFrameCounterFcnt": 1018,
    //     "lostFcnt": 429,
    //     "loraSNR": -7,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2676,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC244",
    //     "description": "90DFFB81872871B9",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871b9",
    //     "appEUI": "2",
    //     "appKEY": "188E96510D2EF2937922D6D6921343DA",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 3,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 87,
    //     "prevFrameCounterFcnt": 1152,
    //     "latestFrameCounterFcnt": 1153,
    //     "lostFcnt": 282,
    //     "loraSNR": -4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2677,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC245",
    //     "description": "90DFFB81872871BA",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871ba",
    //     "appEUI": "2",
    //     "appKEY": "CDC490767819C209DC5FA4D949F55403",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 53,
    //     "prevFrameCounterFcnt": 551,
    //     "latestFrameCounterFcnt": 552,
    //     "lostFcnt": 239,
    //     "loraSNR": -17,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2678,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC246",
    //     "description": "90DFFB81872871BB",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871bb",
    //     "appEUI": "2",
    //     "appKEY": "F4C6850FD29E2C4930614C1FD40DF3F4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 10,
    //     "prevFrameCounterFcnt": 1047,
    //     "latestFrameCounterFcnt": 1048,
    //     "lostFcnt": 248,
    //     "loraSNR": 0,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2679,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC247",
    //     "description": "90DFFB81872871BC",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871bc",
    //     "appEUI": "2",
    //     "appKEY": "E956624A7D0ABFA7DE8DAECD337297DB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 4,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 84,
    //     "prevFrameCounterFcnt": 1144,
    //     "latestFrameCounterFcnt": 1145,
    //     "lostFcnt": 319,
    //     "loraSNR": -8,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2680,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC248",
    //     "description": "90DFFB81872871BD",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871bd",
    //     "appEUI": "2",
    //     "appKEY": "9C8D3953A4F4530AFCB754771E12CBB0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 2,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1114,
    //     "latestFrameCounterFcnt": 1115,
    //     "lostFcnt": 245,
    //     "loraSNR": -5,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2681,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC249",
    //     "description": "90DFFB81872871BE",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871be",
    //     "appEUI": "2",
    //     "appKEY": "CF321506F0BDECF29633EF61D437667E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 79,
    //     "prevFrameCounterFcnt": 1206,
    //     "latestFrameCounterFcnt": 1207,
    //     "lostFcnt": 221,
    //     "loraSNR": 2,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2682,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC25",
    //     "description": "90DFFB8187287200",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287200",
    //     "appEUI": "2",
    //     "appKEY": "F03804AA0BB221350E38DDDBE13B6FFB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -111,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 83,
    //     "prevFrameCounterFcnt": 1137,
    //     "latestFrameCounterFcnt": 1138,
    //     "lostFcnt": 214,
    //     "loraSNR": -1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2683,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC250",
    //     "description": "90DFFB81872871BF",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871bf",
    //     "appEUI": "2",
    //     "appKEY": "B40BB813F065237499F380AB45A2EE0B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 479,
    //     "latestFrameCounterFcnt": 489,
    //     "lostFcnt": 279,
    //     "loraSNR": -20,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2684,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC251",
    //     "description": "90DFFB818728605D",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728605d",
    //     "appEUI": "2",
    //     "appKEY": "5675BA23BB99333D943189431F941458",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -105,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1084,
    //     "latestFrameCounterFcnt": 1085,
    //     "lostFcnt": 174,
    //     "loraSNR": 8,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2685,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC252",
    //     "description": "90DFFB818728605E",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728605e",
    //     "appEUI": "2",
    //     "appKEY": "E485032F9E30811F2CF9ACC4956C7C89",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -111,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1093,
    //     "latestFrameCounterFcnt": 1094,
    //     "lostFcnt": 168,
    //     "loraSNR": 8,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2686,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC253",
    //     "description": "90DFFB818728607A",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728607a",
    //     "appEUI": "2",
    //     "appKEY": "29CA3229CF88D2E8EFF1945D7EF9EE94",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -94,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 84,
    //     "prevFrameCounterFcnt": 1081,
    //     "latestFrameCounterFcnt": 1082,
    //     "lostFcnt": 154,
    //     "loraSNR": 11,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2687,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC254",
    //     "description": "90DFFB818728607B",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728607b",
    //     "appEUI": "2",
    //     "appKEY": "E82916D3BB25551F1D126A5F0DF72404",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -102,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1077,
    //     "latestFrameCounterFcnt": 1078,
    //     "lostFcnt": 151,
    //     "loraSNR": 8,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2688,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC255",
    //     "description": "90DFFB818728607D",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728607d",
    //     "appEUI": "2",
    //     "appKEY": "4A9F7AFF90D48F2F0ADE09F46842E9B2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -105,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 1100,
    //     "latestFrameCounterFcnt": 1101,
    //     "lostFcnt": 167,
    //     "loraSNR": 7,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2689,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC256",
    //     "description": "90DFFB818728607E",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728607e",
    //     "appEUI": "2",
    //     "appKEY": "731B37FC8B9E931FD6335F62AA950242",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -90,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1081,
    //     "latestFrameCounterFcnt": 1082,
    //     "lostFcnt": 155,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2690,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC257",
    //     "description": "90DFFB818728607F",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728607f",
    //     "appEUI": "2",
    //     "appKEY": "38E1DCE927B49F8D4B516A261F4599FD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -105,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 86,
    //     "prevFrameCounterFcnt": 1092,
    //     "latestFrameCounterFcnt": 1093,
    //     "lostFcnt": 161,
    //     "loraSNR": 7,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2691,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC258",
    //     "description": "90DFFB8187286080",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286080",
    //     "appEUI": "2",
    //     "appKEY": "A2C4439F36FE19D354BD692B56688C0D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -109,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1084,
    //     "latestFrameCounterFcnt": 1085,
    //     "lostFcnt": 171,
    //     "loraSNR": 9,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2692,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC259",
    //     "description": "90DFFB8187286081",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286081",
    //     "appEUI": "2",
    //     "appKEY": "C9D54A2F86A41AFE1AEA6464AB37ED9E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -103,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1085,
    //     "latestFrameCounterFcnt": 1086,
    //     "lostFcnt": 172,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2693,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC26",
    //     "description": "90DFFB81872847F2",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872847f2",
    //     "appEUI": "2",
    //     "appKEY": "D6F53C9B1182B63490E7745B3E9B6347",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1135,
    //     "latestFrameCounterFcnt": 1136,
    //     "lostFcnt": 199,
    //     "loraSNR": 1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2694,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC260",
    //     "description": "90DFFB8187286082",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286082",
    //     "appEUI": "2",
    //     "appKEY": "C4735B1E04AFB6ED1C866DC63EE64FD3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 2,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 76,
    //     "prevFrameCounterFcnt": 496,
    //     "latestFrameCounterFcnt": 497,
    //     "lostFcnt": -85,
    //     "loraSNR": -15,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2695,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC261",
    //     "description": "90DFFB8187286083",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286083",
    //     "appEUI": "2",
    //     "appKEY": "13FDF50F435E20AE7F13D165175C8811",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -102,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 11,
    //     "prevFrameCounterFcnt": 1017,
    //     "latestFrameCounterFcnt": 1018,
    //     "lostFcnt": 160,
    //     "loraSNR": 11,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2696,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC262",
    //     "description": "90DFFB8187286084",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286084",
    //     "appEUI": "2",
    //     "appKEY": "9D4C2E04A488FB28006BC3D9E64D42A6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -95,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1079,
    //     "latestFrameCounterFcnt": 1080,
    //     "lostFcnt": 150,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2697,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC263",
    //     "description": "90DFFB8187286091",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286091",
    //     "appEUI": "2",
    //     "appKEY": "155EE0650FF0EE4C80B116A846AD8E32",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -95,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1076,
    //     "latestFrameCounterFcnt": 1077,
    //     "lostFcnt": 159,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2698,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC264",
    //     "description": "90DFFB8187286092",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286092",
    //     "appEUI": "2",
    //     "appKEY": "BDB06C11859F67A682095E8347FEC2EB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -105,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 79,
    //     "prevFrameCounterFcnt": 1079,
    //     "latestFrameCounterFcnt": 1080,
    //     "lostFcnt": 169,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2699,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC265",
    //     "description": "90DFFB8187286093",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286093",
    //     "appEUI": "2",
    //     "appKEY": "8A2DBB7FB9A1F6C7C48DB9F39933D7B3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -103,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1080,
    //     "latestFrameCounterFcnt": 1081,
    //     "lostFcnt": 166,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2700,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC266",
    //     "description": "90DFFB8187286094",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286094",
    //     "appEUI": "2",
    //     "appKEY": "C8BD0B4E0C20568D3BEF278CF2F19F01",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 98,
    //     "prevFrameCounterFcnt": 1156,
    //     "latestFrameCounterFcnt": 1157,
    //     "lostFcnt": 227,
    //     "loraSNR": 2,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2701,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC267",
    //     "description": "90DFFB8187286095",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286095",
    //     "appEUI": "2",
    //     "appKEY": "E1F6EE43165906F4794FE0566B302088",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 95,
    //     "prevFrameCounterFcnt": 1188,
    //     "latestFrameCounterFcnt": 1189,
    //     "lostFcnt": 261,
    //     "loraSNR": 1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2702,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC268",
    //     "description": "90DFFB8187286096",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286096",
    //     "appEUI": "2",
    //     "appKEY": "86DFD1CAA3D8C54AD140197799B29013",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -97,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1071,
    //     "latestFrameCounterFcnt": 1072,
    //     "lostFcnt": 162,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2703,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC269",
    //     "description": "90DFFB8187286097",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286097",
    //     "appEUI": "2",
    //     "appKEY": "CEE511AA5976D04E82FB11C6E3497C19",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -108,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1091,
    //     "latestFrameCounterFcnt": 1092,
    //     "lostFcnt": 157,
    //     "loraSNR": 9,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2704,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC27",
    //     "description": "90DFFB81872847F3",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872847f3",
    //     "appEUI": "2",
    //     "appKEY": "25036255303111A71B29FEB73B46FF67",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -100,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 83,
    //     "prevFrameCounterFcnt": 1080,
    //     "latestFrameCounterFcnt": 1081,
    //     "lostFcnt": 151,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2705,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC270",
    //     "description": "90DFFB8187286099",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286099",
    //     "appEUI": "2",
    //     "appKEY": "A1F1314320A1C3B1F66DC2F9766A1B64",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -105,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 10,
    //     "prevFrameCounterFcnt": 1012,
    //     "latestFrameCounterFcnt": 1014,
    //     "lostFcnt": 147,
    //     "loraSNR": 7,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2706,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC271",
    //     "description": "90DFFB818728609A",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728609a",
    //     "appEUI": "2",
    //     "appKEY": "6612FE7D453572283227C7B85365CF06",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 24,
    //     "prevFrameCounterFcnt": 69,
    //     "latestFrameCounterFcnt": 70,
    //     "lostFcnt": 26,
    //     "loraSNR": -16,
    //     "networkServerMac": "b827eb7a5c58",
    //     "messageTime": null
    // },
    // {
    //     "id": 2707,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC272",
    //     "description": "90DFFB818728609B",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728609b",
    //     "appEUI": "2",
    //     "appKEY": "5664F5E77B4EB3F3E34AF32415E03F31",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 2,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 79,
    //     "prevFrameCounterFcnt": 1118,
    //     "latestFrameCounterFcnt": 1119,
    //     "lostFcnt": 400,
    //     "loraSNR": -16,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2708,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC273",
    //     "description": "90DFFB81872860B5",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860b5",
    //     "appEUI": "2",
    //     "appKEY": "257E89C614B01D5EBD6E21107885E85A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 52,
    //     "latestFrameCounterFcnt": 53,
    //     "lostFcnt": -499,
    //     "loraSNR": -18,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2709,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC274",
    //     "description": "90DFFB81872860B6",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860b6",
    //     "appEUI": "2",
    //     "appKEY": "7CB9745CAB1FC74BD060CADB9663D047",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 3,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1134,
    //     "latestFrameCounterFcnt": 1135,
    //     "lostFcnt": 271,
    //     "loraSNR": -6,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2710,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC275",
    //     "description": "90DFFB81872860B8",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860b8",
    //     "appEUI": "2",
    //     "appKEY": "4A95D13EFE62253F0E97EA4A41495D98",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 4,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1153,
    //     "latestFrameCounterFcnt": 1154,
    //     "lostFcnt": 256,
    //     "loraSNR": -3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2711,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC276",
    //     "description": "90DFFB818728609C",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728609c",
    //     "appEUI": "2",
    //     "appKEY": "3FD4B393E087B59C36FABDC3F767FFC7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 92,
    //     "prevFrameCounterFcnt": 1098,
    //     "latestFrameCounterFcnt": 1100,
    //     "lostFcnt": 444,
    //     "loraSNR": -10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2712,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC277",
    //     "description": "90DFFB81872860B7",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860b7",
    //     "appEUI": "2",
    //     "appKEY": "84D922BCCD418B1311A0B61344DEC883",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 65,
    //     "prevFrameCounterFcnt": 491,
    //     "latestFrameCounterFcnt": 492,
    //     "lostFcnt": 72,
    //     "loraSNR": -13,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2713,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC278",
    //     "description": "90DFFB81872860B9",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860b9",
    //     "appEUI": "2",
    //     "appKEY": "A7FBE8FDB6FF097FE502ED8F477DDE9E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 1,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1123,
    //     "latestFrameCounterFcnt": 1124,
    //     "lostFcnt": 224,
    //     "loraSNR": -9,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2714,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC279",
    //     "description": "90DFFB81872860BA",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860ba",
    //     "appEUI": "2",
    //     "appKEY": "AF8AE04D13DCC25FC1CABC460940A069",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 2,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1171,
    //     "latestFrameCounterFcnt": 1172,
    //     "lostFcnt": 237,
    //     "loraSNR": -13,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2715,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC28",
    //     "description": "90DFFB81872847F4",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872847f4",
    //     "appEUI": "2",
    //     "appKEY": "439CD91D393DCA36C6C0ABF9247CBD45",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -99,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1080,
    //     "latestFrameCounterFcnt": 1081,
    //     "lostFcnt": 153,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2716,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC282",
    //     "description": "90DFFB81872860BD",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860bd",
    //     "appEUI": "2",
    //     "appKEY": "15576507AB1D3FE78A2A4D3A5B60DDEE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 126,
    //     "prevFrameCounterFcnt": 130,
    //     "latestFrameCounterFcnt": 139,
    //     "lostFcnt": -341,
    //     "loraSNR": -16,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2717,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC280",
    //     "description": "90DFFB81872860BB",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860bb",
    //     "appEUI": "2",
    //     "appKEY": "2F99A337C2E417E5DA99848C4D63D9C0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 1,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 79,
    //     "prevFrameCounterFcnt": 1085,
    //     "latestFrameCounterFcnt": 1086,
    //     "lostFcnt": 447,
    //     "loraSNR": -10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2718,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC281",
    //     "description": "90DFFB81872860BC",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860bc",
    //     "appEUI": "2",
    //     "appKEY": "EA4DAA10A7F3724082FA7CC17C3E50EB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 145,
    //     "prevFrameCounterFcnt": 915,
    //     "latestFrameCounterFcnt": 918,
    //     "lostFcnt": 247,
    //     "loraSNR": -15,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2719,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC283",
    //     "description": "90DFFB81872860BE",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860be",
    //     "appEUI": "2",
    //     "appKEY": "D79532EA0E1D8395A50EC383FD0CF156",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 3,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1114,
    //     "latestFrameCounterFcnt": 1115,
    //     "lostFcnt": 350,
    //     "loraSNR": -10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2720,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC284",
    //     "description": "90DFFB81872860BF",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860bf",
    //     "appEUI": "2",
    //     "appKEY": "F411E22549F81D5295F2D105511663A5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 62,
    //     "prevFrameCounterFcnt": 37,
    //     "latestFrameCounterFcnt": 38,
    //     "lostFcnt": -156,
    //     "loraSNR": -18,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2721,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC285",
    //     "description": "90DFFB81872860C0",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860c0",
    //     "appEUI": "2",
    //     "appKEY": "C8C465E6B6C1D18B06D49C74048B5827",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 3,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 79,
    //     "prevFrameCounterFcnt": 1119,
    //     "latestFrameCounterFcnt": 1120,
    //     "lostFcnt": 332,
    //     "loraSNR": -8,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2722,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC286",
    //     "description": "90DFFB81872860C1",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860c1",
    //     "appEUI": "2",
    //     "appKEY": "1E96B8C51F9B6B7872555A46F5F16F07",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 5,
    //     "prevFrameCounterFcnt": 35,
    //     "latestFrameCounterFcnt": 37,
    //     "lostFcnt": -41,
    //     "loraSNR": -20,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2723,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC287",
    //     "description": "90DFFB81872860C2",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860c2",
    //     "appEUI": "2",
    //     "appKEY": "81F94A61E2ECDA843E52384B869CAF6D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 39,
    //     "prevFrameCounterFcnt": 25,
    //     "latestFrameCounterFcnt": 27,
    //     "lostFcnt": -79,
    //     "loraSNR": -21,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2724,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC288",
    //     "description": "90DFFB81872860C3",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860c3",
    //     "appEUI": "2",
    //     "appKEY": "BC0F6AC2FCEF952E26620DAC88E68983",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 86,
    //     "latestFrameCounterFcnt": 193,
    //     "lostFcnt": 86,
    //     "loraSNR": -20,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2725,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC289",
    //     "description": "90DFFB81872860C4",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860c4",
    //     "appEUI": "2",
    //     "appKEY": "EB9DE9700E8DB4F114305A9DBB0819CE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 2,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 79,
    //     "prevFrameCounterFcnt": 530,
    //     "latestFrameCounterFcnt": 531,
    //     "lostFcnt": -166,
    //     "loraSNR": -11,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2726,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC29",
    //     "description": "90DFFB81872847F7",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872847f7",
    //     "appEUI": "2",
    //     "appKEY": "B1003D1FEB0399575EAA54172906F750",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -93,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1079,
    //     "latestFrameCounterFcnt": 1080,
    //     "lostFcnt": 165,
    //     "loraSNR": 8,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2727,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC290",
    //     "description": "90DFFB81872860C5",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860c5",
    //     "appEUI": "2",
    //     "appKEY": "618442DB70DAD465BC7FA56C4FDE613C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 3,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 1136,
    //     "latestFrameCounterFcnt": 1137,
    //     "lostFcnt": 445,
    //     "loraSNR": -2,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2728,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC291",
    //     "description": "90DFFB81872860C6",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860c6",
    //     "appEUI": "2",
    //     "appKEY": "7BF902AE3D88E1FB0058BAF1D48C6C96",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 1057,
    //     "latestFrameCounterFcnt": 1058,
    //     "lostFcnt": 305,
    //     "loraSNR": -9,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2729,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC292",
    //     "description": "90DFFB81872860C7",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860c7",
    //     "appEUI": "2",
    //     "appKEY": "AE14358ED90A9A4320EBBAC11E6F9669",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 86,
    //     "prevFrameCounterFcnt": 950,
    //     "latestFrameCounterFcnt": 951,
    //     "lostFcnt": 131,
    //     "loraSNR": 1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2730,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC293",
    //     "description": "90DFFB81872860C8",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860c8",
    //     "appEUI": "2",
    //     "appKEY": "706B8DAD034AE879307FF677101767DD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1137,
    //     "latestFrameCounterFcnt": 1138,
    //     "lostFcnt": 194,
    //     "loraSNR": 3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2731,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC294",
    //     "description": "90DFFB81872860C9",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860c9",
    //     "appEUI": "2",
    //     "appKEY": "F3A44FCA27F5E1CE5449B9B949FADC05",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 4,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1102,
    //     "latestFrameCounterFcnt": 1103,
    //     "lostFcnt": 349,
    //     "loraSNR": -2,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2732,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC295",
    //     "description": "90DFFB81872860CA",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860ca",
    //     "appEUI": "2",
    //     "appKEY": "5AF58AAACF2A46F44CF632DBBFC24E2A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 83,
    //     "prevFrameCounterFcnt": 289,
    //     "latestFrameCounterFcnt": 291,
    //     "lostFcnt": 43,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2733,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC296",
    //     "description": "90DFFB81872860CB",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860cb",
    //     "appEUI": "2",
    //     "appKEY": "B51C8E4C39C85BB7133032FCF484698E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1171,
    //     "latestFrameCounterFcnt": 1172,
    //     "lostFcnt": 256,
    //     "loraSNR": 1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2734,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC297",
    //     "description": "90DFFB81872860CC",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860cc",
    //     "appEUI": "2",
    //     "appKEY": "49ADE33EEF57E2160B69CE99BDE675D5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 3,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 83,
    //     "prevFrameCounterFcnt": 1107,
    //     "latestFrameCounterFcnt": 1115,
    //     "lostFcnt": 475,
    //     "loraSNR": -13,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2735,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC298",
    //     "description": "90DFFB81872860CD",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860cd",
    //     "appEUI": "2",
    //     "appKEY": "495151604E4269E1CBBF291D7EC40CE3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -110,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 88,
    //     "prevFrameCounterFcnt": 1033,
    //     "latestFrameCounterFcnt": 1035,
    //     "lostFcnt": 347,
    //     "loraSNR": -18,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2736,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC299",
    //     "description": "90DFFB81872860CE",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860ce",
    //     "appEUI": "2",
    //     "appKEY": "DF5D89BD4677F38EE0229EE2AA6F30DA",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 4,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1122,
    //     "latestFrameCounterFcnt": 1123,
    //     "lostFcnt": 357,
    //     "loraSNR": -8,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2737,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC30",
    //     "description": "90DFFB81872847F8",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872847f8",
    //     "appEUI": "2",
    //     "appKEY": "3D62E422400F7C3FEE82DD873036BCC0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -95,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1076,
    //     "latestFrameCounterFcnt": 1077,
    //     "lostFcnt": 153,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2738,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC300",
    //     "description": "90DFFB81872860CF",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872860cf",
    //     "appEUI": "2",
    //     "appKEY": "229C2306D943E8C230028286F2938EDE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 229,
    //     "latestFrameCounterFcnt": 230,
    //     "lostFcnt": 55,
    //     "loraSNR": -19,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2739,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC301",
    //     "description": "90DFFB8187287275",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287275",
    //     "appEUI": "2",
    //     "appKEY": "0F3993F09581C57F16FD98422625DB2A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -90,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1080,
    //     "latestFrameCounterFcnt": 1081,
    //     "lostFcnt": 174,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2740,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC302",
    //     "description": "90DFFB8187287276",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287276",
    //     "appEUI": "2",
    //     "appKEY": "5BEC586A265065A6DBA82D17A85CBA52",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -112,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1129,
    //     "latestFrameCounterFcnt": 1130,
    //     "lostFcnt": 194,
    //     "loraSNR": 8,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2741,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC303",
    //     "description": "90DFFB8187287277",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287277",
    //     "appEUI": "2",
    //     "appKEY": "8E08847B4C8223F430450A643D6D8066",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 88,
    //     "prevFrameCounterFcnt": 539,
    //     "latestFrameCounterFcnt": 540,
    //     "lostFcnt": -250,
    //     "loraSNR": 2,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2742,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC304",
    //     "description": "90DFFB8187287278",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287278",
    //     "appEUI": "2",
    //     "appKEY": "2B6CF32EFAF582138569B1D42D2070EF",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 4,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 76,
    //     "latestFrameCounterFcnt": 78,
    //     "lostFcnt": -467,
    //     "loraSNR": 1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2743,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC305",
    //     "description": "90DFFB8187287279",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287279",
    //     "appEUI": "2",
    //     "appKEY": "B9CEB33DFEE58C91C089F34EAFD143BE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 653,
    //     "latestFrameCounterFcnt": 654,
    //     "lostFcnt": -210,
    //     "loraSNR": -8,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2744,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC306",
    //     "description": "90DFFB818728727A",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728727a",
    //     "appEUI": "2",
    //     "appKEY": "71BAD6DDA466635B4B8FBB92420AE758",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 1155,
    //     "latestFrameCounterFcnt": 1156,
    //     "lostFcnt": 228,
    //     "loraSNR": 1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2745,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC307",
    //     "description": "90DFFB818728727B",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728727b",
    //     "appEUI": "2",
    //     "appKEY": "CC8E8B94E0F2E3E1ABDA7B4B99ACE213",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 47,
    //     "latestFrameCounterFcnt": 56,
    //     "lostFcnt": 38,
    //     "loraSNR": -19,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2746,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC308",
    //     "description": "90DFFB81872AA873",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872aa873",
    //     "appEUI": "2",
    //     "appKEY": "D85E098D5001E571F7953AEDA5E95972",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 2,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 650,
    //     "latestFrameCounterFcnt": 651,
    //     "lostFcnt": 122,
    //     "loraSNR": -8,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2747,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC309",
    //     "description": "90DFFB81872AA874",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872aa874",
    //     "appEUI": "2",
    //     "appKEY": "7F2917E5643526DDE64DB4928CF80A1D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2748,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC31",
    //     "description": "90DFFB8187286DB9",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286db9",
    //     "appEUI": "2",
    //     "appKEY": "557EFDF2D3BA9ADE8079EF59A29CEB96",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 1129,
    //     "latestFrameCounterFcnt": 1130,
    //     "lostFcnt": 199,
    //     "loraSNR": 1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2749,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC310",
    //     "description": "90DFFB81872AA875",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872aa875",
    //     "appEUI": "2",
    //     "appKEY": "B498DAEB0CF385FBB6C5D7CDF0495CA7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2750,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC311",
    //     "description": "90DFFB81872AA876",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872aa876",
    //     "appEUI": "2",
    //     "appKEY": "13EE4C2F4563365B256F3066B4DD4BCF",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2751,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC312",
    //     "description": "90DFFB81872AA877",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872aa877",
    //     "appEUI": "2",
    //     "appKEY": "8C64D445B5928C6B27B9275C16A03FB4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2752,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC313",
    //     "description": "90DFFB81872AF8FE",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872af8fe",
    //     "appEUI": "2",
    //     "appKEY": "C9DE238E54C5E51906F6E51B85F8314A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2753,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC315",
    //     "description": "90DFFB81872AF901",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872af901",
    //     "appEUI": "2",
    //     "appKEY": "785D0326C3B2E2C631D4D4484DB46324",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2754,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC314",
    //     "description": "90DFFB81872AF900",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872af900",
    //     "appEUI": "2",
    //     "appKEY": "27702D2CBA720C0CAAED15CFFF3C79A0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2755,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC317",
    //     "description": "90DFFB81872AF903",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872af903",
    //     "appEUI": "2",
    //     "appKEY": "3257D19D90F898241FFDFFA758C34C67",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2756,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC318",
    //     "description": "90DFFB81872AF904",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872af904",
    //     "appEUI": "2",
    //     "appKEY": "B64BBB185F403BB62A9D10CE71680684",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 691,
    //     "latestFrameCounterFcnt": 692,
    //     "lostFcnt": 159,
    //     "loraSNR": 1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2757,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC319",
    //     "description": "90DFFB81872AF905",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872af905",
    //     "appEUI": "2",
    //     "appKEY": "B1771B1B7049C48638E455425E8AB964",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 3,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 777,
    //     "latestFrameCounterFcnt": 778,
    //     "lostFcnt": 182,
    //     "loraSNR": -6,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2758,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC32",
    //     "description": "90DFFB8187286DBA",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286dba",
    //     "appEUI": "2",
    //     "appKEY": "48BAB2AC90AB15680F399FA0AE009D3D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -89,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 851,
    //     "latestFrameCounterFcnt": 852,
    //     "lostFcnt": 138,
    //     "loraSNR": 9,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2759,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC320",
    //     "description": "90DFFB81872AF906",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872af906",
    //     "appEUI": "2",
    //     "appKEY": "4E85B8B8529D1A80D40A34082B9CD65C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 481,
    //     "latestFrameCounterFcnt": 482,
    //     "lostFcnt": 257,
    //     "loraSNR": -19,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2760,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC321",
    //     "description": "90DFFB81872AF907",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872af907",
    //     "appEUI": "2",
    //     "appKEY": "2DE39035ADC36AE64AE20BA5366F9111",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 4,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 982,
    //     "latestFrameCounterFcnt": 983,
    //     "lostFcnt": 201,
    //     "loraSNR": -5,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2761,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC322",
    //     "description": "90DFFB81872AF908",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872af908",
    //     "appEUI": "2",
    //     "appKEY": "5945D8154A3AA63062D4D1536A170DF6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1164,
    //     "latestFrameCounterFcnt": 1165,
    //     "lostFcnt": 257,
    //     "loraSNR": -6,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2762,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC323",
    //     "description": "90DFFB81872AF909",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872af909",
    //     "appEUI": "2",
    //     "appKEY": "B4B73398F9339EEB5EB2DAB60D823557",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 2,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 19,
    //     "prevFrameCounterFcnt": 73,
    //     "latestFrameCounterFcnt": 74,
    //     "lostFcnt": 9,
    //     "loraSNR": -8,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2763,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC324",
    //     "description": "90DFFB81872AF90A",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872af90a",
    //     "appEUI": "2",
    //     "appKEY": "12679BDB621A9E6CC5B40C9E1969A9E0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 1,
    //     "rssi": -123,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 711,
    //     "latestFrameCounterFcnt": 712,
    //     "lostFcnt": 222,
    //     "loraSNR": -16,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2764,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC325",
    //     "description": "90DFFB81872AF90B",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872af90b",
    //     "appEUI": "2",
    //     "appKEY": "D7F34D04A33D3D44E332C0BF3432B26C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 5,
    //     "prevFrameCounterFcnt": 884,
    //     "latestFrameCounterFcnt": 885,
    //     "lostFcnt": 182,
    //     "loraSNR": 4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2765,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC326",
    //     "description": "90DFFB81872AF90C",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872af90c",
    //     "appEUI": "2",
    //     "appKEY": "A528952D2C0156A1258305758B46517E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 87,
    //     "prevFrameCounterFcnt": 540,
    //     "latestFrameCounterFcnt": 541,
    //     "lostFcnt": -275,
    //     "loraSNR": -5,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2766,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC327",
    //     "description": "90DFFB81872AF90D",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872af90d",
    //     "appEUI": "2",
    //     "appKEY": "E143D3FC34C829AE4495DF579476F325",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 19,
    //     "prevFrameCounterFcnt": 19,
    //     "latestFrameCounterFcnt": 19,
    //     "lostFcnt": 0,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2767,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC33",
    //     "description": "90DFFB8187286DBB",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286dbb",
    //     "appEUI": "2",
    //     "appKEY": "CE7C919D0A34D0CF9CDB5E9F7CE7E4E9",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1142,
    //     "latestFrameCounterFcnt": 1143,
    //     "lostFcnt": 317,
    //     "loraSNR": 2,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2768,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC34",
    //     "description": "90DFFB8187286DBD",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286dbd",
    //     "appEUI": "2",
    //     "appKEY": "CF2E62793BE94A6DEBD289F45FC8210E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -95,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 1030,
    //     "latestFrameCounterFcnt": 1031,
    //     "lostFcnt": 156,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2769,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC35",
    //     "description": "90DFFB8187286DBE",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286dbe",
    //     "appEUI": "2",
    //     "appKEY": "7E0834681B826F0A4959B100673EC514",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -100,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1085,
    //     "latestFrameCounterFcnt": 1086,
    //     "lostFcnt": 168,
    //     "loraSNR": 10,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2770,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC36",
    //     "description": "90DFFB8187286DBF",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286dbf",
    //     "appEUI": "2",
    //     "appKEY": "E2B47AF695D3FEB0D7131199A4D4E064",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 86,
    //     "prevFrameCounterFcnt": 1156,
    //     "latestFrameCounterFcnt": 1157,
    //     "lostFcnt": 283,
    //     "loraSNR": -1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2771,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC37",
    //     "description": "90DFFB8187286DC0",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286dc0",
    //     "appEUI": "2",
    //     "appKEY": "FAB5776A287F90CC971B0758AD801A12",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 12,
    //     "prevFrameCounterFcnt": 1068,
    //     "latestFrameCounterFcnt": 1069,
    //     "lostFcnt": 261,
    //     "loraSNR": 5,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2772,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC38",
    //     "description": "90DFFB8187286DC1",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286dc1",
    //     "appEUI": "2",
    //     "appKEY": "87948FB48AD1251E554BA8048F795E04",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -96,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1089,
    //     "latestFrameCounterFcnt": 1090,
    //     "lostFcnt": 173,
    //     "loraSNR": 11,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2773,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC39",
    //     "description": "90DFFB8187286DC2",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286dc2",
    //     "appEUI": "2",
    //     "appKEY": "EA63E29CF6A89C45E17F05E982044A20",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 94,
    //     "prevFrameCounterFcnt": 1141,
    //     "latestFrameCounterFcnt": 1142,
    //     "lostFcnt": 291,
    //     "loraSNR": 4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2774,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC40",
    //     "description": "90DFFB8187286DC5",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286dc5",
    //     "appEUI": "2",
    //     "appKEY": "D9B9A11A4A8DFDDA8E80F386A1ADBD43",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 9,
    //     "prevFrameCounterFcnt": 1099,
    //     "latestFrameCounterFcnt": 1100,
    //     "lostFcnt": 302,
    //     "loraSNR": -1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2775,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC41",
    //     "description": "90DFFB8187286DC6",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286dc6",
    //     "appEUI": "2",
    //     "appKEY": "5822C0C691C7F18378DA8DE53B9628BD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 1081,
    //     "latestFrameCounterFcnt": 1082,
    //     "lostFcnt": 235,
    //     "loraSNR": 3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2776,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC42",
    //     "description": "90DFFB8187286DC7",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286dc7",
    //     "appEUI": "2",
    //     "appKEY": "7D87B67AF90D2E33EB496D3E990ECB7C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 83,
    //     "prevFrameCounterFcnt": 1148,
    //     "latestFrameCounterFcnt": 1149,
    //     "lostFcnt": 276,
    //     "loraSNR": 0,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2777,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC43",
    //     "description": "90DFFB818728715F",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb818728715f",
    //     "appEUI": "2",
    //     "appKEY": "2AF10A6B6D335DC9F44B790AE6F19A88",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -124,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 86,
    //     "prevFrameCounterFcnt": 1148,
    //     "latestFrameCounterFcnt": 1149,
    //     "lostFcnt": 228,
    //     "loraSNR": -4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2778,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC44",
    //     "description": "90DFFB8187287160",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287160",
    //     "appEUI": "2",
    //     "appKEY": "50E33138D18B4B83311F8CF70830537F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 90,
    //     "prevFrameCounterFcnt": 1157,
    //     "latestFrameCounterFcnt": 1158,
    //     "lostFcnt": 252,
    //     "loraSNR": -3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2779,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC45",
    //     "description": "90DFFB8187287161",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287161",
    //     "appEUI": "2",
    //     "appKEY": "E7CB14F57641CD66AB536901961EA50B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 92,
    //     "prevFrameCounterFcnt": 1158,
    //     "latestFrameCounterFcnt": 1159,
    //     "lostFcnt": 237,
    //     "loraSNR": 4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2780,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC46",
    //     "description": "90DFFB8187287162",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287162",
    //     "appEUI": "2",
    //     "appKEY": "C5A39E268FA8138840F50E968880E3AC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 88,
    //     "prevFrameCounterFcnt": 1106,
    //     "latestFrameCounterFcnt": 1107,
    //     "lostFcnt": 189,
    //     "loraSNR": 3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2781,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC47",
    //     "description": "90DFFB8187287163",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187287163",
    //     "appEUI": "2",
    //     "appKEY": "A7D87783BB2951969BB28752137FEFA5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 11,
    //     "prevFrameCounterFcnt": 1087,
    //     "latestFrameCounterFcnt": 1088,
    //     "lostFcnt": 227,
    //     "loraSNR": -4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2782,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC48",
    //     "description": "90DFFB81872871E2",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871e2",
    //     "appEUI": "2",
    //     "appKEY": "0A57EA8A8BF26D13DA63BA2879529557",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -115,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 87,
    //     "prevFrameCounterFcnt": 1180,
    //     "latestFrameCounterFcnt": 1181,
    //     "lostFcnt": 260,
    //     "loraSNR": 4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2783,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC49",
    //     "description": "90DFFB81872871E7",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871e7",
    //     "appEUI": "2",
    //     "appKEY": "2142B2AD20DD1C08B72B68ACB7ACE36D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 1136,
    //     "latestFrameCounterFcnt": 1137,
    //     "lostFcnt": 233,
    //     "loraSNR": 3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2784,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC50",
    //     "description": "90DFFB81872871E8",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb81872871e8",
    //     "appEUI": "2",
    //     "appKEY": "1B33DA62645CFD2C7FEB80999483A2F2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 92,
    //     "prevFrameCounterFcnt": 867,
    //     "latestFrameCounterFcnt": 868,
    //     "lostFcnt": 116,
    //     "loraSNR": -2,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2785,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC51",
    //     "description": "90DFFB8187283842",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187283842",
    //     "appEUI": "2",
    //     "appKEY": "A8AEEAB8F1A6683C3022110696F6C339",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 90,
    //     "prevFrameCounterFcnt": 1167,
    //     "latestFrameCounterFcnt": 1168,
    //     "lostFcnt": 280,
    //     "loraSNR": -7,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2786,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC52",
    //     "description": "90DFFB8187286D96",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286d96",
    //     "appEUI": "2",
    //     "appKEY": "576C37D9D8B5C9B127E2E4C5D0E730F3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1149,
    //     "latestFrameCounterFcnt": 1150,
    //     "lostFcnt": 219,
    //     "loraSNR": 3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2787,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC53",
    //     "description": "90DFFB8187286D97",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286d97",
    //     "appEUI": "2",
    //     "appKEY": "F08BD2BF80ACBA79F31AD1DAB3495D02",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 86,
    //     "prevFrameCounterFcnt": 1213,
    //     "latestFrameCounterFcnt": 1214,
    //     "lostFcnt": 261,
    //     "loraSNR": -4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2788,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC54",
    //     "description": "90DFFB8187286D98",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286d98",
    //     "appEUI": "2",
    //     "appKEY": "ACBA4C75DB615B28058FC9CF9C944340",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -120,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 88,
    //     "prevFrameCounterFcnt": 1161,
    //     "latestFrameCounterFcnt": 1162,
    //     "lostFcnt": 315,
    //     "loraSNR": -1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2789,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC55",
    //     "description": "90DFFB8187286D99",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286d99",
    //     "appEUI": "2",
    //     "appKEY": "8CFF6F71E8DAA9055C3D283B403B5E3E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 1,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 79,
    //     "prevFrameCounterFcnt": 694,
    //     "latestFrameCounterFcnt": 701,
    //     "lostFcnt": 336,
    //     "loraSNR": -19,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2790,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC56",
    //     "description": "90DFFB8187286D9A",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286d9a",
    //     "appEUI": "2",
    //     "appKEY": "219AA518FB2086A3B1CC205091D488B5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 84,
    //     "prevFrameCounterFcnt": 1159,
    //     "latestFrameCounterFcnt": 1160,
    //     "lostFcnt": 276,
    //     "loraSNR": -2,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2791,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC57",
    //     "description": "90DFFB8187286D9B",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286d9b",
    //     "appEUI": "2",
    //     "appKEY": "4DA4064A22C93F74A20054F8582FE680",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 2,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 1043,
    //     "latestFrameCounterFcnt": 1059,
    //     "lostFcnt": 456,
    //     "loraSNR": -15,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2792,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC58",
    //     "description": "90DFFB8187286D9C",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286d9c",
    //     "appEUI": "2",
    //     "appKEY": "8C2B3F75EF6A2E2A48DF577112C767DB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1133,
    //     "latestFrameCounterFcnt": 1134,
    //     "lostFcnt": 214,
    //     "loraSNR": 2,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2793,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC59",
    //     "description": "90DFFB8187286D9D",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286d9d",
    //     "appEUI": "2",
    //     "appKEY": "260E178A0ACE32F96C31E1E2E625BDA3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 1142,
    //     "latestFrameCounterFcnt": 1143,
    //     "lostFcnt": 201,
    //     "loraSNR": 1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2794,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC60",
    //     "description": "90DFFB8187286D9E",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286d9e",
    //     "appEUI": "2",
    //     "appKEY": "61A53E14381580063B4D5C15AC86126D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 43,
    //     "latestFrameCounterFcnt": 44,
    //     "lostFcnt": -267,
    //     "loraSNR": -14,
    //     "networkServerMac": "b827eb7a5c58",
    //     "messageTime": null
    // },
    // {
    //     "id": 2795,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC61",
    //     "description": "90DFFB8187286D9F",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286d9f",
    //     "appEUI": "2",
    //     "appKEY": "0B292F1372370C869FE9E671627002AE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1153,
    //     "latestFrameCounterFcnt": 1154,
    //     "lostFcnt": 271,
    //     "loraSNR": 0,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2796,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC62",
    //     "description": "90DFFB8187286DA0",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286da0",
    //     "appEUI": "2",
    //     "appKEY": "7E0898206A11753CBD2ABA1314332997",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 84,
    //     "prevFrameCounterFcnt": 1153,
    //     "latestFrameCounterFcnt": 1154,
    //     "lostFcnt": 265,
    //     "loraSNR": 4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2797,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC63",
    //     "description": "90DFFB8187286DA1",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286da1",
    //     "appEUI": "2",
    //     "appKEY": "C83E0FA316F36B3B4F611940BE46B4F2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 2,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 83,
    //     "prevFrameCounterFcnt": 404,
    //     "latestFrameCounterFcnt": 406,
    //     "lostFcnt": 49,
    //     "loraSNR": -5,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2798,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC64",
    //     "description": "90DFFB8187286DA2",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286da2",
    //     "appEUI": "2",
    //     "appKEY": "B8D09CBE21CA88E07A980B971D6CD509",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 2,
    //     "rssi": -123,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 9,
    //     "prevFrameCounterFcnt": 1041,
    //     "latestFrameCounterFcnt": 1044,
    //     "lostFcnt": 517,
    //     "loraSNR": -16,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2799,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC65",
    //     "description": "90DFFB8187286DA3",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286da3",
    //     "appEUI": "2",
    //     "appKEY": "76E2C8B4D2BEC507788B1766279D43C4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 3,
    //     "rssi": -125,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 1058,
    //     "latestFrameCounterFcnt": 1059,
    //     "lostFcnt": 314,
    //     "loraSNR": -12,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2800,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC66",
    //     "description": "90DFFB8187286DA4",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286da4",
    //     "appEUI": "2",
    //     "appKEY": "52C57B75111B7E342DBEA2E428BDEE45",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -125,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 9,
    //     "prevFrameCounterFcnt": 262,
    //     "latestFrameCounterFcnt": 263,
    //     "lostFcnt": -276,
    //     "loraSNR": -12,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2801,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC67",
    //     "description": "90DFFB8187286DA5",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286da5",
    //     "appEUI": "2",
    //     "appKEY": "0E495CD576A459C470FD51400EC4D2A5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -121,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 10,
    //     "prevFrameCounterFcnt": 1079,
    //     "latestFrameCounterFcnt": 1080,
    //     "lostFcnt": 251,
    //     "loraSNR": 1,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2802,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC68",
    //     "description": "90DFFB8187286DA6",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286da6",
    //     "appEUI": "2",
    //     "appKEY": "8A31B33C6FC1E5B346FF5DD56E0A699A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 89,
    //     "prevFrameCounterFcnt": 1155,
    //     "latestFrameCounterFcnt": 1156,
    //     "lostFcnt": 262,
    //     "loraSNR": 3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2803,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC69",
    //     "description": "90DFFB8187286DA7",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286da7",
    //     "appEUI": "2",
    //     "appKEY": "7FA00849A0E557659EEC2F5A2833B995",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 4,
    //     "rssi": -120,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1147,
    //     "latestFrameCounterFcnt": 1148,
    //     "lostFcnt": 339,
    //     "loraSNR": 0,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2804,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC70",
    //     "description": "90DFFB8187286DA9",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286da9",
    //     "appEUI": "2",
    //     "appKEY": "9043F3A61C00A58E29C9A5C16B460ED4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -118,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 86,
    //     "prevFrameCounterFcnt": 1128,
    //     "latestFrameCounterFcnt": 1129,
    //     "lostFcnt": 291,
    //     "loraSNR": -3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2805,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC186",
    //     "description": "90DFFB8187282E08",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb8187282e08",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -119,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 86,
    //     "prevFrameCounterFcnt": 1143,
    //     "latestFrameCounterFcnt": 1144,
    //     "lostFcnt": 235,
    //     "loraSNR": -3,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2806,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC202",
    //     "description": "90DFFB81872871C1",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872871c1",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -117,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 1135,
    //     "latestFrameCounterFcnt": 1136,
    //     "lostFcnt": 241,
    //     "loraSNR": 4,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2807,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC281",
    //     "description": "90DFFB81872860BC",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872860bc",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 145,
    //     "prevFrameCounterFcnt": 915,
    //     "latestFrameCounterFcnt": 918,
    //     "lostFcnt": 247,
    //     "loraSNR": -15,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2808,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC282",
    //     "description": "90DFFB81872860BD",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872860bd",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -123,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 126,
    //     "prevFrameCounterFcnt": 130,
    //     "latestFrameCounterFcnt": 139,
    //     "lostFcnt": -341,
    //     "loraSNR": -16,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2809,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC286",
    //     "description": "90DFFB81872860C1",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872860c1",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 0,
    //     "rssi": -121,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 5,
    //     "prevFrameCounterFcnt": 35,
    //     "latestFrameCounterFcnt": 37,
    //     "lostFcnt": -41,
    //     "loraSNR": -20,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2810,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC312",
    //     "description": "90DFFB81872AA877",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aa877",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2811,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC315",
    //     "description": "90DFFB81872AF901",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872af901",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": null,
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2812,
    //     "gatewaysId": null,
    //     "name": "WaterExternalC71",
    //     "description": "90DFFB8187286DAA",
    //     "provider": null,
    //     "userId": 74,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-02",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": 2.1779921962083733,
    //     "deviceEUI": "90dffb8187286daa",
    //     "appEUI": "2",
    //     "appKEY": "4EBC00F3A3C2632EE46A76D386A75823",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",dca63214b1c9",
    //     "dr": 5,
    //     "rssi": -113,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1126,
    //     "latestFrameCounterFcnt": 1127,
    //     "lostFcnt": 209,
    //     "loraSNR": 6,
    //     "networkServerMac": "dca63214b1c9",
    //     "messageTime": null
    // },
    // {
    //     "id": 2813,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC01",
    //     "description": "Forn2",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59b7",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 49,
    //     "prevFrameCounterFcnt": 1044,
    //     "latestFrameCounterFcnt": 1045,
    //     "lostFcnt": 196,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2814,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC02",
    //     "description": "Forn3",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2227",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 49,
    //     "prevFrameCounterFcnt": 1057,
    //     "latestFrameCounterFcnt": 1058,
    //     "lostFcnt": 194,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2815,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC03",
    //     "description": "Forn4",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59bc",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 48,
    //     "prevFrameCounterFcnt": 1038,
    //     "latestFrameCounterFcnt": 1039,
    //     "lostFcnt": 181,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2816,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC04",
    //     "description": "Forn6",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59bd",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 49,
    //     "prevFrameCounterFcnt": 1058,
    //     "latestFrameCounterFcnt": 1059,
    //     "lostFcnt": 179,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2817,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC05",
    //     "description": "Forn7",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b222a",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 47,
    //     "prevFrameCounterFcnt": 1053,
    //     "latestFrameCounterFcnt": 1054,
    //     "lostFcnt": 175,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2818,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC06",
    //     "description": "Forn8",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59be",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 46,
    //     "prevFrameCounterFcnt": 1045,
    //     "latestFrameCounterFcnt": 1046,
    //     "lostFcnt": 173,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2819,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC07",
    //     "description": "Forn9",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b607e",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 49,
    //     "prevFrameCounterFcnt": 797,
    //     "latestFrameCounterFcnt": 798,
    //     "lostFcnt": -47,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2820,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC10",
    //     "description": "Fornsn",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59a7",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 44,
    //     "prevFrameCounterFcnt": 1052,
    //     "latestFrameCounterFcnt": 1053,
    //     "lostFcnt": 174,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2821,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC100",
    //     "description": "LaFont",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59aa",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 50,
    //     "prevFrameCounterFcnt": 1048,
    //     "latestFrameCounterFcnt": 1049,
    //     "lostFcnt": 172,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2822,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC102",
    //     "description": "Depuradora",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6075",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 41,
    //     "prevFrameCounterFcnt": 1054,
    //     "latestFrameCounterFcnt": 1055,
    //     "lostFcnt": 189,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2823,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC11",
    //     "description": "Riba1",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6099",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 48,
    //     "prevFrameCounterFcnt": 1039,
    //     "latestFrameCounterFcnt": 1040,
    //     "lostFcnt": 183,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2824,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC12",
    //     "description": "Riba1Bj",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b609a",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 46,
    //     "prevFrameCounterFcnt": 1047,
    //     "latestFrameCounterFcnt": 1048,
    //     "lostFcnt": 176,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2825,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC13",
    //     "description": "Riba2",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6098",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 48,
    //     "prevFrameCounterFcnt": 1062,
    //     "latestFrameCounterFcnt": 1063,
    //     "lostFcnt": 178,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2826,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC14",
    //     "description": "Riba3",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6091",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 48,
    //     "prevFrameCounterFcnt": 1065,
    //     "latestFrameCounterFcnt": 1066,
    //     "lostFcnt": 185,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2827,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC15",
    //     "description": "Riba4",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6097",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 52,
    //     "prevFrameCounterFcnt": 1061,
    //     "latestFrameCounterFcnt": 1062,
    //     "lostFcnt": 171,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2828,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC16",
    //     "description": "Riba5",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6090",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 47,
    //     "prevFrameCounterFcnt": 1067,
    //     "latestFrameCounterFcnt": 1068,
    //     "lostFcnt": 189,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2829,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC17",
    //     "description": "Riba6",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6096",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 46,
    //     "prevFrameCounterFcnt": 1045,
    //     "latestFrameCounterFcnt": 1046,
    //     "lostFcnt": 176,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2830,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC18",
    //     "description": "Riba8",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6069",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 49,
    //     "prevFrameCounterFcnt": 1035,
    //     "latestFrameCounterFcnt": 1036,
    //     "lostFcnt": 174,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2831,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC19",
    //     "description": "Riba10",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6068",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 46,
    //     "prevFrameCounterFcnt": 1042,
    //     "latestFrameCounterFcnt": 1043,
    //     "lostFcnt": 171,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2832,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC20",
    //     "description": "Riba12",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b609c",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 48,
    //     "prevFrameCounterFcnt": 1046,
    //     "latestFrameCounterFcnt": 1047,
    //     "lostFcnt": 182,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2833,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC21",
    //     "description": "Riba14",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b609d",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 56,
    //     "prevFrameCounterFcnt": 1057,
    //     "latestFrameCounterFcnt": 1058,
    //     "lostFcnt": 189,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2834,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC22",
    //     "description": "Palau1",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59b8",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 48,
    //     "prevFrameCounterFcnt": 1046,
    //     "latestFrameCounterFcnt": 1047,
    //     "lostFcnt": 178,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2835,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC23",
    //     "description": "Palau2",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b609b",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 53,
    //     "prevFrameCounterFcnt": 1042,
    //     "latestFrameCounterFcnt": 1043,
    //     "lostFcnt": 188,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2836,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC24",
    //     "description": "Palau3",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59bb",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 47,
    //     "prevFrameCounterFcnt": 1063,
    //     "latestFrameCounterFcnt": 1064,
    //     "lostFcnt": 192,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2837,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC25",
    //     "description": "Palau4",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59ba",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 48,
    //     "prevFrameCounterFcnt": 1063,
    //     "latestFrameCounterFcnt": 1064,
    //     "lostFcnt": 228,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2838,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC26",
    //     "description": "Palau5",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59b9",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 47,
    //     "prevFrameCounterFcnt": 1044,
    //     "latestFrameCounterFcnt": 1045,
    //     "lostFcnt": 187,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2839,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC27",
    //     "description": "Font1",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59c6",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 47,
    //     "prevFrameCounterFcnt": 1081,
    //     "latestFrameCounterFcnt": 1082,
    //     "lostFcnt": 238,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2840,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC28",
    //     "description": "Font3",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59c2",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 47,
    //     "prevFrameCounterFcnt": 1074,
    //     "latestFrameCounterFcnt": 1075,
    //     "lostFcnt": 214,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2841,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC29",
    //     "description": "Font5",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59c7",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 50,
    //     "prevFrameCounterFcnt": 1057,
    //     "latestFrameCounterFcnt": 1058,
    //     "lostFcnt": 180,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2842,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC30",
    //     "description": "Font7",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59c1",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 49,
    //     "prevFrameCounterFcnt": 1009,
    //     "latestFrameCounterFcnt": 1011,
    //     "lostFcnt": 215,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2843,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC31",
    //     "description": "Font2",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59a8",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 46,
    //     "prevFrameCounterFcnt": 1049,
    //     "latestFrameCounterFcnt": 1050,
    //     "lostFcnt": 199,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2844,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC32",
    //     "description": "Forat1",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2237",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 49,
    //     "prevFrameCounterFcnt": 1035,
    //     "latestFrameCounterFcnt": 1036,
    //     "lostFcnt": 172,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2845,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC34",
    //     "description": "Baix2",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872ae75a",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 48,
    //     "prevFrameCounterFcnt": 1034,
    //     "latestFrameCounterFcnt": 1035,
    //     "lostFcnt": 190,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2846,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC35",
    //     "description": "Baix2",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59c3",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 49,
    //     "prevFrameCounterFcnt": 611,
    //     "latestFrameCounterFcnt": 612,
    //     "lostFcnt": -211,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2847,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC36",
    //     "description": "Baix4",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59c8",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 47,
    //     "prevFrameCounterFcnt": 1038,
    //     "latestFrameCounterFcnt": 1039,
    //     "lostFcnt": 185,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2848,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC37",
    //     "description": "Baix5",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59c4",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 47,
    //     "prevFrameCounterFcnt": 1053,
    //     "latestFrameCounterFcnt": 1054,
    //     "lostFcnt": 176,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2849,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC38",
    //     "description": "Baix7",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b223c",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 48,
    //     "prevFrameCounterFcnt": 1059,
    //     "latestFrameCounterFcnt": 1060,
    //     "lostFcnt": 186,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2850,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC39",
    //     "description": "Baix9",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59ca",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 47,
    //     "prevFrameCounterFcnt": 1050,
    //     "latestFrameCounterFcnt": 1051,
    //     "lostFcnt": 174,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2851,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC40",
    //     "description": "Baix11",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59c9",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 45,
    //     "prevFrameCounterFcnt": 1032,
    //     "latestFrameCounterFcnt": 1033,
    //     "lostFcnt": 226,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2852,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC41",
    //     "description": "Baix13",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872ae807",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2853,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC42",
    //     "description": "Baix15",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872ae806",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 48,
    //     "prevFrameCounterFcnt": 1041,
    //     "latestFrameCounterFcnt": 1042,
    //     "lostFcnt": 186,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2854,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC43",
    //     "description": "Baix19",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872ae804",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 46,
    //     "prevFrameCounterFcnt": 1037,
    //     "latestFrameCounterFcnt": 1038,
    //     "lostFcnt": 163,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2855,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC44",
    //     "description": "Baix21",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872ae805",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 47,
    //     "prevFrameCounterFcnt": 1041,
    //     "latestFrameCounterFcnt": 1042,
    //     "lostFcnt": 161,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2856,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC45",
    //     "description": "Baix23",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b607c",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 48,
    //     "prevFrameCounterFcnt": 1050,
    //     "latestFrameCounterFcnt": 1051,
    //     "lostFcnt": 186,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2857,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC46",
    //     "description": "Baix25",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872ae78d",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 47,
    //     "prevFrameCounterFcnt": 1033,
    //     "latestFrameCounterFcnt": 1034,
    //     "lostFcnt": 167,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2858,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC47",
    //     "description": "Baix27",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872ae73b",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 46,
    //     "prevFrameCounterFcnt": 1032,
    //     "latestFrameCounterFcnt": 1033,
    //     "lostFcnt": 222,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2859,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC48",
    //     "description": "Almasera1",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2205",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 48,
    //     "prevFrameCounterFcnt": 1095,
    //     "latestFrameCounterFcnt": 1097,
    //     "lostFcnt": 264,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2860,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC49",
    //     "description": "Almasera5",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6092",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 46,
    //     "prevFrameCounterFcnt": 1086,
    //     "latestFrameCounterFcnt": 1087,
    //     "lostFcnt": 201,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2861,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC50",
    //     "description": "Almasera1-1",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6094",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 46,
    //     "prevFrameCounterFcnt": 1042,
    //     "latestFrameCounterFcnt": 1043,
    //     "lostFcnt": 169,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2862,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC52",
    //     "description": "CaminoCementerio",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b607a",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 50,
    //     "prevFrameCounterFcnt": 1060,
    //     "latestFrameCounterFcnt": 1061,
    //     "lostFcnt": 168,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2863,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC53",
    //     "description": "CaminoCementerio",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b607b",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 45,
    //     "prevFrameCounterFcnt": 1040,
    //     "latestFrameCounterFcnt": 1041,
    //     "lostFcnt": 161,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2864,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC54",
    //     "description": "CaminoCementerio",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6074",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 50,
    //     "prevFrameCounterFcnt": 1050,
    //     "latestFrameCounterFcnt": 1051,
    //     "lostFcnt": 185,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2865,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC56",
    //     "description": "Picaora2",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2207",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 49,
    //     "prevFrameCounterFcnt": 1058,
    //     "latestFrameCounterFcnt": 1059,
    //     "lostFcnt": 191,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2866,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC57",
    //     "description": "Picaora4",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2206",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 47,
    //     "prevFrameCounterFcnt": 716,
    //     "latestFrameCounterFcnt": 717,
    //     "lostFcnt": -175,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2867,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC58",
    //     "description": "Picaora6",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b608d",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 47,
    //     "prevFrameCounterFcnt": 1064,
    //     "latestFrameCounterFcnt": 1065,
    //     "lostFcnt": 191,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2868,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC59",
    //     "description": "Picaora8",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b608e",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 2869,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC60",
    //     "description": "Picaora10",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6095",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 48,
    //     "prevFrameCounterFcnt": 1037,
    //     "latestFrameCounterFcnt": 1038,
    //     "lostFcnt": 176,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2870,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC61",
    //     "description": "SantJaume7",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b606b",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 51,
    //     "prevFrameCounterFcnt": 1100,
    //     "latestFrameCounterFcnt": 1101,
    //     "lostFcnt": 239,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2871,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC62",
    //     "description": "SantJaume5",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6070",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 48,
    //     "prevFrameCounterFcnt": 1102,
    //     "latestFrameCounterFcnt": 1103,
    //     "lostFcnt": 250,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2872,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC63",
    //     "description": "SantJaume9",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b606f",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 49,
    //     "prevFrameCounterFcnt": 1091,
    //     "latestFrameCounterFcnt": 1092,
    //     "lostFcnt": 234,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2873,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC64",
    //     "description": "SantJaume11",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b606a",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 47,
    //     "prevFrameCounterFcnt": 1067,
    //     "latestFrameCounterFcnt": 1069,
    //     "lostFcnt": 193,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2874,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC65",
    //     "description": "SantJaume13",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b606c",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 47,
    //     "prevFrameCounterFcnt": 1069,
    //     "latestFrameCounterFcnt": 1070,
    //     "lostFcnt": 205,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2875,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC66",
    //     "description": "SantJaume15",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b606d",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 50,
    //     "prevFrameCounterFcnt": 1114,
    //     "latestFrameCounterFcnt": 1116,
    //     "lostFcnt": 259,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2876,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC67",
    //     "description": "SantJaume17",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b606e",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 50,
    //     "prevFrameCounterFcnt": 1097,
    //     "latestFrameCounterFcnt": 1098,
    //     "lostFcnt": 228,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2877,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC68",
    //     "description": "SantJaume19",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2232",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 48,
    //     "prevFrameCounterFcnt": 1054,
    //     "latestFrameCounterFcnt": 1055,
    //     "lostFcnt": 195,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2878,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC69",
    //     "description": "SantJaume21",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2231",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 49,
    //     "prevFrameCounterFcnt": 1051,
    //     "latestFrameCounterFcnt": 1052,
    //     "lostFcnt": 178,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2879,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC70",
    //     "description": "SantJaume23",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2236",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 51,
    //     "prevFrameCounterFcnt": 1067,
    //     "latestFrameCounterFcnt": 1068,
    //     "lostFcnt": 194,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2880,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC80",
    //     "description": "Tosal1",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59bf",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 41,
    //     "prevFrameCounterFcnt": 1054,
    //     "latestFrameCounterFcnt": 1055,
    //     "lostFcnt": 197,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2881,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC79",
    //     "description": "PartidaTrosSN",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59a9",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 45,
    //     "prevFrameCounterFcnt": 1055,
    //     "latestFrameCounterFcnt": 1056,
    //     "lostFcnt": 183,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2882,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC78",
    //     "description": "PartidaTrosSN",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6080",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 40,
    //     "prevFrameCounterFcnt": 1044,
    //     "latestFrameCounterFcnt": 1045,
    //     "lostFcnt": 197,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2883,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC77",
    //     "description": "PartidaBaixSN",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b607f",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 41,
    //     "prevFrameCounterFcnt": 1042,
    //     "latestFrameCounterFcnt": 1043,
    //     "lostFcnt": 158,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2884,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC76",
    //     "description": "SantJaume55",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2239",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 41,
    //     "prevFrameCounterFcnt": 1058,
    //     "latestFrameCounterFcnt": 1061,
    //     "lostFcnt": 234,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2885,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC75",
    //     "description": "SantJaume49",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2238",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 40,
    //     "prevFrameCounterFcnt": 1049,
    //     "latestFrameCounterFcnt": 1050,
    //     "lostFcnt": 209,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2886,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC74",
    //     "description": "SantJaume47",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2235",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 47,
    //     "prevFrameCounterFcnt": 1046,
    //     "latestFrameCounterFcnt": 1047,
    //     "lostFcnt": 178,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2887,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC73",
    //     "description": "SantJaume37",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59a6",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 46,
    //     "prevFrameCounterFcnt": 1052,
    //     "latestFrameCounterFcnt": 1053,
    //     "lostFcnt": 182,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2888,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC72",
    //     "description": "SantJaume27",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2234",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 50,
    //     "prevFrameCounterFcnt": 1092,
    //     "latestFrameCounterFcnt": 1093,
    //     "lostFcnt": 226,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2889,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC71",
    //     "description": "SantJaume25",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2233",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 48,
    //     "prevFrameCounterFcnt": 1075,
    //     "latestFrameCounterFcnt": 1076,
    //     "lostFcnt": 220,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2890,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC81",
    //     "description": "Tosal3",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b223d",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 39,
    //     "prevFrameCounterFcnt": 1062,
    //     "latestFrameCounterFcnt": 1064,
    //     "lostFcnt": 193,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2891,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC82",
    //     "description": "Tosal5",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59c0",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 40,
    //     "prevFrameCounterFcnt": 1052,
    //     "latestFrameCounterFcnt": 1053,
    //     "lostFcnt": 196,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2892,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC83",
    //     "description": "Tosal7",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59b5",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 39,
    //     "prevFrameCounterFcnt": 1041,
    //     "latestFrameCounterFcnt": 1042,
    //     "lostFcnt": 188,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2893,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC84",
    //     "description": "Tosal9",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59b4",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 40,
    //     "prevFrameCounterFcnt": 1034,
    //     "latestFrameCounterFcnt": 1035,
    //     "lostFcnt": 180,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2894,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC85",
    //     "description": "Tosal11",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59b1",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 38,
    //     "prevFrameCounterFcnt": 1031,
    //     "latestFrameCounterFcnt": 1032,
    //     "lostFcnt": 164,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2895,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC86",
    //     "description": "Tosal13",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59b2",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 38,
    //     "prevFrameCounterFcnt": 1036,
    //     "latestFrameCounterFcnt": 1037,
    //     "lostFcnt": 182,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2896,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC87",
    //     "description": "PartidaDallaS/N",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59ab",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 46,
    //     "prevFrameCounterFcnt": 1086,
    //     "latestFrameCounterFcnt": 1087,
    //     "lostFcnt": 225,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2897,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC88",
    //     "description": "PartidaDallaSN",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6076",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 41,
    //     "prevFrameCounterFcnt": 1046,
    //     "latestFrameCounterFcnt": 1047,
    //     "lostFcnt": 199,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2898,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC89",
    //     "description": "PartidaTosalSN",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6079",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 38,
    //     "prevFrameCounterFcnt": 1041,
    //     "latestFrameCounterFcnt": 1042,
    //     "lostFcnt": 198,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2899,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC90",
    //     "description": "Arc1",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59ac",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 43,
    //     "prevFrameCounterFcnt": 1031,
    //     "latestFrameCounterFcnt": 1044,
    //     "lostFcnt": 217,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2900,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC91",
    //     "description": "Arc",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6073",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 41,
    //     "prevFrameCounterFcnt": 1067,
    //     "latestFrameCounterFcnt": 1068,
    //     "lostFcnt": 204,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2901,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC92",
    //     "description": "Arc9",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59ae",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 43,
    //     "prevFrameCounterFcnt": 1069,
    //     "latestFrameCounterFcnt": 1070,
    //     "lostFcnt": 198,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2902,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC93",
    //     "description": "Arc9",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59af",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 40,
    //     "prevFrameCounterFcnt": 1067,
    //     "latestFrameCounterFcnt": 1068,
    //     "lostFcnt": 178,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2903,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC94",
    //     "description": "Arc11",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59b0",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 55,
    //     "prevFrameCounterFcnt": 772,
    //     "latestFrameCounterFcnt": 773,
    //     "lostFcnt": -218,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2904,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC95",
    //     "description": "Arc13",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6072",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 40,
    //     "prevFrameCounterFcnt": 1036,
    //     "latestFrameCounterFcnt": 1037,
    //     "lostFcnt": 183,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2905,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC96",
    //     "description": "Arc15",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59b6",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 37,
    //     "prevFrameCounterFcnt": 1045,
    //     "latestFrameCounterFcnt": 1046,
    //     "lostFcnt": 209,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2906,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC97",
    //     "description": "Arc17",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6071",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 42,
    //     "prevFrameCounterFcnt": 1057,
    //     "latestFrameCounterFcnt": 1058,
    //     "lostFcnt": 220,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2907,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC98",
    //     "description": "Arc3",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59ad",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 39,
    //     "prevFrameCounterFcnt": 1069,
    //     "latestFrameCounterFcnt": 1070,
    //     "lostFcnt": 222,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2908,
    //     "gatewaysId": null,
    //     "name": "WaterExternalCACGWTC99",
    //     "description": "FontSN",
    //     "provider": null,
    //     "userId": 76,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-03",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b59a5",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": ",b827ebc7dd63",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 40,
    //     "prevFrameCounterFcnt": 1031,
    //     "latestFrameCounterFcnt": 1032,
    //     "lostFcnt": 175,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebc7dd63",
    //     "messageTime": null
    // },
    // {
    //     "id": 2969,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00200",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aaba9",
    //     "appEUI": "app",
    //     "appKEY": "7E4E8B519AB7B4EBA9A66F1539B746ED",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 780,
    //     "latestFrameCounterFcnt": 781,
    //     "lostFcnt": 171,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2970,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00201",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aac3f",
    //     "appEUI": "app",
    //     "appKEY": "4C44D8ABE13A289C6F512D7DFB33634C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 859,
    //     "latestFrameCounterFcnt": 860,
    //     "lostFcnt": 283,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2971,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00202",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872af7f5",
    //     "appEUI": "app",
    //     "appKEY": "0888736D2AE06245922AA42B3F14B758",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1074,
    //     "latestFrameCounterFcnt": 1075,
    //     "lostFcnt": 271,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2972,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00203",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872af813",
    //     "appEUI": "app",
    //     "appKEY": "3F00A7248510BE3E4D2B5055C279F1B0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 1051,
    //     "latestFrameCounterFcnt": 1052,
    //     "lostFcnt": 199,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2973,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00204",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872af816",
    //     "appEUI": "app",
    //     "appKEY": "3562E04121AC56AA5CB9B9B195E84150",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 65,
    //     "prevFrameCounterFcnt": 784,
    //     "latestFrameCounterFcnt": 785,
    //     "lostFcnt": -23,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2974,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00205",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872af81a",
    //     "appEUI": "app",
    //     "appKEY": "A2BDF72777BEC72F975F651A6D46FCF3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 849,
    //     "latestFrameCounterFcnt": 850,
    //     "lostFcnt": 463,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2975,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00206",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872af81b",
    //     "appEUI": "app",
    //     "appKEY": "AF9F355836A0266C6948E4F1CDF94B60",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 831,
    //     "latestFrameCounterFcnt": 832,
    //     "lostFcnt": 235,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2976,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00207",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872af81c",
    //     "appEUI": "app",
    //     "appKEY": "3E27B3A564F3248E7F97AD287E746369",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1118,
    //     "latestFrameCounterFcnt": 1119,
    //     "lostFcnt": 277,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2977,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00208",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872af81d",
    //     "appEUI": "app",
    //     "appKEY": "B119BE4E46AFBD29956B118F2187E6CF",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 833,
    //     "latestFrameCounterFcnt": 834,
    //     "lostFcnt": 220,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2978,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00209",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872af81e",
    //     "appEUI": "app",
    //     "appKEY": "E1009314981FAFA72BA601C312B1620B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 687,
    //     "latestFrameCounterFcnt": 689,
    //     "lostFcnt": 301,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2979,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00210",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872af81f",
    //     "appEUI": "app",
    //     "appKEY": "211834E55F025F6E405AF3C0BA905595",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 999,
    //     "latestFrameCounterFcnt": 1000,
    //     "lostFcnt": 471,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2980,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00211",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872af820",
    //     "appEUI": "app",
    //     "appKEY": "6EFC00CE1A9759334241F65978BC8DF8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1020,
    //     "latestFrameCounterFcnt": 1021,
    //     "lostFcnt": 323,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2981,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00212",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b038c",
    //     "appEUI": "app",
    //     "appKEY": "18617B7EBD0840EA25C0E28D36ED55E8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1095,
    //     "latestFrameCounterFcnt": 1096,
    //     "lostFcnt": 309,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2982,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00213",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0391",
    //     "appEUI": "app",
    //     "appKEY": "C10A58B49E6EE0748E43D2A946593896",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 104,
    //     "latestFrameCounterFcnt": 106,
    //     "lostFcnt": 50,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2983,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00214",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b039e",
    //     "appEUI": "app",
    //     "appKEY": "F9DFA639CCF2C3338A65F9259A7FF1FE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 998,
    //     "latestFrameCounterFcnt": 999,
    //     "lostFcnt": 155,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2984,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00215",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b039f",
    //     "appEUI": "app",
    //     "appKEY": "3F139FA5409B3A6E7CD1B182B6B308E0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 255,
    //     "latestFrameCounterFcnt": 256,
    //     "lostFcnt": 5,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2985,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00216",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03a0",
    //     "appEUI": "app",
    //     "appKEY": "FA1235D9DD4624EFC1976C91C521024E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1041,
    //     "latestFrameCounterFcnt": 1042,
    //     "lostFcnt": 439,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2986,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00217",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03a1",
    //     "appEUI": "app",
    //     "appKEY": "72CEF05EA80D732D9C4C7D7B56E95BDC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 83,
    //     "prevFrameCounterFcnt": 1097,
    //     "latestFrameCounterFcnt": 1098,
    //     "lostFcnt": 367,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2987,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00218",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03a3",
    //     "appEUI": "app",
    //     "appKEY": "04F5F0126A0DE1B90F8D84C3674A34D4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 993,
    //     "latestFrameCounterFcnt": 995,
    //     "lostFcnt": 159,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2988,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00219",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03a4",
    //     "appEUI": "app",
    //     "appKEY": "5A68EC539741DDC653D4EC9C1BB2608B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1014,
    //     "latestFrameCounterFcnt": 1015,
    //     "lostFcnt": 178,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2989,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00220",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03a5",
    //     "appEUI": "app",
    //     "appKEY": "70E02896505BD149F1E06A252C58DF62",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1049,
    //     "latestFrameCounterFcnt": 1050,
    //     "lostFcnt": 223,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2990,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00221",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03a6",
    //     "appEUI": "app",
    //     "appKEY": "61863D0FE3C9D9C8BC6681034EDB58B8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1060,
    //     "latestFrameCounterFcnt": 1061,
    //     "lostFcnt": 289,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2991,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00222",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03a7",
    //     "appEUI": "app",
    //     "appKEY": "BCAF99D31C1816F98F0D4D366D7C6C49",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 1040,
    //     "latestFrameCounterFcnt": 1041,
    //     "lostFcnt": 383,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2992,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00223",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0b81",
    //     "appEUI": "app",
    //     "appKEY": "860C940C599DEF146F472468C7BB61EE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1056,
    //     "latestFrameCounterFcnt": 1057,
    //     "lostFcnt": 354,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2993,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00224",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0b8e",
    //     "appEUI": "app",
    //     "appKEY": "FE38B9F7238081479190A6018DAA315B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1072,
    //     "latestFrameCounterFcnt": 1073,
    //     "lostFcnt": 230,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2994,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00225",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b032b",
    //     "appEUI": "app",
    //     "appKEY": "577FB194819D82D68357042BCB49B938",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 108,
    //     "prevFrameCounterFcnt": 1283,
    //     "latestFrameCounterFcnt": 1287,
    //     "lostFcnt": 305,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2995,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00226",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0360",
    //     "appEUI": "app",
    //     "appKEY": "C2CDE7AA73913E06204A490924CF65AA",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1066,
    //     "latestFrameCounterFcnt": 1069,
    //     "lostFcnt": 316,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2996,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00227",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0361",
    //     "appEUI": "app",
    //     "appKEY": "694EC8B70431B054DECBBA368D9EEBB1",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1051,
    //     "latestFrameCounterFcnt": 1054,
    //     "lostFcnt": 326,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2997,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00228",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0365",
    //     "appEUI": "app",
    //     "appKEY": "EBDC2EC73AEDBAF563728916B82DD5CD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1098,
    //     "latestFrameCounterFcnt": 1099,
    //     "lostFcnt": 243,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2998,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00229",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b036b",
    //     "appEUI": "app",
    //     "appKEY": "9CB511D7B838442E8A0A05EC0405AD0F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1068,
    //     "latestFrameCounterFcnt": 1069,
    //     "lostFcnt": 222,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 2999,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00230",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b036c",
    //     "appEUI": "app",
    //     "appKEY": "4622B044DC10BA01CDA6447FFD2140EA",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 64,
    //     "prevFrameCounterFcnt": 990,
    //     "latestFrameCounterFcnt": 991,
    //     "lostFcnt": 577,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3000,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00231",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b036d",
    //     "appEUI": "app",
    //     "appKEY": "8C2A11F44FC0187D8060107A1731D872",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 64,
    //     "prevFrameCounterFcnt": 997,
    //     "latestFrameCounterFcnt": 1002,
    //     "lostFcnt": 509,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3001,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00232",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b036e",
    //     "appEUI": "app",
    //     "appKEY": "A248BCC056F19B2BE68841A14D9A05AB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1078,
    //     "latestFrameCounterFcnt": 1079,
    //     "lostFcnt": 292,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3002,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00233",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b036f",
    //     "appEUI": "app",
    //     "appKEY": "028E0A533234638D4938722F0BCB3E78",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 993,
    //     "latestFrameCounterFcnt": 998,
    //     "lostFcnt": 502,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3003,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00234",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0371",
    //     "appEUI": "app",
    //     "appKEY": "F4433C4D0962FF7208EF726A3B84F27C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 888,
    //     "latestFrameCounterFcnt": 891,
    //     "lostFcnt": 437,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3004,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00235",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0373",
    //     "appEUI": "app",
    //     "appKEY": "DDCA81095FBF4137835ADC3C8CF6B3B3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1096,
    //     "latestFrameCounterFcnt": 1097,
    //     "lostFcnt": 228,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3005,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00236",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0374",
    //     "appEUI": "app",
    //     "appKEY": "96CADDED3FCF35B02F3653E020D11CDB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1012,
    //     "latestFrameCounterFcnt": 1014,
    //     "lostFcnt": 320,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3006,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00237",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03a2",
    //     "appEUI": "app",
    //     "appKEY": "E43155FCC320D554165DD20F189A5D6A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 76,
    //     "prevFrameCounterFcnt": 1088,
    //     "latestFrameCounterFcnt": 1089,
    //     "lostFcnt": 288,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3007,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00238",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03a8",
    //     "appEUI": "app",
    //     "appKEY": "961F151A861688B94D8698132ABF3E4E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 1087,
    //     "latestFrameCounterFcnt": 1088,
    //     "lostFcnt": 287,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3008,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00239",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03a9",
    //     "appEUI": "app",
    //     "appKEY": "F4E1B9F8495BBBB713B614C55A2C7BA0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 82,
    //     "prevFrameCounterFcnt": 1051,
    //     "latestFrameCounterFcnt": 1052,
    //     "lostFcnt": 424,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3009,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00240",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03aa",
    //     "appEUI": "app",
    //     "appKEY": "E378D61ED8269F8A496C928CFF161253",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1042,
    //     "latestFrameCounterFcnt": 1048,
    //     "lostFcnt": 463,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3010,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00241",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03ab",
    //     "appEUI": "app",
    //     "appKEY": "A3546DF78D0F3A202D587548B4F53072",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1077,
    //     "latestFrameCounterFcnt": 1078,
    //     "lostFcnt": 289,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3011,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00242",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03ac",
    //     "appEUI": "app",
    //     "appKEY": "BA216A52A9DC0E9E4960382CD77087A1",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 1044,
    //     "latestFrameCounterFcnt": 1046,
    //     "lostFcnt": 356,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3012,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00243",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03af",
    //     "appEUI": "app",
    //     "appKEY": "026E743678EB8D971E9C635A6894EE90",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1060,
    //     "latestFrameCounterFcnt": 1061,
    //     "lostFcnt": 345,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3013,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00244",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03b0",
    //     "appEUI": "app",
    //     "appKEY": "F3F9581D0E2A6C86E71ACA6FC3A149FC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 10,
    //     "prevFrameCounterFcnt": 14,
    //     "latestFrameCounterFcnt": 62,
    //     "lostFcnt": 7,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3014,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00245",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03b1",
    //     "appEUI": "app",
    //     "appKEY": "C27F23846132693487DD889E7F81D206",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 64,
    //     "prevFrameCounterFcnt": 1018,
    //     "latestFrameCounterFcnt": 1023,
    //     "lostFcnt": 368,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3015,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00246",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03b2",
    //     "appEUI": "app",
    //     "appKEY": "EAFAFAD3E23EC5F8C97AB1972EFFFD82",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 1042,
    //     "latestFrameCounterFcnt": 1043,
    //     "lostFcnt": 283,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3016,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00247",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03b5",
    //     "appEUI": "app",
    //     "appKEY": "5754BC7A344B5D2AC0B0FDFCF6890A7F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 63,
    //     "prevFrameCounterFcnt": 994,
    //     "latestFrameCounterFcnt": 995,
    //     "lostFcnt": 584,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3017,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00248",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03cd",
    //     "appEUI": "app",
    //     "appKEY": "DD0E96C4D0A66DB74F9DEEF204D31954",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1043,
    //     "latestFrameCounterFcnt": 1044,
    //     "lostFcnt": 293,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3018,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00249",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03cf",
    //     "appEUI": "app",
    //     "appKEY": "3DB55183F3CC9135350C8D0F16E41923",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1081,
    //     "latestFrameCounterFcnt": 1082,
    //     "lostFcnt": 261,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3019,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00250",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeaa2",
    //     "appEUI": "app",
    //     "appKEY": "FD43DBFB88511705823DC4B574B51ED8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1076,
    //     "latestFrameCounterFcnt": 1077,
    //     "lostFcnt": 259,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3020,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00251",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeaa3",
    //     "appEUI": "app",
    //     "appKEY": "7422EB3F631E9D05593060015BAF5206",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 76,
    //     "prevFrameCounterFcnt": 1104,
    //     "latestFrameCounterFcnt": 1105,
    //     "lostFcnt": 258,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3021,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00252",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeaa4",
    //     "appEUI": "app",
    //     "appKEY": "D8EE36AA908F08AE3F50F93B5F2690A1",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1089,
    //     "latestFrameCounterFcnt": 1090,
    //     "lostFcnt": 252,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3022,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00253",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeaa5",
    //     "appEUI": "app",
    //     "appKEY": "CEDD057C2FCD1CE9F2B02823E025BDEF",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1075,
    //     "latestFrameCounterFcnt": 1076,
    //     "lostFcnt": 286,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3023,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00254",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeaa6",
    //     "appEUI": "app",
    //     "appKEY": "1A5A7FC160869D624CF8A17EA582B017",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1097,
    //     "latestFrameCounterFcnt": 1098,
    //     "lostFcnt": 256,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3024,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00255",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeaa7",
    //     "appEUI": "app",
    //     "appKEY": "E4964A8199991D20B19A9B1986C2BFEC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1093,
    //     "latestFrameCounterFcnt": 1094,
    //     "lostFcnt": 281,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3025,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00256",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeaa8",
    //     "appEUI": "app",
    //     "appKEY": "11C735BAA6984577B241E5C59BBD7D1F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1036,
    //     "latestFrameCounterFcnt": 1038,
    //     "lostFcnt": 309,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3026,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00257",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeaa9",
    //     "appEUI": "app",
    //     "appKEY": "64509EAFE9EE0F8FC37DA8C5B876CDA5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1066,
    //     "latestFrameCounterFcnt": 1067,
    //     "lostFcnt": 196,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3027,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00258",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeaaa",
    //     "appEUI": "app",
    //     "appKEY": "D11AEEC65B0BCA82F2A37C7E2C3D534F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1063,
    //     "latestFrameCounterFcnt": 1064,
    //     "lostFcnt": 346,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3028,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00259",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeaac",
    //     "appEUI": "app",
    //     "appKEY": "D56C9F87A53F961A540FF9134F50697A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1197,
    //     "latestFrameCounterFcnt": 1198,
    //     "lostFcnt": 249,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3029,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00260",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeaad",
    //     "appEUI": "app",
    //     "appKEY": "C306AE0F7CC60C52154CFCE5931EF014",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 64,
    //     "prevFrameCounterFcnt": 1022,
    //     "latestFrameCounterFcnt": 1024,
    //     "lostFcnt": 350,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3030,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00261",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeaae",
    //     "appEUI": "app",
    //     "appKEY": "7EF290237DD6895C279510527B45C080",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1063,
    //     "latestFrameCounterFcnt": 1064,
    //     "lostFcnt": 309,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3031,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00262",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeaaf",
    //     "appEUI": "app",
    //     "appKEY": "F942214477464AC06A30DAD44CB947AE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 79,
    //     "prevFrameCounterFcnt": 1085,
    //     "latestFrameCounterFcnt": 1086,
    //     "lostFcnt": 266,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3032,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00263",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeab1",
    //     "appEUI": "app",
    //     "appKEY": "FB83B24C4D86EF9929DE9F5D0F27FD3D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 1053,
    //     "latestFrameCounterFcnt": 1055,
    //     "lostFcnt": 306,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3033,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00264",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeab2",
    //     "appEUI": "app",
    //     "appKEY": "EF1940BCE50A3CFC30AB0E2646C05C62",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1040,
    //     "latestFrameCounterFcnt": 1042,
    //     "lostFcnt": 392,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3034,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00265",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeab3",
    //     "appEUI": "app",
    //     "appKEY": "5AF8DD972188CCA4C5844A9EA392E8F0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 101,
    //     "prevFrameCounterFcnt": 575,
    //     "latestFrameCounterFcnt": 576,
    //     "lostFcnt": 124,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3035,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00266",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeab4",
    //     "appEUI": "app",
    //     "appKEY": "E2C7BACB5D53AA7E5B6D901C6B860869",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 55,
    //     "prevFrameCounterFcnt": 974,
    //     "latestFrameCounterFcnt": 978,
    //     "lostFcnt": 616,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3036,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00267",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeab5",
    //     "appEUI": "app",
    //     "appKEY": "62F8D4520CC34FC2E706739499608E21",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 62,
    //     "prevFrameCounterFcnt": 1006,
    //     "latestFrameCounterFcnt": 1007,
    //     "lostFcnt": 552,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3037,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00268",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeab6",
    //     "appEUI": "app",
    //     "appKEY": "BE5487645CBFE8723337C04278739A8C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1061,
    //     "latestFrameCounterFcnt": 1062,
    //     "lostFcnt": 253,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3038,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00269",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeab7",
    //     "appEUI": "app",
    //     "appKEY": "C45124A357B52E9DFB16A5F1196677F4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 61,
    //     "prevFrameCounterFcnt": 991,
    //     "latestFrameCounterFcnt": 993,
    //     "lostFcnt": 533,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3039,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00270",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeab8",
    //     "appEUI": "app",
    //     "appKEY": "26EFA58911453953D573FCBD641C8C4A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1035,
    //     "latestFrameCounterFcnt": 1040,
    //     "lostFcnt": 331,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3040,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00271",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeab9",
    //     "appEUI": "app",
    //     "appKEY": "7C475C6AA72B6040CFDC05B3A0F58661",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1073,
    //     "latestFrameCounterFcnt": 1074,
    //     "lostFcnt": 243,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3041,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00272",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeaba",
    //     "appEUI": "app",
    //     "appKEY": "E6024FE7019DD84C1629A2EB02EF63CC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1072,
    //     "latestFrameCounterFcnt": 1074,
    //     "lostFcnt": 271,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3042,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00273",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeabb",
    //     "appEUI": "app",
    //     "appKEY": "45FE5881BF213817754767ECBD50C266",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1071,
    //     "latestFrameCounterFcnt": 1072,
    //     "lostFcnt": 260,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3043,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00274",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeabc",
    //     "appEUI": "app",
    //     "appKEY": "10B7419D879825134B064319FBC9E845",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1141,
    //     "latestFrameCounterFcnt": 1142,
    //     "lostFcnt": 453,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3044,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00275",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872af815",
    //     "appEUI": "app",
    //     "appKEY": "8B602EA640423F534D29FA87E5CE6F3D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 1086,
    //     "latestFrameCounterFcnt": 1087,
    //     "lostFcnt": 237,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3045,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00276",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0327",
    //     "appEUI": "app",
    //     "appKEY": "9B61A86EFA28224C36E1CCFF52FA8C27",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1082,
    //     "latestFrameCounterFcnt": 1083,
    //     "lostFcnt": 217,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3046,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00277",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0328",
    //     "appEUI": "app",
    //     "appKEY": "95B36F6446F7DDB5FAD9EAE763A690A2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 79,
    //     "prevFrameCounterFcnt": 1134,
    //     "latestFrameCounterFcnt": 1135,
    //     "lostFcnt": 250,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3047,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00278",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b032c",
    //     "appEUI": "app",
    //     "appKEY": "671C87BEF3466D3C3F2FAC36748436E7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 1058,
    //     "latestFrameCounterFcnt": 1059,
    //     "lostFcnt": 285,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3048,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00279",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b032d",
    //     "appEUI": "app",
    //     "appKEY": "490A31F019AB83837FBCB13CF93921B2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1049,
    //     "latestFrameCounterFcnt": 1050,
    //     "lostFcnt": 262,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3049,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00280",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0330",
    //     "appEUI": "app",
    //     "appKEY": "3905BAA632E580FEB5165B37535E7DD6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 90,
    //     "prevFrameCounterFcnt": 1089,
    //     "latestFrameCounterFcnt": 1091,
    //     "lostFcnt": 337,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3050,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00281",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0331",
    //     "appEUI": "app",
    //     "appKEY": "9FEC663BD74157AD1AE46BBD8914BCED",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1041,
    //     "latestFrameCounterFcnt": 1042,
    //     "lostFcnt": 290,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3051,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00282",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0335",
    //     "appEUI": "app",
    //     "appKEY": "A87016BC9958647CF39F10822394F7FB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1097,
    //     "latestFrameCounterFcnt": 1098,
    //     "lostFcnt": 261,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3052,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00283",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0336",
    //     "appEUI": "app",
    //     "appKEY": "2075A466DE3A74151EA382242909F8E4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1040,
    //     "latestFrameCounterFcnt": 1041,
    //     "lostFcnt": 198,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3053,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00284",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0337",
    //     "appEUI": "app",
    //     "appKEY": "BF34EEC83F8494D6565F9637A4AF4829",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1029,
    //     "latestFrameCounterFcnt": 1030,
    //     "lostFcnt": 177,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3054,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00285",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0370",
    //     "appEUI": "app",
    //     "appKEY": "80144C8B0C1817E7E723F36CF8416296",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1027,
    //     "latestFrameCounterFcnt": 1028,
    //     "lostFcnt": 168,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3055,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00286",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03c5",
    //     "appEUI": "app",
    //     "appKEY": "A59059D2F9CBAD05EA1E39C9174EA2EE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1073,
    //     "latestFrameCounterFcnt": 1075,
    //     "lostFcnt": 248,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3056,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00287",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03c6",
    //     "appEUI": "app",
    //     "appKEY": "6455B7675B5484669EC98B03E952B158",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 81,
    //     "prevFrameCounterFcnt": 1092,
    //     "latestFrameCounterFcnt": 1093,
    //     "lostFcnt": 198,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3057,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00288",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03c8",
    //     "appEUI": "app",
    //     "appKEY": "4DB2312C5955754732807A537AA47C39",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1043,
    //     "latestFrameCounterFcnt": 1044,
    //     "lostFcnt": 191,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3058,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00289",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03c9",
    //     "appEUI": "app",
    //     "appKEY": "79C8B2729692CCDA3934BFC5C61A8CC2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1059,
    //     "latestFrameCounterFcnt": 1060,
    //     "lostFcnt": 208,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3059,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00290",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03ce",
    //     "appEUI": "app",
    //     "appKEY": "6773DAA3210E1AE70515856A8C5C5C74",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1062,
    //     "latestFrameCounterFcnt": 1063,
    //     "lostFcnt": 331,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3060,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00291",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03d1",
    //     "appEUI": "app",
    //     "appKEY": "C0FF7039D5713D3085420D1DA32BB18E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1091,
    //     "latestFrameCounterFcnt": 1092,
    //     "lostFcnt": 233,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3061,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00292",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03d2",
    //     "appEUI": "app",
    //     "appKEY": "409B7AD9C092431679ABF00AA063045F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1067,
    //     "latestFrameCounterFcnt": 1068,
    //     "lostFcnt": 247,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3062,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00293",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03d3",
    //     "appEUI": "app",
    //     "appKEY": "36B87329DB75C72CD8963E162723A521",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 64,
    //     "prevFrameCounterFcnt": 1048,
    //     "latestFrameCounterFcnt": 1049,
    //     "lostFcnt": 393,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3063,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00294",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03d4",
    //     "appEUI": "app",
    //     "appKEY": "5053E33B4DB3122CD9B78268B9DD128D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1076,
    //     "latestFrameCounterFcnt": 1077,
    //     "lostFcnt": 282,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3064,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00295",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03d5",
    //     "appEUI": "app",
    //     "appKEY": "DEB89E7C48E4431923582A56EE06461F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 65,
    //     "prevFrameCounterFcnt": 1006,
    //     "latestFrameCounterFcnt": 1007,
    //     "lostFcnt": 176,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3065,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00296",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03d6",
    //     "appEUI": "app",
    //     "appKEY": "E04A2700D87F2A73296D55859BB4E69F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1071,
    //     "latestFrameCounterFcnt": 1072,
    //     "lostFcnt": 272,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3066,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00297",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03d7",
    //     "appEUI": "app",
    //     "appKEY": "28EC1D187BA5E4B12CAFADFEBD43B7CB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1083,
    //     "latestFrameCounterFcnt": 1084,
    //     "lostFcnt": 250,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3067,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00298",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03d8",
    //     "appEUI": "app",
    //     "appKEY": "F863BE2020BBE018024CE52127036F16",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 52,
    //     "prevFrameCounterFcnt": 162,
    //     "latestFrameCounterFcnt": 163,
    //     "lostFcnt": -27,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3068,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00299",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03d9",
    //     "appEUI": "app",
    //     "appKEY": "FE9FC134EE776B94DC4350A1361027BB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1089,
    //     "latestFrameCounterFcnt": 1090,
    //     "lostFcnt": 237,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3069,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00300",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aa89b",
    //     "appEUI": "app",
    //     "appKEY": "1FB0755E3B1BEFB792401BDDD894B0EE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1082,
    //     "latestFrameCounterFcnt": 1083,
    //     "lostFcnt": 255,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3070,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00301",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03bf",
    //     "appEUI": "app",
    //     "appKEY": "E33B10B00BA0FED1D54DF995845D2110",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1076,
    //     "latestFrameCounterFcnt": 1077,
    //     "lostFcnt": 253,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3071,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00302",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03c0",
    //     "appEUI": "app",
    //     "appKEY": "D5CA893C0DA23D1D1E6A2B4B6948813A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 76,
    //     "prevFrameCounterFcnt": 1069,
    //     "latestFrameCounterFcnt": 1070,
    //     "lostFcnt": 277,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3072,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00303",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03c1",
    //     "appEUI": "app",
    //     "appKEY": "1A76CD1D4879CBD62734407ABB36D336",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1121,
    //     "latestFrameCounterFcnt": 1122,
    //     "lostFcnt": 267,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3073,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00304",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03c2",
    //     "appEUI": "app",
    //     "appKEY": "3300A08C962F71773817F8440FBC3412",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1086,
    //     "latestFrameCounterFcnt": 1087,
    //     "lostFcnt": 279,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3074,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00305",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03c4",
    //     "appEUI": "app",
    //     "appKEY": "75A2AC048FDFE410FD23BF095B5671CC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 84,
    //     "prevFrameCounterFcnt": 1102,
    //     "latestFrameCounterFcnt": 1103,
    //     "lostFcnt": 266,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3075,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00306",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03ca",
    //     "appEUI": "app",
    //     "appKEY": "679DC5FC453272098D46F07FECD34B07",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1083,
    //     "latestFrameCounterFcnt": 1084,
    //     "lostFcnt": 249,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3076,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00307",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03cc",
    //     "appEUI": "app",
    //     "appKEY": "9CE89658A5F609F43F03E10D79F8ABD8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1035,
    //     "latestFrameCounterFcnt": 1038,
    //     "lostFcnt": 377,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3077,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00308",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03d0",
    //     "appEUI": "app",
    //     "appKEY": "A9608FC33C6477D0C536E6C9752F0CBE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 63,
    //     "prevFrameCounterFcnt": 1019,
    //     "latestFrameCounterFcnt": 1020,
    //     "lostFcnt": 519,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3078,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00309",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03de",
    //     "appEUI": "app",
    //     "appKEY": "FF8586B9E87CDF20688C9323E70EE374",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1093,
    //     "latestFrameCounterFcnt": 1094,
    //     "lostFcnt": 304,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3079,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00310",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03df",
    //     "appEUI": "app",
    //     "appKEY": "A30D87C2BEE825B58F36810DE2CEB588",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1074,
    //     "latestFrameCounterFcnt": 1075,
    //     "lostFcnt": 291,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3080,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00311",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03e2",
    //     "appEUI": "app",
    //     "appKEY": "1A9045496C040ED2DE8357BA45A7BF81",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 63,
    //     "prevFrameCounterFcnt": 985,
    //     "latestFrameCounterFcnt": 986,
    //     "lostFcnt": 538,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3081,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00312",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03e3",
    //     "appEUI": "app",
    //     "appKEY": "6C755CBA6528FA3A44713E344E6EFF36",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 304,
    //     "latestFrameCounterFcnt": 308,
    //     "lostFcnt": 43,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3082,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00313",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03e4",
    //     "appEUI": "app",
    //     "appKEY": "0824DDF1D6F6A3889D19CEF29A02050A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 64,
    //     "prevFrameCounterFcnt": 1002,
    //     "latestFrameCounterFcnt": 1003,
    //     "lostFcnt": 499,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3083,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00314",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03e5",
    //     "appEUI": "app",
    //     "appKEY": "FA629CAD2626C935D1159F3FCCD74E64",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 65,
    //     "prevFrameCounterFcnt": 953,
    //     "latestFrameCounterFcnt": 995,
    //     "lostFcnt": 438,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3084,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00315",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03e6",
    //     "appEUI": "app",
    //     "appKEY": "D6B06F79E225D5ADAF9884A9389FAE6C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 61,
    //     "prevFrameCounterFcnt": 996,
    //     "latestFrameCounterFcnt": 997,
    //     "lostFcnt": 506,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3085,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00316",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03e7",
    //     "appEUI": "app",
    //     "appKEY": "D07EAE2CFD72FFEBA8DCFB0097276B3E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 61,
    //     "prevFrameCounterFcnt": 1052,
    //     "latestFrameCounterFcnt": 1053,
    //     "lostFcnt": 197,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3086,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00317",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03e8",
    //     "appEUI": "app",
    //     "appKEY": "6E53F0630F50B667066861CF2801C98F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 56,
    //     "prevFrameCounterFcnt": 1006,
    //     "latestFrameCounterFcnt": 1007,
    //     "lostFcnt": 425,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3087,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00318",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03e9",
    //     "appEUI": "app",
    //     "appKEY": "498F350C15FD363C47085A1C343D7C41",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 65,
    //     "prevFrameCounterFcnt": 1019,
    //     "latestFrameCounterFcnt": 1020,
    //     "lostFcnt": 187,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3088,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00319",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03eb",
    //     "appEUI": "app",
    //     "appKEY": "556C63CBBB75801B04F97B8B1AC2BF3F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 52,
    //     "prevFrameCounterFcnt": 512,
    //     "latestFrameCounterFcnt": 513,
    //     "lostFcnt": 143,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3089,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00320",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03ec",
    //     "appEUI": "app",
    //     "appKEY": "384373E8486999C9B267A851BE575741",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 17,
    //     "latestFrameCounterFcnt": 47,
    //     "lostFcnt": 38,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3090,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00321",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03ed",
    //     "appEUI": "app",
    //     "appKEY": "B6C6C9BB20F6B03129B8558736BDBFE2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1062,
    //     "latestFrameCounterFcnt": 1063,
    //     "lostFcnt": 327,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3091,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00322",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03ee",
    //     "appEUI": "app",
    //     "appKEY": "C985C144DFA13487CF171D47B2409309",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1047,
    //     "latestFrameCounterFcnt": 1049,
    //     "lostFcnt": 203,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3092,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00323",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03f3",
    //     "appEUI": "app",
    //     "appKEY": "063C64D8FF16209ECB1E56133772D4AB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 1091,
    //     "latestFrameCounterFcnt": 1092,
    //     "lostFcnt": 275,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3093,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00324",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03f8",
    //     "appEUI": "app",
    //     "appKEY": "2077F8A9179B6DC54593D32F8F3E55D7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 1021,
    //     "latestFrameCounterFcnt": 1022,
    //     "lostFcnt": 172,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3094,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00325",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0375",
    //     "appEUI": "app",
    //     "appKEY": "C74807DA1FA28A05CCDB600D3751C546",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1030,
    //     "latestFrameCounterFcnt": 1031,
    //     "lostFcnt": 197,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3095,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00326",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0376",
    //     "appEUI": "app",
    //     "appKEY": "4A4FBBAA97CE6AA61C50632B5404D4B4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1072,
    //     "latestFrameCounterFcnt": 1073,
    //     "lostFcnt": 247,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3096,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00327",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0377",
    //     "appEUI": "app",
    //     "appKEY": "2ACD9C1C1321199060CB1D4CB1BA3C28",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1120,
    //     "latestFrameCounterFcnt": 1121,
    //     "lostFcnt": 252,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3097,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00328",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0378",
    //     "appEUI": "app",
    //     "appKEY": "9C2988113A1118E89E21516CD00DEA75",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 65,
    //     "prevFrameCounterFcnt": 1031,
    //     "latestFrameCounterFcnt": 1032,
    //     "lostFcnt": 443,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3098,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00329",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0379",
    //     "appEUI": "app",
    //     "appKEY": "735446BA51BA0B862D0136ADE584AC12",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 961,
    //     "latestFrameCounterFcnt": 962,
    //     "lostFcnt": 165,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3099,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00330",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b037a",
    //     "appEUI": "app",
    //     "appKEY": "232A02BABC1B52CD3DA7F695EFB049C5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 961,
    //     "latestFrameCounterFcnt": 979,
    //     "lostFcnt": 235,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3100,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00331",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b037b",
    //     "appEUI": "app",
    //     "appKEY": "24EAB2E3D1A97C871DF1C4DE773C8D36",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1048,
    //     "latestFrameCounterFcnt": 1049,
    //     "lostFcnt": 301,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3101,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00332",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b037d",
    //     "appEUI": "app",
    //     "appKEY": "5CB90298AF3ECBD9D7FEC851B825DE55",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1080,
    //     "latestFrameCounterFcnt": 1081,
    //     "lostFcnt": 242,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3102,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00333",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b037e",
    //     "appEUI": "app",
    //     "appKEY": "3C614DA91C86CE46BBEAE44295DF3324",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1011,
    //     "latestFrameCounterFcnt": 1013,
    //     "lostFcnt": 281,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3103,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00334",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0380",
    //     "appEUI": "app",
    //     "appKEY": "ED932B5BBA71D3E70B281F2378DAB033",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 65,
    //     "prevFrameCounterFcnt": 997,
    //     "latestFrameCounterFcnt": 998,
    //     "lostFcnt": 172,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3104,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00335",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0381",
    //     "appEUI": "app",
    //     "appKEY": "48771B4DBF37AA901DC3C13528E0AB2C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 12,
    //     "prevFrameCounterFcnt": 954,
    //     "latestFrameCounterFcnt": 955,
    //     "lostFcnt": 528,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3105,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00336",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0382",
    //     "appEUI": "app",
    //     "appKEY": "543DCF79D0304A5C88FFF971738B0106",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 61,
    //     "prevFrameCounterFcnt": 1032,
    //     "latestFrameCounterFcnt": 1033,
    //     "lostFcnt": 583,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3106,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00337",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0383",
    //     "appEUI": "app",
    //     "appKEY": "6AD512232C8902C0D47AF0A9C3F7AE37",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1095,
    //     "latestFrameCounterFcnt": 1096,
    //     "lostFcnt": 237,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3107,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00338",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0388",
    //     "appEUI": "app",
    //     "appKEY": "5B1BBFD49482117D851A58B02EEEA4B7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 63,
    //     "prevFrameCounterFcnt": 1044,
    //     "latestFrameCounterFcnt": 1045,
    //     "lostFcnt": 287,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3108,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00339",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0389",
    //     "appEUI": "app",
    //     "appKEY": "905093B579719AF9DADF59483B5B6A6C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1092,
    //     "latestFrameCounterFcnt": 1093,
    //     "lostFcnt": 228,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3109,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00340",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b038e",
    //     "appEUI": "app",
    //     "appKEY": "93941DB29AA08945434E7DDB27EA4521",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1092,
    //     "latestFrameCounterFcnt": 1093,
    //     "lostFcnt": 275,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3110,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00341",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0393",
    //     "appEUI": "app",
    //     "appKEY": "A1DD930410FFB0C3121BDD13DBF90AFD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1094,
    //     "latestFrameCounterFcnt": 1098,
    //     "lostFcnt": 297,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3111,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00342",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0399",
    //     "appEUI": "app",
    //     "appKEY": "D7925E5D6578116E471BCC62E67B5725",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1006,
    //     "latestFrameCounterFcnt": 1008,
    //     "lostFcnt": 403,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3112,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00343",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03c7",
    //     "appEUI": "app",
    //     "appKEY": "E38AEE3BE49082C1261D350D514D3EBB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 58,
    //     "prevFrameCounterFcnt": 66,
    //     "latestFrameCounterFcnt": 67,
    //     "lostFcnt": -350,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3113,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00344",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03da",
    //     "appEUI": "app",
    //     "appKEY": "6EF356427169D3E26B19F4768B8FB42B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 60,
    //     "prevFrameCounterFcnt": 988,
    //     "latestFrameCounterFcnt": 990,
    //     "lostFcnt": 603,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3114,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00345",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03db",
    //     "appEUI": "app",
    //     "appKEY": "7CC819C102FAEB28E2989F9DD7E50894",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 26,
    //     "prevFrameCounterFcnt": 113,
    //     "latestFrameCounterFcnt": 4,
    //     "lostFcnt": -155,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3115,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00346",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03dc",
    //     "appEUI": "app",
    //     "appKEY": "CAD40AE2C2B9E745C2D2F3EE4BC3EED5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 63,
    //     "prevFrameCounterFcnt": 105,
    //     "latestFrameCounterFcnt": 109,
    //     "lostFcnt": -221,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3116,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00347",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03dd",
    //     "appEUI": "app",
    //     "appKEY": "9B37AAF20FF0DB4B8FE69FE67BF02BD9",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1007,
    //     "latestFrameCounterFcnt": 1009,
    //     "lostFcnt": 372,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3117,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00348",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03e0",
    //     "appEUI": "app",
    //     "appKEY": "D875890BFB27319C1D22769037A9DB6C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1099,
    //     "latestFrameCounterFcnt": 1100,
    //     "lostFcnt": 275,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3118,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00349",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03e1",
    //     "appEUI": "app",
    //     "appKEY": "EC315F85EE20756EB8B8B3220A496EFE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1116,
    //     "latestFrameCounterFcnt": 1117,
    //     "lostFcnt": 268,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3119,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00350",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b038a",
    //     "appEUI": "app",
    //     "appKEY": "9FBC9DFF7F409A9C2DA77F4655A08E20",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 61,
    //     "prevFrameCounterFcnt": 126,
    //     "latestFrameCounterFcnt": 127,
    //     "lostFcnt": -296,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3120,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00351",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b038f",
    //     "appEUI": "app",
    //     "appKEY": "9F705FF3F23A7BEC924CD5FA13478A68",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 65,
    //     "prevFrameCounterFcnt": 427,
    //     "latestFrameCounterFcnt": 431,
    //     "lostFcnt": 17,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3121,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00352",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0394",
    //     "appEUI": "app",
    //     "appKEY": "706713AA3C797AF6B74076A8D87DF006",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 3,
    //     "latestFrameCounterFcnt": 5,
    //     "lostFcnt": -183,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3122,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00353",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0398",
    //     "appEUI": "app",
    //     "appKEY": "7C2D5D13526E44161A4F0EB4EA83E6B3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 62,
    //     "prevFrameCounterFcnt": 168,
    //     "latestFrameCounterFcnt": 193,
    //     "lostFcnt": -42,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3123,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00354",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b039a",
    //     "appEUI": "app",
    //     "appKEY": "0F0F0E15BC007696B9FC0C6FE11B5505",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1014,
    //     "latestFrameCounterFcnt": 1015,
    //     "lostFcnt": 500,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3124,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00355",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03b3",
    //     "appEUI": "app",
    //     "appKEY": "22860B3384AD287E58F8F04500CB2FC3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1005,
    //     "latestFrameCounterFcnt": 1006,
    //     "lostFcnt": 363,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3125,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00356",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03b4",
    //     "appEUI": "app",
    //     "appKEY": "587821FB148C4CD2F8375A3AD226F571",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 64,
    //     "prevFrameCounterFcnt": 1002,
    //     "latestFrameCounterFcnt": 1003,
    //     "lostFcnt": 410,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3126,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00357",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03b6",
    //     "appEUI": "app",
    //     "appKEY": "3A76E132C108B0A2B6A5F2F11FCF7EF0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1102,
    //     "latestFrameCounterFcnt": 1103,
    //     "lostFcnt": 238,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3127,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00358",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03b7",
    //     "appEUI": "app",
    //     "appKEY": "C5974B04AE2AB73AC6D3593C1F8B54AC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1102,
    //     "latestFrameCounterFcnt": 1103,
    //     "lostFcnt": 285,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3128,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00359",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03b8",
    //     "appEUI": "app",
    //     "appKEY": "4B539D65B4B664870E8E8AAF52422439",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1037,
    //     "latestFrameCounterFcnt": 1050,
    //     "lostFcnt": 516,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3129,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00360",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03b9",
    //     "appEUI": "app",
    //     "appKEY": "778771E897EF6B955EF0208CD79FBD49",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1010,
    //     "latestFrameCounterFcnt": 1029,
    //     "lostFcnt": 414,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3130,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00361",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03ba",
    //     "appEUI": "app",
    //     "appKEY": "2B4A13FF0EFDC23115B16CEC8E8F4F0C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 63,
    //     "prevFrameCounterFcnt": 140,
    //     "latestFrameCounterFcnt": 141,
    //     "lostFcnt": -196,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3131,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00362",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03bb",
    //     "appEUI": "app",
    //     "appKEY": "2FD74354D86115D435759497C668F248",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 63,
    //     "prevFrameCounterFcnt": 197,
    //     "latestFrameCounterFcnt": 199,
    //     "lostFcnt": -266,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3132,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00363",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03bc",
    //     "appEUI": "app",
    //     "appKEY": "4099EE0BFB58126CFCCFD13D0EE7ACFC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1030,
    //     "latestFrameCounterFcnt": 1031,
    //     "lostFcnt": 409,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3133,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00364",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03bd",
    //     "appEUI": "app",
    //     "appKEY": "1554F268D48861202A9D578BB301BE97",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 64,
    //     "prevFrameCounterFcnt": 338,
    //     "latestFrameCounterFcnt": 339,
    //     "lostFcnt": -211,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3134,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00365",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03be",
    //     "appEUI": "app",
    //     "appKEY": "AD534F01755AF3EC39BC978A109BDE5B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 995,
    //     "latestFrameCounterFcnt": 1006,
    //     "lostFcnt": 582,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3135,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00366",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03c3",
    //     "appEUI": "app",
    //     "appKEY": "36350516656A714E3D6C403A77FF28B9",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 1038,
    //     "latestFrameCounterFcnt": 1039,
    //     "lostFcnt": 376,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3136,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00367",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d83",
    //     "appEUI": "app",
    //     "appKEY": "31EEF18BA06B18E823E5F654451788BA",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 58,
    //     "prevFrameCounterFcnt": 521,
    //     "latestFrameCounterFcnt": 522,
    //     "lostFcnt": 299,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3137,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00368",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d84",
    //     "appEUI": "app",
    //     "appKEY": "37260822A0D515427C697CA708DCBBAB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 63,
    //     "prevFrameCounterFcnt": 56,
    //     "latestFrameCounterFcnt": 58,
    //     "lostFcnt": -318,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3138,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00369",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d88",
    //     "appEUI": "app",
    //     "appKEY": "1BBB64ED451E073E50BA32C76B8120E4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 61,
    //     "prevFrameCounterFcnt": 12,
    //     "latestFrameCounterFcnt": 13,
    //     "lostFcnt": -282,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3139,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00370",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d8e",
    //     "appEUI": "app",
    //     "appKEY": "BA2BFC2572AC76C0455AFCE0E0F004A2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1038,
    //     "latestFrameCounterFcnt": 1039,
    //     "lostFcnt": 176,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3140,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00371",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d8f",
    //     "appEUI": "app",
    //     "appKEY": "28B11EFD69A7A2AF8CFBEAE0ACDF36AA",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1058,
    //     "latestFrameCounterFcnt": 1059,
    //     "lostFcnt": 235,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3141,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00372",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d93",
    //     "appEUI": "app",
    //     "appKEY": "284722498A8E019D233DDF50578373CC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1071,
    //     "latestFrameCounterFcnt": 1072,
    //     "lostFcnt": 294,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3142,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00373",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d94",
    //     "appEUI": "app",
    //     "appKEY": "A86A375A1137141E9BF444CB9EAFCFAB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1058,
    //     "latestFrameCounterFcnt": 1060,
    //     "lostFcnt": 312,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3143,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00374",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d95",
    //     "appEUI": "app",
    //     "appKEY": "696AD87B6BBC92C48DA5BF10D941D83C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1042,
    //     "latestFrameCounterFcnt": 1043,
    //     "lostFcnt": 205,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3144,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00375",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d82",
    //     "appEUI": "app",
    //     "appKEY": "2F0A69B0DA0F9C6736DB329294ED2FF7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 1036,
    //     "latestFrameCounterFcnt": 1037,
    //     "lostFcnt": 210,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3145,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00376",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d85",
    //     "appEUI": "app",
    //     "appKEY": "A2A46DDF61CB94D62C1CADAC6AB45C91",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1066,
    //     "latestFrameCounterFcnt": 1067,
    //     "lostFcnt": 266,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3146,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00377",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d87",
    //     "appEUI": "app",
    //     "appKEY": "7DDAE27E6695392B185AA319766FB886",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1072,
    //     "latestFrameCounterFcnt": 1073,
    //     "lostFcnt": 218,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3147,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00378",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d89",
    //     "appEUI": "app",
    //     "appKEY": "4B55033677CF06D4FFA974891B3771DC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1055,
    //     "latestFrameCounterFcnt": 1056,
    //     "lostFcnt": 185,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3148,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00379",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d8b",
    //     "appEUI": "app",
    //     "appKEY": "75C1566D0BB848DF7833F8149237D31C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1105,
    //     "latestFrameCounterFcnt": 1106,
    //     "lostFcnt": 247,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3149,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00380",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d8d",
    //     "appEUI": "app",
    //     "appKEY": "8915F6F9E979DFCA5EBDC2B7EE6686BD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1086,
    //     "latestFrameCounterFcnt": 1087,
    //     "lostFcnt": 233,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3150,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00381",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d90",
    //     "appEUI": "app",
    //     "appKEY": "36EB81BF2A23A24E073D8AEBCADAE237",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 980,
    //     "latestFrameCounterFcnt": 981,
    //     "lostFcnt": 183,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3151,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00382",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d92",
    //     "appEUI": "app",
    //     "appKEY": "CAF15C1355F9DC2D7B6925693ADD8A80",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 42,
    //     "prevFrameCounterFcnt": 750,
    //     "latestFrameCounterFcnt": 752,
    //     "lostFcnt": 475,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3152,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00383",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d97",
    //     "appEUI": "app",
    //     "appKEY": "E8B82D5D101AD95D5F36507A0BB93E7D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 1004,
    //     "latestFrameCounterFcnt": 1005,
    //     "lostFcnt": 519,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3153,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00384",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d98",
    //     "appEUI": "app",
    //     "appKEY": "3D82576846DAF7B50C880681E1C0AAB3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 65,
    //     "prevFrameCounterFcnt": 880,
    //     "latestFrameCounterFcnt": 992,
    //     "lostFcnt": 621,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3154,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00385",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d9a",
    //     "appEUI": "app",
    //     "appKEY": "10C35163C2326E8BD0325A9A6DFD023D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 1068,
    //     "latestFrameCounterFcnt": 1069,
    //     "lostFcnt": 272,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3155,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00386",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d9b",
    //     "appEUI": "app",
    //     "appKEY": "1155716D35EB7CD3D6E1A262BB949E33",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 64,
    //     "prevFrameCounterFcnt": 50,
    //     "latestFrameCounterFcnt": 3,
    //     "lostFcnt": -165,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3156,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00387",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d9c",
    //     "appEUI": "app",
    //     "appKEY": "19C41AE2D4A5CBDA2B039BBD457C44B7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 1000,
    //     "latestFrameCounterFcnt": 1001,
    //     "lostFcnt": 325,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3157,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00388",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d9d",
    //     "appEUI": "app",
    //     "appKEY": "1C659CC284F940665C2843D8541A74C9",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 979,
    //     "latestFrameCounterFcnt": 1007,
    //     "lostFcnt": 462,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3158,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00389",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d9f",
    //     "appEUI": "app",
    //     "appKEY": "EA00A7B907402714C9BC258CC1733EA5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 65,
    //     "prevFrameCounterFcnt": 284,
    //     "latestFrameCounterFcnt": 7,
    //     "lostFcnt": -328,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3159,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00390",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2da0",
    //     "appEUI": "app",
    //     "appKEY": "322596126E4D795D97C653C598A553FF",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1106,
    //     "latestFrameCounterFcnt": 1107,
    //     "lostFcnt": 270,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3160,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00391",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2da1",
    //     "appEUI": "app",
    //     "appKEY": "E7FC36ABE1264F9081771F64EF411BA0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 343,
    //     "latestFrameCounterFcnt": 346,
    //     "lostFcnt": -114,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3161,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00392",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2da2",
    //     "appEUI": "app",
    //     "appKEY": "7189D68A5193F4312697C0BEFC2AA953",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 63,
    //     "prevFrameCounterFcnt": 696,
    //     "latestFrameCounterFcnt": 697,
    //     "lostFcnt": -18,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3162,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00393",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2da4",
    //     "appEUI": "app",
    //     "appKEY": "25A99E366DF27F3FCE7BEC4EC3C04DFC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 61,
    //     "prevFrameCounterFcnt": 971,
    //     "latestFrameCounterFcnt": 992,
    //     "lostFcnt": 600,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3163,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00394",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2db1",
    //     "appEUI": "app",
    //     "appKEY": "B6101703326928B6E94362EE751FE132",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1018,
    //     "latestFrameCounterFcnt": 1021,
    //     "lostFcnt": 323,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3164,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00395",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2db2",
    //     "appEUI": "app",
    //     "appKEY": "013711016C1A7268D8AF5585DB8B7A31",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 64,
    //     "prevFrameCounterFcnt": 5,
    //     "latestFrameCounterFcnt": 6,
    //     "lostFcnt": -504,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3165,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00396",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2db3",
    //     "appEUI": "app",
    //     "appKEY": "0D66648D28B325662B03F9EA8E65EC18",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1074,
    //     "latestFrameCounterFcnt": 1075,
    //     "lostFcnt": 379,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3166,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00397",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2db4",
    //     "appEUI": "app",
    //     "appKEY": "FE3E5ACEBD6A6A2095DBDB8C2C166584",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1049,
    //     "latestFrameCounterFcnt": 1050,
    //     "lostFcnt": 387,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3167,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00398",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2db5",
    //     "appEUI": "app",
    //     "appKEY": "769E9E6111AB46BB04C9E449F3C3D23D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1036,
    //     "latestFrameCounterFcnt": 1037,
    //     "lostFcnt": 197,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3168,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00399",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2db6",
    //     "appEUI": "app",
    //     "appKEY": "75749A9F9A1708E9BA342C30F4BC3241",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 1005,
    //     "latestFrameCounterFcnt": 1006,
    //     "lostFcnt": 157,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3169,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00400",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d99",
    //     "appEUI": "app",
    //     "appKEY": "6BBC639D530B7F100A249D96D3F26DE7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 2,
    //     "prevFrameCounterFcnt": 763,
    //     "latestFrameCounterFcnt": 764,
    //     "lostFcnt": 262,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3170,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00401",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d9e",
    //     "appEUI": "app",
    //     "appKEY": "058CB879B855AD777E40346AC4BEB93D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 782,
    //     "latestFrameCounterFcnt": 783,
    //     "lostFcnt": 193,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3171,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00402",
    //     "description": "Pruebas Diehl",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2da3",
    //     "appEUI": "app",
    //     "appKEY": "246CBAB98AB78C810116D506D57CDFD1",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 31,
    //     "latestFrameCounterFcnt": 32,
    //     "lostFcnt": 11,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb524297",
    //     "messageTime": null
    // },
    // {
    //     "id": 3172,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00403",
    //     "description": "Pruebas Diehl",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3080",
    //     "appEUI": "app",
    //     "appKEY": "A729EE2A18A4E37FA49AF98CEA2A4E63",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 11,
    //     "prevFrameCounterFcnt": 488,
    //     "latestFrameCounterFcnt": 489,
    //     "lostFcnt": 372,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3173,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00404",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3081",
    //     "appEUI": "app",
    //     "appKEY": "E92BE6BC085ED5664D8B04E91F420415",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 727,
    //     "latestFrameCounterFcnt": 728,
    //     "lostFcnt": 300,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3174,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00405",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3082",
    //     "appEUI": "app",
    //     "appKEY": "602462E4372B46C5EC6A7ECE991CADB9",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 3,
    //     "prevFrameCounterFcnt": 598,
    //     "latestFrameCounterFcnt": 699,
    //     "lostFcnt": 334,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3175,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00406",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3086",
    //     "appEUI": "app",
    //     "appKEY": "CF1530D0CA755843E32C5740FC2CEF2F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 11,
    //     "latestFrameCounterFcnt": 40,
    //     "lostFcnt": -161,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3176,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00407",
    //     "description": "Pruebas Diehl",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3087",
    //     "appEUI": "app",
    //     "appKEY": "CE4874D2B13C30A7BB424B48FF025C46",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2,
    //     "prevFrameCounterFcnt": 23,
    //     "latestFrameCounterFcnt": 26,
    //     "lostFcnt": 5,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb524297",
    //     "messageTime": null
    // },
    // {
    //     "id": 3177,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00408",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3088",
    //     "appEUI": "app",
    //     "appKEY": "40729E82A92C2CF3672BA3363A7D2D43",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 769,
    //     "latestFrameCounterFcnt": 770,
    //     "lostFcnt": 217,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3178,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00409",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b308b",
    //     "appEUI": "app",
    //     "appKEY": "9C88CF3D5D924EEEF8FCDF138B96692C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 769,
    //     "latestFrameCounterFcnt": 770,
    //     "lostFcnt": 191,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3179,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00410",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b308c",
    //     "appEUI": "app",
    //     "appKEY": "6F11EA041571052D69716EF868AAFAA5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 741,
    //     "latestFrameCounterFcnt": 742,
    //     "lostFcnt": 165,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3180,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00411",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b308d",
    //     "appEUI": "app",
    //     "appKEY": "BC741521C6C86B3459C9E113501D47DC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1097,
    //     "latestFrameCounterFcnt": 1098,
    //     "lostFcnt": 262,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3181,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00412",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3090",
    //     "appEUI": "app",
    //     "appKEY": "A27F4C5297E63C2FBC38580EE0644447",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 535,
    //     "latestFrameCounterFcnt": 536,
    //     "lostFcnt": 203,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3182,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00413",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3091",
    //     "appEUI": "app",
    //     "appKEY": "F9A698964F2C1E1BDEEAC8E8B33E1DAB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1038,
    //     "latestFrameCounterFcnt": 1039,
    //     "lostFcnt": 231,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3183,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00414",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3092",
    //     "appEUI": "app",
    //     "appKEY": "504692B0F1086A6715DE01B11C2EE364",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 96,
    //     "prevFrameCounterFcnt": 1076,
    //     "latestFrameCounterFcnt": 1077,
    //     "lostFcnt": 281,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3184,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00415",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3095",
    //     "appEUI": "app",
    //     "appKEY": "E3EC2E5BF79479341579EE17AB6332DE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 84,
    //     "prevFrameCounterFcnt": 1027,
    //     "latestFrameCounterFcnt": 1028,
    //     "lostFcnt": 239,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3185,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00416",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3096",
    //     "appEUI": "app",
    //     "appKEY": "74F8AE22445F62C0F6F75D356B7FEFF6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1033,
    //     "latestFrameCounterFcnt": 1034,
    //     "lostFcnt": 204,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3186,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00417",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3097",
    //     "appEUI": "app",
    //     "appKEY": "202CFE82471D934CE9E38DEF7B6AB707",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 747,
    //     "latestFrameCounterFcnt": 748,
    //     "lostFcnt": 157,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3187,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00418",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b309a",
    //     "appEUI": "app",
    //     "appKEY": "F5E8F6BE7709326D29AED4BE3783B5DF",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 76,
    //     "prevFrameCounterFcnt": 428,
    //     "latestFrameCounterFcnt": 429,
    //     "lostFcnt": -48,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3188,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00419",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b309b",
    //     "appEUI": "app",
    //     "appKEY": "0BBB3D40AEA138E29FC1933D87FAFF0B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 185,
    //     "latestFrameCounterFcnt": 186,
    //     "lostFcnt": -325,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3189,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00420",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b309f",
    //     "appEUI": "app",
    //     "appKEY": "C61E749C696DDC29275A9015DAA925EF",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 32,
    //     "latestFrameCounterFcnt": 44,
    //     "lostFcnt": 23,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3190,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00421",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30a0",
    //     "appEUI": "app",
    //     "appKEY": "FE843CBF890AD61302428835FC319502",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 3,
    //     "latestFrameCounterFcnt": 4,
    //     "lostFcnt": -468,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3191,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00422",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30a5",
    //     "appEUI": "app",
    //     "appKEY": "510E24E834B88C773BEEDE30BE8FF9E9",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 764,
    //     "latestFrameCounterFcnt": 765,
    //     "lostFcnt": 244,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3192,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00423",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30a6",
    //     "appEUI": "app",
    //     "appKEY": "D1DBD04F35042F86D892DBB10695F4A8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 774,
    //     "latestFrameCounterFcnt": 775,
    //     "lostFcnt": 176,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3193,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00424",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30a7",
    //     "appEUI": "app",
    //     "appKEY": "B138D8D4994CC8F39C885BEBD2B07FD7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 744,
    //     "latestFrameCounterFcnt": 745,
    //     "lostFcnt": 147,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3194,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00425",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3094",
    //     "appEUI": "app",
    //     "appKEY": "3393E682BD048352E61EDEDFFE2A6575",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 747,
    //     "latestFrameCounterFcnt": 748,
    //     "lostFcnt": 195,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3195,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00426",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3099",
    //     "appEUI": "app",
    //     "appKEY": "28EE2A6B98F2FE40ED9B81B141218700",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 771,
    //     "latestFrameCounterFcnt": 772,
    //     "lostFcnt": 283,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3196,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00427",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b309c",
    //     "appEUI": "app",
    //     "appKEY": "CCBD04726148794DC2332238D3D09F2F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 705,
    //     "latestFrameCounterFcnt": 709,
    //     "lostFcnt": 356,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3197,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00428",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b309e",
    //     "appEUI": "app",
    //     "appKEY": "9E8FA6A8B4E8731B3A1CF73E917E61F7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 2,
    //     "prevFrameCounterFcnt": 136,
    //     "latestFrameCounterFcnt": 137,
    //     "lostFcnt": -128,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3198,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00429",
    //     "description": "Pruebas Diehl",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30a1",
    //     "appEUI": "app",
    //     "appKEY": "D45EEB9D6EA184D584FE5670A6342C7F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 734,
    //     "latestFrameCounterFcnt": 736,
    //     "lostFcnt": 269,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3199,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00430",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30a3",
    //     "appEUI": "app",
    //     "appKEY": "3486A9131017B9179B6F0EB8DDBA675E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1047,
    //     "latestFrameCounterFcnt": 1048,
    //     "lostFcnt": 186,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3200,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00431",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30a9",
    //     "appEUI": "app",
    //     "appKEY": "BB6A2D5A697E35AEEC491871513E8D22",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1026,
    //     "latestFrameCounterFcnt": 1027,
    //     "lostFcnt": 166,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3201,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00432",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30aa",
    //     "appEUI": "app",
    //     "appKEY": "5A2AE6B4F6032C2344B39874F6630123",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1022,
    //     "latestFrameCounterFcnt": 1023,
    //     "lostFcnt": 164,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3202,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00433",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30ab",
    //     "appEUI": "app",
    //     "appKEY": "5AE4B9828D1EB597987382A0238333BD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 76,
    //     "prevFrameCounterFcnt": 1015,
    //     "latestFrameCounterFcnt": 1016,
    //     "lostFcnt": 156,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3203,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00434",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30ac",
    //     "appEUI": "app",
    //     "appKEY": "D9803C9FD3CE1003BB9B9BC7DA0B695B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1085,
    //     "latestFrameCounterFcnt": 1086,
    //     "lostFcnt": 242,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3204,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00435",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30ad",
    //     "appEUI": "app",
    //     "appKEY": "FAB43EF81701757A5EA53DC56BF40319",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1047,
    //     "latestFrameCounterFcnt": 1048,
    //     "lostFcnt": 206,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3205,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00436",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30ae",
    //     "appEUI": "app",
    //     "appKEY": "B26FCE6A2BCD0225F762DBA4792D937B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 76,
    //     "prevFrameCounterFcnt": 1020,
    //     "latestFrameCounterFcnt": 1021,
    //     "lostFcnt": 167,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3206,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00437",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30af",
    //     "appEUI": "app",
    //     "appKEY": "FA269B1D2F232E89D4FC2868BC01CA18",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 104,
    //     "prevFrameCounterFcnt": 1206,
    //     "latestFrameCounterFcnt": 1207,
    //     "lostFcnt": 191,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3207,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00438",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30b0",
    //     "appEUI": "app",
    //     "appKEY": "412DB7C593FDDDF7A13D8364CCF460FD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1007,
    //     "latestFrameCounterFcnt": 1008,
    //     "lostFcnt": 162,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3208,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00439",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30b1",
    //     "appEUI": "app",
    //     "appKEY": "5A15B2BF41DB6BAD6EEF5C6638F1AE8F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 992,
    //     "latestFrameCounterFcnt": 1000,
    //     "lostFcnt": 465,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3209,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00440",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30b2",
    //     "appEUI": "app",
    //     "appKEY": "47DF899D2849C10C2AD0596910B4BA37",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1026,
    //     "latestFrameCounterFcnt": 1027,
    //     "lostFcnt": 162,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3210,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00441",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30b3",
    //     "appEUI": "app",
    //     "appKEY": "5C65663AEBC480868C255BA291D7E7C5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 76,
    //     "prevFrameCounterFcnt": 1124,
    //     "latestFrameCounterFcnt": 1125,
    //     "lostFcnt": 285,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3211,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00442",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30b4",
    //     "appEUI": "app",
    //     "appKEY": "BD11974F9ACEA82D6B20A63A6BA5BD22",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1022,
    //     "latestFrameCounterFcnt": 1023,
    //     "lostFcnt": 177,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3212,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00443",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30b7",
    //     "appEUI": "app",
    //     "appKEY": "9EC3B67DB672300DCC51F6E95CCB120D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 230,
    //     "latestFrameCounterFcnt": 231,
    //     "lostFcnt": -627,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3213,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00444",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30b8",
    //     "appEUI": "app",
    //     "appKEY": "F85D220E41347BBE2F7F01938039709F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1046,
    //     "latestFrameCounterFcnt": 1047,
    //     "lostFcnt": 175,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3214,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00445",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30b9",
    //     "appEUI": "app",
    //     "appKEY": "3AB35329BC7D0765916B579E0248CB25",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1007,
    //     "latestFrameCounterFcnt": 1008,
    //     "lostFcnt": 166,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3215,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00446",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30ba",
    //     "appEUI": "app",
    //     "appKEY": "D700AB2C3C9D6DE6FD764978A9FBA6AF",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1096,
    //     "latestFrameCounterFcnt": 1097,
    //     "lostFcnt": 298,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3216,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00447",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30bb",
    //     "appEUI": "app",
    //     "appKEY": "BAF6A51E0EC746ACC43DB7CBCDEF3802",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1013,
    //     "latestFrameCounterFcnt": 1014,
    //     "lostFcnt": 158,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3217,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00448",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30bc",
    //     "appEUI": "app",
    //     "appKEY": "BCD5474C5EB837FEC94C6B72E9793BC2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1021,
    //     "latestFrameCounterFcnt": 1022,
    //     "lostFcnt": 166,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3218,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00449",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30bd",
    //     "appEUI": "app",
    //     "appKEY": "824F8BD7DFA5E12EDB4185C8060FCD88",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 796,
    //     "latestFrameCounterFcnt": 797,
    //     "lostFcnt": 428,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3219,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00450",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aac15",
    //     "appEUI": "app",
    //     "appKEY": "F36DD7FF5375D3B37893A1DE3C99E076",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 96,
    //     "prevFrameCounterFcnt": 1157,
    //     "latestFrameCounterFcnt": 1158,
    //     "lostFcnt": 181,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3220,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00451",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeb17",
    //     "appEUI": "app",
    //     "appKEY": "A407876923945E329CC2830D6173FBCF",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1065,
    //     "latestFrameCounterFcnt": 1067,
    //     "lostFcnt": 270,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3221,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00452",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b069a",
    //     "appEUI": "app",
    //     "appKEY": "1367913D3F22793913E4AC1BC6C099EF",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 748,
    //     "latestFrameCounterFcnt": 749,
    //     "lostFcnt": 265,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3222,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00453",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b06aa",
    //     "appEUI": "app",
    //     "appKEY": "61666AC92613E3DDB715E8C91AF86AA0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1039,
    //     "latestFrameCounterFcnt": 1040,
    //     "lostFcnt": 446,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3223,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00454",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d3c",
    //     "appEUI": "app",
    //     "appKEY": "967AD3CFDCEAD366D2CC8F7C1617DA2D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1070,
    //     "latestFrameCounterFcnt": 1071,
    //     "lostFcnt": 245,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3224,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00455",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d3d",
    //     "appEUI": "app",
    //     "appKEY": "ED6FDF4DE4481DAF50410005EB5ACDE7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1057,
    //     "latestFrameCounterFcnt": 1071,
    //     "lostFcnt": 353,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3225,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00456",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d40",
    //     "appEUI": "app",
    //     "appKEY": "53D1D201C7C7486B0A5FB17DD11B58C4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 877,
    //     "latestFrameCounterFcnt": 878,
    //     "lostFcnt": 395,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3226,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00457",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d41",
    //     "appEUI": "app",
    //     "appKEY": "F4EB8BC0E577D6193BE04CA549D4D2C5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 698,
    //     "latestFrameCounterFcnt": 699,
    //     "lostFcnt": 195,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3227,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00458",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d42",
    //     "appEUI": "app",
    //     "appKEY": "823F068BA0745407526A50CFA94C470C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 89,
    //     "prevFrameCounterFcnt": 1051,
    //     "latestFrameCounterFcnt": 1052,
    //     "lostFcnt": 419,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3228,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00459",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d45",
    //     "appEUI": "app",
    //     "appKEY": "C2057FD91F0FCF71AC277B4E1347B6AB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 76,
    //     "prevFrameCounterFcnt": 1039,
    //     "latestFrameCounterFcnt": 1040,
    //     "lostFcnt": 194,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3229,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00460",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d46",
    //     "appEUI": "app",
    //     "appKEY": "A4C045F9B20ADA8800E1802C76D65847",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 726,
    //     "latestFrameCounterFcnt": 727,
    //     "lostFcnt": 261,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3230,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00461",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d4a",
    //     "appEUI": "app",
    //     "appKEY": "873EBC29F050472B32E0AB14CC23B911",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 33,
    //     "prevFrameCounterFcnt": 1,
    //     "latestFrameCounterFcnt": 26,
    //     "lostFcnt": -13,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3231,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00462",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d4b",
    //     "appEUI": "app",
    //     "appKEY": "9B0E20954C38B966DF3C47E07B535137",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 799,
    //     "latestFrameCounterFcnt": 800,
    //     "lostFcnt": 251,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3232,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00463",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2d4c",
    //     "appEUI": "app",
    //     "appKEY": "C39E93F5F9F6667C365C9C890A343D99",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 770,
    //     "latestFrameCounterFcnt": 771,
    //     "lostFcnt": 315,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3233,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00464",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b30b5",
    //     "appEUI": "app",
    //     "appKEY": "B7026A6E8D10B317605C72F5EDB7EFA6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 735,
    //     "latestFrameCounterFcnt": 736,
    //     "lostFcnt": 316,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3234,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00465",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b314b",
    //     "appEUI": "app",
    //     "appKEY": "5D325B003D3537A6D2B3E256C9709D89",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1126,
    //     "latestFrameCounterFcnt": 1127,
    //     "lostFcnt": 297,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3235,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00466",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b314c",
    //     "appEUI": "app",
    //     "appKEY": "60B0D783AC029A17247BC3077B9FBC50",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1000,
    //     "latestFrameCounterFcnt": 1001,
    //     "lostFcnt": 524,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3236,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00467",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b314d",
    //     "appEUI": "app",
    //     "appKEY": "A8AA36F1D38BA5889F6AB6C509F40382",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 2,
    //     "prevFrameCounterFcnt": 747,
    //     "latestFrameCounterFcnt": 749,
    //     "lostFcnt": 381,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3237,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00468",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b314e",
    //     "appEUI": "app",
    //     "appKEY": "D074C4D490F60D66ABE4ADB4077D856A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2,
    //     "prevFrameCounterFcnt": 161,
    //     "latestFrameCounterFcnt": 163,
    //     "lostFcnt": 8,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3238,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00469",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3150",
    //     "appEUI": "app",
    //     "appKEY": "217199A74BA98578CA9FEDB1F36211A5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 3,
    //     "prevFrameCounterFcnt": 42,
    //     "latestFrameCounterFcnt": 43,
    //     "lostFcnt": -14,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3239,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00470",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b316d",
    //     "appEUI": "app",
    //     "appKEY": "30669A6519A91931B79C170755BFBBA3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1076,
    //     "latestFrameCounterFcnt": 1077,
    //     "lostFcnt": 242,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3240,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00471",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b316e",
    //     "appEUI": "app",
    //     "appKEY": "9FD6A66482D15073843CDFA82AC35AC8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 734,
    //     "latestFrameCounterFcnt": 735,
    //     "lostFcnt": 152,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3241,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00472",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b316f",
    //     "appEUI": "app",
    //     "appKEY": "E01E109986CF7D906535B3DEEAC7E687",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 724,
    //     "latestFrameCounterFcnt": 725,
    //     "lostFcnt": 143,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3242,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00473",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3170",
    //     "appEUI": "app",
    //     "appKEY": "7133D085A9B4736D1F003A2675A3CA23",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 76,
    //     "prevFrameCounterFcnt": 1029,
    //     "latestFrameCounterFcnt": 1030,
    //     "lostFcnt": 259,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3243,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00474",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3171",
    //     "appEUI": "app",
    //     "appKEY": "3775EF6C83A1BE5A5976DA35CF5D261B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 767,
    //     "latestFrameCounterFcnt": 768,
    //     "lostFcnt": 192,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3244,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00475",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31b1",
    //     "appEUI": "app",
    //     "appKEY": "46D30BBA942052057F44A7FB40D16295",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1090,
    //     "latestFrameCounterFcnt": 1091,
    //     "lostFcnt": 237,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3245,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00476",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31b2",
    //     "appEUI": "app",
    //     "appKEY": "ED8793339AFBF7F8B35F1347C4B267F2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1091,
    //     "latestFrameCounterFcnt": 1092,
    //     "lostFcnt": 218,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3246,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00477",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31b3",
    //     "appEUI": "app",
    //     "appKEY": "82299B196C717D8279F61E7AC198F4EC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 744,
    //     "latestFrameCounterFcnt": 745,
    //     "lostFcnt": 159,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3247,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00478",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31b4",
    //     "appEUI": "app",
    //     "appKEY": "7A7F031A8D4955741579964FC3225C36",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 773,
    //     "latestFrameCounterFcnt": 774,
    //     "lostFcnt": 176,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3248,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00479",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31b5",
    //     "appEUI": "app",
    //     "appKEY": "D1EDB4D13ADA3F9F561CF0CB0151132A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 758,
    //     "latestFrameCounterFcnt": 759,
    //     "lostFcnt": 147,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3249,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00480",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31b6",
    //     "appEUI": "app",
    //     "appKEY": "12CC9158330BE8B281B38927D11D806F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1079,
    //     "latestFrameCounterFcnt": 1080,
    //     "lostFcnt": 226,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3250,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00481",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31b7",
    //     "appEUI": "app",
    //     "appKEY": "84E08C9106A7B736EE7D1B51C2870D58",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1020,
    //     "latestFrameCounterFcnt": 1021,
    //     "lostFcnt": 169,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3251,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00482",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31b8",
    //     "appEUI": "app",
    //     "appKEY": "316F90B8EE3ECEC5C657CD7A6A2917D2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1092,
    //     "latestFrameCounterFcnt": 1093,
    //     "lostFcnt": 243,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3252,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00483",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31b9",
    //     "appEUI": "app",
    //     "appKEY": "40C3C5F2DD7C98281254A2B77C9B2145",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1077,
    //     "latestFrameCounterFcnt": 1078,
    //     "lostFcnt": 221,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3253,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00484",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31bb",
    //     "appEUI": "app",
    //     "appKEY": "2FA4A9B025AA43BB1E3D7CAFCD15C123",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 79,
    //     "prevFrameCounterFcnt": 1100,
    //     "latestFrameCounterFcnt": 1101,
    //     "lostFcnt": 252,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3254,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00485",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31bc",
    //     "appEUI": "app",
    //     "appKEY": "E0CA101C060615DD84134B819F21E887",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1047,
    //     "latestFrameCounterFcnt": 1048,
    //     "lostFcnt": 183,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3255,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00486",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31bd",
    //     "appEUI": "app",
    //     "appKEY": "B6B4D1C14C751E3D3D23E35E622742AB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1035,
    //     "latestFrameCounterFcnt": 1036,
    //     "lostFcnt": 261,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3256,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00487",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31c0",
    //     "appEUI": "app",
    //     "appKEY": "764903F776A3969C7E61847D5263616B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1105,
    //     "latestFrameCounterFcnt": 1106,
    //     "lostFcnt": 235,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3257,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00488",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31c1",
    //     "appEUI": "app",
    //     "appKEY": "D6769D0A550FCFE77A4D6580BC7061ED",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 1100,
    //     "latestFrameCounterFcnt": 1101,
    //     "lostFcnt": 231,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3258,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00489",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31c2",
    //     "appEUI": "app",
    //     "appKEY": "A0118857DDEA0518CE8AF1139727CCAE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1082,
    //     "latestFrameCounterFcnt": 1083,
    //     "lostFcnt": 255,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3259,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00490",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4177",
    //     "appEUI": "app",
    //     "appKEY": "5E3F309C6B7395A35D9835AEA421B672",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 2,
    //     "prevFrameCounterFcnt": 574,
    //     "latestFrameCounterFcnt": 651,
    //     "lostFcnt": 389,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3260,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00491",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b417c",
    //     "appEUI": "app",
    //     "appKEY": "952F467CDDE7D1F763A1438FDD7F9072",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 3,
    //     "prevFrameCounterFcnt": 38,
    //     "latestFrameCounterFcnt": 39,
    //     "lostFcnt": -25,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3261,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00492",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b417f",
    //     "appEUI": "app",
    //     "appKEY": "73F5F12744ABF03DD3DF327BD4F69567",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1093,
    //     "latestFrameCounterFcnt": 1094,
    //     "lostFcnt": 271,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3262,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00493",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4180",
    //     "appEUI": "app",
    //     "appKEY": "98608D2B06219F43599C42C5EC488964",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1050,
    //     "latestFrameCounterFcnt": 1051,
    //     "lostFcnt": 274,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3263,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00494",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4181",
    //     "appEUI": "app",
    //     "appKEY": "27ACD828AF667501EBBDF754FD0429D1",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1068,
    //     "latestFrameCounterFcnt": 1070,
    //     "lostFcnt": 256,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3264,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00495",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4182",
    //     "appEUI": "app",
    //     "appKEY": "0495A46348F2D36B99B3F8BB28C2EA7D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 76,
    //     "prevFrameCounterFcnt": 1070,
    //     "latestFrameCounterFcnt": 1071,
    //     "lostFcnt": 235,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3265,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00496",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4183",
    //     "appEUI": "app",
    //     "appKEY": "0BC1229B8BD287E83D3E2CA472198453",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1015,
    //     "latestFrameCounterFcnt": 1017,
    //     "lostFcnt": 352,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3266,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00497",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4184",
    //     "appEUI": "app",
    //     "appKEY": "E95A8CC7CA38DCA20DDE1C8E96251CB6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1099,
    //     "latestFrameCounterFcnt": 1100,
    //     "lostFcnt": 279,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3267,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00498",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4185",
    //     "appEUI": "app",
    //     "appKEY": "EF716867E70D15D75094B55F54D793A6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1042,
    //     "latestFrameCounterFcnt": 1043,
    //     "lostFcnt": 361,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3268,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00499",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4186",
    //     "appEUI": "app",
    //     "appKEY": "2950C3C1C6E9CE193349381F9EDB7023",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1053,
    //     "latestFrameCounterFcnt": 1054,
    //     "lostFcnt": 303,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3269,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00500",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31ba",
    //     "appEUI": "app",
    //     "appKEY": "A7C31DD381BA068B1D8884D4D920CE99",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1066,
    //     "latestFrameCounterFcnt": 1067,
    //     "lostFcnt": 218,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3270,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00501",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31be",
    //     "appEUI": "app",
    //     "appKEY": "68783213937610698B68AC6920449C09",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1095,
    //     "latestFrameCounterFcnt": 1096,
    //     "lostFcnt": 230,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3271,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00502",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31bf",
    //     "appEUI": "app",
    //     "appKEY": "C07BE2039F92FB7DDF1DD67F307A7654",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1023,
    //     "latestFrameCounterFcnt": 1024,
    //     "lostFcnt": 335,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3272,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00503",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31c3",
    //     "appEUI": "app",
    //     "appKEY": "D9516F8E292612D4169683A5AEB4CBBE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1030,
    //     "latestFrameCounterFcnt": 1031,
    //     "lostFcnt": 531,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3273,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00504",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31c4",
    //     "appEUI": "app",
    //     "appKEY": "FEEBEE1CF992305AA390BAAD30EFA2E8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1098,
    //     "latestFrameCounterFcnt": 1099,
    //     "lostFcnt": 264,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3274,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00505",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b415e",
    //     "appEUI": "app",
    //     "appKEY": "A39835D66507ED4D8A4AE5492310AC48",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 91,
    //     "prevFrameCounterFcnt": 994,
    //     "latestFrameCounterFcnt": 1002,
    //     "lostFcnt": 655,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3275,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00506",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b415f",
    //     "appEUI": "app",
    //     "appKEY": "D72ACCDE0ED6A87389F59945319C3B34",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 736,
    //     "latestFrameCounterFcnt": 737,
    //     "lostFcnt": 163,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3276,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00507",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4160",
    //     "appEUI": "app",
    //     "appKEY": "32B5B9892F18D74B6ED15FC7E2AD546D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1093,
    //     "latestFrameCounterFcnt": 1094,
    //     "lostFcnt": 245,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3277,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00508",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4161",
    //     "appEUI": "app",
    //     "appKEY": "3CE1BF9DB09219A01C81ACBC92D49560",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 783,
    //     "latestFrameCounterFcnt": 784,
    //     "lostFcnt": -51,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3278,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00509",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4162",
    //     "appEUI": "app",
    //     "appKEY": "C2B24669CF42EE97A134C197ADFA963B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1088,
    //     "latestFrameCounterFcnt": 1089,
    //     "lostFcnt": 243,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3279,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00510",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4163",
    //     "appEUI": "app",
    //     "appKEY": "5A813B9835DE71FC9ABE886DBC80D805",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1074,
    //     "latestFrameCounterFcnt": 1080,
    //     "lostFcnt": 280,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3280,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00511",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4164",
    //     "appEUI": "app",
    //     "appKEY": "4E3D98F1E3D7C5BF3258911507AADBF8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1067,
    //     "latestFrameCounterFcnt": 1068,
    //     "lostFcnt": 240,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3281,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00512",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4165",
    //     "appEUI": "app",
    //     "appKEY": "5B7D9401A5C97535FEDD989C4630FA50",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 784,
    //     "latestFrameCounterFcnt": 785,
    //     "lostFcnt": 171,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3282,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00513",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4166",
    //     "appEUI": "app",
    //     "appKEY": "516CC0E76132F9EF0EA59FDD2D68F915",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1081,
    //     "latestFrameCounterFcnt": 1082,
    //     "lostFcnt": 280,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3283,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00514",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4167",
    //     "appEUI": "app",
    //     "appKEY": "CCC784ED14F67D1D985A2FE363552987",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 12,
    //     "latestFrameCounterFcnt": 59,
    //     "lostFcnt": -72,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3284,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00515",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4168",
    //     "appEUI": "app",
    //     "appKEY": "FA67C0BF5CDD4ACF8EBF66661E833908",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 663,
    //     "latestFrameCounterFcnt": 667,
    //     "lostFcnt": 296,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3285,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00516",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4169",
    //     "appEUI": "app",
    //     "appKEY": "4D5612B6ADB177FD345C12798B5A774B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1098,
    //     "latestFrameCounterFcnt": 1099,
    //     "lostFcnt": 237,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3286,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00517",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b416a",
    //     "appEUI": "app",
    //     "appKEY": "406F8048492873A2659CFDFD69AC18B3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 730,
    //     "latestFrameCounterFcnt": 731,
    //     "lostFcnt": 261,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3287,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00518",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b416b",
    //     "appEUI": "app",
    //     "appKEY": "2464DD442CBD116B79C1F2458355AAAD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1107,
    //     "latestFrameCounterFcnt": 1108,
    //     "lostFcnt": 220,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3288,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00519",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b416c",
    //     "appEUI": "app",
    //     "appKEY": "18A0D4ECE94ED292F2FC24AB84368A58",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 3,
    //     "prevFrameCounterFcnt": 518,
    //     "latestFrameCounterFcnt": 521,
    //     "lostFcnt": 330,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3289,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00520",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b416d",
    //     "appEUI": "app",
    //     "appKEY": "2B981B34EEFC434A4BF3A409E0C22ABA",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1071,
    //     "latestFrameCounterFcnt": 1072,
    //     "lostFcnt": 203,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3290,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00521",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b416e",
    //     "appEUI": "app",
    //     "appKEY": "388C4D27B95D1D71FD5FF062EA6F8777",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1046,
    //     "latestFrameCounterFcnt": 1047,
    //     "lostFcnt": 207,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3291,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00522",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b416f",
    //     "appEUI": "app",
    //     "appKEY": "5CE08D812E015E3885CF7884B3E2338A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1093,
    //     "latestFrameCounterFcnt": 1094,
    //     "lostFcnt": 230,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3292,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00523",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4170",
    //     "appEUI": "app",
    //     "appKEY": "22613E364551D3B409854698DDC618FB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1096,
    //     "latestFrameCounterFcnt": 1097,
    //     "lostFcnt": 221,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3293,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00524",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4171",
    //     "appEUI": "app",
    //     "appKEY": "1D3E2788FB8D4ABC36CD482CB1B7E166",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1081,
    //     "latestFrameCounterFcnt": 1082,
    //     "lostFcnt": 249,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3294,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00525",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aa011",
    //     "appEUI": "app",
    //     "appKEY": "28268B01BB90FC404C5E63150C70D948",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1094,
    //     "latestFrameCounterFcnt": 1095,
    //     "lostFcnt": 272,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3295,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00526",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aa014",
    //     "appEUI": "app",
    //     "appKEY": "4E31960837B04D7DD9C0AD76F00F6992",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1009,
    //     "latestFrameCounterFcnt": 1010,
    //     "lostFcnt": 160,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3296,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00527",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aa016",
    //     "appEUI": "app",
    //     "appKEY": "441EC2FBE661EDBC5B8E0FCA84810D3A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1061,
    //     "latestFrameCounterFcnt": 1062,
    //     "lostFcnt": 285,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3297,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00528",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aa3bc",
    //     "appEUI": "app",
    //     "appKEY": "9F0649F0EBC1568EEABF0171BFCCA133",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1081,
    //     "latestFrameCounterFcnt": 1082,
    //     "lostFcnt": 242,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3298,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00529",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aab37",
    //     "appEUI": "app",
    //     "appKEY": "FDE48DB20622A51FD871DBC4CF92C319",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1083,
    //     "latestFrameCounterFcnt": 1084,
    //     "lostFcnt": 216,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3299,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00530",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872ab5d9",
    //     "appEUI": "app",
    //     "appKEY": "27B32CA12A3AA9FD89790C5CD0D10AB3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 76,
    //     "prevFrameCounterFcnt": 1016,
    //     "latestFrameCounterFcnt": 1017,
    //     "lostFcnt": 164,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3300,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00531",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aea97",
    //     "appEUI": "app",
    //     "appKEY": "C015F6804BF937718A7458EB7E869CFB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1014,
    //     "latestFrameCounterFcnt": 1015,
    //     "lostFcnt": 172,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3301,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00532",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aea98",
    //     "appEUI": "app",
    //     "appKEY": "8F4657C45D4EDD12632C6C9EEC23495F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1020,
    //     "latestFrameCounterFcnt": 1021,
    //     "lostFcnt": 184,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3302,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00533",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aea99",
    //     "appEUI": "app",
    //     "appKEY": "3A38D1A0481F175E225DEC733C72E379",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 76,
    //     "prevFrameCounterFcnt": 1030,
    //     "latestFrameCounterFcnt": 1031,
    //     "lostFcnt": 176,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3303,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00534",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aea9d",
    //     "appEUI": "app",
    //     "appKEY": "3CF97D6DB13AB14DDEACF5CB5902CAD2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1018,
    //     "latestFrameCounterFcnt": 1019,
    //     "lostFcnt": 182,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3304,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00535",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aea9e",
    //     "appEUI": "app",
    //     "appKEY": "F79DED45DA403BEE84472B17EB6395A5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1041,
    //     "latestFrameCounterFcnt": 1042,
    //     "lostFcnt": 212,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3305,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00536",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aea9f",
    //     "appEUI": "app",
    //     "appKEY": "C2FE504E11F68107C098E5B655807313",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1013,
    //     "latestFrameCounterFcnt": 1014,
    //     "lostFcnt": 169,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3306,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00537",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872af850",
    //     "appEUI": "app",
    //     "appKEY": "8EA43C9C6AC61F79C44261C911A1C539",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 79,
    //     "prevFrameCounterFcnt": 1012,
    //     "latestFrameCounterFcnt": 1013,
    //     "lostFcnt": 182,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3307,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00538",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b319f",
    //     "appEUI": "app",
    //     "appKEY": "D80C0926DB32A6A1AE5AC70A24EBC3BF",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 106,
    //     "prevFrameCounterFcnt": 1200,
    //     "latestFrameCounterFcnt": 1201,
    //     "lostFcnt": 176,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3308,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00539",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31a0",
    //     "appEUI": "app",
    //     "appKEY": "D260590A299E947CB2D26195E65061A3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1021,
    //     "latestFrameCounterFcnt": 1022,
    //     "lostFcnt": 170,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3309,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00540",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31a1",
    //     "appEUI": "app",
    //     "appKEY": "3AB1A0A1E799C2EB3CEDC74FA8B27759",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 811,
    //     "latestFrameCounterFcnt": 812,
    //     "lostFcnt": 281,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3310,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00541",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31a4",
    //     "appEUI": "app",
    //     "appKEY": "4F9BA2D7B22F0F0FA21E80E3A13E5DB5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 968,
    //     "latestFrameCounterFcnt": 969,
    //     "lostFcnt": 358,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3311,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00542",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31a5",
    //     "appEUI": "app",
    //     "appKEY": "267FCF00B8954E63A2561B569FEA17A4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 102,
    //     "prevFrameCounterFcnt": 1049,
    //     "latestFrameCounterFcnt": 1050,
    //     "lostFcnt": 387,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3312,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00543",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31a6",
    //     "appEUI": "app",
    //     "appKEY": "25FC57798EB3E6C9A763B6898EA17DA6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 3,
    //     "prevFrameCounterFcnt": 610,
    //     "latestFrameCounterFcnt": 611,
    //     "lostFcnt": 284,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3313,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00544",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31a7",
    //     "appEUI": "app",
    //     "appKEY": "85DB2AA5B55950A98DA5E5AD25C85F83",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 793,
    //     "latestFrameCounterFcnt": 794,
    //     "lostFcnt": 170,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3314,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00545",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31a9",
    //     "appEUI": "app",
    //     "appKEY": "3FA9831F61494BF36F4CF174089EB824",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1076,
    //     "latestFrameCounterFcnt": 1077,
    //     "lostFcnt": 242,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3315,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00546",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31aa",
    //     "appEUI": "app",
    //     "appKEY": "FA6FC22A07B30DB192F0C4866C43418A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 768,
    //     "latestFrameCounterFcnt": 769,
    //     "lostFcnt": 187,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3316,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00547",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31ac",
    //     "appEUI": "app",
    //     "appKEY": "F65DE3D48F008EF1DFCB24B5B90E108E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 4,
    //     "latestFrameCounterFcnt": 10,
    //     "lostFcnt": -274,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3317,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00548",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31ad",
    //     "appEUI": "app",
    //     "appKEY": "175EC207F675C828399A0B97262EA3C6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 804,
    //     "latestFrameCounterFcnt": 805,
    //     "lostFcnt": 174,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3318,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00549",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31ae",
    //     "appEUI": "app",
    //     "appKEY": "DE0E4964DF1B5EC44C0BC1B6C966405C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 585,
    //     "latestFrameCounterFcnt": 600,
    //     "lostFcnt": 335,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3319,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00550",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b319d",
    //     "appEUI": "app",
    //     "appKEY": "29D299EBDDF6B82EFC22F2837E9FE765",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1090,
    //     "latestFrameCounterFcnt": 1091,
    //     "lostFcnt": 245,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3320,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00551",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b319e",
    //     "appEUI": "app",
    //     "appKEY": "18F8BCF70F6246E5895E685FA3DC68BC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 20,
    //     "prevFrameCounterFcnt": 127,
    //     "latestFrameCounterFcnt": 135,
    //     "lostFcnt": 44,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3321,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00552",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31a2",
    //     "appEUI": "app",
    //     "appKEY": "DD9BC14F6E3475CC2AEFB8B8F3A7B030",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1058,
    //     "latestFrameCounterFcnt": 1059,
    //     "lostFcnt": 252,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3322,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00553",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31a3",
    //     "appEUI": "app",
    //     "appKEY": "8818F5DDAE8E97A0BA4639B865FFDDB9",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 250,
    //     "latestFrameCounterFcnt": 259,
    //     "lostFcnt": 98,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3323,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00554",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31a8",
    //     "appEUI": "app",
    //     "appKEY": "76C041B1401078EDA8D60C2BE1AA1D86",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 758,
    //     "latestFrameCounterFcnt": 759,
    //     "lostFcnt": 279,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3324,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00555",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b414a",
    //     "appEUI": "app",
    //     "appKEY": "6AC0BCF58011287A52C01E95113D7B1D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1067,
    //     "latestFrameCounterFcnt": 1068,
    //     "lostFcnt": 254,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3325,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00556",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b414b",
    //     "appEUI": "app",
    //     "appKEY": "ED0AD0A7308750E8CF83E0660AB1419E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1114,
    //     "latestFrameCounterFcnt": 1115,
    //     "lostFcnt": 262,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3326,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00557",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b414c",
    //     "appEUI": "app",
    //     "appKEY": "C458D53927569B39C6172FB056615762",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 4,
    //     "prevFrameCounterFcnt": 198,
    //     "latestFrameCounterFcnt": 201,
    //     "lostFcnt": 113,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3327,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00558",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b414d",
    //     "appEUI": "app",
    //     "appKEY": "278795B6DB53ACDDB010266240A40449",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 983,
    //     "latestFrameCounterFcnt": 991,
    //     "lostFcnt": 689,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3328,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00559",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b414e",
    //     "appEUI": "app",
    //     "appKEY": "EB1EB82B0563E3EF80095364D8F227EF",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 756,
    //     "latestFrameCounterFcnt": 757,
    //     "lostFcnt": 14,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3329,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00560",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b414f",
    //     "appEUI": "app",
    //     "appKEY": "3664EEA6F99EC24E49DB53EA4B9AEC8E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 64,
    //     "prevFrameCounterFcnt": 1032,
    //     "latestFrameCounterFcnt": 1033,
    //     "lostFcnt": 177,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3330,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00561",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4150",
    //     "appEUI": "app",
    //     "appKEY": "B9C84CAB190E2AF17A2F3E7928E54DDD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 76,
    //     "prevFrameCounterFcnt": 1086,
    //     "latestFrameCounterFcnt": 1087,
    //     "lostFcnt": 268,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3331,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00562",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4151",
    //     "appEUI": "app",
    //     "appKEY": "E087A47B78FA6DE24303C22FB20EA41F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1078,
    //     "latestFrameCounterFcnt": 1079,
    //     "lostFcnt": 324,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3332,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00563",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4152",
    //     "appEUI": "app",
    //     "appKEY": "D1BC439E7E72334A0D8CE8F649F96250",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 840,
    //     "latestFrameCounterFcnt": 842,
    //     "lostFcnt": 293,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3333,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00564",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4153",
    //     "appEUI": "app",
    //     "appKEY": "40D41D52BF0D662A92F173EA33325366",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1061,
    //     "latestFrameCounterFcnt": 1062,
    //     "lostFcnt": 207,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3334,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00565",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4154",
    //     "appEUI": "app",
    //     "appKEY": "9CE22F8DEB55C3BD9E0CEB1D7E4BEE9C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 76,
    //     "prevFrameCounterFcnt": 1087,
    //     "latestFrameCounterFcnt": 1088,
    //     "lostFcnt": 244,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3335,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00566",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4155",
    //     "appEUI": "app",
    //     "appKEY": "F95DFA1FE9A7C5A1508CB242D3FE4199",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1063,
    //     "latestFrameCounterFcnt": 1064,
    //     "lostFcnt": 221,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3336,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00567",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4156",
    //     "appEUI": "app",
    //     "appKEY": "F88DA2C5FF2529C417FDEB47F0D19F8A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1074,
    //     "latestFrameCounterFcnt": 1075,
    //     "lostFcnt": 239,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3337,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00568",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4157",
    //     "appEUI": "app",
    //     "appKEY": "37EF93B51E302BA744FB9C9FBFEF1DFF",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1056,
    //     "latestFrameCounterFcnt": 1057,
    //     "lostFcnt": 201,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3338,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00569",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4158",
    //     "appEUI": "app",
    //     "appKEY": "C88DDE1F3F5EE93BBE2574EAA9916087",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1047,
    //     "latestFrameCounterFcnt": 1048,
    //     "lostFcnt": 194,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3339,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00570",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b4159",
    //     "appEUI": "app",
    //     "appKEY": "F53647316BC5ECE922804D119170D00E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 800,
    //     "latestFrameCounterFcnt": 801,
    //     "lostFcnt": 179,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3340,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00571",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b415a",
    //     "appEUI": "app",
    //     "appKEY": "E79B062BA1EF1A3C91258C54E1FEF7D4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 76,
    //     "prevFrameCounterFcnt": 1031,
    //     "latestFrameCounterFcnt": 1033,
    //     "lostFcnt": 348,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3341,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00572",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b415b",
    //     "appEUI": "app",
    //     "appKEY": "57F9AA4F82713ECF11F7EF94CD0BF500",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1081,
    //     "latestFrameCounterFcnt": 1082,
    //     "lostFcnt": 260,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3342,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00573",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b415c",
    //     "appEUI": "app",
    //     "appKEY": "43B93ED5ED8F617D7B50C17B5B7D0F45",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1102,
    //     "latestFrameCounterFcnt": 1103,
    //     "lostFcnt": 250,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3343,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00574",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b415d",
    //     "appEUI": "app",
    //     "appKEY": "FEF86377F4A2BC23ACD452299A625187",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 88,
    //     "prevFrameCounterFcnt": 763,
    //     "latestFrameCounterFcnt": 764,
    //     "lostFcnt": -12,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3344,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00575",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aea9a",
    //     "appEUI": "app",
    //     "appKEY": "1F84B603B57FB8474DCA330982CDE73E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1085,
    //     "latestFrameCounterFcnt": 1086,
    //     "lostFcnt": 358,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3345,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00576",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aea9b",
    //     "appEUI": "app",
    //     "appKEY": "278594305F0208D079873B9CC04F36DB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1070,
    //     "latestFrameCounterFcnt": 1071,
    //     "lostFcnt": 282,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3346,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00577",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeaa0",
    //     "appEUI": "app",
    //     "appKEY": "D0FE16D58D3C096F228DD09D8EB5FCCF",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1068,
    //     "latestFrameCounterFcnt": 1069,
    //     "lostFcnt": 315,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3347,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00578",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872aeaa1",
    //     "appEUI": "app",
    //     "appKEY": "6CAE643CCCB5443C18911854294BAD18",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 111,
    //     "prevFrameCounterFcnt": 1095,
    //     "latestFrameCounterFcnt": 1096,
    //     "lostFcnt": 356,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3348,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00579",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3189",
    //     "appEUI": "app",
    //     "appKEY": "611E8D47C98989CD6F2E4397F2A4FD2C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 103,
    //     "prevFrameCounterFcnt": 1216,
    //     "latestFrameCounterFcnt": 1217,
    //     "lostFcnt": 196,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3349,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00580",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b318a",
    //     "appEUI": "app",
    //     "appKEY": "B240E71318575986B5B106BF7418A1E9",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1020,
    //     "latestFrameCounterFcnt": 1021,
    //     "lostFcnt": 156,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3350,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00581",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b318b",
    //     "appEUI": "app",
    //     "appKEY": "877B4C34945836C288C615B861AB7006",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1008,
    //     "latestFrameCounterFcnt": 1009,
    //     "lostFcnt": 184,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3351,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00582",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b318c",
    //     "appEUI": "app",
    //     "appKEY": "F03F3E9635470F9B8F70EF8BAD1BE02A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 2,
    //     "prevFrameCounterFcnt": 120,
    //     "latestFrameCounterFcnt": 122,
    //     "lostFcnt": -87,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3352,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00583",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b318d",
    //     "appEUI": "app",
    //     "appKEY": "4517D52ECA4C920A3A14981236AF0753",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1020,
    //     "latestFrameCounterFcnt": 1021,
    //     "lostFcnt": 176,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3353,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00584",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b318e",
    //     "appEUI": "app",
    //     "appKEY": "0C0E17619D53D352491991776890BF9B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1031,
    //     "latestFrameCounterFcnt": 1032,
    //     "lostFcnt": 339,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3354,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00585",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b318f",
    //     "appEUI": "app",
    //     "appKEY": "7114037EB7E9B2F0E3A954BE1AE226C6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1034,
    //     "latestFrameCounterFcnt": 1035,
    //     "lostFcnt": 192,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3355,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00586",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3190",
    //     "appEUI": "app",
    //     "appKEY": "F718FE97DEEAC98489F6C160D3E27BC2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1038,
    //     "latestFrameCounterFcnt": 1039,
    //     "lostFcnt": 172,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3356,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00587",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3191",
    //     "appEUI": "app",
    //     "appKEY": "0029C17A3BBC40717E0CC3EA1CF73F0D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 2,
    //     "prevFrameCounterFcnt": 781,
    //     "latestFrameCounterFcnt": 782,
    //     "lostFcnt": 213,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3357,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00588",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3192",
    //     "appEUI": "app",
    //     "appKEY": "C97E2F81E3158B7D9A9AB606B11BD5C0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1084,
    //     "latestFrameCounterFcnt": 1086,
    //     "lostFcnt": 250,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3358,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00589",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3193",
    //     "appEUI": "app",
    //     "appKEY": "194627BEDDD40B2DB3925FFAE8E043AA",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1014,
    //     "latestFrameCounterFcnt": 1015,
    //     "lostFcnt": 189,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3359,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00590",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3194",
    //     "appEUI": "app",
    //     "appKEY": "6BB9B84C8F905E330700C11FAD7F3FDC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1098,
    //     "latestFrameCounterFcnt": 1099,
    //     "lostFcnt": 252,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3360,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00591",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3195",
    //     "appEUI": "app",
    //     "appKEY": "4CB222D73CC600C199358C7162A1391E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1084,
    //     "latestFrameCounterFcnt": 1085,
    //     "lostFcnt": 232,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3361,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00592",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3196",
    //     "appEUI": "app",
    //     "appKEY": "FE7DF3367DEA409D8CC1D22C8D49A161",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1058,
    //     "latestFrameCounterFcnt": 1059,
    //     "lostFcnt": 266,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3362,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00593",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3197",
    //     "appEUI": "app",
    //     "appKEY": "46E79E735D84B65CE097ECD5020E78EA",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1114,
    //     "latestFrameCounterFcnt": 1115,
    //     "lostFcnt": 213,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3363,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00594",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3198",
    //     "appEUI": "app",
    //     "appKEY": "9E9273177CB5070E2D7B841CD60A0BAC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1061,
    //     "latestFrameCounterFcnt": 1062,
    //     "lostFcnt": 279,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3364,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00595",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3199",
    //     "appEUI": "app",
    //     "appKEY": "46C3D4EF8B926D14028C486EF1ED2C1C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1085,
    //     "latestFrameCounterFcnt": 1086,
    //     "lostFcnt": 247,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3365,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00596",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b319a",
    //     "appEUI": "app",
    //     "appKEY": "F5FEBE56311728739FF3D43ED5B03F57",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1072,
    //     "latestFrameCounterFcnt": 1073,
    //     "lostFcnt": 249,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3366,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00597",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31ab",
    //     "appEUI": "app",
    //     "appKEY": "64AC98148FCC096E8A259C34854D0800",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1050,
    //     "latestFrameCounterFcnt": 1051,
    //     "lostFcnt": 195,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3367,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00598",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31af",
    //     "appEUI": "app",
    //     "appKEY": "BE8623599D710C71519FF23CF791074B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1086,
    //     "latestFrameCounterFcnt": 1087,
    //     "lostFcnt": 246,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3368,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00599",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b31b0",
    //     "appEUI": "app",
    //     "appKEY": "B33C6BE8EFA646F954F18ECB8522E3A6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1068,
    //     "latestFrameCounterFcnt": 1069,
    //     "lostFcnt": 238,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3369,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00600",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3160",
    //     "appEUI": "app",
    //     "appKEY": "843B4A7A73C4ECEBD91800B0B35A5F23",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1012,
    //     "latestFrameCounterFcnt": 1013,
    //     "lostFcnt": 172,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3370,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00601",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3162",
    //     "appEUI": "app",
    //     "appKEY": "4FC7B09233C40D3300C672DAA81FB366",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1003,
    //     "latestFrameCounterFcnt": 1004,
    //     "lostFcnt": 162,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3371,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00602",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3163",
    //     "appEUI": "app",
    //     "appKEY": "CD7D1FBE45BF35E699DD421103683A44",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1008,
    //     "latestFrameCounterFcnt": 1009,
    //     "lostFcnt": 170,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3372,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00603",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3173",
    //     "appEUI": "app",
    //     "appKEY": "258AF86191A53DA9F05DDBDBC1522B44",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 994,
    //     "latestFrameCounterFcnt": 995,
    //     "lostFcnt": 162,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3373,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00604",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3174",
    //     "appEUI": "app",
    //     "appKEY": "ABF3DAE78DF51B7C9A2E98D8FD035AB0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1024,
    //     "latestFrameCounterFcnt": 1025,
    //     "lostFcnt": 168,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3374,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00605",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3175",
    //     "appEUI": "app",
    //     "appKEY": "734354AAA13622CAF2A34422A1FB5329",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1026,
    //     "latestFrameCounterFcnt": 1027,
    //     "lostFcnt": 188,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3375,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00606",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3176",
    //     "appEUI": "app",
    //     "appKEY": "1AD949C778498412D043BC0231FA5463",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 992,
    //     "latestFrameCounterFcnt": 993,
    //     "lostFcnt": 176,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3376,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00607",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3177",
    //     "appEUI": "app",
    //     "appKEY": "5C53DAF03B17ED5C306698223BFF7D49",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 996,
    //     "latestFrameCounterFcnt": 997,
    //     "lostFcnt": 156,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3377,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00608",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3178",
    //     "appEUI": "app",
    //     "appKEY": "503FFFE50EF6827CA7D72E8DC872E539",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1109,
    //     "latestFrameCounterFcnt": 1110,
    //     "lostFcnt": 259,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3378,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00609",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3179",
    //     "appEUI": "app",
    //     "appKEY": "D5CC2D3B63A7714FB693757DB84948FB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 85,
    //     "prevFrameCounterFcnt": 1129,
    //     "latestFrameCounterFcnt": 1130,
    //     "lostFcnt": 265,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3379,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00610",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b317a",
    //     "appEUI": "app",
    //     "appKEY": "14A927F6542C645EB96B55FA1D174554",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1095,
    //     "latestFrameCounterFcnt": 1096,
    //     "lostFcnt": 296,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3380,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00611",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b317b",
    //     "appEUI": "app",
    //     "appKEY": "E207295E816E482C3108276536B966EE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1073,
    //     "latestFrameCounterFcnt": 1074,
    //     "lostFcnt": 330,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3381,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00612",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b317c",
    //     "appEUI": "app",
    //     "appKEY": "A1FD6E096BA17A3742487220A7C5473D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1086,
    //     "latestFrameCounterFcnt": 1087,
    //     "lostFcnt": 223,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3382,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00613",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b317d",
    //     "appEUI": "app",
    //     "appKEY": "01E0B13B18F0C3720FF698D4364012A7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1056,
    //     "latestFrameCounterFcnt": 1059,
    //     "lostFcnt": 241,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3383,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00614",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b317f",
    //     "appEUI": "app",
    //     "appKEY": "E841F4624F25EA264364403BAAD82DC7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1011,
    //     "latestFrameCounterFcnt": 1012,
    //     "lostFcnt": 166,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3384,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00615",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3180",
    //     "appEUI": "app",
    //     "appKEY": "B69DD483987C5AB5FF3371314E2FA7B8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 76,
    //     "prevFrameCounterFcnt": 1072,
    //     "latestFrameCounterFcnt": 1073,
    //     "lostFcnt": 242,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3385,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00616",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3182",
    //     "appEUI": "app",
    //     "appKEY": "F36182E19EF1512D83801408C10BF8AD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1062,
    //     "latestFrameCounterFcnt": 1063,
    //     "lostFcnt": 308,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3386,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00617",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3183",
    //     "appEUI": "app",
    //     "appKEY": "0740C2D9F1678D5F90C5701DF76F8D96",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1032,
    //     "latestFrameCounterFcnt": 1033,
    //     "lostFcnt": 185,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3387,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00618",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3184",
    //     "appEUI": "app",
    //     "appKEY": "7EBCA70335D97EC12D5E4D1056844BD9",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1054,
    //     "latestFrameCounterFcnt": 1055,
    //     "lostFcnt": 196,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3388,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00619",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3185",
    //     "appEUI": "app",
    //     "appKEY": "8A0006ECD679514CE4CD9DDF1654F270",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 65,
    //     "prevFrameCounterFcnt": 1055,
    //     "latestFrameCounterFcnt": 1056,
    //     "lostFcnt": 217,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3389,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00620",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3186",
    //     "appEUI": "app",
    //     "appKEY": "10C1BC240BD107A0B7B67ECE6465C87D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1053,
    //     "latestFrameCounterFcnt": 1054,
    //     "lostFcnt": 198,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3390,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00621",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3187",
    //     "appEUI": "app",
    //     "appKEY": "201C963411891F4CC1CD25E1EC4E910A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1029,
    //     "latestFrameCounterFcnt": 1030,
    //     "lostFcnt": 200,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3391,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00622",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3188",
    //     "appEUI": "app",
    //     "appKEY": "77486B5B91AD251FEDFC64294E5EF8FC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1085,
    //     "latestFrameCounterFcnt": 1086,
    //     "lostFcnt": 253,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3392,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00623",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b319b",
    //     "appEUI": "app",
    //     "appKEY": "6ADF4EE233182B0CA6BD99D922493B9A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1071,
    //     "latestFrameCounterFcnt": 1072,
    //     "lostFcnt": 262,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3393,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00624",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b319c",
    //     "appEUI": "app",
    //     "appKEY": "05368566DE41EF47490FF7DB1F57A3A7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1070,
    //     "latestFrameCounterFcnt": 1072,
    //     "lostFcnt": 255,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3394,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00625",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25bd",
    //     "appEUI": "app",
    //     "appKEY": "CBAA02A5A638D5C7E469239389F4058E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1039,
    //     "latestFrameCounterFcnt": 1040,
    //     "lostFcnt": 191,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3395,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00626",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25be",
    //     "appEUI": "app",
    //     "appKEY": "2C28E67A048650E9B9720D592AF55559",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 65,
    //     "prevFrameCounterFcnt": 1024,
    //     "latestFrameCounterFcnt": 1025,
    //     "lostFcnt": 173,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3396,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00627",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25bf",
    //     "appEUI": "app",
    //     "appKEY": "5EBA5867C724B3F6A09878BFC17A9CB9",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 83,
    //     "prevFrameCounterFcnt": 1200,
    //     "latestFrameCounterFcnt": 1201,
    //     "lostFcnt": 216,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3397,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00628",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25c0",
    //     "appEUI": "app",
    //     "appKEY": "5CE62AA78077C1C056BB8719453A836A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1089,
    //     "latestFrameCounterFcnt": 1090,
    //     "lostFcnt": 222,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3398,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00629",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25c1",
    //     "appEUI": "app",
    //     "appKEY": "F3122A20C8C3AF52652577DDB26DBC19",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 64,
    //     "prevFrameCounterFcnt": 908,
    //     "latestFrameCounterFcnt": 912,
    //     "lostFcnt": 433,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3399,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00630",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25c2",
    //     "appEUI": "app",
    //     "appKEY": "985D93EE75689C54B31F1DEF3E1DB845",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 1006,
    //     "latestFrameCounterFcnt": 1007,
    //     "lostFcnt": 181,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3400,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00631",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25c3",
    //     "appEUI": "app",
    //     "appKEY": "EAC4FDE9AD0E00F6A67AB01659A54453",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1005,
    //     "latestFrameCounterFcnt": 1006,
    //     "lostFcnt": 160,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3401,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00632",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25c4",
    //     "appEUI": "app",
    //     "appKEY": "8259923A1971087103B97CDD86DAD951",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 65,
    //     "prevFrameCounterFcnt": 806,
    //     "latestFrameCounterFcnt": 844,
    //     "lostFcnt": 533,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3402,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00633",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25c5",
    //     "appEUI": "app",
    //     "appKEY": "D3100EAED0D7A0623996C7DCFA821A54",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1076,
    //     "latestFrameCounterFcnt": 1077,
    //     "lostFcnt": 287,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3403,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00634",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25c6",
    //     "appEUI": "app",
    //     "appKEY": "7D17F5476599E048DB3F8F6ACF6C2A51",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1035,
    //     "latestFrameCounterFcnt": 1036,
    //     "lostFcnt": 425,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3404,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00635",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25c7",
    //     "appEUI": "app",
    //     "appKEY": "7A8DC938A2A2913A27E8F7892E8AFB09",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 65,
    //     "prevFrameCounterFcnt": 1012,
    //     "latestFrameCounterFcnt": 1013,
    //     "lostFcnt": 187,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3405,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00636",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25c8",
    //     "appEUI": "app",
    //     "appKEY": "F89D3C3D799E30728882F39C95F59B1C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1000,
    //     "latestFrameCounterFcnt": 1001,
    //     "lostFcnt": 161,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3406,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00637",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25c9",
    //     "appEUI": "app",
    //     "appKEY": "466A290E27A6B3A06F07A8726A509E4E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1029,
    //     "latestFrameCounterFcnt": 1031,
    //     "lostFcnt": 427,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3407,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00638",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25ca",
    //     "appEUI": "app",
    //     "appKEY": "0E6AEBA21A15192C5DF385BDC72C6298",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 64,
    //     "prevFrameCounterFcnt": 1043,
    //     "latestFrameCounterFcnt": 1045,
    //     "lostFcnt": 323,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3408,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00639",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25cb",
    //     "appEUI": "app",
    //     "appKEY": "0C24734038CE0CEB43399ADCAE66E002",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1043,
    //     "latestFrameCounterFcnt": 1044,
    //     "lostFcnt": 394,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3409,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00640",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25cc",
    //     "appEUI": "app",
    //     "appKEY": "0C4F81752DA3F74EFC9D98CA2F3792E6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 1009,
    //     "latestFrameCounterFcnt": 1010,
    //     "lostFcnt": 153,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3410,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00641",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25cd",
    //     "appEUI": "app",
    //     "appKEY": "C6C5A862E6CD6C6592BD7CB7EADC6F96",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1001,
    //     "latestFrameCounterFcnt": 1002,
    //     "lostFcnt": 157,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3411,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00642",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25ce",
    //     "appEUI": "app",
    //     "appKEY": "A9EF77CD61908908FECBB3CD695B3BD8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1012,
    //     "latestFrameCounterFcnt": 1013,
    //     "lostFcnt": 173,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3412,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00643",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25cf",
    //     "appEUI": "app",
    //     "appKEY": "B641D409DB8845D33F5CF5178460F7B8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1004,
    //     "latestFrameCounterFcnt": 1005,
    //     "lostFcnt": 172,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3413,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00644",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25d0",
    //     "appEUI": "app",
    //     "appKEY": "160A6D1724A3F480B0285C93D21D820E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 65,
    //     "prevFrameCounterFcnt": 1011,
    //     "latestFrameCounterFcnt": 1012,
    //     "lostFcnt": 184,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3414,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00645",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25d1",
    //     "appEUI": "app",
    //     "appKEY": "6A3329A3CB0CEDFD0A79E2D48AFAC69E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1080,
    //     "latestFrameCounterFcnt": 1081,
    //     "lostFcnt": 213,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3415,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00646",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25d2",
    //     "appEUI": "app",
    //     "appKEY": "66F284DB24BB30B58B63F5CD882402E8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1119,
    //     "latestFrameCounterFcnt": 1120,
    //     "lostFcnt": 264,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3416,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00647",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25d3",
    //     "appEUI": "app",
    //     "appKEY": "980D8E78FA5CE9AC1B6516E82A380A3C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1021,
    //     "latestFrameCounterFcnt": 1022,
    //     "lostFcnt": 175,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3417,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00648",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25d4",
    //     "appEUI": "app",
    //     "appKEY": "011D998DAB92421C5879998FC9E672D1",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 1005,
    //     "latestFrameCounterFcnt": 1006,
    //     "lostFcnt": 171,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3418,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00649",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25d5",
    //     "appEUI": "app",
    //     "appKEY": "1811BC045AEB0A2D2025E712A79E405F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1017,
    //     "latestFrameCounterFcnt": 1018,
    //     "lostFcnt": 181,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3419,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00650",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25d6",
    //     "appEUI": "app",
    //     "appKEY": "F36C31F846F1C424543A10D2B583AE63",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1063,
    //     "latestFrameCounterFcnt": 1064,
    //     "lostFcnt": 330,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3420,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00651",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25d7",
    //     "appEUI": "app",
    //     "appKEY": "AC5156B30D6EA40DF2D8B717F76CB5F0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1064,
    //     "latestFrameCounterFcnt": 1066,
    //     "lostFcnt": 277,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3421,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00652",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25d8",
    //     "appEUI": "app",
    //     "appKEY": "1E2AE8CAB71C02ABC889303D73C1803D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1080,
    //     "latestFrameCounterFcnt": 1081,
    //     "lostFcnt": 251,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3422,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00653",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25d9",
    //     "appEUI": "app",
    //     "appKEY": "BCAD5C939DE09DE228D9C34803B4D04A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1064,
    //     "latestFrameCounterFcnt": 1065,
    //     "lostFcnt": 226,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3423,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00654",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25da",
    //     "appEUI": "app",
    //     "appKEY": "1BBA523A4D100D9680C9032E173B66F7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1063,
    //     "latestFrameCounterFcnt": 1064,
    //     "lostFcnt": 200,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3424,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00655",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25dc",
    //     "appEUI": "app",
    //     "appKEY": "42E53B1716307ED15774DBD4AE44800D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1076,
    //     "latestFrameCounterFcnt": 1077,
    //     "lostFcnt": 221,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3425,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00656",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25dd",
    //     "appEUI": "app",
    //     "appKEY": "F801530B21D57E36FA9E60192BAFBCAA",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1069,
    //     "latestFrameCounterFcnt": 1070,
    //     "lostFcnt": 269,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3426,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00657",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25de",
    //     "appEUI": "app",
    //     "appKEY": "CE0DE3315E8E51FCAF152F87739168D3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1015,
    //     "latestFrameCounterFcnt": 1016,
    //     "lostFcnt": 174,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3427,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00658",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25df",
    //     "appEUI": "app",
    //     "appKEY": "D1A6891196BCC33D7911BC12B3FCED30",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 1045,
    //     "latestFrameCounterFcnt": 1046,
    //     "lostFcnt": 338,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3428,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00659",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25e0",
    //     "appEUI": "app",
    //     "appKEY": "E5303041AF191C48A4639DEAC0EF5458",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1066,
    //     "latestFrameCounterFcnt": 1067,
    //     "lostFcnt": 308,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3429,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00660",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25e1",
    //     "appEUI": "app",
    //     "appKEY": "7C3FF1F876A248293310366B77D41212",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1095,
    //     "latestFrameCounterFcnt": 1096,
    //     "lostFcnt": 280,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3430,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00661",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25e2",
    //     "appEUI": "app",
    //     "appKEY": "173D4C0064A81230491A1B57A937A11F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 906,
    //     "latestFrameCounterFcnt": 978,
    //     "lostFcnt": 446,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3431,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00662",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25e3",
    //     "appEUI": "app",
    //     "appKEY": "C783AF9A81D50C363A74181CCDCACF36",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1079,
    //     "latestFrameCounterFcnt": 1080,
    //     "lostFcnt": 241,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3432,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00663",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25e4",
    //     "appEUI": "app",
    //     "appKEY": "52AC9D118C2D5F7A5F70EA16A4FEF6C9",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1082,
    //     "latestFrameCounterFcnt": 1083,
    //     "lostFcnt": 214,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3433,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00664",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b315f",
    //     "appEUI": "app",
    //     "appKEY": "D2CFA3EB7E282BE3F0E6FD228201FCF8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1093,
    //     "latestFrameCounterFcnt": 1094,
    //     "lostFcnt": 275,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3434,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00665",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3161",
    //     "appEUI": "app",
    //     "appKEY": "B6A2A7F8DAF7377C1B810081A53B588B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1058,
    //     "latestFrameCounterFcnt": 1059,
    //     "lostFcnt": 402,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3435,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00666",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3164",
    //     "appEUI": "app",
    //     "appKEY": "909569BC515CFBA4A218E873C4923960",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1084,
    //     "latestFrameCounterFcnt": 1085,
    //     "lostFcnt": 267,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3436,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00667",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3165",
    //     "appEUI": "app",
    //     "appKEY": "F7E24802F0716604F24687965B1C6E05",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1003,
    //     "latestFrameCounterFcnt": 1004,
    //     "lostFcnt": 371,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3437,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00668",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3166",
    //     "appEUI": "app",
    //     "appKEY": "FDE5AE009BB429CE6B564E9412108FA9",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1079,
    //     "latestFrameCounterFcnt": 1081,
    //     "lostFcnt": 266,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3438,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00669",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3167",
    //     "appEUI": "app",
    //     "appKEY": "9D3F748154FB8B897BC797D9861B5B02",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1097,
    //     "latestFrameCounterFcnt": 1098,
    //     "lostFcnt": 252,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3439,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00670",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3168",
    //     "appEUI": "app",
    //     "appKEY": "13F262C24D9A561B93BAB2372DDA265F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1054,
    //     "latestFrameCounterFcnt": 1055,
    //     "lostFcnt": 208,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3440,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00671",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b3169",
    //     "appEUI": "app",
    //     "appKEY": "4CAB4DFC80C287C8CB00321B6499F729",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 79,
    //     "prevFrameCounterFcnt": 1114,
    //     "latestFrameCounterFcnt": 1115,
    //     "lostFcnt": 243,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3441,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00672",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b316a",
    //     "appEUI": "app",
    //     "appKEY": "7A5CA834D88BDE52172C3527CD7AAC2B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1076,
    //     "latestFrameCounterFcnt": 1077,
    //     "lostFcnt": 316,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3442,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00673",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b316b",
    //     "appEUI": "app",
    //     "appKEY": "F7740AC9C51B0D552CFFA2DCB7CF3779",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 966,
    //     "latestFrameCounterFcnt": 969,
    //     "lostFcnt": 227,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3443,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00674",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b316c",
    //     "appEUI": "app",
    //     "appKEY": "B31A7D2FE2D7B0EEFA37D1791570EEB5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1110,
    //     "latestFrameCounterFcnt": 1111,
    //     "lostFcnt": 370,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3444,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00675",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25a4",
    //     "appEUI": "app",
    //     "appKEY": "FD7300BC7E0360C6DEE792076E44D0F4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1063,
    //     "latestFrameCounterFcnt": 1064,
    //     "lostFcnt": 215,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3445,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00676",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25a5",
    //     "appEUI": "app",
    //     "appKEY": "770DA637936E0EFBF38584BEDFD918A3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1099,
    //     "latestFrameCounterFcnt": 1100,
    //     "lostFcnt": 229,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3446,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00677",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25a6",
    //     "appEUI": "app",
    //     "appKEY": "1641301B28625C11CEC3F67989C029D6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1100,
    //     "latestFrameCounterFcnt": 1101,
    //     "lostFcnt": 300,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3447,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00678",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25a7",
    //     "appEUI": "app",
    //     "appKEY": "6FE07AE353FE1369E893C17F271DA899",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 65,
    //     "prevFrameCounterFcnt": 1020,
    //     "latestFrameCounterFcnt": 1021,
    //     "lostFcnt": 369,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3448,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00679",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25a8",
    //     "appEUI": "app",
    //     "appKEY": "5F34CF4B3A416E52C74CD9A0231F6244",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1041,
    //     "latestFrameCounterFcnt": 1042,
    //     "lostFcnt": 426,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3449,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00680",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25a9",
    //     "appEUI": "app",
    //     "appKEY": "81304C84A0D465AB24CF7775C51F5403",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1051,
    //     "latestFrameCounterFcnt": 1052,
    //     "lostFcnt": 258,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3450,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00681",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25aa",
    //     "appEUI": "app",
    //     "appKEY": "7754965E4106BE89AED4C8CD831D96B6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 1097,
    //     "latestFrameCounterFcnt": 1098,
    //     "lostFcnt": 267,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3451,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00682",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25ab",
    //     "appEUI": "app",
    //     "appKEY": "D89C3855469185009D496515066F5F1C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1077,
    //     "latestFrameCounterFcnt": 1078,
    //     "lostFcnt": 236,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3452,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00683",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25ac",
    //     "appEUI": "app",
    //     "appKEY": "059710D51F15ABB4EBE57EC8E78769BE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 996,
    //     "latestFrameCounterFcnt": 997,
    //     "lostFcnt": 155,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3453,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00684",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25ad",
    //     "appEUI": "app",
    //     "appKEY": "711EB57839D9D32A9DA905F6BB2781B4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 79,
    //     "prevFrameCounterFcnt": 1210,
    //     "latestFrameCounterFcnt": 1211,
    //     "lostFcnt": 245,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3454,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00685",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25ae",
    //     "appEUI": "app",
    //     "appKEY": "30041E56E05C44337023854E16468D3A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1041,
    //     "latestFrameCounterFcnt": 1042,
    //     "lostFcnt": 281,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3455,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00686",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25af",
    //     "appEUI": "app",
    //     "appKEY": "33A118C41053CF5252CD5D774815D83D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1062,
    //     "latestFrameCounterFcnt": 1063,
    //     "lostFcnt": 343,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3456,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00687",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25b0",
    //     "appEUI": "app",
    //     "appKEY": "7367968F0BFD5F877A8E6D7B4ACB4094",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1039,
    //     "latestFrameCounterFcnt": 1040,
    //     "lostFcnt": 350,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3457,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00688",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25b1",
    //     "appEUI": "app",
    //     "appKEY": "4A1C51EFAF149515C6CC86D14B61B4EC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 1014,
    //     "latestFrameCounterFcnt": 1015,
    //     "lostFcnt": 149,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3458,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00689",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25b2",
    //     "appEUI": "app",
    //     "appKEY": "4E2E2B4C29E0E17B25033AE9408DFD7C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1055,
    //     "latestFrameCounterFcnt": 1056,
    //     "lostFcnt": 189,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3459,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00690",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25b3",
    //     "appEUI": "app",
    //     "appKEY": "D20D302FDC0F5E9173FEAD2C5CD9F32B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1097,
    //     "latestFrameCounterFcnt": 1098,
    //     "lostFcnt": 278,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3460,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00691",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25b4",
    //     "appEUI": "app",
    //     "appKEY": "FBA418F33D53F3F144EC5CD522D61396",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 1024,
    //     "latestFrameCounterFcnt": 1025,
    //     "lostFcnt": 186,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3461,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00692",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25b5",
    //     "appEUI": "app",
    //     "appKEY": "EAC0594F0FCB598D63ED9AD0A1B2AF9B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 1006,
    //     "latestFrameCounterFcnt": 1007,
    //     "lostFcnt": 158,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3462,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00693",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25b6",
    //     "appEUI": "app",
    //     "appKEY": "8E0213EE1511639D1EAC9ADF6D70B341",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1084,
    //     "latestFrameCounterFcnt": 1085,
    //     "lostFcnt": 192,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3463,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00694",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25b7",
    //     "appEUI": "app",
    //     "appKEY": "4AF983AA5A1E08DF14688B7F0FC2B52C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 65,
    //     "prevFrameCounterFcnt": 1047,
    //     "latestFrameCounterFcnt": 1048,
    //     "lostFcnt": 198,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3464,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00695",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25b8",
    //     "appEUI": "app",
    //     "appKEY": "420719D6CA61AE5118CD3A6B34E5DD93",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1044,
    //     "latestFrameCounterFcnt": 1045,
    //     "lostFcnt": 219,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3465,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00696",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25b9",
    //     "appEUI": "app",
    //     "appKEY": "07813684D46F13160E77A3F6913F432E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1038,
    //     "latestFrameCounterFcnt": 1039,
    //     "lostFcnt": 172,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3466,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00697",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25ba",
    //     "appEUI": "app",
    //     "appKEY": "ACF9568AB61451FCCFBDC2E921FBDFDA",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1035,
    //     "latestFrameCounterFcnt": 1036,
    //     "lostFcnt": 188,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3467,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00698",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25bb",
    //     "appEUI": "app",
    //     "appKEY": "82450098A3FDE1396B471243B5E7ECA7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1013,
    //     "latestFrameCounterFcnt": 1014,
    //     "lostFcnt": 176,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3468,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00699",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25bc",
    //     "appEUI": "app",
    //     "appKEY": "03CEEA66B582643BE433123C984DDFCA",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1045,
    //     "latestFrameCounterFcnt": 1046,
    //     "lostFcnt": 190,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3469,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00700",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b258a",
    //     "appEUI": "app",
    //     "appKEY": "FFAABA2ABBF7FFBE1258ADFB37FD6798",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1103,
    //     "latestFrameCounterFcnt": 1104,
    //     "lostFcnt": 232,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3470,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00701",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b258b",
    //     "appEUI": "app",
    //     "appKEY": "BEA505992C04FDD44DBDA1A65B8CA7EA",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1077,
    //     "latestFrameCounterFcnt": 1078,
    //     "lostFcnt": 251,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3471,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00702",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b258c",
    //     "appEUI": "app",
    //     "appKEY": "29A0F0E544901043BC92FA8D1D2ADB1A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1089,
    //     "latestFrameCounterFcnt": 1090,
    //     "lostFcnt": 269,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3472,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00703",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b258d",
    //     "appEUI": "app",
    //     "appKEY": "BBB5FCB57EEC15FAB9060C52A0AD44EE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1010,
    //     "latestFrameCounterFcnt": 1015,
    //     "lostFcnt": 338,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3473,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00704",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b258e",
    //     "appEUI": "app",
    //     "appKEY": "8DC3B60E58F656B078F933D21EFC155D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1073,
    //     "latestFrameCounterFcnt": 1074,
    //     "lostFcnt": 261,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3474,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00705",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2590",
    //     "appEUI": "app",
    //     "appKEY": "62ACC1001AC603045BFB1E4121D0B368",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1121,
    //     "latestFrameCounterFcnt": 1122,
    //     "lostFcnt": 300,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3475,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00706",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2591",
    //     "appEUI": "app",
    //     "appKEY": "C7C86434A15D37D3DA53DA1DE0A155F4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1073,
    //     "latestFrameCounterFcnt": 1074,
    //     "lostFcnt": 231,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3476,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00707",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2592",
    //     "appEUI": "app",
    //     "appKEY": "63E1B74D9B81738AE87BF1594B557E8F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1058,
    //     "latestFrameCounterFcnt": 1059,
    //     "lostFcnt": 276,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3477,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00708",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2593",
    //     "appEUI": "app",
    //     "appKEY": "0A374C22122D023BDBE5CF403E36EE7F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1104,
    //     "latestFrameCounterFcnt": 1105,
    //     "lostFcnt": 245,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3478,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00709",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2594",
    //     "appEUI": "app",
    //     "appKEY": "5DCC9E9AD3DBD7188012166105B6816D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1043,
    //     "latestFrameCounterFcnt": 1044,
    //     "lostFcnt": 279,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3479,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00710",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2595",
    //     "appEUI": "app",
    //     "appKEY": "371FF18601FEC1486350F94D040D0E3B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1042,
    //     "latestFrameCounterFcnt": 1043,
    //     "lostFcnt": 208,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3480,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00711",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2596",
    //     "appEUI": "app",
    //     "appKEY": "60C90A281A9CE34DD491008779FA0B75",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1050,
    //     "latestFrameCounterFcnt": 1051,
    //     "lostFcnt": 222,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3481,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00712",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2597",
    //     "appEUI": "app",
    //     "appKEY": "9F8CF76327CF73860E38DA25384827DC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1024,
    //     "latestFrameCounterFcnt": 1025,
    //     "lostFcnt": 175,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3482,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00713",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2598",
    //     "appEUI": "app",
    //     "appKEY": "7A0E1CBF9020AF145DAA53E19507B008",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 65,
    //     "prevFrameCounterFcnt": 1057,
    //     "latestFrameCounterFcnt": 1058,
    //     "lostFcnt": 232,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3483,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00714",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2599",
    //     "appEUI": "app",
    //     "appKEY": "FD29F2E1FB99C844162C8E2C51B99F84",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1050,
    //     "latestFrameCounterFcnt": 1051,
    //     "lostFcnt": 198,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3484,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00715",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b259a",
    //     "appEUI": "app",
    //     "appKEY": "8B4FFEFE6A5C3B63E838D6319B6AB16D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1054,
    //     "latestFrameCounterFcnt": 1055,
    //     "lostFcnt": 213,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3485,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00716",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b259b",
    //     "appEUI": "app",
    //     "appKEY": "39DEDDAEBB3355F553B78732E1379D03",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1048,
    //     "latestFrameCounterFcnt": 1049,
    //     "lostFcnt": 190,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3486,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00717",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b259c",
    //     "appEUI": "app",
    //     "appKEY": "E6DACEAF2EDAC6E07807D4F9965F4695",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1070,
    //     "latestFrameCounterFcnt": 1072,
    //     "lostFcnt": 210,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3487,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00718",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b259d",
    //     "appEUI": "app",
    //     "appKEY": "0DB6E16B593C6CE363399017D5668BB8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1037,
    //     "latestFrameCounterFcnt": 1038,
    //     "lostFcnt": 215,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3488,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00719",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b259e",
    //     "appEUI": "app",
    //     "appKEY": "1C404B38D72F8D96920D948082C7A1A6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1037,
    //     "latestFrameCounterFcnt": 1038,
    //     "lostFcnt": 186,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3489,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00720",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b259f",
    //     "appEUI": "app",
    //     "appKEY": "05CC40D705D794C91A876E37F9BA1DBC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1016,
    //     "latestFrameCounterFcnt": 1017,
    //     "lostFcnt": 191,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3490,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00721",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25a0",
    //     "appEUI": "app",
    //     "appKEY": "043F15FFA508277D9322C0ED660A3646",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1000,
    //     "latestFrameCounterFcnt": 1001,
    //     "lostFcnt": 160,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3491,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00722",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25a1",
    //     "appEUI": "app",
    //     "appKEY": "3369F77874D139B63088A50DF08FF42D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 998,
    //     "latestFrameCounterFcnt": 999,
    //     "lostFcnt": 173,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3492,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00723",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25a2",
    //     "appEUI": "app",
    //     "appKEY": "37B1991FBA32FB53FD7CE52255B7BDCB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1002,
    //     "latestFrameCounterFcnt": 1003,
    //     "lostFcnt": 180,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3493,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00724",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b25a3",
    //     "appEUI": "app",
    //     "appKEY": "C7A95AEB1FC9D74C5C25FBF04EDC603D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 64,
    //     "prevFrameCounterFcnt": 1053,
    //     "latestFrameCounterFcnt": 1054,
    //     "lostFcnt": 220,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3494,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00725",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b580c",
    //     "appEUI": "app",
    //     "appKEY": "32EA6FDA881A65A8B1C8F4E2930F3C45",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1077,
    //     "latestFrameCounterFcnt": 1078,
    //     "lostFcnt": 227,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3495,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00726",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b5811",
    //     "appEUI": "app",
    //     "appKEY": "FF7748EA2B0A41FFA8CC57C85E9900B1",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1005,
    //     "latestFrameCounterFcnt": 1006,
    //     "lostFcnt": 151,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3496,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00727",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b5812",
    //     "appEUI": "app",
    //     "appKEY": "0B19F3D13594459A2F5E33A9A8ABD524",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1008,
    //     "latestFrameCounterFcnt": 1009,
    //     "lostFcnt": 157,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3497,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00728",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b5813",
    //     "appEUI": "app",
    //     "appKEY": "265ED30C5B84D42C7C0C08E4B9106D4C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1070,
    //     "latestFrameCounterFcnt": 1071,
    //     "lostFcnt": 230,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3498,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00729",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b5814",
    //     "appEUI": "app",
    //     "appKEY": "B62DD8390F05C6D36ED4F6837A54C600",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1013,
    //     "latestFrameCounterFcnt": 1014,
    //     "lostFcnt": 180,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3499,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00730",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b5815",
    //     "appEUI": "app",
    //     "appKEY": "D071A3D349A60892596F8C8C8E96FB39",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1094,
    //     "latestFrameCounterFcnt": 1095,
    //     "lostFcnt": 259,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3500,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00731",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b5816",
    //     "appEUI": "app",
    //     "appKEY": "0E7113C86BB73605A632324573EB228B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1081,
    //     "latestFrameCounterFcnt": 1082,
    //     "lostFcnt": 269,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3501,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00732",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b5817",
    //     "appEUI": "app",
    //     "appKEY": "67FE18E3F1906FC1D781AB5451C2A53D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 62,
    //     "prevFrameCounterFcnt": 3,
    //     "latestFrameCounterFcnt": 4,
    //     "lostFcnt": -263,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3502,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00733",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b5818",
    //     "appEUI": "app",
    //     "appKEY": "5E427386A805EB027BB50A3A27D8F91A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 789,
    //     "latestFrameCounterFcnt": 790,
    //     "lostFcnt": -50,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3503,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00734",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b5819",
    //     "appEUI": "app",
    //     "appKEY": "6EBA3E606DBEB8E967E8E4FEEBA917E5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 63,
    //     "latestFrameCounterFcnt": 66,
    //     "lostFcnt": 10,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3504,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00735",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b581a",
    //     "appEUI": "app",
    //     "appKEY": "A5D2910A8BA7BD8602E3AA514B53CD72",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1094,
    //     "latestFrameCounterFcnt": 1096,
    //     "lostFcnt": 289,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3505,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00736",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b581b",
    //     "appEUI": "app",
    //     "appKEY": "D95BE8174A88F7880C0CB450CED1CE58",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1075,
    //     "latestFrameCounterFcnt": 1076,
    //     "lostFcnt": 206,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3506,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00737",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b581c",
    //     "appEUI": "app",
    //     "appKEY": "EEDCACA00F3AE33F3D472C61C489B028",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 180,
    //     "latestFrameCounterFcnt": 188,
    //     "lostFcnt": -25,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3507,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00738",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b581d",
    //     "appEUI": "app",
    //     "appKEY": "6C6232D02A3978E31AC6C67A7839C558",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 50,
    //     "prevFrameCounterFcnt": 963,
    //     "latestFrameCounterFcnt": 964,
    //     "lostFcnt": 629,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3508,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00739",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b581e",
    //     "appEUI": "app",
    //     "appKEY": "B0CDA37E04278CE97AB9ED253D7DD35E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1070,
    //     "latestFrameCounterFcnt": 1072,
    //     "lostFcnt": 435,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3509,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00740",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b581f",
    //     "appEUI": "app",
    //     "appKEY": "4E620558684AC1E37383A7EA3BB80E12",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1040,
    //     "latestFrameCounterFcnt": 1041,
    //     "lostFcnt": 270,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3510,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00741",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b5820",
    //     "appEUI": "app",
    //     "appKEY": "97E837AD91FE9E928D9C7F3012C18690",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1064,
    //     "latestFrameCounterFcnt": 1065,
    //     "lostFcnt": 216,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3511,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00742",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b5821",
    //     "appEUI": "app",
    //     "appKEY": "4B8CFD3FE35217C66C3F9883F6DC41BB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1076,
    //     "latestFrameCounterFcnt": 1077,
    //     "lostFcnt": 276,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3512,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00743",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b5822",
    //     "appEUI": "app",
    //     "appKEY": "FD1B51275DCEE7001E0DDDBA7C2A0568",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1075,
    //     "latestFrameCounterFcnt": 1076,
    //     "lostFcnt": 273,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3513,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00744",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b5823",
    //     "appEUI": "app",
    //     "appKEY": "015E2A3BC7F87D4509A52D5211A5FE95",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1049,
    //     "latestFrameCounterFcnt": 1052,
    //     "lostFcnt": 270,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3514,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00745",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b5824",
    //     "appEUI": "app",
    //     "appKEY": "50E576115B1DF27B84501BC0CCED69F7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1016,
    //     "latestFrameCounterFcnt": 1052,
    //     "lostFcnt": 378,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3515,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00746",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b5825",
    //     "appEUI": "app",
    //     "appKEY": "3C95656677BDA1CF2F83F41E03B7AFAB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 63,
    //     "prevFrameCounterFcnt": 1042,
    //     "latestFrameCounterFcnt": 1043,
    //     "lostFcnt": 206,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3516,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00747",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b5826",
    //     "appEUI": "app",
    //     "appKEY": "43344382A9C5AF39FD11180EF662210E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1039,
    //     "latestFrameCounterFcnt": 1040,
    //     "lostFcnt": 294,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3517,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00748",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b5827",
    //     "appEUI": "app",
    //     "appKEY": "464CE14F9B8351ED6D80CBA40F8511F4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1049,
    //     "latestFrameCounterFcnt": 1050,
    //     "lostFcnt": 340,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3518,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00749",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b5828",
    //     "appEUI": "app",
    //     "appKEY": "49793006D170E98EBDFFD3C8B5991BA2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1050,
    //     "latestFrameCounterFcnt": 1051,
    //     "lostFcnt": 281,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3519,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00750",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2574",
    //     "appEUI": "app",
    //     "appKEY": "F3904D3BEB9D15C8B5676207E4E4ACC4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 966,
    //     "latestFrameCounterFcnt": 967,
    //     "lostFcnt": 180,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3520,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00751",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2575",
    //     "appEUI": "app",
    //     "appKEY": "24BA8A602E0AE67FFD13530BC2678398",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1015,
    //     "latestFrameCounterFcnt": 1016,
    //     "lostFcnt": 183,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3521,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00752",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2576",
    //     "appEUI": "app",
    //     "appKEY": "377742B4218B0031B314896C7CC3C9C6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1004,
    //     "latestFrameCounterFcnt": 1005,
    //     "lostFcnt": 169,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3522,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00753",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2577",
    //     "appEUI": "app",
    //     "appKEY": "385830DA0BB3A65DACE9696F4EC222C2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1010,
    //     "latestFrameCounterFcnt": 1011,
    //     "lostFcnt": 165,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3523,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00754",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2578",
    //     "appEUI": "app",
    //     "appKEY": "F0699DC9F3FE63AF648945A78CA76039",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 64,
    //     "prevFrameCounterFcnt": 999,
    //     "latestFrameCounterFcnt": 1000,
    //     "lostFcnt": 153,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3524,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00755",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2579",
    //     "appEUI": "app",
    //     "appKEY": "4B9886E7416022BA2E5F58FF55717B41",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 64,
    //     "prevFrameCounterFcnt": 989,
    //     "latestFrameCounterFcnt": 990,
    //     "lostFcnt": 152,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3525,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00756",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b257a",
    //     "appEUI": "app",
    //     "appKEY": "982EC02725E52694AD84A27F0BCA7201",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1019,
    //     "latestFrameCounterFcnt": 1020,
    //     "lostFcnt": 180,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3526,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00757",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b257b",
    //     "appEUI": "app",
    //     "appKEY": "B7CA59868A7CD8F2C6C248D5BE033223",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1014,
    //     "latestFrameCounterFcnt": 1015,
    //     "lostFcnt": 187,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3527,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00758",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b257c",
    //     "appEUI": "app",
    //     "appKEY": "5215DFCC64902BEA434B576207F8D1C8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1033,
    //     "latestFrameCounterFcnt": 1034,
    //     "lostFcnt": 175,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3528,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00759",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b257d",
    //     "appEUI": "app",
    //     "appKEY": "0ED77B3FA5EDC718FC0A0A8348AF540F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1019,
    //     "latestFrameCounterFcnt": 1020,
    //     "lostFcnt": 170,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3529,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00760",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b257e",
    //     "appEUI": "app",
    //     "appKEY": "A5595AE1F37547F94DB92F4D7FC1F8BE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 1029,
    //     "latestFrameCounterFcnt": 1030,
    //     "lostFcnt": 191,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3530,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00761",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b257f",
    //     "appEUI": "app",
    //     "appKEY": "BB2F771F31A9754E297947C638013B57",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1014,
    //     "latestFrameCounterFcnt": 1015,
    //     "lostFcnt": 180,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3531,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00762",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2580",
    //     "appEUI": "app",
    //     "appKEY": "5BB8E84A11646F2B55E7776FB6889408",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1066,
    //     "latestFrameCounterFcnt": 1067,
    //     "lostFcnt": 217,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3532,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00763",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2581",
    //     "appEUI": "app",
    //     "appKEY": "7052F13690004F04C18BF2D8BB13C0B3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 65,
    //     "prevFrameCounterFcnt": 1052,
    //     "latestFrameCounterFcnt": 1053,
    //     "lostFcnt": 207,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3533,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00764",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2582",
    //     "appEUI": "app",
    //     "appKEY": "1532442AA0EA6066F03C16C73ECE2BD4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1073,
    //     "latestFrameCounterFcnt": 1074,
    //     "lostFcnt": 231,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3534,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00765",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2583",
    //     "appEUI": "app",
    //     "appKEY": "72272E8E8BA150800881C6CAEE55CDA3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1090,
    //     "latestFrameCounterFcnt": 1091,
    //     "lostFcnt": 241,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3535,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00766",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2584",
    //     "appEUI": "app",
    //     "appKEY": "CF7E28615E6C10EA5861388902B110CB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1076,
    //     "latestFrameCounterFcnt": 1078,
    //     "lostFcnt": 308,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3536,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00767",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2585",
    //     "appEUI": "app",
    //     "appKEY": "A3394266F02C1112E354D8FB9E2E2F15",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1095,
    //     "latestFrameCounterFcnt": 1096,
    //     "lostFcnt": 257,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3537,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00768",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2586",
    //     "appEUI": "app",
    //     "appKEY": "4B554F5B2C253E01CFF3BF23796FBDA8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 79,
    //     "prevFrameCounterFcnt": 1094,
    //     "latestFrameCounterFcnt": 1095,
    //     "lostFcnt": 283,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3538,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00769",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2587",
    //     "appEUI": "app",
    //     "appKEY": "3EBB110F7FBCA961117FEAD1B56C3407",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1115,
    //     "latestFrameCounterFcnt": 1116,
    //     "lostFcnt": 300,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3539,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00770",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2588",
    //     "appEUI": "app",
    //     "appKEY": "8007C3BC200D5A9E45B16A503AC46BA3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 79,
    //     "prevFrameCounterFcnt": 1039,
    //     "latestFrameCounterFcnt": 1040,
    //     "lostFcnt": 419,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3540,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00771",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2589",
    //     "appEUI": "app",
    //     "appKEY": "4EF8938A96A0B5FF97BF7C5F2121787C",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1100,
    //     "latestFrameCounterFcnt": 1101,
    //     "lostFcnt": 258,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3541,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00772",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b5829",
    //     "appEUI": "app",
    //     "appKEY": "776BB8E7EB21C2935EAA2C8362CCDD09",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 79,
    //     "prevFrameCounterFcnt": 1099,
    //     "latestFrameCounterFcnt": 1100,
    //     "lostFcnt": 262,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3542,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00773",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b582a",
    //     "appEUI": "app",
    //     "appKEY": "C08726B9511B1E65C9244FC43BEEDF77",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 1010,
    //     "latestFrameCounterFcnt": 1013,
    //     "lostFcnt": 321,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3543,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00774",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b582b",
    //     "appEUI": "app",
    //     "appKEY": "6B7C84C8F3E0A4D4E512CBAF6C7F841F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1090,
    //     "latestFrameCounterFcnt": 1091,
    //     "lostFcnt": 334,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3544,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00775",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0390",
    //     "appEUI": "app",
    //     "appKEY": "B34810A9E6C44E0D443BDDEB5EE0A7F1",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1050,
    //     "latestFrameCounterFcnt": 1051,
    //     "lostFcnt": 341,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3545,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00776",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0392",
    //     "appEUI": "app",
    //     "appKEY": "F5A4882C47404E89A6DCBBDB9EF3365B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 79,
    //     "prevFrameCounterFcnt": 1083,
    //     "latestFrameCounterFcnt": 1084,
    //     "lostFcnt": 366,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3546,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00777",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0395",
    //     "appEUI": "app",
    //     "appKEY": "0BC001F3619C56501098633D3F5D0F34",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1089,
    //     "latestFrameCounterFcnt": 1090,
    //     "lostFcnt": 300,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3547,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00778",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0396",
    //     "appEUI": "app",
    //     "appKEY": "21FDDCBDF940D45979486715408CA8C8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 495,
    //     "latestFrameCounterFcnt": 496,
    //     "lostFcnt": -106,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3548,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00779",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b0397",
    //     "appEUI": "app",
    //     "appKEY": "CD7DBE83906001DC44DCFA4488B25B08",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 80,
    //     "prevFrameCounterFcnt": 1078,
    //     "latestFrameCounterFcnt": 1079,
    //     "lostFcnt": 301,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3549,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00780",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b039b",
    //     "appEUI": "app",
    //     "appKEY": "A15BC604839B64368E078B1915FC15C9",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 108,
    //     "prevFrameCounterFcnt": 1231,
    //     "latestFrameCounterFcnt": 1232,
    //     "lostFcnt": 311,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3550,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00781",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b039c",
    //     "appEUI": "app",
    //     "appKEY": "2A54018413A8A98601BF2EDE7B5845EC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": 37,
    //     "latestFrameCounterFcnt": 43,
    //     "lostFcnt": 38,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 3551,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00782",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b039d",
    //     "appEUI": "app",
    //     "appKEY": "05C1B1D232AD9C248FFB508DC353D3D5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 976,
    //     "latestFrameCounterFcnt": 979,
    //     "lostFcnt": 455,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3552,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00783",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b03ae",
    //     "appEUI": "app",
    //     "appKEY": "CBDFF93AE87F8F2527DC64C6D05364A6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 77,
    //     "prevFrameCounterFcnt": 1052,
    //     "latestFrameCounterFcnt": 1053,
    //     "lostFcnt": 397,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3553,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00784",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2524",
    //     "appEUI": "app",
    //     "appKEY": "64819695D45F6A30C258EE4E40E221DF",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1085,
    //     "latestFrameCounterFcnt": 1086,
    //     "lostFcnt": 248,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3554,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00785",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2525",
    //     "appEUI": "app",
    //     "appKEY": "AD2D77D19B849F275D4A159C708C7154",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1056,
    //     "latestFrameCounterFcnt": 1057,
    //     "lostFcnt": 324,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3555,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00786",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2526",
    //     "appEUI": "app",
    //     "appKEY": "5B7BD5D2D25CD991712AFE0FF440170D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1093,
    //     "latestFrameCounterFcnt": 1094,
    //     "lostFcnt": 239,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3556,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00787",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2527",
    //     "appEUI": "app",
    //     "appKEY": "25143546823372DC5E374C0BE3E7BF14",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1082,
    //     "latestFrameCounterFcnt": 1083,
    //     "lostFcnt": 270,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3557,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00788",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2528",
    //     "appEUI": "app",
    //     "appKEY": "D6EEA7190855F3EDFD6FEA5FB3482501",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1063,
    //     "latestFrameCounterFcnt": 1064,
    //     "lostFcnt": 309,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3558,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00789",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2529",
    //     "appEUI": "app",
    //     "appKEY": "9E69F4EE43601D1CA80B5BDB508656F0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 490,
    //     "latestFrameCounterFcnt": 491,
    //     "lostFcnt": -106,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3559,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00790",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b252a",
    //     "appEUI": "app",
    //     "appKEY": "AD2D1970C1C5FBBC1721199FBED0D8F5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1033,
    //     "latestFrameCounterFcnt": 1035,
    //     "lostFcnt": 340,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3560,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00791",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b252c",
    //     "appEUI": "app",
    //     "appKEY": "16A11B1031799CCAE13F8A014D63EB54",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 1040,
    //     "latestFrameCounterFcnt": 1041,
    //     "lostFcnt": 395,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3561,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00792",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b252d",
    //     "appEUI": "app",
    //     "appKEY": "F80F6D1ED3A81CBAFEFFB91E52CB0013",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 64,
    //     "prevFrameCounterFcnt": 26,
    //     "latestFrameCounterFcnt": 33,
    //     "lostFcnt": -271,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3562,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00793",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b252e",
    //     "appEUI": "app",
    //     "appKEY": "3B316DC82E0A0E60469758B8970FAAD0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1056,
    //     "latestFrameCounterFcnt": 1057,
    //     "lostFcnt": 277,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3563,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00794",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b252f",
    //     "appEUI": "app",
    //     "appKEY": "8FC3169EB9B788F888806791A8897AEE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 79,
    //     "prevFrameCounterFcnt": 1089,
    //     "latestFrameCounterFcnt": 1090,
    //     "lostFcnt": 260,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3564,
    //     "gatewaysId": null,
    //     "name": "WaterExternalPOSGWTC00795",
    //     "description": "INSTALACION AQLARA",
    //     "provider": null,
    //     "userId": 66,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-05",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2530",
    //     "appEUI": "app",
    //     "appKEY": "414306CD13E7A2E62DEB7C7DC37DDFE5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb59c72e",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1070,
    //     "latestFrameCounterFcnt": 1071,
    //     "lostFcnt": 292,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb59c72e",
    //     "messageTime": null
    // },
    // {
    //     "id": 3565,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC01",
    //     "description": "90DFFB81872B6608",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6608",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 104,
    //     "prevFrameCounterFcnt": 1063,
    //     "latestFrameCounterFcnt": 1064,
    //     "lostFcnt": 261,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3566,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC02",
    //     "description": "90DFFB81872B6609",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6609",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 104,
    //     "prevFrameCounterFcnt": 1048,
    //     "latestFrameCounterFcnt": 1049,
    //     "lostFcnt": 286,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3568,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC04",
    //     "description": "90DFFB81872B660B",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b660b",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 101,
    //     "prevFrameCounterFcnt": 954,
    //     "latestFrameCounterFcnt": 959,
    //     "lostFcnt": 167,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3569,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC05",
    //     "description": "90DFFB81872B660C",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b660c",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 105,
    //     "prevFrameCounterFcnt": 1068,
    //     "latestFrameCounterFcnt": 1069,
    //     "lostFcnt": 308,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3570,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC06",
    //     "description": "90DFFB81872B6619",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6619",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 101,
    //     "prevFrameCounterFcnt": 1030,
    //     "latestFrameCounterFcnt": 1031,
    //     "lostFcnt": 392,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3571,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC07",
    //     "description": "90DFFB81872B661B",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b661b",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 109,
    //     "prevFrameCounterFcnt": 1037,
    //     "latestFrameCounterFcnt": 1038,
    //     "lostFcnt": 350,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3572,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC08",
    //     "description": "90DFFB81872B661C",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b661c",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 104,
    //     "prevFrameCounterFcnt": 1057,
    //     "latestFrameCounterFcnt": 1058,
    //     "lostFcnt": 336,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3573,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC09",
    //     "description": "90DFFB81872B661D",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b661d",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 100,
    //     "prevFrameCounterFcnt": 999,
    //     "latestFrameCounterFcnt": 1009,
    //     "lostFcnt": 484,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3574,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC10",
    //     "description": "90DFFB81872B661E",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b661e",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 104,
    //     "prevFrameCounterFcnt": 1028,
    //     "latestFrameCounterFcnt": 1029,
    //     "lostFcnt": 423,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3575,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC100",
    //     "description": "90DFFB81872B6E9F",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6e9f",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 92,
    //     "prevFrameCounterFcnt": 246,
    //     "latestFrameCounterFcnt": 250,
    //     "lostFcnt": -121,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3576,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC101",
    //     "description": "90DFFB81872B6670",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6670",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 99,
    //     "prevFrameCounterFcnt": 1066,
    //     "latestFrameCounterFcnt": 1067,
    //     "lostFcnt": 220,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3577,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC102",
    //     "description": "90DFFB81872B6671",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6671",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 104,
    //     "prevFrameCounterFcnt": 972,
    //     "latestFrameCounterFcnt": 973,
    //     "lostFcnt": 274,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3578,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC103",
    //     "description": "90DFFB81872B6672",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6672",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 100,
    //     "prevFrameCounterFcnt": 1007,
    //     "latestFrameCounterFcnt": 1008,
    //     "lostFcnt": 474,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3579,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC104",
    //     "description": "90DFFB81872B6673",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6673",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 99,
    //     "prevFrameCounterFcnt": 1074,
    //     "latestFrameCounterFcnt": 1075,
    //     "lostFcnt": 237,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3580,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC105",
    //     "description": "90DFFB81872B6674",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6674",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 99,
    //     "prevFrameCounterFcnt": 1081,
    //     "latestFrameCounterFcnt": 1082,
    //     "lostFcnt": 282,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3581,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC106",
    //     "description": "90DFFB81872B6675",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6675",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 100,
    //     "prevFrameCounterFcnt": 13,
    //     "latestFrameCounterFcnt": 14,
    //     "lostFcnt": -688,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3582,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC107",
    //     "description": "90DFFB81872B6682",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6682",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 104,
    //     "prevFrameCounterFcnt": 922,
    //     "latestFrameCounterFcnt": 924,
    //     "lostFcnt": 431,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3583,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC108",
    //     "description": "90DFFB81872B6683",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6683",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 101,
    //     "prevFrameCounterFcnt": 1083,
    //     "latestFrameCounterFcnt": 1084,
    //     "lostFcnt": 235,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3584,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC109",
    //     "description": "90DFFB81872B6684",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6684",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 94,
    //     "prevFrameCounterFcnt": 1026,
    //     "latestFrameCounterFcnt": 1027,
    //     "lostFcnt": 388,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3585,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC11",
    //     "description": "90DFFB81872B661F",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b661f",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 103,
    //     "prevFrameCounterFcnt": 95,
    //     "latestFrameCounterFcnt": 96,
    //     "lostFcnt": -312,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3586,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC110",
    //     "description": "90DFFB81872B6685",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6685",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 100,
    //     "prevFrameCounterFcnt": 1072,
    //     "latestFrameCounterFcnt": 1073,
    //     "lostFcnt": 400,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3587,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC111",
    //     "description": "90DFFB81872B6686",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6686",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 98,
    //     "prevFrameCounterFcnt": 1065,
    //     "latestFrameCounterFcnt": 1066,
    //     "lostFcnt": 294,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3588,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC112",
    //     "description": "90DFFB81872B6687",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6687",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 91,
    //     "prevFrameCounterFcnt": 619,
    //     "latestFrameCounterFcnt": 620,
    //     "lostFcnt": 151,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3589,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC113",
    //     "description": "90DFFB81872B6688",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6688",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 95,
    //     "prevFrameCounterFcnt": 1060,
    //     "latestFrameCounterFcnt": 1062,
    //     "lostFcnt": 303,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3590,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC114",
    //     "description": "90DFFB81872B6689",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6689",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 90,
    //     "prevFrameCounterFcnt": 76,
    //     "latestFrameCounterFcnt": 77,
    //     "lostFcnt": -380,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3591,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC115",
    //     "description": "90DFFB81872B668C",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b668c",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 96,
    //     "prevFrameCounterFcnt": 1051,
    //     "latestFrameCounterFcnt": 1052,
    //     "lostFcnt": 213,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3592,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC116",
    //     "description": "90DFFB81872B668D",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b668d",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 106,
    //     "prevFrameCounterFcnt": 1054,
    //     "latestFrameCounterFcnt": 1055,
    //     "lostFcnt": 274,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3593,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC117",
    //     "description": "90DFFB81872B668E",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b668e",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 101,
    //     "prevFrameCounterFcnt": 1065,
    //     "latestFrameCounterFcnt": 1067,
    //     "lostFcnt": 274,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3594,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC118",
    //     "description": "90DFFB81872B668F",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b668f",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1066,
    //     "latestFrameCounterFcnt": 1067,
    //     "lostFcnt": 250,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3595,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC119",
    //     "description": "90DFFB81872B6690",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6690",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 104,
    //     "prevFrameCounterFcnt": 1048,
    //     "latestFrameCounterFcnt": 1049,
    //     "lostFcnt": 262,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3596,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC12",
    //     "description": "90DFFB81872B6620",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6620",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 107,
    //     "prevFrameCounterFcnt": 65,
    //     "latestFrameCounterFcnt": 148,
    //     "lostFcnt": -105,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3597,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC120",
    //     "description": "90DFFB81872B6691",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6691",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 91,
    //     "prevFrameCounterFcnt": 1057,
    //     "latestFrameCounterFcnt": 1066,
    //     "lostFcnt": 242,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3598,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC121",
    //     "description": "90DFFB81872B6692",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6692",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 8,
    //     "prevFrameCounterFcnt": 8,
    //     "latestFrameCounterFcnt": 8,
    //     "lostFcnt": 0,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3599,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC122",
    //     "description": "90DFFB81872B6693",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6693",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 10,
    //     "prevFrameCounterFcnt": 63,
    //     "latestFrameCounterFcnt": 66,
    //     "lostFcnt": 35,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3601,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC124",
    //     "description": "90DFFB81872B6695",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6695",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 3,
    //     "prevFrameCounterFcnt": 49,
    //     "latestFrameCounterFcnt": 56,
    //     "lostFcnt": 49,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3603,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC126",
    //     "description": "90DFFB81872B2B93",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2b93",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 185,
    //     "latestFrameCounterFcnt": 186,
    //     "lostFcnt": 152,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3604,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC127",
    //     "description": "90DFFB81872B2B94",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2b94",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 40,
    //     "prevFrameCounterFcnt": 106,
    //     "latestFrameCounterFcnt": 110,
    //     "lostFcnt": 63,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3607,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC13",
    //     "description": "90DFFB81872B6621",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6621",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 116,
    //     "latestFrameCounterFcnt": 2,
    //     "lostFcnt": -82,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3608,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC130",
    //     "description": "90DFFB81872B2B99",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2b99",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 25,
    //     "prevFrameCounterFcnt": 79,
    //     "latestFrameCounterFcnt": 88,
    //     "lostFcnt": 33,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3609,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC131",
    //     "description": "90DFFB81872B2B9A",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2b9a",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 95,
    //     "prevFrameCounterFcnt": 1007,
    //     "latestFrameCounterFcnt": 1008,
    //     "lostFcnt": 427,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3610,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC132",
    //     "description": "90DFFB81872B2B9B",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2b9b",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 91,
    //     "prevFrameCounterFcnt": 1017,
    //     "latestFrameCounterFcnt": 1018,
    //     "lostFcnt": 374,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3611,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC133",
    //     "description": "90DFFB81872B2B9C",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2b9c",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 89,
    //     "prevFrameCounterFcnt": 1025,
    //     "latestFrameCounterFcnt": 1026,
    //     "lostFcnt": 347,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3612,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC134",
    //     "description": "90DFFB81872B2B9D",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2b9d",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 59,
    //     "prevFrameCounterFcnt": 59,
    //     "latestFrameCounterFcnt": 1,
    //     "lostFcnt": -59,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3614,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC136",
    //     "description": "90DFFB81872B2B9F",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2b9f",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1003,
    //     "latestFrameCounterFcnt": 1004,
    //     "lostFcnt": 378,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3615,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC137",
    //     "description": "90DFFB81872B2BA0",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2ba0",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1065,
    //     "latestFrameCounterFcnt": 1066,
    //     "lostFcnt": 221,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3616,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC138",
    //     "description": "90DFFB81872B2BA1",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2ba1",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 844,
    //     "latestFrameCounterFcnt": 845,
    //     "lostFcnt": 410,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3617,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC139",
    //     "description": "90DFFB81872B2BA2",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2ba2",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 592,
    //     "latestFrameCounterFcnt": 596,
    //     "lostFcnt": 198,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3618,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC14",
    //     "description": "90DFFB81872B6622",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6622",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 113,
    //     "prevFrameCounterFcnt": 1081,
    //     "latestFrameCounterFcnt": 1082,
    //     "lostFcnt": 320,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3619,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC140",
    //     "description": "90DFFB81872B2BA3",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2ba3",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 5,
    //     "latestFrameCounterFcnt": 7,
    //     "lostFcnt": -92,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3620,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC141",
    //     "description": "90DFFB81872B2BA4",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2ba4",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 772,
    //     "latestFrameCounterFcnt": 773,
    //     "lostFcnt": 213,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3621,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC142",
    //     "description": "90DFFB81872B2BA5",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2ba5",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 201,
    //     "latestFrameCounterFcnt": 202,
    //     "lostFcnt": -34,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3622,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC143",
    //     "description": "90DFFB81872B2BA6",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2ba6",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1022,
    //     "latestFrameCounterFcnt": 1024,
    //     "lostFcnt": 372,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3623,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC144",
    //     "description": "90DFFB81872B2BA7",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2ba7",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1133,
    //     "latestFrameCounterFcnt": 1134,
    //     "lostFcnt": 257,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3624,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC145",
    //     "description": "90DFFB81872B2BA8",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2ba8",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 989,
    //     "latestFrameCounterFcnt": 990,
    //     "lostFcnt": 389,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3625,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC146",
    //     "description": "90DFFB81872B2BA9",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2ba9",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1011,
    //     "latestFrameCounterFcnt": 1012,
    //     "lostFcnt": 173,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3626,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC147",
    //     "description": "90DFFB81872B2BAA",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2baa",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 76,
    //     "prevFrameCounterFcnt": 1030,
    //     "latestFrameCounterFcnt": 1031,
    //     "lostFcnt": 316,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3627,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC148",
    //     "description": "90DFFB81872B2BAD",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2bad",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 973,
    //     "latestFrameCounterFcnt": 975,
    //     "lostFcnt": 398,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3628,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC149",
    //     "description": "90DFFB81872B2BB0",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2bb0",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 84,
    //     "prevFrameCounterFcnt": 1077,
    //     "latestFrameCounterFcnt": 1078,
    //     "lostFcnt": 210,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3629,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC15",
    //     "description": "90DFFB81872B6623",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6623",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 106,
    //     "prevFrameCounterFcnt": 1076,
    //     "latestFrameCounterFcnt": 1077,
    //     "lostFcnt": 252,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3631,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC151",
    //     "description": "90DFFB81872B2BAE",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2bae",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1001,
    //     "latestFrameCounterFcnt": 1003,
    //     "lostFcnt": 354,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3632,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC152",
    //     "description": "90DFFB81872B2BB2",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2bb2",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1042,
    //     "latestFrameCounterFcnt": 1043,
    //     "lostFcnt": 279,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3633,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC153",
    //     "description": "90DFFB81872B2BB3",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2bb3",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 74,
    //     "prevFrameCounterFcnt": 834,
    //     "latestFrameCounterFcnt": 835,
    //     "lostFcnt": 276,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3634,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC154",
    //     "description": "90DFFB81872B2BB4",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2bb4",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 76,
    //     "prevFrameCounterFcnt": 1055,
    //     "latestFrameCounterFcnt": 1056,
    //     "lostFcnt": 239,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3635,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC155",
    //     "description": "90DFFB81872B2BB5",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2bb5",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 983,
    //     "latestFrameCounterFcnt": 984,
    //     "lostFcnt": 160,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3636,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC156",
    //     "description": "90DFFB81872B2BB6",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2bb6",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 65,
    //     "prevFrameCounterFcnt": 63,
    //     "latestFrameCounterFcnt": 12,
    //     "lostFcnt": -224,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3637,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC157",
    //     "description": "90DFFB81872B2BB7",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2bb7",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 65,
    //     "prevFrameCounterFcnt": 1014,
    //     "latestFrameCounterFcnt": 1015,
    //     "lostFcnt": 264,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3638,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC158",
    //     "description": "90DFFB81872B2BB8",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2bb8",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1056,
    //     "latestFrameCounterFcnt": 1057,
    //     "lostFcnt": 254,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3639,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC159",
    //     "description": "90DFFB81872B2BB9",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2bb9",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 979,
    //     "latestFrameCounterFcnt": 980,
    //     "lostFcnt": 163,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3640,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC16",
    //     "description": "90DFFB81872B6624",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6624",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 103,
    //     "prevFrameCounterFcnt": 946,
    //     "latestFrameCounterFcnt": 1024,
    //     "lostFcnt": 308,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3641,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC160",
    //     "description": "90DFFB81872B2BBA",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2bba",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1043,
    //     "latestFrameCounterFcnt": 1044,
    //     "lostFcnt": 249,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3642,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC161",
    //     "description": "90DFFB81872B2BBB",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2bbb",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 970,
    //     "latestFrameCounterFcnt": 971,
    //     "lostFcnt": 134,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3643,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC162",
    //     "description": "90DFFB81872B2BBC",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2bbc",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 981,
    //     "latestFrameCounterFcnt": 982,
    //     "lostFcnt": 174,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3644,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC163",
    //     "description": "90DFFB81872B2BBD",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2bbd",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 978,
    //     "latestFrameCounterFcnt": 979,
    //     "lostFcnt": 173,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3645,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC164",
    //     "description": "90DFFB81872B2BBE",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2bbe",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 981,
    //     "latestFrameCounterFcnt": 982,
    //     "lostFcnt": 146,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3646,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC165",
    //     "description": "90DFFB81872B2BBF",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2bbf",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1018,
    //     "latestFrameCounterFcnt": 1023,
    //     "lostFcnt": 207,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3647,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC166",
    //     "description": "90DFFB81872B65C5",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65c5",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 969,
    //     "latestFrameCounterFcnt": 971,
    //     "lostFcnt": 170,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3648,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC167",
    //     "description": "90DFFB81872B65C6",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65c6",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 76,
    //     "prevFrameCounterFcnt": 1064,
    //     "latestFrameCounterFcnt": 1066,
    //     "lostFcnt": 280,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3649,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC168",
    //     "description": "90DFFB81872B65C9",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65c9",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 965,
    //     "latestFrameCounterFcnt": 966,
    //     "lostFcnt": 148,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3650,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC169",
    //     "description": "90DFFB81872B65CA",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65ca",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 967,
    //     "latestFrameCounterFcnt": 968,
    //     "lostFcnt": 164,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3652,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC170",
    //     "description": "90DFFB81872B65CB",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65cb",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1029,
    //     "latestFrameCounterFcnt": 1030,
    //     "lostFcnt": 195,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3653,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC171",
    //     "description": "90DFFB81872B65CC",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65cc",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 996,
    //     "latestFrameCounterFcnt": 997,
    //     "lostFcnt": 355,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3654,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC172",
    //     "description": "90DFFB81872B65CE",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65ce",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 980,
    //     "latestFrameCounterFcnt": 989,
    //     "lostFcnt": 402,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3655,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC173",
    //     "description": "90DFFB81872B65CF",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65cf",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1032,
    //     "latestFrameCounterFcnt": 1033,
    //     "lostFcnt": 220,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3657,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC175",
    //     "description": "90DFFB81872B65D2",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65d2",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1055,
    //     "latestFrameCounterFcnt": 1056,
    //     "lostFcnt": 294,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3658,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC176",
    //     "description": "90DFFB81872B65CD",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65cd",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1051,
    //     "latestFrameCounterFcnt": 1052,
    //     "lostFcnt": 324,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3659,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC177",
    //     "description": "90DFFB81872B65D3",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65d3",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 879,
    //     "latestFrameCounterFcnt": 886,
    //     "lostFcnt": 439,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3660,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC178",
    //     "description": "90DFFB81872B65D4",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65d4",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 272,
    //     "latestFrameCounterFcnt": 273,
    //     "lostFcnt": -75,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3661,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC179",
    //     "description": "90DFFB81872B65D5",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65d5",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 540,
    //     "latestFrameCounterFcnt": 543,
    //     "lostFcnt": 28,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3662,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC18",
    //     "description": "90DFFB81872B6626",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6626",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 978,
    //     "latestFrameCounterFcnt": 980,
    //     "lostFcnt": 411,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3663,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC180",
    //     "description": "90DFFB81872B65D6",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65d6",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 986,
    //     "latestFrameCounterFcnt": 987,
    //     "lostFcnt": 435,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3664,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC181",
    //     "description": "90DFFB81872B65D9",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65d9",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 990,
    //     "latestFrameCounterFcnt": 991,
    //     "lostFcnt": 143,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3665,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC182",
    //     "description": "90DFFB81872B65DA",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65da",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1046,
    //     "latestFrameCounterFcnt": 1047,
    //     "lostFcnt": 221,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3666,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC183",
    //     "description": "90DFFB81872B65DB",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65db",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1014,
    //     "latestFrameCounterFcnt": 1015,
    //     "lostFcnt": 178,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3667,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC184",
    //     "description": "90DFFB81872B65DE",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65de",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 978,
    //     "latestFrameCounterFcnt": 979,
    //     "lostFcnt": 163,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3668,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC185",
    //     "description": "90DFFB81872B65DF",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65df",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 79,
    //     "prevFrameCounterFcnt": 1061,
    //     "latestFrameCounterFcnt": 1062,
    //     "lostFcnt": 310,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3669,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC186",
    //     "description": "90DFFB81872B65E0",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65e0",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 1038,
    //     "latestFrameCounterFcnt": 1039,
    //     "lostFcnt": 225,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3670,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC187",
    //     "description": "90DFFB81872B65E1",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65e1",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 64,
    //     "prevFrameCounterFcnt": 377,
    //     "latestFrameCounterFcnt": 378,
    //     "lostFcnt": -144,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3671,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC188",
    //     "description": "90DFFB81872B65E2",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65e2",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 945,
    //     "latestFrameCounterFcnt": 946,
    //     "lostFcnt": 164,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3672,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC189",
    //     "description": "90DFFB81872B65E4",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65e4",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 70,
    //     "prevFrameCounterFcnt": 1035,
    //     "latestFrameCounterFcnt": 1036,
    //     "lostFcnt": 206,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3674,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC190",
    //     "description": "90DFFB81872B65E5",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65e5",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 71,
    //     "prevFrameCounterFcnt": 1029,
    //     "latestFrameCounterFcnt": 1030,
    //     "lostFcnt": 266,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3675,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC191",
    //     "description": "90DFFB81872B65E6",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65e6",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 977,
    //     "latestFrameCounterFcnt": 978,
    //     "lostFcnt": 145,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3676,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC192",
    //     "description": "90DFFB81872B65E7",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65e7",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 978,
    //     "latestFrameCounterFcnt": 979,
    //     "lostFcnt": 153,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3677,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC193",
    //     "description": "90DFFB81872B65E8",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65e8",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1030,
    //     "latestFrameCounterFcnt": 1031,
    //     "lostFcnt": 221,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3678,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC195",
    //     "description": "90DFFB81872B65EA",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65ea",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 72,
    //     "prevFrameCounterFcnt": 1008,
    //     "latestFrameCounterFcnt": 1009,
    //     "lostFcnt": 156,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3679,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC197",
    //     "description": "90DFFB81872B65ED",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65ed",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 968,
    //     "latestFrameCounterFcnt": 969,
    //     "lostFcnt": 160,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3680,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC198",
    //     "description": "90DFFB81872B65EE",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65ee",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 73,
    //     "prevFrameCounterFcnt": 1043,
    //     "latestFrameCounterFcnt": 1044,
    //     "lostFcnt": 244,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3681,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC199",
    //     "description": "90DFFB81872B65EF",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65ef",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 78,
    //     "prevFrameCounterFcnt": 1050,
    //     "latestFrameCounterFcnt": 1051,
    //     "lostFcnt": 233,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3682,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC20",
    //     "description": "90DFFB81872B6628",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6628",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 108,
    //     "prevFrameCounterFcnt": 1006,
    //     "latestFrameCounterFcnt": 1008,
    //     "lostFcnt": 371,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3683,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC200",
    //     "description": "90DFFB81872B6E46",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6e46",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 68,
    //     "prevFrameCounterFcnt": 1030,
    //     "latestFrameCounterFcnt": 1031,
    //     "lostFcnt": 231,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3684,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC201",
    //     "description": "90DFFB81872B65EB",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65eb",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 69,
    //     "prevFrameCounterFcnt": 899,
    //     "latestFrameCounterFcnt": 993,
    //     "lostFcnt": 569,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3685,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC202",
    //     "description": "90DFFB81872B65F0",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65f0",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 65,
    //     "prevFrameCounterFcnt": 1011,
    //     "latestFrameCounterFcnt": 1012,
    //     "lostFcnt": 417,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3686,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC203",
    //     "description": "90DFFB81872B65F2",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65f2",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 75,
    //     "prevFrameCounterFcnt": 1053,
    //     "latestFrameCounterFcnt": 1054,
    //     "lostFcnt": 231,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3687,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC204",
    //     "description": "90DFFB81872B65F3",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65f3",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 66,
    //     "prevFrameCounterFcnt": 364,
    //     "latestFrameCounterFcnt": 366,
    //     "lostFcnt": -140,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3688,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC21",
    //     "description": "90DFFB81872B6629",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6629",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 105,
    //     "prevFrameCounterFcnt": 1036,
    //     "latestFrameCounterFcnt": 1037,
    //     "lostFcnt": 355,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3689,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC22",
    //     "description": "90DFFB81872B662A",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b662a",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 108,
    //     "prevFrameCounterFcnt": 650,
    //     "latestFrameCounterFcnt": 652,
    //     "lostFcnt": -14,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3690,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC23",
    //     "description": "90DFFB81872B662B",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b662b",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 109,
    //     "prevFrameCounterFcnt": 1075,
    //     "latestFrameCounterFcnt": 1076,
    //     "lostFcnt": 288,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3691,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC24",
    //     "description": "90DFFB81872B662C",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b662c",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 109,
    //     "prevFrameCounterFcnt": 1069,
    //     "latestFrameCounterFcnt": 1070,
    //     "lostFcnt": 244,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3692,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC25",
    //     "description": "90DFFB81872B662D",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b662d",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 105,
    //     "prevFrameCounterFcnt": 1043,
    //     "latestFrameCounterFcnt": 1044,
    //     "lostFcnt": 156,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3693,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC26",
    //     "description": "90DFFB81872B2ADE",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2ade",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 104,
    //     "prevFrameCounterFcnt": 1020,
    //     "latestFrameCounterFcnt": 1021,
    //     "lostFcnt": 470,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3694,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC27",
    //     "description": "90DFFB81872B2AE1",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2ae1",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 103,
    //     "prevFrameCounterFcnt": 145,
    //     "latestFrameCounterFcnt": 146,
    //     "lostFcnt": -288,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3695,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC28",
    //     "description": "90DFFB81872B2AE2",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2ae2",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 100,
    //     "prevFrameCounterFcnt": 33,
    //     "latestFrameCounterFcnt": 34,
    //     "lostFcnt": -434,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3696,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC29",
    //     "description": "90DFFB81872B2AE3",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2ae3",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 103,
    //     "prevFrameCounterFcnt": 1009,
    //     "latestFrameCounterFcnt": 1010,
    //     "lostFcnt": 151,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3697,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC30",
    //     "description": "90DFFB81872B2AE4",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2ae4",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 108,
    //     "prevFrameCounterFcnt": 1010,
    //     "latestFrameCounterFcnt": 1011,
    //     "lostFcnt": 150,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3698,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC31",
    //     "description": "90DFFB81872B2AE5",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2ae5",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 109,
    //     "prevFrameCounterFcnt": 1065,
    //     "latestFrameCounterFcnt": 1066,
    //     "lostFcnt": 245,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3699,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC32",
    //     "description": "90DFFB81872B2AE6",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2ae6",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 106,
    //     "prevFrameCounterFcnt": 1035,
    //     "latestFrameCounterFcnt": 1036,
    //     "lostFcnt": 162,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3700,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC33",
    //     "description": "90DFFB81872B2AE7",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2ae7",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 105,
    //     "prevFrameCounterFcnt": 893,
    //     "latestFrameCounterFcnt": 894,
    //     "lostFcnt": 275,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3701,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC34",
    //     "description": "90DFFB81872B2AE8",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2ae8",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 104,
    //     "prevFrameCounterFcnt": 1048,
    //     "latestFrameCounterFcnt": 1049,
    //     "lostFcnt": 308,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3702,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC35",
    //     "description": "90DFFB81872B2AE9",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2ae9",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 104,
    //     "prevFrameCounterFcnt": 1011,
    //     "latestFrameCounterFcnt": 1012,
    //     "lostFcnt": 148,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3703,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC36",
    //     "description": "90DFFB81872B2AEA",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2aea",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 104,
    //     "prevFrameCounterFcnt": 1007,
    //     "latestFrameCounterFcnt": 1008,
    //     "lostFcnt": 146,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3704,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC37",
    //     "description": "90DFFB81872B2AEB",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b2aeb",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 104,
    //     "prevFrameCounterFcnt": 1055,
    //     "latestFrameCounterFcnt": 1056,
    //     "lostFcnt": 330,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3705,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC38",
    //     "description": "90DFFB81872B66CD",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b66cd",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 102,
    //     "prevFrameCounterFcnt": 1044,
    //     "latestFrameCounterFcnt": 1045,
    //     "lostFcnt": 253,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3706,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC39",
    //     "description": "90DFFB81872B66CE",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b66ce",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 99,
    //     "prevFrameCounterFcnt": 730,
    //     "latestFrameCounterFcnt": 732,
    //     "lostFcnt": 198,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3707,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC40",
    //     "description": "90DFFB81872B66CF",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b66cf",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 112,
    //     "prevFrameCounterFcnt": 1061,
    //     "latestFrameCounterFcnt": 1062,
    //     "lostFcnt": 292,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3708,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC41",
    //     "description": "90DFFB81872B66D0",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b66d0",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 102,
    //     "prevFrameCounterFcnt": 1031,
    //     "latestFrameCounterFcnt": 1032,
    //     "lostFcnt": 410,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3709,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC42",
    //     "description": "90DFFB81872B66D1",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b66d1",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 103,
    //     "prevFrameCounterFcnt": 1012,
    //     "latestFrameCounterFcnt": 1013,
    //     "lostFcnt": 536,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3710,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC43",
    //     "description": "90DFFB81872B66D2",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b66d2",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 103,
    //     "prevFrameCounterFcnt": 1005,
    //     "latestFrameCounterFcnt": 1007,
    //     "lostFcnt": 568,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3711,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC44",
    //     "description": "90DFFB81872B66D3",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b66d3",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 105,
    //     "prevFrameCounterFcnt": 143,
    //     "latestFrameCounterFcnt": 144,
    //     "lostFcnt": -348,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3712,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC45",
    //     "description": "90DFFB81872B66D4",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b66d4",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 98,
    //     "prevFrameCounterFcnt": 1006,
    //     "latestFrameCounterFcnt": 1008,
    //     "lostFcnt": 439,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3713,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC46",
    //     "description": "90DFFB81872B66D5",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b66d5",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 105,
    //     "prevFrameCounterFcnt": 1064,
    //     "latestFrameCounterFcnt": 1065,
    //     "lostFcnt": 264,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3714,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC47",
    //     "description": "90DFFB81872B66D6",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b66d6",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 106,
    //     "prevFrameCounterFcnt": 1064,
    //     "latestFrameCounterFcnt": 1065,
    //     "lostFcnt": 331,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3715,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC48",
    //     "description": "90DFFB81872B66D7",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b66d7",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 3,
    //     "prevFrameCounterFcnt": 117,
    //     "latestFrameCounterFcnt": 118,
    //     "lostFcnt": -118,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3716,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC49",
    //     "description": "90DFFB81872B66D8",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b66d8",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 105,
    //     "prevFrameCounterFcnt": 860,
    //     "latestFrameCounterFcnt": 865,
    //     "lostFcnt": 334,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3717,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC50",
    //     "description": "90DFFB81872B66D9",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b66d9",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 100,
    //     "prevFrameCounterFcnt": 1019,
    //     "latestFrameCounterFcnt": 1020,
    //     "lostFcnt": 468,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3718,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC51",
    //     "description": "90DFFB81872B6643",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6643",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 105,
    //     "prevFrameCounterFcnt": 1045,
    //     "latestFrameCounterFcnt": 1046,
    //     "lostFcnt": 175,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3719,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC52",
    //     "description": "90DFFB81872B6648Alonso",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6648",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 100,
    //     "prevFrameCounterFcnt": 1031,
    //     "latestFrameCounterFcnt": 1032,
    //     "lostFcnt": 152,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3720,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC53",
    //     "description": "90DFFB81872B6649",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6649",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 105,
    //     "prevFrameCounterFcnt": 1095,
    //     "latestFrameCounterFcnt": 1096,
    //     "lostFcnt": 258,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3721,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC54",
    //     "description": "90DFFB81872B664A",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b664a",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 104,
    //     "prevFrameCounterFcnt": 1053,
    //     "latestFrameCounterFcnt": 1054,
    //     "lostFcnt": 279,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3722,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC55",
    //     "description": "90DFFB81872B664B",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b664b",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 101,
    //     "prevFrameCounterFcnt": 1090,
    //     "latestFrameCounterFcnt": 1091,
    //     "lostFcnt": 284,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3723,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC56",
    //     "description": "90DFFB81872B664C",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b664c",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 94,
    //     "prevFrameCounterFcnt": 1019,
    //     "latestFrameCounterFcnt": 1023,
    //     "lostFcnt": 565,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3724,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC57",
    //     "description": "90DFFB81872B664D",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b664d",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 35,
    //     "prevFrameCounterFcnt": 20,
    //     "latestFrameCounterFcnt": 27,
    //     "lostFcnt": -123,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3725,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC58",
    //     "description": "90DFFB81872B664E",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b664e",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 101,
    //     "prevFrameCounterFcnt": 1059,
    //     "latestFrameCounterFcnt": 1060,
    //     "lostFcnt": 258,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3726,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC59",
    //     "description": "90DFFB81872B664F",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b664f",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 95,
    //     "prevFrameCounterFcnt": 1005,
    //     "latestFrameCounterFcnt": 1006,
    //     "lostFcnt": 353,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3727,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC60",
    //     "description": "90DFFB81872B6650",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6650",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 96,
    //     "prevFrameCounterFcnt": 1024,
    //     "latestFrameCounterFcnt": 1025,
    //     "lostFcnt": 342,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3728,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC61",
    //     "description": "90DFFB81872B6651",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6651",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 62,
    //     "prevFrameCounterFcnt": 31,
    //     "latestFrameCounterFcnt": 53,
    //     "lostFcnt": -31,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3729,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC62",
    //     "description": "90DFFB81872B6652",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6652",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 104,
    //     "prevFrameCounterFcnt": 1051,
    //     "latestFrameCounterFcnt": 1052,
    //     "lostFcnt": 329,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3730,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC63",
    //     "description": "90DFFB81872B6654",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6654",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 107,
    //     "prevFrameCounterFcnt": 1062,
    //     "latestFrameCounterFcnt": 1063,
    //     "lostFcnt": 275,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3731,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC64",
    //     "description": "90DFFB81872B6655",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6655",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 101,
    //     "prevFrameCounterFcnt": 1082,
    //     "latestFrameCounterFcnt": 1083,
    //     "lostFcnt": 228,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3732,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC65",
    //     "description": "90DFFB81872B6656",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6656",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 95,
    //     "prevFrameCounterFcnt": 1064,
    //     "latestFrameCounterFcnt": 1065,
    //     "lostFcnt": 222,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3733,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC66",
    //     "description": "90DFFB81872B6657",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6657",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 120,
    //     "prevFrameCounterFcnt": 1123,
    //     "latestFrameCounterFcnt": 1124,
    //     "lostFcnt": 234,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3734,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC67",
    //     "description": "90DFFB81872B6658",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6658",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 107,
    //     "prevFrameCounterFcnt": 898,
    //     "latestFrameCounterFcnt": 899,
    //     "lostFcnt": 131,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3735,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC68",
    //     "description": "90DFFB81872B6659",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6659",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 106,
    //     "prevFrameCounterFcnt": 1109,
    //     "latestFrameCounterFcnt": 1110,
    //     "lostFcnt": 260,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3736,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC69",
    //     "description": "90DFFB81872B665A",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b665a",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 98,
    //     "prevFrameCounterFcnt": 1079,
    //     "latestFrameCounterFcnt": 1080,
    //     "lostFcnt": 282,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3737,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC70",
    //     "description": "90DFFB81872B665B",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b665b",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 116,
    //     "prevFrameCounterFcnt": 1081,
    //     "latestFrameCounterFcnt": 1082,
    //     "lostFcnt": 306,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3738,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC71",
    //     "description": "90DFFB81872B665C",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b665c",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 6,
    //     "prevFrameCounterFcnt": 83,
    //     "latestFrameCounterFcnt": 129,
    //     "lostFcnt": 22,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3739,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC72",
    //     "description": "90DFFB81872B665E",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b665e",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 584,
    //     "latestFrameCounterFcnt": 585,
    //     "lostFcnt": 304,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3740,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC73",
    //     "description": "90DFFB81872B665F",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b665f",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 4,
    //     "prevFrameCounterFcnt": 1,
    //     "latestFrameCounterFcnt": 47,
    //     "lostFcnt": 27,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3741,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC74",
    //     "description": "90DFFB81872B6660",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6660",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 18,
    //     "latestFrameCounterFcnt": 51,
    //     "lostFcnt": 27,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3742,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC75",
    //     "description": "90DFFB81872B6661",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6661",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 61,
    //     "prevFrameCounterFcnt": 27,
    //     "latestFrameCounterFcnt": 33,
    //     "lostFcnt": -56,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3743,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC76",
    //     "description": "90DFFB81872B65BA",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65ba",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 59,
    //     "latestFrameCounterFcnt": 8,
    //     "lostFcnt": -75,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3744,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC77",
    //     "description": "90DFFB81872B65BB",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65bb",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 46,
    //     "latestFrameCounterFcnt": 47,
    //     "lostFcnt": 32,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3745,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC78",
    //     "description": "90DFFB81872B65BC",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65bc",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 22,
    //     "prevFrameCounterFcnt": 32,
    //     "latestFrameCounterFcnt": 50,
    //     "lostFcnt": -16,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3747,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC80",
    //     "description": "90DFFB81872B65BE",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65be",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 7,
    //     "prevFrameCounterFcnt": 70,
    //     "latestFrameCounterFcnt": 2,
    //     "lostFcnt": -93,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3748,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC81",
    //     "description": "90DFFB81872B65C0",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65c0",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 99,
    //     "prevFrameCounterFcnt": 1009,
    //     "latestFrameCounterFcnt": 1010,
    //     "lostFcnt": 168,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3749,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC82",
    //     "description": "90DFFB81872B65C1",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65c1",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 95,
    //     "prevFrameCounterFcnt": 1009,
    //     "latestFrameCounterFcnt": 1010,
    //     "lostFcnt": 170,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3750,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC83",
    //     "description": "90DFFB81872B65C2",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65c2",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 99,
    //     "prevFrameCounterFcnt": 1013,
    //     "latestFrameCounterFcnt": 1014,
    //     "lostFcnt": 157,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3751,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC84",
    //     "description": "90DFFB81872B65C3",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65c3",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 95,
    //     "prevFrameCounterFcnt": 730,
    //     "latestFrameCounterFcnt": 731,
    //     "lostFcnt": -136,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3752,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC85",
    //     "description": "90DFFB81872B65C4",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b65c4",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 813,
    //     "latestFrameCounterFcnt": 814,
    //     "lostFcnt": 145,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3753,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC86",
    //     "description": "90DFFB81872B665D",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b665d",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 96,
    //     "prevFrameCounterFcnt": 1020,
    //     "latestFrameCounterFcnt": 1021,
    //     "lostFcnt": 197,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3754,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC87",
    //     "description": "90DFFB81872B6664",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6664",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 105,
    //     "prevFrameCounterFcnt": 1017,
    //     "latestFrameCounterFcnt": 1018,
    //     "lostFcnt": 179,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3755,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC88",
    //     "description": "90DFFB81872B6665",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6665",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 94,
    //     "prevFrameCounterFcnt": 1017,
    //     "latestFrameCounterFcnt": 1018,
    //     "lostFcnt": 161,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3756,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC89",
    //     "description": "90DFFB81872B6666",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6666",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 96,
    //     "prevFrameCounterFcnt": 1012,
    //     "latestFrameCounterFcnt": 1013,
    //     "lostFcnt": 172,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3757,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC90",
    //     "description": "90DFFB81872B6667",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6667",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 98,
    //     "prevFrameCounterFcnt": 1003,
    //     "latestFrameCounterFcnt": 1004,
    //     "lostFcnt": 173,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3758,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC91",
    //     "description": "90DFFB81872B6668",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6668",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 99,
    //     "prevFrameCounterFcnt": 1061,
    //     "latestFrameCounterFcnt": 1062,
    //     "lostFcnt": 208,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3759,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC92",
    //     "description": "90DFFB81872B6669",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6669",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 96,
    //     "prevFrameCounterFcnt": 1007,
    //     "latestFrameCounterFcnt": 1008,
    //     "lostFcnt": 158,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3760,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC93",
    //     "description": "90DFFB81872B666A",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b666a",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 67,
    //     "prevFrameCounterFcnt": 1019,
    //     "latestFrameCounterFcnt": 1020,
    //     "lostFcnt": 208,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3761,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC94",
    //     "description": "90DFFB81872B666B",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b666b",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 97,
    //     "prevFrameCounterFcnt": 1042,
    //     "latestFrameCounterFcnt": 1043,
    //     "lostFcnt": 315,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3762,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC95",
    //     "description": "90DFFB81872B666C",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b666c",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 95,
    //     "prevFrameCounterFcnt": 1033,
    //     "latestFrameCounterFcnt": 1034,
    //     "lostFcnt": 319,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3763,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC96",
    //     "description": "90DFFB81872B666D",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b666d",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 94,
    //     "prevFrameCounterFcnt": 1060,
    //     "latestFrameCounterFcnt": 1061,
    //     "lostFcnt": 213,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3764,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC97",
    //     "description": "90DFFB81872B666E",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b666e",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 100,
    //     "prevFrameCounterFcnt": 1063,
    //     "latestFrameCounterFcnt": 1064,
    //     "lostFcnt": 226,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 3766,
    //     "gatewaysId": null,
    //     "name": "WaterExternalBEGGOC99",
    //     "description": "90DFFB81872B6E9E",
    //     "provider": null,
    //     "userId": 80,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": null,
    //     "installationDate": "2021-11-22",
    //     "applicationName": null,
    //     "latitude": null,
    //     "longitude": null,
    //     "deviceEUI": "90dffb81872b6e9e",
    //     "appEUI": null,
    //     "appKEY": null,
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827eb603343,b827eb166322",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 100,
    //     "prevFrameCounterFcnt": 1066,
    //     "latestFrameCounterFcnt": 1067,
    //     "lostFcnt": 328,
    //     "loraSNR": null,
    //     "networkServerMac": "b827eb603343",
    //     "messageTime": null
    // },
    // {
    //     "id": 4171,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC01",
    //     "description": "C/ Antonio Machado, 4",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046412,
    //     "longitude": -0.469075,
    //     "deviceEUI": "90dffb81872ae738",
    //     "appEUI": "app",
    //     "appKEY": "EFE7BB4B42FAAC581F166C2E1DACA0C1",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 19,
    //     "prevFrameCounterFcnt": 989,
    //     "latestFrameCounterFcnt": 990,
    //     "lostFcnt": 367,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4172,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC02",
    //     "description": "C/ Antonio Machado, 6",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046382,
    //     "longitude": -0.46976,
    //     "deviceEUI": "90dffb81872ae739",
    //     "appEUI": "app",
    //     "appKEY": "891F8CF32DD9898351628FC455BC5E71",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 942,
    //     "latestFrameCounterFcnt": 945,
    //     "lostFcnt": 526,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4173,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC03",
    //     "description": "C/ l'Estació, 5 ",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.04642,
    //     "longitude": -0.46985,
    //     "deviceEUI": "90dffb81872b2002",
    //     "appEUI": "app",
    //     "appKEY": "D9ADC9A9AF53D972F46F8587BFA75D38",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "dca63214becf",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 966,
    //     "latestFrameCounterFcnt": 967,
    //     "lostFcnt": 374,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4174,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC04",
    //     "description": "C/ Antonio Machado, 8",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046313,
    //     "longitude": -0.469781,
    //     "deviceEUI": "90dffb81872ae809",
    //     "appEUI": "app",
    //     "appKEY": "E2F5749DA0B1C865E0ED49FE7E3BC34D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 944,
    //     "latestFrameCounterFcnt": 945,
    //     "lostFcnt": 486,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4175,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC05",
    //     "description": "C/ Antonio Machado, 10",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046283,
    //     "longitude": -0.469872,
    //     "deviceEUI": "90dffb81872aa39c",
    //     "appEUI": "app",
    //     "appKEY": "19E3EE81AD9033A401AFDC52152DB4B2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 973,
    //     "latestFrameCounterFcnt": 974,
    //     "lostFcnt": 490,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4176,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC06",
    //     "description": "C/ Antonio Machado, 12",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046199,
    //     "longitude": -0.469957,
    //     "deviceEUI": "90dffb81872aac03",
    //     "appEUI": "app",
    //     "appKEY": "DE6CA1576F385F4483D83DBA9D0EF546",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 967,
    //     "latestFrameCounterFcnt": 974,
    //     "lostFcnt": 359,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4177,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC07",
    //     "description": "C/ Antonio Machado, 14",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046144,
    //     "longitude": -0.470075,
    //     "deviceEUI": "90dffb81872aa013",
    //     "appEUI": "app",
    //     "appKEY": "6953D6CCE96680C1E11C635DCEAE18FB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 988,
    //     "latestFrameCounterFcnt": 989,
    //     "lostFcnt": 356,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4178,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC08",
    //     "description": "C/ Antonio Machado, 16",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046062,
    //     "longitude": -0.470107,
    //     "deviceEUI": "90dffb81872ae808",
    //     "appEUI": "app",
    //     "appKEY": "D10B5E977F0E27F4FCD089E4ECAADED3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 980,
    //     "latestFrameCounterFcnt": 981,
    //     "lostFcnt": 418,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4179,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC09",
    //     "description": "Antonio Machado, 15",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045949,
    //     "longitude": -0.469753,
    //     "deviceEUI": "90dffb81872aeb15",
    //     "appEUI": "app",
    //     "appKEY": "1BFB16EEA82DE184BD32571FB8B21154",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 11,
    //     "prevFrameCounterFcnt": 984,
    //     "latestFrameCounterFcnt": 985,
    //     "lostFcnt": 391,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4180,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC10",
    //     "description": "C/ Antonio Machado, 9",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046013,
    //     "longitude": -0.469778,
    //     "deviceEUI": "90dffb81872b59d8",
    //     "appEUI": "app",
    //     "appKEY": "3A1BC3DB261A8C10BBCA91E1659EE726",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 993,
    //     "latestFrameCounterFcnt": 994,
    //     "lostFcnt": 408,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4181,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC11",
    //     "description": "C/ Antonio Machado, 7",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046097,
    //     "longitude": -0.469789,
    //     "deviceEUI": "90dffb81872b59d9",
    //     "appEUI": "app",
    //     "appKEY": "F5CA69237EA93DD8AADACE024A55395E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 1018,
    //     "latestFrameCounterFcnt": 1020,
    //     "lostFcnt": 304,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4182,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC12",
    //     "description": "C/ Antonio Machado, 0",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046097,
    //     "longitude": -0.469789,
    //     "deviceEUI": "90dffb81872ae80a",
    //     "appEUI": "app",
    //     "appKEY": "F1C24ABB46A729AE1C1827DB06F927ED",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 1000,
    //     "latestFrameCounterFcnt": 1001,
    //     "lostFcnt": 329,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4183,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC13",
    //     "description": "C/ Antonio Machado, 3",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046151,
    //     "longitude": -0.46964,
    //     "deviceEUI": "90dffb81872b59dc",
    //     "appEUI": "app",
    //     "appKEY": "5993098C7652EFD7F2AAE473689C93D7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 981,
    //     "latestFrameCounterFcnt": 982,
    //     "lostFcnt": 425,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4184,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC14",
    //     "description": "Plaza Joan XXIII, 11",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045558,
    //     "longitude": -0.46832,
    //     "deviceEUI": "90dffb81872b59d7",
    //     "appEUI": "app",
    //     "appKEY": "7F5919357B77BA054FF3BEE9F308BC01",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 1008,
    //     "latestFrameCounterFcnt": 1009,
    //     "lostFcnt": 271,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4185,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC15",
    //     "description": "Plaza Joan XXIII, 5",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045552,
    //     "longitude": -0.467532,
    //     "deviceEUI": "90dffb81872b59d4",
    //     "appEUI": "app",
    //     "appKEY": "7C62E4A7295089AACB6A428EF3BB05F6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 12,
    //     "prevFrameCounterFcnt": 993,
    //     "latestFrameCounterFcnt": 994,
    //     "lostFcnt": 514,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4186,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC16",
    //     "description": "Plaza Joan XXIII, 15",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045874,
    //     "longitude": -0.468072,
    //     "deviceEUI": "90dffb81872b59d3",
    //     "appEUI": "app",
    //     "appKEY": "45E36648E9517712A6166730C6F4DC1A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 1025,
    //     "latestFrameCounterFcnt": 1026,
    //     "lostFcnt": 278,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4187,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC17",
    //     "description": "Plaza Joan XXIII, 17",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045971,
    //     "longitude": -0.467953,
    //     "deviceEUI": "90dffb81872b59da",
    //     "appEUI": "app",
    //     "appKEY": "FE9BC60D0372E943F46573AA944C73A6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 1033,
    //     "latestFrameCounterFcnt": 1034,
    //     "lostFcnt": 317,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4188,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC18",
    //     "description": "Plaza Joan XXIII, 9 a ",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045473,
    //     "longitude": -0.468169,
    //     "deviceEUI": "90dffb81872b59db",
    //     "appEUI": "app",
    //     "appKEY": "B1E6F1B57743E657E2C55E83CC3D3651",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 1018,
    //     "latestFrameCounterFcnt": 1019,
    //     "lostFcnt": 342,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4189,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC19",
    //     "description": "Mare de Deu dels Desemparats, 5",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045715,
    //     "longitude": -0.468552,
    //     "deviceEUI": "90dffb81872b59d5",
    //     "appEUI": "app",
    //     "appKEY": "67640FD5589D256E255C51732832ADC0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 1016,
    //     "latestFrameCounterFcnt": 1017,
    //     "lostFcnt": 312,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4190,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC20",
    //     "description": "Mare de Deu dels Desemparats, 3",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045707,
    //     "longitude": -0.46844,
    //     "deviceEUI": "90dffb81872b59d6",
    //     "appEUI": "app",
    //     "appKEY": "266C814C2ACA2CDEC8F8D4F1239734A2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 12,
    //     "prevFrameCounterFcnt": 984,
    //     "latestFrameCounterFcnt": 985,
    //     "lostFcnt": 391,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4191,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC21",
    //     "description": "Mare de Deu dels Desemparats, 6",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045971,
    //     "longitude": -0.468454,
    //     "deviceEUI": "90dffb81872af84d",
    //     "appEUI": "app",
    //     "appKEY": "316974166E630E81DA66F487690CB7E8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 1034,
    //     "latestFrameCounterFcnt": 1035,
    //     "lostFcnt": 313,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4192,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC22",
    //     "description": "La Pau, 1",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046025,
    //     "longitude": -0.468604,
    //     "deviceEUI": "90dffb81872ae80b",
    //     "appEUI": "app",
    //     "appKEY": "CDD5A99138B2CA8E62DCBEF7427B4D86",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 12,
    //     "prevFrameCounterFcnt": 1004,
    //     "latestFrameCounterFcnt": 1005,
    //     "lostFcnt": 396,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4193,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC23",
    //     "description": "La Pau, 5",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.04617,
    //     "longitude": -0.468563,
    //     "deviceEUI": "90dffb81872af852",
    //     "appEUI": "app",
    //     "appKEY": "1750B91A818A6B608ED385BA90FC95E1",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 957,
    //     "latestFrameCounterFcnt": 958,
    //     "lostFcnt": 430,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4194,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC24",
    //     "description": "La Pau, 4 ",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045959,
    //     "longitude": -0.468317,
    //     "deviceEUI": "90dffb81872ab67b",
    //     "appEUI": "app",
    //     "appKEY": "D304BD0C4E805FAA1292107FADA117E2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 325,
    //     "latestFrameCounterFcnt": 326,
    //     "lostFcnt": 41,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4195,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC25",
    //     "description": "La Pau, 7",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046191,
    //     "longitude": -0.468489,
    //     "deviceEUI": "90dffb81872af854",
    //     "appEUI": "app",
    //     "appKEY": "14DC8B32A0D31E5A720860F110223828",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 536,
    //     "latestFrameCounterFcnt": 537,
    //     "lostFcnt": 101,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4196,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC26",
    //     "description": "Cardenal Tarancón, 7 ",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046165,
    //     "longitude": -0.468276,
    //     "deviceEUI": "90dffb81872aac02",
    //     "appEUI": "app",
    //     "appKEY": "AAEBE9580486B35D0039A7DFAB996FAB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 1021,
    //     "latestFrameCounterFcnt": 1022,
    //     "lostFcnt": 283,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4197,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC27",
    //     "description": "Mare de deu dels desamparats, 26 (por detrás)",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046491,
    //     "longitude": -0.46936,
    //     "deviceEUI": "90dffb81872aea93",
    //     "appEUI": "app",
    //     "appKEY": "5E9FD5269E482AFB6D71250348A30B8A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 19,
    //     "prevFrameCounterFcnt": 1012,
    //     "latestFrameCounterFcnt": 1013,
    //     "lostFcnt": 352,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4198,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC28",
    //     "description": "Sant Josep, 18 ",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045734,
    //     "longitude": -0.469534,
    //     "deviceEUI": "90dffb81872aea96",
    //     "appEUI": "app",
    //     "appKEY": "AE150145FFD8BAB06A9178FE33567001",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 12,
    //     "prevFrameCounterFcnt": 614,
    //     "latestFrameCounterFcnt": 615,
    //     "lostFcnt": 106,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4199,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC29",
    //     "description": "Tirant Lo Blanc, 9",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.04578,
    //     "longitude": -0.47001,
    //     "deviceEUI": "90dffb81872af84b",
    //     "appEUI": "app",
    //     "appKEY": "D80EC05D70C072CFE0ECEEC67ABF5CA5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 990,
    //     "latestFrameCounterFcnt": 991,
    //     "lostFcnt": 387,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4200,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC30",
    //     "description": "Les Eres, 8 ",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.04552,
    //     "longitude": -0.468762,
    //     "deviceEUI": "90dffb81872aea95",
    //     "appEUI": "app",
    //     "appKEY": "EF7B49BE6A41352DCDA42FAD416BB82F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 948,
    //     "latestFrameCounterFcnt": 952,
    //     "lostFcnt": 580,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4201,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC31",
    //     "description": "Les Eres, 10",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045562,
    //     "longitude": -0.468831,
    //     "deviceEUI": "90dffb81872b2305",
    //     "appEUI": "app",
    //     "appKEY": "BE6A52CF6A1E258F900C96479F686E72",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 960,
    //     "latestFrameCounterFcnt": 961,
    //     "lostFcnt": 467,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4202,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC32",
    //     "description": "Antonio Machado, 2",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046491,
    //     "longitude": -0.469688,
    //     "deviceEUI": "90dffb81872ae79c",
    //     "appEUI": "app",
    //     "appKEY": "6D2E9611BA9F959876460C60075F6EF3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 1000,
    //     "latestFrameCounterFcnt": 1001,
    //     "lostFcnt": 369,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4203,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC33",
    //     "description": "Antonio Machado, 5 ",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046491,
    //     "longitude": -0.469688,
    //     "deviceEUI": "90dffb81872ae79d",
    //     "appEUI": "app",
    //     "appKEY": "2B125C7139099E88CDBABDD0883A78FB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 1010,
    //     "latestFrameCounterFcnt": 1012,
    //     "lostFcnt": 422,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4204,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC34",
    //     "description": "Antonio Machado, 5 bis",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046491,
    //     "longitude": -0.469688,
    //     "deviceEUI": "90dffb81872ae79e",
    //     "appEUI": "app",
    //     "appKEY": "C1495E3F4EEEDF03C8FC4520025A86B8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 19,
    //     "prevFrameCounterFcnt": 1031,
    //     "latestFrameCounterFcnt": 1032,
    //     "lostFcnt": 416,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4205,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC35",
    //     "description": "Antonio Machado, 7 - 1",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046097,
    //     "longitude": -0.469789,
    //     "deviceEUI": "90dffb81872ae79f",
    //     "appEUI": "app",
    //     "appKEY": "C1D354791C59B74260120680FE39985A",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 12,
    //     "prevFrameCounterFcnt": 971,
    //     "latestFrameCounterFcnt": 972,
    //     "lostFcnt": 392,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4206,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC36",
    //     "description": "Mare de deu dels desamparats, 27",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046344,
    //     "longitude": -0.469488,
    //     "deviceEUI": "90dffb81872ae7a0",
    //     "appEUI": "app",
    //     "appKEY": "D058031CBD70FE979EDAAABF724E17C0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 952,
    //     "latestFrameCounterFcnt": 953,
    //     "lostFcnt": 412,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4207,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC37",
    //     "description": "Mare de deu dels desamparats, 26 ",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046491,
    //     "longitude": -0.46936,
    //     "deviceEUI": "90dffb81872ae838",
    //     "appEUI": "app",
    //     "appKEY": "09B95E35B98A5156EF1991F813C1CE72",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 988,
    //     "latestFrameCounterFcnt": 989,
    //     "lostFcnt": 381,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4208,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC38",
    //     "description": "Mare de deu dels desamparats, 24",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046491,
    //     "longitude": -0.46936,
    //     "deviceEUI": "90dffb81872ae837",
    //     "appEUI": "app",
    //     "appKEY": "8D57844D0731AC35CC0CDF61E997842B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 11,
    //     "prevFrameCounterFcnt": 970,
    //     "latestFrameCounterFcnt": 971,
    //     "lostFcnt": 474,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4209,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC39",
    //     "description": "Mare de deu dels desamparats, 25",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046491,
    //     "longitude": -0.46936,
    //     "deviceEUI": "90dffb81872ae836",
    //     "appEUI": "app",
    //     "appKEY": "7B6A3C2C5BED3C09267F73C40944E063",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 18,
    //     "prevFrameCounterFcnt": 1021,
    //     "latestFrameCounterFcnt": 1022,
    //     "lostFcnt": 314,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4210,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC40",
    //     "description": "Mare de deu dels desamparats, 23",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046491,
    //     "longitude": -0.46936,
    //     "deviceEUI": "90dffb81872ae835",
    //     "appEUI": "app",
    //     "appKEY": "A6C40B819934FFB3526A45CBA69BFF9B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 933,
    //     "latestFrameCounterFcnt": 936,
    //     "lostFcnt": 623,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4211,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC41",
    //     "description": "Mare de deu dels desamparats, 20 A",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046491,
    //     "longitude": -0.46936,
    //     "deviceEUI": "90dffb81872ae834",
    //     "appEUI": "app",
    //     "appKEY": "8341434188D0A248298A27215CD12C61",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 999,
    //     "latestFrameCounterFcnt": 1000,
    //     "lostFcnt": 411,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4212,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC42",
    //     "description": "Mare de deu dels desamparats, 18",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046491,
    //     "longitude": -0.46936,
    //     "deviceEUI": "90dffb81872ae7a8",
    //     "appEUI": "app",
    //     "appKEY": "2253A96D27C62AB928B447EFCCE33644",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 956,
    //     "latestFrameCounterFcnt": 957,
    //     "lostFcnt": 492,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4213,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC43",
    //     "description": "Mare de deu dels desamparats, 16",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046491,
    //     "longitude": -0.46936,
    //     "deviceEUI": "90dffb81872ae7a9",
    //     "appEUI": "app",
    //     "appKEY": "672812D56A8E62CE9A3CF34FB53BFC29",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 811,
    //     "latestFrameCounterFcnt": 812,
    //     "lostFcnt": 378,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4214,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC44",
    //     "description": "Sant Josep, 4",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046182,
    //     "longitude": -0.469177,
    //     "deviceEUI": "90dffb81872ae7a2",
    //     "appEUI": "app",
    //     "appKEY": "936D7F6654101E379B28847952D9579D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 991,
    //     "latestFrameCounterFcnt": 992,
    //     "lostFcnt": 437,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4215,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC45",
    //     "description": "Mare de deu dels desamparats, 19",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046182,
    //     "longitude": -0.469177,
    //     "deviceEUI": "90dffb81872ae7a5",
    //     "appEUI": "app",
    //     "appKEY": "4EBF1E5A5ADA3C111540EF69EAA36FDF",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 12,
    //     "prevFrameCounterFcnt": 121,
    //     "latestFrameCounterFcnt": 122,
    //     "lostFcnt": -222,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4216,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC46",
    //     "description": "Mare de deu dels desamparats, 17",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046182,
    //     "longitude": -0.469177,
    //     "deviceEUI": "90dffb81872ae7aa",
    //     "appEUI": "app",
    //     "appKEY": "3F79C27134DCC08A343A5A53F7A60989",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 12,
    //     "prevFrameCounterFcnt": 989,
    //     "latestFrameCounterFcnt": 990,
    //     "lostFcnt": 426,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4217,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC47",
    //     "description": "Mare de deu dels desamparats, 10",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046131,
    //     "longitude": -0.468811,
    //     "deviceEUI": "90dffb81872ae7a1",
    //     "appEUI": "app",
    //     "appKEY": "2BB6CB50DCF072761EDB44F5B7CC7F42",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 5,
    //     "prevFrameCounterFcnt": 672,
    //     "latestFrameCounterFcnt": 686,
    //     "lostFcnt": 290,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4219,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC49",
    //     "description": "Mare de deu dels desamparats, 15",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046182,
    //     "longitude": -0.469177,
    //     "deviceEUI": "90dffb81872ae7ab",
    //     "appEUI": "app",
    //     "appKEY": "7FCA37B2D0ADF2A76BAE51D602EDC1CD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 961,
    //     "latestFrameCounterFcnt": 963,
    //     "lostFcnt": 589,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4220,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC50",
    //     "description": "Mare de deu dels desamparats, 13",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046131,
    //     "longitude": -0.468811,
    //     "deviceEUI": "90dffb81872ae7a3",
    //     "appEUI": "app",
    //     "appKEY": "14CECA8B59409D6ADB53D644C0ED75EA",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 12,
    //     "prevFrameCounterFcnt": 957,
    //     "latestFrameCounterFcnt": 959,
    //     "lostFcnt": 495,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4221,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC51",
    //     "description": "Mare de deu dels desamparats, 9",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046131,
    //     "longitude": -0.468811,
    //     "deviceEUI": "90dffb81872ae7a6",
    //     "appEUI": "app",
    //     "appKEY": "179992828D6D738AE3D75742CDF8E2D1",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 1092,
    //     "latestFrameCounterFcnt": 1094,
    //     "lostFcnt": 344,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4222,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC52",
    //     "description": "Mare de deu dels desamparats, 11",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046131,
    //     "longitude": -0.468811,
    //     "deviceEUI": "90dffb81872af87d",
    //     "appEUI": "app",
    //     "appKEY": "E682C85EF31CFF9405ACBF27DFC5AD81",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 1004,
    //     "latestFrameCounterFcnt": 1005,
    //     "lostFcnt": 367,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4223,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC53",
    //     "description": "Mare de deu dels desamparats, 4",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046131,
    //     "longitude": -0.468811,
    //     "deviceEUI": "90dffb81872af88d",
    //     "appEUI": "app",
    //     "appKEY": "37899376114B71C91F0907415CAD4020",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 1032,
    //     "latestFrameCounterFcnt": 1033,
    //     "lostFcnt": 360,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4224,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC54",
    //     "description": "Plaza Joan XXIII, 12",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045558,
    //     "longitude": -0.46832,
    //     "deviceEUI": "90dffb81872af889",
    //     "appEUI": "app",
    //     "appKEY": "7DBA52B63664E590CC0E749E8620B7E9",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 1011,
    //     "latestFrameCounterFcnt": 1012,
    //     "lostFcnt": 305,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4225,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC55",
    //     "description": "Plaza Joan XXIII, 13",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045558,
    //     "longitude": -0.46832,
    //     "deviceEUI": "90dffb81872af891",
    //     "appEUI": "app",
    //     "appKEY": "855113C996C41BA77916FAC20157A8C3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 1033,
    //     "latestFrameCounterFcnt": 1034,
    //     "lostFcnt": 320,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4226,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC56",
    //     "description": "Plaza Joan XXIII, 14",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045558,
    //     "longitude": -0.46832,
    //     "deviceEUI": "90dffb81872af88b",
    //     "appEUI": "app",
    //     "appKEY": "2B78280828812C6A5CFFC2C5C7667081",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 981,
    //     "latestFrameCounterFcnt": 984,
    //     "lostFcnt": 383,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4227,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC57",
    //     "description": "Plaza Joan XXIII, 15 pta 1",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045951,
    //     "longitude": -0.46807,
    //     "deviceEUI": "90dffb81872af890",
    //     "appEUI": "app",
    //     "appKEY": "BFA3943C486FB09BD084B02B1ADE33F8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 990,
    //     "latestFrameCounterFcnt": 991,
    //     "lostFcnt": 491,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4228,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC58",
    //     "description": "Plaza Joan XXIII, 16",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045951,
    //     "longitude": -0.46807,
    //     "deviceEUI": "90dffb81872af88c",
    //     "appEUI": "app",
    //     "appKEY": "55EE499687C10EC7A4E000AE0604D5F8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 992,
    //     "latestFrameCounterFcnt": 993,
    //     "lostFcnt": 326,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4229,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC59",
    //     "description": "Plaza Joan XXIII, 6",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045595,
    //     "longitude": -0.467813,
    //     "deviceEUI": "90dffb81872af88e",
    //     "appEUI": "app",
    //     "appKEY": "7844B71AE1CDED00164CA3FED28A447F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 944,
    //     "latestFrameCounterFcnt": 949,
    //     "lostFcnt": 555,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4230,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC60",
    //     "description": "Sant Josep, 22 ",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045477,
    //     "longitude": -0.469877,
    //     "deviceEUI": "90dffb81872af88f",
    //     "appEUI": "app",
    //     "appKEY": "68F14B2BA72C22E2BEBEFAB0F2025A0E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 1004,
    //     "latestFrameCounterFcnt": 1005,
    //     "lostFcnt": 347,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4231,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC61",
    //     "description": "Tirant Lo Blanc, 7",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.04578,
    //     "longitude": -0.47001,
    //     "deviceEUI": "90dffb81872af88a",
    //     "appEUI": "app",
    //     "appKEY": "FB87D50D2E5383CDAE39003A6B6A8CED",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 987,
    //     "latestFrameCounterFcnt": 988,
    //     "lostFcnt": 469,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4232,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC62",
    //     "description": "Tirant Lo Blanc, 5",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.04578,
    //     "longitude": -0.47001,
    //     "deviceEUI": "90dffb81872ae82c",
    //     "appEUI": "app",
    //     "appKEY": "3CFDF61F5372326462931325525C826D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 1001,
    //     "latestFrameCounterFcnt": 1002,
    //     "lostFcnt": 324,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4233,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC63",
    //     "description": "Sant Josep, 21",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045576,
    //     "longitude": -0.469424,
    //     "deviceEUI": "90dffb81872ae82e",
    //     "appEUI": "app",
    //     "appKEY": "37C9F149E44226F894F65A49B15BA9C5",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 1009,
    //     "latestFrameCounterFcnt": 1010,
    //     "lostFcnt": 340,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4234,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC64",
    //     "description": "Sant Josep, 15",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.04571,
    //     "longitude": -0.469379,
    //     "deviceEUI": "90dffb81872ae82a",
    //     "appEUI": "app",
    //     "appKEY": "EE034F426D5F45790C557B4ECBBAD42B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 968,
    //     "latestFrameCounterFcnt": 969,
    //     "lostFcnt": 468,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4235,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC65",
    //     "description": "Sant Josep, 19",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045576,
    //     "longitude": -0.469424,
    //     "deviceEUI": "90dffb81872ae831",
    //     "appEUI": "app",
    //     "appKEY": "06BBE1CDD1DBFD32CFD8BFA82CC19709",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 988,
    //     "latestFrameCounterFcnt": 989,
    //     "lostFcnt": 541,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4236,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC66",
    //     "description": "Sant Josep, 17",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045576,
    //     "longitude": -0.469424,
    //     "deviceEUI": "90dffb81872ae82b",
    //     "appEUI": "app",
    //     "appKEY": "A5B5BF2B798693633BE6EBE020C6CEAB",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 938,
    //     "latestFrameCounterFcnt": 940,
    //     "lostFcnt": 584,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4237,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC67",
    //     "description": "Sant Josep, 16",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045576,
    //     "longitude": -0.469424,
    //     "deviceEUI": "90dffb81872ae833",
    //     "appEUI": "app",
    //     "appKEY": "D0B83FBD743204FDC919D130DB8C4AB3",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 18,
    //     "prevFrameCounterFcnt": 996,
    //     "latestFrameCounterFcnt": 998,
    //     "lostFcnt": 412,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4238,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC68",
    //     "description": "Sant Josep, 13",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045762,
    //     "longitude": -0.469278,
    //     "deviceEUI": "90dffb81872ae830",
    //     "appEUI": "app",
    //     "appKEY": "F8686746D11CCC330D745B489B9EACAD",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 12,
    //     "prevFrameCounterFcnt": 1009,
    //     "latestFrameCounterFcnt": 1010,
    //     "lostFcnt": 399,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4239,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC69",
    //     "description": "Sant Josep, 14",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045762,
    //     "longitude": -0.469278,
    //     "deviceEUI": "90dffb81872ae828",
    //     "appEUI": "app",
    //     "appKEY": "B4F7D746B41037303739F015FBB6B3A8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 950,
    //     "latestFrameCounterFcnt": 953,
    //     "lostFcnt": 600,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4240,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC70",
    //     "description": "Sant Josep, 12",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045762,
    //     "longitude": -0.469278,
    //     "deviceEUI": "90dffb81872ae827",
    //     "appEUI": "app",
    //     "appKEY": "2FA559397DA4650CD9C746698B87F349",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 20,
    //     "prevFrameCounterFcnt": 1067,
    //     "latestFrameCounterFcnt": 1069,
    //     "lostFcnt": 342,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4241,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC71",
    //     "description": "Sant Josep, 9",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045881,
    //     "longitude": -0.469151,
    //     "deviceEUI": "90dffb81872ae82d",
    //     "appEUI": "app",
    //     "appKEY": "48EDB45DF44909CE0305B4997DECEFE2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 946,
    //     "latestFrameCounterFcnt": 947,
    //     "lostFcnt": 532,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4242,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC72",
    //     "description": "Sant Josep, 7",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045881,
    //     "longitude": -0.469151,
    //     "deviceEUI": "90dffb81872ae825",
    //     "appEUI": "app",
    //     "appKEY": "BF6128ED2BAEABD6E183867F30DB6623",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 822,
    //     "latestFrameCounterFcnt": 823,
    //     "lostFcnt": 412,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4243,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC73",
    //     "description": "Sant Josep, 8",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045881,
    //     "longitude": -0.469151,
    //     "deviceEUI": "90dffb81872ae81f",
    //     "appEUI": "app",
    //     "appKEY": "5F8981668BB4F77AC25D4B8AEFD1AFE6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 133,
    //     "latestFrameCounterFcnt": 136,
    //     "lostFcnt": -213,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4244,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC74",
    //     "description": "Sant Josep, 6",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045881,
    //     "longitude": -0.469151,
    //     "deviceEUI": "90dffb81872ae823",
    //     "appEUI": "app",
    //     "appKEY": "1FCAA75AC0BA99BCCBA8BB7879C656A8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 999,
    //     "latestFrameCounterFcnt": 1000,
    //     "lostFcnt": 420,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4245,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC75",
    //     "description": "Sant Josep, 3",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045947,
    //     "longitude": -0.468971,
    //     "deviceEUI": "90dffb81872ae822",
    //     "appEUI": "app",
    //     "appKEY": "2BEFBE1CB7422DDA76EF7CE1067FDFF4",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 13,
    //     "prevFrameCounterFcnt": 943,
    //     "latestFrameCounterFcnt": 944,
    //     "lostFcnt": 417,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4246,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC76",
    //     "description": "Mare de Déu dels Desamparants, 20",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046386,
    //     "longitude": -0.469181,
    //     "deviceEUI": "90dffb81872ae81d",
    //     "appEUI": "app",
    //     "appKEY": "61039B6D6FFBECCB6CA2A201D4ABF71B",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 972,
    //     "latestFrameCounterFcnt": 974,
    //     "lostFcnt": 439,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4247,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC77",
    //     "description": "Mare de Déu dels Desamparants, 22",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046386,
    //     "longitude": -0.469181,
    //     "deviceEUI": "90dffb81872ae81e",
    //     "appEUI": "app",
    //     "appKEY": "25AC4E41EAAC13A51DE4899A061A42B6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 1039,
    //     "latestFrameCounterFcnt": 1040,
    //     "lostFcnt": 374,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4248,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC78",
    //     "description": "Plaza Sant Joan XXIII 1",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046186,
    //     "longitude": -0.46754,
    //     "deviceEUI": "90dffb81872ae824",
    //     "appEUI": "app",
    //     "appKEY": "0F26C37B5A5B40BD8B569B7BB234C4DC",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 1021,
    //     "latestFrameCounterFcnt": 1022,
    //     "lostFcnt": 317,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4249,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC79",
    //     "description": "C/ Cardenal Tarancón, 2 ",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046121,
    //     "longitude": -0.467754,
    //     "deviceEUI": "90dffb81872b308e",
    //     "appEUI": "app",
    //     "appKEY": "670B29C785663BA49C62D0564A4CDA97",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 1001,
    //     "latestFrameCounterFcnt": 1002,
    //     "lostFcnt": 348,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4250,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC80",
    //     "description": "C/ Cardenal Tarancón, 10",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046301,
    //     "longitude": -0.468101,
    //     "deviceEUI": "90dffb81872af8aa",
    //     "appEUI": "app",
    //     "appKEY": "E1E53BE0301FAA0F41C38E0D5741368E",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 1016,
    //     "latestFrameCounterFcnt": 1017,
    //     "lostFcnt": 340,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4251,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC81",
    //     "description": "C/ Cardenal Tarancón, 5",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046121,
    //     "longitude": -0.4682,
    //     "deviceEUI": "90dffb81872af8a0",
    //     "appEUI": "app",
    //     "appKEY": "64E4AE97CA7E9CB5DE5D6EDF8E83B7DF",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 18,
    //     "prevFrameCounterFcnt": 1000,
    //     "latestFrameCounterFcnt": 1001,
    //     "lostFcnt": 423,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4252,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC82",
    //     "description": "C/ Cardenal Tarancón, 12",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046303,
    //     "longitude": -0.468101,
    //     "deviceEUI": "90dffb81872b253a",
    //     "appEUI": "app",
    //     "appKEY": "DDE1E839BBDB585B2C3D285AB39D2AF0",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 62,
    //     "latestFrameCounterFcnt": 65,
    //     "lostFcnt": -264,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4253,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC83",
    //     "description": "C/ Cardenal Tarancón, 14",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046365,
    //     "longitude": -0.468264,
    //     "deviceEUI": "90dffb81872af89e",
    //     "appEUI": "app",
    //     "appKEY": "10988979032F94DCF42BBA4316766915",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 1001,
    //     "latestFrameCounterFcnt": 1002,
    //     "lostFcnt": 357,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4254,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC84",
    //     "description": "Carrer la Pau, 11 ",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046102,
    //     "longitude": -0.468443,
    //     "deviceEUI": "90dffb81872b253b",
    //     "appEUI": "app",
    //     "appKEY": "A58ED1993E161DA15CDAA3C57B8EF764",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 885,
    //     "latestFrameCounterFcnt": 886,
    //     "lostFcnt": 147,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4255,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC85",
    //     "description": "Carrer la Pau, 11 pta 1",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046102,
    //     "longitude": -0.468443,
    //     "deviceEUI": "90dffb81872af89f",
    //     "appEUI": "app",
    //     "appKEY": "730C1142C19277A2DBD73AAE55A0B2E6",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 23,
    //     "prevFrameCounterFcnt": 1049,
    //     "latestFrameCounterFcnt": 1050,
    //     "lostFcnt": 323,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4256,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC86",
    //     "description": "C/ Cardenal Tarancón. 11",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046356,
    //     "longitude": -0.468608,
    //     "deviceEUI": "90dffb81872b253c",
    //     "appEUI": "app",
    //     "appKEY": "EB288F34B17A483BE327C731EEF6D885",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 1040,
    //     "latestFrameCounterFcnt": 1041,
    //     "lostFcnt": 318,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4257,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC87",
    //     "description": "C/ Cardenal Tarancón, 13",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046374,
    //     "longitude": -0.468702,
    //     "deviceEUI": "90dffb81872af89d",
    //     "appEUI": "app",
    //     "appKEY": "9E99F384AAB2FA99D344384083BAC3A8",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 1026,
    //     "latestFrameCounterFcnt": 1027,
    //     "lostFcnt": 379,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4260,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC90",
    //     "description": "C/ l'Enova, 5 ",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045021,
    //     "longitude": -0.468066,
    //     "deviceEUI": "90dffb81872ae826",
    //     "appEUI": "app",
    //     "appKEY": "DECD939F729B9C7DFA1E7C3BCEDF95CF",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 18,
    //     "prevFrameCounterFcnt": 1003,
    //     "latestFrameCounterFcnt": 1004,
    //     "lostFcnt": 350,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4261,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC91",
    //     "description": "C/ l'Enova, 5 pta 1",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045021,
    //     "longitude": -0.468066,
    //     "deviceEUI": "90dffb81872ae7af",
    //     "appEUI": "app",
    //     "appKEY": "3351C1D2C747A8BFA40A67A33AA28260",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 1015,
    //     "latestFrameCounterFcnt": 1016,
    //     "lostFcnt": 329,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4262,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC92",
    //     "description": "Plaza Sant Joan XXIII, 9 ",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045473,
    //     "longitude": -0.468169,
    //     "deviceEUI": "90dffb81872af7d5",
    //     "appEUI": "app",
    //     "appKEY": "12AAA7CCE6A79082DE7079A6C6276AFF",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 17,
    //     "prevFrameCounterFcnt": 1022,
    //     "latestFrameCounterFcnt": 1023,
    //     "lostFcnt": 348,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4263,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC93",
    //     "description": "L'Enova, 1 ",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045262,
    //     "longitude": -0.467906,
    //     "deviceEUI": "90dffb81872af7d3",
    //     "appEUI": "app",
    //     "appKEY": "732B0DC86687D526B2215B2D6773979F",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 24,
    //     "prevFrameCounterFcnt": 1000,
    //     "latestFrameCounterFcnt": 1001,
    //     "lostFcnt": 315,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4264,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC94",
    //     "description": "Les Eres, 2 ",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045388,
    //     "longitude": -0.46872,
    //     "deviceEUI": "90dffb81872af7d0",
    //     "appEUI": "app",
    //     "appKEY": "4619C9AA984C752D227B6729F4E3D41D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 15,
    //     "prevFrameCounterFcnt": 1008,
    //     "latestFrameCounterFcnt": 1010,
    //     "lostFcnt": 561,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4265,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC95",
    //     "description": "Les Eres, 4 ",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045403,
    //     "longitude": -0.468622,
    //     "deviceEUI": "90dffb81872ae7bf",
    //     "appEUI": "app",
    //     "appKEY": "657647D488AF397D2803F20F84CF7032",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 956,
    //     "latestFrameCounterFcnt": 957,
    //     "lostFcnt": 547,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4266,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC96",
    //     "description": "Les Eres, 12 ",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045651,
    //     "longitude": -0.469082,
    //     "deviceEUI": "90dffb81872ae7ae",
    //     "appEUI": "app",
    //     "appKEY": "FE8176219CB23E9B55702575385E31A7",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 14,
    //     "prevFrameCounterFcnt": 989,
    //     "latestFrameCounterFcnt": 990,
    //     "lostFcnt": 455,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4267,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC97",
    //     "description": "Plaza Sant Joan XXIII, 10 ",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045528,
    //     "longitude": -0.468228,
    //     "deviceEUI": "90dffb81872b30a8",
    //     "appEUI": "app",
    //     "appKEY": "6101FA20E509DF8A0B67687956E79EF2",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 983,
    //     "latestFrameCounterFcnt": 984,
    //     "lostFcnt": 429,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4268,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC98",
    //     "description": "L'Enova Cuadra",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.045262,
    //     "longitude": -0.467906,
    //     "deviceEUI": "90dffb81872ae7b0",
    //     "appEUI": "app",
    //     "appKEY": "8A05B389E8AF9E96930E4D9BFDD8ACEE",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": [],
    //     "meterComponentNames": [],
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 16,
    //     "prevFrameCounterFcnt": 1037,
    //     "latestFrameCounterFcnt": 1038,
    //     "lostFcnt": 307,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4269,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC99",
    //     "description": "La Pau, 9 ",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.046226,
    //     "longitude": -0.468456,
    //     "deviceEUI": "90dffb81872ae7be",
    //     "appEUI": "app",
    //     "appKEY": "6CBE437ABBF9C467586DFBCAC6077F0D",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 12,
    //     "prevFrameCounterFcnt": 403,
    //     "latestFrameCounterFcnt": 405,
    //     "lostFcnt": 78,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4270,
    //     "gatewaysId": null,
    //     "name": "WaterExternalRAFGWTC100",
    //     "description": "Mare de deu, 7 ",
    //     "provider": null,
    //     "userId": 82,
    //     "typeSensor": null,
    //     "sensorTypeInfo": {
    //         "id": null,
    //         "name": null,
    //         "description": null,
    //         "sensorType": null,
    //         "componentType": null,
    //         "applicationName": null
    //     },
    //     "sensorModelName": "DIEHL",
    //     "installationDate": "2021-12-01",
    //     "applicationName": null,
    //     "latitude": 39.202102,
    //     "longitude": -0.312282,
    //     "deviceEUI": "90dffb81872ae7ad",
    //     "appEUI": "app",
    //     "appKEY": "377EF771FE5BDD8B3CD1C41531FE7482",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "dca63214becf",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 18,
    //     "prevFrameCounterFcnt": 971,
    //     "latestFrameCounterFcnt": 981,
    //     "lostFcnt": 370,
    //     "loraSNR": null,
    //     "networkServerMac": "dca63214becf",
    //     "messageTime": null
    // },
    // {
    //     "id": 4909,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC01",
    //     "description": "1. PMR c/Crist del Consol",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39566083,
    //     "longitude": -0.528944,
    //     "deviceEUI": "474f5350fb050118",
    //     "appEUI": "app",
    //     "appKEY": "57E0619EE9A25D4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 19,
    //     "latestFrameCounterFcnt": 21,
    //     "lostFcnt": -496,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4910,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC02",
    //     "description": "2. PMR c/Benissanó",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39565758,
    //     "longitude": -0.528248,
    //     "deviceEUI": "474f5350fb0500f2",
    //     "appEUI": "app",
    //     "appKEY": "BD151BA3F7AD5A4E9DDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": "4999",
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 881,
    //     "latestFrameCounterFcnt": 882,
    //     "lostFcnt": -8635,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4911,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC03",
    //     "description": "3. PMR c/ Forces armades",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39564900,
    //     "longitude": -0.529277,
    //     "deviceEUI": "474f5350fb0500f8",
    //     "appEUI": "app",
    //     "appKEY": "B71019A2F7AD5A4E9DDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 4,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 1,
    //     "latestFrameCounterFcnt": 2,
    //     "lostFcnt": -4441,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4912,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC04",
    //     "description": "4. PMR c/ Puríssima",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39565990,
    //     "longitude": -0.529887,
    //     "deviceEUI": "474f5350fb050156",
    //     "appEUI": "app",
    //     "appKEY": "19C77297EDA05C4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 18,
    //     "latestFrameCounterFcnt": 0,
    //     "lostFcnt": -414,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4913,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC05",
    //     "description": "5. PMR Cinema d'Estiu",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39564688,
    //     "longitude": -0.533042,
    //     "deviceEUI": "474f5350fb050151",
    //     "appEUI": "app",
    //     "appKEY": "1EC47397EDA05C4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 28,
    //     "latestFrameCounterFcnt": 29,
    //     "lostFcnt": -2492,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4914,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC06",
    //     "description": "6. PMR Centre Sociocultural",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39566289,
    //     "longitude": -0.53416,
    //     "deviceEUI": "474f5350fb0500f5",
    //     "appEUI": "app",
    //     "appKEY": "BA161AA3F7AD5A4E9DDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 5,
    //     "latestFrameCounterFcnt": 9,
    //     "lostFcnt": -1368,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4915,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC07",
    //     "description": "7. PMR Centre Esportiu Mandor 1",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39562431,
    //     "longitude": -0.536089,
    //     "deviceEUI": "474f5350fb05016d",
    //     "appEUI": "app",
    //     "appKEY": "22DA7C90EEA15C4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 1,
    //     "latestFrameCounterFcnt": 2,
    //     "lostFcnt": -719,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4916,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC08",
    //     "description": "8. PMR Centre Esportiu Mandor 2",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39562448,
    //     "longitude": -0.536113,
    //     "deviceEUI": "474f5350fb05016c",
    //     "appEUI": "app",
    //     "appKEY": "23DA7C90EEA15C4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 24,
    //     "latestFrameCounterFcnt": 25,
    //     "lostFcnt": -1740,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4917,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC09",
    //     "description": "9. PMR Centre Esportiu Mandor 3",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39562460,
    //     "longitude": -0.536132,
    //     "deviceEUI": "474f5350fb05016a",
    //     "appEUI": "app",
    //     "appKEY": "25D97D90EEA15C4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 91,
    //     "latestFrameCounterFcnt": 92,
    //     "lostFcnt": -1502,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4918,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC10",
    //     "description": "13. PMR Pàrquing Estació de Metro 3",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39561824,
    //     "longitude": -0.536196,
    //     "deviceEUI": "474f5350fb050152",
    //     "appEUI": "app",
    //     "appKEY": "1DC57397EDA05C4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 1,
    //     "latestFrameCounterFcnt": 2,
    //     "lostFcnt": -1196,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4919,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC11",
    //     "description": "11. PMR Pàrguing estació de Metro 1",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39561363,
    //     "longitude": -0.536343,
    //     "deviceEUI": "474f5350fb05014f",
    //     "appEUI": "app",
    //     "appKEY": "00CB7494ECA05C4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 24,
    //     "latestFrameCounterFcnt": 25,
    //     "lostFcnt": -528,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4920,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC12",
    //     "description": "12. PMR Pàrquing Estació de Metro 2",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39561429,
    //     "longitude": -0.536044,
    //     "deviceEUI": "474f5350fb05014e",
    //     "appEUI": "app",
    //     "appKEY": "01CB7494ECA05C4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 60,
    //     "latestFrameCounterFcnt": 62,
    //     "lostFcnt": -914,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4921,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC13",
    //     "description": "14. PMR c/València (Sant Josep)",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39566600,
    //     "longitude": -0.531764,
    //     "deviceEUI": "474f5350fb050117",
    //     "appEUI": "app",
    //     "appKEY": "58E7629FE9A25D4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 20,
    //     "latestFrameCounterFcnt": 21,
    //     "lostFcnt": -2095,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4922,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC14",
    //     "description": "15. PMR c/Major (forn)",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39566762,
    //     "longitude": -0.530572,
    //     "deviceEUI": "474f5350fb0500f3",
    //     "appEUI": "app",
    //     "appKEY": "BC151BA3F7AD5A4E9DDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 131,
    //     "latestFrameCounterFcnt": 6,
    //     "lostFcnt": -4888,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4923,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC15",
    //     "description": "16. PMR c/General Pastor",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39565077,
    //     "longitude": -0.530399,
    //     "deviceEUI": "474f5350fb050119",
    //     "appEUI": "app",
    //     "appKEY": "56E0619EE9A25D4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 82,
    //     "latestFrameCounterFcnt": 83,
    //     "lostFcnt": -10782,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4924,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC16",
    //     "description": "17. PMR c/ General Pastor (València)",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39564357,
    //     "longitude": -0.531252,
    //     "deviceEUI": "474f5350fb050155",
    //     "appEUI": "app",
    //     "appKEY": "1AC67297EDA05C4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 0,
    //     "latestFrameCounterFcnt": 0,
    //     "lostFcnt": -98,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4925,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC17",
    //     "description": "18. PMR c/Francisco Alcaide",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39564011,
    //     "longitude": -0.530964,
    //     "deviceEUI": "474f5350fb05013b",
    //     "appEUI": "app",
    //     "appKEY": "74F1699AEBA35D4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 4926,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC18",
    //     "description": "19. PMR c/ Olocau",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39564057,
    //     "longitude": -0.530394,
    //     "deviceEUI": "474f5350fb050122",
    //     "appEUI": "app",
    //     "appKEY": "6DFD6F99EAA35D4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 4927,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC19",
    //     "description": "20. PMR c/ València (Molí)",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39566095,
    //     "longitude": -0.5317,
    //     "deviceEUI": "474f5350fb0500f4",
    //     "appEUI": "app",
    //     "appKEY": "BB161AA3F7AD5A4E9DDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 5,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 3183,
    //     "latestFrameCounterFcnt": 3184,
    //     "lostFcnt": -3688,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4928,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC20",
    //     "description": "21. PMR Av. Corts Valencianes (gerres) 1",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39564776,
    //     "longitude": -0.527465,
    //     "deviceEUI": "474f5350fb05016b",
    //     "appEUI": "app",
    //     "appKEY": "24D97D90EEA15C4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 66,
    //     "latestFrameCounterFcnt": 67,
    //     "lostFcnt": -1473,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4929,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC21",
    //     "description": "22. PMR Av. Corts Valencianes (gerres) 2",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39562397,
    //     "longitude": -0.531772,
    //     "deviceEUI": "474f5350fb050154",
    //     "appEUI": "app",
    //     "appKEY": "1BC67297EDA05C4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 16,
    //     "latestFrameCounterFcnt": 17,
    //     "lostFcnt": -4725,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4930,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC22",
    //     "description": "23. PMR c/Begonya",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39562589,
    //     "longitude": -0.532209,
    //     "deviceEUI": "474f5350fb05013a",
    //     "appEUI": "app",
    //     "appKEY": "75F1699AEBA35D4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 17,
    //     "latestFrameCounterFcnt": 18,
    //     "lostFcnt": -1892,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4931,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC23",
    //     "description": "24. PMR Centre de Salut 1",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39562774,
    //     "longitude": -0.534151,
    //     "deviceEUI": "474f5350fb05014d",
    //     "appEUI": "app",
    //     "appKEY": "02CA7494ECA05C4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 2,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 287,
    //     "latestFrameCounterFcnt": 288,
    //     "lostFcnt": -10771,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4932,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC24",
    //     "description": "25. PMR Centre de Salut 2",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39562713,
    //     "longitude": -0.534123,
    //     "deviceEUI": "474f5350fb050153",
    //     "appEUI": "app",
    //     "appKEY": "1CC57397EDA05C4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 3,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 213,
    //     "latestFrameCounterFcnt": 214,
    //     "lostFcnt": -6345,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4933,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC25",
    //     "description": "26. PMR c/Verge del Pilar",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39567026,
    //     "longitude": -0.533838,
    //     "deviceEUI": "474f5350fb050113",
    //     "appEUI": "app",
    //     "appKEY": "5CE5639FE9A25D4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 1,
    //     "prevFrameCounterFcnt": 8,
    //     "latestFrameCounterFcnt": 11,
    //     "lostFcnt": -166,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4934,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC26",
    //     "description": "27. PMR c/ Sant Josep",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39566598,
    //     "longitude": -0.531734,
    //     "deviceEUI": "474f5350fb050120",
    //     "appEUI": "app",
    //     "appKEY": "6FFC6F99EAA35D4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 2,
    //     "latestFrameCounterFcnt": 3,
    //     "lostFcnt": -1271,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4935,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC27",
    //     "description": "28. PMR c/Félix Rodríguez de la Fuente 1",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39566818,
    //     "longitude": -0.532171,
    //     "deviceEUI": "474f5350fb050137",
    //     "appEUI": "app",
    //     "appKEY": "78F76A9BEBA35D4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 50,
    //     "latestFrameCounterFcnt": 51,
    //     "lostFcnt": -2214,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4936,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC28",
    //     "description": "29. PMR c/ Félix Rodríguez de la Fuente 2",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39566826,
    //     "longitude": -0.532185,
    //     "deviceEUI": "474f5350fb05013d",
    //     "appEUI": "app",
    //     "appKEY": "72F2689AEBA35D4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 3,
    //     "latestFrameCounterFcnt": 9,
    //     "lostFcnt": -983,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4937,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC29",
    //     "description": "30. PMR c/Ricardo Llopis (Cambiar por: C/ Virgen del Pilar, 36)",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 395670700,
    //     "longitude": -0.533727,
    //     "deviceEUI": "474f5350fb05013c",
    //     "appEUI": "app",
    //     "appKEY": "73F2689AEBA35D4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 4938,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC30",
    //     "description": "31. PMR c/Major (Corts Valencianes)",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39564421,
    //     "longitude": -0.529232,
    //     "deviceEUI": "474f5350fb050171",
    //     "appEUI": "app",
    //     "appKEY": "3ED47B93EFA15C4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": null,
    //     "rssi": null,
    //     "frequency": null,
    //     "firstFrameCounterFcnt": null,
    //     "prevFrameCounterFcnt": null,
    //     "latestFrameCounterFcnt": null,
    //     "lostFcnt": null,
    //     "loraSNR": null,
    //     "networkServerMac": null,
    //     "messageTime": null
    // },
    // {
    //     "id": 4939,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC31",
    //     "description": "32. PMR c/Ramón Giner",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39567392,
    //     "longitude": -0.52802,
    //     "deviceEUI": "474f5350fb05014a",
    //     "appEUI": "app",
    //     "appKEY": "05C97594ECA05C4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 1,
    //     "rssi": null,
    //     "frequency": 868100000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 8,
    //     "latestFrameCounterFcnt": 10,
    //     "lostFcnt": -1696,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4940,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC32",
    //     "description": "33. PMR Av. Constitució",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39568677,
    //     "longitude": -0.528399,
    //     "deviceEUI": "474f5350fb050145",
    //     "appEUI": "app",
    //     "appKEY": "0ACE7695ECA05C4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868300000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 24,
    //     "latestFrameCounterFcnt": 25,
    //     "lostFcnt": -94,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null
    // },
    // {
    //     "id": 4941,
    //     "gatewaysId": null,
    //     "name": "ParkingSpotLEACTMC33",
    //     "description": "34. PMR c/Secundino Blat",
    //     "provider": null,
    //     "userId": 83,
    //     "typeSensor": "74",
    //     "sensorTypeInfo": {
    //         "id": 74,
    //         "name": "FLEXIMODO_PARK",
    //         "description": null,
    //         "sensorType": "customsensor",
    //         "componentType": null,
    //         "applicationName": "app"
    //     },
    //     "sensorModelName": "FLEXIMODO_PARK",
    //     "installationDate": "2021-12-09",
    //     "applicationName": null,
    //     "latitude": 39568385,
    //     "longitude": -0.529045,
    //     "deviceEUI": "474f5350fb050121",
    //     "appEUI": "app",
    //     "appKEY": "6EFC6F99EAA35D4D9CDE2044EE1A7A91",
    //     "encryptionKey": null,
    //     "sensorFromPort": null,
    //     "sensorFromChirpstack": "chirpstack",
    //     "inputComponentNames": null,
    //     "meterComponentNames": null,
    //     "decoderServers": "b827ebe1f9fd",
    //     "dr": 0,
    //     "rssi": null,
    //     "frequency": 868500000,
    //     "firstFrameCounterFcnt": 0,
    //     "prevFrameCounterFcnt": 29,
    //     "latestFrameCounterFcnt": 30,
    //     "lostFcnt": -625,
    //     "loraSNR": null,
    //     "networkServerMac": "b827ebe1f9fd",
    //     "messageTime": null,
    // }
    //     ]
    ]