import React, { useState,useEffect } from 'react';
import {  Button, Row, Col,} from "antd";
import {Checkbox} from "antd";
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'

import { useTranslation } from "react-i18next";
import { Table} from "antd"
import dayjs from 'dayjs';
import { useAuth } from "../../../Hooks/useAuth";
import { ENV } from '../../../utils';
import { ModalAlarms } from './ModalAlarms/ModalAlarms';
import { theme } from "antd";

const { useToken } = theme

function Alarms({url=`${ENV.Alarms_API}alarms`, hardware}) {
  const { accessToken } = useAuth()
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [alarmsData, setAlarmsData] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [alarmEntity, setAlarmEntity] = useState();
  const onChangeStep = (value) => {setCurrentStep(value);};
  const { token } = useToken()

  const [hardwareSelectedKeys, setHardwareSelectedKeys] = useState({});
  const refreshData = () => {
    fetch(`${url}?type=${hardware.join(",")}`,{method: "GET",headers: {"x-access-token":accessToken }})
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error al realizar la solicitud: ${response.status}`);
      }
      return response.json()
    })
    .then((data) => {
      setAlarmsData(data.result)
    })
    .catch((error) => {
      console.error('Error:', error);
    })
    let tmp = {}
    hardware.map((item)=> tmp[item] = [] )
    setHardwareSelectedKeys(tmp)
  }
  const deleteAlarm = async (id) => {
    fetch(`${ENV.Alarms_API}alarms/${id}`, {
      method: "DELETE",
      headers: {"x-access-token": accessToken}
    }).then((response) => {
      if (!response.ok) {
        throw new Error(`Error al realizar la solicitud: ${response.status}`);
      }
      refreshData()
    }).catch((error) => {
      console.error('Error:', error);
    })
  }
  const sendData = async({tmp_alarm}) => {
    const alarm = tmp_alarm? tmp_alarm : {...alarmEntity};
    hardware.map((item)=> alarm[item] = hardwareSelectedKeys[item])
    let url = alarm.id ? `${ENV.Alarms_API}alarms/${alarm.id}` : `${ENV.Alarms_API}alarms`
    let method = alarm.id ? "PUT" : "POST"
    fetch(url, {
      method: method,
      headers: {"x-access-token": accessToken,"Content-Type": "application/json"},
      body: JSON.stringify(alarm)
    }).then((response) => {
      if (!response.ok) {
        throw new Error(`Error al realizar la solicitud: ${response.status}`);
      }
      return response.json()
    }).then((data) => {
      refreshData()
      setModalOpen(false)
    }).catch((error) => {
      console.error('Error:', error);
    })
    
  }
  const ModalUpdate = async(alarm) => {
    setAlarmEntity({...alarm, start_date:dayjs(alarm.start_date)});
    let tmp = {}
    hardware.map( (item) => tmp[item] = alarm[item])
    setHardwareSelectedKeys(tmp)
    setCurrentStep(0)
    setModalOpen(true)
  }
  const ModalCreate = async() => {
    setAlarmEntity({ 
        id:undefined,
        name:"",
        description:"",
        deactive_hours:3,
        start_date:dayjs(),
        enable:true,
        emails:[],
        email_subject:"",
        email_body:"",
        alarm_status:"GENERATED",
        gateways:[],
        devices:[],
        stas:[],
        alarmType:"connection",
        aforoType:"WiFi",
        aforoValue:1,
      });
    let tmp = {}
    hardware.map((item)=> tmp[item] = [] )
    setHardwareSelectedKeys(tmp)
    setCurrentStep(0)
    setModalOpen(true)
  }
  useEffect(() => {
    refreshData()
  }, [])
  return(
    <>
      {/*MODAL*/}
      <ModalAlarms
        sendData={sendData}
        setModalOpen={setModalOpen} modalOpen={modalOpen}
        currentStep={currentStep} onChangeStep={onChangeStep}
        alarmEntity={alarmEntity} setAlarmEntity={setAlarmEntity}
        hardware={hardware}
        hardwareSelectedKeys={hardwareSelectedKeys} setHardwareSelectedKeys={setHardwareSelectedKeys}
      />


      <Row align="middle" justify="space-between">
        <Col></Col>
        <Col  style= {{padding:"1rem"}}>
            <Button type="primary"  className="buttonCentered" icon={<PlusOutlined />} onClick={ModalCreate} />
        </Col>
      </Row>
      <Table
        dataSource={alarmsData} 
        rowKey="id"
      >
        <Table.Column title={t("Name")} dataIndex="name" key="name" width={"100px"}/>
        <Table.Column title={t("Description")} dataIndex="description" key="description" width={"300px"} />

        <Table.Column title={t("Start_date")} dataIndex="start_date" key="date" width={"100px"}/>
        <Table.Column title={t("Deactive hours")} dataIndex="deactive_hours" key="time" width={"20px"}/>
        <Table.Column title={t("Emails")} 
          render={(text, record) => {
            return JSON.parse(record.emails)
          }}
        />
        <Table.Column 
          title={t("Enable")} 
          dataIndex="enable" key="time"  width={"40px"}
          render={(text, record) => {
            return <Checkbox
              checked={record.enable} 
              onClick={async (value)=>{
                sendData({tmp_alarm:{...record, enable:!record.enable, start_date:dayjs(record.start_date)}})
              }}
              /> 
          }}
          />
        <Table.Column
          title={t("Actions")}
          key="action"
          render={(text, record) => (
            <>
              <EditOutlined style={{ "fontSize": "large", "color": token.colorPrimary }} onClick={()=>{ModalUpdate(record)}} />
              <DeleteOutlined  onClick={()=>{deleteAlarm(record.id)} }style={{ "fontSize": "large", "color": token.colorError }} />
            </>
          )}
        />
      </Table>
    </>
  )
}
export default Alarms
