import { Col, DatePicker, Flex, Input, InputNumber, Modal, Row, Steps, Switch } from "antd"
import { TableHardware } from "./TableHardware/TableHardware"
import { ENV } from "../../../../utils"
import { useTranslation } from "react-i18next";
import { AlarmSetting } from "./AlarmSetting/AlarmSetting";


export function ModalAlarms(
    {
        sendData,
        setModalOpen, modalOpen, 
        currentStep, onChangeStep, 
        alarmEntity, setAlarmEntity, 
        hardwareSelectedKeys,setHardwareSelectedKeys,
        hardware
    }) 
    {
    const { t } = useTranslation();
    let stepItems = [{title: 'Set Alarm'}]
    hardware.map((item)=>{stepItems.push({title: item.charAt(0).toUpperCase() + item.slice(1) })})
    return(
        <Modal width={"800px"}  open={modalOpen} onOk={()=>(sendData({}))} onCancel={()=>{setModalOpen(false)}}>
        <Steps current={currentStep} onChange={onChangeStep} style={{padding:"20px 10px"}}
          items={stepItems}
        />


        <div hidden={currentStep!=0}>
            <AlarmSetting alarmEntity={alarmEntity} setAlarmEntity={setAlarmEntity}/>
        </div>


        { hardware.map((item,index)=>{

          let urlDataSource=
                      item.toLowerCase()==="stas"?
                      `${ENV.Sta_API}sta/ids`:
                      `${ENV.Infraestructure_API}/${item.toLowerCase()}Ids`;
                return(
                    <div hidden={currentStep!=index+1} key={index}>
                    <TableHardware
                      columnTitle={"hardwareId"}
                      variableName={item }
                      urlDataSource={urlDataSource}
                      selectedKeys={hardwareSelectedKeys}
                      setSelectedKeys={setHardwareSelectedKeys}
                    />
                  </div>
                )
            })
        }

      </Modal>

    )
}
