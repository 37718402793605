// --------------------------------------------
// Properties
// key: identifier
// name: shown in columns and edit modals
// filter: filter type in column
// type: input type when editing
// editable: can be created and edited
// default: shown first
// --------------------------------------------

import CountryStateSelector from "../components/UpdateItemForm/CountryStateSelector.component"
import TextNotEditable from "../components/UpdateItemForm/TextNotEditable.component"
import TextInput from "../components/UpdateItemForm/TextInput.component"
import PasswordInput from "../components/UpdateItemForm/PasswordInput.component"
import PasswordInputMqtt from "../components/UpdateItemForm/PasswordInputMqtt.component"
import UserSelector from "../components/UpdateItemForm/UserSelector.component"
import BooleanInput from "../components/UpdateItemForm/BooleanInput.component"
import NumberInput from "../components/UpdateItemForm/NumberInput.component"
import FloatInput from "../components/UpdateItemForm/FloatInput.component"
import CouncilSelector from "../components/UpdateItemForm/CouncilSelector.component"
//import BusinessSelector from "../components/UpdateItemForm/BusinessSelector.component"
import EnumSelector from "../components/UpdateItemForm/EnumSelector.component"
import EnumSelectorNetworkServer from "../components/UpdateItemForm/EnumSelectorNetworkServer.component"
import DateInput from "../components/UpdateItemForm/DateInput.component"
import RoleSelector from "../components/UpdateItemForm/RoleSelector.component"
import UsersSelector from "../components/UpdateItemForm/UsersSelector.component"
import PhoneInput from "../components/UpdateItemForm/PhoneInput.component"
import EmbebedNetServer from "../components/UpdateItemForm/EmbebedNetServer.component"
import ServiceProfileSelector from "../components/UpdateItemForm/ServiceProfileSelector.component"
import OrganizationSelector from "../components/UpdateItemForm/OrganizationSelector.component"
import ApplicationSelector from "../components/UpdateItemForm/ApplicationSelector.component"
import DeviceProfileSelector from "../components/UpdateItemForm/DeviceProfileSelector.component"
import TextInputJSON from "../components/UpdateItemForm/TextInputJSON.component"
import PhisicalGatewaysTableSelector from "../components/UpdateItemForm/PhisicalGatewaySelector.component"
import AutoGeneratedUUID from "../components/UpdateItemForm/AutoGeneratedUUID.component"
import BooleanInputFalseDefault from "../components/UpdateItemForm/BooleanInputFalseDefault.component"
export const loraWanNetworkTable = {
    attributes: [
        { key: "name", name: "Name", filter: "name", formItem: TextInput, default: true },
        { key: "description", name: "Description", filter: "text", formItem: TextInput, default: true },
        { key: "type", name: "Type", filter: "name", formItem: EnumSelector, default: true },
        { key: "networkType", name: "Network Type", filter: "name", formItem: EnumSelectorNetworkServer, default: true, child:[
            { key: "url", name: "URL", filter: "none", formItem: TextInput, default: true },
            { key: "gateway", name: "Gateway", filter: "none", formItem: TextInput, default: true } 
        ]
        },    
        { key: "actions", name: "Actions", actions: ["edit", "remove"], default: true }
    ]
}


export const networkServerTable = {
    attributes: [
        { key: "mqtt", name: "MQTT SERVER", formItem: EmbebedNetServer,default: false},
        { key: "centralized", name: "Is Centralied?", filter: "name", formItem: BooleanInput, default: true },
        { key: "gatewayMac", name: "NS Identifier", filter: "name", formItem: PhisicalGatewaysTableSelector, default: true },
        // { key: "name", name: "Name", filter: "name", formItem: TextInput, default: true },
        // { key: "server", name: "server", filter: "text", formItem: TextInput, default: true },
        // { key: "caCert", name: "caCert", filter: "text", formItem: TextInput, default: false , required: false},
        // { key: "gatewayDiscoveryEnabled", name: "Discovery Enable", filter: "number", formItem: BooleanInput, default: false, required: false },
        // { key: "gatewayDiscoveryDR", name: "Discovery DR", filter: "number", formItem: NumberInput , required: false},
        // { key: "gatewayDiscoveryInterval", name: "Discovery Interval", filter: "number", formItem: NumberInput, default: false, required: false },
        // { key: "gatewayDiscoveryTXFrequency", name: "Discovery Freq.", filter: "number", formItem: NumberInput, default: false, required: false },
        // { key: "routingProfileCACert", name: "Ca Cert Routing", filter: "text", formItem: TextInput, default: false, required: false },
        // { key: "routingProfileTLSCert", name: "Routing TLS Cert", filter: "text", formItem: TextInput, default: false, required: false },
        // { key: "routingProfileTLSKey", name: "Routing TLS Key", filter: "text", formItem: TextInput, default: false, required: false },
        // { key: "tlsCert", name: "tls Cert", filter: "text", formItem: TextInput, default: false, required: false },
        // { key: "tlsKey", name: "tls Key", filter: "text", formItem: TextInput, default: false, required: false },
        { key: "actions", name: "Actions", actions: ["edit", "remove"], default: true }
    ]
}

export const mqttServer = {
    attributes: [
        { key: "profileName", name: "profileName", filter: "name", formItem: TextInput, default: true },
        { key: "description", name: "Description", filter: "text", formItem: TextInput, default: true },
        { key: "url", name: "url", filter: "text", formItem: TextInput, default: true },
        { key: "port", name: "port", filter: "none", formItem: NumberInput, default: true },
        { key: "user", name: "user", filter: "none", formItem: TextInput, default: true },
        { key: "pass", name: "pass", filter: "none", formItem: PasswordInputMqtt, default: false },
        { key: "ssl_tls", name: "ssl_tls", filter: "none", formItem: BooleanInput, default: false,required: false },
        { key: "CA_signed_server_certificate", name: "Signed by server", filter: "none", formItem: BooleanInput, default: false,required: false },
        { key: "certificate", name: "Certificate", filter: "none", formItem: TextInput, default: false,required: false },
        { key: "actions", name: "Actions", actions: ["edit", "remove"], default: true }
    ]
}

export const decoderTable = {
    attributes: [
        { key: "name", name: "Name", filter: "name", formItem: TextInput, default: true },
        { key: "description", name: "Description", formItem: TextInput, default: true },
        { key: "decoderType", name: "Type", formItem: EnumSelector, default: true },
        { key: "actions", name: "Actions", actions: ["edit", "remove"], default: true }
    ]
}

export const userTable = {
    attributes: [
        { key: "username", name: "Username", required: true, formItem: TextInput, default: true, filter: "text" },
        { key: "name", name: "Name", required: true, formItem: TextInput, default: true, filter: "text" },
        { key: "surname", name: "Surname", required: false, formItem: TextInput, default: true, filter: "text" },
        { key: "country", name: "Country", type: "select", formItem: CountryStateSelector, filter: "text" },
        { key: "state", name: "State", type: "select", filter: "text" },
        { key: "city", name: "City", type: "select", formItem: TextInput, filter: "text" },
        { key: "postalCode", name: "Postal Code", formItem: TextInput, default: true, filter: "text" },
        { key: "roleName", name: "Role", type: "select", formItem: RoleSelector, filter: "name" },
        // { key: "councilsId", name: "Council", type: "select", formItem: CouncilSelector, filter: "text" },
        // { key: "businessId", name: "Business", type: "select", formItem: BusinessSelector, filter: "text" },
        { key: "phone", name: "Phone", formItem: PhoneInput, filter: "text" },
        { key: "email", name: "Email", required: true, formItem: TextInput, default: true, filter: "text" },
        { key: "status", name: "Status", default: true, filter: "name" },
        { key: "pswd", name: "Password", required: true, formItem: PasswordInput },
        { key: "actions", name: "Actions", actions: ["edit", "remove"], default: true }
    ]
}

export const gatewaysTable = {
    attributes: [
        { key: "lastSeen", name: "status", filter: "text", width:"150px",default: true },
        { key: "lastSeenDate", name: "lastSeen", filter: "text" },
        { key: "id", name: "ID", default: true },
        { key: "name", name: "Name", formItem: TextInput, default: true },
        { key: "description", name: "Description", formItem: TextInput, default: true  },
        { key: "gatewayProfileID", name: "gatewayProfileID", formItem: TextInput, default: true  },
        { key: "tags", name: "tags", formItem: TextInput, default: true },
        { key: "actions", name: "Actions", actions: ["edit"], default: true }
    ]
}
export const phisicalGatewaysTable = {
    attributes: [
        { key: "id", name: "ID", default: false },
        { key: "name", name: "Name", formItem: TextInput, default: true,required:true  },
        { key: "mac", name: "MAC", formItem: TextInput, default: true,required:true  },
        { key: "embedded", name: "Is Embebed?", formItem: BooleanInput,default: false},
        //TODO REFACTOR this component
        { key: "mqttId", name: "MQTT SERVER", formItem: EmbebedNetServer,default: false},
        { key: "description", name: "Description", formItem: TextInput, required:true },
        { key: "latitude", name: "Latitude", formItem: FloatInput, required:true },
        { key: "longitude", name: "Longitude", formItem: FloatInput, required:true },
        { key: "actions", name: "Actions", actions: ["edit", "remove", "networkServer"], default: true }
    ]
}


export const centralizedTable = {
    attributes: [
        { key: "id", name: "ID", default: false },
        { key: "name", name: "Name", formItem: TextInput, default: true,required:true  },
        { key: "mac", name: "CentralID", formItem: AutoGeneratedUUID, default: false,required:true  },
        { key: "embedded", name: "Is Embebed?", formItem: BooleanInputFalseDefault},
        { key: "mqttId", name: "MQTT SERVER", formItem: EmbebedNetServer,default: false},
        { key: "description", name: "Description", formItem: TextInput, required:true,default: true, },
        { key: "latitude", name: "Latitude", formItem: FloatInput, required:true },
        { key: "longitude", name: "Longitude", formItem: FloatInput, required:true },
        { key: "actions", name: "Actions", actions: ["edit", "remove", "networkServer"], default: true }
    ]
}

export const sensorTypesTable = {
    attributes: [
        { key: "name", name: "Name", formItem: TextInput, default: true },
        { key: "type", name: "Type", formItem: TextInput, default: true },
        { key: "description", name: "Description", formItem: TextInput, default: true },
        { key: "actions", name: "Actions", actions: ["edit", "remove"], default: true }
    ]
}
// Device
export const sensorsTable = {
    attributes: [
        { key: "lastSeen", name: "status", filter: "text", width:"150px",default: true },
        { key: "lastSeenDate", name: "lastSeen", filter: "text" },
        { key: "applicationID", name: "app_ID", filter: "text", formItem: ApplicationSelector, width:"100px",default: true },
        { key: "name", name: "Name", filter: "text", formItem: TextInput, default: true, width:"260px" },
        { key: "description", name: "description", filter: "text", formItem: TextInput, default: true },
        { key: "devEUI", name: "Dev EUI", filter: "text", formItem: TextInput, default: true },
        { key: "type", name: "Type", filter: "text", formItem: TextInput, default: true },
        { key: "isDisabled", name: "isDisabled", filter: "boolean", formItem: BooleanInput, default: false,required:false },
        { key: "referenceAltitude", name: "referenceAltitude", filter: "number", formItem: NumberInput, default: false, required:false },
        { key: "skipFCntCheck", name: "skipFCntCheck", filter: "boolean", formItem: BooleanInput, required:false, default: false },
        { key: "deviceProfileID", name: "deviceProfileID", filter: "text", formItem: DeviceProfileSelector },
        { key: "tags", name: "tags", filter: "text", formItem: TextInputJSON,required:false },
        { key: "variables", name: "variables", filter: "text", formItem: TextInputJSON,required:false },
        { key: "actions", name: "Actions", actions: [ "remove","keys"], default: true }
    ]
}
export const applicationTable = {
    attributes: [
        { key: "id", name: "ID", default: true },
        { key: "name", name: "Name", filter: "text", formItem: TextInput, default: true },
        { key: "description", name: "Description", filter: "text", formItem: TextInput, default: true,required: false },
        { key: "organizationID", name: "Organization ID", filter: "text", formItem: OrganizationSelector, default: true,required: true },
        { key: "serviceProfileID", name: "Service Profile", filter: "text", formItem: ServiceProfileSelector, default: true,required: true },
        { key: "actions", name: "Actions", actions: ["edit", "remove"], default: true }
    ]
}

export const serviceProfileTable = {
    attributes: [
        { key: "id", name: "ID", default: true },
        { key: "name", name: "Name", filter: "text", formItem: TextInput, default: true },
        { key: "networkServerID", name: "networkServerID", filter: "text", formItem: TextInput, default: true },
        { key: "organizationID", name: "Organization ID", filter: "text", formItem: NumberInput, default: true },
        { key: "addGWMetaData", name: "addGWMetaData", filter: "bool", formItem: BooleanInput, default: false },
        { key: "channelMask", name: "channelMask", filter: "text", formItem: TextInput, default: false },
        { key: "devStatusReqFreq", name: "devStatusReqFreq", filter: "text", formItem: NumberInput, default: false },
        { key: "dlBucketSize", name: "dlBucketSize", filter: "text", formItem: NumberInput, default: true },
        { key: "dlRate", name: "dlRate", filter: "text", formItem: NumberInput, default: true },
        { key: "dlRatePolicy", name: "dlRatePolicy", filter: "text", formItem: TextInput, default: true },
        { key: "drMax", name: "drMax", filter: "text", formItem: NumberInput, default: true },
        { key: "drMin", name: "drMin", filter: "text", formItem: NumberInput, default: true },
        { key: "gwsPrivate", name: "gwsPrivate", filter: "bool", formItem: BooleanInput, default: false },
        { key: "hrAllowed", name: "hrAllowed", filter: "bool", formItem: BooleanInput, default: false },
        { key: "nwkGeoLoc", name: "nwkGeoLoc", filter: "bool", formItem: BooleanInput, default: false },
        { key: "prAllowed", name: "prAllowed", filter: "bool", formItem: BooleanInput, default: false },
        { key: "raAllowed", name: "raAllowed", filter: "bool", formItem: BooleanInput, default: false },
        { key: "reportDevStatusBattery", name: "reportDevStatusBattery", filter: "bool", formItem: BooleanInput, default: false },
        { key: "reportDevStatusMargin", name: "reportDevStatusMargin", filter: "bool", formItem: BooleanInput, default: false },
        { key: "minGWDiversity", name: "minGWDiversity", filter: "text", formItem: NumberInput, default: true },
        { key: "targetPER", name: "targetPER", filter: "text", formItem: NumberInput, default: true },
        { key: "ulBucketSize", name: "ulBucketSize", filter: "text", formItem: NumberInput, default: true },
        { key: "ulRate", name: "ulRate", filter: "text", formItem: NumberInput, default: true },
        { key: "ulRatePolicy", name: "ulRatePolicy", filter: "text", formItem: TextInput, default: true },
        { key: "actions", name: "Actions", actions: [], default: true }
    ]
}

export const deviceProfileTable = {
    attributes: [
        { key: "id", name: "ID", default: false },
        { key: "topic", name: "NS", filter: "name", formItem: TextNotEditable, default: true },
        { key: "name", name: "Name", filter: "text", formItem: TextInput, default: true,required: true  },
        { key: "networkServerID", name: "NS ( Should be 1 )", filter: "number", formItem: NumberInput, default: false,required: true },
        { key: "organizationID", name: "Organization ID", filter: "text", formItem: OrganizationSelector, default: true,required: true },
        { key: "uplinkInterval", name: "UplinkInterval", filter: "text", formItem: TextInput, default: false ,required:true},
        { key: "factoryPresetFreqs", name: "Freq. (should be [0])", filter: "text", formItem: TextInput, default: false,required:true },
        { key: "adrAlgorithmID", name: "adrAlgorithmID", filter: "text", formItem: TextInput, default: false , required: false },
        { key: "classBTimeout", name: "classBTimeout", filter: "text", formItem: NumberInput, default: false ,required:false},
        { key: "classCTimeout", name: "classCTimeout", filter: "text", formItem: NumberInput, default: false,required:false },
        { key: "geolocBufferTTL", name: "geolocBufferTTL", filter: "text", formItem: NumberInput, default: false,required:false },
        { key: "geolocMinBufferSize", name: "geolocMinBufferSize", filter: "text", formItem: NumberInput, default: false,required:false },
        { key: "macVersion", name: "macVersion", filter: "text", formItem: TextInput, default: false,required:false },
        { key: "maxDutyCycle", name: "maxDutyCycle", filter: "text", formItem: NumberInput, default: false,required:false },
        { key: "maxEIRP", name: "maxEIRP", filter: "text", formItem: NumberInput, default: false,required:false },
        { key: "payloadCodec", name: "payloadCodec", filter: "text", formItem: TextInput, default: false,required:false },
        { key: "payloadDecoderScript", name: "payloadDecoderScript", filter: "text", formItem: TextInput, default: false,required:false },
        { key: "payloadEncoderScript", name: "payloadEncoderScript", filter: "text", formItem: TextInput, default: false,required:false },
        { key: "pingSlotDR", name: "pingSlotDR", filter: "text", formItem: NumberInput, default: false,required:false },
        { key: "pingSlotFreq", name: "pingSlotFreq", filter: "text", formItem: NumberInput, default: false,required:false },
        { key: "pingSlotPeriod", name: "pingSlotPeriod", filter: "text", formItem: NumberInput, default: false,required:false },
        { key: "regParamsRevision", name: "regParamsRevision", filter: "text", formItem: TextInput, default: false , required:false },
        { key: "rfRegion", name: "rfRegion", filter: "text", formItem: TextInput, default: false , required:false },
        { key: "rxDROffset1", name: "rxDROffset1", filter: "text", formItem: NumberInput, default: false,required:false },
        { key: "rxDataRate2", name: "rxDataRate2", filter: "text", formItem: NumberInput, default: false,required:false },
        { key: "rxDelay1", name: "rxDelay1", filter: "text", formItem: NumberInput, default: false,required:false },
        { key: "rxFreq2", name: "rxFreq2", filter: "text", formItem: NumberInput, default: false,required:false },
        { key: "supports32BitFCnt", name: "supports32BitFCnt", filter: "text", formItem: BooleanInput, default: false , required:false},
        { key: "supportsClassB", name: "supportsClassB", filter: "text", formItem: BooleanInput, default: false , required:false},
        { key: "supportsClassC", name: "supportsClassC", filter: "text", formItem: BooleanInput, default: false , required:false},
        { key: "supportsJoin", name: "supportsJoin", filter: "text", formItem: BooleanInput, default: false , required:false},
        { key: "tags", name: "tags", filter: "text", formItem: TextInputJSON, default: false },
        { key: "actions", name: "Actions", actions: ["edit", "remove"], default: true } 
    ]
}

export const organizationTable = {
    attributes: [
        { key: "id", name: "ID", default: true },
        { key: "name", name: "Name", filter: "text", formItem: TextInput, default: true },
        { key: "canHaveGateways", name: "canHaveGateways", filter: "text", formItem: BooleanInput, default: true },
        { key: "displayName", name: "Display Name", filter: "text", formItem: TextInput, default: true },
        { key: "maxDeviceCount", name: "Max Devices", filter: "number", formItem: NumberInput, default: false },
        { key: "maxGatewayCount", name: "Max Gateway", filter: "number", formItem: NumberInput, default: false },
        { key: "actions", name: "Actions", actions: ["edit", "remove", "insideOrganization","CNO"], default: true }
    ]
}
export const serversTable = {
    attributes: [
        { key: "id", name: "ID", default: true },
        { key: "name", name: "Name", filter: "text", formItem: TextInput, default: true },
        { key: "url", name: "URL", formItem: TextInput, default: true },
        { key: "type", name: "Type", filter: "name", type: "select", formItem: TextInput, default: true },
        { key: "providerId", name: "Provider ID", formItem: TextInput, default: true },
        { key: "authToken", name: "Authorization token", formItem: TextInput, default: true },
        { key: "actions", name: "Actions", actions: ["edit", "remove"], default: true }
    ]
}

export const gatewayGroupsTable = {
    attributes: [
        { key: "name", name: "Name", filter: "text", formItem: TextInput, default: true },
        { key: "type", name: "Type", filter: "number", formItem: TextInput, default: true },
        { key: "parentId", name: "Parent ID", filter: "number", formItem: TextInput, default: true },
        { key: "creation", name: "Creation Date", filter: "date", formItem: TextInput, default: true },
        { key: "actions", name: "Actions", actions: ["edit", "remove"], default: true }
    ]
}

export const businessTable = {
    attributes: [
        { key: "id", name: "ID", default: true },
        { key: "name", name: "Name", formItem: TextInput, default: true },
        { key: "address", name: "Address", formItem: TextInput, default: true },
        { key: "contact", name: "Contact", formItem: TextInput, default: true },
        { key: "email", name: "Email", formItem: TextInput, default: true },
        { key: "telephone", name: "Telephone", formItem: TextInput, default: true },
        { key: "url", name: "Web", formItem: TextInput, default: true },
        { key: "country", name: "Country", formItem: TextInput, default: true },
        { key: "state", name: "State", formItem: TextInput, default: true },
        { key: "city", name: "City", formItem: TextInput, default: true },
        { key: "postalCode", name: "Postal Code", formItem: TextInput, default: true },
        { key: "actions", name: "Actions", actions: ["edit", "remove"], default: true }
    ]
}

export const councilTable = {
    attributes: [
        { key: "id", name: "ID", default: true },
        { key: "name", name: "Name", formItem: TextInput, default: true },
        { key: "address", name: "Address", formItem: TextInput, default: true },
        { key: "contact_person", name: "Contact Person", formItem: UserSelector, default: true },
        { key: "user_creator", name: "User Creator" },
        { key: "user_last_modificated", name: "User Last Modified" },
        { key: "phone", name: "Phone", formItem: PhoneInput, default: true },
        { key: "web", name: "Web", formItem: TextInput },
        { key: "url", name: "URL", formItem: TextInput, default: true },
        { key: "country", name: "Country", formItem: CountryStateSelector, default: true },
        { key: "state", name: "State", default: true },
        { key: "locality", name: "City", formItem: TextInput, default: true },
        { key: "postalCode", name: "Postal Code", formItem: TextInput },
        { key: "users", name: "Users", formItem: UsersSelector },
        { key: "actions", name: "Actions", actions: ["edit", "remove"], default: true }
    ]
}

export const projectsTable = {
    attributes: [
        { key: "id", name: "ID", default: true },
        { key: "name", name: "Name", formItem: TextInput, default: true },
        { key: "startDate", name: "Start Date", formItem: DateInput, default: true, required:true },
        { key: "council", name: "Council", formItem: TextInput, default: true },
        { key: "business", name: "Business", formItem: TextInput, default: true },
        { key: "actions", name: "Actions", actions: ["edit", "remove"], default: true }
    ]
}

export const mqttLogTable = {
    attributes: [
        { key: "id", name: "ID", filter: "number", default: true },
        { key: "gateway", name: "Gateway", filter: "text", formItem: TextInput, default: true },
        { key: "deviceEui", name: "Device EUI", formItem: TextInput, default: true },
        { key: "name", name: "Name", filter: "text", formItem: TextInput, default: true },
        { key: "description", name: "Description", formItem: TextInput, default: true },
        { key: "from", name: "From", filter: "name", formItem: TextInput, default: true },
        { key: "to", name: "To", filter: "name", formItem: TextInput, default: true },
        { key: "actions", name: "Actions", actions: ["edit", "mqttlog", "remove"], default: true }
    ]
}

export const doorsTable = {
    attributes: [
        { key: "lock", name: "Lock", filter: "name", formItem: TextInput, default: true },
        { key: "lastLock", name: "Last lock", filter: "date", formItem: TextInput, default: true },
        { key: "variable", name: "Variable", filter: "text", formItem: TextInput, default: true },
        { key: "sensor", name: "Sensor", type: "select", formItem: TextInput, default: false },
        { key: "output", name: "Output", filter: "text", formItem: TextInput, default: true },
        { key: "timeBlock", name: "Time block", type: "select", formItem: TextInput, default: false },
        { key: "adminApproval", name: "Admin to approve", type: "switch", formItem: TextInput, default: false },
        { key: "actions", name: "Actions", actions: ["edit", "calendar", "history", "lock", "remove"], default: true }
    ]
}

export const waterGeneralMunicipalTable = {
    attributes: [
        { key: "id", name: "ID", filter: "number", formItem: TextInput, default: true },
        { key: "name", name: "Name", filter: "text", formItem: TextInput, default: true },
        { key: "address", name: "Address", formItem: TextInput, default: false },
        { key: "description", name: "Description", filter: "text", formItem: TextInput, default: true },
        { key: "taxNumber", name: "Tax Number", filter: "number", formItem: TextInput, default: true },
        { key: "mobile", name: "Mobile", filter: "number", formItem: TextInput, default: true },
        { key: "email", name: "Email", filter: "text", formItem: TextInput, default: true },
        { key: "createdDate", name: "Created Date", filter: "date" },
        { key: "iban", name: "IBAN", formItem: TextInput, default: false },
        { key: "bic", name: "BIC", formItem: TextInput, default: false },
        { key: "actions", name: "Actions", actions: ["edit", "editterms", "xml", "remove"], default: true }
    ]
}

export const waterGeneralMunicipalTermsEdit = {
    attributes: [
        { key: "year", name: "Year", type: "date" },
        { key: "municipality", name: "Municipality", type: "select" },
        { key: "terms", name: "Terms", type: "addfield" }
    ]
}

export const waterAlarmsTable = {
    attributes: [
        { key: "name", name: "Name", filter: "text", formItem: TextInput, default: true },
        { key: "device", name: "Device", filter: "text", formItem: TextInput, default: true },
        { key: "deviceEui", name: "Device EUI", filter: "text", formItem: TextInput, default: true },
        { key: "alarmType", name: "Alarm Type", filter: "name", formItem: TextInput, default: true },
        { key: "description", name: "Description", filter: "text", formItem: TextInput, default: true },
        { key: "waterDevice", name: "Water device", type: "select", formItem: TextInput, default: true },
        { key: "variable", name: "Select a variable", type: "select", formItem: TextInput, default: false },
        { key: "dailyLimit", name: "Daily Limit", formItem: TextInput, default: false },
        { key: "email", name: "Alert On Email", formItem: TextInput, default: false },
        { key: "actions", name: "Actions", actions: ["edit", "remove"], default: true }
    ]
}

export const waterDevicesTable = {
    attributes: [
        { key: "name", name: "Name", required: true, formItem: TextInput, default: true },
        { key: "user", name: "User", type: "select", formItem: TextInput, default: true },
        { key: "variable", name: "Variable", type: "select", required: true, formItem: TextInput },
        { key: "diameter", name: "Diameter", type: "select", formItem: TextInput, default: true, filter: "number" },
        { key: "loraNumber", name: "Lora module number", formItem: TextInput, filter: "text" },
        { key: "description", name: "Description", formItem: TextInput, default: true },
        { key: "municipality", name: "Municipality", type: "select", required: true, formItem: TextInput, default: true, filter: "name" },
        { key: "waterUnits", name: "Water Unit", type: "select", formItem: TextInput },
        { key: "sewerRate", name: "Sewer Rate", type: "select", formItem: TextInput },
        { key: "counterNumber", name: "Counter number", type: "select", formItem: TextInput },
        { key: "waterGroup", name: "Water Group", type: "select", formItem: TextInput, default: true },
        { key: "sensor", name: "Sensor", type: "select", required: true, formItem: TextInput, default: true },
        { key: "contractNumber", name: "Contract number", formItem: TextInput },
        { key: "address", name: "Installation address", formItem: TextInput, default: true, filter: "name" },
        { key: "correctionFactor", name: "Correction Factor", formItem: TextInput },
        { key: "clock", name: "Clock", filter: "date", formItem: TextInput, default: true },
        { key: "actions", name: "Actions", actions: ["edit", "remove"], default: true }
    ]
}
