import React, { useState, useEffect } from "react";
import { Modal, Form, Select, message } from "antd";
import { ENV } from "../../../utils";
import { BasicApi } from "../../../API/basicApi";
import { useAuth } from "../../../Hooks";
import RemoveButton from "../RemoveButton";
import { useLocation } from "react-router-dom";

const { Option } = Select;

const ModalCNO = ({ visible, onCancel, element }) => {
  const [cnoList, setCnoList] = useState([]);
  const [councilList, setCouncilList] = useState([]);
  const [networkServerList, setNetworkServerList] = useState([]);
  const location = useLocation()

  const [form] = Form.useForm();
  const { accessToken } = useAuth()
  const [messageApi] = message.useMessage();

  // CNO
  const routeCNO = `${ENV.Auth_API}/${ENV.API_ROUTES.CNO}`;
  const apiCNO = new BasicApi(routeCNO)
  const handleOnRemove = async () => {
    // try {
    //   await apiCNO.deleteOne(element.id, accessToken)
    //   messageApi.open({
    //     type: "success",
    //     content: 'Element deleted.'
    //   })
    // } catch (error) {
    //   messageApi.open({
    //     type: "error",
    //     content: 'Error with this element.'
    //   })
    // }
  }
  const getCNOs = async () => {
    try {
      //TODO: CHECK DATA NOW ITS MOCKED
      const data = await apiCNO.getAll(accessToken)
      setCnoList(data.db.result)

    } catch (error) {
      // // console.log(error)
      messageApi.open({
        type: "error",
        content: 'Error while loading. '
      })
    }
  }
  //Council   
  const routeCouncil = `${ENV.Auth_API}/${ENV.API_ROUTES.COUNCIL}`;
  const apiCouncil = new BasicApi(routeCouncil)
  const getCouncils = async () => {
    try {
      //TODO: CHECK DATA NOW ITS MOCKED
      const data = await apiCouncil.getAll(accessToken)
      // console.log("COUNCIL: ", data)
      setCouncilList(data.db.result)
    } catch (error) {
      // // console.log(error)
      messageApi.open({
        type: "error",
        content: 'Error while getting councils. '
      })
    }
  }
  //NS
  const routeNetworkServer = `${ENV.Infraestructure_API}/${ENV.API_ROUTES.NETWORKSERVER}`;
  const apiNetworkServer = new BasicApi(routeNetworkServer)
  const getNetworkServers = async () => {
    try {
      const data = await apiNetworkServer.getAll(accessToken)

      const gatewayMac = location.pathname.split("/")[4]
      const filteredData = data.db.result.filter(item => item.gatewayMac === gatewayMac|| item.topic === ("NSC_" + gatewayMac));

      setNetworkServerList(filteredData)

    } catch (error) {
      // // console.log(error)
      messageApi.open({
        type: "error",
        content: (error.message)
      })
    }
  }
  const onFormSubmit = async (values) => {
    // Hacer algo con los valores del formulario, por ejemplo, enviarlos a la API
    try {
      const response = await apiCNO.postOne(values, accessToken)
      // console.log("RESPONSE", response)

      message.success(`CNO created OK`);

    } catch (error) {
      // console.log(error)
      message.error(error.message)
    }
  }

  const onFinishFailed = async () => {
    // console.log("ERROR")
  }

  useEffect(() => {
    getCNOs()
    getCouncils()
    getNetworkServers()
  }, [])

  return (
    <Modal
      open={visible}
      title={`${element.name}`}
      onCancel={onCancel}
      onOk={form.submit}
    >
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <h3>CNO List</h3>
          {
          cnoList.length > 0 ? 
          cnoList.map((item) => (
            item.OrganizationId === element.id ?
              <div style={{ display: "flex", margin: "10px" }}>
                <RemoveButton route={routeCNO} element={item} onRemove={handleOnRemove} />
                <div style={{ margin: "10px" }}>
                  <li key={item.NsId}>NS: {item.NsId}</li>
                  <li key={item.OrganizationId}>Org: {element.name}</li>
                  <li key={item.CouncilId}>Council: {item.CouncilId}</li>
                </div>

              </div> : <></>
          )
          )
          : <></>
        }
          </div>
        <div style={{ flex: 1 }}>
          <h3>Formulario</h3>
          <Form form={form} onFinish={onFormSubmit} onFinishFailed={onFinishFailed} >
            <Form.Item name="councilId" label="Council">
              <Select>
                {councilList.map((council) => (
                  <Option key={council.id} value={council.id}>
                    {council.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="nsId" label="N Server">
              <Select>
                {networkServerList.map((networkServer) => (
                  <Option key={networkServer.gatewayMac} value={networkServer.gatewayMac}>
                    {networkServer.gatewayMac}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="organizationId" label="Org.">
              <Select>
                {[element].map((organization) => (
                  <Option key={organization.id} value={organization.id}>
                    {organization.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );


};

export default ModalCNO;